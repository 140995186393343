import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import RoleService from '../../../../../../../services/api/role.service';
import UserRolesForm from '../user-roles-form/UserRolesForm';
import APIResponse from '../../../../../../templates/components/APIResponse'
const UserRolesAdd = (props) => {
    // function for input change
    const [apiResponse, setApiResponse] = useState()
    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }
    const submitHandler = (data) => {
        RoleService.add(data,props.practiceId)
        .then(res=>{
            setApiResponse(res)
        }
        )
        .catch(err=>{
            setApiResponse(err)
        })
    };
    return (
        <div className='row d-flex'>
            <UserRolesForm
                practiceId={props.practiceId}
                initialData={{}}
                submitHandler={submitHandler}
                onClose={props.onClose}
                submitButton="Save" />
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </div>
    )
}
export default UserRolesAdd