import React, { useEffect, useState, useContext } from 'react'
import DoctorService from '../../../../../../services/api/doctor.service'
import Utilities from '../../../../../../services/commonservice/utilities'
import ModalBox from '../../../../../templates/components/ModalBox'
import { store } from '../../../../../../context/StateProvider'

// Common service
import Table2 from '../../../../../templates/components/Table2'
import EditDoctor from '../../../doctor/edit-doctor/EditDoctor'
import PracticeLocationSelector from '../../../../../templates/components/PracticeLocationSelector'
import AddDoctor from '../../../doctor/add-doctor/AddDoctor'
import ProviderService from '../../../../../../services/api/provider.service'
import toast from 'react-hot-toast'
import ProviderDetails from './provider-details/ProviderDetails'
import { Transition } from 'semantic-ui-react'
import ProviderServices from '../provider-services/ProviderServices'

const ProviderTableSettings = (props) => {
    const { keyword } = props
    // Temp var holders
    const [isLoader, setIsLoader] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [editProvider, setEditProvider] = useState(false)
    const [showAdd, setShowAdd] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [showServices, setShowServices] = useState(false)
    const [providerList, setProviderList] = useState([])
    const [selectedProvider, setSelectedProvider] = useState()
    const [viewDetails, setViewDetails] = useState(true)
    const [transform, setTransform] = useState(false)
    const [activateModal, setActivateModal] = useState(false)
    const state = useContext(store).state
    const hiddenColumns = ["email", "mobile", "npi"]

    const activateProvider = (id, provider) => {
        //console.log(provider)
        if (provider?.isActiveDoctor) {
            DoctorService.deactivatePractitioner(id)
                .then(res => {
                    toast.success("Provider is Deactivated")
                    getProviders()
                    setActivateModal(false)
                })
                .catch(err => {
                    toast.error("Could not Deactivate")
                    setActivateModal(false)
                })
        }
        else {
            DoctorService.activatePractitioner(id)
                .then(res => {
                    toast.success("Provider is Activated")
                    getProviders()
                    setActivateModal(false)
                })
                .catch(err => {
                    toast.error("Could not Deactivate")
                    setActivateModal(false)
                })
        }
    }

    const deleteItem = (id) => {
        setIsLoader(true)
        DoctorService.delete(id)
        // console.log(id + " is deleted")
        setIsLoader(false)
    }
    const getProviders = () => {
        setIsLoader(true);
        DoctorService.doctorLookup({ isRegistered: true, PracticeLocationId: state.practiceLocationId })
            .then((response) => {
                console.log(response)
                // setProviderList(response.map(res => {
                //     return { ...res, subData: <ProviderDetails providerId={res.id} /> }
                // }));
                setProviderList(response)
                setIsLoader(false);
            })
            .catch(error => {
                setIsLoader(false);
                // console.log(error)
                // setCheckException(error);
            })
    }
    useEffect(() => {
        if (state?.practiceLocationId) {
            getProviders()
        }
    }, [state?.practiceLocationId])


    const columns = [
        {
            id: "firstName",
            Header: "First Name",
            accessor: provider => provider.firstName
        },
        {
            id: "lastName",
            Header: "Last Name",
            accessor: provider => provider.lastName
        },
        {
            id: "email",
            Header: "Email",
            accessor: provider => provider.email
        },
        {
            id: "mobile",
            Header: "Mobile Number",
            accessor: (provider) => Utilities.toPhoneNumber(provider.mobile)
        },
        {
            id: "npi",
            Header: "NPI",
            accessor: provider => provider.npi
        },
        {
            id: "status",
            Header: "Status",
            // className: "name",
            align: "center",
            sortable: true,
            accessor: (provider) => provider.isActiveDoctor ? <span className='btn btn-success text-white w-100'>Active</span> : <span className='btn btn-danger text-white w-100'>Inactive</span>
        },
        {
            id: "actionProvider",
            Header: "Action",
            textAlign: 'center',
            disableSortBy: true,
            accessor: (provider) => {
                //console.log(provider)
                return (
                    <div className='btn-group'>
                        <button className="p-0 ps-1 btn btn-primary ms-1" title="Edit Provider" onClick={e => {
                            e.preventDefault();
                            setSelectedProvider(provider);
                            setShowEdit(true);
                            return; // flip()
                        }}><i className="icon pencil" /></button>

                        <button className="p-0 ps-1 btn btn-primary ms-1" title="Availability" onClick={e => {
                            e.preventDefault();
                            setSelectedProvider(provider);
                            setShowDetails(true);
                            return; // flip()
                        }}><i className="icon clock" /></button>

                        <button className="p-0 ps-1 btn btn-primary ms-1" title="Services" onClick={e => {
                            e.preventDefault();
                            setSelectedProvider(provider);
                            setShowServices(true);
                            return;
                        }}><i className="icon stethoscope" /></button>

                        <button className="p-0 ps-1 btn btn-primary ms-1" title={provider.isActiveDoctor ? 'Deactivate User' : 'Activate User'} onClick={e => {
                            e.preventDefault();
                            setSelectedProvider(provider);
                            return setActivateModal(true)
                        }}>{provider.isActiveDoctor ? <i className="icon dont" /> : <i className="icon check" />}</button>
                    </div>
                )
            }
        }
    ]
    const flip = () => {
        setTransform(1)
        if (viewDetails) {
            setTimeout(() => {
                setViewDetails(false)
                setEditProvider(true)
            }, 500)
        }
        else {
            setTimeout(() => {
                setEditProvider(false)
                setViewDetails(true)
            }, 500)
        }
        setTimeout(() => {
            setTransform(2)
        }, 501)
    }
    return (
        <div>
            {viewDetails &&
                <div className={transform ? `transform-${transform}` : ''}>
                    <Table2 hiddenColumns={hiddenColumns} export searchable columns={columns} data={providerList} loading={isLoader}
                        extraButtons={[
                            { className: 'btn btn-primary', children: <i className='icon plus' />, onClick: () => { setShowAdd(true) }, title: "Add Provider" }
                        ]}
                        extraElements={[
                            { children: <div style={{ width: '250px' }}><PracticeLocationSelector /></div> },
                        ]} />
                </div>}
            {editProvider && <div className={transform ? `transform-${transform}` : ''}>
                <ProviderDetails provider={selectedProvider} goBack={() => { flip(); getProviders() }} />
            </div>}
            <ModalBox open={deleteModal} onClose={() => { setDeleteModal(false) }} title="Delete Modal">
                <div className='row d-flex align-items-center justify-content-between'>
                    <div className='col-md-auto col-12'>
                        Are you sure you want to delete {selectedProvider?.name}?
                    </div>
                    <div className='col-md-auto col-12'>
                        <div className='row d-flex justify-content-between modal-button-bar'>
                            <div className='col-auto'>
                                <button className='btn btn-secondary ms-3 m-m-0' onClick={e => { e.preventDefault(); setSelectedProvider(); return setDeleteModal(false) }}>No</button>
                            </div>
                            <div className='col-auto'>
                                <button className='btn btn-primary' onClick={e => { e.preventDefault(); deleteItem(selectedProvider?.id) }}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox open={activateModal} onClose={() => { setActivateModal(false) }} title={selectedProvider?.isActiveDoctor ? 'Deactivate Provider' : 'Activate Provider'}>
                <div className='row d-flex align-items-center justify-content-between remove-gutter'>
                    <div className='col-md-auto col-12 py-3'>
                        Are you sure you want to {selectedProvider?.isActiveDoctor ? 'Deactivate' : 'Activate'} {selectedProvider?.name}?
                    </div>
                    <div className='col-md-auto col-12'>
                        <div className='row d-flex justify-content-between modal-button-bar remove-gutter'>
                            <div className='col-auto'>
                                <button className='btn btn-primary' onClick={e => { e.preventDefault(); activateProvider(selectedProvider?.id, selectedProvider) }}>Yes</button>
                            </div>
                            <div className='col-auto'>
                                <button className='btn btn-secondary ms-3 m-m-0' onClick={e => { e.preventDefault(); setSelectedProvider(); return setActivateModal(false) }}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox open={showAdd} onClose={() => { setShowAdd(false) }} title="Add Provider">
                <AddDoctor onClose={() => { getProviders(); return setShowAdd(false); }} />
            </ModalBox>
            <ModalBox open={showEdit && selectedProvider} onClose={() => { setShowEdit(false) }} title="Edit Provider">
                <EditDoctor id={selectedProvider?.id} onClose={() => { getProviders(); return setShowEdit(false); }} />
            </ModalBox>
            <ModalBox open={showDetails && selectedProvider} onClose={() => { setShowDetails(false) }} title="Provider Details">
                <ProviderDetails provider={selectedProvider} goBack={() => { getProviders(); return setShowDetails(false); }} />
            </ModalBox>
            <ModalBox open={showServices && selectedProvider} onClose={() => { setShowServices(false) }} title="Provider Services" size="large">
                <ProviderServices provider={selectedProvider} goBack={() => { getProviders(); return setShowServices(false); }} />
            </ModalBox>
        </div>
    )
}

export default ProviderTableSettings