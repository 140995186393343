import React, { useEffect, useContext, useState } from 'react'
import { store } from '../../../context/StateProvider'
import Select from 'react-select'
import AppointmentService from '../../../services/api/appointment.service'
import PracticeLocationAvailabilityService from '../../../services/api/practice-location-availablity.service'

import StorageService from '../../../services/session/storage.service'
import toast from 'react-hot-toast'
import CacheUtilities from '../../../services/cache/CacheUtilities'

const PracticeLocationSelector = (props) => {
    // Context items
    const stateAndDispatch = useContext(store)
    const state = stateAndDispatch.state
    const dispatch = stateAndDispatch.dispatch
    const [locations, setLocations] = useState()
    const [locationId, setLocationId] = useState()
    const [dontResetState, setDontRestState] = useState(props?.dontResetState || false)

    useEffect(() => {
        if (props?.locationId) {
            setLocationId(props?.locationId);
        }
        else {
            setLocationId(state?.practiceLocationId);
        }
    }, [])

    const setCurrentLocation = async (newLocationId, always) => {
        if (always || !state?.practiceLocationId) {
            if (props.onChanging) props.onChanging(newLocationId)

            setLocationId(newLocationId);

            if (!dontResetState) {
                await CacheUtilities.pullAndCacheAllLocationData(newLocationId, dispatch);
            }

            if (props.onSelected) props.onSelected(newLocationId)
        } 
        else {
            if (!locationId && (props?.locationId || state?.practiceLocationId)) {
                setLocationId(props?.locationId || state?.practiceLocationId);
            }
        }
    }

    useEffect(() => {
        // If no location was passed (selector is using state)
        //   and another location selector changes the location
        if (!props.locationId && locationId && locationId != state?.practiceLocationId) {
            setLocationId(state?.practiceLocationId);
        }
    },[state?.practiceLocationId])

    useEffect(() => {
        if (locations && !props.locations)
        {
            // locations populated but dont set system-wide caches if may have come from props

            // Update state if doesnt exist
            if (!state?.practiceLocations || (Array.isArray(state?.practiceLocations) && state?.practiceLocations.length==0)) {
                dispatch({ type: "setLocationIds", payload: locations });
            }

            // update session if doesnt exist
            if (!StorageService.get("session", "locale")) {
                StorageService.save("session", "locale", JSON.stringify(locations))
            }

        }

    },[locations])

    useEffect(() => {
        if (!locations) {

            if(props.locations && Array.isArray(props.locations) && props.locations.length>0) {
                setLocations(props.locations)
                setCurrentLocation(props.locations[0].practiceLocationId)
            }
            else 
            {
                // get locations from state?
                if (state?.practiceLocations && Array.isArray(state?.practiceLocations) && state?.practiceLocations.length>0) {
                    setLocations(state?.practiceLocations)
                    setCurrentLocation(state?.practiceLocations[0].practiceLocationId)
                }
                else
                {
                    // get locations from session
                    let payload = StorageService.get("session", "locale")
                    if (payload) {
                        let payload = JSON.parse(StorageService.get("session", "locale"))
                        setLocations(payload)
                        setCurrentLocation(payload[0].practiceLocationId)
                    }
                    else 
                    {
                        // finally lookup locations
                        AppointmentService.practiceLocationLookup()
                        .then((res) => {
                            setLocations(res)
                            setCurrentLocation(res[0].practiceLocationId)
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    }
                }
            }
        }
    }, [])

    return (
        <Select
            style={props.style}
            className="react-select-container"
            classNamePrefix="react-select"
            isDisabled={props.disabled}
            options={locations && locations}
            isLoading={!locations}
            loadingMessage="Locations are loading..."
            name="practiceLocationId"
            value={locationId && locations && Array.isArray(locations) ? locations.find(obj => obj.practiceLocationId === locationId) : null}
            onChange={e => {
                setCurrentLocation(e.practiceLocationId, true)
            }}
            getOptionLabel={(option) => option.practiceLocation}
            getOptionValue={(option) => option.practiceLocationId}
        >
        </Select>
    )
}
export default PracticeLocationSelector