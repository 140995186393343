import ReactPaginate from 'react-paginate';

const PaginationTemplate = (props) => {

    return (
        <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={props.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={props.onPageChange}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={props.pageCount <=1 ? "page-item disabled": "page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={props.pageCount <=1 ? "page-item disabled": "page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
        />
    )
}
export default PaginationTemplate