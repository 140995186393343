import StorageService from '../session/storage.service';
import AppSetting from '../../common/constants/appsetting.constant';
import axios from 'axios'

const PracticeAppointmentStatusCodeService = {
  loggedInUserData: {},

  getLoggedInData() {
    return JSON.parse(StorageService.get('session', 'userDetails'));
  },

  buildQuery(data) {
    let queryData = '';
    for (const prop in data) {
      if (data[prop] !== '' && data[prop] !== 'undefined' && data[prop] !== null) {
        if (queryData === '') {
          queryData = '?' + prop + '=' + data[prop];
        } else {
          queryData += '&' + prop + '=' + data[prop];
        }
      }
    }
    return queryData;
  },


  getPracticeAppointmentStatusCode(reqObj) {
    let url = '';
    url = AppSetting.practiceappointmentstatuscode.getById
      .replace('{parentId}', this.getLoggedInData().parentId)+this.buildQuery(reqObj);
    return axios.get(url)
      .then(res => { return res })
      .catch(err => console.log('lookup error', err))
  },
  getAllPracticeAppointmentStatusCode() {
    let url = '';
    url = AppSetting.practiceappointmentstatuscode.find
      .replace('{parentId}', this.getLoggedInData().parentId);
    return axios.get(url)
      .then(res => { return res })
      .catch(err => console.log('lookup error', err))
  },
  updatePracticeAppointmentStatusCode(reqObj) {
    let url = '';
    url = AppSetting.practiceappointmentstatuscode.edit
      .replace('{parentId}', this.getLoggedInData().parentId)
      .replace('{id}', reqObj.practiceAppointmentStatusCodeId);
    return axios.put(url, reqObj)
      .then(res => { return res })
      .catch(err => console.log('lookup error', err))
  }
}

export default PracticeAppointmentStatusCodeService