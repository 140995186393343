import React from 'react'
import TabsTemplate from '../../../../templates/components/TabsTemplate'
import UnavailableBlockSettings from '../unavailable-blocks/UnavailableBlocks'
import PracticeLocationAvailability from '../location-settings/practice-location-availablity/PracticeLocationAvailablity'
import ProviderAvailability from '../provider-settings/provider-table-settings/provider-details/provider-availability/ProviderAvailability'
import PracticeAppointmentStatusCodeSettings from './practice-appointment-status-code-settings/PracticeAppointmentStatusCodeSettings'

const SchedulingSettings = (props) => {
    return (
        <TabsTemplate>
            <div title='Provider Hours'>
                <ProviderAvailability/>
            </div>
            <div title='Location Hours'>
                <PracticeLocationAvailability/>
            </div>
            <div title='Closed/Away Times'>
                <UnavailableBlockSettings />
            </div>
            <div title="Status Code">
                <PracticeAppointmentStatusCodeSettings />
            </div>
        </TabsTemplate>
    )
}

export default SchedulingSettings