import React, { useState, useEffect } from "react";
import moment from 'moment'
import DocumentsService from "../../../../../../../services/api/documents.service";
import DimLoader from "../../../../../../templates/components/DimLoader";
import NoResults from "../../../../../../templates/components/NoResults";
import APIResponse from "../../../../../../templates/components/APIResponse";
import ModalBox from "../../../../../../templates/components/ModalBox";
import DocumentPreview from "./DocumentPreview";
const DocumentList = (props) => {
  const [documentList, setDocumentList] = useState([]);
  const [isLoader, setIsLoader] = useState(false)
  const [deleteDoc, setDeleteDoc] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [selectedDoc, setSelectedDoc] = useState({})
  const [apiResponse, setApiResponse] = useState()
  const [isDownloadFile, setDownloadFile] = useState(false)
  const [fileType, setFileType] = useState()
  //preview file type
  const fileTypeList = [
    'png', 'jpeg', 'pdf', 'bmp', 'jpg', 'txt'
  ]
  const onSuccess = (message) => {
    if (isDownloadFile) {
      const link = document.createElement('a')
      link.href = apiResponse?.data?.downloadUrl
      link.click()
    }
    if (props.refresh) {
      props.refresh()
    }
    if (props.onClose) {
      props.onClose()
    }
    if (props.exitHandler) {
      props.exitHandler()
    }
  }
  useEffect(() => {
    pullDocuments();
  }, [props.refresh]);

  const downloadDocuments = (doc) => {
    setIsLoader(true)
    DocumentsService.getDownloadURL(props.patientId, doc.documentId)
      .then(response => {
        setApiResponse(response)
      })
      .catch(err => {
        setDownloadFile(false)
        setApiResponse(err)
      })
      .finally(() => {
        setDownloadFile(false)
        setIsLoader(false)
      })
  }
  const deleteDocument = (doc) => {
    setDeleteDoc(false)
    setIsLoader(true)
    DocumentsService.deleteDocument(props.patientId, doc?.documentId)
      .then(response => {
        setApiResponse(response)
        pullDocuments();
      })
      .catch(err => {
        setApiResponse(err)
      })
      .finally(() => {
        setSelectedDoc({})
        setIsLoader(false)
      });
  }

  const pullDocuments = () => {
    setIsLoader(true)
    return DocumentsService.getDocumentsList(props.patientId)
      .then((res) => {
        setDocumentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false)
      });
  };

  return (
    <div className="row d-flex g-4">
      {documentList && documentList.length > 0 ?
        documentList.map((form, idx) => {
          return (
            <div className="col-xl-4 col-lg-6 col-12">
              <div className="card">
                <div className="card-header"><strong>{form.documentName}</strong><div className="btn-group float-right">
                {form.uploadStatus == 1 ?<>
                  <button className="btn btn-primary" title="Download" onClick={e => { e.preventDefault(); setSelectedDoc(form); setDownloadFile(true); downloadDocuments(form); }}> <i className="icon download"></i></button>
                  <button className="btn btn-primary" title="Delete" onClick={e => { e.preventDefault(); setDeleteDoc(true); setSelectedDoc(form) }}> <i className="icon trash alternate"></i></button>
                  {(fileTypeList.indexOf(form.documentName.split('.')[1]) > -1) && <button className="btn btn-primary" title="Preview" onClick={e => { e.preventDefault();setFileType(form.documentName.split('.')[1]); setSelectedDoc(form); setShowPreview(true) }}> <i className="icon eye"></i></button>}
                </>:null}
                </div></div>
                <div className="row p-3 card-list">
                  <span className="col-12"><strong className="w-150px">Uploaded On:</strong> {form.uploadStatus == 1 ? moment(form.modifiedOn).format("MM-DD-YYYY") : "Pending.."}</span>
                </div>
              </div>
            </div>
          )
        })
        : <div className="col-12">
          <NoResults>
            <span>
              There are currently no documents for this user.{" "}
              {props.openModal && <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  props.openModal();
                }}
              >
                Add Documents
              </a>}
            </span>
          </NoResults>
        </div>
      }
      {isLoader ? <DimLoader /> : null}
      <ModalBox open={deleteDoc} title="Delete Document" onClose={() => { setDeleteDoc(false); setSelectedDoc({}) }} size="small" confirmButton="Yes" onCloseSuccess={() => { deleteDocument(selectedDoc); }}>
        Are you sure you want to permanently delete this document?
      </ModalBox>
      <ModalBox size="large" open={showPreview} onClose={() => { setShowPreview(false) }} title={`${selectedDoc.documentName}`}>
        <DocumentPreview fileType={fileType} onClose={() => { return setShowPreview(false) }} document={selectedDoc} />
      </ModalBox>
      <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={!isDownloadFile} />
    </div>
  );
};

export default DocumentList;
