import React from 'react'

const FourOhFour = () =>{
 return(
     <div className='row d-flex justify-content-center'>
         <h1>404</h1>
     </div>
 )
}

export default FourOhFour