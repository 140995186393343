import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment';
import Utilities from '../../../../../services/commonservice/utilities';
import { Accordion, Dropdown, Icon } from 'semantic-ui-react';
import ModalBox from '../../../../templates/components/ModalBox';
import EditInvoice from '../edit-invoice/EditInvoice';
import InvoicePreview from '../invoice-preview/InvoicePreview';
import InvoiceService from '../../../../../services/api/invoice.service';
import PaymentHistoryList from '../../report/paymentPlan-list/paymentPlan-card/payment-history-accordion/payment-history-list/PaymentHistoryList';
import VirtualTerminalForm from '../../transactions/terminals/virtual-terminal/virtual-terminal-form/VirtualTerminalForm';
import SendInvoice from '../send-invoice/SendInvoice';
import PaymentsList from '../../report/paymentPlan-list/paymentPlan-card/payments-accordion/payments-list/PaymentsList';
import CloseAndWriteOff from '../../transactions/close-and-write-off/CloseAndWriteOff';
import toast from 'react-hot-toast';
import { store } from '../../../../../context/StateProvider'
import AddRecurringPlan from '../../transactions/terminals/installment-terminal/AddRecurringPlan';
import APIResponse from '../../../../templates/components/APIResponse';

import { InvoiceUtility } from '../../../../../services/commonservice/invoice.utility'
import { InvoiceOperation } from '../../../../../services/commonservice/invoice.utility'

const InvoiceCard = (props) => {
    const state = useContext(store).state

    const [show, setShow] = useState(false)
    const [preview, setPreview] = useState(false)
    const [edit, setEdit] = useState(false)
    const [pay, setPay] = useState(false)
    const [recurringPlan, setRecurringPlan] = useState(false)
    const [recurringPlanType, setRecurringPlanType] = useState(false)
    const [payPlan, setPayPlan] = useState(false)
    const [subscriptionPlan, setSubscriptionPlan] = useState(false)
    const [apiResponse, setApiResponse] = useState()
    
    const [activeIndex, setActiveIndex] = useState()
    const [sendInvoice, setSendInvoice] = useState()
    const [cancel, setCancel] = useState(false)
    const [close, setClose] = useState(false)
    const [cancelWO, setCancelWO] = useState(false)
    const [closeReason, setCloseReason] = useState()
    const [errors, setErrors] = useState()
    const cancelHandler = () => {
        InvoiceService.deleteInvoice(props.invoiceId)
            .then(res => {
                console.log(res)
                toast.success("Invoice Canceled")
                setCancel(false)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const closeHandler = () => {
        let reqObj = { closeReason: 'Close invoice' }
        InvoiceService.closeAndWriteOff(props.invoiceId, reqObj)
            .then(res => {
                setApiResponse(res);
                console.log(res)
            })
            .catch(err => {
                setApiResponse(res);
                console.log(err)
            })
    }
    const onSuccess = (message) => {
        if (props.refresh) {
          props.refresh()
        }
        if (props.onClose) {
          props.onClose()
        }
        if (props.exitHandler) {
          props.exitHandler()
        }
      }
    useEffect(() => {
        if (closeReason?.length <= 10) {
            setErrors("Your reason for closing must be at least 10 characters long")
        }
        else if (!closeReason || closeReason == "") {
            setErrors("A reason for closing is required")
        }
        else {
            setErrors()
        }
    }, [closeReason])
    //Real Invioce Status for Reference
    // export enum InvoiceStatusEnum {
    //     'Draft' = 1,
    //     'Finalize' = 2,
    //     'Cancelled' = 3,
    //     'FullPaymentCreated' = 4, //Payment Created  //'PayInProgress' = 4,
    //     'PaymentPlanCreated' = 5, //Payment Plan Created
    //     'OneTimeScheduledCreated'= 6,   //'PayScheduled' = 6,
    //     'SubscriptionPlanCreated' = 7, //Subscription Plan Created
    //     'InProgress' = 8, //Unknown = 100,Created = 0,Pending = 1,Approved = 10,
    //     'Paid' = 9,  //Authorized = 2,Success = 16,
    //     'Unpaid' = 10,//Unpaid final failed status  //Failed = 5, Cancelled = 8,Refunded = 9,CancelAttempt = 11,RefundAttempt = 12,Denied = 14
    //     'Unsubscribed' = 11, //subscription payment plan when cancel = 8
    //     'Closed' = 30
    // }
    const invoiceStatus = [
        { title: 'All', value: null },
        { title: 'Ready To Send', value: 1 },
        { title: 'Open', value: 2 }, //Awaiting Payment
        { title: 'Cancelled', value: 3 },
        { title: 'In Progress', value: 4 }, // for swipe transaction status = 4 means awaitng response from HP and transaction is initiated
        { title: 'Created', value: 5 }, //5 = Payment Plan 
        { title: 'Created', value: 6 }, //One Time Scheduled Payment
        { title: 'Created', value: 7 }, //Subscription
        { title: 'In Progress', value: 8 },
        { title: 'Paid', value: 9 },
        { title: 'Unpaid', value: 10 },
        { title: 'Unsubscribed', value: 11 },
        { title: 'Closed', value: 30 }
    ]
    const invoiceType = [
        { title: 'Standard Invoice', value: 0 }, //Full Payment
        { title: 'Payment Plan', value: 1 },
        { title: 'One Time Scheduled', value: 2 },
        { title: 'Subscription', value: 3 },
        { title: 'Split Payment', value: 4 },
        { title: 'Prepaid Balance', value: 5 },
    ]
    useEffect(() => {
        if (props.keyword) {
            setActiveIndex()
        }
    }, [props.keyword])

    return (
        <div className='card mb-3 p-3'>
            <div className='row d-flex mx-0 remove-gutter'>
                <div className='point col-12 d-flex align-items-center' onClick={e => { e.preventDefault(); setShow(!show) }}>
                    <div className='row d-flex w-100 remove-gutter'>
                        <h5 className='col-md-8 col-12 invoice-card__header'>
                                {props.transaction.invoiceNumber && <span>{props.transaction.invoiceNumber}<span className='not-mobile'> | </span></span>}
                                <span><strong>{props.transaction.patientName}</strong><span className='not-mobile'> | </span></span>
                                <span>
                                    {props.transaction.invoiceType !== 3 && props.transaction.amountPaid && (0 < props.transaction.amountPaid < props.transaction.finalAmount) && props.transaction.amountPaid !== props.transaction.finalAmount ? Utilities.toDollar(props.transaction.finalAmount - props.transaction.amountPaid) + ' /' : null} {Utilities.toDollar(props.transaction.finalAmount)}
                                </span>
                                <span>
                                    {props.transaction.inProcessAmount && props.transaction.inProcessAmount > 0 ? <span className='not-mobile'> | Processing: {Utilities.toDollar(props.transaction.inProcessAmount) }</span> : null}
                                </span>
                        </h5>
                        <div className='col-md-4 col-12'>
                            <div className='d-flex row justify-content-end'>
                                {/* {props.transaction.invoiceStatus} */}
                                <div className='col-md-auto col-12 btn-group'>
                                    {props.transaction.invoiceStatus && 
                                        <div className='btn btn-secondary' title={InvoiceUtility.GetInvoiceStatusDescription(props.transaction.invoiceStatus)}>
                                            {
                                            (props.transaction?.invoiceType && [1,2,3,5].includes(props.transaction.invoiceType)) 
                                            ?
                                            invoiceType.find(obj => obj.value == props.transaction.invoiceType)?.title + ": " 
                                            : null}
                                            
                                            {InvoiceUtility.GetInvoiceStatusDescription(props.transaction.invoiceStatus)}
                                        </div>
                                    }

                                    {
                                        state.permissions.paymentsModifyInvoices
                                    &&
                                          InvoiceUtility.OperationAllowed(props.transaction, InvoiceOperation.UPDATE) ?                                            
                                          <button className='btn btn-primary' title="Edit" onClick={e => { e.preventDefault(); setEdit(true) }}><i className={`icon pencil`} /></button> : null
                                    }

                                    {
                                        state.permissions.patientModifyCommunications
                                    &&
                                        InvoiceUtility.OperationAllowed(props.transaction, InvoiceOperation.SEND_TO_PATIENT) &&
                                            <button className='btn btn-primary' title="Send to Patient" onClick={e => { e.preventDefault(); setSendInvoice(true) }}><i className="icon paper plane outline" /></button>
                                    }

                                    {
                                        state.permissions.paymentsModifyTransactions
                                    &&
                                        InvoiceUtility.OperationAllowed(props.transaction, InvoiceOperation.MAKE_PAYMENT) ?
                                            <button className='btn btn-primary' title="Collect Payment" onClick={e => { e.preventDefault(); setPay(true) }}><i className="icon dollar" /></button> : null
                                    }

                                    {
                                        state.permissions.paymentsViewInvoices
                                    &&
                                        <button className='btn btn-primary' title="View Details" onClick={e => { e.preventDefault(); setPreview(true) }}><i className="icon eye" /></button>
                                    }

                                    {/* {
                                        ![9,30,4].includes(props.transaction?.invoiceStatus) 
                                    &&  
                                        (
                                            state.permissions.paymentsModifySubscriptionsAndPlans
                                        ||
                                            state.permissions.paymentsModifyInvoices
                                        )
                                    ? */}
                                    <Dropdown button direction="left" icon="ellipsis horizontal" className="btn-primary icon btn p-o">
                                        <Dropdown.Menu>
                                            { 
                                                state.permissions.paymentsModifySubscriptionsAndPlans
                                            &&
                                            InvoiceUtility.OperationAllowed(props.transaction, InvoiceOperation.SETUP_PAYMENT_PLAN) ?
                                                <Dropdown.Item onClick={e => { e.preventDefault(); setRecurringPlanType(1); setRecurringPlan(true) }}>Setup Payment Plan</Dropdown.Item> : null
                                            }

                                            { 
                                                state.permissions.paymentsModifySubscriptionsAndPlans
                                            &&
                                            InvoiceUtility.OperationAllowed(props.transaction, InvoiceOperation.SETUP_SUBSCRIPTION) ?
                                                    <Dropdown.Item onClick={e => { e.preventDefault();setRecurringPlanType(3); setRecurringPlan(true) }}>Setup Subscription Plan</Dropdown.Item> : null
                                            }

                                            {   
                                                state.permissions.paymentsModifyInvoices
                                            &&
                                            InvoiceUtility.OperationAllowed(props.transaction, InvoiceOperation.CANCEL) &&
                                                <Dropdown.Item onClick={e => { e.preventDefault(); setCancel(true) }}>Cancel</Dropdown.Item>
                                            }

                                            {   
                                                state.permissions.paymentsModifyInvoices
                                            && 
                                                InvoiceUtility.OperationAllowed(props.transaction, InvoiceOperation.CLOSE) ?
                                                    <Dropdown.Item onClick={e => { e.preventDefault(); setClose(true) }}>Close</Dropdown.Item> : null
                                            }

                                            {
                                                state.permissions.paymentsModifyInvoices
                                            &&
                                                InvoiceUtility.OperationAllowed(props.transaction, InvoiceOperation.CLOSE_WRITEOFF) &&
                                                <Dropdown.Item onClick={e => { e.preventDefault(); setCancelWO(true) }}>Cancel & Write Off</Dropdown.Item>
                                            }

                                        </Dropdown.Menu>
                                    </Dropdown> 
                                    {/* : null} */}
                                </div>
                            </div>
                        </div>
                        <div className='col-12 text-start justify-content-between'>
                            <div className='row d-flex invoice-card__details'>
                                <span className='col'><strong>Provider: </strong>{props.transaction.doctorName}</span>
                                {props.transaction.createdOn && <span className='col text-center'><strong className='ms-5'>Created On: </strong>{moment(props.transaction.createdOn).format("M-D-YYYY")}</span>}
                                {props.transaction.serviceDate && <span className='col text-center'><strong className='ms-5'>Service Date: </strong>{moment(props.transaction.serviceDate).format("M-D-YYYY")}</span>}
                                {props.transaction.dueDate && <span className='col text-end'><strong className={`ms-5 ${new Date(props.transaction.dueDate) <= new Date() && 'text-danger'}`}>Due Date: </strong>{moment(props.transaction.dueDate).format("M-D-YYYY")}</span>}
                            </div>
                        </div>
                    </div>
                </div>
                {show &&
                    <div className='col-12 mt-3'>
                        <Accordion fluid styled>
                            <Accordion.Title
                                active={activeIndex === 0}
                                index={0}
                                onClick={e => { e.preventDefault(); if (activeIndex === 0) { setActiveIndex() } else { setActiveIndex(0) } }}
                            > <Icon name='dropdown' />Preview</Accordion.Title>
                            <Accordion.Content active={activeIndex === 0}>
                                <InvoicePreview transaction={props.transaction} pull={activeIndex === 0} />
                            </Accordion.Content>
                            {props.transaction.invoiceStatus === 5 || props.transaction.invoiceStatus === 6 || props.transaction.invoiceStatus === 7 || props.transaction.invoiceStatus === 8 ? <Accordion.Title
                                active={activeIndex === 1}
                                index={1}
                                onClick={e => { e.preventDefault(); if (activeIndex === 1) { setActiveIndex() } else { setActiveIndex(1) } }}
                            > <Icon name='dropdown' />Upcoming Payments</Accordion.Title> : null}
                            <Accordion.Content active={activeIndex === 1}>
                                <PaymentsList paymentPlanId={props.transaction.paymentId} patientId={props.transaction.patientId} autoPull={activeIndex === 1} />
                            </Accordion.Content>
                            <Accordion.Title
                                active={activeIndex === 2}
                                index={2}
                                onClick={e => { e.preventDefault(); if (activeIndex === 2) { setActiveIndex() } else { setActiveIndex(2) } }}
                            > <Icon name='dropdown' />Payment History</Accordion.Title>
                            <Accordion.Content active={activeIndex === 2}>
                                <PaymentHistoryList paymentPlan={props.transaction} autoPull={activeIndex === 2} />
                            </Accordion.Content>
                        </Accordion>

                    </div>
                }
            </div>
            <ModalBox open={preview} onClose={() => { setPreview(false) }} title={`Invoice No ${props.transaction?.invoiceNumber}`}>
                <InvoicePreview transaction={props.transaction} invoiceId={props.transaction.id} isModal onClose={() => { setPreview(false) }} />
            </ModalBox>
            <ModalBox open={edit} onClose={() => { setEdit(false) }} size="fullscreen" title={`Edit Invoice No ${props.transaction?.invoiceNumber}`}>
                <EditInvoice id={props.transaction.id} onClose={() => { setEdit(false); if (props.refresh()) { props.refresh() } }} isModal />
            </ModalBox>
            <ModalBox open={pay} onClose={() => { setPay(false); if (props.refresh()) { props.refresh() } }} title="Collect Payment">
                <VirtualTerminalForm initialData={props.transaction} invoiceId={props.transaction.id} onClose={() => { setPay(false); if (props.refresh()) { props.refresh() } }} isModal />
            </ModalBox>
            <ModalBox open={recurringPlan} onClose={() => { setRecurringPlan(false) }} title={recurringPlanType == 1 ? "Create Payment Plan" : "Subscription"}>
                {/*recurringPlanType = 1 installment and 3 subscription  */}
                <AddRecurringPlan transactionType={recurringPlanType} initialData={props.transaction} onClose={() => { setRecurringPlan(false); if (props.refresh()) { props.refresh() } }} isModal />
            </ModalBox>
            <ModalBox open={sendInvoice} onClose={() => { setSendInvoice(false) }} size="large" title="Send Invoice">
                <SendInvoice patientId={props.transaction.patientId} transaction={props.transaction} invoiceId={props.transaction.id} onClose={() => { setSendInvoice(false) }} />
            </ModalBox>
            <ModalBox open={cancel} onClose={() => { setCancel(false) }} size="tiny" title="Cancel Invoice">
                <div className='col-12'>
                    Are you sure you want to cancel this invoice?
                </div>
                {/* <div className='col-12'>
                    <input type="text" className="mt-3" minLength={10} value={closeReason} onChange={e => { e.preventDefault(); setCloseReason(e.target.value) }} />
                    <span className="error-message">{errors && errors}</span>
                </div> */}
                <div className='col-12 d-flex justify-content-between mt-3'>
                    <div className='col-auto'>
                        <button className='btn btn-primary' onClick={(e) => { e.preventDefault(); cancelHandler(); if (props.refresh()) { props.refresh() }; return setCancel(false) }}>Confirm</button>
                    </div>
                    <div className="col-auto">
                        <button className='btn btn-secondary' onClick={e => { e.preventDefault(); setCancel(false) }}>Close</button>
                    </div>
                </div>
            </ModalBox>

            <ModalBox open={close} onClose={() => { setClose(false) }} size="tiny" title="Cancel Invoice">
                <div className='col-12'>
                    Are you sure you want to close this invoice?
                </div>
                <div className='col-12 d-flex justify-content-between mt-3'>
                    <div className='col-auto'>
                        <button className='btn btn-primary' onClick={(e) => { e.preventDefault(); closeHandler(); return setClose(false) }}>Yes</button>
                    </div>
                    <div className="col-auto">
                        <button className='btn btn-secondary' onClick={e => { e.preventDefault(); setClose(false) }}>No</button>
                    </div>
                </div>
            </ModalBox>

            <ModalBox open={cancelWO} onClose={() => { setCancelWO(false) }} size="tiny" title="Close and Write Off">
                <CloseAndWriteOff refresh={() => { props.refresh() }} invoiceId={props.transaction.id} invoice={props.transaction} onClose={() => { setCancelWO(false); if (props.refresh()) { props.refresh() } }} />
            </ModalBox>
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </div>
    )
}
export default InvoiceCard