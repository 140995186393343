import React, { useState, useEffect, useRef } from "react";
import DocumentsService from "../../../../../../../services/api/documents.service";
import APIResponse from "../../../../../../templates/components/APIResponse";
import DimLoader from "../../../../../../templates/components/DimLoader";

const DocumentPreview = (props) => {
  const [isLoader, setIsLoader] = useState(true)
  const [apiResponse, setApiResponse] = useState()
  const [apiResponseURL, setApiResponseURL] = useState()
  const imgRef = useRef(null);

  const handleImageLoad = () => {
    const img = imgRef.current;
    img.style.height = 'auto';
    img.style.width = '100%';
    img.style.maxWidth = '100%';
    img.style.maxHeight = '100%';
  };
  const onSuccess = (message) => {
    if(['pdf','txt'].includes(props.fileType)){
      document.querySelector('#blob-iframe').src = apiResponse?.data?.previewUrl
    }
    else {
      setApiResponseURL(apiResponse?.data?.previewUrl)
    }
    
    if (props.refresh) {
      props.refresh()
    }
    if (props.exitHandler) {
      props.exitHandler()
    }
  }


  const downloadDocument = (doc) => {
    setIsLoader(true)
    DocumentsService.getDownloadURL(doc.patientId, doc.documentId)
      .then(response => {
        setApiResponse(response)
        setIsLoader(false)
      })
      .catch(err => {
        setApiResponse(err)
        setIsLoader(false)
      })
  }
  useEffect(() => {
    downloadDocument(props.document);
  }, []);


  return (
    <>
      {isLoader ? <DimLoader loadMessage="Loading..." /> : null}
      <div className="row d-flex">
      {apiResponseURL ? <div style={{ position: 'relative', paddingBottom: '100%' }}>
      <img
        ref={imgRef}
        src={apiResponseURL}
        onLoad={handleImageLoad}
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
      />
    </div>:<iframe id="blob-iframe"
        style={{border:"1px solid rgba(0, 0, 0, 0.1)",height: "475px"}}></iframe>}

        <div className="d-flex justify-content-between mt-3 modal-button-bar">
          <div className="col-auto">
            <button className="btn btn-secondary float-right" onClick={(e) => { e.preventDefault(); props.onClose() }} >Close</button>
          </div>
        </div>
      </div>
      <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={false} onClose={()=>props.onClose()}/>
    </>
  );
};

export default DocumentPreview;