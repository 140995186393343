import React, { useState, useEffect } from 'react'
import TabsTemplate from '../../../../templates/components/TabsTemplate'
import DoctorList from '../../doctor/doctor-list/DoctorList'
import ProviderTableSettings from './provider-table-settings/ProviderTableSettings'
import LocationProviders from './location-providers/LocationProviders'

const ProviderSettings = (props) => {
    return (
        <TabsTemplate>
            <div title='Practice Providers'>
                {/* <DoctorList/> */}
                <ProviderTableSettings/>
            </div>
            {/* <div title='Provider Scheduling' disabled></div> */}
            <div title='Location Providers'>
                <LocationProviders/>
            </div>
        </TabsTemplate>
    )
}

export default ProviderSettings