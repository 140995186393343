import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react'
import Utilities from '../../../../../../../services/commonservice/utilities'
import { store } from '../../../../../../../context/StateProvider'
import PatientService from '../../../../../../../services/api/patient.service'
import CreditBalanceService from '../../../../../../../services/api/credit-balance.service'
import DimLoader from '../../../../../../templates/components/DimLoader'
import CommonService from '../../../../../../../services/api/common.service'
import { debounce } from 'lodash'
import AsyncSelect from 'react-select/async';
import moment from 'moment'
import Select from 'react-select'
import { useMediaQuery } from 'react-responsive'
import ModalBox from  "../../../../../../templates/components/ModalBox"
import AddPatient from "../../../../patient/add-patient/AddPatient"

const PatientFinancialHeader = (props) => {
    const [patient, setPatient] = useState(props.patient)
    const isMobile = useMediaQuery({ query: `(max-width: 576px)` });
    useEffect(() => {
        setPatient(props.patient)
    }, [props.patient])
    const [accounts, setAccounts] = useState(props.accounts)
    const [editPatient, setEditPatient] = useState(false)
    const [editLocation, setEditLocation] = useState(false)
    const [showEditPatientIcon, setShowEditPatientIcon] = useState(false)
    const [showEditLocationIcon, setShowEditLocationIcon] = useState(false)
    const [location, setLocation] = useState()
    const [creditBalance, setCreditBalance] = useState(props.creditBalance)
    const [showAddPatient, setShowAddPatient] = useState(false)

    const state = useContext(store).state

    const getAccounts = () => {
        let reqObj = { IsActive: 1, PracticeLocationId: location?.practiceLocationId }
        PatientService.fetchPatientAccount(props.patient?.id, reqObj)
            .then(res => {
                console.log(res)
                setAccounts(res)
            })
    }

    const getPrepaidBalance = () => {
        let reqObj = { PatientIds: props.patient?.id }
        CreditBalanceService.lookupCreditBalance(reqObj)
            .then(res => {
                console.log(res)
                if (Array.isArray(res?.data)) {
                    setCreditBalance(res.data[0]?.currentBalance)
                }
            })
    }
    useEffect(() => {
        if (props.accounts) {
            setAccounts(props.accounts)
        }
        else if (props.patient && location) {
            getAccounts()
        }
    }, [props.accounts, patient, location])

    useEffect(() => {
        if (props.patient) {
            if (props.creditBalance) {
                setCreditBalance(props.creditBalance)
            }
            else {
                getPrepaidBalance()
            }
        }
    }, [props.creditBalance, props.patient])



    useEffect(() => {
        if (props.locationId) {
            console.log(props.locationId)
            setLocation(state.practiceLocations.find(obj => obj.practiceLocationId == props.locationId))
        }
        // else{
        //     setLocation(state.practiceLocations.find(obj=>obj.practiceLocationId==state.practiceLocationId))
        // }
    }, [props.locationId])

    const patientLoad = (inputText, callBack) => {
        if (inputText?.length < 3) return;
        let reqObj = { SearchTerm: inputText, isActive: true, isRegistered: true, SortField: 'firstName', Asc: true }
        if (props.patientId) {
            reqObj.PatientIds = props.patientId
        }
        CommonService.patientLookup(reqObj)
            .then(res => {
                if (res) {
                    callBack(res.data)
                }
            }
            )
            .catch(err => console.log(err))
    }
    const rePatientLoad = useCallback(debounce(patientLoad, 500), [])

    useEffect(() => {
        if (patient) {
            setEditPatient(false)
        }
        else if (!props.disablePatientEdit) {
            setEditPatient(true)
        }
    }, [patient])

    useEffect(() => {
        if (location) {
            setEditLocation(false)
        }
        else {
            setEditLocation(true)
        }
    }, [location])


    return (
        <div className="col-12">
            <div className="card p-3 mb-3" style={{ minHeight: '58px' }}>
                <div className="row d-flex align-items-center">
                    <div className='col-md-6 col-12'>
                        <div className='d-flex row'>
                            <div className="col row d-flex justify-content-between align-items-center">
                                {!editPatient ? <div className="col d-flex align-items-center financial-header"
                                    onMouseEnter={e => {
                                        e.preventDefault()
                                        if (!props.disablePatientEdit) {
                                            setShowEditPatientIcon(true)
                                        }
                                    }}
                                    onMouseLeave={e => {
                                        e.preventDefault()
                                        setShowEditPatientIcon(false)
                                    }}
                                    onClick={e => {
                                        e.preventDefault()
                                        if (!props.disablePatientEdit) {
                                            setShowEditPatientIcon(!showEditPatientIcon)
                                        }
                                    }
                                    }
                                >
                                    <div className="col-auto">
                                        <i className="icon user big text-primary" />
                                    </div>
                                    {props.disablePatientEdit && !patient ?
                                        <div className='row d-flex'>
                                            <h5>Loading Patient...</h5>
                                        </div>
                                        : <><div className='row d-flex justify-content-start financial-card-name'>
                                            <div className='col-auto'><h5>{patient?.firstName ? patient.firstName + ' ' + patient?.lastName : patient?.name}
                                            </h5>
                                            </div>
                                            {showEditPatientIcon ? <div className='col-auto'><i className='icon pencil point text-primary' title="Change Patient" onClick={e => { e.preventDefault; setEditPatient(true) }} /></div> : null}
                                        </div>
                                            <div className='col-auto' style={{padding:"5px"}}>
                                                {patient?.patientTags && patient?.patientTags.map(tag => {
                                                    return (
                                                        <span className={`badge text-white me-1 bg-info`}>{tag.name}</span>
                                                    )
                                                })}
                                            </div>
                                        </>
                                        }
                                </div> :
                                    <div className='col field required'>
                                        <label><i className="icon map outline" /> Choose Patient</label>
                                        <AsyncSelect
                                            classNamePrefix="react-select"
                                            className="react-select-container"
                                            value={patient}
                                            name="patientId"
                                            loadOptions={rePatientLoad}
                                            placeholder="Select Patient"
                                            onChange={e => {
                                                if (props.onPatientChange) {
                                                    if (e?.id) {
                                                        props.onPatientChange(e)
                                                    }
                                                    else {
                                                        props.onPatientChange(null)
                                                    }
                                                }
                                            }}
                                            getOptionLabel={(option) => {
                                                return (
                                                    <div className="d-flex row">
                                                        <span className="col">{option.firstName} {option.lastName}</span>
                                                        <span className='col text-start'><i className='icon birthday cake' />{moment.utc(option.dob).format("M/D/YYYY").toString()}</span>
                                                        <span className='col text-end'><i className='icon phone' />{option.mobile && Utilities.toPhoneNumber(option.mobile) || option.patientPhone && Utilities.toPhoneNumber(option.patientPhone)}</span>
                                                    </div>
                                                )
                                            }}
                                            getOptionValue={(option) => option.id}
                                            noOptionsMessage={(e) =>
                                                <button className='btn btn-primary form-control' onClick={e => { e.preventDefault(); setShowAddPatient(true) }}>Add Patient</button>}
                                        />
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className='d-flex row justify-content-end align-items-center financial-header-info'>
                            {accounts?.length > 0 && !editLocation ? <div className="d-flex col-auto align-items-center point" title={`You have ${accounts?.length > 0 ? accounts.length : 'No'} accounts`}>
                                {/* <span class="material-icons material-icons-outlined text-primary large">
                                    account_balance_wallet
                                </span> */}
                                <i className='icon credit card large text-primary' />
                                <span className="badge bg-primary ms-2">{accounts?.length || 0}</span>
                            </div> : null}
                            {creditBalance && !editLocation ? <div className="col-auto point align-items-center d-flex" title={`You have a prepaid balance of ${Utilities.toDollar(creditBalance)}`}>
                                <span className='text-primary text-icon large'>P</span>
                                <span className='badge bg-primary ms-2'>{Utilities.toDollar(typeof creditBalance === 'number' ? creditBalance : 0)}</span>
                            </div> : null}
                            {!editLocation ? <div className="col-auto point" title={`You are using the ${location?.practiceLocation} location`}
                                onMouseEnter={e => {
                                    e.preventDefault()
                                    if (!props.disableLocationEdit) {
                                        setShowEditLocationIcon(true)
                                    }
                                }}
                                onMouseLeave={e => {
                                    e.preventDefault()
                                    setShowEditLocationIcon(false)
                                }}
                                onClick={e => {
                                    e.preventDefault()
                                    if (!props.disableLocationEdit) {
                                        setShowEditLocationIcon(!showEditLocationIcon)
                                    }
                                }}
                            >
                                <i className='icon map marker alternate large text-primary' />
                                <span className="badge bg-primary">{location?.practiceLocation}</span>
                                {showEditLocationIcon ? <i className='icon pencil ms-2 text-primary' title="Change Location" onClick={e => { e.preventDefault(); setEditLocation(true) }} /> : null}
                            </div> :
                                <div className='col required field'>
                                    {!props.isRenew && <label><i className="icon map outline" /> Choose Location</label>}
                                    {!props.isRenew && <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Select Location"
                                        options={state.practiceLocations}
                                        isLoading={!state.practiceLocations}
                                        loadingMessage="Locations are loading..."
                                        name="practiceLocationId"
                                        value={location}
                                        onChange={e => {
                                            if (props.onLocationChange) {
                                                if (e?.practiceLocationId) {
                                                    props.onLocationChange(e)
                                                }
                                                else {
                                                    props.onLocationChange(null)
                                                }
                                            }
                                        }}
                                        getOptionLabel={(option) => option.practiceLocation}
                                        getOptionValue={(option) => option.practiceLocationId}
                                    >
                                    </Select>}
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            <ModalBox open={showAddPatient} onClose={() => { setShowAddPatient(false) }} title="Add Patient">
                <AddPatient isModal onClose={() => { setShowAddPatient(false) }} />
            </ModalBox>            
        </div>
    )
}
export default PatientFinancialHeader