import React, { useEffect, useState, useContext } from 'react'
import DoctorService from '../../../../../services/api/doctor.service'
import moment from 'moment'
import Utilities from '../../../../../services/commonservice/utilities'
import { store } from '../../../../../context/StateProvider'
import defaultLogo from '../../../../../../assets/images/logo_login.png'
// import defaultLogo from '../../../../../../assets/images/revitalized-logo.png'
import InvoiceService from '../../../../../services/api/invoice.service'
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import StorageService from '../../../../../services/session/storage.service'
import PatientService from '../../../../../services/api/patient.service'
import TransactionService from '../../../../../services/api/transaction.service'
import PaymentsList from '../../report/paymentPlan-list/paymentPlan-card/payments-accordion/payments-list/PaymentsList'
import PaymentHistoryList from '../../report/paymentPlan-list/paymentPlan-card/payment-history-accordion/payment-history-list/PaymentHistoryList'
import { InvoiceTypeEnum as invoiceTypeFilter } from '../../../../../common/enum/invoice-type.enum'
const InvoicePreview = (props) => {
    const [providerList, setProviderList] = useState()
    const [practiceLocation, setPracticeLocation] = useState()
    
    const [subTotal, setSubTotal] = useState(0)
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [totalTax, setTotalTax] = useState(0)
    const [total, setTotal] = useState(0)
    const [totalProducts, setTotalProducts] = useState(0)
    const [logo, setLogo] = useState(defaultLogo)
    const [totalServices, setTotalServices] = useState(0)
    const [totalFees, setTotalFees] = useState(0)
    const globalStateAndDispatch = useContext(store)
    const state = globalStateAndDispatch.state
    const dispatch = globalStateAndDispatch.dispatch
    const [invoice, setInvoice] = useState(props.transaction || null)
    const [tc, setTc] = useState()
    const [practiceLocationId, setPracticeLocationId] = useState()
    const contextState = globalStateAndDispatch.state;
    const contextDispatch = globalStateAndDispatch.dispatch;
    const getLogo = () => {
        let logoPath = ""
        if (invoice?.practiceLocationId) {
            logoPath = StorageService.getLogo(invoice?.practiceLocationId);
        }
        if (logoPath) {
          setLogo(logoPath)
        }
        else {
          setLogo(defaultLogo)
        }
      }
    
    const handlePdf = () => {
        // const input = document.getElementById('invoice');
        // let canvasOptions = { imageTimeout: 0 }
        // html2canvas(input, canvasOptions)
        //     .then((canvas) => {
        //         let options = { orientation: 'p', unit: 'in', format: 'letter' }
        //         const imgData = canvas.toDataURL('image/png', 1.0);
        //         const pdf = new jsPDF(options);
        //         var width = pdf.internal.pageSize.getWidth();
        //         var height = pdf.internal.pageSize.getHeight();
        //         console.log(width, height)
        //         pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
        //         //   pdf.autoPrint();
        //         pdf.save("Invoice");
        //     })
        Utilities.printWindow('invoice', 'Invoice', { landscape: false })
    }
    const invoiceStatus = [
        { value: 1, title: 'Ready To Send' },
        { value: 2, title: 'Open' },
        { value: 3, title: 'Cancelled' },
        { value: 4, title: 'In Progress' }, // for swipe transaction status = 4 means awaitng response from HP and transaction is initiated
        { value: 5, title: 'Payment Plan created' },
        { value: 6, title: 'OneTime scheduled created' },
        { value: 7, title: 'Subscription created' },
        { value: 8, title: 'In Progress' },
        { value: 9, title: 'Paid' },
        { value: 10, title: 'Open' },
        { value: 11, title: 'Unsubscribed' },
       // { value: 13, title: 'Partial Payment' },
        { value: 30, title: 'Closed' }
    ]

    const patientLookup = () => {
        // let reqObj = { SearchTerm: '', isActive: true, isRegistered: true, SortField: 'firstName', PatientIds: invoice.patientId }
        PatientService.getPatientById(invoice.patientId)
            .then(res => {
                if (res) {
                    setPatientList(res.data)
                }
            }
            )
            .catch(err => console.log(err))
    }


    const providerLookup = () => {
        // setIsLoader(true)
        if (!providerList && invoice.doctorId) {
            // const reqObj = { isRegistered: true, isActive: true, SearchTerm: invoice?.doctorId };
            DoctorService.getById(invoice.doctorId)
                .then(
                    (response) => {
                        setProviderList(response)
                    })
                .catch((error) => {
                    // setIsLoader(false)
                    console.log(error);
                })
        }
    }

    const invoiceLookup = () => {
        InvoiceService.getInvoiceById(props.invoiceId)
            .then(res => {
                setInvoice(res)
            })
    }

    useEffect(() => {
        
        if (invoice) {
            providerLookup()
            sumItems(invoice.items)
            transactionLookup()
            getLogo()
            if (state?.practiceLocations) {
                setPracticeLocation(state?.practiceLocations.find(obj => obj.practiceLocationId == invoice?.practiceLocationId)?.practiceLocation)
            }
        }
    }, [invoice])

    useEffect(() => {
        if (props.invoiceId) {
            invoiceLookup()
        }
    }, [props.invoiceId])

    const lineTotal = (element) => {
        if (typeof element.quantity === 'number') {
            let total = parseFloat(element.unitPrice) * parseInt(element.quantity)

            let discountAmt = 0
            if (parseFloat(element.discount) > 0 || parseFloat(element.discountAmount) > 0) {
                discountAmt = element.discountType === 3? parseFloat(element.discountAmount):   parseInt(element.quantity) * element.discountAmount
            }
            total = total - discountAmt
            if (element.taxPercent > 0) {
                return Math.round(total * (1 + (element.taxPercent / 100)) * 100) / 100
            }
            else {
                return total
            }

        }
        else return 0
    }


    const transactionLookup = () => {
        let reqObj = { InvoiceNo: invoice.invoiceNumber }
        TransactionService.findTransaction(reqObj)
            .then(res => {
                // let tcs = res.data.data.filter(item =>
                //     item.transactionStatus == 2 || item.transactionStatus == 16
                // )[0]
                let tcs = res.data.data
                if(tcs?.length > 0){
                    setTc(tcs)
                    if (tcs[0]?.practiceLocationId) {
                        setPracticeLocationId(tcs[0].practiceLocationId)
                    }                    
                }
                else{
                    setTc()
                }
            })
    }


    const sumItems = (items) => {
        let newArray
        if (items) {
            if (Array.isArray(items)) {
                newArray = items
            }
            else {
                newArray = [items]
            }

            let subTotal = 0
            let discountTotal = 0
            let taxTotal = 0
            let productTotal = 0
            let serviceTotal = 0
            let feesTotal = 0
            newArray.forEach((item, i) => {
                let subTotalInside = 0
                let discountTotalInside = 0
                subTotalInside = parseFloat(item.unitPrice * item.quantity)
                subTotal += subTotalInside
                discountTotalInside = parseFloat(item.discountAmount) > 0 ? (item.discountType === 3 ? item.discountAmount : item.discountAmount * item.quantity) : parseFloat(item.discountPercent) ? parseFloat(item.discountPercent) / 100 * item.quantity * item.unitPrice : 0
                discountTotal += discountTotalInside
                productTotal = item.itemType == 1 ? productTotal + lineTotal(item) : productTotal
                serviceTotal = item.itemType == 2 ? serviceTotal + lineTotal(item) : serviceTotal
                feesTotal = item.itemType == 4 ? feesTotal + lineTotal(item) : feesTotal
                taxTotal = parseFloat(item.taxPercent) > 0 ? taxTotal + Math.round((subTotalInside - discountTotalInside) * parseFloat(item.taxPercent)) / 100 : taxTotal
            })
            setTotalDiscount(discountTotal)
            setTotalServices(serviceTotal)
            setTotalProducts(productTotal)
            setTotalFees(feesTotal)
            setSubTotal(subTotal)
            setTotalTax(taxTotal)
            setTotal(subTotal - discountTotal + taxTotal)
        }
    }

    useEffect(() => {
        if (practiceLocationId) {
            getLogo()
        }
    }, [practiceLocationId])

    return (
        <div className='container-fluid invoice-preview'>
            <div className='row d-flex justify-content-end'>
                <div className='col-auto mb-3'>
                    <button className="btn btn-primary" title="Print" onClick={e => { e.preventDefault(); handlePdf() }}><i className='icon file print' /></button>
                </div>
            </div>
            <div id="invoice" className='portrait'>
                <div className='portrait invoice-preview-portrait' style={{ margin: '5px' }}>
                    {tc && <div className="row d-flex g-3">
                        <div className="col-12 btn btn-secondary">{invoice?.invoiceStatus && <span
                            title={invoiceStatus.find(obj => obj.value == invoice?.invoiceStatus)?.title}
                        >{invoiceStatus.find(obj => obj.value == invoice?.invoiceStatus)?.title}</span>}
                        </div>
                    </div>}
                    {invoice && <div className={`row d-flex g-3 mt-1`}  >
                        <div className={`col-12 px-0 card d-flex justify-content-center`}>
                            <div className='justify-content-between card-header bg-white p-3 row-fluid d-flex align-items-center'>
                                <div className='col-6'>
                                    <img src={logo} style={{ height: '.65in' }} />
                                </div>
                                <div className='col-6 text-end'>
                                    {invoice.invoiceNumber && <h5 className='mb-0'>Reference # {invoice.invoiceNumber}</h5>}
                                    {/* {invoice.invoiceType == 5 && <span className='badge bg-secondary p-2'>Prepaid Balance</span>} */}
                                    {/* invoice.invoiceType==0  adding this login 0 is considering as false */}
                                    {invoice.invoiceType || invoice.invoiceType==0 ? <span className='badge bg-secondary p-2'>{invoiceTypeFilter.find(obj => obj.value === invoice.invoiceType)?.title}</span>:""}
                                    {invoice.transactionDate && <span className='text-end'>Checkout Date: {moment(invoice.transactionDate).format("M-D-YYYY")}</span>}<br />
                                    {practiceLocation && <><span className='text-end'>Location: {practiceLocation}</span><br /></>}
                                    <span className='text-end'>Created By: {invoice.createdBy}</span><br />
                                    <span className='text-end'>Invoice Date: {moment(invoice.serviceDate).format("M-D-YYYY")}</span><br />
                                    <span className={`text-end ${new Date(invoice.dueDate) <= new Date() && 'text-danger'}`}>Due Date: {moment(invoice.dueDate).format("M-D-YYYY")}</span>
                                </div>
                            </div>
                            <div className='card-body px-0 table-responsive'>
                                <div className='row px-3 pb-3'>
                                    <div className='col-6'>
                                        <strong>Patient</strong><br />
                                        {invoice.patientName}<br />
                                        {invoice.toEmail && <>{invoice.toEmail}<br /></>}
                                        {invoice.phone && Utilities.toPhoneNumber(invoice.phone)}
                                    </div>
                                    <div className='col-6'>
                                        <strong>Provider</strong><br />
                                        {invoice.doctorName}
                                    </div>
                                </div>
                                <table className='table table-borderless my-0'>
                                    <colgroup>
                                        <col span="1" style={{ minWidth: '150px' }} />
                                        <col span="1" />
                                        <col span="1" />
                                        <col span="1" />
                                        <col span="1" />
                                        <col span="1" style={{ width: '150px' }} />
                                    </colgroup>
                                    <thead>
                                        {invoice.items && invoice.items.filter(product => { return product.itemType === 1 }).length > 0 &&
                                            <tr>
                                                <th>Products</th>
                                                <th>Unit Rate</th>
                                                <th>Quantity</th>
                                                <th>Discount</th>
                                                <th>Tax(%)</th>
                                                <th className='text-end pe-4'>Amount</th>
                                            </tr>
                                        }
                                    </thead>
                                    <tbody>
                                        {invoice.items && invoice.items.filter(product => { return product.itemType === 1 }).map((product, i) => {
                                            return (
                                                <tr>
                                                    <td className='pb-0 pt-3'>{product.name}</td>
                                                    <td>
                                                        {/* <div className='input-group align-items-center'> */}
                                                        {/* <i className='icon dollar small' /> */}
                                                        <input disabled value={Utilities.toDollar(product.unitPrice)} type="text" name="unitPrice" onChange={e => { e.preventDefault(); changeProduct(e, i) }} />
                                                        {/* </div> */}
                                                    </td>
                                                    <td><input disabled value={product.quantity} type="number" name="quantity" onChange={e => { e.preventDefault(); changeProduct(e, i) }} /></td>
                                                    <td><input disabled value={product.discountType == 2 && product.discountPercent ? parseFloat(product?.discountPercent) + '%' : (product.discountAmount ? (product.discountType === 3 ? `Total ${Utilities.toDollar(product.discountAmount)}` : Utilities.toDollar(product.discountAmount)) : '$0.00') } type="text" name="discount" onChange={e => { e.preventDefault(); changeProduct(e, i) }} /></td>
                                                    <td >
                                                        <input disabled value={product.taxPercent || 0} type="number" name="taxPercent" onChange={e => { e.preventDefault(); changeProduct(e, i) }} />
                                                    </td>
                                                    <td>
                                                        <input disabled type="text" className='text-end' value={Utilities.toDollar(lineTotal(product)).toString()} />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        {invoice.items && invoice.items.filter(product => { return product.itemType === 1 }).length > 0 && <tr className="thead m-0">
                                            <th colSpan={5} className='text-end'>Product Total</th>
                                            <th colSpan={1} className='text-end pe-4'>{Utilities.toDollar(totalProducts || 0)}</th>
                                        </tr>}
                                        {invoice.items && invoice.items.filter(product => { return product.itemType === 2 }).length > 0 &&
                                            <tr className="thead">
                                                <th>Services</th>
                                                <th>Unit Rate</th>
                                                <th>Quantity</th>
                                                <th>Discount</th>
                                                <th>Tax(%)</th>
                                                <th className='text-end pe-4'>Amount</th>
                                            </tr>}
                                        {invoice.items && invoice.items.filter(product => { return product.itemType === 2 }).map((service, i) => {
                                            return (
                                                <tr>
                                                    <td className='pb-0 pt-3'>{service.name}</td>
                                                    <td>
                                                        <div className='input-group align-items-center'>
                                                            {/* <i className='icon dollar small' /> */}
                                                            <input disabled value={Utilities.toDollar(service.unitPrice)} type="text" name="unitPrice" />
                                                        </div>
                                                    </td>
                                                    <td><input disabled value={service.quantity} type="number" name="quantity" /></td>
                                                    <td><input disabled value={service.discountType == 2 && service.discountPercent ? service.discountPercent + '%' : (service.discountAmount ? (service.discountType === 3 ? `Total ${Utilities.toDollar(service.discountAmount)}` : Utilities.toDollar(service.discountAmount)) : '$0.00')} type="text" name="discount" /></td>
                                                    <td><input disabled value={service.taxPercent || 0} type="number" name="taxPercent" /></td>
                                                    <td>
                                                        <input disabled type="text" className='text-end' value={Utilities.toDollar(lineTotal(service)).toString()} />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        {invoice.items && invoice.items.filter(product => { return product.itemType === 2 }).length > 0 && <tr className="thead">
                                            <th colSpan={5} className='text-end'>Services Total</th>
                                            <th colSpan={1} className='text-end pe-4'>{Utilities.toDollar(totalServices || 0)}</th>
                                        </tr>}
                                        {invoice.items && invoice.items.filter(fees => { return fees.itemType === 4 }).length > 0 &&
                                            <tr className="thead">
                                                <th>Fees</th>
                                                <th>Unit Rate</th>
                                                <th>Fees Charged($)</th>
                                                <th>Fees Charged(%)</th>
                                                <th>Tax(%)</th>
                                                <th className='text-end pe-4'>Amount</th>
                                            </tr>}
                                        {invoice.items && invoice.items.filter(fees => { return fees.itemType === 4 }).map((fee, i) => {
                                            return (

                                                <tr>
                                                    <td className='pb-0 pt-3'>{fee.name}</td>
                                                    <td>
                                                        <div className='input-group align-items-center'>
                                                            {/* <i className='icon dollar small' /> */}
                                                            <input disabled value={Utilities.toDollar(fee.unitPrice)} type="text" name="unitPrice" />
                                                        </div>
                                                    </td>
                                                    {/* {fee.feeAmount > 0 ? <td><input disabled value={fee.feeAmount || 0} type="number" name="feeAmount" /></td>
                                                        : <td><input disabled value={fee.feePercentage || 0} type="number" name="feePercentage" /></td>
                                                    } */}
                                                    <td><input disabled value={fee.feePercentage > 0 ? 0 : fee.unitPrice || 0} type="number" name="feeAmount" /></td>
                                                    <td><input disabled value={fee.feePercentage || 0} type="number" name="feePercentage" /></td>
                                                    <td><input disabled value={fee.taxPercent || 0} type="number" name="taxPercent" /></td>
                                                    <td>
                                                        <input disabled type="text" className='text-end' value={Utilities.toDollar(lineTotal(fee)).toString()} />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        {/* Prepaid Items */}
                                        {invoice.items && invoice.items.filter(product => { return product.itemType === 3 }).length > 0 &&
                                            <tr className="thead">
                                                <th colSpan={5}>Prepaid Item</th>
                                                <th colSpan={1} className="text-end pe-4">Amount</th>
                                            </tr>}
                                        {invoice.items && invoice.items.filter(product => { return product.itemType === 3 }).map((service, i) => {
                                            return (
                                                <tr>
                                                    <td colSpan={5} className='pb-0 pt-3'>{service.name}</td>
                                                    <td colSpan={1}>
                                                        <input disabled className='text-end' type="text" value={Utilities.toDollar(lineTotal(service)).toString()} />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        
                                        {invoice.items && invoice.items.filter(product => { return product.itemType === 4 }).length > 0 && <tr className="thead">
                                            <th colSpan={5} className='text-end'>Fees Total</th>
                                            <th colSpan={1} className='text-end pe-4'>{Utilities.toDollar(totalFees || 0)}</th>
                                        </tr>}
                                        {/* end prepaid items */}
                                        {totalDiscount > 0 || totalTax > 0 ? <tr className='thead'>
                                            <th colSpan={5} className='text-end'>Sub Total</th>
                                            <th colSpan={1} className='text-end pe-4'>{Utilities.toDollar(subTotal || 0)}</th>
                                        </tr> : null}
                                        {totalDiscount > 0 ? <tr className="thead">
                                            <th colSpan={5} className='text-end'>Total Discount</th>
                                            <th colSpan={1} className='text-end pe-4'>{Utilities.toDollar(totalDiscount || 0)}</th>
                                        </tr> : null}
                                        {totalTax > 0 && <tr className="thead">
                                            <th colSpan={5} className='text-end'>Total Tax</th>
                                            <th colSpan={1} className='text-end pe-4'>{Utilities.toDollar(totalTax || 0)}</th>
                                        </tr>}
                                        <tr className="thead">
                                            <th colSpan={5} className='text-end'>Total {invoice.invoiceType === 5 && 'Prepaid '}Amount</th>
                                            <th colSpan={1} className='text-end pe-4'>{Utilities.toDollar(total)}</th>
                                        </tr>
                                        {invoice.description && <tr>
                                            <td colspan={6}>
                                                Note: {invoice.description}
                                            </td>
                                        </tr>}
                                        <tr>
                                            <td colspan={6}>
                                                Status:  {invoice.invoiceStatus && <span
                                                    title={invoiceStatus.find(obj => obj.value == invoice.invoiceStatus)?.title}
                                                >{invoiceStatus.find(obj => obj.value == invoice.invoiceStatus)?.title}</span>}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                    }
                </div>
                {invoice && <div className='portrait' style={{ margin: '5px' }}>
                    <div className='row d-flex g-3 mt-1'>
                        {/* {invoice.invoiceStatus >= 6 && invoice.invoiceStatus <= 8 ? */}
                        <div className='card px-0 pb-0'>
                            <table className='table pagebreak'><tbody>
                                <tr className='thead'><th colSpan={6}>Payment History</th></tr>
                                <tr><td colSpan={6}>  <PaymentHistoryList paymentPlan={invoice} autoPull /></td></tr>
                                {invoice?.paymentPlanId!=null ? <> <tr className="thead">  <th colSpan={6}>Scheduled Payments</th></tr>
                                    <tr><td colSpan={6}>
                                        <PaymentsList paymentPlanId={invoice?.paymentPlanId ? invoice.paymentPlanId : null} patientId={invoice.patientId} autoPull />
                                    </td></tr></> : null}
                            </tbody>
                            </table>
                        </div>
                        {/* : null} */}
                    </div>
                </div>}
            </div>
            {
                !invoice &&
                <div className={`ui warning message mt-3 segment p-3 shadow-sm`}>
                    <span className="">
                        <p>Invoice Preview Not Found</p>
                    </span>
                </div>
            }
        </div >
    )
}
export default InvoicePreview