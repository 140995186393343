import moment from 'moment';
import React, { useEffect, useState, useContext } from 'react'
import { store } from '../../../../../context/StateProvider';
import CommonService from '../../../../../services/api/common.service'
import TransactionService from '../../../../../services/api/transaction.service';
import Utilities from '../../../../../services/commonservice/utilities';
import Module from '../../../../templates/components/Module';
import PracticeLocationSelector from '../../../../templates/components/PracticeLocationSelector';

import { ChannelTypeEnum as filters } from '../../../../../common/enum/channel-type.enum';
import Table2 from '../../../../templates/components/Table2';
import DatePicker from '../../../../templates/components/DatePicker';
import DimLoader from '../../../../templates/components/DimLoader'

const TransactionReportTable = (props) => {
    const [transactions, setTransactions] = useState()
    const [isLoader, setIsLoader] = useState(false)
    const [offsetHour, setOffsetHour] = useState(moment().utcOffset() >= 0 ? Math.floor(moment().utcOffset() / 60) : Math.ceil(moment().utcOffset() / 60),)
    const [offsetMinute, setOffsetMinute] = useState(moment().utcOffset() % 60)
    const [slabBy, setSlabBy] = useState('Month')
    const [startDate, setStartDate] = useState(moment().startOf("D"));
    const [endDate, setEndDate] = useState(moment().endOf("D"));
    const state = useContext(store).state
    const [initialMessage, setInitialMessage] = useState(true)
    const [locationId, setLocationId] = useState(state.practiceLocationId || null)

    // const [patientList, setPatientList] = useState()
    const transactionStatusList = [
        { 'statusName': 'Created', 'id': 0 },
        { 'statusName': 'Pending', 'id': 1 },
        { 'statusName': 'Authorized', 'id': 2 },
        { 'statusName': 'Posted', 'id': 3 },
        { 'statusName': 'Failed', 'id': 5 },
        { 'statusName': 'Void', 'id': 8 },
        { 'statusName': 'Refunded', 'id': 9 },
        { 'statusName': 'Approved', 'id': 10 },
        { 'statusName': 'Void attempted', 'id': 11 },
        { 'statusName': 'Refund attempted', 'id': 12 },
        { 'statusName': 'Hold', 'id': 13 },
        { 'statusName': 'Denied', 'id': 14 },
        { 'statusName': 'Success', 'id': 16 }, //Paid
        { 'statusName': 'Retried', 'id': 17 },
        { 'statusName': "Swipe Canceled", id: 20 },
        { 'statusName': "Partial Approved", id: 21 },
        { 'statusName': "Swipe Initiated", id: 22 },
        { 'statusName': 'Closed', 'id': 30 },
    ];

    // const for table 
    const columns = [
        // ["Name", "Equipment Type", "Room", "Actions"]
        {
            id: "transactionDate",
            Header: "Date",
            accessor: (tc) => moment(tc.transactionDate).format("MM-DD-YYYY h:mm A"),
            Footer: ()=>"Totals"
        },
        {
            id: "id",
            Header: "Transaction Id",
            accessor: (tc) => tc.id
        },
        {
            id: "channelType",
            Header: "Payment Method",
            align: "left",
            sortable: false,
            accessor: (tc) => filters.find(obj => obj.value === tc.tenderInfo.channelType)?.title
        },
        {
            id: "nameOnCard",
            Header: "Name on Account",
            accessor: (tc) => tc.tenderInfo.nameOnCheckOrCard
        },
        {
            id: "cardType",
            Header: "Account Type",
            accessor: (tc) => tc.tenderInfo.cardType ? tc.tenderInfo.cardType : tc.tenderInfo.bankName
        },
        {
            id: "lastFour",
            Header: "Account/Check Number",
            accessor: (tc) => tc.tenderInfo.maskCardNumber ? tc.tenderInfo.maskCardNumber : tc.tenderInfo.maskAccount ? tc.tenderInfo.maskAccount : tc.tenderInfo.checkNumber
        },
        {
            id: "firstName",
            Header: "Patient First Name",
            accessor: (tc) => tc.firstName
        },
        {
            id: "lastName",
            Header: "Patient Last Name",
            accessor: (tc) => tc.lastName
        },
        {
            id: "transactionStatus",
            Header: "Status",
            accessor: (tc) => transactionStatusList.find(obj => obj.id === tc.transactionStatus)?.statusName ?transactionStatusList.find(obj => obj.id === tc.transactionStatus)?.statusName :tc.transactionStatus
        },
        {
            id: "totalAmount",
            Header: "Amount",
            accessor: (tc) => Utilities.toDollar(tc.tenderInfo.totalAmount),
            Footer: info => {
                // Only calculate total visits if rows change
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.tenderInfo.totalAmount + sum, 0),
                    [info.rows]
                )

                return <>{Utilities.toDollar(total)}</>
            },
        },
    ]

    const transactionLookup = () => {
        setInitialMessage(false);
        setIsLoader(true)
        let reqObj = {
            sortField: 'createdOn',
            Asc: 'false',
            // PageSize: 20,
            StartRow: 0,
            StartDate: moment(startDate).toISOString(),
            EndDate: moment(endDate).toISOString(),
        }
        if (locationId) reqObj.PracticeLocationIds = locationId;
        TransactionService.findTransaction(reqObj)
            .then(res => {
                console.log(res.data.data)
                setTransactions(res.data.data)
                return setIsLoader(false)
            })
    }


    return (
        <>
        {isLoader &&
            <DimLoader loadMessage="Processing" />
        }    
    
        <div className='row d-flex g-3'>
            <div className='col-12 m-p-0'>
                <Module title="Filters">
                    <div className='row d-flex'>
                        <div className='col-12 row d-flex justify-content-center mx-0 align-items-end report-filters'>
                        <DatePicker startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} setLocationId={setLocationId}/>
                            <div className="col-md-auto col-12">
                                <button className="btn btn-primary" onClick={e => { e.preventDefault(); transactionLookup() }} title="Pull Report">
                                    <i className="icon arrow circle right" />
                                </button>
                            </div>
                        </div>
                    </div>
                </Module>
            </div>
            <div className='col-12 m-p-0'>
                <Module title="Transaction Report">
                    <Table2 export searchable title="Transaction Report" data={transactions} columns={columns} loading={isLoader} initialMessage={initialMessage}/>
                </Module>
            </div>
        </div>
        </>
    )
}

export default TransactionReportTable