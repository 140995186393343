import moment from "moment";
import React, { useState, useEffect } from "react";
import NotificationService from "../../../../../../../services/api/notification.service";
import ModalBox from "../../../../../../templates/components/ModalBox";
import Table2 from "../../../../../../templates/components/Table2";
import AppointmentPreview from '../appointment-list/AppointmentPreview'
const CommunicationList = (props) => {
  const [communicationList, setCommunicationList] = useState()
  const [isLoader, setIsLoader] = useState(false)
  const [view, setView] = useState(false)
  const [viewData, setViewData] = useState()
  const [appointment, setAppointment] = useState()
  const [viewAppt, setViewAppt] = useState(false)
  const hiddenColumns = ["createdOn","description"]
  const columns = [
    {
      id: "createdOn",
      Header: "Sent On",
      accessor: (cell) => moment(cell.createdOn).format("M-D-YYYY H:mm A")
    },
    {
      id: "templateName",
      Header: "Template",
      accessor: cell=>cell.templateName
    },
    {
      id: "notificationMethod",
      Header: "Method",
      accessor: cell=>cell.notificationMethod
    },
    {
      id: "description",
      Header: "Description",
      accessor: cell=>cell.description
    },
    {
      id: "action",
      Header: "Actions",
      disableSortBy: true,
      accessor: (cell) => {
        // console.log(cell);
        return (<span className="w-100 d-flex justify-content-center">
          <div className="col-auto btn-group">
            <button title="View Notification" className="btn btn-primary" onClick={e => { e.preventDefault(); setViewData(cell); return setView(true) }}><i className="icon eye" /></button>
            {cell.description.includes("Appointment") && <button title="View Associated Appointment" className="btn btn-primary" onClick={e => { e.preventDefault(); setAppointment(cell.appointmentId); return setViewAppt(true) }}><i className="icon calendar alternate outline" /></button>}
          </div>
        </span>)
      }
    },
  ]

  useEffect(() => {
    if (props.patientId) {
      if (props.autoPull) {
        pullCommunications();
      } else if (props.pull) {
        pullCommunications();
      }
    }
  }, [props.autoPull, props.pull, props.idword,props.patientId]);

  const pullCommunications = () => {
    setIsLoader(true)
    let reqObj = { PatientId: props.patientId }
    NotificationService.communicationsLookup(reqObj)
      .then((res) => {
        console.log(res.data);
        setCommunicationList(res.data.sort((a, b) =>b.createdOn.localeCompare(a.createdOn)))
        setIsLoader(false)
      })
      .catch((err) => {
        console.log(err);
        setIsLoader(false)
      });
  };

  return (
    <div>
      <Table2 hiddenColumns={hiddenColumns} data={communicationList} loading={isLoader} columns={columns}/>
      <ModalBox open={view} onClose={() => { setView(false) }} title={viewData?.description}>
        <div dangerouslySetInnerHTML={{ __html: viewData?.message }} />
      </ModalBox>
      <ModalBox open={viewAppt} onClose={() => { setViewAppt(false) }} title="Appointment Preview">
        {viewAppt && appointment ? <AppointmentPreview id={appointment} /> : null}
      </ModalBox>
    </div>
  );
};

export default CommunicationList;
