
import React from 'react'
import PasswordSettings from './password-settings/PasswordSettings'
import Module from '../../../../templates/components/Module'
import TextNotificationSettings from './notification-settings/TextNotificationSettings'

const AccountSettings = (props) => {

    return (
        <>
            <Module title="">
                <PasswordSettings onClose={false} />
                <TextNotificationSettings />
            </Module>
        </>
    )
}

export default AccountSettings