import React, { useState, useEffect } from 'react'
import AppointmentService from '../../../../../services/api/appointment.service'
import PracticeLocationForm from '../location-form/PracticeLocationForm'
import APIResponse from '../../../../templates/components/APIResponse'
import DimLoader from '../../../../templates/components/DimLoader'

const PracticeLocationEdit = (props) => {
    const {locationId } = props
    const [locationFormData, setLocationFormData] = useState()
    const [apiResponse, setApiResponse] = useState()
    const [isLoader, setIsLoader] = useState(false)
    useEffect(() => {
        setIsLoader(true)
        AppointmentService.getLocationById(props.locationId)
            .then(res => {
                // console.log(res)
                setLocationFormData(res)
                setIsLoader(false)
            })
            .catch(err => {
                setIsLoader(false)
            })
    }, [])

    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }

    // console.log(userFormData)
    const submitHandler = (data) => {
        setIsLoader(true)
        let newObj = { ...data }
        delete newObj.createdOn
        delete newObj.createdBy
        delete newObj.isDeleted
        delete newObj.modifiedBy
        delete newObj.modifiedOn
        AppointmentService.editPracticeLocation(newObj, locationId)
            .then(response => {
                setApiResponse(response)
            })
            .catch(error => {
                setApiResponse(error)
            })
            .finally(() => {
                setIsLoader(false)
            });
    }
    return (
        <div>
            {isLoader &&
                <DimLoader loadMessage="Processing" />
            }
            {locationFormData && <PracticeLocationForm submitHandler={submitHandler} initialFormData={locationFormData} onClose={() => { props.onClose() }}/>}
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </div>

    )
}

export default PracticeLocationEdit