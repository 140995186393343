import './AppointmentSchedule.scss';

import React, { useEffect, useState, useContext } from 'react'

// Npm packages
import moment from 'moment'
import Select from 'react-select'
import * as moment1 from "moment-timezone";
import TimeZoneEnum from '../../../../../../common/enum/time-zone.enum'
import { store } from '../../../../../../context/StateProvider'
import { useMediaQuery } from 'react-responsive';
// Templates 
import Module from '../../../../../templates/components/Module';
import ModalBox from '../../../../../templates/components/ModalBox';
import AppointmentForm from '../../appointment-form/AppointmentForm';
import UnavailableBlockForm from '../../../unavailable-block/unavailable-block-form/UnavailableBlockForm';

// Calendar Components 
import { Calendar as RBCal, momentLocalizer } from "react-big-calendar";
import Calendar from 'react-calendar'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import EventTile from '../../event-tile/EventTile';
import { useNavigate } from 'react-router-dom';
// Constants 

// Services 
import AppointmentService from '../../../../../../services/api/appointment.service';
import DoctorService from '../../../../../../services/api/doctor.service'
import CommonService from '../../../../../../services/api/common.service';
import PracticeLocationSelector from '../../../../../templates/components/PracticeLocationSelector';
import Utilities from '../../../../../../services/commonservice/utilities';
import PracticeServiceTypeService from '../../../../../../services/api/practice-service-type.service';
import toast from 'react-hot-toast';
import PracticePatientService from '../../../../../../services/api/patient-type.service';
import Table2 from '../../../../../templates/components/Table2';
import PracticeLocationAvailabilityService from '../../../../../../services/api/practice-location-availablity.service';
import APIResponse from '../../../../../templates/components/APIResponse';
import { 
    useGetPracticePatientTypes, 
    useGetLocationEquipment, 
    useGetLocationRooms,
    useGetLocationServiceTypes,
    useGetLocationProviderServices,
    useGetLocationAvailability
 } from "../../../../../../services/cache/CacheService";

const AppointmentSchedule = (props) => {
    // context state and dispatch 
    const globalStateAndDispatch = useContext(store)
    const state = globalStateAndDispatch.state
    const dispatch = globalStateAndDispatch.dispatch
    const isMobile = useMediaQuery({ query: `(max-width: 576px)` });
    const navigate = useNavigate()
    const [toasterMessage, setToasterMessage] = useState()
    // filter lists
    const [doctorList, setDoctorList] = useState()
    const rawDoctorList = useGetLocationProviderServices()

    const [formObjective, setFormObjective] = useState()

    // filter states 
    const [selectedDoctorList, setSelectedDoctorList] = useState()
    const [selectedLocation, setSelectedLocation] = useState([]) //Only one

    // event update data 
    const [initialData, setInitialData] = useState(props.initialData || {})
    const patientTypes = useGetPracticePatientTypes()

    // Appointment Edits modal control 
    const [showAdd, setShowAdd] = useState(false)
    const [showEdit, setShowEdit] = useState(false)

    // calendar data
    const [events, setEvents] = useState([])
    const [fromDate, setFromDate] = useState(new Date(moment().startOf('d')))
    const [toDate, setToDate] = useState(new Date(moment().add(1, 'd').startOf('d')))
    const [date, setDate] = useState(new Date())
    const [view, setView] = useState("day")
    const [skipInitialRender, setSkipInitialRender] = useState(true)
    const [openTime, setOpenTime] = useState(moment().hour(7).minutes(0))
    const [closeTime, setCloseTime] = useState(moment().hour(22).minutes(0))
    const localizer = momentLocalizer(moment);
    const DnDCalendar = withDragAndDrop(RBCal)


    // controls for resource views
    const [isLoader_Equipment, setIsLoader_Equipment] = useState(false)
    const [isLoader_Room, setIsLoader_Room] = useState(false)
    const [isLoader_Availability, setIsLoader_Availability] = useState(false)

    // Calendar resource constants 
    const [selectedResource, setSelectedResource] = useState(props.resource)
    const equipmentList = useGetLocationEquipment()
    const roomList = useGetLocationRooms()
    const [selectedEquipmentList, setSelectedEquipmentList] = useState()
    const [selectedRoomList, setSelectedRoomList] = useState()
    const [patientList, setPatientList] = useState()
    const [selectedPatient, setSelectedPatient] = useState()
    const serviceList = useGetLocationServiceTypes()
    const [selectedService, setSelectedService] = useState()
    const [addUnavailableBlock , setAddUnavailableBlock ] = useState()
    const [availableList, setAvailableList] = useState()
    const [expand, setExpand] = useState(true)
    const [practiceAvailability, setPracticeAvailability] = useState()
    const rawPracticeAvailability = useGetLocationAvailability()
    const [aptDisabled, setAptDisabled] = useState(false)
    const [apiResponse, setApiResponse] = useState()
    const [apiAutoAssignResponse, setApiAutoAssignResponse] = useState()
    const [apiUpdateAllResponse, setApiUpdateAllResponse] = useState()

    const [aptDoctorList, setAptDoctorList] = useState([])
    const [newAptDoctorList, setNewAptDoctorList] = useState([])
    const [unassignedAptList, setUnassignedAptList] = useState([])
    const [isLoader_location, setIsLoader_location] = useState(false)

    const [screenLocked, setScreenLocked] = useState(props?.shouldBlock || false);
    const forceUnlockScreen = () => {
        navigate('/provider');
    }

    useEffect(() => {
        let newArray1 = aptDoctorList.filter(event => event.doctorId === '999999999');
        setUnassignedAptList([...newArray1])
    }, [aptDoctorList])
    
    useEffect(() => {
        if (practiceAvailability) {
            calculateOpenClose()
        }
    }, [view, date, practiceAvailability])

    useEffect(() => {
        if (rawPracticeAvailability) {
            setPracticeAvailability(rawPracticeAvailability.sort((a, b) => (a.dow > b.dow) ? 1 : ((b.dow > a.dow) ? -1 : 0)))
        }
    }, [rawPracticeAvailability])

    useEffect(() => {
        if (selectedResource == "dayview") {
            let newArray = events.map(event => ({ appointmentId: event.id, doctorId: event.doctorId }));
            setAptDoctorList(newArray)
        }
    }, [events])

    const columns = [
        {
            id: "date",
            Header: "Date",
            accessor: (slot) => isMobile ? moment.utc(slot.startDate).format("M/D") + ' at ' + moment.utc(slot.startDate.slice(0, 10) + "T" + slot.startTime).format("h:mm a") : moment.utc(slot.startDate).format("dddd, MMMM D") + ' at ' + moment.utc(slot.startDate.slice(0, 10) + "T" + slot.startTime).format("h:mm a")
        },
        {
            id: "duration",
            Header: "Duration",

            accessor: (slot) => `${slot.duration} mins`
        },
        {
            id: "drName",
            Header: "Provider",
            accessor: slot => {
                if (isMobile) {
                    let doctorArray = slot.drName.split(" ")
                    return doctorArray[0] + ' ' + doctorArray[doctorArray.length - 1].substr(0, 1) + '.'
                }
                else { return slot.drName }
            }
        },
        {
            id: "practiceServiceTypeId",
            Header: "Services",
            accessor: (slot) => {
                let length = doctorList?.find(ob => ob.id === slot.doctorId)?.services?.length
                return (
                    selectedService ? <span>{selectedService.practiceServiceType}</span> : doctorList?.find(ob => ob.id === slot.doctorId)?.services?.slice(0, 3).map((service, i) => {
                        if (2 === i && length > 3) {
                            return <span>{i !== 0 && ','} {service.practiceServiceType} and {length - 3} more</span>
                        }
                        else {
                            return <span>{i !== 0 && ','} {service.practiceServiceType}</span>
                        }
                    }
                    )
                )
            }
        },
        {
            id: "actions",
            Header: "Book",
            disableSortBy: true,
            accessor: (slot) => <div className='w-100 d-flex justify-content-center'><button className='btn btn-transparent p-0' onClick={e => { e.preventDefault(); toggleAppointment({ start: moment(slot.startDate.slice(0, 10) + "T" + slot.startTime.slice(0, 5)).toISOString(), duration: 30, doctorId: slot.doctorId }) }}><i className="icon plus text-primary" /></button></div>
        },
    ]

    const dayViewColumns = [
        {
            id: "patientId",
            Header: "Patient",
            accessor: (slot) => `${slot.firstName + ' ' + slot.lastName}`
        },
        {
            id: "fromDate",
            Header: "Start",
            accessor: (slot) => moment.utc(slot.fromDate).format("h:mm a")
        },
        {
            id: "duration",
            Header: "Duration",
            accessor: (slot) => `${moment.duration(moment(slot.toDate).diff(moment(slot.fromDate))).asMinutes()} mins`
        },
        {
            id: "toDate",
            Header: "End",
            accessor: (slot) => moment.utc(slot.toDate).format("h:mm a")
        },
        {
            id: "drName",
            Header: "Provider",
            accessor: slot => <div className='w-100 d-flex justify-content-center'>
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={doctorList}
                    isDisabled={!doctorList}
                    //isClearable
                    isSearchable
                    name="doctorId"
                    isLoading={isLoader}
                    value={doctorList ?
                        (newAptDoctorList && newAptDoctorList.find(aptDoc => (aptDoc.appointmentId === slot.id))) ? doctorList.find(obj => obj.doctorId === newAptDoctorList.find(aptDoc => (aptDoc.appointmentId === slot.id)).doctorId)
                            : ((aptDoctorList && aptDoctorList.find(aptDoc => (aptDoc.appointmentId === slot.id))) ? doctorList.find(obj => obj.doctorId === aptDoctorList.find(aptDoc => (aptDoc.appointmentId === slot.id)).doctorId) : null) : null}
                    onChange={e => {
                        let getApt = aptDoctorList.find(obj => obj.appointmentId === slot.id)
                        if (getApt.doctorId !== e.doctorId) {
                            let newAptDoc = newAptDoctorList.find(obj => obj.appointmentId === slot.id)
                            if (newAptDoc) {
                                const alterList = newAptDoctorList.map(obj1 => {
                                    if (obj1.appointmentId === slot.id) {
                                        // change doctor id
                                        return {
                                            ...obj1,
                                            doctorId: e.doctorId,
                                            isProviderNotAvailable: false
                                        }
                                    } else {
                                        // no change
                                        return obj1
                                    }
                                });
                                setNewAptDoctorList(alterList)
                            } else {
                                setNewAptDoctorList([...newAptDoctorList, { appointmentId: slot.id, doctorId: e.doctorId }])
                            }
                        } else {
                            //if apt is present in newAptDoc list with same doctor id delete that record from newAptDoclist
                            let newList = newAptDoctorList.filter((obj) => (obj.appointmentId !== slot.id))
                            setNewAptDoctorList([...newList])
                        }
                    }}
                    getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
                    getOptionValue={(option) => option.doctorId}
                />
            </div>
        },
        {
            id: "practiceServiceTypeId",
            Header: "Services",
            accessor: (slot) => slot.practiceServiceType
        },
        {
            id: "actions",
            Header: "Action",
            disableSortBy: true,
            accessor: (slot) => <>{newAptDoctorList && newAptDoctorList.find(obj => obj.appointmentId === slot.id) ? (newAptDoctorList.find(obj => obj.appointmentId === slot.id).isProviderNotAvailable ? <span style={{color:'red'}}>*No Provider Available</span>:<span>*</span>) : null}</>
        },
    ]
    // Setting Open/Close 
    const calculateOpenClose = () => {
        if (view === 'day') {
            let currentDow;
            switch (moment(date).day()) {
                case 1:
                    currentDow = 2
                    break;
                case 2:
                    currentDow = 3
                    break;
                case 3:
                    currentDow = 4
                    break;
                case 4:
                    currentDow = 5
                    break;
                case 5:
                    currentDow = 6
                    break;
                case 6:
                    currentDow = 7
                    break;
                case 7:
                    currentDow = 1
                    break;
                default:
                    currentDow = 2
                    break;
            }
            let newthing = practiceAvailability.find(obj => obj.dow == currentDow)
            if (newthing?.length > 1) {
                setHours(newthing)
            }
            else if (newthing) {
                let startTimeArray = newthing.startTime.split(":")
                let endTimeArray = newthing.endTime.split(":")
                // todo
                // setOpenTime(moment.utc().hour(startTimeArray[0]).minute(startTimeArray[1]).tz(moment1.tz.guess()))
                // setCloseTime(moment.utc().hour(endTimeArray[0]).minute(endTimeArray[1]).tz(moment1.tz.guess()))
            }
        }
        else {
            setHours(practiceAvailability)
        }
    }

    const setHours = (array) => {
        let earliest;
        let latest;
        array.forEach(obj => {
            let startTimeArray = obj.startTime.split(":")
            let start = moment.utc().hour(startTimeArray[0]).minute(startTimeArray[1]).tz(moment1.tz.guess())
            let endTimeArray = obj.endTime.split(":")
            let end = moment.utc().hour(endTimeArray[0]).minute(endTimeArray[1]).tz(moment1.tz.guess())
            if (!earliest) {
                earliest = start
            }
            else {
                if (start < earliest) {
                    earliest = start
                }
            }
            if (!latest) {
                latest = end
            }
            else {
                if (end > latest) {
                    latest = end
                }
            }
        })
        setOpenTime(earliest)
        setCloseTime(latest)
    }

    const autoAssignProvider = () => {
        setIsLoader(true);
        let reqObj = {
            PracticeLocationId: state.practiceLocationId,
            ForDate: moment(fromDate).format("YYYY-MM-DD")
        }
        AppointmentService.autoAssignProvider(reqObj)
            .then((response) => {
                setApiAutoAssignResponse(response)
            })
            .catch(error => {
                setApiAutoAssignResponse(error)
            }).finally(() => {
                setIsLoader(false);
            })
    }

    const updateAptsProvider = () => {
        setIsLoader(true);
        AppointmentService.updateAptsProvider(newAptDoctorList.map(obj => {
            if (obj.doctorId === '999999999') {
                obj.doctorId = ''
                return obj
            } else return obj
        }))
            .then((response) => {
                setApiUpdateAllResponse(response)
                setIsLoader(false);
            })
            .catch(error => {
                setIsLoader(false);
                setApiUpdateAllResponse(error)
            })
    }
    const getPracticeAvailability = () => {
        PracticeLocationAvailabilityService.practiceLocationAvailablity({ PracticeLocationId: state.practiceLocationId })
            .then((response) => {
                response.selectResponse.sort((a, b) => (a.dow > b.dow) ? 1 : ((b.dow > a.dow) ? -1 : 0))
                console.log(response.selectResponse)
                setPracticeAvailability(response.selectResponse.sort((a, b) => (a.dow > b.dow) ? 1 : ((b.dow > a.dow) ? -1 : 0)))
                setIsLoader(false);
            })
            .catch(error => {
                setIsLoader(false);
                console.log(error)
                setPracticeAvailability()
            })
    }
    const practicePatientLookup = () => {
        PracticePatientService.practicePatientLookup(state.practiceLocationId)
            .then((response) => {
                setPatientTypes(response.data.selectResponse);
            })
            .catch(error => {
                console.log(error)
                // setCheckException(error);
            })
    }


    useEffect(() => {
        setSelectedResource(props.resource)
    }, [props.resource])

    const refresh = () => {
        setView("day")
        setSelectedDoctorList(false)
        selectDate(new Date())
        return navigate('/provider/schedule')
    }

    const patientLookup = () => {
        let reqObj = { SearchTerm: '', isActive: true, isRegistered: true, SortField: 'firstName' }
        CommonService.patientLookup(reqObj)
            .then(res => {
                if (res) {
                    console.log(res.data)
                    return setPatientList(res.data)
                }
            }
            )
            .catch(err => console.log(err))
    }

    const callCheckAvailability = (newDate) => {
        setIsLoader_Availability(true)
        let reqObj = {
            calendarDate: newDate ? moment(newDate).format("YYYY-MM-DD") : moment(date).format("YYYY-MM-DD"),
            doctorIds: selectedDoctorList ? selectedDoctorList.map(doctor => { return doctor.id }).toString() : '',
            equipmentTypeId: selectedEquipmentList ? selectedEquipmentList.map(equipment => { return equipment.id }).toString() : '',
            practiceLocationId: state.practiceLocationId || '',
            serviceTypeId: selectedService ? selectedService.practiceServiceTypeId : ''
        }
        if (selectedDoctorList) {
            console.log(selectedDoctorList)
        }
        AppointmentService
            .checkAvailability(reqObj)
            .then(
                (appointmentResponse) => {
                    setAvailableList(appointmentResponse)
                    console.log(appointmentResponse)
                    setIsLoader_Availability(false)
                    //   this.isLoader_EventList = true;
                })
            .catch(
                (error) => {
                    setIsLoader_Availability(false)
                    //   this.isLoader_EventList = true;
                    console.log(error);
                }
            );
    }

    const practiceLocationRoomLookup = () => {
        setRoomList([])
        setSelectedRoomList()
        setIsLoader_Room(true)
        AppointmentService.practiceLocationRoomLookup(state.practiceLocationId)
            .then((response) => {
                setRoomList(response)
                setIsLoader_Room(false)
            })
            .catch((error) => {
                setIsLoader_Room(false)
                setRoomList([])
                setSelectedRoomList()
                console.log(error)
                // this.checkException(error);
            }
            )
    }
    const equipmentLookup = () => {
        setEquipmentList([])
        setSelectedEquipmentList()
        setIsLoader_Equipment(true)
        // AppointmentService.getAvailableEquipmentsForLocationAndService(searchParams)
        AppointmentService.equipmentLookup(state.practiceLocationId)
            .then((response) => {
                // console.log(response)
                setEquipmentList(response)
                // setSelectedEquipmentList(response)
                setIsLoader_Equipment(false)
            })
            .catch((error) => {
                setEquipmentList([])
                setSelectedEquipmentList()
                console.log(error)
                setIsLoader_Equipment(false)
            }
            )
    }

    // form Data 


    const [noResultsMessage, setNoResultsMessage] = useState(undefined)

    // loaders
    const [isLoader, setIsLoader] = useState(false)
    const [isLoader_findAppointment, setIsLoader_findAppointment] = useState(false)

    // Form formulas 
    const doctorChange = (e) => {
        if (e.length !== 0) { setSelectedDoctorList(e); }
        else { setSelectedDoctorList(); setView("day") }
    };
    const equipmentChange = (e) => {
        console.log(e)
        if (e.length !== 0) { setSelectedEquipmentList(e); }
        else { setSelectedEquipmentList() }
    };
    const roomChange = (e) => {
        if (e.length !== 0) { setSelectedRoomList(e); }
        else { setSelectedRoomList() }
    };

    //API pulls


    const setParams = () => {
        let doctorIds = []
        if (selectedDoctorList) {
            selectedDoctorList.map(element => {
                return doctorIds.push(element.id)
            })
        }
        else {
            doctorIds = ""
        }
        let location = state.practiceLocationId
        return {
            DoctorIds: doctorIds.toString(), Location: location, FromDate: new Date(fromDate).toISOString(), ToDate: new Date(toDate).toISOString()
        }
    }

    // calendar change formulas 
    const navigateCalendar = (data) => {
        let newFromDate;
        let newToDate;
        let newDate;
        if (data.action == 'next') {
            newDate = new Date(moment(date).add(1, "w"))
            // newFromDate = new Date(moment(date).add(1, "w").startOf("w"))
            // newToDate = new Date(moment(date).add(1, "w").endOf("w"))
        }
        else if (data.action == 'next2') {
            newDate = new Date(moment(date).add(1, "M"))
            // newFromDate = new Date(moment(date).add(1, "M").startOf("w"))
            // newToDate = new Date(moment(date).add(1, "M").endOf("w"))
        }
        else if (data.action == 'prev') {
            newDate = new Date(moment(date).subtract(1, "w"))
            // newFromDate = new Date(moment(date).subtract(1, "w").startOf("w"))
            // newToDate = new Date(moment(date).subtract(1, "w").endOf("w"))
        }
        else if (data.action == 'prev2') {
            newDate = new Date(moment(date).subtract(1, "M"))
            // newFromDate = new Date(moment(date).subtract(1, "M").startOf("w"))
            // newToDate = new Date(moment(date).subtract(1, "M").startOf("w"))
        }
        if (view == 'week') {
            newFromDate = new Date(moment(newDate).startOf("w"))
            newToDate = new Date(moment(newDate).endOf("w"))
        }
        else {
            newFromDate = new Date(moment(newDate).startOf("d"))
            newToDate = new Date(moment(newDate).add(1, "d").startOf("d"))
        }
        setDate(newDate)
        setFromDate(newFromDate)
        setToDate(newToDate)
        if (selectedResource == "availability") {
            return callCheckAvailability()
        }
        else if (selectedResource == "dayview") {
            setView("day")
            return fetchAppointment(newFromDate, newToDate)
        }
        else {
            return fetchAppointment(newFromDate, newToDate)
        }
    }

    const selectDate = (newDate) => {
        console.log("select date", newDate)
        let fromDate;
        let toDate;
        if (view == "week") {
            fromDate = new Date(moment(newDate).startOf("w"))
            toDate = new Date(moment(newDate).endOf("w"))
        }
        else if (view == "day") {
            fromDate = new Date(moment(newDate).startOf("d"))
            toDate = new Date(moment(newDate).add(1, "d").startOf("d"))
        }
        else if (view == "month") {
            fromDate = new Date(moment(newDate).startOf("M"))
            toDate = new Date(moment(newDate).add(1, "M").startOf("d"))
        }
        else {
            fromDate = new Date(moment(newDate).startOf("d"))
            toDate = new Date(moment(newDate).add(1, "M").startOf("d"))
        }
        setDate(newDate)
        setFromDate(fromDate)
        setToDate(toDate)
        return fetchAppointment(fromDate, toDate)
    }

    const startDateChange = (data) => {
        let newDate;
        let toDate;
        let fromDate;
        if (data.action == 'next') {
            newDate = new Date(moment(date).add(1, "w"))
        }
        else if (data.action == 'next2') {
            newDate = new Date(moment(date).add(1, "M"))
        }
        else if (data.action == 'prev') {
            newDate = new Date(moment(date).subtract(1, "w"))
        }
        else if (data.action == 'prev2') {
            newDate = new Date(moment(date).subtract(1, "M"))
        }
        else{
            newDate = new Date(data.value)
        }
        if (view == "week") {
            fromDate = new Date(moment(newDate).startOf("w"))
            toDate = new Date(moment(newDate).endOf("w"))
        }
        else if (view == "day") {
            fromDate = new Date(moment(newDate).startOf("d"))
            toDate = new Date(moment(newDate).add(1, "d").startOf("d"))
        }
        else if (view == "month") {
            fromDate = new Date(moment(newDate).startOf("M"))
            toDate = new Date(moment(newDate).add(1, "M").startOf("d"))
        }
        setDate(newDate)
        setFromDate(fromDate)
        setToDate(toDate)
        if (selectedResource == "availability") {
            return callCheckAvailability(newDate)
        }
        else {
            return fetchAppointment(fromDate, toDate)
        }
    }

    useEffect(() => {
        if (!skipInitialRender){
            selectDate(date)
        }
        setSkipInitialRender(false);
    }, [view])

    const getPracticeServiceTypeForLocation = () => {
        PracticeServiceTypeService.practiceServiceTypeLookup()
            .then(
                (response) => {
                    console.log(response.data)
                    setServiceList(response.data)
                })
            .catch((error) => {
            }
            );
    }

    useEffect(() => {
        if (state?.practiceLocationId) {
            if(selectedResource == 'dayview'){
                setEvents([]) //resetting appointment list
            }
        }
    }, [state?.practiceLocationId])

    useEffect(() => {
        if (doctorList?.length > 0) {
            if (selectedResource == "availability") {
                return callCheckAvailability()
            }
            else if (selectedResource == "dayview") {
                setView("day")
                return fetchAppointment()
            }
            else {
                return fetchAppointment()
            }
        }
    }, [doctorList])
    useEffect(() => {
        if (rawDoctorList?.length > 0) {
            let newDoctorList = [...rawDoctorList];
            newDoctorList.sort((a, b) => a.firstName.localeCompare(b.firstName));
            newDoctorList.unshift(
            {
                id: "999999999",
                doctorId: "999999999",
                firstName: "Unassigned",
                lastName: ""
            });
            setDoctorList(newDoctorList);
        }
    }, [rawDoctorList])

    useEffect(() => {
        if (state?.practiceLocationId) {
            if (selectedResource === "availability") {
                patientLookup()
                callCheckAvailability()
            }
            else if (selectedResource == 'dayview') {
                let newArray = events.map(event => ({ appointmentId: event.id, doctorId: event.doctorId }));
                setAptDoctorList(newArray)
                setNewAptDoctorList([])
            }
        }
    }, [selectedResource])

    useEffect(() => {
        if (selectedResource === "availability") {
            callCheckAvailability()
        }
    }, [selectedDoctorList, selectedService])

    // Appointment API pull 
    const fetchAppointment = (newFromDate, newToDate) => {
        setIsLoader_findAppointment(true);
        let fromISO = Utilities.representLocalDateAsUTCMidnightISO(newFromDate || fromDate);
        let toISO = Utilities.representLocalDateAsUTCMidnightISO(newToDate || toDate);
        let searchParams = {
            FromDate: fromISO,
            ToDate: toISO,
            Location: state.practiceLocationId
        }
        AppointmentService.findAppointment(searchParams)
            .then((findAppointmentResponse) => {
                if (findAppointmentResponse?.length == 0 || !findAppointmentResponse) {
                    setNoResultsMessage("No results found")
                    setEvents([])
                } else {
                    let appointmentList = findAppointmentResponse
                    let tempEvents = []
                    const appointmentLister = () => {
                        appointmentList.forEach((element) => {
                            let fullName = "";
                            let toolTipTimeStart = "";
                            let toolTipTimeEnd = "";
                            fullName =
                                element.firstName != null
                                    ? `${fullName} ${element.firstName}`
                                    : `${fullName}`;
                            fullName =
                                element.lastName != null
                                    ? `${fullName} ${element.lastName}`
                                    : `${fullName}`;
                            element.fullName = fullName;
                            element.doctorId = element.doctorId == '' ? '999999999' : element.doctorId;
                            let indexDoctor = doctorList.findIndex(
                                (x) => x.id === element.doctorId
                            );
                            toolTipTimeStart = moment(element.fromDate).format('h:mm A')
                            toolTipTimeEnd = moment(element.toDate).format('h:mm A')
                            let start = new Date(element.fromDate)
                            let end = new Date(element.toDate)
                            tempEvents.push({
                                ...element,
                                isPopupOpen: false,
                                start: start,
                                end: end,
                                title: element?.firstName && element?.lastName ? element.firstName + ' ' + element.lastName.substr(0, 1) + '.' : element?.reasonForVisit ? element.reasonForVisit : 'Unavailable',
                                doctorId: element.doctorId,
                                toolTip: toolTipTimeStart + "-" + toolTipTimeEnd,
                                borderStyle: element.parentGroupId ? "dashed" : "solid",
                                fillColour:
                                    element.color !== null
                                        ? element.color
                                        : "white",
                                borderColour:
                                    element.serviceTypeFillColour !== null
                                        ? element.serviceTypeFillColour
                                        : "darkGrey",
                                popupText:
                                    +element.repeatOn > 0
                                        ? "@" +
                                        RecurringAppointmentEnum[element.repeatOn] +
                                        " " +
                                        element.aptTotalCount +
                                        " times"
                                        : "",
                                resizable: {
                                    beforeStart: true,
                                    afterEnd: true,
                                },
                                doctor: doctorList[indexDoctor],
                            })
                        })
                        return tempEvents
                    }
                    setEvents(appointmentLister())
                }
            })
            .catch((error) => {
                console.log(error);
            }
            ).finally(()=>{
                setIsLoader_findAppointment(false)
            });

    }

    const mobileSelect = (date) => {
        let newDate = new Date(date + 'T00:00:00')
        selectDate(newDate)
    }
    const toggleAppointment = (e) => {
        if (!state.permissions.schedulingModifyAppointments) return;
        if (false) { // (e.start < moment()) {
            toast.error("Appointment start time must be in the future")
        }
        else {
            setFormObjective("add")
            let newInitialData = {
                fromDate: moment(new Date(e.start)),
                toDate: e.duration ? moment(new Date(e.start)).add(e.duration, "m") : e.end ? moment(new Date(e.end)) : moment(new Date(e.start)).add(60, "m"),
                practiceLocationId: state.practiceLocationId,
                duration: e.duration ? e.duration : e.end ? moment(new Date(e.end)).diff(moment(new Date(e.start)), "minutes") : ''
            }
            if (selectedResource === "provider") {
                newInitialData.doctorId = e.resourceId
            }
            if (selectedResource === "equipment") {
                newInitialData.equipmentId = e.resourceId
            }
            if (selectedResource === "room") {
                newInitialData.practiceLocationRoomId = e.resourceId
            }
            if (selectedResource === "availability") {
                newInitialData.doctorId = e.doctorId
                if (selectedPatient) {
                    newInitialData.patientId = selectedPatient.id
                }
                if (Array.isArray(selectedEquipmentList) === false) {
                    newInitialData.equipmentId = selectedEquipmentList?.equipmentId
                }
                if (selectedService) {
                    newInitialData.practiceServiceTypeId = selectedService.practiceServiceTypeId
                }
            }
            setInitialData(newInitialData)
            return setShowAdd(true)
        }
    }

    const onSuccess = (message) => {
        fetchAppointment();
        return setShowAdd(false)
    }
    const onAutoAssignSuccess = (message) => {
        let newList = apiAutoAssignResponse.data?.map(obj => { return { appointmentId: obj.id, doctorId: obj.doctorId == '' ? '999999999' : obj.doctorId, isProviderNotAvailable: obj.doctorId == '' ? true : false } })
        setNewAptDoctorList(newList)
    }
    const onUpdateAllSuccess = (message) => {
        fetchAppointment();
        setNewAptDoctorList([])
    }
    const copyEvent = (newApt) => {
        if (!state.permissions.schedulingModifyAppointments) return;
        setFormObjective("add")
        setInitialData(newApt)
        setShowAdd(true)
    }
    const submitHandler = (data) => {
        setAptDisabled(true);
        if (formObjective === "add") {
            let reqObj = {
                "aptTotalCount": data.aptTotalCount,
                "checkEligibility": data.checkEligibility,
                "day": moment(data.fromDate).day(),
                "doctorId": data.doctorId && data.doctorId !== "" ? data.doctorId : undefined,
                "duration": data.duration,
                "email": data.email,
                "equipmentId": data.equipmentId && data.equipmentId !== "" ? data.equipmentId : undefined,
                "fromDate": Utilities.convertUISchedulingDateToDBFormat(data.fromDate),
                "memo": data.memo,
                "patientId": data.patientId,
                "patientInsuranceId": data.patientInsuranceId,
                "phone": data.patientPhone,
                "practiceAppointmentStatusCodeId": data.practiceAppointmentStatusCodeId,
                "practiceLocationId": data.practiceLocationId,
                "practiceLocationRoomId": data.practiceLocationRoomId && data.practiceLocationRoomId !== "" && data.practiceLocationRoomId !== "0rbd3W69" ? data.practiceLocationRoomId : undefined,
                "practicePatientTypeId": data.practicePatientTypeId,
                "practiceServiceTypeId": data.practiceServiceTypeId,
                "reasonForVisit": data.memo,
                "repeatOn": data.repeatOn,
                "timeZone": 58, // TimeZoneEnum[moment1.tz.guess()] != undefined ? TimeZoneEnum[moment1.tz.guess()] : TimeZoneEnum["Default"],
                "toDate": Utilities.convertUISchedulingDateToDBFormat(data.toDate),
            }
            if (data.childAppointments && data.childAppointments.length > 0) {
                //reset sequence value in correct order
                reqObj.childAppointments = data.childAppointments.map((item, index) => {
                    item.sequence = index + 1
                    item.doctorId = item.doctorId && item.doctorId !== "" ? item.doctorId : undefined
                    item.equipmentId = item.equipmentId && item.equipmentId !== "" ? item.equipmentId : undefined
                    item.practiceLocationRoomId = item.practiceLocationRoomId && item.practiceLocationRoomId !== "" && item.practiceLocationRoomId !== "0rbd3W69" ? item.practiceLocationRoomId : undefined
                    //delete item.sequenceId
                    return item
                })
            } else {
                reqObj.childAppointments = [];
            }
            return AppointmentService.addAppointment(reqObj)
                .then(res => { 
                    console.log(res); 
                    setAptDisabled(false);
                    setApiResponse(res);
                })
                .catch(err => { 
                    console.log(err); 
                    setAptDisabled(false);
                    setApiResponse(err);
                })
        }
        else {
            let reqObj = {
                "doctorId": data.doctorId && data.doctorId !== "" ? data.doctorId : undefined,
                "fromDate": Utilities.convertUISchedulingDateToDBFormat(data.fromDate),
                "toDate": Utilities.convertUISchedulingDateToDBFormat(data.toDate),
                "day": moment(data.fromDate).day(),
                "duration": data.duration,
                "repeatOn": data.repeatOn,
                "aptTotalCount": data.aptTotalCount,
                "memo": data.memo,
                "phone": data.patientPhone,
                "email": data.email,
                "patientId": data.patientId, //made required field
                "timeZone": 58, // data?.timeZone ? data.timeZone : TimeZoneEnum[moment1.tz.guess()] != undefined ? TimeZoneEnum[moment1.tz.guess()] : TimeZoneEnum["Default"],
                "checkEligibility": data.checkEligibility,
                "patientInsuranceId": data.patientInsuranceId,
                "practicePatientTypeId": data.practicePatientTypeId,
                "practiceLocationId": data.practiceLocationId,
                "practiceServiceTypeId": data.practiceServiceTypeId,
                "practiceAppointmentStatusCodeId": data.practiceAppointmentStatusCodeId,
                "equipmentId": data.equipmentId && data.equipmentId !== "" ? data.equipmentId : undefined,
                "reasonForVisit": data.memo,
                "id": data.id,
                "parentGroupId": data.parentGroupId //only in edit needed
            }
            if (data?.practiceLocationRoomId !== '0rbd3W69') {
                reqObj.practiceLocationRoomId = data.practiceLocationRoomId && data.practiceLocationRoomId !== "" ? data.practiceLocationRoomId : undefined
            }
            if (data.childAppointments && data.childAppointments.length > 0) {
                //reset sequence in correct order
                reqObj.childAppointments = data.childAppointments.map((item, index) => {
                    item.sequence = index + 1
                    item.doctorId = item.doctorId && item.doctorId !== "" ? item.doctorId : undefined
                    item.equipmentId = item.equipmentId && item.equipmentId !== "" ? item.equipmentId : undefined
                    item.practiceLocationRoomId = item.practiceLocationRoomId && item.practiceLocationRoomId !== "" && item?.practiceLocationRoomId !== '0rbd3W69' ? item.practiceLocationRoomId : undefined
                    //delete item.sequenceId
                    return item
                })
            } else {
                reqObj.childAppointments = [];
            }
            return AppointmentService.editAppointment(reqObj)
            .then(res => { 
                console.log(res); 
                    setAptDisabled(false);
                    setApiResponse(res);
                })
                .catch(err => {
                    setAptDisabled(false);
                    setApiResponse(err);
                })
        }
    }

    const onDrop = (e) => {
        if (!state.permissions.schedulingModifyAppointments) return;
        setFormObjective("edit")
        let tempData = e.event
        if (false) { // (e.start < moment()) {
            toast.error("Appointment start time must be in the future")
        }
        else {
            if (selectedResource === "provider") {
                tempData.doctorId = e.resourceId
            }
            if (selectedResource === "equipment") {
                tempData.equipmentId = e.resourceId
            }
            if (selectedResource === "room") {
                tempData.practiceLocationRoomId = e.resourceId
            }
            tempData.fromDate = new Date(e.start)
            tempData.toDate = new Date(e.end)
            tempData.visitStatus = 0
            setInitialData(tempData)
            return setShowAdd(true)
        }
    }

    const onEventResize = (e) => {
        if (!state.permissions.schedulingModifyAppointments) return;
        setFormObjective("edit")
        let tempData = e.event
        if (selectedResource === "provider") {
            tempData.doctorId = e.resourceId
        }
        if (selectedResource === "equipment") {
            tempData.equipmentId = e.resourceId
        }
        if (selectedResource === "room") {
            tempData.practiceLocationRoomId = e.resourceId
        }
        tempData.fromDate = new Date(e.start)
        tempData.toDate = new Date(e.end)
        delete tempData.duration
        setInitialData(tempData)
        return setShowAdd(true)
    }

    const toggleAdd = () => {
        if (showAdd) {
            setShowAdd(false)
        }
        else {
            setShowAdd(state.permissions.schedulingModifyAppointments)
        }
    }

    useEffect(() => {
        if (isMobile) {
            setView("day")
        }
    }, [isMobile, view])
    const Toolbar = (props) => {
        let today = new Date().toLocaleDateString();
        let currentDate = new Date(props.date).toLocaleDateString();
        return (
            <div className="rbc-toolbar row justify-content-between d-flex remove-gutters">
                {isMobile ? <span className="rbc-toolbar-label col mb-3">{props.label} | {selectedResource[0].toLocaleUpperCase()}{selectedResource.slice(1)} View</span> : null}
                <span className="rbc-btn-group col-2 d-flex justify-content-start" id="navigate-big-calendar">
                    <button type="button" onClick={e => { e.preventDefault(); if (view === "day") { selectDate(new Date(moment(date).subtract(1, "d"))) } else { selectDate(new Date(moment(date).subtract(1, "w"))) } }}>Prev</button>
                    <button type="button" onClick={e => { e.preventDefault(); selectDate(new Date()) }} className={today === currentDate && 'rbc-active'}>Today</button>
                    <button type="button" onClick={e => { e.preventDefault(); if (view === "day") { selectDate(new Date(moment(date).add(1, "d"))) } else { selectDate(new Date(moment(date).add(1, "w"))) } }}>Next</button>
                </span>
                {!isMobile ? <span className="rbc-toolbar-label col-8">{props.label} | {selectedResource[0].toLocaleUpperCase()}{selectedResource.slice(1)} View</span> : null}
                {!isMobile ? <span className="rbc-btn-group col-2 d-flex justify-content-end">
                    <button type="button" onClick={e => { e.preventDefault(); setView('week') }} className={props.view === 'week' && 'rbc-active'}>Week</button>
                    <button type="button" onClick={e => { e.preventDefault(); setView("day") }} className={props.view === 'day' && 'rbc-active'}>Day</button>
                </span> : null}
            </div>
        )
    }

    const EventTileTemplate = (props) => {
        return <EventTile readOnly={screenLocked} copyEvent={copyEvent} {...props} onCopyUnavailable={copyUnavailable} onEventUpdate={onEventUpdate} resource={selectedResource} refresh={() => { fetchAppointment() }} />
    }

    const onEventUpdate = (updatedEvent) => {
        let newEvents = events.filter(function( event ) {
            return event.id !== updatedEvent.id;
        });
        newEvents.push(updatedEvent);
        setEvents(newEvents);
    }

    const newUnavailable = (resource) => {
        let startEndDate = date.toISOString().split("T")[0];
        setAddUnavailableBlock(
            {
                practiceLocationId:  state.practiceLocationId,
                doctorId: resource.doctorId,
                startDate: startEndDate,
                endDate: startEndDate
            }
        )
    } 

    const copyUnavailable = (unavailableBlockId) => {

        AppointmentService.getUnavailableBlock(unavailableBlockId)
            .then(unavailableblock => 
                {
                    delete unavailableblock.unavailableBlockId;
                    setAddUnavailableBlock(unavailableblock)
                }
            )
            .catch(() => {
                    toast.error('Oops! Something went wrong');
                }
            );
    } 


    const Legend = (props) => {
        return (
            <div className='container'>
                <div className="mt-3 hidden-lg-mobile scroll-box" >
                    <label>
                        Service Types:
                    </label>
                    <div className="card p-3">
                        {serviceList && serviceList.map(service => {
                            return (
                                <span style={{ display: 'block', fontSize: "12px" }}><i className="icon small square me-3" style={{ color: service.appointmentFillColor }} />{service.practiceServiceType}</span>
                            )
                        })}
                    </div>
                </div>
                <div className="mt-3 hidden-lg-mobile" >
                    <label>
                        Patient Types:
                    </label>
                    <div className="card p-3">
                        {patientTypes.map(type => {
                            return (
                                <span style={{ display: 'block', fontSize: "12px" }}><i className="icon small square me-3" style={{ color: type.appointmentBorderColor }} />{type.patientType}</span>
                            )
                        })}
                    </div>
                </div>
            </div >)
    }
    return (
        <div className="row mt-3">
            {expand ? <div className="col-lg-auto col-12 mb-3" id="schedule-filters">
                <Module title="Filters" extraTool={expand && !isMobile ? <i title="hide navigation" className='icon minus small btn btn-transparent m-1 text-primary' onClick={e => { e.preventDefault(); setExpand(false) }} /> : null}>
                    {!screenLocked && <div className='field mb-3'>
                        <label>View Type</label>
                        <div className='justify-content-between d-flex'>
                            <span className="rbc-btn-group col-auto">
                                <button type="button" onClick={e => { e.preventDefault(); navigate('/provider/schedule') }} className={selectedResource === 'provider' ? 'btn btn-primary' : 'btn btn-secondary'} title="Provider">P</button>
                                {state.permissions.schedulingModifyAppointments && <>
                                    <button type="button" onClick={e => { e.preventDefault(); navigate("/provider/schedule/availability") }} className={selectedResource === 'availability' ? 'btn btn-primary' : 'btn btn-secondary'} title="Availability">A</button>
                                </>}
                                <button type="button" onClick={e => { e.preventDefault(); navigate("/provider/schedule/room") }} className={selectedResource === 'room' ? 'btn btn-primary' : 'btn btn-secondary'} title="Room">R</button>
                                <button type="button" onClick={e => { e.preventDefault(); navigate("/provider/schedule/equipment"); }} className={selectedResource === 'equipment' ? 'btn btn-primary' : 'btn btn-secondary'} title="Equipment">E</button>
                                <button type="button" onClick={e => { e.preventDefault(); navigate("/provider/schedule/dayview"); }} className={selectedResource === 'dayview' ? 'btn btn-primary' : 'btn btn-secondary'} title="Day View">D</button>
                            </span>
                            <button type="button" className='btn btn-primary' title="Refresh" onClick={e => { e.preventDefault(); refresh() }}><i className='icon refresh' /></button>
                        </div>
                    </div>}
                    {screenLocked && 
                        <div className='row-fluid  d-flex align-items-center justify-content-left'>
                            <button type="submit" onClick={e => {e.preventDefault(); forceUnlockScreen() }} style={{width:'100%'}} className="btn btn-primary mt-3 col-auto">
                                Unlock Screen
                            </button>
                        </div >
                    }
                    <div className="calendar picker mb-3 row" side="left" title='Filters'>
                        <div className="field col-lg-12 col-md-4 col-12 mb-3">
                            {selectedResource === "availability" &&
                                <div className="field col-12">
                                    <label>Service Type</label><Select
                                        options={serviceList}
                                        classNamePrefix="react-select"
                                        className="react-select-container"
                                        name="practiceServiceTypeId"
                                        placeholder="All Service Types"
                                        value={selectedService}
                                        isClearable={true}
                                        onChange={e => {
                                            setSelectedService(e)
                                        }}
                                        getOptionLabel={(option) => option.practiceServiceType}
                                        getOptionValue={(option) => option.practiceServiceTypeId}
                                    />
                                    {/* <label>Select Patient</label>
                                    <Select
                                        options={patientList}
                                        name="patientId"
                                        classNamePrefix="react-select"
                                        className="react-select-container"
                                        isClearable={true}
                                        value={selectedPatient}
                                        onChange={e => {
                                            setSelectedPatient(e)
                                        }}
                                        getOptionLabel={(option) => {
                                            return (
                                                option.firstName + ' ' + option.lastName + ' | ' + moment.utc(option.dob).format("M/D/YYYY") + ' | ' + Utilities.toPhoneNumber(option.mobile) || Utilities.toPhoneNumber(option.patientPhone)
                                            )
                                        }}
                                    /> */}
                                </div>
                            }
                            <label>Select Location</label>
                            <PracticeLocationSelector onChanging={()=> {setIsLoader_location(true);}} onSelected={()=> {setIsLoader_location(false);}}/>
                            {selectedResource !== "dayview" && <div className="col-lg-12 col-md-4 col-12">
                                <label>Filter by Provider</label>
                                <Select
                                    options={doctorList}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    onChange={e => { doctorChange(e) }}
                                    value={selectedDoctorList && doctorList.find(obj => obj.id === selectedDoctorList.doctorId)}
                                    isClearable={true}
                                    isLoading={!doctorList}
                                    isDisabled={!doctorList}
                                    loadingMessage="Retrieving list of providers"
                                    isMulti={true}
                                    placeholder={doctorList?.length > 0 ? "All Providers" : "No Providers Available"}
                                    getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
                                    getOptionValue={(option) => option.id}
                                />
                            </div>}
                        </div>
                        <div className="hidden-lg-mobile mb-3">
                            <Calendar
                                className="date-picker mt-3"
                                calendarType="US"
                                // showDoubleView={true}
                                activeStartDate={fromDate}
                                // onActiveStartDateChange={e=>{setDate(e); navigateCalendar(e); console.log(e)}}
                                value={date}
                                // showNavigation={false}
                                // onDrillUp={(a)=>console.log(a)}
                                // navigationLabel={DateLabel}
                                showNeighboringMonth={false}
                                // showFixedNumberOfWeeks={false}
                                // onChange={e => { selectDate(e) }}
                                minDetail="month"
                                maxDetail="month"
                                onActiveStartDateChange={e => { startDateChange(e) }}
                                formatShortWeekday={(locale, date) => { return moment(date).format('dd')[0] }}
                                tileClassName={(option) => {
                                    if (option.date > new Date(moment(fromDate).subtract(1, "d").startOf("d")) && option.date < new Date(toDate)) {
                                        return "tile-range"
                                    }
                                }}
                                tileDisabled={(data) => data.date < data.activeStartDate || data.date > moment(data.activeStartDate).endOf("M")}
                            />
                            {selectedResource !== "availability" && selectedResource !== "dayview" && <div>
                                <div className="react-calendar__navigation bg-primary">
                                    <div aria-label="" className="react-calendar__navigation__label text-center" disabled="" type="button" style={{ flexGrow: 1 }}>
                                        <span className="react-calendar__navigation__label__labelText react-calendar__navigation__label__labelText--from">{moment(fromDate).add(1, "M").format("MMMM YYYY")}</span>
                                    </div>
                                </div>
                                <Calendar
                                    className="date-picker"
                                    calendarType="US"
                                    activeStartDate={new Date(moment(fromDate).add(1, "M"))}
                                    value={date}
                                    showNeighboringMonth={false}
                                    onClickDay={e => { selectDate(e) }}
                                    showNavigation={false}
                                    minDetail="month"
                                    maxDetail="month"
                                    formatShortWeekday={(locale, date) => { return moment(date).format('dd')[0] }}
                                    tileDisabled={(data) => data.date < data.activeStartDate || data.date > moment(data.activeStartDate).endOf("M")}
                                />
                            </div>}
                        </div>
                        <div className="show-lg-mobile col-lg-12 col-md-4 col-12">
                            <label>Date Selector</label>
                            <input type="date" value={moment(new Date(date)).format('yyyy-MM-DD')} onChange={e => { e.preventDefault(); mobileSelect(e.target.value); }} />
                        </div>
                        {selectedResource === "equipment" || selectedResource === "availability" ? <div className="col-lg-12 col-md-4 col-12">
                            <label>Filter by Equipment</label>
                            <Select
                                options={equipmentList}
                                onChange={e => { equipmentChange(e) }}
                                value={selectedEquipmentList}
                                classNamePrefix="react-select"
                                className="react-select-container"
                                isClearable={true}
                                isMulti
                                placeholder="All Equipment"
                                getOptionLabel={(option) => option.description}
                                getOptionValue={(option) => option.equipmentId}

                            />
                        </div> : null}
                        {selectedResource === "room" || selectedResource === 'availability' ? <div className="col-lg-12 col-md-4 col-12">
                            <label>Filter by Room</label>
                            <Select
                                classNamePrefix="react-select"
                                className="react-select-container"
                                options={roomList}

                                onChange={e => { roomChange(e) }}
                                value={selectedRoomList}
                                isClearable={true}
                                isMulti
                                placeholder="All Rooms"
                                getOptionLabel={(option) => option.room}
                                getOptionValue={(option) => option.practiceLocationRoomId}

                            />
                        </div> : null}
                    </div>
                </Module>
            </div> : null}
            <div className={expand ? "col" : "col-12"} id="schedule-body">
                {selectedResource !== "availability" && selectedResource !== "dayview" &&
                    <Module title="Calendar"
                        dontCollapse
                        helperIcon="question mark"
                        wide toolTip={Legend}
                        helper={serviceList}
                        toolTipSize="small"
                        toolTipPosition="bottom right"
                        helperMessage="Click for legend"
                        extraTool={!expand && !isMobile ? <i title="show navigation" className='icon plus small btn btn-transparent m-1 text-primary' onClick={e => { e.preventDefault(); setExpand(true) }} /> : null}
                    >
                        <div className="calendar" side="right" title="Calendar" id="calendar-wrap">
                            {!showAdd && state.permissions.schedulingModifyAppointments && <button className='btn btn-primary round mobile-button-float' onClick={e => { e.preventDefault(); setFormObjective("add"); return setShowAdd(true) }}><i className='icon large calendar plus' /></button>}
                            {isLoader_location || isLoader_findAppointment || isLoader_Availability || !doctorList ?
                                <div className="ui active dimmer">
                                    <div className="ui indeterminate text loader">Schedule Loading...</div>
                                </div> : null}
                            <DnDCalendar
                                events={doctorList ? events : []}
                                localizer={localizer}
                                style={{ height: "80vh" }}
                                defaultDate={moment().toDate()}
                                view={view}
                                width={isMobile ? 230 : null}
                                views={['week', 'day', 'agenda']}
                                components={{
                                    event: EventTileTemplate,
                                    toolbar: Toolbar,
                                    resourceHeader: (element) => {
                                        return <>
                                        
                                        <button
                                            className={`btn btn-transparent text-primary w-75`}
                                            onClick={e => {
                                                e.preventDefault();
                                                if (selectedResource === "provider") {
                                                    doctorChange([element.resource]);
                                                }
                                                else if (selectedResource === "equipment") {
                                                    equipmentChange([element.resource])
                                                }
                                                else if (selectedResource === "room") {
                                                    roomChange([element.resource])
                                                }
                                                setView("week");
                                                return navigateCalendar({ action: 'view', view: 'week' })
                                            }}
                                            title={`Week View for ${element.resource?.firstName} ${element.resource?.lastName?.substr(0, 1)}`}
                                        >
                                            {element.resource.firstName && element.resource?.firstName} &nbsp;
                                            {element.resource?.lastName ? element.resource?.lastName?.substr(0, 1) : element.label}
                                        </button>
                                        
                                        {element.resource?.doctorId!="999999999" && <i onClick={e => { 
                                            e.preventDefault(); 
                                            newUnavailable(element.resource) 
                                            }} 
                                            className="ui icon coffee ul btn p-0 mb-2" 
                                            title="Away" 
                                            style={{ float: 'right' }} />
                                        }                                        
                                        </>
                                    },
                                    week: {
                                        header: (element) => { return <span>{element.label}</span> }
                                    }
                                }}
                                date={date}
                                selectable='ignoreEvents'
                                scrollToTime={moment().subtract(9, "h")}
                                timeslots={1}
                                resizable
                                onSelectSlot={e =>
                                    {
                                        if (!screenLocked) {
                                            toggleAppointment(e);
                                        }
                                    } 
                                }
                                min={openTime}
                                max={closeTime}
                                resources={selectedResource === "provider" ? selectedDoctorList || doctorList : selectedResource === "equipment" ? selectedEquipmentList || equipmentList : selectedRoomList || roomList}
                                resourceAccessor={selectedResource === "provider" ? 'doctorId' : selectedResource === "equipment" ? 'equipmentId' : 'practiceLocationRoomId'}
                                resourceIdAccessor={selectedResource === "provider" ? 'id' : selectedResource === "equipment" ? 'equipmentId' : 'practiceLocationRoomId'}
                                resourceTitleAccessor={selectedResource === "provider" ? "name" : selectedResource === "equipment" ? 'description' : selectedResource === "room" ? 'room' : null}
                                onEventDrop={(e) => { 
                                    if (!screenLocked) onDrop(e); 
                                }}
                                onEventResize={(e) => { 
                                    if (!screenLocked) onEventResize(e);
                                }}
                                draggableAccessor={event => {
                                    //console.log("event ::"+JSON.stringify(event))
                                    if ( !screenLocked && event?.id) {
                                        if (!event?.parentGroupId) { //parentGroupId doesnot exist means main appointment - drag drop allowed
                                            return true
                                        }
                                    }
                                    return false
                                }}
                                eventPropGetter={
                                    (event, start, end, isSelected) => { 
                                        if (!event.unavailableBlockId && !event.id) {
                                            return { className: 'off-hours-event' } 
                                        }
                                        else if (!event.id) { 
                                            return { className: 'unavailable-event' } 
                                        } 
                                        else { 
                                            return { style: { backgroundColor: event.fillColour, borderColor: event.borderColour, borderTopStyle: event.borderStyle, borderRightStyle: event.borderStyle, borderBottomStyle: event.borderStyle, color: 'black' } } 
                                        } 
                                    }
                                }
                            />
                        </div>
                    </Module>}
                {selectedResource === "availability" &&
                    <Module title="Availability" dontCollapse>
                        <Table2 hiddenColumns={['practiceServiceTypeId']} data={availableList} loading={isLoader_Availability} columns={columns}
                            extraButtons={!isMobile ? [
                                {
                                    className: view === 'week' ? 'btn btn-primary' : 'btn btn-secondary', children: 'Week', onClick: () => { setView('week'); return navigateCalendar({ action: 'view', view: 'week' }) }
                                },
                                {
                                    className: view === 'day' ? 'btn btn-primary' : 'btn btn-secondary', children: 'Day', onClick: () => { setView('day'); return navigateCalendar({ action: 'view', view: 'day' }) }
                                },
                            ] : null} />
                    </Module>
                }
                {selectedResource === "dayview" &&
                    <Module title="Day View" dontCollapse>
                        <Table2 data={events.filter(appt => appt.patientId != "0rbd3W69")} loading={isLoader_findAppointment} columns={dayViewColumns}
                            extraButtons={[
                                {
                                    className: unassignedAptList.length > 0 ? 'btn btn-primary ' : 'btn btn-secondary disabled', children: 'Auto Assign', onClick: () => { return autoAssignProvider() }
                                },
                                {
                                    className: newAptDoctorList.length > 0 ? 'btn btn-primary' : 'btn btn-secondary disabled', children: 'Update All', onClick: () => { return updateAptsProvider() }
                                },
                            ]} />
                    </Module>
                }
            </div>
            <ModalBox open={showAdd} onClose={() => { setShowAdd(false); }} isEdit={formObjective == 'edit' ? true : false} edit={formObjective === "edit" ? true : false} title={formObjective == 'edit' ? 'Edit Appointment' : 'Add Appointment'}>
                {showAdd && <AppointmentForm initialData={{ ...initialData, practiceLocationRoomId: selectedRoomList ? selectedRoomList[0].practiceLocationRoomId : initialData.practiceLocationRoomId, equipmentId: selectedEquipmentList ? selectedEquipmentList[0].equipmentId : initialData.equipmentId }} submitLabel={formObjective === "add" ? 'Add' : 'Update'} submitHandler={submitHandler} onClose={() => { fetchAppointment(); return setShowAdd(false); }} disabled={aptDisabled} />}
            </ModalBox>

            <ModalBox open={addUnavailableBlock} onClose={() => { setAddUnavailableBlock() }} onOpen={() => {  }} title="Add Away Time">
                <UnavailableBlockForm providerId={state.providerId} initialData={addUnavailableBlock} onClose={() => { setAddUnavailableBlock(); refresh(); }} buttonLabel="Add" />
            </ModalBox>

            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
            <APIResponse apiResponse={apiAutoAssignResponse} onSuccess={onAutoAssignSuccess} toastOnSuccess={true} successMessage="Provider assigned successfully." />
            <APIResponse apiResponse={apiUpdateAllResponse} onSuccess={onUpdateAllSuccess} toastOnSuccess={true} />
        </div>
    )
}

export default AppointmentSchedule