import React, { useState, useContext } from "react";
import DocumentsService from "../../../../../../../services/api/documents.service";
import AddDocumentsPatient from "./AddDocumentsPatient";
import DimLoader from "../../../../../../templates/components/DimLoader";
import APIResponse from "../../../../../../templates/components/APIResponse";

import { store } from '../../../../../../../context/StateProvider'
const AddDocuments = (props) => {
  const globalStateAndDispatch = useContext(store)
  const contextState = globalStateAndDispatch.state
  const [documentData, setDocumentData] = useState();
  const [errors, setErrors] = useState({});
  const [reloader, setReloader] = useState(false);
  const [isLoader, setIsLoader] = useState(false)
  const [apiResponse, setApiResponse] = useState()
  const [uploadApiResponse, setUploadApiResponse] = useState()
  
  const onSuccess = (message) => {
    setIsLoader(true)
    DocumentsService.addDocument(documentData,apiResponse.data?.uploadUrl)
      .then(res => {
        setUploadApiResponse(res)
      })
      .catch(
        (error) => {
          setUploadApiResponse(error)
        })
      .finally(() => {
        setIsLoader(false)
      });
  }
  const onUploadSuccess = (message) => {
    if (props.refresh) {
      props.refresh()
    }
    if (props.onClose) {
      props.onClose()
    }
    if (props.exitHandler) {
      props.exitHandler()
    }
  }
  const onSaveDocuments = (data,isOverWrite) => {
    setIsLoader(true)
    let reqObj = {
      "fileName" : data.meta.name,
      "practiceLocationId" :contextState.practiceLocationId,
      "isOverWrite": isOverWrite.toString()
  }
    DocumentsService.getUploadURL( props.patientId,reqObj)
    .then(response => {
      setDocumentData(data.file)
      setApiResponse(response)
    }
    ).catch(
      (error) => {
        setApiResponse(error)
      })
    .finally(() => {
      setIsLoader(false)
    });
  }


  const handleSetReloader = () => {
    setReloader(true)
  }

  return (
    <div>
      {isLoader ? <DimLoader loadMessage="Uploading" /> : null}
      <AddDocumentsPatient
        submitHandler={onSaveDocuments}
        submitLabel="Submit"
        //initialData={documentData}
        errors={errors}
        reloader={handleSetReloader}
        isModal={props.isModal}
        onClose={() => props.onClose()}
        patientId={props.patientId}
      />
      <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={false} />
      <APIResponse apiResponse={uploadApiResponse} onSuccess={onUploadSuccess} toastOnSuccess={true} />
    </div>
  );
};

export default AddDocuments;