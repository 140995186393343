import moment from 'moment';
import React, { useState, useEffect } from 'react'
import { Dropdown, Icon, Accordion } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import ProviderService from '../../../../../../services/api/provider.service';
import Utilities from '../../../../../../services/commonservice/utilities';
import StorageService from '../../../../../../services/session/storage.service';
import UserRolesTable from '../../../../provider/settings-dashboard/security-settings/user-roles/user-roles-table/UserRolesTable';
import UserSettingsTable from '../../../../provider/settings-dashboard/security-settings/user/user-settings-table/UserSettingsTable'
import ModalBox from '../../../../../templates/components/ModalBox';
import toast from 'react-hot-toast';
import ResetPasswordService from '../../../../../../services/api/reset-password.service';
import PracticeForm from '../practice-form/PracticeForm';

const AdminPracticeCard = (props) => {
    const [activeIndex, setActiveIndex] = useState()
    const [show, setShow] = useState(false)
    const [userTypes, setUserTypes] = useState()
    const [showDeactivate, setShowDeactivate] = useState(false)
    const [showPasswordReset, setShowPasswordReset] = useState(false)
    const [showEdit, setShowEdit] = useState(false)

    const navigate = useNavigate()
    const emulate = () => {
        let reqObj = props.practice
        reqObj.userName = props.practice.providerAdminUser
        ProviderService.emulate(reqObj)
            .then(res => {
                let reqObj = res.data
                reqObj.userName = props.practice.providerAdminUser
                StorageService.save('session', 'guestUser', JSON.stringify(reqObj));
                return navigate('/login')
            })
    }
    const deactivateProvider = () => {
        if (props.practice?.isActive == 1) {
            ProviderService.deactivateProvider(props.practice.id)
                .then(res => {
                    console.log(res)
                    if (props.refreshList) {
                        props.refreshList()
                    }
                    toast.success("Provider is Deactivated")
                    setShowDeactivate(false)
                })
        }
        else {
            ProviderService.activateProvider(props.practice.id)
                .then(res => {
                    console.log(res)
                    if (props.refreshList) {
                        props.refreshList()
                    }
                    toast.success("Provider is Activated")
                    setShowDeactivate(false)
                })
        }
    }

    const resetPassword = () =>{
        ResetPasswordService.resetPassword({isReset: true},props.practice.providerAdminUser,1,true)
        .then(res=>{
            toast.success("Password Reset")
            console.log(res)
            setShowPasswordReset(false)
        })
        .catch(err=>{
            toast.error("Failed to reset password")
            setShowPasswordReset(false)
        })
    }

    const navigateToEdit = () =>{
        navigate(`/admin/${props.practice.id}`)
    }
    return (
        <div className='card'>
            <div className='row d-flex p-3'>
                <div className='col point row d-flex' onClick={e => { e.preventDefault(); setShow(!show) }}>
                    <div className='col-12 mb-3'>
                        <h5><strong>{props.practice.name}</strong> | {props.practice.contact.name.firstName} {props.practice.contact.name.lastName}</h5>

                    </div>

                    <div className='col-12 row d-flex align-items-center'>
                        <div className='col-4 d-flex align-items-center'>
                            <span className='w-150px truncate'><strong>Created On</strong></span> {props.practice.createdOn && moment.utc(props.practice.createdOn).format("M-D-YYYY")}
                        </div>
                        <div className='col-4 d-flex align-items-center'>
                            <span className='w-150px truncate'><strong>Phone</strong></span> {props.practice.contact.phone && Utilities.toPhoneNumber(props.practice.contact.phone)}
                        </div>
                        <div className='col-4 d-flex align-items-center'>
                            <span className='w-150px truncate'><strong>Email</strong></span> {props.practice.contact.email}
                        </div>
                    </div>
                </div>
                <div className='col-auto text-end'>
                    <div className='btn-group'>
                        <button className={`btn ${props.practice?.isActive == 1 ? 'btn-success' : 'btn-danger'}`}>{props.practice?.isActive == 1 ? 'Active' : 'Inactive'}</button>
                        <Dropdown button direction="left" icon="ellipsis horizontal" className="btn-primary icon btn p-o">
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={e => { e.preventDefault(); navigateToEdit() }}>Edit Practice Details</Dropdown.Item>
                                <Dropdown.Item onClick={e => { e.preventDefault(); setShowDeactivate(true) }}>{props.practice?.isActive == 1 ? 'Deactivate' : 'Activate'}</Dropdown.Item>
                                {props.practice?.isActive == 1 ? <Dropdown.Item onClick={e => { e.preventDefault(); setShowPasswordReset(true) }}>Reset Password</Dropdown.Item> : null}
                                {props.practice?.isActive == 1 ? <Dropdown.Item onClick={e => { e.preventDefault(); emulate() }}>Emulate</Dropdown.Item> : null}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>

            </div>
            {show && <div className='col-12 row d-flex p-3'>
                <div className="col-12">
                    <Accordion fluid styled>
                        <Accordion.Title
                            active={activeIndex === 0}
                            index={0}
                            onClick={e => { e.preventDefault(); if (activeIndex === 0) { setActiveIndex() } else { setActiveIndex(0) } }}
                        > <Icon name='dropdown' />Practice Information</Accordion.Title>
                        <Accordion.Content active={activeIndex === 0}>
                            <div className="d-flex row">
                                <div className='col-4 d-flex align-items-start'>
                                    <span className='w-150px truncate'><strong>Address</strong></span>
                                    {props.practice.contact.address.addressLine1}
                                    {props.practice.contact.address.addressLine2}
                                    , {props.practice.contact.address.city}
                                    &nbsp;{props.practice.contact.address.state}
                                    &nbsp;{props.practice.contact.address.postalCode}
                                </div>
                                <div className='col-4 d-flex align-items-center'>
                                    <span className='w-150px truncate'><strong>Facility Name</strong></span> {props.practice.facilityName}
                                </div>
                                <div className='col-4 d-flex align-items-center'>
                                    <span className='w-150px truncate'><strong>Facility Branch</strong></span> {props.practice.branchName}
                                </div>
                            </div>
                        </Accordion.Content>
                        <Accordion.Title
                            active={activeIndex === 1}
                            index={1}
                            onClick={e => { e.preventDefault(); if (activeIndex === 1) { setActiveIndex() } else { setActiveIndex(1) } }}
                        > <Icon name='dropdown' />Practice User Types</Accordion.Title>
                        <Accordion.Content active={activeIndex === 1}>
                            <UserRolesTable practice={props.practice} setUserTypes={setUserTypes} practiceId={props.practice.id} refresh={props.refresh} />
                        </Accordion.Content>
                        <Accordion.Title
                            active={activeIndex === 2}
                            index={2}
                            onClick={e => { e.preventDefault(); if (activeIndex === 2) { setActiveIndex() } else { setActiveIndex(2) } }}
                        > <Icon name='dropdown' />Practice Users</Accordion.Title>
                        <Accordion.Content active={activeIndex === 2}>
                            <UserSettingsTable userTypes={userTypes} practiceId={props.practice.id} refresh={props.refresh} />
                        </Accordion.Content>
                    </Accordion>
                </div>
            </div>}
            <ModalBox open={showDeactivate} onClose={() => { setShowDeactivate(false) }} title={`${props.practice?.isActive == 1 ? 'Deactivate' : 'Activate'} Practice`}>
                <div className='row d-flex'>
                    <div className='col-12'>
                        Are you sure you want to {props.practice.isActive == 1 ? 'deactivate' : 'activate'} {props.practice?.name}?
                    </div>
                    <div className='col-12'>
                        <div className='row d-flex mt-3 modal-button-bar justify-content-between'>
                            <div className='col-auto'>
                                <button className='btn btn-primary' onClick={e => { e.preventDefault(); deactivateProvider(false) }}>Yes</button>
                            </div>
                            <div className='col-auto'>
                                <button className='btn btn-secondary' onClick={e => { e.preventDefault(); setShowDeactivate(false) }}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox open={showPasswordReset} onClose={() => { setShowPasswordReset(false) }} title="Practice Password Reset">
                <div className='row d-flex'>
                    <div className='col-12'>
                        Are you sure you want to reset the password for {props.practice?.name}?
                    </div>
                    <div className='col-12'>
                        <div className='row d-flex mt-3 modal-button-bar justify-content-between'>
                        <div className='col-auto'>
                                <button className='btn btn-primary' onClick={e => { e.preventDefault(); resetPassword() }}>Yes</button>
                            </div>
                            <div className='col-auto'>
                                <button className='btn btn-secondary' onClick={e => { e.preventDefault(); setShowPasswordReset(false) }}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox open={showEdit} onClose={() => setShowEdit(false)} title={`Edit ${props.practice?.name}`}>
               {showEdit ? <PracticeForm practiceId={props.practice.id} onClose={() => {props.refreshList(); return setShowEdit(false)}}/>:null}
            </ModalBox>
        </div>
    )
}

export default AdminPracticeCard