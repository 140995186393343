import React, { useEffect, useState, useCallback, useContext } from 'react'
import moment from 'moment';
import CommonService from '../../../../../services/api/common.service'
import TabsTemplate from '../../../../templates/components/TabsTemplate'
import AsyncSelect from 'react-select/async'
import InvoiceService from '../../../../../services/api/invoice.service'
import ModalBox from '../../../../templates/components/ModalBox'
import AddInvoice from '../add-invoice/AddInvoice'
import Utilities from '../../../../../services/commonservice/utilities'
import InvoiceCard from '../invoice-card/InvoiceCard'
import AddPatient from '../../patient/add-patient/AddPatient'
import { debounce } from 'lodash'
import { store } from "../../../../../context/StateProvider";
import PaginationTemplate from '../../../../templates/components/PaginationTemplate';

const FindAllInvoices = (props) => {
    const [transactions, setTransactions] = useState()
    const [showAdd, setShowAdd] = useState(false)
    const [keyword, setKeyword] = useState()
    const [patientId, setPatientId] = useState()
    const [invoiceId, setInvoiceId] = useState()
    const [sortBy, setSortBy] = useState(false)
    const [collectPayment, setCollectPayment] = useState(false)
    const [activeKey, setActiveKey] = useState('patientId')
    const [pageCount, setPageCount] = useState()
    const [startRow, setStartRow] = useState()
    const filters = [
        { title: 'All', value: '' },
        // { title: 'Ready To Send', value: 1 },
        { title: 'Open', value: 2 },
        { title: 'In Progress', value: 4 },
        { title: 'Closed', value: 30 },
        { title: 'Cancelled', value: 3 },
        { title: 'Payment Plan', value: 5 },
        { title: 'One Time Scheduled Payment', value: 6 },
        { title: 'Subscription', value: 7 },
        //{ title: 'In Progress', value: 8 },
        { title: 'Paid', value: 9 },
        // { title: 'Unpaid', value: 10 },
        { title: 'Unsubscribed', value: 11 },
    ]
    const [currentFilter, setCurrentFilter] = useState()
    const [isLoader, setIsLoader] = useState(false)
    const globalStateAndDispatch = useContext(store);
    const contextState = globalStateAndDispatch.state;

    const initialInvoiceLoad = () => {
        setIsLoader(true)
        let reqObj = {
            SortField: 'createdOn',
            Asc: sortBy,
            StartRow: startRow || 0,
            PageSize: 10,
        }
        if (keyword) {
            if (activeKey == 'createdOn') {
                reqObj.CheckOutStartDate = new Date(moment.utc(keyword).startOf("d")).toISOString()
                reqObj.CheckOutEndDate = new Date(moment.utc(keyword).add(1, "d").startOf("d")).toISOString()
            }
            if (activeKey == 'dueDate') {
                reqObj.FromDueDate = new Date(moment.utc(keyword).startOf("d")).toISOString()
                reqObj.ToDueDate = new Date(moment.utc(keyword).add(1, "d").startOf("d")).toISOString()
            }
        }
        if (patientId) {
            reqObj.PatientIds = patientId
        }
        if (currentFilter) {
            if (currentFilter == 2) {
                reqObj.InvoiceStatuses = [2, 10]
            }
            else if (currentFilter == 4) {
                reqObj.InvoiceStatuses = [4, 8]
            }
            else {
                reqObj.InvoiceStatuses = currentFilter
            }
        }
        if (invoiceId) {
            reqObj.InvoiceId = invoiceId
        }
        InvoiceService.findInvoice(reqObj)
            .then(res => {
                if (res?.data?.data) {
                    setPageCount(Math.ceil(res?.data?.totalRowCount/10))
                    setTransactions(res.data.data)
                }
                else{
                    setTransactions()
                }
            })
            .catch(err=>{
                setTransactions()
            }).finally(()=>{
                setIsLoader(false)
            })
    }
    const onPageChange = (data) => {
        setStartRow(CommonService.calculatePageStartRow(data?.selected, 10))
    }

    const patientLoad = (inputText, callBack) => {
        if (inputText?.length < 3) return;
        let reqObj = { SearchTerm: inputText, isActive: true, isRegistered: true, SortField: 'firstName', Asc: true }
        CommonService.patientLookup(reqObj)
            .then(res => {
                if (res) {
                    callBack(res.data)
                }
            }
            )
            .catch(err => console.log(err))
    }
    const rePatientLoad = useCallback(debounce(patientLoad, 500), [])

    const invoiceLoad = (inputText, callBack) => {
        if (inputText?.length < 3) return;
        // setIsLoader(true)
        let reqObj = {
            SortField: 'createdOn',
            Asc: false,
            StartRow: 0,
            PageSize: 10,
            InvoiceNumber: inputText
        }
        InvoiceService.findInvoice(reqObj)
            .then(res => {
                callBack(res?.data?.data)
            })
    }
    const reInvoiceLoad = useCallback(debounce(invoiceLoad, 500), [])

    useEffect(() => {
        initialInvoiceLoad()
    }, [keyword, invoiceId, patientId, currentFilter, sortBy, startRow])
    return (
        <div className=''>
            <div title="Search" className='row d-flex align-items-end' id="invoiceSearchBar">
                <div className='col-md-6 col-12'>
                    <TabsTemplate style="pills" activeKey={activeKey} onSelect={k => { setActiveKey(k); setKeyword() }}>
                        <div title="Patient" eventKey="patientId">
                            <AsyncSelect
                                classNamePrefix="react-select"
                                className="react-select-container"
                                name="patientId"
                                loadOptions={rePatientLoad}
                                placeholder="Search Patient"
                                onChange={e => {
                                    console.log(e)
                                    if (e?.id) {
                                        setPatientId(e.id)
                                    }
                                    else {
                                        setPatientId()
                                    }
                                }}
                                isClearable={true}
                                // getOptionLabel={(option) => {
                                //     return (
                                //         option.firstName + ' ' + option.lastName + ' | ' + moment.utc(option.dob).format("M/D/YYYY") + ' | ' + Utilities.toPhoneNumber(option.mobile) || Utilities.toPhoneNumber(option.patientPhone)
                                //     )
                                // }
                                // }
                                getOptionLabel={(option)=>{
                                    return(
                                       <div className="d-flex row">
                                        <span className="col">{option.firstName} {option.lastName}</span>
                                          <span className='col text-start'><i className='icon birthday cake' />{moment.utc(option.dob).format("M/D/YYYY").toString()}</span>
                         <span className='col text-end'>
                                                    {option.mobile && option.mobile != '' || option.patientPhone && option.patientPhone != '' ?
                                                        <>
                                                            <i className='icon phone' />
                                                            {option.mobile && option.mobile != '' ? Utilities.toPhoneNumber(option.mobile) : option.patientPhone && option.patientPhone != '' ? Utilities.toPhoneNumber(option.patientPhone) : null}
                                                        </> : null}
                                                </span>
                                        </div>
                                    )
                                   }}
                                getOptionValue={(option) => option.id}
                                noOptionsMessage={(e) => { 
                                    if (!contextState.permissions.patientModifyPatients) {
                                        return null;
                                    }
                                    else
                                    {
                                        return <button className='btn btn-primary form-control' onClick={e => { e.preventDefault(); setShowAdd(true) }}>Add Patient</button> 
                                    }
                                }}
                            />
                        </div>
                        <div title="Invoice Number" eventKey='invoiceNumber'>
                            <AsyncSelect
                                classNamePrefix="react-select"
                                className="react-select-container"
                                // options={transactions}
                                name="invoiceNumber"
                                loadOptions={reInvoiceLoad}
                                placeholder="Search Invoice Number"
                                // value={transactions && transactions.find(obj => obj.invoiceNumber === keyword)}
                                onChange={e => {
                                    if (e?.id) {
                                        setInvoiceId(e.id)
                                    } else {
                                        setInvoiceId()
                                    }
                                }}
                                isClearable={true}
                                getOptionLabel={(option) => option.invoiceNumber + ' | ' + option.patientName + ' | ' + `${option.adjustedAmountUnpaid != null ? Utilities.toDollar(option.adjustedAmountUnpaid) + '/' : ''}` + Utilities.toDollar(option.finalAmount)
                                }
                                getOptionValue={(option) => option.id}
                            />
                        </div>
                        <div title="Checkout Date" eventKey="createdOn">
                            <div className='input-group'>
                                <input type="date" value={activeKey === 'createdOn' ? keyword : null} onBlur={e => {
                                    setKeyword(e.target.value)
                                }} />
                                <button className='btn btn-primary' onClick={e => { e.preventDefault(); setKeyword('') }}>Clear</button>
                            </div>
                        </div>
                        <div title="Due Date" eventKey="dueDate">
                            <div className='input-group'>
                                <input type="date" value={activeKey === 'dueDate' ? keyword : null} onBlur={e => {
                                    setKeyword(e.target.value)
                                }} />
                                <button className='btn btn-primary' onClick={e => { e.preventDefault(); setKeyword('') }}>Clear</button>
                            </div>
                        </div>
                    </TabsTemplate>
                </div>
                <div className='col-md-2 col-12 field mb-3'>
                    <label>Sort by: </label>
                    <div>
                        <select className='form-select' onChange={e => { setSortBy(e.target.value) }}>
                            <option value={false} selected>Date: Desc</option>
                            <option value={true}>Date: Asc</option>
                        </select>
                    </div>
                </div>
                <div className='col-md-3 col-12 field mb-3'>
                    <label>Filter By: </label>
                    <select className='form-select' onChange={e => { e.preventDefault(); setCurrentFilter(e.target.value) }}>
                        {filters.map((obj, i) => {
                            return (
                                <option value={obj.value}>
                                    {obj.title}
                                    {/* (
                                            {Array.isArray(transactions) && transactions.filter((tc) => {
                                                if (obj.value === "" || obj.value === null) {
                                                    return tc
                                                } else if (tc.invoiceStatus === obj.value) {
                                                    return tc
                                                }
                                            }).filter((transaction) => {
                                                if (keyword === "" || keyword === null || !keyword) {
                                                    return transaction
                                                } else if (transaction[activeKey].includes(keyword)) {
                                                    return transaction
                                                }

                                            }).length}
                                            ) */}
                                </option>
                            )
                        })
                        }
                    </select>
                </div>
                {contextState.permissions.paymentsModifyInvoices && <>
                <div className='col-md-1 col-12 mb-3'>
                    <button className='btn btn-primary w-100' onClick={e => { e.preventDefault(); setCollectPayment(true) }} title="Create Invoice"><i className='icon plus' /></button>
                </div>
                </>}
            </div>
            <div className='row-fluid' title="Invoices">
                {isLoader && <div className="ui active dimmer">
                    <div className="ui indeterminate text loader">Loading</div>
                </div>}
                    <div>
                            {transactions && Array.isArray(transactions) ? transactions
                                // .filter((tc) => {
                                //     if (currentFilter == "" || currentFilter == null || !currentFilter) {
                                //         return tc
                                //     } else if (tc.invoiceStatus === parseInt(currentFilter)) {
                                //         return tc

                                //     }
                                // })
                                //     .sort((a, b) => sortBy === 'Desc' ? b.createdOn.localeCompare(a.createdOn) : a.createdOn.localeCompare(b.createdOn))
                                //     .filter((transaction) => {
                                //         if (keyword === "" || keyword === null || !keyword) {
                                //             return transaction
                                //         } else {
                                //             if (transaction[activeKey].includes(keyword))
                                //                 return transaction
                                //         }
                                //     })
                                .map((tc, i) => {
                                    // console.log(tc)
                                    return (
                                        <InvoiceCard transaction={tc} invoiceId={tc.id} keyword={keyword} refresh={() => { initialInvoiceLoad() }} />
                                    )
                                }) : null}
                                {Array.isArray(transactions) && transactions.length > 0 &&
                        <PaginationTemplate onPageChange={onPageChange} pageCount={pageCount} />
                    }

                    </div>
            </div>
            {/* </Dashboard> */}
            <ModalBox open={collectPayment} onClose={() => { setCollectPayment(false) }} size="fullscreen" title="Add Invoice">
                <AddInvoice onClose={() => { initialInvoiceLoad(); return setCollectPayment(false) }} initialData={keyword?{ patientId: keyword }:null} isModal/>
            </ModalBox>
            <ModalBox open={showAdd} onClose={() => { setShowAdd(false) }} title="Register Patient">
                <AddPatient onClose={() => { patientLookup(); return setShowAdd(false) }} />
            </ModalBox>
        </div>
    )
}

export default FindAllInvoices