import React, { useState } from "react";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from "react-dropzone-uploader";

const AddDocumentsPatient = (props) => {
  const [documents, setDocuments] = useState(null);
  const [isOverWrite, setOverWrite] = useState(false);
  const [uploadStatus, setUploadStatus] = useState();

  const handleChangeStatus = ({ meta }, status) => {
    setUploadStatus(status)
  }
  return (
    <div className="row d-flex">
      <Dropzone
      onChangeStatus={handleChangeStatus}
       //onSubmit={handleSubmit}
      maxFiles={1}
      maxSizeBytes ={52428800}
      multiple={false}
      //canCancel={false}
      inputContent={(files, extra) => (extra.reject ? extra.reject : 'Choose a File')}
      inputWithFilesContent={files => setDocuments(files[0])}
      styles={{
        dropzone: { width: 500, height: 100, overflow:'hidden',minHeight:'auto'},
        dropzoneActive: { borderColor: "green" },
        dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
        inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
      }}
      accept="image/*,
      audio/*,
      video/*,
      application/pdf,
      application/msword,
      application/vnd.openxmlformats-officedocument.wordprocessingml.document,
      application/vnd.ms-excel,
      application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
      text/csv,
      text/plain"
      submitButtonDisabled = {true}
    />
      <div className="field">
        <div className="form-check">
          <input type="checkbox" className='form-check-input' checked={isOverWrite} name="isOverWrite" onChange={e => { setOverWrite(e.target.checked) }} />
          <label className="form-check-label">
            Overwrite existing file
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3 modal-button-bar">
        
        <div className="col-auto">
          <button className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault()
              props.submitHandler(documents, isOverWrite)
            }}
            disabled={uploadStatus !== "done"? true : false}
          >Save</button>
        </div>
        <div className="col-auto">
          <button className="btn btn-secondary float-right" onClick={(e) => { e.preventDefault(); props.onClose() }} >Cancel</button>
        </div>
      </div>
    </div>
  );
};
export default AddDocumentsPatient;
