import React, { useState } from 'react';
import CommonService from '../../../../../../services/api/common.service';
import InvoiceService from '../../../../../../services/api/invoice.service';
import APIResponse from '../../../../../templates/components/APIResponse';
import RecurringPlanForm from './installment-form/RecurringPlanForm';
// both subscription and installment plan
const AddRecurringPlan = (props) => {

    const [apiResponse, setApiResponse] = useState()
    const [isLoader, setIsLoader] = useState(false)
    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }

    const addRecurringPayment = (inputData,firstTransactionDate) => {
        console.log("inputData "+JSON.stringify(inputData))
        let loggedInUserData = CommonService.getLoggedInData()
        setIsLoader(true)
        let startDate = new Date(firstTransactionDate).toISOString();
        const recReqObj = {
            paymentAccountId: inputData?.account?.id,
            firstTransactionDate: startDate,
            frequency: inputData?.frequency ? parseInt(inputData?.frequency) : 3,
            noOfPayments: inputData?.noOfPayments ? parseInt(inputData?.noOfPayments) : 3,
            transactionType: props.transactionType,
            // 1 is payment plan, 3 is Subscription
            //channelType = 3 for cc and 2 for ach
            channelType: inputData?.account?.accountType == 2 ? 2 : 3, //accountType=1 for CC and accountType=2 for ACH 
            downPayment: inputData?.downPayment || 0,
            email: inputData?.email,
            description: inputData?.description
        };

        if (loggedInUserData.userType === 1) {
            recReqObj.providerId = loggedInUserData.parentId;
        } else if (loggedInUserData.userType === 0) {
            recReqObj.patientId = loggedInUserData.parentId;
        }
        //accountType=1 for CC and accountType=2 for ACH 
        if (inputData?.account?.accountType == 1 && inputData?.cvv) {
            recReqObj.cvv = inputData?.cvv;
        }

        return InvoiceService.addRecurringPayment(props.initialData.id, recReqObj)
            .then(
                (response) => {
                    setApiResponse(response);
                })
            .catch(error => {
                setApiResponse(error);
            })
            .finally(() => {
                setIsLoader(false)
            });
    }
    return (
        <>
        <RecurringPlanForm submitHandler={addRecurringPayment} isLoader={isLoader} onClose={props.onClose} isModal={props.isModal} initialData={props.initialData} transactionType={props.transactionType} submitLabel="Add" />
        <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true}/>
        </>
    )
}

export default AddRecurringPlan