import React, { useEffect, useState } from 'react'
import Table2 from '../../../../../templates/components/Table2'
import LeadSourceService from '../../../../../../services/api/lead-source.service'
import Utilities from '../../../../../../services/commonservice/utilities'
const MatchedPatientTable = (props) => {

    const [isLoader, setIsLoader] = useState(false)
    const [allList, setAllList] = useState()
    const hiddenColumns = ["action"]

    const getAll = () => {
        setIsLoader(true);
        LeadSourceService.getAllMatchedPatient()
            .then((response) => {
                setAllList(response.data);
                setIsLoader(false);
            })
            .catch(error => {
                setIsLoader(false);
            })
    }

    useEffect(() => {
        getAll()
    }, [])

    const columns = [
        {
            id: "possibleName",
            Header: "Possible Name",
            accessor: item => item.possibleFirstName +" "+ item.possibleLastName  
        },
         {
            id: "possibleMobile",
            Header: "Possible Phone",
            accessor: item => Utilities.toPhoneNumber(item.possibleMobile)
        }, {
            id: "possibleEmail",
            Header: "Possible Email",
            accessor: item => item.possibleEmail
        }, {
            id: "isMobileMatch",
            Header: "Mobile matched",
            accessor: item => item.isMobileMatch ? "Yes" : "No"
        }, {
            id: "isEmailMatch",
            Header: "Email matched",
            accessor: item => item.isEmailMatch ? "Yes" : "No"
        },{
            id: "isNameMatch",
            Header: "Name matched",
            accessor: item => item.isNameMatch ? "Yes" : "No"
        }
    ]

    return (
        <>
            <div title="Matched Patient" className='py-3'>
                <Table2
                    hiddenColumns={hiddenColumns}
                    searchable
                    data={allList}
                    columns={columns}
                    loading={isLoader}
                />
            </div>
        </>
    )
}

export default MatchedPatientTable