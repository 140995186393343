import React, { useEffect, useState, useContext } from 'react'
import PracticeServiceTypeService from '../../../../../../services/api/practice-service-type.service'
import AddPracticeServiceType from '../../../practice-service-type/add-practice-service-type/AddPracticeServiceType'
import EditPracticeServiceType from '../../../practice-service-type/edit-practice-service-type/EditPracticeServiceType'
import ModalBox from '../../../../../templates/components/ModalBox'
import { store } from '../../../../../../context/StateProvider'
import toast from 'react-hot-toast'
import Table2 from '../../../../../templates/components/Table2'
import Utilities from '../../../../../../services/commonservice/utilities'

const PracticeServiceType = (props) => {
    const globalStateAndDispatch = useContext(store)
    const contextState = globalStateAndDispatch.state

    const [isLoader_PracticeService, setIsLoader_PracticeService] = useState(false)
    const [practiceServiceList, setPracticeServiceList] = useState()

    // For forms 
    const [showPracticeServiceAdd, setShowPracticeServiceAdd] = useState(false)

    const [showPracticeServiceEdit, setShowPracticeServiceEdit] = useState(false)
    const [practiceServiceId, setPracticeServiceId] = useState()
    const [selectedServiceType, setSelectedServiceType] = useState()
    const [deleteModal, setDeleteModal] = useState()

    const practiceServiceTypeLookup = () => {
        setIsLoader_PracticeService(true);
        PracticeServiceTypeService.practiceServiceTypeLookup()
            .then((response) => {
                // console.log(response.data)
                setPracticeServiceList(response.data);
                setIsLoader_PracticeService(false);
            })
            .catch(error => {
                setIsLoader_PracticeService(false);
                console.log(error)
            })
    }

    useEffect(() => {
        practiceServiceTypeLookup()
    }, [])

    const deleteItem = () => {
        return PracticeServiceTypeService.deletePracticeServiceType(selectedServiceType.practiceServiceTypeId)
            .then(res => {
                toast.success("Practice Service Type Deleted")
                practiceServiceTypeLookup()
            })
            .catch(err => {
                toast.error("Error deleting Service Type")
            })
    }

    const practiceServiceColumns = [
        {
            id: "practiceServiceType",
            Header: "Practice Service Type",
            accessor: cell=>cell.practiceServiceType
        },
        {
            id: "description",
            Header: "description",
            accessor:(cell) => cell.description
        },
        {
            id: "unitPrice",
            Header: "Price",
            accessor:(cell) => cell.unitPrice?Utilities.toDollar(cell.unitPrice):null
        },
        {
            id: "cost",
            Header: "Cost",
            accessor: (cell) => cell.cost? Utilities.toDollar(cell.cost):null
        },
        {
            id: "overhead",
            Header: "Overhead",
            accessor: (cell) => cell.overhead? Utilities.toDollar(cell.overhead) :null
        },
        {
            id: "defaultDuration",
            Header: "Default Duration",
            accessor:(cell) => cell.defaultDuration+' mins'
        },
        {
            id: "appointmentFillColor",
            Header: "Appointment Fill Color",
            accessor:(practiceServiceType, i) => {
                return (
                    <div className="d-flex align-items-center">
                        <span className="me-3" style={{height:'15px',width:'15px',display:'block', backgroundColor:practiceServiceType.appointmentFillColor}} title={practiceServiceType.appointmentFillColor}></span> <p>{practiceServiceType.appointmentFillColor}</p>
                    </div>
                )
            }
        },
        {
            id: "actionPracticeServiceType",
            Header: "Action",
            disableSortBy: true,
            accessor:(practiceServiceType, i) => {
                return (
                    <div className='btn-group'>
                        <button className="p-0 ps-1 btn btn-primary" title="edit" onClick={e => { e.preventDefault(); setPracticeServiceId(practiceServiceType.practiceServiceTypeId); return setShowPracticeServiceEdit(true) }}><i className="icon pencil" /></button>
                        <button className="p-0 ps-1 btn btn-primary ms-1" title="delete" onClick={e => { e.preventDefault(); setSelectedServiceType(practiceServiceType); return setDeleteModal(true) }}><i className="icon trash" /></button>
                    </div>
                )
            }
        }
    ]



    return (
        <div>
            <div title="Practice Service Type" className='py-3'>
                <Table2 
                searchable
                export
                data={practiceServiceList} columns={practiceServiceColumns}  loading={isLoader_PracticeService} 
                extraButtons={[{
                    className: 'btn btn-primary',
                    title: "Add Pracice Service Type",
                    children:
                        <i className='icon plus' />
                    ,
                    onClick: () => {
                        setShowPracticeServiceAdd(true)
                    }
                }]}
                />
            </div>
            <ModalBox open={showPracticeServiceAdd} onClose={() => { setShowPracticeServiceAdd(false) }} title="Add Service">
                <AddPracticeServiceType exitHandler={() => {practiceServiceTypeLookup(); return setShowPracticeServiceAdd(false)}} />
            </ModalBox>
            <ModalBox open={showPracticeServiceEdit} onClose={() => { setShowPracticeServiceEdit(false) }} title="Edit Service">
                <EditPracticeServiceType id={practiceServiceId} exitHandler={() => {practiceServiceTypeLookup(); return setShowPracticeServiceEdit(false)}} />
            </ModalBox>

            <ModalBox open={deleteModal} onClose={() => { setDeleteModal(false) }}>
                {selectedServiceType?.practiceServiceTypeId && <div className='row d-flex align-items-center justify-content-between'>
                    <div className='col'>
                        Are you sure you want to delete {selectedServiceType.practiceServiceType}?
                    </div>
                    <div className='col-auto'>
                        <button className='btn btn-primary ms-3' onClick={e => { e.preventDefault(); deleteItem(); setDeleteModal(false) }}>Yes</button>
                        <button className='btn btn-secondary' onClick={e => { e.preventDefault(); setSelectedServiceType(); return setDeleteModal(false) }}>No</button>
                    </div>
                </div>}
            </ModalBox>
        </div>
    )
}

export default PracticeServiceType