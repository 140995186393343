import moment from 'moment'
import React, { useContext, useEffect, useState, useMemo } from 'react'
import ReportService from '../../../../../services/api/report.service'
import Utilities from '../../../../../services/commonservice/utilities'
import Module from '../../../../templates/components/Module'
import { store } from '../../../../../context/StateProvider'
import Select from 'react-select'
import AppointmentService from '../../../../../services/api/appointment.service'
import Table2 from '../../../../templates/components/Table2'
import StorageService from '../../../../../services/session/storage.service'
import DatePicker from "../../../../templates/components/DatePicker";
import DimLoader from '../../../../templates/components/DimLoader'

const PaymentReconciliationReport = (props) => {
    const state = useContext(store).state
    const [slabBy, setSlabBy] = useState('M')
    const [startDate, setStartDate] = useState(moment().startOf("D"));
    const [endDate, setEndDate] = useState(moment().endOf("D"));
    const [timePeriod, setTimePeriod] = useState("day")
    const [data, setData] = useState()
    const [activeIndex, setActiveIndex] = useState()
    const [isLoader, setIsLoader] = useState(false)
    const [providerList, setProviderList] = useState()
    const [listSlabBy, setListSlabBy] = useState()
    const [selectedProvider, setSelectedProvider] = useState()
    const [sums, setSums] = useState()
    const [locationList, setLocationList] = useState(state.practiceLocations || null)
    const [locationId, setLocationId] = useState(state.practiceLocationId || null)
    const [footer, setFooter] = useState()
    const [initialMessage, setInitialMessage] = useState(true)

    // const for table 
    const columns = [
        // ["Name", "Equipment Type", "Room", "Actions"]
        {
            Header: "Period",
            id: 'period',
            sortType: (rowA, rowB, columnId, desc) => {
                console.log(rowA, rowB, columnId, desc); if (rowA.period > rowB.period) { return 1 } else {
                    return -1
                }
            },
            accessor: (tc) => {
                switch (listSlabBy) {
                    case 'Y':
                        return moment.utc(tc.period).format("YYYY")
                    case 'M':
                        return moment.utc(tc.period).format("MMMM")
                    case 'W':
                        if (moment.utc(tc.period).date() < 7 > 1)
                            return moment.utc(tc.period).startOf("M").format("MM-DD-YYYY") + ' - ' + moment.utc(tc.period).endOf("w").format("MM-DD-YYYY")
                        else if (moment.utc(tc.period).date() === 1)
                            return moment.utc(tc.period).startOf("M").format("MM-DD-YYYY") + ' - ' + moment.utc(tc.period).endOf("w").format("MM-DD-YYYY")
                        else
                            return moment.utc(tc.period).startOf("w").format("MM-DD-YYYY") + ' - ' + (moment.utc(tc.period).endOf("w").month() == moment.utc(tc.period).month() ? moment.utc(tc.period).endOf("w").format("MM-DD-YYYY") : moment.utc(tc.period).endOf("M").format("MM-DD-YYYY"))
                    case 'D':
                        return moment.utc(tc.period).format("MM-DD-YYYY")
                    case 'H':
                        return moment.utc(tc.period).format("h:mm A")
                    default:
                        break
                }
            },
            Footer: () => { return 'Totals' }
        },
        {
            Header: "Credit",
            id: 'credit',
            accessor: (tc) => { console.log(tc); return Utilities.toDollar(+tc.credit) },
            Footer: info => {
                // Only calculate total visits if rows change
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.credit + sum, 0),
                    [info.rows]
                )

                return <div text={Utilities.toDollar(total)}>{Utilities.toDollar(total)}</div>
            },
        },
        {
            Header: "ACH",
            id: 'ach',
            accessor: (tc) => Utilities.toDollar(+tc.ach),
            Footer: info => {
                // Only calculate total visits if rows change
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.ach + sum, 0),
                    [info.rows]
                )

                return <>{Utilities.toDollar(total)}</>
            },
        },
        {
            Header: "Cash",
            id: 'cash',
            accessor: (tc) => Utilities.toDollar(+tc.cash),
            Footer: info => {
                // Only calculate total visits if rows change
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.cash + sum, 0),
                    [info.rows]
                )

                return <>{Utilities.toDollar(total)}</>
            },
        },
        {
            Header: "Check",
            accessor: 'check',
            accessor: (tc) => Utilities.toDollar(+tc.check),
            Footer: info => {
                // Only calculate total visits if rows change
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.check + sum, 0),
                    [info.rows]
                )

                return <>{Utilities.toDollar(total)}</>
            },
        },
        {
            Header: "Swipe",
            accessor: 'terminal',
            accessor: (tc) => Utilities.toDollar(+tc.terminal),
            Footer: info => {
                // Only calculate total visits if rows change
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.terminal + sum, 0),
                    [info.rows]
                )

                return <>{Utilities.toDollar(total)}</>
            },
        },
        {
            Header: "Total",
            id: 'total',
            export: false,
            accessor: (tc) => Utilities.toDollar(+tc.total),
            Footer: info => {
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.total + sum, 0),
                    [info.rows]
                )

                return <>{Utilities.toDollar(total)}</>
            },
        }
    ]

    const findLocations = () => {
        AppointmentService.practiceLocationLookup()
            .then((res) => {
                setLocationList(res)
                setLocationId(res[0].practiceLocationId)

            })
            .catch((err) => {
                console.log(err);
            });
    }


    const getReport = () => {
        setIsLoader(true);
        setInitialMessage(false);
        setData()
        let reqObj = {
            FromDate: moment(startDate).toISOString(),
            ToDate: moment(endDate).toISOString(),
            // ProviderId: selectedProvider?.providerId,
            GroupBy: slabBy
        }
        if (locationId) {
            reqObj.PracticeLocationId = locationId
        }

        return ReportService.getReconciliationReport(reqObj)
            .then(res => {
                console.log(res)
                let results = res
                let credit = 0
                let ach = 0
                let cash = 0
                let check = 0
                let terminal = 0
                results.forEach(tc => {
                    credit += tc.credit
                    cash += tc.cash
                    check += tc.check
                    ach += tc.ach
                    terminal += tc.terminal
                    tc.total = tc.credit + tc.check + tc.ach + tc.cash + tc.terminal
                })
                setIsLoader(false);
                return setData(results)
            }
            )
            .catch(err => { console.log(err) })
    }


    return (
        <>
        {isLoader &&
            <DimLoader loadMessage="Processing" />
        }    
    
    <div className='row d-flex g-3'>
            <div className='col-12 m-p-0'>
                <Module title="Filters">
                    <div className='row d-flex'>
                        <div className='col-12 justify-content-center mx-0 align-items-end report-filters'>
                            <div className='col-52 row d-flex justify-content-center mx-0 align-items-end report-filters'>
                            <DatePicker setLocationId={setLocationId} startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} setSlabBy={setSlabBy} setTimePeriod={setTimePeriod}/>


                                <div className="col-md-auto col-12">
                                    <button className="btn btn-primary" onClick={e => { e.preventDefault(); setListSlabBy(slabBy); return getReport() }} title="Pull Report">
                                        <i className="icon arrow circle right" />
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </Module>
            </div>
            <div className='col-12 m-p-0'>
                <Module title="Payment Reconciliation Report">
                    <Table2 export title="Payment Reconciliation Report" data={data} columns={columns} loading={isLoader} initialMessage={initialMessage} />
                </Module>
            </div>
        </div>
        </>
    )
}
export default PaymentReconciliationReport