import React, { useState, useEffect } from 'react';
import InvoiceService from '../../../../../services/api/invoice.service';
import Utilities from '../../../../../services/commonservice/utilities';
import * as moment from "moment";
import Module from '../../../../templates/components/Module';
import Table2 from '../../../../templates/components/Table2';
import DatePicker from '../../../../templates/components/DatePicker';
import DimLoader from '../../../../templates/components/DimLoader'


const TaxReport = (props) => {
    const [isLoader, setIsLoader] = useState(false)
    const [newInitialReqObject, setNewInitialReqObject] = useState({})
    const [data, setData] = useState()
    const [offsetHour, setOffsetHour] = useState(moment().utcOffset() >= 0 ? Math.floor(moment().utcOffset() / 60) : Math.ceil(moment().utcOffset() / 60),)
    const [offsetMinute, setOffsetMinute] = useState(moment().utcOffset() % 60)
    const [slabBy, setSlabBy] = useState('Month')
    const [startDate, setStartDate] = useState(moment().startOf("D"));
    const [endDate, setEndDate] = useState(moment().endOf("D"));
    const [initialMessage, setInitialMessage] = useState(true)

    // const for table 
    const columns = [
        // ["Name", "Equipment Type", "Room", "Actions"]
        {
            id: "TransactionDate",
            Header: "Transaction Date",
            accessor: (invoice, i) => moment(invoice.transactionDate).format("MM-DD-YYYY H:mm A"),
            Footer: () => "Totals"
        },
        {
            id: "InvoiceNumber",
            Header: "Invoice Number",
            accessor: (invoice) => invoice.invoiceNumber
        },
        {
            id: "PaymentType",
            Header: "Payment Method",
            accessor: (invoice) => invoice.paymentType === "Terminal"? "Swipe" : invoice.paymentType
        },
        {
            id: "Name",
            Header: "Name",
            accessor: (invoice) => invoice.name
        },
        {
            id: "UnitPrice",
            Header: "Unit Price",
            accessor: (invoice, i) => Utilities.toDollar(invoice.unitPrice)
        },
        {
            id: "Quantity",
            Header: "Quantity",
            accessor: (invoice) => invoice.quantity
        },
        {
            id: "ItemSubTotal",
            Header: "SubTotal",
            accessor: (invoice, i) => isNaN(invoice.itemSubTotal) ? invoice.ItemSubTotal : Utilities.toDollar(invoice.itemSubTotal),
            Footer: info => {
                // Only calculate total visits if rows change
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.itemSubTotal + sum, 0),
                    [info.rows]
                )

                return <>{Utilities.toDollar(total)}</>
            },
        },
        {
            id: "TaxAmount",
            Header: "Tax",
            accessor: (invoice, i) => isNaN(invoice.taxAmount) ? invoice.taxAmount : Utilities.toDollar(invoice.taxAmount),
            Footer: info => {
                // Only calculate total visits if rows change
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.taxAmount + sum, 0),
                    [info.rows]
                )

                return <>{Utilities.toDollar(total)}</>
            },
        },
        {
            id: "ItemTotal",
            Header: "Item Total",
            accessor: (invoice, i) => isNaN(invoice.itemTotal) ? invoice.itemTotal : Utilities.toDollar(invoice.itemTotal),
            Footer: info => {
                // Only calculate total visits if rows change
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.itemTotal + sum, 0),
                    [info.rows]
                )

                return <>{Utilities.toDollar(total)}</>
            },
        },
    ]
    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        filename: "Tax Report",
        button: {
            excel: true,
            print: true,
            csv: true,
            extra: true
        },
        language: {
            loading_text: "Please be patient while data loads..."
        }
    }


    const getTaxReport = (downloadFormat) => {
        setInitialMessage(false);
        let newInitialReqObject = {
            StartDate: moment(startDate).toISOString(),
            EndDate: moment(endDate).toISOString(),
            offsetHour: offsetHour,
            offsetMinute: offsetMinute
        }
        setIsLoader(true);
        InvoiceService.getTaxReport(newInitialReqObject)
            .then(res => {
                // console.log(res)
                if (res && res.length > 0) {
                    setData(res)
                    setIsLoader(false)
                    console.log(res)
                    let TotalAmountOfItemSubTotal = 0;
                    let TotalAmountOfItemTotal = 0;
                    let TotalQuantity = 0;
                    let TotalTaxAmount = 0;
                    res.forEach(element => {
                        // console.log(element)
                        // element.UnitPrice = '$' + formatNumber(element.UnitPrice, "en-US", '1.2-2');
                        //console.log(element.TransactionDate)
                        element.transactionDate = moment.utc(element.transactionDate, 'YYYY-MM-DDTHH:mm:ss.SSSz').local().format('MM/DD/YYYY') || null;
                        //console.log(element.TransactionDate)
                        TotalAmountOfItemSubTotal = TotalAmountOfItemSubTotal + element.itemSubTotal || null;
                        TotalAmountOfItemTotal = TotalAmountOfItemTotal + element.itemTotal || null;
                        TotalQuantity = TotalQuantity + element.quantity || null;
                        TotalTaxAmount = TotalTaxAmount + element.taxAmount || null;
                        // element.ItemSubTotal = formatNumber(element.ItemSubTotal, "en-US", '1.2-2');
                        // element.ItemTotal = formatNumber(element.ItemTotal, "en-US", '1.2-2');
                        // element.TaxAmount = formatNumber(element.TaxAmount, "en-US", '1.2-2');
                    });
                    //adding total record at the end of array object
                    res.push({
                        TransactionDate: '', InvoiceNumber: '', Name: '',
                        UnitPrice: '', Quantity: TotalQuantity,
                        TaxAmount: Utilities.toDollar(TotalTaxAmount), ItemSubTotal: Utilities.toDollar(TotalAmountOfItemSubTotal), ItemTotal: Utilities.toDollar(TotalAmountOfItemTotal), PaymentType: ''
                    })
                    //console.log(res.length)
                    if (downloadFormat === 'CSV') {
                        if (Utilities.exportToCsv(res, 'Tax_Report.csv')) {
                            setIsLoader(false);
                        }
                    }
                    if (downloadFormat === 'PDF') {
                        const pdfdata = Utilities.exportToPdf(res, 'Tax_Report.csv');
                        // console.log(pdfdata);
                        if (pdfdata) {
                            const filters = {};
                            Utilities.pdf(pdfdata, filters, 'Tax_Report.pdf');
                            setIsLoader(false);
                        }
                    }
                } else {
                    setIsLoader(false);
                    console.log("No Record Found");
                }
            }
            ).catch(err => { console.log(err); setIsLoader(false); })
    }



    return (
        <>
        {isLoader &&
            <DimLoader loadMessage="Processing" />
        }    
    
            <div className='row d-flex g-3'>
                <div className='col-12 m-p-0'>
                    <Module title="Filters">
                        <div className='row d-flex'>
                            <div className='col-12  justify-content-center mx-0  align-items-end report-filters'>
                                <div className='col-52 row d-flex justify-content-center mx-0 align-items-end report-filters'>
                                <DatePicker startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} />
                                    <div className="col-md-auto col-12"><button className="btn btn-primary" onClick={e => { e.preventDefault(); getTaxReport() }} title="Pull Report"><i className="icon arrow circle right" /></button></div>
                                </div>
                            </div>
                        </div>
                    </Module>
                </div>
                <div className="col-12 m-p-0">
                    <Module title="Tax Report">
                        <div className='m-p-0'>
                            <Table2 export searchable title="Tax Report" data={data} columns={columns} loading={isLoader} initialMessage={initialMessage}/>
                        </div>
                    </Module>
                </div>
            </div>
        </>
    )
}

export default TaxReport