import React, { useEffect, useState, useContext } from "react";
import Module from "../../../../templates/components/Module";
import moment from "moment";
import AppointmentService from "../../../../../services/api/appointment.service";
// import CommonService from '../../../../../services/api/common.service'
import { store } from "../../../../../context/StateProvider";
import PracticeLocationSelector from "../../../../templates/components/PracticeLocationSelector";
import Table2 from "../../../../templates/components/Table2";
import DatePicker from "../../../../templates/components/DatePicker";
import DimLoader from '../../../../templates/components/DimLoader'


const AppointmentsTable = (props) => {
  const [appointments, setAppointments] = useState();
  // const [keyword, setKeyword] = useState("64Q2M1xn")
  const [keyword, setKeyword] = useState("");
  const [sortBy, setSortBy] = useState('Desc')
  const [isLoader, setIsLoader] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf("D"));
  const [endDate, setEndDate] = useState(moment().endOf("D"));
  const state = useContext(store).state
  const [initialMessage, setInitialMessage] = useState(true)
  const [locationId, setLocationId] = useState(state.practiceLocationId || null)




  const appointmentLookup = () => {
    if (state.practiceLocationId) {
      let reqObj = {
        FromDate: moment(startDate).toISOString(),
        ToDate: moment(endDate).toISOString(),
        SortField: "ToDate",
        Asc: true
      };
      if (locationId) {
        reqObj.Location = locationId
      }      
      setInitialMessage(false);
      setIsLoader(true);
      AppointmentService.findAppointment(reqObj)
        .then((res) => {
          if (res) {
            console.log(res);
            setAppointments(res.filter(obj => obj.practiceServiceType !== '**unavailable**'));
            setIsLoader(false);
          }
        })
        .catch((err) => {
         setIsLoader(false)
          console.log(err)
        });
    }
  }

  const columns = [
    {
      id: "fromDate",
      Header: "Start",
      accessor: (appt) => moment(appt.fromDate).format("dddd, MMM D, YYYY")
    },
    {
      id: "duration",
      Header: "Duration",
      accessor: (appt) => appt.duration + ' mins'
    },
    {
      id: "firstName",
      Header: "First Name",
      accessor: (appt) => appt.firstName
    },
    {
      id: "lastName",
      Header: "Last Name",
      accessor: (appt) => appt.lastName
    },
    {
      id: "practiceServiceType",
      Header: "Service",
      accessor: (appt) => appt.practiceServiceType
    },
   {
      id: "drFirstName",
      Header: "Provider First Name",
      accessor: (appt) => appt.drFirstName
    },
    {
      id: "drLastName",
      Header: "Provider Last Name",
      accessor: (appt) => appt.drLastName
    },
  ]

  return (
    <>
    {isLoader &&
        <DimLoader loadMessage="Processing" />
    }    

    <div className="row d-flex g-3">
      <div className="col-12 m-p-0">
        <Module title="Filters">
          <div className='row d-flex'>
            <div className='col-12 row d-flex justify-content-center mx-0 align-items-end report-filters'>
            <DatePicker setLocationId={setLocationId} startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} />
              <div className="col-md-auto col-12">
                <button className="btn btn-primary" onClick={e=>{e.preventDefault(); appointmentLookup()}} title="Pull Report">
                  <i className="icon arrow circle right"/>
                </button>
              </div>
            </div>
          </div>
        </Module>
      </div>
      <div className="col-12 m-p-0">
        <Module title="Appointment Report">
         <Table2 export searchable title="Appointment Report" data={appointments} loading={isLoader} columns={columns} initialMessage={initialMessage}/>
        </Module>
      </div>
    </div>
    </>
  );
};

export default AppointmentsTable;
