import React, { useState, useEffect } from "react";
import DimLoader from "../../../../templates/components/DimLoader";
import EditLead from "./EditLead";
import LeadSourceService from "../../../../../services/api/lead-source.service";
import APIResponse from "../../../../templates/components/APIResponse";

const LeadWrapper = (props) => {

    const [isLoading, setIsLoading] = useState();
    const [lead, setLead] = useState();

    const [leadId, setLeadId] = useState(props?.leadId);
    const [apiResponse, setApiResponse] = useState()
    const onSuccess = (message) => {
        setLead(apiResponse.data);
    }
    const onError = (message) => {
        if(props.closeModal){
            props.closeModal()
        }
    }
    const fetchLeadDetails = async () => {

        setIsLoading(true);
        LeadSourceService.getLeadById(leadId)
        .then(res => {
            setApiResponse(res)
            setIsLoading(false)
        })
        .catch(err => {
            setApiResponse(err)
            setIsLoading(false) 
        })
    }

    useEffect(() => {
        if (leadId) fetchLeadDetails();
    }, [leadId])


    return (
        <>
            {isLoading ? <DimLoader loadMessage="Loading..." /> : null}
            {lead ? 
                <EditLead
                    id={leadId}
                    initialData={lead}
                    onClose={props.onClose}
                    closeModal={props.closeModal}
                />
            : null }
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} onError={onError} toastOnFailure={true}/>
        </>
  );
};

export default LeadWrapper;






