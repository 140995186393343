import { useEffect, useState, useContext } from 'react'
import AppointmentService from "../api/appointment.service"
import PatientService from '../api/patient.service';
import PracticeLocationAvailabilityService from '../api/practice-location-availablity.service';
import PlFormsService from '../api/plforms.service';
import InvoiceService from '../api/invoice.service';
import CreditBalanceService from '../api/credit-balance.service';

import { store } from "../../context/StateProvider" ;

const CacheUtilities = {

    async pullAndCachePracticeAndLocationData(newLocationId, dispatch) {

        const [
            appointmentStatuses, patientTypes, patientTags,
            availability, equipment, providerServices, rooms, serviceTypes] = await Promise.all([

            this.pullAPIData(
                {
                    APIService: "statusLookup",
                    SortBy: "stepOrder"
                }
            ),

            this.pullAPIData(
                {
                    APIService: "practicePatientTypeLookup",
                    SortBy: "patientType"
                }
            ),

            this.pullAPIData(
                {
                    APIService: "getAllLookupTags",
                    Service: PatientService,
                    SortBy: "name"
                }
            ),

            this.pullAPIData(
                {
                    practiceLocationId:newLocationId,
                    APIService: "practiceLocationAvailablity",
                    Service: PracticeLocationAvailabilityService,
                    Parameters: { PracticeLocationId: newLocationId }
                }
            ),

            this.pullAPIData(
                {
                    practiceLocationId:newLocationId,
                    APIService: "equipmentLookup",
                    SortBy: "description"
                }
            ),

            this.pullAPIData(
                {
                    practiceLocationId:newLocationId,
                    APIService: "findWithServices",
                    SortBy: "",
                    Parameters: { isRegistered: true, isActive: true, PracticeLocationId: newLocationId, SortField: 'firstName' }
                }
            ),

            this.pullAPIData(
                {
                    practiceLocationId:newLocationId,
                    APIService: "practiceLocationRoomLookup",
                    SortBy: "room"
                }       
            ),
           
            this.pullAPIData(
                {
                    practiceLocationId:newLocationId,
                    APIService: "getPracticeServiceTypeForLocation",
                    SortBy: "practiceServiceType"
                }                
            ),

        ]);

        dispatch({
            type: "setAllPracticeAndLocationValues",
            payload:
                {
                    appointmentStatuses:appointmentStatuses,
                    patientTypes:patientTypes,
                    patientTags:patientTags,

                    locationAvailability:availability,
                    equipment:equipment,
                    providerServices:providerServices,
                    rooms:rooms,
                    serviceTypes:serviceTypes,
                    practiceLocationId:newLocationId
                }
        });

    },

    async pullAndCacheAllLocationData(newLocationId, dispatch) {

        const [availability, equipment, providerServices, rooms, serviceTypes] = await this.pullAllLocationData(newLocationId);

        dispatch({
            type: "setAllLocationValues",
            payload:
                {
                    locationAvailability:availability,
                    equipment:equipment,
                    providerServices:providerServices,
                    rooms:rooms,
                    serviceTypes:serviceTypes,
                    practiceLocationId:newLocationId
                }
        });

    },

    async pullAllLocationData(newLocationId) {
        return await Promise.all([

            this.pullAPIData(
                {
                    practiceLocationId:newLocationId,
                    APIService: "practiceLocationAvailablity",
                    Service: PracticeLocationAvailabilityService,
                    Parameters: { PracticeLocationId: newLocationId }
                }
            ),

            this.pullAPIData(
                {
                    practiceLocationId:newLocationId,
                    APIService: "equipmentLookup",
                    SortBy: "description"
                }
            ),

            this.pullAPIData(
                {
                    practiceLocationId:newLocationId,
                    APIService: "findWithServices",
                    SortBy: "",
                    Parameters: { isRegistered: true, isActive: true, PracticeLocationId: newLocationId, SortField: 'firstName' }
                }
            ),

            this.pullAPIData(
                {
                    practiceLocationId:newLocationId,
                    APIService: "practiceLocationRoomLookup",
                    SortBy: "room"
                }       
            ),
            
            
            this.pullAPIData(
                {
                    practiceLocationId:newLocationId,
                    APIService: "getPracticeServiceTypeForLocation",
                    SortBy: "practiceServiceType"
                }                
            ),

        ]);

    },


    async pullAPIData(props) {
        let parameters;
        if (props?.Parameters) {
            parameters = props?.Parameters;
        }
        else if (props?.practiceLocationId) {
            parameters = props.practiceLocationId;
        }

        let service = AppointmentService;
        if (props.Service) {
            service = props.Service
        }

        return service[props.APIService](parameters)
        .then(
            (response) => {
                if (response) {
                    let result
                    if (props?.isObject) {
                        if (response?.data) {
                            return response?.data;
                        }
                        else {
                            return response;
                        }
                    }
                    else {
                        if (Array.isArray(response)) {
                            result = response
                        }
                        else if (response?.data && Array.isArray(response?.data)) {
                            result = response?.data
                        }
                        else if (response?.selectResponse && Array.isArray(response?.selectResponse)) {
                            result = response?.selectResponse
                        }
                        if (result) {
                            if (props.SortBy) {
                                let sortedTypes = result.sort((a, b) => a[props.SortBy].localeCompare(b[props.SortBy]));
                                return sortedTypes;
                            }
                            else {
                                return result;
                            }
                        }
                    }
                }
            })
        .catch(
            (error) => {
                return null;
            }
        );
    }

}

export default CacheUtilities