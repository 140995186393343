import InputMask from 'react-input-mask'
import moment from 'moment'
import * as moment1 from "moment-timezone";

import React, { useLayoutEffect, useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import SettingsService from '../../../services/api/settings.service'
import ThemeService from '../../../services/api/theme.service'
import AppointmentService from '../../../services/api/appointment.service'
import LoginService from '../../../services/api/login.service'
import StorageService from '../../../services/session/storage.service'

import Select from 'react-select'

import Utilities from '../../../services/commonservice/utilities';

import TimeZoneEnum from '../../../common/enum/time-zone.enum' 

import toast from 'react-hot-toast'

const PublicSchedule = (props) => {
    // Params
    // http://localhost:3000/schedule/dOxkopx9/skinbodymemphis
    const params = useParams()
    // const params = { providerId: 'dOxkopx9', providerSlug: 'skinbodymemphis' }

    //Function
    const [activeTab, setActiveTab] = useState(0)
    const [isLoader, setIsLoader] = useState(true)

    //Branding
    const [logo, setLogo] = useState()
    const [skin, setSkin] = useState()

    // form information
    const [locations, setLocations] = useState()
    const [selectedLocation, setSelectedLocation] = useState()

    const [services, setServices] = useState()
    const [selectedService, setSelectedService] = useState()

    const [providersAndServices, setProvidersAndServices] = useState()
    const [masterServices, setMasterServices] = useState()
    
    const [providers, setProviders] = useState()
    const [selectedProvider, setSelectedProvider] = useState()

    const [slots, setSlots] = useState()
    const [selectedSlot, setSelectedSlot] = useState()

    const [patientPhone, setPatientPhone] = useState()
    const [otp, setOTP] = useState()

    const [email, setEmail] = useState()
    const [patientId, setPatientId] = useState()
    const [phone, setPhone] = useState()

    const [errorMessage, setErrorMessage] = useState()
    
    const providerId = useMemo(() => {
        return params.providerId
    }, [params])
    const providerSlug = useMemo(() => {
        return params.providerSlug
    }, [params])

    const fetchLogo = () => {
        console.log('fetchLogo')
        if (providerSlug) {
            SettingsService.getProviderSettingsLogo(providerSlug)
                .then(res => {
                    console.log(res)
                    if (res?.data?.logo) {
                        setLogo(res.data.logo)
                    }
                })
                .catch((err) => {
                    console.log(err);
                })                
        }
    }

    const fetchSkin = () => {
        console.log('fetchSkin')
        if (providerSlug) {
            SettingsService.getProviderSettingsSkin(providerSlug)
                .then(res => {
                    console.log(res)
                    if (res?.data?.skin) {
                        setSkin(res.data.skin)
                    }
                })
                .catch((err) => {
                    console.log(err);
                })                
        }
    }

    useEffect(() => {
        console.log('useEffect [providerSlug] 1')
        fetchLogo()
        fetchSkin()
    }, [providerSlug])

    useEffect(() => {
        console.log('useEffect [providerId]')
        getLocations()
    }, [providerId])

    useLayoutEffect(() => {
        console.log('useLayoutEffect [logo, skin]')
        if (logo && skin) {
            ThemeService.changeTheme(skin)
        }
    }, [logo, skin])

    const getLocations = () => {
        console.log('getLocations')
        if (providerId) {
            AppointmentService.publicPracticeLocationLookup({ parentId: providerId })
                .then(res => {
                    console.log(res)
                    if (Array.isArray(res)) {
                        let newData = [...res]
                        setLocations(newData)

                        if (newData.length>0) {
                            setSelectedLocation(newData[0])
                        }
                        else{
                            setSelectedLocation()
                        }

                        setIsLoader(false)
                    }
                })
                .catch((err) => {
                    console.log(err);
                })                
        }
        else{
            setSelectedLocation()
        }
    }

    const getLocationServicesAndDoctors = () => {
        console.log('getLocationServicesAndDoctors')

        if (selectedLocation) {
            getServices()
            getProviders()
        }
        else {
            setServices()
            setSelectedService()
            
            setProviders()
            setSelectedProvider()

            setMasterServices()
            setProvidersAndServices()
        }
    }

    const getServices = () => {
        console.log('getServices')
        AppointmentService.publicPracticeServiceTypeForLocation(selectedLocation.practiceLocationId, providerId)
            .then(res => {
                console.log(res)
                if (res)
                {
                    setServices([...res])
                    setMasterServices([...res])
                }
                else {
                    setServices()
                    setMasterServices()
                }
                setSelectedService()
            })
            .catch((err) => {
                console.log(err);
            })                
    }

    const getProviders = () => {
        console.log('getProviders')
        AppointmentService.publicDoctorLookupByPracticelocation(providerId, {PracticeLocationId:selectedLocation.practiceLocationId} )
            .then(res => {
                console.log(res)
                let doctors = []
                if (res) {
                    setProvidersAndServices([...res])
                    doctors = res.map(element => {
                        return {
                                id: element.id,
                                prompt: element.firstName + " " + element.lastName
                        }
                    })
                }
                else {
                    doctors = ""
                }
                setProviders(doctors)
                setSelectedProvider()
            })
            .catch((err) => {
                console.log(err);
            })                
    }

    const refreshProviders = () => {
        console.log('refreshProviders')
        let res = providersAndServices;
        let currentDoctorIsValid = false;
        let doctors = []
        if (res) {
            doctors = 
            res
                .filter(doctor => { return !selectedService || doctor.services.find(obj => obj.practiceServiceTypeId === selectedService.practiceServiceTypeId) })
                .map(element => 
                    {
                        currentDoctorIsValid = currentDoctorIsValid || (selectedProvider && element.id == selectedProvider?.id);
                        return {
                                id: element.id,
                                prompt: element.firstName + " " + element.lastName
                        }
                    }
                )
        }
        else {
            doctors = ""
        }
        setProviders(doctors)
        if (!currentDoctorIsValid) setSelectedProvider();

    }
    
    const refreshServices = () => {
        console.log('refreshServices')
        let services = []
        if (!selectedProvider) {
            // If a doctor is not selected, use master services list
            services = masterServices;
        }
        else {
            // otherwise, use services of the doctor selected
            services = providersAndServices.find(doctor => doctor.id == selectedProvider.id).services;
        }
        if (services) {
            setServices([...services]);
        }
        else
        {
            setServices([]);
        }
        setServices(services || []);
        if (selectedService && !services.find(service => service.practiceServiceTypeId == selectedService?.practiceServiceTypeId)) setSelectedService();
    }

    const goToTab01 = () => {
        console.log('goToTab01')
        getTimeSlots();
    }

    const getTimeSlots = () => {
        console.log('getTimeSlots')

        // todo, if doctor and service selected, only show time slots and dates
        // if service selected, show by doctor the times and dates
        // dont enable the button unless service selected  
        setIsLoader(true);
        if (selectedLocation)
        {
            let reqObj = 
            {
                practiceLocationId:selectedLocation.practiceLocationId,
                calendarDate:moment().format("YYYY-MM-DD"),
                equipmentTypeId:"",
                doctorIds:null
            };

            if (selectedProvider && selectedProvider?.id) reqObj.doctorIds = selectedProvider.id;
            if (selectedService && selectedService?.practiceServiceTypeId) reqObj.serviceTypeId = selectedService.practiceServiceTypeId;
    
            AppointmentService.publicCheckAvailability(providerId, reqObj)
                .then(res => {
                    setIsLoader(false);
                    console.log(res)
                    setSlots(res)

                    if (Array.isArray(res) && res.length>0) {
                        setActiveTab(1);
                    }
                    else 
                    {
                        setErrorMessage("No time slots available")
                    }


                })
                .catch((err) => {
                    setIsLoader(false);
                    console.log(err);
                })                
        }
    }

    const goToTab02 = () => {
        console.log('goToTab02')
        setActiveTab(2);
    }

    const sendOTP = () => {
        console.log('sendOTP')
        setIsLoader(true);
        LoginService.patientLoginOTP(
            {
                "userName": patientPhone,
                "password": "",
                "authCode": "",
                "RememberMe": null,
                "sendCatalog": true
            })
            .then(res => {
                setIsLoader(false);
                if (res.isAxiosError) {
                    setErrorMessage("Please verify your contact information")
                }
                else
                {
                    toast.success("OTP requested");
                    setOTP()
                    setActiveTab(3);
                }
            })
            .catch((err) => {
                setIsLoader(false);
                setErrorMessage("An error occurred requesting the OTP")
            })                

    }

    const loginViaOTP = () => {
        console.log('loginViaOTP')
        setIsLoader(true);
        LoginService.patientLoginViaOTP(
            {
                "userName": patientPhone,
                "password": "",
                "authCode": otp,
                "RememberMe": null,
                "sendCatalog": true,
                "otp": otp,
                "providerUrl": ""
            })
            .then(response => {
                if (response.isAxiosError) {
                    setIsLoader(false);
                    setErrorMessage("OTP is invalid or expired")
                    return
                }

                let loginResponse = response.data;

                // response.data.id is user Id
                // response.data.parentId is the patient Id
                let localPatientId = response.data.parentId;
                setPatientId(localPatientId);

                StorageService.save(
                    'session',
                    "auth",
                    JSON.stringify(loginResponse)
                );                

                LoginService.getloginUserData(
                    loginResponse?.userType,
                    loginResponse?.id,
                    loginResponse?.parentId
                )
                .then(
                    (res) => {
                        if (response.isAxiosError) {
                            setIsLoader(false);
                            setErrorMessage("Issue logging in")
                            return
                        }
        
                        let userDataResponse = res.data
                        console.log(userDataResponse)

                        setEmail(res.data.contact.email);
                        setPhone(res.data.contact.mobile);
                    
                        userDataResponse.publicSchedule = 1;

                        StorageService.save(
                            'session',
                            "userDetails",
                            JSON.stringify(userDataResponse)
                        );
                        bookAppointment(localPatientId, res.data.contact.email, res.data.contact.mobile);

                    })
                .catch(error => {
                    setIsLoader(false);
                    console.log(error) 
                    setErrorMessage("An error occurred logging in")
                });
            })
            .catch((err) => {
                setIsLoader(false);
                console.log(err) 
                setErrorMessage("An error occurred logging in")
            })                
    }

    const contactChange = (e) => {
        console.log('contactChange')
        let newValue = e.target.value
        if (e.target.name == 'phone') {
            newValue = e.target.value.replaceAll(/[^a-zA-Z0-9]/g, "")
            console.log(newValue)
            return setPatientPhone(newValue);
        }
    }    

    const updateOTP = (e) => {
        console.log('updateOTP')
        let newValue = e.target.value
        if (e.target.name == 'otp') {
            newValue = e.target.value.replaceAll(/[^a-zA-Z0-9]/g, "")
            console.log(newValue)
            return setOTP(newValue);
        }
    }    

    const bookAppointment = (_patientId, _email, _mobile) => {
        setIsLoader(true);

        console.log('bookAppointment')

        let reqObj=
        {
            "day": 2,
            "doctorId": selectedProvider.id,
            "duration": selectedSlot.duration,
            "email": _email,
            "fromDate": selectedSlot.startTime,
            "patientId": _patientId,
            "phone": _mobile,
            "practiceAppointmentStatusCodeId": "n3WB8xog",
            "practiceLocationId": selectedLocation.practiceLocationId,
            "practiceServiceTypeId": selectedService.practiceServiceTypeId,
            "timeZone": 58, // TimeZoneEnum[moment1.tz.guess()] != undefined ? TimeZoneEnum[moment1.tz.guess()] : TimeZoneEnum["Default"],
            "toDate":  selectedSlot.endTime,
            "childAppointments": []
        }
        
        return AppointmentService.publicAddAppointment(providerId, reqObj)
        .then((res) => { 
            setIsLoader(false);

            console.log(res) 

            if (res.isAxiosError) {
                setErrorMessage("There was an issue booking the appointment")
                return
            }

            toast.success("Appointment booked!");
            setActiveTab(5);
        })
        .catch((err) => { 
            setIsLoader(false);
            console.log(err) 
            setErrorMessage("There was an issue booking the appointment")
        });    
    }

    useEffect(() => {
        console.log("useEffect [selectedLocation]")
        getLocationServicesAndDoctors()
    }, [selectedLocation])

    useEffect(() => {
        console.log("useEffect [selectedService]")
        refreshProviders()
    }, [selectedService])

    useEffect(() => {
        console.log("useEffect [selectedProvider]")
        refreshServices();
    }, [selectedProvider])

    useEffect(() => {
        setErrorMessage()
    }, [activeTab])

    return (
        <div className='fill-screen'>
            {isLoader && <div className="ui active dimmer">
                <div className="ui indeterminate text loader">Loading</div>
            </div>}

                <div className='fill-screen p-3 justify-content-center align-items-stretch'>
                    <div className='container overflow-clip'>
                        <div className='card w-100 p-3'>
                            <div className='row'>


                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12 justify-content-center mb-3'>
                                            <div className='nav-image' style={{ backgroundImage: `url(${logo})` }} />
                                        </div>
                                        <div className='col-12 justify-content-center mb-3'>
                                            <h5>
                                                NOTE: For registered clients only
                                            </h5>
                                        </div>
                                        <div className='col-12'>
                                            <h5>
                                                <div >
                                                    <div>
                                                        What To Schedule
                                                    </div>

                                                    <ul>
                                                        {selectedLocation ? <li>{selectedLocation.practiceLocation}</li> : null}
                                                        {selectedService ? <li>{selectedService.practiceServiceType}</li> : null}
                                                        {selectedProvider ? <li>{selectedProvider.prompt}</li> : null}
                                                    </ul>
                                                    
                                                </div>
                                            </h5>
                                            <hr />
                                        </div>
                                        <div className='col-12'>
                                            <h5>

                                                <div>
                                                    <div>When</div>

                                                    <ul>
                                                        {selectedSlot ? <li>{selectedSlot.formattedStartDateTime}</li> : null}
                                                    </ul>

                                                </div>

                                            </h5>
                                            <hr />
                                        </div>
                                        <div className='col-12'>
                                            <h5>
                                                <div>
                                                    <div>Your Info</div>

                                                    <ul>
                                                        {patientPhone ? <li className='col-auto'>{Utilities.toPhoneNumber(patientPhone)}</li> : null}
                                                    </ul>
                                                    
                                                </div>
                                            </h5>
                                            <hr />
                                        </div>

                                        <div className='col-12'>
                                            <div className='card p-3'>
{
    //#region Location, Provider, Service
}                                                
                                                {activeTab == 0 ?
                                                    <>
                                                    <div className='row justify-content-center d-inline-block'>
                                                        <div className='col-auto'>
                                                            <h5>Location</h5>
                                                        </div>
                                                        <div className='col-12'>
                                                            <Select
                                                                className='react-select-container'
                                                                classNamePrefix='react-select'
                                                                options={locations}
                                                                value={selectedLocation}
                                                                isLoading={!locations}
                                                                onChange={e => {
                                                                    if (e?.practiceLocationId) {
                                                                        setSelectedLocation(e)
                                                                    }
                                                                    else{
                                                                        setSelectedLocation()
                                                                    }
                                                                }}
                                                                getOptionLabel={(option) => option.practiceLocation}
                                                                getOptionValue={(option) => option.practiceLocationId}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className='row justify-content-center d-inline-block'>
                                                        <div className='col-auto mt-3'>
                                                            <h5>Service</h5>
                                                        </div>
                                                        <div className='col-12'>

                                                            <Select
                                                                className='react-select-container'
                                                                classNamePrefix='react-select'
                                                                options={services}
                                                                value={(services && selectedService ? services.find(obj => obj.practiceServiceTypeId === selectedService.practiceServiceTypeId) : null)}
                                                                isLoading={!services}
                                                                isClearable={true}
                                                                onChange={e => {
                                                                    if (e?.practiceServiceTypeId) {
                                                                        setSelectedService(e)
                                                                    }
                                                                    else{
                                                                        setSelectedService()
                                                                    }
                                                                }}
                                                                getOptionLabel={(option) => option.practiceServiceType}
                                                                getOptionValue={(option) => option.practiceServiceTypeId}
                                                            />
                                                            
                                                        </div>
                                                    </div>

                                                    <div className='row justify-content-center d-inline-block'>
                                                        <div className='col-auto mt-3'>
                                                            <h5>Provider</h5>
                                                        </div>
                                                        <div className='col-12'>

                                                            <Select
                                                                className='react-select-container'
                                                                classNamePrefix='react-select'
                                                                options={providers}
                                                                value={(providers && selectedProvider ? providers.find(obj => obj.id === selectedProvider.id) : null)}
                                                                isLoading={!providers}
                                                                isClearable={true}
                                                                onChange={e => {
                                                                    if (e?.id) {
                                                                        setSelectedProvider(e)
                                                                    }
                                                                    else{
                                                                        setSelectedProvider()
                                                                    }
                                                                }}
                                                                getOptionLabel={(option) => option.prompt}
                                                                getOptionValue={(option) => option.id}
                                                            />
                                                            
                                                        </div>

                                                    </div>

                                                    <div className='col-12'>
                                                        <div className="d-flex row justify-content-between mt-3">
                                                            <div className={`col-auto `}></div>
                                                            <div className="col-auto">
                                                                <button onClick={e => { e.preventDefault(); goToTab01() }} className="btn btn-primary">Find Date/Time</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    </>                                                    
                                                    : null}

{
    //#endregion
}

{
    //#region Time Slot
}
                                                {activeTab == 1 ?

                                                    <>

                                                    <div className='row justify-content-center d-inline-block'>
                                                        <div className='col-auto mt-3'>
                                                            <h5>Select Date and Time</h5>
                                                        </div>
                                                        <div className='col-12'>

                                                            <Select
                                                                className='react-select-container'
                                                                classNamePrefix='react-select'
                                                                options={slots}
                                                                value={selectedSlot}
                                                                isLoading={!slots}
                                                                onChange={e => {
                                                                    if (e?.formattedStartDateTime) {
                                                                        setSelectedSlot(e)
                                                                    }
                                                                    else{
                                                                        setSelectedSlot()
                                                                    }
                                                                }}
                                                                getOptionLabel={(option) => option.formattedStartDateTime}
                                                                getOptionValue={(option) => option.formattedStartDateTime}
                                                            />
                                                            
                                                        </div>

                                                    </div>

                                                    <div className='col-12'>
                                                        <div className="d-flex row justify-content-between mt-3">
                                                            <div className={`col-auto `}>
                                                                <button onClick={e => { e.preventDefault(); setActiveTab(0); }} className="btn btn-primary">Back</button>
                                                            </div>
                                                            <div className="col-auto">
                                                                <button onClick={e => { e.preventDefault(); goToTab02() }} className="btn btn-primary">Provide Your Info</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    </>

                                                    : null}

{
    //#endregion
}

{
    //#region Patient Info
}

                                                {activeTab == 2 ?

                                                    <>

                                                    <div className='row justify-content-center d-inline-block'>
                                                        <div className='col-auto mt-3'>
                                                            <h5>Mobile Phone</h5>
                                                        </div>
                                                        <div className='col-12'>

                                                            <InputMask
                                                                placeholder="Phone"
                                                                type="text"
                                                                name="phone"
                                                                mask="(999) 999-9999"
                                                                unmask={true}
                                                                value={patientPhone}
                                                                onChange={(e) => {
                                                                    e.preventDefault();
                                                                    contactChange(e);
                                                                }}
                                                                editable
                                                            />
                                                            
                                                        </div>
                                                    </div>

                                                    <div className='col-12'>
                                                        <div className="d-flex row justify-content-between mt-3">
                                                            <div className={`col-auto `}>
                                                                <button onClick={e => { e.preventDefault(); setActiveTab(1); }} className="btn btn-primary">Back</button>
                                                            </div>
                                                            <div className="col-auto">
                                                                <button onClick={e => { e.preventDefault(); sendOTP() }} className="btn btn-primary">Request Passcode</button>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    </>

                                                    : null}

{
    //#endregion
}

{
    //#region OTP
}

                                                {activeTab == 3 ?
                                                    <>
                                                    <div className='row justify-content-center d-inline-block'>
                                                        <div className='col-auto mt-3'>
                                                            <h5>Please Check Email/Phone For OTP And Enter Here</h5>
                                                        </div>
                                                        <div className='col-12'>

                                                            <input type="number"
                                                                value={otp}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    updateOTP(e);
                                                                }}
                                                                name="otp"
                                                            />
                                                    
                                                        </div>
                                                    </div>

                                                    <div className='col-12'>
                                                        <div className="d-flex row justify-content-between mt-3">
                                                            <div className={`col-auto `}>
                                                                <button onClick={e => { e.preventDefault(); setActiveTab(2); }} className="btn btn-primary">Back</button>
                                                            </div>
                                                            <div className="col-auto">
                                                                <button onClick={e => { e.preventDefault(); loginViaOTP() }} className="btn btn-primary">Book Appointment</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </>
                                                    : null}
{
    //#endregion
}

{
    //#region Book Appointment
}

                                                {activeTab == 4 ?

                                                    <>
                                                    <div className='row justify-content-center d-inline-block'>
                                                        <div className='col-auto mt-3'>
                                                            <h5>Book Appointment</h5>
                                                        </div>
                                                        <div className='col-12'>

                                                        </div>
                                                    </div>

                                                    <div className='col-12'>
                                                        <div className="d-flex row justify-content-between mt-3">
                                                            <div className={`col-auto `}>
                                                                <button onClick={e => { e.preventDefault(); setActiveTab(3); }} className="btn btn-primary">Back</button>
                                                            </div>
                                                            <div className="col-auto">
                                                                <button onClick={e => { e.preventDefault(); bookAppointment() }} className="btn btn-primary">Book</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    </>

                                                    : null}

{
    //#endregion
}
                                                {activeTab == 5 ?
                                                    <div className='d-flex row justify-content-center'>
                                                        <div className='col-auto'>
                                                            <h5>Your Appointment Was Booked!</h5>
                                                        </div>
                                                    </div>
                                                : null}

                                                    <div className='d-flex row justify-content-center'>
                                                        <div className='col-auto' style={{color:'red', marginTop:'10px'}}>
                                                            {errorMessage}
                                                        </div>
                                                    </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
        </div >
    )
}

export default PublicSchedule
