import React, { useState, useEffect } from 'react'
import ProductService from '../../../../../../../../services/api/product.service'
import APIResponse from '../../../../../../../templates/components/APIResponse';
import MembershipForm from '../membership-form/MembershipForm';
import moment from 'moment'
const MembershipEdit = (props) => {
    const [formData, setFormData] = useState({})
    const [messages, setMessages] = useState({})
    const [isLoaded_form, setIsLoaded_form] = useState(false)
    const [apiResponse, setApiResponse] = useState()
    const [isLoader, setIsLoader] = useState(false)
    useEffect(() => {
        setIsLoaded_form(false)
        ProductService.getProductById({ id: props.id })
            .then(res => {
                setFormData(res)
            })
            .catch(err => { console.log(err); })
            .finally(() => {
                setIsLoaded_form(true)
            })
    }, [])

    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }

    const submitHandler = (data, items) => {
        let reqObj = {
            id: data.id,
            "name": data.name,
            "membershipType": data.membershipType,
            "membershipPeriodUnit": data.membershipPeriodUnit,
            "membershipPeriodQty": data.membershipPeriodQty,
            "membershipInstructions": data.membershipInstructions,
            "unitPrice": data.unitPrice,
            "practiceLocationId": data.practiceLocationId,
            "itemType": 5
        }
        if (data.membershipType == 3) {
            reqObj.perPeriodQtyLimit = data.perPeriodQtyLimit
            reqObj.rollOverQtyLimit = data.rollOverQtyLimit
            reqObj.rollOverTimeLimit = data.rollOverTimeLimit
        }
        reqObj.membershipInstructions = reqObj.membershipInstructions || ""
        reqObj.membershipItems = items.filter(x => x.itemId !== null).map(x => {
            if(x.priceOverride && (x.unitPrice === "" || x.unitPrice == null)){
                x.unitPrice = 0
            }
            else if (!x.priceOverride)
            {
                x.unitPrice = null
            }
            if(x.discountOverride && (x.discount === "" || x.discount == null)){
                x.discount = 0
            }
            else if (!x.discountOverride)
            {
                x.discount = null
            }
            return x
        })
        reqObj.membershipOfferBeginsOn = moment(data.membershipOfferBeginsOn).startOf('d').toISOString()
        reqObj.membershipOfferEndsOn = moment(data.membershipOfferEndsOn).startOf('d').toISOString()
        setIsLoader(true)
        ProductService.editProduct(reqObj)
            .then(response => {
                setApiResponse(response)
                setIsLoader(false)
            })
            .catch(error => {
                setApiResponse(error)
                setIsLoader(false)
            })
    }

    return (
        <div>
            {isLoaded_form && <MembershipForm submitHandler={submitHandler} initialData={formData} messages={messages} isEdit
                submitLabel="Update" membershipId={props.id} isLoader={isLoader} onClose={() => props.onClose()} />}
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </div>
    )
}

export default MembershipEdit