import React from 'react'
import TabsTemplate from '../../../../templates/components/TabsTemplate'
import PracticeServiceType from '../../provider-settings/practice-settings/practice-service-type/practiceServiceType'
// import ProductSettings from '../location-settings/product-settings/ProductSettings'
import FeesSettings from '../location-settings/fees-settings/FeesSettings'
import LocationServices from './location-services/LocationServices'
import ProductTagSettings from '../location-settings/product-settings/ProductTagSettings'
import ManufacturerSettings from './manufacturer-settings/ManufacturerSettings'
import ProductTable from '../../product/product-table/ProductTable'
import MembershipSettings from './membership-settings/MembershipSettings'

const ProductsServicesFeesSettings = (props) => {
    return (
        <div>
            <TabsTemplate id="productsServicesFeesSettings" accordionId="productsServicesFeesSettings">
                <div title='Products'>
                    <ProductTable title="Products"/>
                </div>
                <div title='Product Tags'>
                    <ProductTagSettings title="Product Tags"/>
                </div>
                <div title='Practice Services'>
                    <PracticeServiceType/>
                </div>
                <div title='Location Services'>
                    <LocationServices/>
                </div>
                <div title='Fees'>
                    <FeesSettings/>
                </div>
                <div title='Manufacturer'>
                    <ManufacturerSettings/>
                </div>
                <div title='Membership'>
                    <MembershipSettings/>
                </div>
            </TabsTemplate>
        </div>
    )
}

export default ProductsServicesFeesSettings