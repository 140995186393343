import React, { useEffect, useState, useContext } from 'react'
import { useRoutes } from 'react-router-dom'
import SecureRoutes from '../../routes/SecureRoutes/SecureRoutes'
import TopNav from './TopNav/TopNav'
import { store } from '../../context/StateProvider'
import StorageService from '../../services/session/storage.service'

import { Amplify, Auth, PubSub, API } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import Utilities from "./../../services/commonservice/utilities";
import toast, { ToastBar, Toaster, ToastIcon } from 'react-hot-toast'

import AppSetting from './../../common/constants/appsetting.constant';

import LogRocket from 'logrocket';

const AppFrame = (props) => {
  let routes = useRoutes(SecureRoutes)
  const globalStateAndDispatch = useContext(store)
  const state = globalStateAndDispatch.state
  const dispatch = globalStateAndDispatch.dispatch

  const [isSubscribed, setIsSubscribed] = useState(false)
  const [subscribedTopic, setSubscribedTopic] = useState('')
  const [subscription, setSubscription] = useState(null)
  const [iotProviderConfigured, setIotProviderConfigured] = useState(false)

  async function configurePubSub() {
    console.log("AppSetting.iotEndpoint "+AppSetting.iotEndpoint)
    if (!iotProviderConfigured) {
      Amplify.configure({
        Auth: {
          identityPoolId: AppSetting.identityPoolId,
          region: AppSetting.region,
        },
      });

      Amplify.addPluggable(new AWSIoTProvider({
        aws_pubsub_region: AppSetting.region,
        aws_pubsub_endpoint: AppSetting.iotEndpoint,
      }));
      setIotProviderConfigured(true);
    }
  }
  
  function subscribeToTopic() {

    if (!state.providerId)
    {
      if (isSubscribed) {
        subscription.unsubscribe();
        setIsSubscribed(false);
        setSubscribedTopic('');
      }
    }
    else
    {
      if (isSubscribed) {
        subscription.unsubscribe();
        console.log(`Unsubscribed from ${subscribedTopic}`);
        setIsSubscribed(false);
        setSubscribedTopic('');
      }
      if (state.providerId != '0rbd3W69') {
        setSubscription(PubSub.subscribe(state.providerId).subscribe({
          next: data => handleReceivedMessage(data),
          error: error => console.error(error),
          close: () => {
            console.log('Done');
            subscribeToTopic();
          },
        }));
        setIsSubscribed(true);
        setSubscribedTopic(state.providerId);
        console.log(`Subscribed to IoT topic ${state.providerId }`);
      }
    }
   
  }

  function handleReceivedMessage(data) {

    // Received messages contain the topic name in a Symbol that we have to decode: 
    const symbolKey = Reflect.ownKeys(data.value).find(key => key.toString() === 'Symbol(topic)');
    const publishedTopic = data.value[symbolKey];
    const message = JSON.stringify(data.value, null, 2);

    toast.success("New text message");

    Utilities.trigger("patientAlert",message);

    console.log(`Message received on ${publishedTopic}:\n ${message}`);
  }  

  useEffect(() => {
    let newState = { ...state }

    let userDetails = JSON.parse(StorageService.get("session", "userDetails"))
    if (userDetails) newState.providerSelected = userDetails;
    
    let providerSelected = JSON.parse(StorageService.get("session", "providerSelected"))
    let skinData = providerSelected && { logo: providerSelected.logo, skin: providerSelected.skin, providerName: providerSelected.providerUrlSuffix } || null
    if (skinData) newState.skinData = skinData;

    let settingsData = JSON.parse(StorageService.get("session", "settingsData"))
    if (settingsData) newState.settingsData = settingsData;

    let locations = JSON.parse(StorageService.get("session", "locale"))
    if (locations) {
      newState.practiceLocations = locations
      newState.practiceLocationId = locations[0].practiceLocationId
    }

    let permissions = JSON.parse(StorageService.get("session", 'permissions'))
    newState.permissions = permissions;

    return dispatch({ type: 'setAll', payload: newState })
  }, []);

  useEffect(() => {
      configurePubSub();
      subscribeToTopic();

      if (state.providerId && ['64Q2qxny','vEQrmxB8','6RWPoQ1A'].includes(state.providerId) ) {

        if (
          window.location.host.includes("hpt-2.0-prd") ||
          window.location.host.includes("hpt-2.0-staging") ||
          window.location.host.includes("v2.hellopatients") ||
          window.location.host.includes("admin.hellopatients") ||
          window.location.host.includes("login.hellopatients") ||
          window.location.host.includes("v2.revique") ||
          window.location.host.includes("admin.revique") ||
          window.location.host.includes("login.revique")
        ) {
          var userDetails = JSON.parse(StorageService.get("session", 'userDetails'));

          if (state?.isEmulate) {
            // LogRocket.init('jbnkfa/revique');
            // var identity = userDetails.userName;
            // var providerName = state?.skinData?.providerName;
            // if (providerName) identity = providerName+'/'+identity
            // LogRocket.identify('emulate/'+identity)
          }
          else {
            LogRocket.init('jbnkfa/revique');
            var identity = userDetails.userName;
            var providerName = state?.skinData?.providerName;
            if (providerName) identity = providerName+'/'+identity
            LogRocket.identify(identity)
          }
        }        
      }

  },[state.providerId]);

  return (
    <div>
      <TopNav />
      <div className="appframe">{routes}</div>
    </div>
  )
}

export default AppFrame