import React, { useEffect, useState } from 'react'
import PatientService from '../../../../../../services/api/patient.service'
import ModalBox from '../../../../../templates/components/ModalBox'
import Table2 from '../../../../../templates/components/Table2'
import AddPatientTag from './AddPatientTag'
import MessageSetting from '../../../../../../common/constants/message-setting.constant'
import APIResponse from '../../../../../templates/components/APIResponse'
import label from "../../../../../../../assets/i18n/en.json";
import DimLoader from '../../../../../templates/components/DimLoader'

const PatientTagSettings = (props) => {
  const [isLoader, setIsLoader] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState()
  const [selectedTag, setSelectedTag] = useState()
  const [add, setAdd] = useState(false)
  const [statusModal, setStatusModal] = useState(false);
  const [apiResponse, setApiResponse] = useState()

  const onSuccess = (message) => {
    if (props.refresh) {
      props.refresh()
    }
    if (props.onClose) {
      props.onClose()
    }
    if (props.exitHandler) {
      props.exitHandler()
    }
    findAllTags()
  }

  const findAllTags = () => {
    setIsLoader(true)
    PatientService.getAllLookupTags()
      .then(res => {
        console.log(res.data)
        setTags(res.data)
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setIsLoader(false)
      });
  }

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: tag => tag.name
    },
    {
      id: "actionPatientTag",
      Header: "Action",
      textAlign: "center",
      disableSortBy: true,
      accessor: (patientTag, i) => {
        return (

          <div className="d-flex justify-content-center">
            <button
              className="p-0 ps-1 btn btn-primary ms-1"
              title={"Delete Tag"}
              onClick={(e) => {
                e.preventDefault();
                setSelectedTag(patientTag);
                return setStatusModal(true);
              }}
            >
              <i className="icon trash" />
            </button>
          </div>
        );
      },
    },
  ];

  const statusHandler = () => {
    setStatusModal(false);
    setIsLoading(true)
    PatientService.deletePatientTag(selectedTag.patientTagId).then((response) => {
      setApiResponse(response);
    }).catch(error => {
      setApiResponse(error);
    })
      .finally(() => {
        setIsLoading(false)
      });
  };
  useEffect(() => {
    findAllTags()
  }, [])
  return (
    <div className='d-flex row'>
      <Table2 export searchable data={tags} loading={isLoader} columns={columns}
        extraButtons={[
          { className: 'btn btn-primary', children: <i className='icon plus' />, title: 'Add Tag', onClick: () => { setAdd(true) } }
        ]} />
      <ModalBox open={add} onClose={() => { setAdd(false) }} title={"Add Patient Tag"}>
        <AddPatientTag onClose={() => { findAllTags(); return setAdd(false) }} />
      </ModalBox>
      <ModalBox open={statusModal} onClose={() => setStatusModal(false)} title={"Delete Tag"}>
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col">
            <p>Would you like to delete {selectedTag?.name}</p>
          </div>
          <div className="col-auto">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                statusHandler();
              }}
            >
              OK
            </button>
            <button
              className="btn btn-secondary ms-3"
              onClick={(e) => {
                e.preventDefault();
                setStatusModal(false);
              }}
            >
              {label.common.cancel}
            </button>
          </div>
        </div>
      </ModalBox>
      {isLoading ? <DimLoader /> : null}
      <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} successMessage={MessageSetting.PatientTag.delete} />
    </div>
  )
}
export default PatientTagSettings