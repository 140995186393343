import React, { useState, useEffect } from 'react'
import FeesForm from '../fees-form/FeesForm'
import ProductService from '../../../../../../../../services/api/product.service'
import APIResponse from '../../../../../../../templates/components/APIResponse'

const FeesAdd = (props) => {
    const { embed, inputChange, refresh, exitHandler } = props;
    const [formData, setFormData] = useState({ })
    const [messages, setMessages] = useState({})
    const [isLoader, setIsLoader] = useState(false)
    const [apiResponse,setApiResponse] = useState()

    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }
    const submitHandler = (data) => {
            let reqObj = {}
            reqObj.itemType = 4;
            reqObj.feeAmount = data.feeType === "1" ? (parseFloat(data.fee) || 0) : 0;
            reqObj.feePercentage = data.feeType === "2" ? (parseFloat(data.fee) || 0) : 0;
            reqObj.taxPercent = data.feeIsTaxable ? (parseFloat(data.taxPercent) || 0) : 0;
            reqObj.name = data.name;
            reqObj.practiceLocationId = data.practiceLocationId;
            reqObj.feeApplicableTo = +data.feeApplicableTo;
            reqObj.feeRequired = Boolean(data.feeRequired) === true ? 1 : 0;
            reqObj.feeIsTaxable = Boolean(data.feeIsTaxable) === true ? 1 : 0;
            setIsLoader(true)
            ProductService.addProduct(reqObj)
                .then(response => {
                    setApiResponse(response)
                    clearForm()
                    setIsLoader(false)
                })
                .catch(error => {
                    setApiResponse(error)
                    setIsLoader(false)
                })
    }

    const clearForm = () => {
        setFormData({ })
    }
    return (
        <div>
            <FeesForm  submitHandler={submitHandler} onClose={()=>props.onClose()} initialData={formData} inputChange={inputChange} loaded={!isLoader} messages={messages} />
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true}/>
        </div>
    )
}

export default FeesAdd