import PracticeSettingsGlobal from "../../components/secure/admin/dashboard/practice-management/practice-settings-global/PracticeSettingsGlobal";
import PracticeManagement from "../../components/secure/admin/dashboard/practice-management/PracticeManagement";
import FormsAdminSettings from "../../components/secure/provider/settings-dashboard/forms-settings/FormsAdminSettings";

const AdminRoutes = [
    { index: true, path: '', element: <PracticeManagement /> },
    { index: true, path: 'forms', element: <FormsAdminSettings /> },
    { path: ':practiceId', element: <PracticeSettingsGlobal tab="practice-details"/> },
    { path: ':practiceId/modules', element: <PracticeSettingsGlobal tab="modules"/> },
    { path: ':practiceId/users', element: <PracticeSettingsGlobal tab="users"/> }
    /* { path: 'practice-management', element: <PracticeManagement/> }, */
    // { path: 'equipmenttype', element: <FindEquipmentType/> },
];

export default AdminRoutes