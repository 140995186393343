import React, { useEffect, useState, useContext } from 'react'
import moment from 'moment';
import AppointmentService from '../../../../../../services/api/appointment.service'
import ModalBox from '../../../../../templates/components/ModalBox'
import PracticeLocationEdit from '../../../practice-location/location-edit/PracticeLocationEdit'
import PracticeLocationAdd from '../../../practice-location/location-add/PracticeLocationAdd'
import toast from 'react-hot-toast'
import Table2 from '../../../../../templates/components/Table2'
import PracticeLocationServiceTable from '../practice-location-services-table/PracticeLocationServicesTable'
import PracticeLocationSelector from '../../../../../templates/components/PracticeLocationSelector'
import { store } from '../../../../../../context/StateProvider'
import { Accordion, Icon } from 'semantic-ui-react'
import PracticeLocationProvider from '../practice-location-provider/PracticeLocationProvider'

const LocationSettingsTable = (props) => {

    const [isLoader, setIsLoader] = useState(false)
    const [locationList, setLocationList] = useState()
    const [editModal, setEditModal] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedLocation, setSelectedLocation] = useState()
    const state = useContext(store).state


    const getPracticeLocations = () => {
        setIsLoader(true);
        AppointmentService.practiceLocation()
            .then((response) => {
                setLocationList(response);
                setIsLoader(false);
            })
            .catch(error => {
                setIsLoader(false);
                console.log(error)
            })
    }
    useEffect(() => {
        getPracticeLocations()
    }, [])

    const deleteItem = (id) => {
        return AppointmentService.deletePracticeLocation(id)
            .then(res => {
                toast.success("Practice Location deleted");
                getPracticeLocations()
            })
            .catch(err => {
                toast.error("Error deleting Practice Location")
            })
    }

    const columns = [
        {
            id: "practiceLocation",
            Header: "Practice Location",
            accessor: location => location.practiceLocation
        },
        {
            id: "createdOn",
            Header: "Created On",
            accessor: (location) => location.createdOn && moment(location.createdOn).format("MM-DD-YYYY h:mm A"),
        },
        {
            id: "modifiedOn",
            Header: "Modified On",
            accessor: (location) => location.modifiedOn && moment(location.modifiedOn).format("MM-DD-YYYY h:mm A"),
        },
        {
            id: "actions",
            Header: "Action",
            disableSortBy: true,
            accessor: (location) => {
                return (
                    <>{

                        state?.isEmulate ? <span className='w-100 d-flex justify-content-center'><div className="btn-group">
                            <button className='btn btn-primary' onClick={e => { e.preventDefault(); setSelectedLocation(location); return setEditModal(true) }} title="Edit Location"><i className="icon pencil" /></button>
                        </div></span> : null}</>)
            }
        },
    ]


    return (
        <div>

            <Table2
                loading={isLoader} data={locationList} columns={columns}
                searchable
                extraButtons={
                    state?.isEmulate ? [
                        {
                            className: 'btn btn-primary mt-auto',
                            children: <i className="icon plus" />,
                            title: "Add Location",
                            onClick: () => { setAddModal(true) }
                        }
                    ] : null}
                extraElements={
                    [
                        {
                            children:
                                <div className='field'>
                                    <label>Select Default Location</label>
                                    <div className='input-group' style={{ minWidth: '300px' }}>
                                        <PracticeLocationSelector locations={locationList} />
                                    </div>
                                </div>
                        },
                    ]}
            />

            {1 == 0 && state.practiceLocationId && <Accordion fluid styled>
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={e => { e.preventDefault(); if (activeIndex === 0) { setActiveIndex() } else { setActiveIndex(0) } }}
                > <Icon name='dropdown' />Services</Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                    <div>
                        <PracticeLocationServiceTable id={state.practiceLocationId} />
                    </div>
                </Accordion.Content>
                <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    onClick={e => { e.preventDefault(); if (activeIndex === 1) { setActiveIndex() } else { setActiveIndex(1) } }}
                > <Icon name='dropdown' />Providers</Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                    <div>
                        <PracticeLocationProvider id={state.practiceLocationId} />
                    </div>
                </Accordion.Content>
            </Accordion>}
            <ModalBox open={editModal} onClose={() => setEditModal(false)} title="Edit Location">
                <PracticeLocationEdit locationId={selectedLocation?.practiceLocationId} onClose={() => { setEditModal(false); getPracticeLocations() }} />
            </ModalBox>
            <ModalBox open={addModal} onClose={() => setAddModal(false)} title="Add Location">
                <PracticeLocationAdd onClose={() => { setAddModal(false) }} refresh={() => { getPracticeLocations() }} />
            </ModalBox>
            <ModalBox open={deleteModal} onClose={() => { setDeleteModal(false) }} title="Delete Location">
                {selectedLocation?.practiceLocationId && <div className='row d-flex align-items-center justify-content-between'>
                    <div className='col'>
                        Are you sure you want to delete {selectedLocation.practiceLocation}?
                    </div>
                    <div className='col-auto'>
                        <button className='btn btn-secondary' onClick={e => { e.preventDefault(); setSelectedLocation(); return setDeleteModal(false) }}>No</button>
                        <button className='btn btn-primary ms-3' onClick={e => { e.preventDefault(); deleteItem(selectedLocation?.practiceLocationId); setDeleteModal(false) }}>Yes</button>
                    </div>
                </div>}
            </ModalBox>
        </div>
    )
}

export default LocationSettingsTable