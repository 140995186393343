import React, { useState, useEffect } from 'react'
import FormValidatorService from '../../../../../services/validator/form-validator.service'
import ValidationConstant from '../../../../../services/validator/validation.constant'
import toast from "react-hot-toast";
import label from "../../../../../../assets/i18n/en.json";
import InputMask from "react-input-mask";
import CommonService from '../../../../../services/api/common.service';
import HPTSelect from '../../../../templates/components/HPTSelect';
import { LeadStatusEnum as leadStatusList} from '../../../../../common/enum/lead-status.enum';

CommonService
const LeadForm = (props) => {
  const [formData, setFormData] = useState(props.initialData || {})
  const [errors, setErrors] = useState({})
  const required = ['firstName', 'lastName', 'mobile', 'email']
  const [allUserList, setAllUserList] = useState([])
  const [isLoader_User, setIsLoader_User] = useState(false)
  const LeadFormConfig = {
    config: {
      'FirstName': {
        required: { name: ValidationConstant.patient.add.firstName.name },
        maxlength: {
          name: ValidationConstant.patient.add.firstName.name,
          max: ValidationConstant.patient.add.firstName.maxLength.toString()
        },
        // minlength: {
        //     name: ValidationConstant.patient.add.firstName.name,
        //     min: ValidationConstant.patient.add.firstName.minLength.toString()
        // },
        pattern: {
          name: ValidationConstant.patient.add.firstName.name,
        }
      },
      'LastName': {
        required: { name: ValidationConstant.patient.add.lastName.name },
        maxlength: {
          name: ValidationConstant.patient.add.lastName.name,
          max: ValidationConstant.patient.add.lastName.maxLength.toString()
        },
        // minlength: {
        //     name: ValidationConstant.patient.add.lastName.name,
        //     min: ValidationConstant.patient.add.lastName.minLength.toString()
        // },
        pattern: { name: ValidationConstant.patient.add.lastName.name }
      }, 'Mobile': {
        required: { name: ValidationConstant.patient.add.mobile.name },
        maxlength: {
          name: ValidationConstant.patient.add.mobile.name,
          max: ValidationConstant.patient.add.mobile.maxLength.toString()
        },
        minlength: {
          name: ValidationConstant.patient.add.mobile.name,
          min: ValidationConstant.patient.add.mobile.minLength.toString()
        },
        pattern: {
          name: ValidationConstant.patient.add.mobile.name,
          regex: new RegExp(ValidationConstant.phone_regex)
        }
      },
      'Email': {
        required: { name: ValidationConstant.patient.add.email.name },
        maxlength: {
          name: ValidationConstant.patient.add.email.name,
          max: ValidationConstant.patient.add.email.maxLength.toString()
        },
        minlength: {
          name: ValidationConstant.patient.add.email.name,
          min: ValidationConstant.patient.add.email.minLength.toString()
        },
        pattern: {
          name: ValidationConstant.patient.add.email.name,
          regex: new RegExp(ValidationConstant.email_regex)
        }
      },
    },
    'FirstName': ['', []],
    'LastName': ['', []],
    'Mobile': ['', []],
    'Email': ['', []],
    'Comment': [null, []],
    'Source': [null, []],
  }
  // formula for input change
  const inputChange = (e) => {
    let newStateObject = { ...formData };
    newStateObject[e.target.name] = e.target.value
    setErrors(FormValidatorService.setErrors(e, errors, LeadFormConfig.config))
    return setFormData(newStateObject);
  };

  const isJSON = (str) => {
    try {
      return (JSON.parse(str) && !!str);
    } catch (e) {
      return false;
    }
  }
  const userLookup = () => {
    setIsLoader_User(true)
    let reqObj = {
      //UserType: +userType, 
      isActive: true
    }
    CommonService.allUserLookup(reqObj)
      .then((response) => {
        setAllUserList(response.data)
      })
      .catch(error => {
        console.log(error)
      }).finally(() => {
        setIsLoader_User(false)
      })
  }
  useEffect(() => {
    userLookup()
  }, [])
  return (
    <div className='d-flex row'>
      <div className="col-md-6 col-12 required field">
        <label> {label.patient.add.firstName}</label>
        <input
          placeholder="First Name"
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={(e) => {
            e.preventDefault();
            inputChange(e);
          }}
          required
        />
        <span className="error-message">{errors.firstName}</span>
      </div>

      <div className="col-md-6 col-12 required field">
        <label> {label.patient.add.lastName}</label>
        <input
          placeholder="Last Name"
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={(e) => {
            e.preventDefault();
            inputChange(e);
          }}
          required
        />
        <span className="error-message">{errors.lastName}</span>
      </div>

      <div className="col-md-6 col-12 required field">
        <label> {label.patient.add.phone}</label>
        <InputMask
          placeholder="Mobile"
          type="text"
          name="mobile"
          mask="(999) 999-9999"
          unmask={true}
          onUnmask
          value={formData.mobile}
          onChange={(e) => {
            e.preventDefault();
            inputChange(e);
          }}
          editable
        />
        <span className="error-message">{errors.mobile}</span>
      </div>
      <div
        className="col-md-6 col-12 required field"
      >
        <label> {label.patient.add.email}</label>
        <input
          placeholder="Email"
          type="email"
          name="email"
          className="form-control"
          value={formData.email}
          onChange={(e) => {
            e.preventDefault();
            inputChange(e);
          }}
        />
        <span className="error-message">{errors.email}</span>
      </div>
      <div className="col-md-6 col-12 field">
        <label>Source</label>
        <input
          placeholder="Source"
          type="text"
          name="source"
          value={formData.source}
          onChange={(e) => {
            e.preventDefault();
            inputChange(e);
          }}
          disabled
        />
        <span className="error-message">{errors.source}</span>
      </div>
      <div className="field col-12">
        {formData.metadata ? (isJSON(formData.metadata) ? Object.entries(JSON.parse(formData.metadata)).map(([key, value]) => {
          return (
            <>
              <label><span style={{ fontWeight: "bold" }}>{key}</span> : {value.toString()}</label>
            </>
          );
        }) : <label><span style={{ fontWeight: "bold" }}>Metadata :</span> {formData.metadata}</label>) : null}
      </div>
      <div
        className="col-md-6 col-12 field"
      >
        <label>Assigned To</label>
        <HPTSelect
          className="react-select-container"
          classNamePrefix="react-select"
          options={allUserList}
          name="assignedToId"
          value={allUserList && formData.assignedToId ? allUserList.find(obj => obj.id === formData.assignedToId) : null}
          onChange={e => {
            inputChange({
              target:
                { value: e.id, name: 'assignedToId' }
            })
          }}
          getOptionLabel={(option) => `${option.contact?.name?.firstName} ${option.contact?.name?.lastName}`}
          getOptionValue={(option) => option.id}
        />
      </div>
      <div
        className="col-md-6 col-12 field"
      >
        <label>Status</label>
        <HPTSelect
          className="react-select-container"
          classNamePrefix="react-select"
          name="status"
          value={leadStatusList && formData.status ? leadStatusList.find(obj => obj.value === formData.status) : null}
          options={leadStatusList}
          onChange={e => {
            //e.preventDefault();
            //setSelectedPeriodUnit(e);
            inputChange({ target: { value: e.value, name: 'status' } })
          }}
          getOptionLabel={(option) => { return (option.title) }}
        />
      </div>
      <div className="col-md-12 col-12 field">
        <label>Comment</label>
        <textarea placeholder="Comment"
          className="form-control"
          value={formData.comments}
          onChange={e => { e.preventDefault(); inputChange(e) }}
          name="comments"
          rows={2}
        ></textarea>
      </div>
      <div className='mt-3 d-flex justify-content-between'>

        <div className='col-auto'>
          <button className='btn btn-primary' onClick={e => {
            e.preventDefault();
            if (!FormValidatorService.checkForm(errors, formData, required)) {
              toast.error("Please make sure the form is complete")
            }
            else {
              props.submitHandler(formData);
            }
          }}>Save</button>
        </div>
        <div className='col-auto'>
          <button className='btn btn-secondary' onClick={e => { e.preventDefault(); props.closeModal(false); }}>Close</button>
        </div>
      </div>
    </div>
  )
}
export default LeadForm