import React, { useEffect, useState, useContext } from "react";
import label from "../../../../../../../assets/i18n/en.json";
import moment from "moment";
import MembershipAdd from "./membership/membership-add/MembershipAdd";
import MembershipEdit from "./membership/membership-edit/MembershipEdit";
import ModalBox from "../../../../../templates/components/ModalBox";
import Table2 from "../../../../../templates/components/Table2";
import APIResponse from "../../../../../templates/components/APIResponse";
import { store } from "../../../../../../context/StateProvider";
import ProductService from "../../../../../../services/api/product.service";
import { MembershipType as MembershipType } from "../../../../../../common/enum/membership-type.enum";
import PracticeLocationSelector from "../../../../../templates/components/PracticeLocationSelector";

const MembershipTable = (props) => {
  const [isLoader, setIsLoader] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [membershipList, setMembershipList] = useState([]);
  const [selectedMembership, setSelectedMembership] = useState();
  const [filter, setFilter] = useState()
  const [allLocations, setAllLocations] = useState(false)
  const hiddenColumns = ["isActive", "action"]
  const [apiResponse, setApiResponse] = useState()
  const globalStateAndDispatch = useContext(store)
  const state = globalStateAndDispatch.state
  const [locationId, setLocationId] = useState(state.practiceLocationId);

  const onSuccess = (message) => {
    if (props.refresh) {
      props.refresh()
    }
    if (props.onClose) {
      props.onClose()
    }
    if (props.exitHandler) {
      props.exitHandler()
    }
  }

  const findMembership = () => {
    setIsLoader(true);
    let reqObj = {
      ItemType: 5,
      PracticeLocationId: locationId || undefined
    }
    if (allLocations) delete reqObj.PracticeLocationId;
    ProductService.findProduct(reqObj)
      .then((response) => {
        setMembershipList(response.data);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  useEffect(() => {
    setMembershipList([])
    findMembership();
  }, [allLocations, locationId])

  const onLocationChanged = (newLocationId) => {
    setLocationId(newLocationId)
  }

  const columns = [
    {
      id: "membershipOfferBeginsOn",
      Header: "Start",
      accessor: (membership) => moment(membership.membershipOfferBeginsOn).format("MM/DD/YYYY"),
    },
    {
      id: "membershipOfferEndsOn",
      Header: "End",
      accessor: (membership) => moment(membership.membershipOfferEndsOn).format("MM/DD/YYYY"),
    },
    {
      id: "name",
      Header: "Name",
      accessor: item => item.name,
    },
    {
      id: "membershipType",
      Header: "Type",
      accessor: item => MembershipType.find(obj => obj.value === item.membershipType)?.title,
    },
    {
      id: "membershipPeriodQty",
      Header: "Duration",
      accessor: item => item.membershipPeriodQty,
    },
    {
      id: "membershipPeriodUnit",
      Header: "Period",
      accessor: item => item.membershipPeriodUnit,
    },
    {
      id: "actionMembership",
      Header: "Action",
      disableSortBy: true,
      accessor: (membership, i) => {
        return (
          <div className="d-flex justify-content-center">
            <button
              className="p-0 ps-1 btn btn-primary"
              title="Edit Membership"
              onClick={(e) => {
                e.preventDefault();
                setSelectedMembership(membership);
                return setOpenModal(true);
              }}
            >
              <i className="icon pencil" />
            </button>
          </div>
        );
      },
    },
  ];



  return (
    <>
      {membershipList ? (
        <div className="row d-flex col-12 mb-3">
          <div>{membershipList ? (
            <Table2
              hiddenColumns={hiddenColumns}
              export
              searchable
              loading={isLoader}
              columns={columns}
              data={membershipList.filter(obj => { if (filter === '' || filter === null || !filter || filter === 'All') { return obj } else { return obj.isActive === parseInt(filter) } })}
              extraButtons={[
                {
                  className: 'btn btn-primary mt-auto',
                  children: <i className="icon plus" />,
                  title: "Add Membership",
                  onClick: () => { setShowAdd(true) }
                }
              ]}
              extraElements={
                [
                  {
                    children:
                      <div className='col-md-auto'>
                        <div className='form-check'>
                          <input type="checkbox" className='form-check-input' checked={allLocations} name="allLocations" onChange={e => { setAllLocations(e.target.checked) }} />
                          <label className='form-check-label'>All Locations</label>
                        </div>
                      </div>
                  },
                  {
                    children:
                      <div className='col-md-auto'>
                        <PracticeLocationSelector dontResetState={true} onSelected={onLocationChanged} />
                      </div>
                  }
                ]
              }
            />
          ) :
            null}
          </div>
        </div>
      ) : null}
      <ModalBox size="fullscreen" open={openModal} onClose={() => setOpenModal(false)} title="Edit Membership">
        {selectedMembership && <MembershipEdit
          id={selectedMembership.id}
          onClose={() => {
            findMembership();
            return setOpenModal(false);
          }}
        />}
      </ModalBox>

      <ModalBox
        size="fullscreen"
        open={showAdd}
        onClose={() => {
          setShowAdd(false);
        }}
        title="Add Membership"
      >
        <MembershipAdd
          onClose={() => {
            findMembership();
            return setShowAdd(false);
          }}
        />
      </ModalBox>
      <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
    </>
  );
};

export default MembershipTable;
