import React, { useState, useEffect } from 'react'
import tinycolor from 'tinycolor2';

const PracticeAppointmentStatusCodeForm = (props) => {
    const [formData, setFormData] = useState(props.initialData)

    // formula for input change
    const inputChange = (e) => {
        let newStateObject = { ...formData };
        newStateObject[e.target.name] = e.target.value
        setFormData(newStateObject);
        return console.log(formData)
    };
    useEffect(() => {
        if (formData?.color?.substr(0, 1) !== "#") {
            //console.log(tinycolor(formData.color).toHex())
            inputChange({ target: { name: 'color', value: `#${tinycolor(formData.color).toHex()}` } })
        }
    }, [formData.color])

    return (
        <div>

            <form className=''>
                <div className="ui content">
                    <div className="row d-flex">
                        <div className="col-md-5 col-12">
                            <label>Code</label>
                            <input placeholder="Code" type="text" disabled name="appointmentStatusCode" value={formData.appointmentStatusCode} onChange={(e) => { e.preventDefault(); inputChange(e) }} />
                        </div>
                        <div className="col-md-5 col-12">
                            <label> Label</label>
                            <input placeholder="Label" type="text" disabled name="appointmentStatusLabel" value={formData.appointmentStatusLabel} onChange={(e) => { e.preventDefault(); inputChange(e) }} />
                        </div>
                        <div className='col-md-2 col-12'>
                            <div className="field col">
                                <label>Color</label>
                                <input type="color"
                                    className="form-control form-control-color align-self-end"
                                    value={formData.color}
                                    name="color"
                                    onChange={e => {
                                        inputChange(e)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div >
            </form >
            <div className="mt-3 d-flex justify-content-between">
                <div className='col-auto'>
                    <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); props.submitHandler(formData) }}>{props.submitLabel}</button>
                </div>
                <div className='col-auto'>
                    <button className="btn btn-secondary" onClick={(e) => { e.preventDefault(); props.onClose() }}> Cancel </button>
                </div>
            </div >
        </div >
    )
}

export default PracticeAppointmentStatusCodeForm