import React, { useEffect, useState, useContext } from 'react'
import Table2 from '../../../../../templates/components/Table2'
import { store } from '../../../../../../context/StateProvider'
import AppointmentService from '../../../../../../services/api/appointment.service'
import PracticeLocationSelector from '../../../../../templates/components/PracticeLocationSelector'
import ModalBox from '../../../../../templates/components/ModalBox'
import PracticeLocationRoomEdit from '../../../practice-location-room/location-room-edit/PracticeLocationRoomEdit'
import PracticeLocationRoomAdd from '../../../practice-location-room/location-room-add/PracticeLocationRoomAdd'
import toast from 'react-hot-toast'
import APIResponse from '../../../../../templates/components/APIResponse'

const RoomSettingsTable = (props) => {

    const [isLoader, setIsLoader] = useState(false)
    const [roomList, setRoomList] = useState()
    const [selectedRoom, setSelectedRoom] = useState()
    const [editModal, setEditModal] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [statusModal, setStatusModal] = useState(false);
    const state = useContext(store).state
    const [apiResponse, setApiResponse] = useState()
    const hiddenColumns = ["isActive","actionPracticePatient"]
    const onSuccess = (message) => {
        practiceLocationRoomLookup();
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }
    const statusHandler = () => {
        setStatusModal(false);
        setIsLoader(true)
        if (selectedRoom.isActive == 1) {
            AppointmentService.deactivateRoom(selectedRoom.practiceLocationRoomId).then(response => {
                setApiResponse(response)
            })
                .catch(error => {
                    setApiResponse(error)
                })
                .finally(() => {
                    setIsLoader(false)
                });

        } else {
            AppointmentService.activateRoom(selectedRoom.practiceLocationRoomId).then(response => {
                setApiResponse(response)
            })
                .catch(error => {
                    setApiResponse(error)
                })
                .finally(() => {
                    setStatusModal(false);
                    setIsLoader(false)
                });
        }
    };
    const practiceLocationRoomLookup = (id) => {
        setIsLoader(true)
        AppointmentService.practiceLocationRoomLookup(state.practiceLocationId)
            .then((response) => {
                setRoomList(response.sort((a, b) => a.room.localeCompare(b.room)))
                setIsLoader(false)
            })
            .catch((error) => {
                // console.log(error)
                setIsLoader(false)
            })
    }

    const deleteItem = (id) => {
        return AppointmentService.deletePracticeLocationRoom(id)
            .then(res => {
                toast.success("Practice Location Room deleted");
                practiceLocationRoomLookup()
            })
            .catch(err => {
                toast.error("Error deleting Practice Location Room")
            })
    }
    const columns = [
        {
            id: "room",
            Header: "Room",
            accessor: room => room.room
        },
        {
            id: "isActive",
            Header: "Active",
            align: "center",
            sortable: true,
            accessor: (room, i) =>
                room.isActive === 1 ? (
                    <span className="btn btn-success text-white">Active</span>
                ) : (
                    <span className="btn btn-danger text-white">Inactive</span>
                ),
        },
        {
            id: "actionPracticePatient",
            Header: "Action",
            textAlign: "center",
            disableSortBy: true,
            accessor: (room, i) => {
                return (

                    <div className='d-flex justify-content-center'>
                        <button className="p-0 ps-1 btn btn-primary" title="Edit room" onClick={e => { e.preventDefault(); setSelectedRoom(room); return setEditModal(true) }}><i className="icon pencil" /></button>
                        <button className="p-0 ps-1 btn btn-primary ms-1" title="Delete Room" onClick={e => { e.preventDefault(); setSelectedRoom(room); return setDeleteModal(true) }}><i className="icon trash" /></button>
                        <button
                            className="p-0 ps-1 btn btn-primary ms-1"
                            title={
                                room.isActive === 1 ? "Deactivate Room" : "Activate Room"
                            }
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedRoom(room);
                                return setStatusModal(true);
                            }}
                        >
                            {room.isActive === 1 ? <i className="icon dont" /> : <i className="icon check" />}
                        </button>
                    </div>
                )
            }
        }
    ]

    useEffect(() => {
        if (state?.practiceLocationId) {
            practiceLocationRoomLookup()
        }
    }, [state?.practiceLocationId])

    return (
        <>
            <Table2 
            hiddenColumns={hiddenColumns}
            loading={isLoader} data={roomList} columns={columns}
                extraButtons={[
                    {
                        className: 'btn btn-primary', title: "Add Room", children: <i className='icon plus' />, onClick: () => { setAddModal(true); }
                    }]}
                extraElements={[{ children: <div style={{ width: '250px' }}><PracticeLocationSelector /></div> }]} />
            <ModalBox open={editModal} onClose={() => { setEditModal(false) }} title="Edit Room">
                <PracticeLocationRoomEdit initialData={{ practiceLocationRoomId: selectedRoom?.practiceLocationRoomId }} onClose={() => { practiceLocationRoomLookup(); return setEditModal(false) }} />
            </ModalBox>
            <ModalBox open={addModal} onClose={() => { setAddModal(false) }} title="Add Room">
                <PracticeLocationRoomAdd initialData={{ practiceLocationId: state.practiceLocationId }} onClose={() => { practiceLocationRoomLookup(); return setAddModal(false) }} />
            </ModalBox>
            <ModalBox open={deleteModal} onClose={() => { setDeleteModal(false) }} title="Delete Room">
                {selectedRoom?.practiceLocationRoomId && <div className='row d-flex align-items-center justify-content-between'>
                    <div className='col'>
                        Are you sure you want to delete {selectedRoom.room}?
                    </div>
                    <div className='col-auto'>
                        <button className='btn btn-secondary' onClick={e => { e.preventDefault(); setSelectedRoom(); return setDeleteModal(false) }}>No</button>
                        <button className='btn btn-primary ms-3' onClick={e => { e.preventDefault(); { state.practiceLocationId ? deleteItem(selectedRoom?.practiceLocationRoomId) : toast.error("Error deleting Practice Location Room") }; setDeleteModal(false) }}>Yes</button>
                    </div>
                </div>}
            </ModalBox>
            <ModalBox open={statusModal} onClose={() => setStatusModal(false)} title={selectedRoom?.isActive === 1 ? "Deactivate Room" : "Activate Room"}>
                <div className="row d-flex justify-content-between align-items-center">
                    <div className="col">
                        {selectedRoom?.isActive === 1 ? (
                            <p>Would you like to deactivate {selectedRoom?.room}</p>
                        ) : (
                            <p>Would you like to activate {selectedRoom?.room} </p>
                        )}
                    </div>
                    <div className="col-auto">
                        <button
                            className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault();
                                statusHandler();
                            }}
                        >
                            OK
                        </button>
                        <button
                            className="btn btn-secondary ms-3"
                            onClick={(e) => {
                                e.preventDefault();
                                setStatusModal(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </ModalBox>
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} toastOnFailure={true}/>
        </>
    )
}

export default RoomSettingsTable