import React, { useState, useContext, useImperativeHandle, forwardRef } from 'react'
import moment from 'moment'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { store } from '../../../../context/StateProvider';
import AppointmentService from '../../../../services/api/appointment.service';
import PatientService from '../../../../services/api/patient.service';
const SMSInput = forwardRef((props, ref) => {
  const [text, setText] = useState("");
  const globalStateAndDispatch = useContext(store)
  const state = globalStateAndDispatch.state

  const onChange = (e) => {
    if (e) {
      setText(e.target.value);
    } else {
      setText("");
    }
  };

  useImperativeHandle(ref, () => ({
    onChange,
  }));

  const onSubmit = (e) => {
    e.preventDefault();
    props.onSendMessage(text);
    setText("");
  };

  const getPatientBalances = () => {
    props.setIsLoading(true)
    PatientService.getPatientBalance({ PatientIds: props.patientId })
      .then(res => {
        if (res?.data[0]?.openBalance && res?.data[0]?.openBalance > 0) {
          setText(`You currently have a balance of $${res?.data[0]?.openBalance}`)
        } else {
          setText(`You currently have a balance of $0`)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        props.setIsLoading(false)
      });
  }
  const getPatientNextApt = () => {
    props.setIsLoading(true)
    let searchParams = {
        FromDate: new Date().toISOString(),
        PracticeLocation: state.practiceLocationId,
        PatientIds: props.patientId,
        SortField:"FromDate",
        Asc:true
    }
    AppointmentService.findNextAppointment(searchParams)
        .then((findAppointmentResponse) => {
            if (findAppointmentResponse?.length == 0 || !findAppointmentResponse) {
              setText(`You do not have any upcoming appointments.`)
            } else {
                let nextApt = findAppointmentResponse[0]
                setText(`Your next appointment for ${nextApt.practiceServiceType} with ${nextApt.doctorName} is scheduled for ${moment(nextApt.fromDate).format("dddd, MMMM D")} at ${moment(nextApt.fromDate).format("h:mm a")}.`)
            }
        })
        .catch((error) => {
            console.log(error);
        }).finally(() => {
          props.setIsLoading(false)
        });
}

  return (
    <div>
      <div className="Input">
        <form onSubmit={e => onSubmit(e)} style={{ marginBottom: '0px' }}>
          <InputGroup className="">
            <Form.Control
              aria-describedby="basic-addon2"
              onChange={e => onChange(e)}
              value={text}
              type="text"
              placeholder="New message"
              autofocus="true"
              style={{ marginTop: '10px', marginLeft: '5px' }}
            />
            <Button className='btn btn-primary' variant="outline-secondary" id="button-addon2" onClick={e => onSubmit(e)} style={{ marginTop: '10px' }}>
              Send
            </Button>
          </InputGroup>
        </form>
      </div>

      <div className="col-md-auto col-12">
        <div className='btn-group'>
          <button className='btn button-bar' title="Paste open balance message" onClick={e => { e.preventDefault(); getPatientBalances() }} ><i className={`icon dollar`} /></button>
          <button className='btn button-bar' title="Paste next appointment message" onClick={e => { e.preventDefault(); getPatientNextApt() }} ><i className={`icon calendar`} /></button>
        </div>
      </div>
    </div>
  );
});

export default SMSInput