import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useTable, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination, useExpanded } from 'react-table'
import { useExportData } from 'react-table-plugins'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment';
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import DimLoader from './DimLoader';
import Utilities from '../../../services/commonservice/utilities';
const Table2 = (props) => {
    const isMobile = useMediaQuery({ query: `(max-width: 576px)` });
    const data = React.useMemo(
        // () => [
        //     {
        //         col1: 'Hello',
        //         col2: 'World',
        //     },
        //     {
        //         col1: 'react-table',
        //         col2: 'rocks',
        //     },
        //     {
        //         col1: 'whatever',
        //         col2: 'you want',
        //     },
        // ]
        () => {
            if (Array.isArray(props.data)) { return [...props.data] } else {
                return ([])
            }
        },
        [props.data]
    )

    const hiddenColumns = React.useMemo(() => {
        if (Array.isArray(props.hiddenColumns) && isMobile)
            return props.hiddenColumns
        else if(Array.isArray(props.desktopHiddenColumns) && !isMobile){
            return props.desktopHiddenColumns
        }
        else {
            return []
        }
    }, [props.hiddenColumns])
    const columns = React.useMemo(
        // () => [
        //     {
        //         Header: 'Column 1',
        //         accessor: 'col1', // accessor is the "key" in the data
        //     },
        //     {
        //         Header: 'Column 2',
        //         accessor: 'col2',
        //     },
        // ]
        () => [...props.columns],
        [props.columns]
    )

    const [filter, setFilter] = React.useState("")

    function GlobalFilter({
        preGlobalFilteredRows,
        setGlobalFilter,
        setFilter,
        filter
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(filter)
        const onChange = useAsyncDebounce(value => {
            setFilter(value);
            setGlobalFilter(value || undefined);
        }, 500)

        const inputReference = useRef(null);

        useEffect(() => {
            inputReference.current.focus();
        });

        return (
            <span>
                Search:{' '}
                <input
                    type="text"
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={props.loading ? 'loading...' : `${count} records...`}
                    ref={inputReference} 
                />
            </span>
        )
    }

    function getExportFileBlob({ columns, data, fileType, fileName }) {
        if (fileType === "csv") {
            // CSV example
            const headerNames = columns.map((col) => col.exportValue);
            const csvString = Papa.unparse({ fields: headerNames, data });
            return new Blob([csvString], { type: "text/csv" });
        } else if (fileType === "XLSX") {
            // XLSX example

            const header = columns.map((c) => c.exportValue);
            const compatibleData = data.map((row) => {
                const obj = {};
                header.forEach((col, index) => {
                    obj[col] = row[index];
                });
                return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.XLSX`);

            // Returning false as downprops.loading of file is already taken care of
            return false;
        }
        //PDF example
        if (fileType === "pdf") {
            const headerNames = columns.map((column) => column.exportValue);
            const footerNames = columns.map((column) => console.log(column))
            const doc = new JsPDF('p', 'pt', 'letter');
            doc.text(fileName, 15, 20)
            doc.autoTable({
                title: fileName,
                head: [headerNames],
                body: data,
                margin: { top: 30 },
                styles: {
                    minCellHeight: 9,
                    halign: "left",
                    valign: "center",
                    fontSize: 11,
                },
                // foot: [footerNames]
            });
            doc.save(`${fileName}.pdf`);

            return false;
        }

        // Other formats goes here
        return false;
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        preGlobalFilteredRows,
        setGlobalFilter,
        globalFilter,
        rows,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, expanded },
        prepareRow,
        exportData,
    } = useTable({ columns, data, initialState: { pageIndex: 0, hiddenColumns: hiddenColumns }, getExportFileBlob, getExportFileName: ({ all }) => { return props.title } }, useGlobalFilter, useSortBy, useExpanded, usePagination, useExportData)

    return (
        <div className="table-2-template">
            {props.extraElements || props.extraButtons || props.searchable || props.export ? <div className="row d-flex justify-content-end mb-3 align-items-end g-3 mx-0 px-0">
                { props.extraElements?.length > 0 ? (props.extraElements?.length  > 1 ? <>{props.extraElements.map(element => element.children)}</> : <div className="col-md-auto col-12 extra-elements">{props.extraElements.map(element => element.children)}</div>)  : null}
                {props.searchable && <div className='col-md-auto'>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        setGlobalFilter={setGlobalFilter}
                        setFilter={setFilter}
                        filter={filter}
                
                    />
                </div>}
                <div className='col-md-auto'>
                    <div className='btn-group m-w-100'>
                        {props.export && <> <button
                            title="Download to PDF"
                            className='btn btn-primary'
                            onClick={(e) => {
                                e.preventDefault();
                                exportData("pdf", false);
                            }}
                        >
                            <i className="icon file pdf" />
                        </button>
                            <button
                                title="Download to Excel"
                                className='btn btn-primary'
                                onClick={(e) => {
                                    e.preventDefault();
                                    exportData("XLSX", false);
                                }}
                            >
                                <i className="icon file excel" />
                            </button>
                            <button
                                title="Download to CSV"
                                className='btn btn-primary'
                                onClick={(e) => {
                                    e.preventDefault();
                                    exportData("csv", false);
                                }}
                            >
                                <i className="icon download" />
                            </button>
                        </>}
                        {props.extraButtons && props.extraButtons.map(button => {
                            return (<button title={button.title} className={`${button.className}`} onClick={e => { e.preventDefault(); if (button.onClick) { button.onClick() } }}>{button.children}</button>)
                        })}
                    </div>
                </div>
            </div> : null}
            <div style={{ overflowX: "auto" }}>
            <table {...getTableProps()} className="table table-borderless">
                {headerGroups.map(headerGroup => (
                    <colgroup>
                        {headerGroup.headers.map(column => (
                            <col span={1} style={{ minWidth: column.minWidth }} />
                        ))}
                    </colgroup>)
                )}
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps({ ...column.getSortByToggleProps(), style: { textAlign: column.textAlign } })} className={column.disableSortBy ? '' : 'point'}>
                                    {column.render('Header')}
                                    {/* Add a sort direction indicator */}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <i className="icon angle down" />
                                                : <i className="icon angle up" />
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page?.length > 0 ? page.map(row => {
                        prepareRow(row)
                        // console.log(row)
                        return (
                            <Fragment>
                                <tr {...row.getRowProps()} className={`${row.original.subData ? 'point' : ''} ${row.depth > 0 ? 'bg-light' : ''}`} onClick={e => {
                                    e.preventDefault(); if (row.original.subData) {
                                        row.toggleRowExpanded(!row.isExpanded ? true : false)
                                    }
                                }}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps(
                                                    {
                                                        style: {
                                                            width: cell.column.width,
                                                            textAlign: cell.column.textAlign,
                                                            verticalAlign: 'middle'
                                                        }
                                                    }
                                                )}
                                                className="align-items-center"
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                                {row.isExpanded &&
                                    <tr><td colSpan={row.cells.length}>{row.original.subData}</td></tr>}
                            </Fragment>
                        )
                    }) : 
                    
                    props.loading ? <tr><td colSpan={columns.length}>Please be patient while data loads...</td></tr> : 
                    props.initialMessage ? <tr><td colSpan={columns.length}>Please submit the report...</td></tr> : 
                    <tr><td colSpan={columns.length}>No Results Found</td></tr> } 
                </tbody>
                {!props.loading && rows?.length > 0 ? <tfoot>
                    {footerGroups.map(group => (
                        <tr className="thead" {...group.getFooterGroupProps()}>
                            {group.headers.map(column => (

                                <th {...column.getFooterProps(
                                    {
                                        style: {
                                            // width: column.column.width,
                                            textAlign: column.textAlign,
                                            // verticalAlign: 'middle'
                                        }
                                    }
                                )}
                                >
                                    {column.render('Footer')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </tfoot> : null}
            </table>
            </div>
            {rows?.length > 10 && !props.noPagination ?
                <div className='row d-flex align-items-center justify-content-between mt-3 pe-0 me-0'>
                    <div className='col'>
                        <div className="pagination align-items-center row d-flex justify-content-start">
                            <div className='btn-group col-md-auto col-12'>
                                <button className='btn btn-primary' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                    {'<<'}
                                </button>{' '}
                                <button className='btn btn-primary' onClick={() => previousPage()} disabled={!canPreviousPage}>
                                    {'<'}
                                </button>{' '}
                                <button className='btn btn-primary' onClick={() => nextPage()} disabled={!canNextPage}>
                                    {'>'}
                                </button>{' '}
                                <button className='btn btn-primary' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                    {'>>'}
                                </button>{' '}
                            </div>
                            <span className='col-md-auto col-12'>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                        </div>
                    </div>
                    <div className='col-md-auto col-12'>
                        <strong>{page.length} of {rows.length} records</strong>
                    </div>
                </div> : null}
        </div>
    )
}

export default Table2