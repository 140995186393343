import React from 'react'
import TabsTemplate from '../../../../templates/components/TabsTemplate'
import UserSettingsTable from './user/user-settings-table/UserSettingsTable'
import UserRolesTable from './user-roles/user-roles-table/UserRolesTable'
import LocationUsers from './location-users/LocationUsers'
const SecuritySettings = (props) => {
    return (
        <TabsTemplate>
            <div title='Manage Users'>
                <UserSettingsTable />
            </div>
            <div title='Manage Roles'>
                <UserRolesTable/>
            </div>
            <div title='Location Users'>
                <LocationUsers/>
            </div>
        </TabsTemplate>
    )
}

export default SecuritySettings