import React, { useEffect, useState, useContext } from "react";
import { store } from '../../../../../context/StateProvider'
import ProductService from "../../../../../services/api/product.service";
import label from "../../../../../../assets/i18n/en.json";
import moment from "moment";
import MessageSetting from "../../../../../common/constants/message-setting.constant";
import ModalBox from "../../../../templates/components/ModalBox";
import ProductAdd from "../product-add/ProductAdd";
import ProductEdit from "../product-edit/ProductEdit";
import Utilities from "../../../../../services/commonservice/utilities";
import Table2 from "../../../../templates/components/Table2";
import APIResponse from "../../../../templates/components/APIResponse";
import DimLoader from "../../../../templates/components/DimLoader";
import PracticeLocationSelector from "../../../../templates/components/PracticeLocationSelector";
import AppointmentService from "../../../../../services/api/appointment.service";

const ProductTable = (props) => {
  const globalStateAndDispatch = useContext(store)
  const state = globalStateAndDispatch.state
  const [isLoader, setIsLoader] = useState(false);
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [productList, setProductList] = useState([]);
  const [locationList, setLocationList] = useState()
  const [selectedProduct, setSelectedProduct] = useState();
  const [productLookupList, setProductLookupList] = useState([]);
  const [filter, setFilter] = useState()
  const hiddenColumns = ["createdOn", "taxPercent", "discount", "description", "quantity"]
  const [apiResponse, setApiResponse] = useState()
  const [allLocations, setAllLocations] = useState(false)
  const onSuccess = (message) => {
    findProduct();
    if (props.refresh) {
      props.refresh()
    }
    if (props.onClose) {
      props.onClose()
    }
    if (props.exitHandler) {
      props.exitHandler()
    }
  }

  const getPracticeLocations = () => {
    setIsLocationLoading(true);
    AppointmentService.practiceLocation()
      .then((response) => {
        setLocationList(response);
        setIsLocationLoading(false);
      })
      .catch(error => {
        setIsLocationLoading(false);
      })
  }
  const findProduct = () => {
    let reqObj = {
      ItemType: 1,
      PracticeLocationId: state?.practiceLocationId || undefined
    }
    if (allLocations) delete reqObj.PracticeLocationId;
    setIsLoader(true);
    ProductService.findProduct(reqObj)
      .then((response) => {
        setProductList(response.data.filter(prod => prod.itemType === 1));
        setProductLookupList(response.data);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };
  const statusHandler = () => {
    setStatusModal(false);
    setIsLoading(true)
    if (selectedProduct.status == 1) {
      ProductService.deactivateProduct(selectedProduct.id).then((response) => {
        setApiResponse(response);
      }).catch(error => {
        setApiResponse(error);
      })
        .finally(() => {
          setIsLoading(false)
        });
    } else {
      ProductService.activateProduct(selectedProduct.id).then((response) => {
        setApiResponse(response);
      }).catch(error => {
        setApiResponse(error);
      })
        .finally(() => {
          setIsLoading(false)
        });;
    }
  };

  useEffect(() => {
    getPracticeLocations()
  }, []);
  useEffect(() => {
      findProduct();
  }, [allLocations,state?.practiceLocationId])

  const columns = [
    {
      id: "createdOn",
      Header: "Created On",
      accessor: (product) => moment(product.createdOn).format("MM/DD/YYYY"),
      Footer: () => 'Totals'
    },
    {
      id: "name",
      Header: "Name",
      accessor: item => item.name,
    },
    {
      id: "quantity",
      Header: "Quantity",
      accessor: item => item.quantity,
      Footer: info => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce((sum, row) => parseInt(row.original.quantity) + sum, 0),
          [info.rows]
        )

        return <>{total}</>
      },
    },
    {
      id: "unitPrice",
      Header: "Unit Price",
      accessor: product => { return Utilities.toDollar(product.unitPrice) }
    },
    {
      id: "taxPercent",
      Header: "Tax Percent",
      accessor: item => item.taxPercent ? `${item.taxPercent}%` : null
    },
    {
      id: "discount",
      Header: "Discount",
      accessor: product => product.discount ? Utilities.toDollar(product.discount) : null
    },
    {
      id: "sku",
      Header: "SKU",
      accessor: product => product.sku ? product.sku : null
    },
    {
      id: "cost",
      Header: "Cost",
      accessor: product => { return Utilities.toDollar(product.cost) }
    },
    // {
    //   id: "mfg",
    //   Header: "MFG",
    //   accessor: product => product.mfg ? product.mfg : null
    // },
    {
      id: "description",
      Header: "Description",
      accessor: item => item.description
    },
    {
      id: "isActive",
      Header: "Active",
      align: "center",
      sortable: true,
      accessor: (product, i) =>
        product.status === 1 ? (
          <span className="btn btn-success text-white w-100">Active</span>
        ) : (
          <span className="btn btn-danger text-white w-100">Inactive</span>
        ),
    },
    {
      id: "actionPracticePatient",
      Header: "Action",
      disableSortBy: true,
      accessor: (product, i) => {
        return (
          <div className="d-flex justify-content-center">
            <button
              className="p-0 ps-1 btn btn-primary"
              title="Edit Product"
              onClick={(e) => {
                e.preventDefault();
                setSelectedProduct(product);
                return setOpenModal(true);
              }}
            >
              <i className="icon pencil" />
            </button>
            <button
              className="p-0 ps-1 btn btn-primary ms-1"
              title={
                product.status === 1 ? "Deactivate Product" : "Activate Product"
              }
              onClick={(e) => {
                e.preventDefault();
                setSelectedProduct(product);
                return setStatusModal(true);
              }}
            >
              {product.status === 1 ? <i className="icon dont" /> : <i className="icon check" />}
            </button>
          </div>
        );
      },
    },
  ];



  return (
    <>
      {productList ? (
        <div className="row d-flex col-12 mb-3">
          <div>{productList ? (
            <Table2
              hiddenColumns={hiddenColumns}
              export
              searchable
              loading={isLoader && isLocationLoading}
              columns={columns}
              data={productList.filter(obj => { if (filter === '' || filter === null || !filter || filter === 'All') { return obj } else { return obj.status === parseInt(filter) } })}
              extraButtons={[
                {
                  className: 'btn btn-primary mt-auto',
                  children: <i className="icon plus" />,
                  title: "Add Product",
                  onClick: () => { setShowAdd(true) }
                }
              ]}
              extraElements={
                [
                  {
                    children: 
                     <div className='col-md-auto'>
                    <div className='form-check'>
                      <input type="checkbox" className='form-check-input' checked={allLocations} name="allLocations" onChange={e => { setAllLocations(e.target.checked) }} />
                      <label className='form-check-label'>All Locations</label>
                    </div>
                   </div>
                  },
                  {
                    children: 
                   <div className='col-md-auto'>
                  <PracticeLocationSelector locations={locationList}/>
                  </div>
                  },
                  {
                    children: <div className="col-md-auto">
                      <label>Filter By</label>
                      <select className="form-select" value={filter} onChange={e => { setFilter(e.target.value) }}>
                        <option value={null} selected>All</option>
                        <option value={1}>Active Only</option>
                        <option value={0}>Inactive Only</option>
                      </select>
                    </div>
                  }
                ]
              }
            />
          ) : // <ProductList />
            null}
          </div>
        </div>
      ) : null}
      <ModalBox open={openModal} onClose={() => setOpenModal(false)} title="Edit Product">
        {selectedProduct && <ProductEdit
          id={selectedProduct.id}
          onClose={() => {
            findProduct();
            return setOpenModal(false);
          }}
        />}
      </ModalBox>

      <ModalBox open={statusModal} onClose={() => setStatusModal(false)} title={selectedProduct?.status === 1 ? "Deactivate Product" : "Activate Product"}>
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col">
            {selectedProduct?.status === 1 ? (
              <p>Would you like to deactivate {selectedProduct?.name}</p>
            ) : (
              <p>Would you like to activate {selectedProduct?.name} </p>
            )}
          </div>
          <div className="col-auto">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                statusHandler();
              }}
            >
              OK
            </button>
            <button
              className="btn btn-secondary ms-3"
              onClick={(e) => {
                e.preventDefault();
                setStatusModal(false);
              }}
            >
              {label.common.cancel}
            </button>
          </div>
        </div>
      </ModalBox>
      <ModalBox
        open={showAdd}
        onClose={() => {
          setShowAdd(false);
        }}
        title="Add Product"
      >
        <ProductAdd
          onClose={() => {
            findProduct();
            return setShowAdd(false);
          }}
        />
      </ModalBox>
      {isLoading ? <DimLoader /> : null}
      <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true}  successMessage={selectedProduct?.status == 1 ? MessageSetting.Product.deactivate : MessageSetting.Product.activate}/>
    </>
  );
};

export default ProductTable;
