import React, { useState } from 'react'
import ManufacturerTable from './ManufacturerTable'

const ManufacturerSettings = (props) => {
    return (
        <div title="Manufacturer">
            <ManufacturerTable title="Manufacturer" />
        </div>
    )
}

export default ManufacturerSettings