import React, { useState, useEffect } from 'react'
import InvoiceService from "../../../../../../../../services/api/invoice.service";
import Utilities from '../../../../../../../../services/commonservice/utilities';
import ModalBox from '../../../../../../../templates/components/ModalBox';
import OpenBalanceCard from './OpenBalanceCard';
import AddInvoice from '../../../../../invoices/add-invoice/AddInvoice';

const OpenBalancesList = (props) => {
  const [invoiceBalances, setInvoiceBalances] = useState()
  const [showAdd, setShowAdd] = useState(false)
  const [openBalance, setOpenBalance] = useState(0)
  const [isLoader, setIsLoader] = useState(false)
  useEffect(() => {
    if (props.autoPull) {
      pullInvoiceBalances()
    }
    else if (props.pull) {
      pullInvoiceBalances()
    }
  }, [props.autoPull, props.pull])

  const pullInvoiceBalances = () => {
    setIsLoader(true);
    let reqObj = {
      sortField: 'dueDate',
      Asc: 'false',
      PatientIds: [props.patientId],
      StartRow: 0,
      // InvoiceStatuses: '1,2,5,6,7,8,10',
      InvoiceStatuses: '1,2,4,5,6,8,10',
    }
    InvoiceService.findInvoice(reqObj)
      .then((res) => {
        if (res?.data?.data.length) {
          if (Array.isArray(res?.data?.data) === true) {
            setInvoiceBalances(res?.data?.data.sort((a, b) => b.dueDate.localeCompare(a.dueDate)))
          }
          else {
            setInvoiceBalances([res?.data?.data]);
          }
        }
        else {
          setInvoiceBalances();
        }
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setIsLoader(false);
      });

  }

  // const changeShowDetails = (id) => {
  //     if (id === showDetails) {
  //         setShowDetails()
  //     }
  //     else (
  //         setShowDetails(id)
  //     )
  // }
  // console.log(props.patientId)

  useEffect(() => {
    if (invoiceBalances) {
      let total = 0
      invoiceBalances.forEach(obj => { if (obj?.amountPaid) { total += obj.finalAmount - obj.amountPaid } else { total += obj.finalAmount } })
      return setOpenBalance(total)
    } else {
      return setOpenBalance(0)
    }
  }, [invoiceBalances, props.refresh])

  // useEffect(() => {
  //   setInvoiceBalances(props.balances)
  // }, [props.balances])

  return (
    <>
      {!isLoader ? <div className="row d-flex g-4"><div className='col-12'>
        <div className='btn btn-primary w-100 p-3'><h5 className='text-white'><strong>Open Balance Sum: {openBalance && Utilities.toDollar(openBalance)}</strong></h5></div>
      </div>
        {invoiceBalances ? invoiceBalances.map((invoice, idx) => (
          <div className='col-md-4 col-12'>
            <OpenBalanceCard invoice={invoice} refresh={() => { props.refresh(); pullInvoiceBalances() }} />
          </div>
          // <div className='row-fluid col-12'> 
          //         <InvoiceCard transaction={invoice}/>
          // </div>
        ))
          :
          <div>
            <div className="ui warning message mt-3 segment p-3 shadow-sm">
              <span>There are currently no balances for this user. <a href="#" onClick={e => { e.preventDefault(); setShowAdd(true) }}>Add Balance</a></span>
            </div>
          </div>
        }</div> : <div className="ui warning message mt-3 segment p-3 shadow-sm">
        <span>Please be patient while data loads...</span>
      </div>}
      <ModalBox open={showAdd} onClose={() => { pullInvoiceBalances(); return setShowAdd(false) }} title="Add Invoice">
        <AddInvoice initialData={{ patientId: props.patientId }} onClose={() => { pullInvoiceBalances(); return setShowAdd(false) }} isModal />
      </ModalBox>
    </>
  );
}

export default OpenBalancesList