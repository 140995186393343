import React, { useEffect, useState } from 'react'
import AppointmentSchedule from './AppointmentSchedule'
import {useBlocker } from 'react-router-dom';
import StorageService from '../../../../../../services/session/storage.service';
import StorageType from '../../../../../../services/session/storage.enum';
import LoginService from '../../../../../../services/api/login.service';
import ModalBox from '../../../../../templates/components/ModalBox';

const ScreenLockWrapper= (props) => {

    const blocker = useBlocker(true);
	const [password, setPassword] = useState();
    const [showPassword, setShowPassword] = useState(false)
    const [passwordError, setPasswordError] = useState()
    const [showUnlockModal, setShowUnlockModal] = useState(false)

    useEffect(() => {
        if (blocker?.state === "blocked") {
            setPasswordError('');
            setPassword('');
            setShowPassword(false)
            setShowUnlockModal(true);
        } 
    }, [blocker])

    const unlockScreen = () => {
        let userDetails = JSON.parse(StorageService.get(StorageType.session, 'userDetails'));
        var userName = userDetails?.userName;

        // call unlockScreen API
        let req = {
            "sendCatalog":true,
            "RememberMe":true,
            "userName": userName,
            "password": password
        };
        LoginService.unlockScreen(req)
        .then(res => {
            if (res?.statusText == 'OK') {
                blocker?.proceed()
            }
            else if (res?.isAxiosError && res?.response?.data?.message == "Key_InvalidUsernameOrPassword") {
                    setPasswordError('Invalid username or password.  Please try again.')
            }
            else {
                setPasswordError('Something went wrong.  Please try again.')
            }
        })
        .catch(err => {
            console.log(err);
        })

    };

    const passwordChanged = (e) => {
        e.preventDefault()
        setPassword(e.target.value);
        setPasswordError();
    };

    return (
        <>

            <AppointmentSchedule shouldBlock={true} resource="provider" />
        
            <ModalBox open={showUnlockModal} 
                onClose={() => { 
                    blocker?.reset() ;
                    setShowUnlockModal(false);
                    }} 
                title={`Screen Locked`}>
                <div className='row d-flex'>
                    <div className='col-12'>
                        Screen is locked.  Please enter your password and click Unlock Screen.
                    </div>
                    <div className='col-12'>
                        <div className="required field col-12">
                            <label>Password</label>
                            <div className="ui left icon input w-100 password-field">
                                <i className="lock icon ms-2 password-field"></i>
                                <input type={showPassword ? 'text' : 'password'} placeholder="password" name="password" value={password} onChange={e => { passwordChanged(e) }} />
                                <a className="ui icon view-pw">
                                    <i onClick={e => { e.preventDefault(); showPassword ? setShowPassword(false) : setShowPassword(true) }} className={showPassword ? 'eye icon text-decoration-none' : 'low vision icon text-decoration-none'}></i>
                                </a>
                            </div >
                            <span className="error-msg" style={{ textAlign:"left", color: "red"}}>
                                {passwordError && passwordError}
                            </span>
                        </div >

                        <div className='col-12 d-flex justify-content-end mt-3 modal-button-bar'>

                            <button type="submit" onClick={e => {e.preventDefault(); unlockScreen(e) }} className="btn btn-primary me-2">
                                Unlock Screen
                            </button>
                            <button 
                                onClick={e => { 
                                    e.preventDefault(); 
                                    blocker?.reset?.();
                                    setShowUnlockModal(false);
                                }} 
                                className='btn btn-secondary '  
                            >
                                Close
                            </button>
                        </div >
                    </div>
                </div>
            </ModalBox>        
        </>
    )
}

export default ScreenLockWrapper