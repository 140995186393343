import './App.scss';
import { RouterProvider, createBrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useEffect, useLayoutEffect } from 'react';
import StorageService from './app/services/session/storage.service';
import $ from 'jquery'
import { Toaster } from 'react-hot-toast'
import TokenInterceptor from './app/services/api/token-interceptor';
import StateProvider from './app/context/StateProvider';
import Login from './app/components/public/login/Login';
import AppFrame from './app/components/layouts/AppFrame';
import CommonService from './app/services/api/common.service';
import FourOhFour from './app/components/public/FourOhFour';
import TermsAndConditions from './app/components/public/terms-and-conditions/TermsAndConditions';
import ResetPassword from './app/components/public/password/ResetPassword';
import ChangePassword from './app/components/public/password/ChangePassword';
import PatientLogin from './app/components/public/login/patient-login/PatientLogin';
import EmailSMSTemplates from './app/components/templates/EmailSMSTemplates';
import PublicSchedule from './app/components/public/public-schedule/PublicSchedule';
// This provides us with context


function App() {

  // const navigate = useNavigate()
  // // TokenInterceptor()
  // $("img").mousedown(function (e) {
  //   e.preventDefault()
  // });
  // $("img").on("contextmenu", function (e) {
  //   e.preventDefault()
  //   return false;
  // });
useLayoutEffect(()=>{
     TokenInterceptor()
},[])
  useEffect(() => {
    setInterval(() => {
      let tempToken = CommonService.getLoggedInData()
      if (tempToken.publicSchedule || !tempToken.id) return;
      CommonService.getRefreshToken(tempToken)
        .then(res => {
          StorageService.save('session', "auth", JSON.stringify(res));
          // toast.success("Token Refreshed | Dev Environment")
        })
        .catch(err => { navigate('login') })
    },
      300000)

    return () => {
      let tempToken = CommonService.getLoggedInData()
      if (tempToken.publicSchedule || !tempToken.id) return;
      CommonService.getRefreshToken(tempToken)
        .then(res => {
          StorageService.save('session', "auth", JSON.stringify(res));
          // toast.success("Token Refreshed | Dev Environment")
        })
        .catch(err => {
          navigate('login')
        })
    }
  }, [])


  const router = createBrowserRouter([
    {
    path:"login" ,
    element:<Login  />
    },
    {
    path:"login/:providerName" ,
    element:<Login  />
    },
    {
    path:"terms-conditions" ,
    element:<TermsAndConditions  />
    },
    {
    path:"terms-conditions/:userId" ,
    element:<TermsAndConditions  />
    },
    {
    path:"change-password" ,
    element:<ChangePassword  />
    },
    {
    path:"change-password/:parentId/:userType/:userId/:isReset" ,
    element:<ChangePassword  />
    },
    {
    path:"change-password/:parentId/:userType/:userId/:isReset/:providerName" ,
    element:<ChangePassword  />
    },
    {
    path:"forgot-password" ,
    element:<ResetPassword  />
    },
    {
    path:"patient/forgot-password" ,
    element:<ResetPassword  />
    },
    {
    path:"patient/login" ,
    element:<PatientLogin  />
    },
    {
    path:"patient/login/:providerName" ,
    element:<PatientLogin  />
    },
    {
    path:"templates" ,
    element:<EmailSMSTemplates />  
    },
    {
      path:"schedule" ,
      element:<PublicSchedule />  
    },
    {
      path:"schedule/:providerId" ,
      element:<PublicSchedule />  
    },
    {
      path:"schedule/:providerId/:providerSlug" ,
      element:<PublicSchedule />  
    },
    {
    path:"*" ,
    element:<AppFrame  />
    },
    {
    path:"/" ,
    element:<Navigate to='login' />
    },
    {
    path:"/404" ,
    element:<FourOhFour  />
    },
  ]);

  return (
    <StateProvider>
      <Toaster position="bottom center"
        toastOptions={{
          success: {
            className: "toaster-success"
          },
          error: {
            className: "btn btn-light",
          }
        }} />
      <RouterProvider router={router} />
    </StateProvider>
  );
}



export default App;
