import AppSetting from '../../common/constants/appsetting.constant';
import StorageType from '../session/storage.enum';
import StorageService from '../session/storage.service';
import axios from 'axios'

const ManufacturerService = {
  getLoggedInData() {
    return JSON.parse(StorageService.get(StorageType.session, 'userDetails'));
  },
  addManufacturer(reqObj) {
    let loggedInUserData = this.getLoggedInData();
    let url = AppSetting.settings.addManufacturer
      .replace('{providerId}', loggedInUserData.parentId);
    return axios.post(url, reqObj)
      .then(a => { return a })
      .catch(err => { return err })
  },

  editManufacturer(reqObj) {
    let loggedInUserData = this.getLoggedInData();
    let url = '';
    url = AppSetting.settings.editManufacturer
      .replace('{providerId}', loggedInUserData.parentId)
      .replace('{manufacturerId}', reqObj.manufacturerId);
    return axios.put(url, reqObj)
      .then(a => { return a })
      .catch(err => { return err })
  },
  getManufacturerById(reqObj) {
    this.loggedInUserData = this.getLoggedInData();
    let url;

    url = AppSetting.settings.editManufacturer
      .replace('{providerId}', this.getLoggedInData()['parentId'])
      .replace('{manufacturerId}', reqObj.id);

    return axios.get(url)
      .then(a => { return a.data })
      .catch(err => console.log(err))

  },
  deleteManufacturer(id) {
    let loggedInUserData = this.getLoggedInData();
    let url;
    url = AppSetting.settings.deleteManufacturer
      .replace('{providerId}', loggedInUserData.parentId)
      .replace('{manufacturerId}', id);

    return axios.delete(url)
      .then(a => { return a })
      .catch(err => { return err })

  },

  getAllManufacturer() {
    let loggedInUserData = this.getLoggedInData();
    let url = AppSetting.settings.getAllManufacturer
      .replace('{providerId}', loggedInUserData.parentId);

    return axios.get(url)
      .then(a => { return a })
      .catch(err => { return err })
  },
  activateManufacturer(id) {
    this.loggedInUserData = this.getLoggedInData();
    let url;
    url = AppSetting.settings.activateDeactivateManufacturer
      .replace('{providerId}', this.getLoggedInData()['parentId'])
      .replace('{manufacturerId}', id);
    return axios.post(url, {})
      .then(a => { return a.data })
      .catch(err => console.log(err))
  },

  deactivateManufacturer(id) {
    this.loggedInUserData = this.getLoggedInData();
    let url;
    url = AppSetting.settings.activateDeactivateManufacturer
      .replace('{providerId}', this.getLoggedInData()['parentId'])
      .replace('{manufacturerId}', id);

    return axios.delete(url)
      .then(a => { return a.data })
      .catch(err => console.log(err))
  },
}

export default ManufacturerService