import React, { useState } from 'react'
import label from '../../../../../../../../../assets/i18n/en.json'
const ManufacturerForm = (props) => {
    // Pull properties form parent
    const { initialData, messages, isEdit, submitHandler, loaded, exitHandler } = props;
    // Set states for form
    const [isLoader, setIsLoader] = useState(false)

    const [formData, setFormData] = useState(props.initialData || {})
    const [formErrors, setFormErrors] = useState(messages.errors)

    // formula for input change
    const inputChange = (e) => {
        let newStateObject = { ...formData };
        newStateObject[e.target.name] = e.target.value;
        setFormData(newStateObject);
        return (console.log(formData))
    };
    return (
        <div>
            {isLoader && !loaded && <div className="ui">
                <div className="ui active dimmer">
                    <div className="ui indeterminate text loader">{label.common.processing}</div>
                </div>
            </div>}
            <form className=''>
                <div className="">
                    <div className="">
                        <div className="required field">
                            <label>Manufacturer</label>
                            <input
                                onChange={(e) => {
                                    e.preventDefault();
                                    inputChange(e);
                                }}
                                placeholder="Manufacturer"
                                type="text"
                                name="manufacturer"
                                value={formData.manufacturer}
                                required
                            />
                            {/* <span>{formErrors.manufacturer}</span> */}
                        </div>
                    </div>
                </div >
            </form >
            <div className="mt-3 d-flex justify-content-between">
                <div className='col-auto'>
                    <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); submitHandler(formData) }}> {isEdit ? 'Update' : 'Save'}</button>
                </div>
                <div className='col-auto'>
                    <button className="btn btn-secondary float-right" onClick={(e) => { e.preventDefault(); props.onClose() }}> Cancel </button>
                </div>
            </div>
        </div >
    )
}

export default ManufacturerForm