import moment from 'moment'
import React, { useState, useEffect, useContext } from 'react'
import { store } from '../../../../../context/StateProvider'
import AppointmentService from '../../../../../services/api/appointment.service'
import ProviderDashboardService from '../../../../../services/api/provider-dashboard.service'
import Utilities from '../../../../../services/commonservice/utilities'
import StorageService from '../../../../../services/session/storage.service'
import DatePicker from '../../../../templates/components/DatePicker'
import Module from '../../../../templates/components/Module'
import Table2 from '../../../../templates/components/Table2'
const TransactionSummary = (props) => {
    // context state and dispatch 
    const globalStateAndDispatch = useContext(store)
    const state = globalStateAndDispatch.state
    const [startDate, setStartDate] = useState(moment().startOf("D"));
    const [endDate, setEndDate] = useState(moment().endOf("D"));
    const [locationList, setLocationList] = useState(state.practiceLocations || null)
    const [locationId, setLocationId] = useState(state.practiceLocationId || null)
    const [transactionSummary, setTransactionSummary] = useState()
    const [isLoader_Summary, setIsLoader_Summary] = useState(false)
    const [initialMessage, setInitialMessage] = useState(true)

    const getTransactionSummary = () => {
        setTransactionSummary()
        setIsLoader_Summary(true)
        setInitialMessage(false)
        let reqObj = {
            ToDate: moment(endDate).toISOString(),
            FromDate: moment(startDate).toISOString(),
        };
        if (locationId) {
            reqObj.PracticeLocationId = locationId
        }
        return ProviderDashboardService.getTransactionSummary(reqObj)
            .then(response => {
                setTransactionSummary(response.data)
                setIsLoader_Summary(false)
            })
            .catch(err => {
                setTransactionSummary()
                setIsLoader_Summary(false)
            })
    }

    const summaryColumns = [
        {
            id: "practiceLocation",
            Header: "Location",
            accessor: (tc) => tc.practiceLocation,
            Footer: () => 'Totals'
        },
        {
            id: "channel",
            Header: "Channel",
            accessor: (tc) => tc.channel
        },
        {
            id: "count",
            Header: "Count",
            accessor: (tc) => tc.count,
            Footer: info => {
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.count + sum, 0),
                    [info.rows]
                )

                return <>{total}</>
            },
        },
        {
            id: "netSale",
            Header: "Net Sale",
            accessor: (tc) => Utilities.toDollar(tc.netSale),
            Footer: info => {
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.netSale + sum, 0),
                    [info.rows]
                )

                return <>{Utilities.toDollar(total)}</>
            },
        },
        {
            id: "tax",
            Header: "Tax",
            accessor: (tc) => Utilities.toDollar(tc.tax),
            Footer: info => {
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.tax + sum, 0),
                    [info.rows]
                )

                return <>{Utilities.toDollar(total)}</>
            },
        },
        {
            id: "total",
            Header: "Total",
            accessor: (tc) => Utilities.toDollar(tc.total),
            Footer: info => {
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.total + sum, 0),
                    [info.rows]
                )

                return <>{Utilities.toDollar(total)}</>
            },
        },
        {
            id: "refund",
            Header: "Refund",
            accessor: (tc) => Utilities.toDollar(tc.refund),
            Footer: info => {
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.refund + sum, 0),
                    [info.rows]
                )

                return <>{Utilities.toDollar(total)}</>
            },
        },
        {
            id: "deposit",
            Header: "Deposit",
            accessor: (tc) => Utilities.toDollar(tc.deposit),
            Footer: info => {
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.deposit + sum, 0),
                    [info.rows]
                )

                return <>{Utilities.toDollar(total)}</>
            },
        },
    ]

    const findLocations = () => {
        AppointmentService.practiceLocationLookup()
            .then((res) => {
                setLocationList(res)
                setLocationId(res[0].practiceLocationId)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        if (!locationList) {
            if (StorageService.get("session", "locale")) {
                let payload = JSON.parse(StorageService.get("session", "locale"))
                setLocationList(payload)
            }
            else {
                findLocations();
            }
        }
        //getTransactionSummary()
    }, [])

    return (
        <div
            className="row-fluid"
            id="primary"
            role="tabpanel"
            aria-labelledby="primary-tab"
        >
            <div className="row g-3">
                <div className='col-12 m-p-0'>
                    <Module title="Filters">
                        <div className='row d-flex'>
                            <div className='col-12 row d-flex justify-content-center mx-0 align-items-end report-filters'>
                            <DatePicker locationClearable={true} setLocationId={setLocationId} startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate}/>
                                <div className="col-md-auto col-12"><button className="btn btn-primary" onClick={e => { e.preventDefault(); getTransactionSummary() }} title="Pull Report"><i className="icon arrow circle right" /></button></div>
                            </div>
                        </div>
                    </Module>
                </div>
                <div className='col-12 m-p-0'>
                    <Module title="Transaction Summary">
                        <Table2 export title="Transaction Summary" loading={isLoader_Summary} columns={summaryColumns} data={transactionSummary}  initialMessage={initialMessage}/>
                    </Module>
                </div>
            </div >
        </div >
    )
}
export default TransactionSummary