import React, { useState } from 'react'
import PracticeLocationForm from '../location-form/PracticeLocationForm'
import AppointmentService from '../../../../../services/api/appointment.service'
import APIResponse from '../../../../templates/components/APIResponse';
import DimLoader from '../../../../templates/components/DimLoader'

const PracticeLocationAdd = (props) => {
    const [isLoader, setIsLoader] = useState(false)
    const [apiResponse, setApiResponse] = useState()

    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }

    const submitHandler = (data) => {
        setIsLoader(true)
        AppointmentService.addPracticeLocation(data)
            .then(response => {
                setApiResponse(response)
            })
            .catch(error => {
                setApiResponse(error)
            })
            .finally(() => {
                setIsLoader(false)
            });
    }
    return (
        <div>
            {isLoader &&
                <DimLoader loadMessage="Processing" />
            }
            <PracticeLocationForm submitHandler={submitHandler} onClose={() => { props.onClose() }} />
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </div>
    )
}

export default PracticeLocationAdd