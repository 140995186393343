const Countries = [
    {
      'countryId': 1,
      'name': 'USA',
      'id': 1
    },
    // {
    //   'countryId': 2,
    //   'name': 'CANADA',
    //   'id': 2
    // }
  ]

  export default Countries
