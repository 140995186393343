import React, { useState, useEffect } from 'react'
import ManufacturerForm from '../manufacturer-form/ManufacturerForm'
import ManufacturerService from '../../../../../../../../services/api/manufacturer.service'
import APIResponse from '../../../../../../../templates/components/APIResponse'

const ManufacturerAdd = (props) => {
    const { embed, inputChange, refresh, exitHandler } = props;
    const [formData, setFormData] = useState({ })
    const [messages, setMessages] = useState({})
    const [isLoader, setIsLoader] = useState(false)
    const [apiResponse,setApiResponse] = useState()

    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }
    const submitHandler = (data) => {
            let reqObj = {}
            reqObj.manufacturer = data.manufacturer;
            setIsLoader(true)
            ManufacturerService.addManufacturer(reqObj)
                .then(response => {
                    setApiResponse(response)
                    clearForm()
                    setIsLoader(false)
                })
                .catch(error => {
                    setApiResponse(error)
                    setIsLoader(false)
                })
    }

    const clearForm = () => {
        setFormData({ })
    }
    return (
        <div>
            <ManufacturerForm  submitHandler={submitHandler} onClose={()=>props.onClose()} initialData={formData} inputChange={inputChange} loaded={!isLoader} messages={messages} />
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true}/>
        </div>
    )
}

export default ManufacturerAdd