import React, {useState, useEffect} from 'react'
import TabsTemplate from '../../../../../../../templates/components/TabsTemplate'
import UnavailableBlockTable from '../../../../unavailable-block-table/UnavailableBlockTable'
import ProviderAvailability from '../provider-availability/ProviderAvailability'

const ProviderAvailabilityTabs = (props) => {

    return(
        <div style={{ paddingTop: 5 }}>
            <TabsTemplate>
                <div title="Away">
                    <UnavailableBlockTable providerId={props.id}/>
                </div>
                <div title="Available Hours">
                    <ProviderAvailability id={props.id}/>
                </div>
                </TabsTemplate>
        </div>
    )
}

export default ProviderAvailabilityTabs