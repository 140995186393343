import React, { useEffect, useState } from 'react'
import LeadSourceService from '../../../../../../services/api/lead-source.service'
import ModalBox from '../../../../../templates/components/ModalBox'
import Table2 from '../../../../../templates/components/Table2'
import AddLeadSource from './AddLeadSource'
import MessageSetting from '../../../../../../common/constants/message-setting.constant'
import APIResponse from '../../../../../templates/components/APIResponse'
import label from "../../../../../../../assets/i18n/en.json";
import DimLoader from '../../../../../templates/components/DimLoader'
import EditLeadSource from './EditLeadSource'

const LeadSourceSettings = (props) => {
  const [isLoader, setIsLoader] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [leadSource, setLeadSource] = useState()
  const [selectedLeadSource, setSelectedLeadSource] = useState()
  const [add, setAdd] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [statusModal, setStatusModal] = useState(false);
  const [apiResponse, setApiResponse] = useState()

  const onSuccess = (message) => {
    if (props.refresh) {
      props.refresh()
    }
    if (props.onClose) {
      props.onClose()
    }
    if (props.exitHandler) {
      props.exitHandler()
    }
    findAllLeadSource()
  }

  const findAllLeadSource = () => {
    setIsLoader(true)
    LeadSourceService.getAllLeadSource()
      .then(res => {
        setLeadSource(res.data)
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setIsLoader(false)
      });
  }

  const columns = [
    {
      id: "leadSource",
      Header: "Lead Source",
      accessor: leadSource => leadSource.leadSource
    },
    {
      id: "actionLeadSource",
      Header: "Action",
      textAlign: "center",
      disableSortBy: true,
      accessor: (leadSource, i) => {
        return (

          <div className="d-flex justify-content-center">
            <button className="p-0 ps-1 btn btn-primary" title="Update Lead Source" onClick={e => { e.preventDefault(); setSelectedLeadSource(leadSource); setShowEdit(true) }}><i className="icon pencil" /></button>
            <button
              className="p-0 ps-1 btn btn-primary ms-1"
              title={"Delete Lead Source"}
              onClick={(e) => {
                e.preventDefault();
                setSelectedLeadSource(leadSource);
                return setStatusModal(true);
              }}
            >
              <i className="icon trash" />
            </button>
          </div>
        );
      },
    },
  ];

  const statusHandler = () => {
    setStatusModal(false);
    setIsLoading(true)
    LeadSourceService.deleteLeadSource(selectedLeadSource.id).then((response) => {
      setApiResponse(response);
    }).catch(error => {
      setApiResponse(error);
    })
      .finally(() => {
        setIsLoading(false)
      });
  };
  useEffect(() => {
    findAllLeadSource()
  }, [])
  return (
    <div className='d-flex row'>
      <Table2 export searchable data={leadSource} loading={isLoader} columns={columns}
        extraButtons={[
          { className: 'btn btn-primary', children: <i className='icon plus' />, title: 'Add Lead Source', onClick: () => { setAdd(true) } }
        ]} />
      <ModalBox open={showEdit} onClose={() => { setShowEdit(false) }} title="Edit Lead Source">
        <EditLeadSource initialData={selectedLeadSource} closeModal={setShowEdit} onClose={() => { findAllLeadSource(); setShowEdit(false) }} />
      </ModalBox>
      <ModalBox open={add} onClose={() => { setAdd(false) }} title={"Add Lead Source"}>
        <AddLeadSource closeModal={setAdd} onClose={() => { findAllLeadSource(); setAdd(false) }} />
      </ModalBox>
      <ModalBox open={statusModal} onClose={() => setStatusModal(false)} title={"Delete Lead Source"}>
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col">
            <p>Would you like to delete {selectedLeadSource?.leadSource}</p>
          </div>
          <div className="col-auto">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                statusHandler();
              }}
            >
              OK
            </button>
            <button
              className="btn btn-secondary ms-3"
              onClick={(e) => {
                e.preventDefault();
                setStatusModal(false);
              }}
            >
              {label.common.cancel}
            </button>
          </div>
        </div>
      </ModalBox>
      {isLoading ? <DimLoader /> : null}
      <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} successMessage={MessageSetting.LeadSource.delete} />
    </div>
  )
}
export default LeadSourceSettings