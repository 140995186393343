import moment from 'moment'
import React, { useEffect, useState, useContext } from 'react'
import Utilities from '../../../../../services/commonservice/utilities'
import { store } from '../../../../../context/StateProvider'
import ReportService from '../../../../../services/api/report.service'
import DoctorService from '../../../../../services/api/doctor.service'
import Select from 'react-select'
import NoResults from '../../../../templates/components/NoResults'
import DimLoader from '../../../../templates/components/DimLoader'

const EmployeeWorkTicket = (props) => {
    const [tickets, setTickets] = useState()
    const [startDate, setStartDate] = useState(Utilities.toDate(moment().startOf("D")))
    const [endDate, setEndDate] = useState(Utilities.toDate(moment().add(1, "d").startOf("D")))
    const [providerId, setProviderId] = useState()
    const [providerList, setProviderList] = useState()
    const stateAndDispatch = useContext(store)
    const state = stateAndDispatch.state

    const [locationList, setLocationList] = useState(state?.practiceLocations || null)
    const [initialMessage, setInitialMessage] = useState(true)
    const [loading, setLoading] = useState(false)
    const [locationId, setLocationId] = useState(state.practiceLocationId || null)
    const [providerName, setProviderName] = useState()

    const fetchReport = () => {
        let reqObj = {
            FromDate: moment(startDate).startOf('d').format("YYYY-MM-DD HH:mm:ss"),
            ToDate: moment(startDate).endOf('d').format("YYYY-MM-DD HH:mm:ss")
        }
        if (providerId != undefined && providerId != null && providerId && locationId) {
            setInitialMessage(false)
            setLoading(true)
            setTickets()
            ReportService.getEmployeeWorkTicket(providerId, locationId, reqObj)
                .then(res => {
                    console.log(res)
                    setTickets(res)
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setTickets()
                    setLoading(false)
                })
        }
    }
    const providerLookup = () => {
        setLoading(true)
        if (props.providerId) {
            DoctorService.getById(props.providerId)
                .then(
                    res => {
                        setLoading(false)
                        console.log(res)
                        setProviderList([res])
                        setProviderId(res?.id)
                    }
                )
        }
        else {
            let reqObj = { isRegistered: true, isActive: true, PracticeLocationId: state?.practiceLocationId }
            DoctorService.find(reqObj)
                .then(res => {
                    setLoading(false)
                    console.log(res.data)
                    setProviderList(res.data)
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                    setProviderList()
                })
        }
    }

    useEffect(() => {
        if (props.providerId) {
            setProviderId(props.providerId)
        }
        else{
            providerLookup()
        }
    }, [props.providerId])

    useEffect(() => {
        if(providerId && providerList && Array.isArray(providerList) ) {
            var provider = providerList?.find(obj => obj?.id === providerId)
            if  (provider) {
                setProviderName(`${provider.firstName} ${provider.lastName}`)
            }
        }
    }, [providerId])

    return (
        <>
        {loading &&
            <DimLoader loadMessage="Processing" />
        }    
    
        <div>
            <div className='row d-flex align-items-end justify-content-between'>
                <div className='col'>
                    <div className='row d-flex align-items-end'>
                        <div className='col-md-4 col-12'>
                            <div className='field required'>
                                <label>Search Provider</label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={providerList}
                                    name="id"
                                    isDisabled={props.providerId ? true : false}
                                    value={providerList && providerList.find(obj => obj?.id == providerId)}
                                    onChange={e => {
                                        setProviderId(e?.id)
                                    }}
                                    getOptionLabel={(option) => { if (option.name) { return option.name } else { return option.firstName + ' ' + option.lastName } }}
                                    getOptionValue={(option) => option.id}
                                />
                            </div>
                        </div>
                        <div className='col-md-3 col-12'>
                            <div className='field required'>
                                <label>Start Date</label>
                                <input type="date" value={Utilities.toDate(startDate)} onChange={e => { e.preventDefault(); setStartDate(e.target.value) }} />
                            </div>
                        </div>
                        <div className='col-md-5 col-12'>
                            <label>Practice Location</label>
                            <div className='input-group'>
                                
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={locationList && locationList}
                                    isLoading={!locationList}
                                    isDisabled={!locationList}
                                    loadingMessage="loading..."
                                    name="practiceLocationId"
                                    value={locationList && Array.isArray(locationList) ? locationList.find(obj => obj.practiceLocationId === locationId) : null}
                                    onChange={e => {
                                        if (e?.practiceLocationId) {
                                            setLocationId(e.practiceLocationId)
                                        }
                                        else {
                                            setLocationId()
                                        }
                                    }}
                                    getOptionLabel={(option) => option.practiceLocation}
                                    getOptionValue={(option) => option.practiceLocationId}
                                    >
                                </Select>

                                <button className="btn btn-primary" onClick={e => { e.preventDefault(); fetchReport() }} title="Pull Report"><i className="icon arrow circle right" /></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-auto col-12'>
                    {Array.isArray(tickets) && tickets?.length > 0 ?
                        <button className='btn btn-primary mobile-print-button-wt' style={{height:'38px'}} onClick={e => { e.preventDefault(); Utilities.printWindow("work-ticket", "Employee Work Ticket", { landscape: true, style: '.one-third{width:3.45in; height:7.50in}' }) }}><i className='icon print' /></button>
                        : null}
                </div>
            </div>
            <div id="work-ticket" className='mt-4 landscape'>
                <div className='row d-flex g-3 justify-content-center landscape'>
                    {Array.isArray(tickets) && tickets.map((ticket, idx) => {
                        let row = false
                        if ((idx + 1) % 3 == 0) {
                            row = true
                        }
                        return (
                            <>
                                <div className='col col-12 one-third'>
                                    <div className='card pt-3 h-100 w-100'>
                                        <div>
                                            <h5 className='text-center'><strong>Work Ticket</strong>
                                                <br />{ticket.doctorName}<br />
                                                {moment(startDate).format("MM/DD/YYYY")}</h5>
                                        </div>
                                        <table className='table table-borderless mt-3'>
                                            <tbody>
                                                <tr><th colSpan={4}>Current Appointment</th></tr>
                                                <tr><td><strong>Client:</strong></td><td colSpan={3}>{ticket.patientName}</td></tr>
                                                <tr><td><strong>Service:</strong></td><td colSpan={3}>{ticket.service}</td></tr>
                                                <tr><td><strong>Appt Type:</strong></td><td colSpan={3}>{ticket.appointmentType}</td></tr>
                                                <tr><td><strong>Resource:</strong></td><td colSpan={3}>{ticket.room}</td></tr>
                                                <tr><td><strong>Time:</strong></td><td colSpan={3}>{moment(ticket.appoinmentStartDate).format("h:mm A")}</td></tr>
                                            </tbody>
                                            <tbody>
                                                <tr><th colSpan={4}>Sales History</th></tr>
                                                {ticket.salesHistory[0].salesDate !== null ? ticket.salesHistory.map(appt => {
                                                    if (appt.salesDate) {
                                                        return (
                                                            <tr>
                                                                <td><small>{moment(appt.salesDate).format("M/D/YY")}</small></td>
                                                                <td><small>{appt.itemName}</small></td>
                                                                <td><small>{appt.doctorName.split(' ')[1]}</small></td>
                                                                <td><small>{Utilities.toDollar(appt.itemTotalCost)}</small></td>
                                                            </tr>
                                                        )
                                                    }
                                                }) : <tr><td colSpan={4}>No Sales Data</td></tr>}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {row && <div className='pagebreak' />}
                            </>
                        )
                    })}

                </div>
                {providerList && !tickets || tickets?.length < 1 ? 
                    <NoResults>
                        {initialMessage 
                            ? `Please submit the report...` 
                            : loading 
                                ? 'Please be patient while data loads...'
                                : `There are no work tickets on ${moment.utc(startDate).format("ddd, MMMM Do")} for ${providerName}`

                        }
                    </NoResults> 
                : null}
            </div>
        </div>
        </>
    )
}

export default EmployeeWorkTicket