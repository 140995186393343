import React, { useEffect, useState, useCallback, useContext } from "react";
import { debounce } from "lodash";
import PaymentPlanCard from "../paymentPlan-list/paymentPlan-card/PaymentPlanCard";
import AsyncSelect from 'react-select/async'
import AddInvoice from "../../invoices/add-invoice/AddInvoice";
import ModalBox from "../../../../templates/components/ModalBox";
import RecurringPaymentsService from "../../../../../services/api/recurring-payments.service";
import Utilities from "../../../../../services/commonservice/utilities";
import CommonService from "../../../../../services/api/common.service";
import moment from "moment";
import AddPatient from '../../patient/add-patient/AddPatient'
import { store } from "../../../../../context/StateProvider";
import PaginationTemplate from "../../../../templates/components/PaginationTemplate";

const PaymentPlanList = (props) => {
  const [paymentPlanList, setPaymentPlanList] = useState([]);
  const [keyword, setKeyword] = useState();
  const [sortBy, setSortBy] = useState(false)
  const [addInvoice, setAddInvoice] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [patientId, setPatientId] = useState()
  const [showAdd, setShowAdd] = useState(false)
  const [pageCount, setPageCount] = useState()
  const [startRow, setStartRow] = useState()
  const globalStateAndDispatch = useContext(store);
  const contextState = globalStateAndDispatch.state;

  const patientLoad = (inputText, callBack) => {
    if (inputText?.length < 3) return;
    let reqObj = { SearchTerm: inputText, isActive: true, isRegistered: true, SortField: 'firstName', isAsc: true }
    CommonService.patientLookup(reqObj)
      .then(res => {
        if (res) {
          callBack(res.data)
        }
      }
      )
      .catch(err => console.log(err))
  }
  const rePatientLoad = useCallback(debounce(patientLoad, 500), [])
  const onPageChange = (data) => {
    setStartRow(CommonService.calculatePageStartRow(data?.selected, 10))
  }
  const paymentPlanLookup = () => {
    setIsLoader(true);
    let reqObj = {
      SearchTerm: "",
      SortField: "CreatedOn",
      Asc: sortBy,
      StartRow: startRow || 0,
      PageSize: 10,
    };
    if (props.type) {
      reqObj.RecurringTransactionType = props.type
    }
    if (props.patientId || patientId) {
      reqObj.PatientIds = props.patientId || patientId
    }
    RecurringPaymentsService.findRecurringPayments(reqObj)
      .then((res) => {
        if (res?.data?.data) {
          setPageCount(Math.ceil(res?.data?.totalRowCount / 10))
          setPaymentPlanList(res?.data?.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoader(false)
    });
  };

  useEffect(() => {
    paymentPlanLookup();
  }, [sortBy, patientId, keyword,startRow]);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-md-6 col-12 mb-3" id="ppSearchBar">
          <div className="required field">
            <label>Search by Patient</label>
            <div className="input-group col-6">
              <AsyncSelect
                classNamePrefix="react-select"
                className="react-select-container"
                name="patientId"
                loadOptions={rePatientLoad}
                placeholder="Search Patient"
                onChange={e => {
                  console.log(e)
                  if (e?.id) {
                    setPatientId(e.id)
                  }
                  else {
                    setPatientId()
                  }
                }}
                isClearable={true}
                getOptionLabel={(option) => {
                  return (
                    <div className="d-flex row">
                      <span className="col">{option.firstName} {option.lastName}</span>
                      <span className='col text-start'><i className='icon birthday cake' />{moment.utc(option.dob).format("M/D/YYYY").toString()}</span>
                      <span className='col text-end'>
                        {option.mobile && option.mobile != '' || option.patientPhone && option.patientPhone != '' ?
                          <>
                            <i className='icon phone' />
                            {option.mobile && option.mobile != '' ? Utilities.toPhoneNumber(option.mobile) : option.patientPhone && option.patientPhone != '' ? Utilities.toPhoneNumber(option.patientPhone) : null}
                          </> : null}
                      </span>
                    </div>
                  )
                }}
                getOptionValue={(option) => option.id}
                noOptionsMessage={(e) => {
                  if (!contextState.permissions.patientModifyPatients) {
                    return null;
                  }
                  else {
                    return <button className='btn btn-primary form-control' onClick={e => { e.preventDefault(); setShowAdd(true) }}>Add Patient</button>
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="field">
            <label className="me-3">Sort by: </label>
            <select
              className="form-select"
              onChange={(e) => {
                setSortBy(e.target.value);
              }}
            >
              <option value={false} selected>
                Date: Desc
              </option>
              <option value={true} selected>
                Date: Asc
              </option>
            </select>
          </div>
        </div>
      </div>
      <div className='row-fluid' title="Payment Plans">
        {/* {isLoader && <div className="ui active dimmer">
          <div className="ui indeterminate text loader">Loading</div>
        </div>} */}
        {isLoader ? <div className="ui warning message mt-3 segment p-3 shadow-sm">
        <span>Please be patient while data loads...</span>
      </div>:
        <>
          {Array.isArray(paymentPlanList) && paymentPlanList.length > 0
            ? paymentPlanList
              .filter((transaction) => {
                if (keyword === "" || keyword === null || !keyword) {
                  return transaction
                } else if (transaction.id == keyword.id) {
                  return transaction
                }
              }).map((paymentPlan, i) => {
                return (
                  <PaymentPlanCard
                    createdOn={paymentPlan.createdOn}
                    id={paymentPlan.id}
                    paymentPlan={paymentPlan}
                    invoiceId={paymentPlan.invoiceId}
                    key={i}
                    index={i}
                    type={props.type}
                    refresh={paymentPlanLookup}
                  />
                );
              })
            : <>{!contextState.permissions.paymentsModifySubscriptionsAndPlans ? null : <>
              <span className="row-fluid">
                <span>There are no payments plans currently setup.</span>{" "}
                <a
                  href="#"
                  className="p-0 pb-2 text-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setAddInvoice(true);
                  }}
                >
                  Click Here to Start an Invoice
                </a>
              </span>
            </>
            }
            </>}
        </>}
        {Array.isArray(paymentPlanList) && paymentPlanList.length > 0 &&
          <PaginationTemplate onPageChange={onPageChange} pageCount={pageCount} />
        }
      </div>
      <ModalBox open={addInvoice} onClose={() => setAddInvoice(false)} title="Create an Invoice for your Payment Plan">
        <AddInvoice onClose={() => { setAddInvoice(false) }} />
      </ModalBox>
      <ModalBox open={showAdd} onClose={() => { setShowAdd(false) }}>
        <AddPatient onClose={() => { setShowAdd(false) }} />
      </ModalBox>
    </div >
  );
};

export default PaymentPlanList;
