import React, { useState, useEffect } from 'react'
import moment from 'moment'
import TransactionStatusEnum from '../../../../../common/enum/transaction-status.enum'
import { ChannelTypeEnum as channelType } from '../../../../../common/enum/channel-type.enum'
import {TransactionOperationTypeEnum as operationTypeFilter } from '../../../../../common/enum/Conversions/transaction-operation.enum'
import TransactionService from '../../../../../services/api/transaction.service'
import Utilities from '../../../../../services/commonservice/utilities'
const TransactionStatus = (props) => {
    const [tc, setTc] = useState()
    const [tcStatus, setTcStatus] = useState()
    const [isLoader, setIsLoader] = useState(false)

    const pullTransactionInfo = () =>{
        TransactionService.viewTransaction(props.paymentId)
        .then(res=>{
            setTc(res.data)
        })
    }
    const pullTransactionStatus = (id) => {
        setIsLoader(true)
        console.log(tc)
        TransactionService.getTransactionStatus(id)
            .then(res => {
                setTcStatus(res.data)
                setIsLoader(false)
            })
    }

    useEffect(() => {
        console.log(tc)
        if(props.payment && !tc){
            setTc({...props.payment})
        }
        else if(props.paymentId && !tc){
            pullTransactionInfo()
        }
        else if(tc.id){
            pullTransactionStatus(tc.id)
        }
    }, [tc])
    return (
        <div className='mb-3'>
            {tc?
            <table className='table table-borderless' style={{ border: '1px solid lightgrey' }}>
                <colgroup>
                    <col span="1" />
                    <col span="1"/>
                    <col span="1" />
                    <col span="1" style={{ width: '250px' }} />
                </colgroup>
                {tc?.transactionStatus == 5 || tc?.transactionStatus == 14 ?
                    <thead>
                        <tr className='bg-danger'>
                            <th className='bg-danger'><strong>Transaction</strong> {TransactionStatusEnum[tc.transactionStatus]}</th>
                            <th><strong>Failure Code</strong> {tc && tc?.reasonCode}</th>
                            <th colSpan={2}><strong>Transaction Date</strong> {tc.transactionDate && moment(tc.transactionDate).format("MM-DD-YYYY h:mm A")}</th>
                        </tr>
                    </thead>
                    : <thead>
                        <tr>
                            <th colSpan={3}><strong>Transaction Date</strong> {tc.transactionDate && moment(tc.transactionDate).format("MM-DD-YYYY h:mm A")}</th>
                            <th> <strong>Transaction</strong> {TransactionStatusEnum[tc.transactionStatus]}</th>
                        </tr>
                    </thead>
                }
                {props.payment && <tbody>
                    <tr>
                        <td colSpan={2}><strong>Transaction Id</strong> {tc.id}</td>
                        <td colSpan={1}><strong>Operation Type</strong> {operationTypeFilter?.find(obj => obj.value === tc.operationType)?.title}</td>
                        <td colSpan={1}><strong>Transaction Amount</strong> {Utilities.toDollar(tc.tenderInfo?.totalAmount)}</td>
                    </tr>
                </tbody>}
                {tc.tenderInfo?.channelType == 3 || tc.tenderInfo?.channelType == 4 || tc.tenderInfo?.channelType == 7 ? <tbody>
                    <tr>   {tc.tenderInfo?.cardType && <td><strong>Card Type</strong> {tc.tenderInfo?.cardType}</td>}
                        {tc.tenderInfo?.nameOnCheckOrCard && <td><strong>Name on Card</strong> {tc.tenderInfo?.nameOnCheckOrCard}</td>}
                        {tc.tenderInfo?.maskCardNumber && <td><strong>Card Number</strong> {tc.tenderInfo?.maskCardNumber}</td>}
                        <td><strong>Payment Method</strong> {channelType.find(obj => obj.value === tc.tenderInfo?.channelType).title}</td></tr>
                </tbody> : tc.tenderInfo?.channelType == 10 || tc.tenderInfo?.channelType == 2 ? <tbody>
                    <tr>
                        <td span={1}><strong>Payment Method</strong> {channelType.find(obj => obj.value === tc.tenderInfo?.channelType).title}</td>
                        {tc.tenderInfo?.bankName ? <td colSpan={2}><strong>Bank</strong> {tc.tenderInfo?.bankName}</td>:<td colSpan={2}></td>}
                        <td span={1}><strong>Check Number</strong> {tc.tenderInfo?.checkNumber}</td>
                    </tr>
                </tbody> :
                    <tbody>
                        <tr>   <td colSpan={4}><strong>Payment Method</strong> {channelType.find(obj => obj.value === tc.tenderInfo?.channelType)?.title}</td></tr>
                    </tbody>}
                {tc.transactionStatus == 5 || tc.transactionStatus == 14 ?
                    <thead>
                        <tr>
                            <th colSpan={4}><strong>Failure Information</strong> {TransactionService.getExceptionMessage(tc)}</th>
                        </tr>
                    </thead>:null}
            </table>
            :
            null}
        </div>
    )
}

export default TransactionStatus