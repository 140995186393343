import React, { useState, useEffect } from 'react'
import PatientService from '../../../../../../../services/api/patient.service'
import ModalBox from '../../../../../../templates/components/ModalBox'
import PatientAccountAdd from '../../../../patient-account/patient-account-add/PatientAccountAdd'
import ScheduledTransactionService from '../../../../../../../services/api/scheduled-transaction.service'
import DatePicker from "react-multi-date-picker"
import APIResponse from '../../../../../../templates/components/APIResponse'
import DimLoader from '../../../../../../templates/components/DimLoader'
import HPTSelect from '../../../../../../templates/components/HPTSelect'

const PaymentAdjust = (props) => {
    const [accounts, setAccounts] = useState()
    const [selectedAccount, setSelectedAccount] = useState()
    const [transactionDate, setTransactionDate] = useState()
    const [showAdd, setShowAdd] = useState(false)
    const [minStartDate, setMinStartDate] = useState()
    const [maxStartDateCount, setMaxStartDateCount] = useState()
    const [isLoader, setIsLoader] = useState(false)
    const [isLoader_Account, setIsLoader_Account] = useState(false)
    const accountTypes = {
        // 0:'all',
        1: 'Credit',
        2: 'ACH',
        3: 'credit',
        4: 'debit',
        // 5:'cash',
        // 6:'check',
    }
    const frequency ={
         0:'Daily',
         1:'Weekly',
         2:'BiWeekly',
         3:'Monthly',
        4:'Quarterly',
         5:'HalfYearly',
         6:'Annually',
      }
    const [apiResponse, setApiResponse] = useState()
    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }
    const adjustPayment = () => {
        setIsLoader(true)
        let reqObj ={}
        if (props.operationType == 3) { //failed transaction
            reqObj = {
                operationType: 3,
                newExecutionDate: new Date(transactionDate).toLocaleDateString("sv-SE") + "T" + props.payment.executionDate.split("T")[1],
            }
            if (selectedAccount && props.payment?.paymentAccountId !== selectedAccount.id) {
                reqObj.paymentAccountId = selectedAccount.id
            }
        }
        else { //operationType==2 
            reqObj = {
                operationType: 2,
                paymentAccountId: selectedAccount.id,
            }
            let newTransDate = new Date(transactionDate).toLocaleDateString("sv-SE")
            if (new Date(props.payment.executionDate).toLocaleDateString("sv-SE") !== newTransDate) {
                reqObj.newExecutionDate = newTransDate + "T" + props.payment.executionDate.split("T")[1] //T01:01:56.000Z"
            }
        }
        ScheduledTransactionService.updateScheduleTransaction(reqObj, props.payment.recurringPaymentId, props.payment.id)
        .then(res=>{
            setApiResponse(res);
        })
        .catch(err=>{
            setApiResponse(err);
        }).finally(() => {
            setIsLoader(false)
        });
    }
    const findAccounts = () => {
        setIsLoader_Account(true)
        PatientService.fetchPatientAccount(props.patientId)
            .then(res => {
                setAccounts(res)
                if (props.payment?.paymentAccountId) {
                    setSelectedAccount(res.find(obj => obj.id === props.payment.paymentAccountId))
                }
            }).finally(() => {
                setIsLoader_Account(false)
            });
           
    }

    useEffect(() => {
        findAccounts()
        if (props.payment.executionDate) {
            setCalendarDates()
            if (props.operationType == 3) {
                const today = new Date() // get today's date
                //HPT-14269 Please allow today's date since the scheduler runs more than once a day. - for failed transaction
                //const tomorrow = new Date(today)
                //tomorrow.setDate(today.getDate() + 1) // Add 1 to today's date and set it to tomorrow
                setMinStartDate(today)
                setTransactionDate(today)
            } else {
                setTransactionDate(props.payment.executionDate)//"2023-12-02T23:30:00.000Z"
            }

        }
    }, [props.patientId])

    const setCalendarDates = () => {
        const currentTransactionDate = new Date(props.payment.executionDate);
        if(props.operationType===3){
            setMaxStartDateCount(30)
        }
        else if (props.nextTransaction !== undefined) {
          const nextTransactionDate = new Date(props.nextTransaction.executionDate);
          
          let days = Math.floor(
            (Date.UTC(nextTransactionDate.getFullYear(), nextTransactionDate.getMonth(), nextTransactionDate.getDate()) -
              Date.UTC(currentTransactionDate.getFullYear(), currentTransactionDate.getMonth(), currentTransactionDate.getDate())) /
              (1000 * 60 * 60 * 24)
          );
          //setMinStartDate(0)
          setMaxStartDateCount(days-1)
        } else if (props.paymentPlan !== undefined) {
          if (props.paymentPlan.frequency !== undefined) {
            const freq = frequency[props.paymentPlan.frequency];
            let numberOfDays = 0;
            switch (freq) {
              case 'Monthly':
                numberOfDays = 30;
                break;
              case 'Quarterly':
                numberOfDays = 120;
                break;
              case 'Annually':
                numberOfDays = 365;
                break;
              case 'HalfYearly':
                numberOfDays = 180;
                break;
              case 'BiWeekly':
                numberOfDays = 14;
                break;
              case 'Weekly':
                numberOfDays = 7;
                break;
            }
    
            //setMinStartDate(0);
            setMaxStartDateCount(numberOfDays-1);
          } else {
            //setMinStartDate(0);
            setMaxStartDateCount(30);
          }
    
        } else {
            setMaxStartDateCount(30);
          //setMinStartDate(0)
        }
      }
    return (
        <>
        {isLoader ? <DimLoader loadMessage="Processing..." /> : null}
        <div className='p-3 row d-flex'>
            <div className='field mb-3 col-8'>
                <label>Select Account</label>
                <div className='input-group'>
                    <HPTSelect
                        classNamePrefix="react-select"
                        className="react-select-container"
                        options={accounts}
                        name="patientId"
                        value={selectedAccount}
                        onChange={e => {
                            setSelectedAccount(e)
                        }}
                        placeholder="Select Account"
                        getOptionLabel={(option) => {
                            let label = ""
                            if (option.accountType === 1) {
                                label = accountTypes[option.accountType] + ' | ' + option.cardType + ' ' + option.maskedCardNumber + ' | ' + option.accountHolderName
                            }
                            else if (option.accountType === 2) {
                                label = accountTypes[option.accountType] + ' | ' + option.bankName + ' ' + option.maskedAccountNo + ' | ' + option.accountHolderName
                            }
                            return label
                        }
                        }
                        getOptionValue={(option) => option.id}
                        noOptionsMessage={() => { return <button className='btn btn-primary form-control' onClick={e => { e.preventDefault(); setShowAdd(true) }}>Add Account</button> }}
                    />
                    <button className='btn btn-primary col-auto' title="Add Account" onClick={e => { e.preventDefault(); setShowAdd(true) }}><i className='icon plus' /></button>
                </div>
            </div>
            {(props?.paymentPlan?.frequency && props.paymentPlan?.frequency.toString() !== "0") || props.operationType===3 ? <div className='field required col-4'>
                <label>Transaction Date</label>
                <DatePicker
                    value={transactionDate}
                    format={"MM-DD-YYYY"}
                    onChange={setTransactionDate}
                    minDate={props.operationType===3? minStartDate : new Date(props.payment.executionDate)}
                    maxDate={props.operationType===3? new Date().setDate( new Date().getDate() + maxStartDateCount) : new Date(props.payment.executionDate).setDate( new Date(props.payment.executionDate).getDate() + maxStartDateCount)}
                />
            </div> : null}
            <div className='col-12'>
                <button className='btn btn-primary' disabled={isLoader_Account} onClick={(e)=>{e.preventDefault(); adjustPayment()}}>Update</button>
                <button className='btn btn-secondary ms-3' onClick={e => { e.preventDefault(); props.onClose() }}>Close</button>
            </div>
            <ModalBox open={showAdd} onClose={() => { setShowAdd(false) }} title="Add Patient Account">
                <PatientAccountAdd disableLocationEdit={true} patientId={props.patientId} onClose={() => { setShowAdd(false) }} />
            </ModalBox>
        </div>
        <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </>
    )
}

export default PaymentAdjust