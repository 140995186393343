import AppSetting from '../../common/constants/appsetting.constant';
import axios from 'axios';
import toast from 'react-hot-toast';

const ForgotPasswordService = {

  forgotPassword(userName, reqObj) {
    let url = AppSetting.baseUrl + 'users/' + userName + '/forgotpasswords';
    if (window.location.host.includes("logindev.") || window.location.host.includes("login.uat") || window.location.host.includes("login.hellopatients") || window.location.host.includes("login.revique") || window.location?.href?.includes('patient')) {
      url = AppSetting.baseUrl + 'patient/' + userName + '/forgotpasswords';
    }
    return axios.post(url, reqObj)
      .then(a => { return a })
      .catch(err => { return err });
  }
}

export default ForgotPasswordService
