
import React from 'react'
import toast from 'react-hot-toast'

const URLForm = (props) => {

  const copyToClipBoard = async copyMsg => {
    try {
      await navigator.clipboard.writeText(copyMsg);
      toast.success('Copied!');
    } catch (err) {
      toast.error('Failed to copy!');
    }
  };
  const Print = () => {
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
  return (
    < div className='ui segment'>
      <h3>{props?.form?.formTitle}
      </h3>
      <div className="item">
        <strong>Description: </strong>{props?.form?.formDescription ? props?.form?.formDescription : '--'}
      </div>

      <div className="item">
        <i className="globe icon"></i>
        <strong>URL: </strong>
        <a target="_blank" href={props?.form?.publicUrl}>{props?.form?.publicUrl}</a>&nbsp;
        <span onClick={e => { e.preventDefault(); copyToClipBoard(props?.form?.publicUrl) }}>
          <i className="copy icon"></i>
          <a href="#">Copy to clipboard</a>
        </span>
      </div>
      {props.form.qrCode && <div className="item">
        <div className="content" id="printablediv"><img src={props.form.qrCode}
          style={{ width: '200px', height: '200px', display: 'block', marginLeft: 'auto', marginRight: "auto" }} />
        </div>
        <div className="content"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <button className="btn btn-primary circular ui floated icon button" title="Print QR code" onClick={() => Print()}>
            <i className="icon print"></i>
          </button>
          <a href={props.form.qrCode} target="_blank" download={props.form.formTitle}><button
            className="btn btn-primary circular ui floated icon button" title="Download QR code" >
            <i className="icon download"></i>
          </button></a>
        </div>
      </div>}
    </div>
  )
}

export default URLForm