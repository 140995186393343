import React, { useEffect, useState, useContext } from 'react'
import AppointmentService from '../../../../../../services/api/appointment.service'
import ModalBox from '../../../../../templates/components/ModalBox'
import PracticeLocationEdit from '../../../practice-location/location-edit/PracticeLocationEdit'
import PracticeLocationAdd from '../../../practice-location/location-add/PracticeLocationAdd'
import toast from 'react-hot-toast'
import Table2 from '../../../../../templates/components/Table2'
// import PracticeLocationServiceTable from '../practice-location-services-table/PracticeLocationServicesTable'
import PracticeLocationServiceTable from '../../location-settings/practice-location-services-table/PracticeLocationServicesTable'



import PracticeLocationSelector from '../../../../../templates/components/PracticeLocationSelector'
import { store } from '../../../../../../context/StateProvider'
import { Accordion, Icon } from 'semantic-ui-react'
// import PracticeLocationProvider from '../practice-location-provider/PracticeLocationProvider'
import PracticeLocationProvider from '../../location-settings/practice-location-provider/PracticeLocationProvider'

const LocationProviders = (props) => {

    const [isLoader, setIsLoader] = useState(false)
    const [selectedLocation, setSelectedLocation] = useState()
    const [activeIndex, setActiveIndex] = useState(0)
    const [deleteModal, setDeleteModal] = useState(false)
    const state = useContext(store).state
    const [locationId, setLocationId] = useState(state.practiceLocationId);

    const onLocationChanged = (newLocationId) => {
        setLocationId(newLocationId)
    }

    const deleteItem = (id) => {
        return AppointmentService.deletePracticeLocation(id)
            .then(res => {
                toast.success("Practice Location deleted");
                getPracticeLocations()
            })
            .catch(err => {
                toast.error("Error deleting Practice Location")
            })
    }

    return (
        <div>
            <div className='row d-flex mb-3'>
                <div className='col-md-auto col-12'>
                    <div className='field'>
                        <label>Select a Location</label>
                        <div className='input-group' style={{minWidth:'300px'}}>
                            <PracticeLocationSelector dontResetState={true} onSelected={onLocationChanged}/>
                        </div>
                    </div>
                </div>
            </div>
            {locationId && <Accordion fluid styled>
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={e => { e.preventDefault(); if (activeIndex === 1) { setActiveIndex() } else { setActiveIndex(1) } }}
                > <Icon name='dropdown' />Providers</Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                    <div>
                        <PracticeLocationProvider id={locationId} />
                    </div>
                </Accordion.Content>
            </Accordion>}
            <ModalBox open={deleteModal} onClose={() => { setDeleteModal(false) }} title="Delete Location">
                {selectedLocation?.practiceLocationId && <div className='row d-flex align-items-center justify-content-between'>
                    <div className='col'>
                        Are you sure you want to delete {selectedLocation.practiceLocation}?
                    </div>
                    <div className='col-auto'>
                        <button className='btn btn-secondary' onClick={e => { e.preventDefault(); setSelectedLocation(); return setDeleteModal(false) }}>No</button>
                        <button className='btn btn-primary ms-3' onClick={e => { e.preventDefault(); deleteItem(selectedLocation?.practiceLocationId); setDeleteModal(false) }}>Yes</button>
                    </div>
                </div>}
            </ModalBox>
        </div>
    )
}

export default LocationProviders