import React, { useEffect, useState, useContext } from 'react'
import CommonService from '../../../../../services/api/common.service'
import DoctorService from '../../../../../services/api/doctor.service'
import ProductService from '../../../../../services/api/product.service'
import Utilities from '../../../../../services/commonservice/utilities'
import ModalBox from '../../../../templates/components/ModalBox'
import AddPatient from '../../patient/add-patient/AddPatient'
import DimLoader from '../../../../templates/components/DimLoader'
import { store } from '../../../../../context/StateProvider'
import PracticeServiceTypeService from '../../../../../services/api/practice-service-type.service'
import PatientService from '../../../../../services/api/patient.service'
import { useMediaQuery } from 'react-responsive'
import PatientFinancialHeader from '../../transactions/terminals/virtual-terminal/virtual-terminal-form/PatientFinancialHeader'

import { InvoiceUtility, InvoiceOperation } from '../../../../../services/commonservice/invoice.utility'
import HPTSelect from '../../../../templates/components/HPTSelect'

import { Accordion, Icon } from 'semantic-ui-react'
import moment from 'moment'

import PropTypes from 'prop-types'

// import { FeeApplicableTo } from '../../../../../common/enum/fee-applicable-to.enum'
const InvoiceForm = (props) => {
    const [patientList, setPatientList] = useState()
    const [inputData, setInputData] = useState(props.initialData)
    const [providerList, setProviderList] = useState()

    const [productList, setProductList] = useState()
    const [selectedProductList, setSelectedProductList] = useState([])

    const [membershipItemsList, setMembershipItemsList] = useState([]) // combined lookup and selected
    const [membershipItemsLookup, setMembershipItemsLookup] = useState([]) // original list of memberhip items
    const [selectedMembershipItems, setSelectedMembershipItems] = useState([]) // just mmebership items with quantity
    

    const [membershipsList, setMembershipsList] = useState([])
    const [selectedMemberships, setSelectedMemberships] = useState([])

    const [serviceList, setServiceList] = useState()
    const [selectedServiceList, setSelectedServiceList] = useState([])

    const [feesList, setFeesList] = useState()
    const [selectedFeesList, setSelectedFeesList] = useState([])

    const [subTotal, setSubTotal] = useState(0)
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [totalTax, setTotalTax] = useState(0)
    const [total, setTotal] = useState(0)
    const [totalProducts, setTotalProducts] = useState(0)
    const [totalServices, setTotalServices] = useState(0)
    const [totalFees, setTotalFees] = useState(0)
    const [totalMemberships, setTotalMemberships] = useState(0)
    const [totalMembershipItems, setTotalMembershipItems] = useState(0)
    const [showAdd, setShowAdd] = useState(false)
    const [selectedPatient, setSelectedPatient] = useState()
    // const [isLoader_Patients, setIsLoader_Patients] = useState(false)
    const [invoiceType, setInvoiceType] = useState(0)
    const [selectedPrepaid, setSelectedPrepaid] = useState([])
    const [prepaidList, setPrepaidList] = useState()
    const [invoiceTotalError, setInvoiceTotalError] = useState(false)
    // Adding location context
    const stateAndDispatch = useContext(store)
    const state = stateAndDispatch.state
    const isMobile = useMediaQuery({ query: `(max-width: 576px)` });
    const [activeIndex, setActiveIndex] = useState(0)

    const [expandProducts, setExpandProducts] = useState(true)
    const [expandServices, setExpandServices] = useState(true)
    const [expandFees, setExpandFees] = useState(false)
    const [expandMemberships, setExpandMemberships] = useState(false)
    const [expandMembershipItems, setExpandMembershipItems] = useState(false)
    const [redeemServicesChecked,setRedeemServicesChecked] = useState(false)
    const [confirmRedeemItem, setConfirmRedeemItem] = useState(false)
    const [serviceToRedeem, setServiceToRedeem] = useState()

    const setIndex = (i) => {
        if (i === activeIndex) {
            setActiveIndex()
        }
        else {
            setActiveIndex(i)
        }
    }

    const getFirstProvider = () => {
        return (providerList && Array.isArray(providerList) && providerList.length>0 ? providerList[0].id : null);
    }
        
    const patientLookup = () => {
        // setIsLoader_Patients(true)
        if (props.initialData?.patientId) {
            PatientService.getPatientById(props.initialData.patientId)
                .then(res => {
                    setPatientList([res.data])
                    setSelectedPatient(res.data)
                    // setIsLoader_Patients(false)
                })
                .catch(err => {
                    // setIsLoader_Patients(false)
                })
        }
        else if (inputData.practiceLocationId) {
            let reqObj = { SearchTerm: '', isActive: true, isRegistered: true, SortField: 'firstName', PracticeLocationId: inputData.practiceLocationId }
            CommonService.patientLookup(reqObj)
                .then(res => {
                    if (res) {
                        setPatientList(res.data)
                        if (props.initialData.patientId) {
                            setSelectedPatient(res.data.find(obj => obj.id === props.initialData.patientId))
                        }
                    }
                    // setIsLoader_Patients(false)
                }
                )
                .catch(err => {
                    // setIsLoader_Patients(true)
                    }
                )

        }
    }


    const providerLookup = () => {
        // setIsLoader(true)
        if (!providerList) {
            const reqObj = { isRegistered: true, PracticeLocationId: state.practiceLocationId, isActive: true };
            DoctorService.doctorLookup(reqObj)
                .then(
                    (response) => {
                        setProviderList(response)
                        if (props.initialData.doctorId) {
                            inputChange({ target: { name: 'doctorId', value: response.find(obj => obj.id === props.initialData.doctorId).id } })
                        }
                        // setIsLoader(false)
                    })
                .catch((error) => {
                    // setIsLoader(false)
                    console.log(error);
                })
        }
    }

    const productLookup = () => {
        const reqObj = { 
            practiceLocationId: inputData?.practiceLocationId,
            includePracticeItems: 'yes',
            isActive: true 
        };
        ProductService.productLookup(reqObj)
            .then(res => {
                if (res) {
                    setProductList(extractItems(res, 1))
                    setFeesList(extractItems(res, 4))
                    setPrepaidList(extractItems(res, 3))
                    setMembershipsList(extractItems(res, 5))
                }
            }
        )
    }

    const extractItems = (results, itemType) => {
        let items = [];
        if (Array.isArray(results)) {
            items = results.filter(result => { return result?.itemType == itemType && (result?.practiceLocationId=="" || result?.practiceLocationId == inputData.practiceLocationId) });
        }
        if (!Array.isArray(items)) {
            items = [];
        }
        return items;
    }

    const serviceLookup = () => {
        const reqObj = { 
            PracticeLocationId: inputData?.practiceLocationId,
            isActive: 1
        };
        ProductService.serviceLookup(reqObj)
            .then(res => {
                //console.log("serviceLookup :::: "+JSON.stringify(res))
                setServiceList(res.data);
            }
            ).catch(error => {
                console.log(error)
            })
    }
    
    const membershipItemLookup = () => {
        const reqObj = { 
            practiceLocationId: inputData?.practiceLocationId,
            patientId: selectedPatient?.id,
            balancesAsOf: moment().toISOString() 
        };
        ProductService.membershipItemLookup(reqObj)
            .then(res => {
                if (Array.isArray(res.data)) {
                    storeMembershipLookup(res.data);
                }
                else {
                    setMembershipItemsLookup([])
                }
            }
            ).catch(error => {
                console.log(error)
            })
    }

    const storeSelectedMembershipItems = (_selectedMembershipItems) => {
        // Called from parseInitialData only

        // save selected
        setSelectedMembershipItems(_selectedMembershipItems);

        // add these to list and populate balance
        let _membershipItemsList = combineSelectedAndLookupMembershipItems(membershipItemsLookup, _selectedMembershipItems);

        setMembershipItemsList(_membershipItemsList);

    }
        
    const storeMembershipLookup = (_membershipItemsLookup) => {
        // Called from membershipItemLookup only 

        let _lookups = []
        let _display = []

        _membershipItemsLookup.forEach(membershipObj => {

            _display.push({
                membershipRow: true,
                membershipName: membershipObj.membershipName,
                membershipType: membershipObj.membershipType,
                membershipInvoiceItemsId: membershipObj.membershipInvoiceItemsId,
                membershipParentItemId: membershipObj.membershipParentItemId,
                balance: membershipObj.items[0].balance, 
                available: membershipObj.items[0].balance,
                itemId: 0
            })

            membershipObj.items.forEach(membershipItemObj => {

                membershipItemObj.membershipRow = false
                membershipItemObj.membershipName = membershipObj.membershipName
                membershipItemObj.membershipType = membershipObj.membershipType
                membershipItemObj.membershipInvoiceItemsId = membershipObj.membershipInvoiceItemsId
                membershipItemObj.membershipParentItemId = membershipObj.membershipParentItemId

                membershipItemObj.serviceId = isFalsy(membershipItemObj.serviceId,null)
                membershipItemObj.itemId = membershipItemObj.id
                delete membershipItemObj.id // only existing invoice items should have an id
                membershipItemObj.quantity = 0
                membershipItemObj.cashValueApplied = false
                membershipItemObj.discount = membershipItemObj.discount || 0
                membershipItemObj.discountType = parseInt(membershipItemObj.discountType) || 2
                membershipItemObj.doctorId = (providerList && Array.isArray(providerList) && providerList.length>0 ? providerList[0].id : null)
                membershipItemObj.cost = parseFloat(membershipItemObj.cost) || 0
                membershipItemObj.overhead = parseFloat(membershipItemObj.overhead) || 0
                membershipItemObj.unitPrice = +membershipItemObj.unitPrice
                membershipItemObj.taxPercent = parseFloat(membershipItemObj.taxPercent) || 0

                membershipItemObj.available = parseFloat(membershipItemObj.balance) || 0

                _lookups.push(membershipItemObj)
                _display.push(membershipItemObj)

            })
        });

        // save lookup
        setMembershipItemsLookup(_lookups);

        // add these to list and populate balance
        let _membershipItemsList = combineSelectedAndLookupMembershipItems(_display, selectedMembershipItems);

        setMembershipItemsList(_membershipItemsList);

    }

    const combineSelectedAndLookupMembershipItems = (_membershipItemsLookup, _selectedMembershipItems) => {
        // find selected that are not in lookup and add them under their membership
        let _membershipItemsList = _membershipItemsLookup.map(membershipItem => 
            {
                if (membershipItem.membershipRow == false) {
                    let selectedItem = _selectedMembershipItems.find(a => 
                        a.itemId === membershipItem.itemId && 
                        a.membershipParentItemId === membershipItem.membershipParentItemId
                    )
                    if (selectedItem) {
                        membershipItem.balance = (selectedItem?.quantity || 0) + (membershipItem?.balance || 0);
                        membershipItem.quantity = selectedItem?.quantity
                        membershipItem.cashValueApplied = selectedItem?.cashValueApplied
                        membershipItem.unitPrice = selectedItem?.unitPrice
                        if (selectedItem.id) membershipItem.id = selectedItem.id // only existing invoice items should have an id
                    }
                }
                return membershipItem;
            }
        );


        _membershipItemsList
            .filter(m => m.membershipRow == true && m.membershipType == 3)            
            .forEach(membership => {
                // get all quantites if membership's items
                let otherOptionQuantities = 0;
                _membershipItemsList
                    .filter(m => {return m.membershipParentItemId === membership.membershipParentItemId} ) 
                    .forEach(m => {
                        otherOptionQuantities = otherOptionQuantities + (m?.quantity || 0)
                    })
                membership.balance = membership.balance + otherOptionQuantities;
                _membershipItemsList
                    .filter(m => {return m.membershipParentItemId === membership.membershipParentItemId} ) 
                    .forEach(item => {
                        item.balance = membership.balance;
                        item.available = membership.available;
                    })
            });

        // TODO what about selected items that were not returned in membership item lookup

        return _membershipItemsList;
    }


    const isFalsy = (toCheck, substitue) => {
        return toCheck || substitue;
    }


    const parseInitialData = () => {
        //console.log(props.initialData.items)
        setSelectedProductList(props.initialData.items.filter(obj => { return obj.itemType == 1 && !obj.membershipItemId }).map(prod => {
            let newProd = prod
            let discountPercent = isFalsy(prod.discountPercent, 0);
            let discountAmount = isFalsy(prod.discountAmount, 0);
            // newProd.serviceId = isFalsy(prod.serviceId, null)
            newProd.discount = isFalsy(prod.discountType, 2) == 2 ? discountPercent : discountAmount 
            newProd.discountType = isFalsy(prod.discountType, 2)
            newProd.doctorId = isFalsy(prod.doctorId, null)
            return newProd
        }))
        setSelectedServiceList(props.initialData.items.filter(obj => obj.itemType == 2 && !obj.membershipItemId).map(serv => {
            let newServ = serv
            let discountPercent = isFalsy(serv.discountPercent, 0);
            let discountAmount = isFalsy(serv.discountAmount, 0);
            // newServ.serviceId = isFalsy(serv.serviceId, null)
            newServ.discount = isFalsy(serv.discountType, 2) == 2 ? discountPercent : discountAmount 
            newServ.discountType = isFalsy(serv.discountType, 2)
            newServ.doctorId = isFalsy(serv.doctorId, null)
            return newServ
        }))
        setSelectedMemberships(props.initialData.items.filter(obj => obj.itemType == 5).map(membership => {
            let newMembership = membership
            let discountPercent = isFalsy(membership.discountPercent, 0);
            let discountAmount = isFalsy(membership.discountAmount, 0);
            // newMembership.serviceId = isFalsy(membership.serviceId, null)
            newMembership.discount = isFalsy(membership.discountType, 2) == 2 ? discountPercent : discountAmount; 
            newMembership.discountType = isFalsy(membership.discountType, 2)
            newMembership.doctorId = isFalsy(membership.doctorId, null)
            newMembership.membershipStartsOn = (membership.membershipStartsOn ? membership.membershipStartsOn.split('T')[0] : null )
            return newMembership
        }))

        storeSelectedMembershipItems(props.initialData.items.filter(obj => [1,2].includes(obj.itemType) && obj.membershipItemId).map(membership => {
            let newMembership = membership
            let discountPercent = isFalsy(membership.discountPercent, 0);
            let discountAmount = isFalsy(membership.discountAmount, 0);
            // newMembership.serviceId = isFalsy(membership.serviceId, null)
            newMembership.discount = isFalsy(membership.discountType, 2) == 2 ? discountPercent : discountAmount; 
            newMembership.discountType = isFalsy(membership.discountType, 2)
            newMembership.doctorId = isFalsy(membership.doctorId, null)
            return newMembership
        }))

    }
    useEffect(() => {
        if (inputData.practiceLocationId) {
            setSelectedFeesList([])
            providerLookup()
            productLookup()
            serviceLookup()
        }
    }, [inputData.practiceLocationId])

    useEffect(() => {
        patientLookup()
        if (props.initialData?.items) {
            parseInitialData()
        }
    }, [props.initialData])

    useEffect(() => {

        if (!redeemServicesChecked &&
            selectedServiceList && Array.isArray(selectedServiceList) && selectedServiceList.length>0 &&
            membershipItemsList && Array.isArray(membershipItemsList) && membershipItemsList.length>0 ) {
            let selectedServices = [...selectedServiceList];
            let selectedServicesUpdated = false;

            selectedServices.forEach(serv => {

                let canRedeem = false;
                if (serv.quantity>0) {
                    const membershipItem = membershipItemsList.find((x) => x.itemId == serv.itemId && x.available>0);
                    if (membershipItem) {
                        canRedeem = true;
                    }
                }

                if (canRedeem != serv.canRedeem) {
                    selectedServicesUpdated = true;
                    serv.canRedeem = canRedeem;
                }


            });

            if (selectedServicesUpdated) {
                setSelectedServiceList(selectedServices);
                setRedeemServicesChecked(true);
            }
    
        }

    },[membershipItemsList, selectedServiceList, redeemServicesChecked])

    const addProduct = (product) => {
        let newProduct = {}
        let newList = [...selectedProductList]
        newProduct.name = product.name
        newProduct.itemId = product.id
        newProduct.quantity = 1
        newProduct.discount = product.discount || 0
        newProduct.discountType = parseInt(product.discountType) || 2
        newProduct.doctorId = (providerList && Array.isArray(providerList) && providerList.length>0 ? providerList[0].id : null)
        newProduct.itemType = 1
        newProduct.cost = parseFloat(product.cost) || 0
        newProduct.overhead = parseFloat(product.overhead) || 0
        newProduct.unitPrice = +product.unitPrice
        newProduct.taxPercent = parseFloat(product.taxPercent) || 0
        newProduct.cost = parseFloat(product.cost) || 0
        newList.push(newProduct)
        setSelectedProductList([...newList])
    }

    
    const addMembership = (product) => {
        let newProduct = {}
        let newList = [...selectedMemberships]
        newProduct.name = product.name
        newProduct.itemId = product.id
        newProduct.quantity = 1
        newProduct.discount = product.discount || 0
        newProduct.discountType = parseInt(product.discountType) || 2
        newProduct.doctorId = (providerList && Array.isArray(providerList) && providerList.length>0 ? providerList[0].id : null)
        newProduct.itemType = 1
        newProduct.cost = parseFloat(product.cost) || 0
        newProduct.overhead = parseFloat(product.overhead) || 0
        newProduct.unitPrice = +product.unitPrice
        newProduct.taxPercent = parseFloat(product.taxPercent) || 0
        newProduct.cost = parseFloat(product.cost) || 0
        newProduct.membershipStartsOn = moment(new Date()).format('yyyy-MM-DD')
        newList.push(newProduct)
        setSelectedMemberships([...newList])
    }

    const addService = (service) => {
        let newService = {}
        let newList = [...selectedServiceList]
        newService.itemId = service.id
        newService.itemType = 2
        newService.quantity = 1
        newService.name = service.name
        newService.discount = service.discount || 0
        newService.discountType = parseInt(service.discountType) || 2
        newService.unitPrice = +service.unitPrice
        newService.cost = parseFloat(service.cost) || 0
        newService.overhead = parseFloat(service.overhead) || 0
        newService.doctorId = (providerList && Array.isArray(providerList) && providerList.length>0 ? providerList[0].id : null)
        newService.taxPercent = parseFloat(service.taxPercent) || 0
        newList.push(newService)
        setSelectedServiceList([...newList])
        setRedeemServicesChecked(false)
    }
    const addFees = (fees) => {
        if (selectedFeesList?.find(ob => ob.itemId === fees.id)) {
            console.log("already selected")
        } else {
            let newFees = {}
            newFees.itemId = fees.id
            newFees.itemType = 4
            newFees.feeAmount = fees.feeAmount || 0
            newFees.name = fees.name
            newFees.feePercentage = fees.feePercentage || 0
            newFees.feeApplicableTo = fees.feeApplicableTo
            newFees.feeRequired = fees.feeRequired
            newFees.taxPercent = parseFloat(fees.taxPercent) || 0
            newFees.feeIsTaxable = fees.feeIsTaxable
            newFees.discountType = 1
            setSelectedFeesList(selectedFeesList => [...selectedFeesList, newFees])
        }
    }
    const changeProduct = (e, i) => {
        let newList = [...selectedProductList]
        if (e.target.name === "discountType" || e.target.type === "number") {
            newList[i][e.target.name] = parseFloat(e.target.value)
            return setSelectedProductList([...newList])
        }
        else {
            newList[i][e.target.name] = e.target.value
            return setSelectedProductList([...newList])
        }
    }

    const changeMembershipItem = (e, i) => {
        let newList = [...membershipItemsList]
        if (e.target.name === "cashValueApplied") {
            newList[i][e.target.name] = e.target.checked
            if (e.target.checked) {
                newList[i]["unitPrice"] = (-1 * +newList[i]["cashValue"]);
            }
            else {
                newList[i]["unitPrice"] = 0;
            }
        }
        else if (e.target.name === "discountType" || e.target.type === "number") {

            if (e.target.name === "quantity") {

                // get quantities of other items if option membership
                let otherOptionQuantities = 0
                if (newList[i]["membershipType"] == 3) {
                    membershipItemsList
                        .filter(m => {return m.itemId != newList[i]["itemId"] && m.membershipParentItemId === newList[i]["membershipParentItemId"]} ) 
                        .forEach(m => {
                            otherOptionQuantities = otherOptionQuantities + ((m?.quantity || 0) * (m?.countsAs || 1))
                        })
                }

                let thisQuantity = (parseFloat(e.target.value)||0);
                let balance = newList[i]["balance"]||0;
                // item's quantity cannot be greater than available
                let countsAs = (newList[i]["countsAs"]||1)
                let countsAsQty = countsAs * thisQuantity;
                let newQuantity;
                if (balance < (countsAsQty+otherOptionQuantities)) {
                    newQuantity = Math.floor((balance-otherOptionQuantities) / countsAs)
                }
                else {
                    newQuantity = thisQuantity
                }

                if (e.target.value != "")
                {
                    newList[i][e.target.name] = newQuantity;
                }
                else
                {
                    newList[i][e.target.name] = e.target.value;
                }
                
                let newAvailable = balance - (otherOptionQuantities + (newQuantity*countsAs));

                // for membership option type update available balance for all items
                if (newList[i]["membershipType"] == 3) {
                    // find the membership row for this item
                    let membership = newList.find(a => 
                        a.membershipType == 3 && 
                        a.membershipParentItemId === newList[i]["membershipParentItemId"]
                    )
                    if (membership) {
                        membership.available = newAvailable;
                        // update available for all the other items
                        newList
                            .filter(m => {return m.membershipParentItemId === newList[i]["membershipParentItemId"]} ) 
                            .forEach(m => {
                                m.available = newAvailable;
                            })
                    }
                } 
                else {
                    newList[i]["available"] = newAvailable;
                }

            }
            else
            {
                newList[i][e.target.name] = parseFloat(e.target.value)
            }

        }
        else {
            newList[i][e.target.name] = e.target.value
        }
        setSelectedMembershipItems(newList.filter(result => { return result?.quantity > 0}) )

        setMembershipItemsList([...newList])
        setRedeemServicesChecked(false)
    }

    const changeMembership = (e, i) => {
        let newList = [...selectedMemberships]
        if (e.target.name === "discountType" || e.target.type === "number") {
            newList[i][e.target.name] = parseFloat(e.target.value)
            return setSelectedMemberships([...newList])
        }
        else {
            newList[i][e.target.name] = e.target.value
            return setSelectedMemberships([...newList])
        }
    }


    const changeFees = (e, i) => {
        let newList = [...selectedFeesList]
        newList[i][e.target.name] = e.target.value
        return setSelectedFeesList([...newList])
    }
    const changeService = (e, i) => {
        let newList = [...selectedServiceList]
        if (e.target.name === "discountType" || e.target.type === "number") {
            newList[i][e.target.name] = parseFloat(e.target.value)
        }
        else {
            newList[i][e.target.name] = e.target.value
        }
        setSelectedServiceList([...newList])
        setRedeemServicesChecked(false)        
    }

    const changePrepaid = (e) => {
        let newList = selectedPrepaid[0]
        if (e.target.name === "discountType" || e.target.type === "number") {
            newList[e.target.name] = parseFloat(e.target.value)
            return setSelectedPrepaid([newList])
        }
        else {
            newList[e.target.name] = e.target.value
            return setSelectedPrepaid([newList])
        }
    }

    const removeMembership = (removeItem, i) => {
        let newList = [...selectedMemberships];
        newList.splice(i,1);
        setSelectedMemberships(newList);
    }
    const removeProduct = (removeItem, i) => {
        let newList = [...selectedProductList];
        newList.splice(i,1);
        setSelectedProductList(newList);
    }
    const removeService = (removeItem, i) => {
        let newList = [...selectedServiceList];
        newList.splice(i,1);
        setSelectedServiceList(newList);
    }
    const removeFees = (removeItem, i) => {
        let newList = [...selectedFeesList];
        newList.splice(i,1);
        setSelectedFeesList(newList);
    }
    const redeemService = () => {

        let i = serviceToRedeem;
        let serv = selectedServiceList[i];
        let selectedServices = [...selectedServiceList];
        let membershipItems = [...membershipItemsList];
        let membershipsUpdated = false;

        let keepSearching = true;
        while (keepSearching) {

            const membershipItem = membershipItems.find((x) => x.practiceServiceTypeId == serv.practiceServiceTypeId && x.available>0);
            if (membershipItem) {
                membershipsUpdated = true;

                let qtyRedeemed = Math.min(membershipItem.available,serv.quantity);
                serv.quantity = serv.quantity - qtyRedeemed;
                membershipItem.available = membershipItem.available - qtyRedeemed;
                membershipItem.quantity = membershipItem.quantity + qtyRedeemed;
                membershipItem.needToUpdate = true;
                
                keepSearching = serv.quantity > 0;

            } else
            {
                keepSearching = false;
            }

        }

        if (serv.quantity == 0) {
            selectedServices.splice(i, 1);
        }
        else {
            selectedServices[i].quantity = serv.quantity;
        }

        if (membershipsUpdated) {
            setSelectedServiceList(selectedServices);
            membershipItems.forEach((membershipItem, memIndex) => {
                if (membershipItems?.needToUpdate) {
                    changeMembershipItem({target: {
                        name: "quantity",
                        value:membershipItem.quantity
                    }},memIndex);
                }
            })
        }
    
    }
    // formula for input change
    const inputChange = (e) => {
        let newStateObject = { ...inputData };
        newStateObject[e.target.name] = e.target.value
        setInputData(newStateObject);
    };

    useEffect(() => {
        if (selectedPatient) {
            inputChange({ target: { name: 'patientName', value: selectedPatient.name ? selectedPatient.name : `${selectedPatient.firstName} ${selectedPatient.lastName}` } })
        }
        else if (props.initialData.patientId) {
            let newData = inputData
            newData.patientId = ''
            newData.patientName = ''
            setInputData(newData)
        }
    }, [selectedPatient])

    useEffect(() => {
        //console.log("feesList useEffect")
        if (feesList && feesList.length > 0) {
            if (props.isEdit) {
                if(props.initialData?.items){
                    props.initialData.items.filter(obj => { return obj.itemType == 4 }).map(item => {
                        if (selectedFeesList?.find(ob => ob.itemId === item.itemId)) {
                            console.log("already selected")
                            return null
                        } else {
                            //fetch fee item record from item table
                            let feeObject = feesList.filter(result => result.fees == item.itemId)
                            let newFees = item
                            let defaultFeeIsTaxable = item.taxPercent > 0 ? 1 : 0;
                            newFees.feeAmount = item.feePercentage > 0 ? 0 : isFalsy(item.unitPrice,0)
                            newFees.feeApplicableTo = isFalsy(feeObject.feeApplicableTo, 2) //if fee record is deleted default is set to 2 = both
                            newFees.feeRequired = isFalsy(feeObject.feeRequired, 1) //if fee record is deleted default is set to 1 = required
                            newFees.feeIsTaxable = isFalsy(feeObject.feeIsTaxable,defaultFeeIsTaxable)    //if fee record is deleted default is set 1 if tax percent has some value else 0
                            newFees.discountType = 1
                            newFees.feesEachSubTotal = 0
                            newFees.feesEachTaxAmount = 0
                            newFees.feesEachTotalAmount = 0
                            setSelectedFeesList(selectedFeesList => [...selectedFeesList, newFees])
                            return newFees
                        }
                    })
                }
            } else {
                feesList.filter(result => { return result?.feeRequired == 1 }).map((item) => {
                    addFees(item)
                    return item
                })
            }
        }
    }, [feesList])

    const feesCalculateTotal = (element) => {
        let subTotal = 0
        if (element.feePercentage > 0) {
            if (element.feeApplicableTo === 2 && (selectedProductList.length > 0 || selectedServiceList.length > 0)) {
                let newItemList = [...selectedProductList, ...selectedServiceList]

                newItemList.forEach((item, i) => {
                    subTotal = subTotal + (item.unitPrice * item.quantity)
                })

            } else if (element.feeApplicableTo === 1 && selectedServiceList.length > 0) {
                selectedServiceList.forEach((item, i) => {
                    subTotal = subTotal + (item.unitPrice * item.quantity)
                })
            } else if (element.feeApplicableTo === 0 && selectedProductList.length > 0) {
                selectedProductList.forEach((item, i) => {
                    subTotal = subTotal + (item.unitPrice * item.quantity)
                })
            }
            if (subTotal>0) {
                element.feesEachTotalAmount = Math.round((Math.round(subTotal * (element.feePercentage / 100) * 100) / 100) * (1 + (element.taxPercent / 100)) * 100) / 100 
                element.feesEachSubTotal = Math.round(subTotal * (element.feePercentage / 100) * 100) / 100 
                element.feesEachTaxAmount = Math.round((Math.round(subTotal * (element.feePercentage / 100) * 100) / 100) * (element.taxPercent / 100) * 100) / 100 
            }
            return element.feesEachTotalAmount
        }
        else {
            if (element.feeAmount > 0) {
                element.feesEachTotalAmount = Math.round(element.feeAmount * (1 + (element.taxPercent / 100)) * 100) / 100 
                element.feesEachSubTotal = element.feeAmount 
                element.feesEachTaxAmount = Math.round(element.feeAmount * (element.taxPercent / 100) * 100) / 100 
            }
            return element.feesEachTotalAmount
        }

    }
    const lineTotal = (element) => {
        let total = element.unitPrice * element.quantity
        let discountAmt = 0
        if (+element.discount > 0) {
            if (element.discountType == 2) {
                discountAmt = Math.round(parseFloat(element.quantity) * element.unitPrice * (element.discount / 100) * 100) / 100
            }
            else if (element.discountType == 3) {
                discountAmt = parseFloat(element.discount)
            }
            else {
                discountAmt = element.discount * parseFloat(element.quantity)
            }
        }

        total = total - discountAmt
        if (element.taxPercent > 0) {
            return Math.round(total * (1 + (element.taxPercent / 100)) * 100) / 100
        }
        else {
            return total
        }
    }

    const fixNaN = (value, substitute) => {
        if (isNaN(value)) return substitute;
        return value;
    }
    const sumItems = (items) => {
        let newArray = [...items]
        let subTotal = 0
        let discountTotal = 0
        let taxTotal = 0
        let productTotal = 0
        let serviceTotal = 0
        let feesTotal = 0
        let ltotalMemberships = 0;
        let ltotalMembershipItems = 0;
    
        newArray.forEach((item, i) => {
            if (item.itemType === 4) {
                //only for fee type item
                feesTotal = feesTotal + item.feesEachTotalAmount
                subTotal = subTotal + item.feesEachSubTotal
                taxTotal = taxTotal + item.feesEachTaxAmount
            } 
            else {

                let unitPrice = parseFloat(fixNaN(item.unitPrice,0))
                let quantity = parseFloat(fixNaN(item.quantity,0))
                let discount = parseFloat(fixNaN(item.discount,0))
                let lineTotalVal = parseFloat(fixNaN(lineTotal(item),0))
    
                subTotal = subTotal + (unitPrice * quantity)
    
                let discountAmt = 0
                if (discount > 0) {
                    if (item.discountType == 2) {
                        discountAmt = Math.round(quantity * unitPrice * (discount / 100) * 100) / 100
                    }
                    else if (item.discountType == 3) {
                        discountAmt = discount
                    }
                    else {
                        discountAmt = discount * quantity
                    }
                }
                discountTotal = discountTotal + discountAmt
    
                if (parseFloat(item.taxPercent) > 0) {
                    taxTotal = taxTotal + ((quantity * unitPrice) - discountAmt) * item.taxPercent / 100
                }
    
                if ([1,2].includes(item.itemType) && item.membershipItemId) {
                    ltotalMembershipItems = parseFloat(ltotalMembershipItems) + lineTotalVal
                }
                else if (item.itemType == 1) {
                    productTotal = parseFloat(productTotal) + lineTotalVal;
                }
                else if (item.itemType == 2) {
                    serviceTotal = parseFloat(serviceTotal) + lineTotalVal
                }
                else if (item.itemType == 5) {
                    ltotalMemberships = parseFloat(ltotalMemberships) + lineTotalVal
                }


            }

        })

        setTotalMemberships(ltotalMemberships)
        setTotalMembershipItems(ltotalMembershipItems)
    
        setTotalDiscount(discountTotal)
        setTotalServices(serviceTotal)
        setTotalProducts(productTotal)
        setTotalFees(feesTotal)
        setSubTotal(subTotal)
        setTotalTax(taxTotal)
        setTotal(subTotal - discountTotal + taxTotal)
    }

    useEffect(() => {
        if (invoiceType == 0) {
            sumItems([...selectedProductList, ...selectedServiceList, ...selectedFeesList, ...selectedMembershipItems, ...selectedMemberships])
        }
        else if (selectedPrepaid?.length > 0) {
            setTotal(selectedPrepaid[0]?.unitPrice)
        }
        else {
            setTotal(0)
        }
    }, [selectedProductList, selectedServiceList, selectedFeesList, invoiceType, selectedPrepaid, selectedMembershipItems, selectedMemberships])

    useEffect(() => {
        // TO DO ????

        inputChange({
            target: {
                name: 'transactionType', value: 2
            }
        })
        return inputChange({
            target: {
                name: "invoiceStatus", value: 2
            }
        })
    }, [])


    useEffect(() => {
         //console.log("props.initialData ::::"+JSON.stringify(props.initialData))

        // TO DO should use available membership items?

        let practiceServiceTypeIds = [];
        let servicesToAdd = [];
        if (props.initialData?.practiceServiceTypeId) {
            practiceServiceTypeIds.push(props.initialData?.practiceServiceTypeId);
            servicesToAdd.push({
                practiceServiceTypeId: props.initialData?.practiceServiceTypeId,
                doctorId: props.initialData?.doctorId
            });
        }
        if (props.initialData?.childServices && Array.isArray(props.initialData.childServices) && props.initialData.childServices.length>0) {
            props.initialData.childServices.map(x => {
                practiceServiceTypeIds.push(x.practiceServiceTypeId);
                servicesToAdd.push({
                    practiceServiceTypeId: x.practiceServiceTypeId,
                    doctorId: x.doctorId
                });
                return null;
            })
        }
        
        if (practiceServiceTypeIds.length>0)        
        {
            PracticeServiceTypeService.serviceTypeByPracticeServiceTypeId({ practiceServiceTypeIds: practiceServiceTypeIds })
                .then(res => 
                    {
                        let selectedServices = [];
                        servicesToAdd.map(serviceToAdd => {
                            
                            const serv = res.data.find((serv) => serv.practiceServiceTypeId == serviceToAdd.practiceServiceTypeId);
                            if (serv) {
                                let newServ = serv
                                newServ.itemId = isFalsy(serv.id, null)
                                // newServ.serviceId = isFalsy(serv.serviceId, null)
                                newServ.discount = isFalsy(serv.discountPercent, 0)
                                newServ.discountType = isFalsy(serv.discountType, 2)
                                newServ.taxPercent = isFalsy(serv.taxPercent, 0)
                                newServ.doctorId = serviceToAdd.doctorId
                                newServ.quantity = 1
                                selectedServices.push(newServ)
                            }
                        })
            
                        if (selectedServices) setSelectedServiceList(selectedServices);
                    }
                )
        }
    }, [props.initialData])

    useEffect(() => {

        // TO DO ????

        //console.log("invoiceType, prepaidList useEffect"+invoiceType+" : "+prepaidList)
        if (invoiceType == 0) {
            setSelectedPrepaid([])
            //adding required fee list by default
            if (feesList && !props.isEdit && feesList.length > 0) {
                feesList.filter(result => { return result?.feeRequired == 1 }).map((item) => {
                    addFees(item)
                    return item
                })
            }
        }
        else if (invoiceType == 1) {
            setSelectedProductList([])
            setSelectedServiceList([])
            setSelectedFeesList([])
            setSelectedMemberships([])
            setSelectedMembershipItems([])
            
            if (prepaidList?.length > 0) {
                let reqObj = (prepaidList && Array.isArray(prepaidList) && prepaidList.length>0 ? prepaidList[0] : null)
                reqObj.quantity = 1
                reqObj.discountType = 1
                reqObj.taxPercent = 0
                delete reqObj.serviceId
                setSelectedPrepaid([reqObj])
            }
        }
    }, [invoiceType, prepaidList])

    useEffect(() => {
        console.log("initial data", props.initialData)
        if (props.isEdit) {
            if (props.initialData?.invoiceType === 5) {
                setInvoiceType(1)
            }
            else {
                setInvoiceType(0)
            }
        }
        inputChange({ target: { name: 'practiceLocationId', value: props.initialData?.practiceLocationId || state?.practiceLocationId } })
    }, [props.initialData])

    useEffect(() => {
        if (selectedPatient) {
            inputChange({ target: { name: 'patientId', value: selectedPatient?.id } })
            if (selectedPatient?.id) {
                membershipItemLookup();
            }
        }
    }, [selectedPatient, patientList])

    const onLocationChange = (location) => {
        if (location?.practiceLocationId) {
            inputChange({ target: { name: 'practiceLocationId', value: location.practiceLocationId } })
        }
    }

    const onPatientChange = (patient) => {
        if (patient?.id) {
            setSelectedPatient(patient)
        }
    }

    const submitUpdate = () => {
        if (inputData.amountPaid && total < inputData.amountPaid) {
            setInvoiceTotalError(true);
            return;
        }
        let providerId = (providerList && Array.isArray(providerList) && providerList.length>0 ? providerList[0].id : null);
        props.submitHandler(inputData, selectedPatient, [...selectedProductList, ...selectedServiceList, ...selectedFeesList, ...selectedPrepaid, ...selectedMemberships, ...selectedMembershipItems ], providerId, invoiceType)
    }

    return (
        <div>
            <div className='container-fluid mb-3 m-m-0'>
                {props.isLoader && <DimLoader loadMessage="Creating Invoice" />}
                <div className='mb-3'>
                    <PatientFinancialHeader patient={selectedPatient} locationId={inputData?.practiceLocationId} disableLocationEdit={props.isEdit} disablePatientEdit={props.isEdit} onLocationChange={location => onLocationChange(location)} onPatientChange={patient => onPatientChange(patient)} />
                </div>
                {/* <h5 className='text-center'>Collect Payment</h5> */}
                {!props.isEdit && <div className='row d-flex align-items-end'>
                    <div className="col-12 ui small buttons center mb-3">
                        <button type="button" className={`btn w-50 ${invoiceType == 0 ? 'btn-primary' : 'btn-secondary'}`} onClick={e => { e.preventDefault(); setInvoiceType(0) }}>Products and Services</button>
                        <div className="or"></div>
                        <button className={`btn w-50 ${invoiceType == 1 ? 'btn-primary' : 'btn-secondary'}`} type="button" onClick={e => { e.preventDefault(); setInvoiceType(1) }}>Prepaid Balances</button>
                    </div>
                </div>}
                <div className="card p-3 m-m-0">
                    {invoiceType == 0 ?
                        <div className="row d-flex">
                            <h5 className='text-center mt-3'>Products and Services</h5>
                            <div title='Products/Services' className='p-0'>
                                <div className='container-fluid mt-3'>
                                    <div className='row d-flex'>
                                        <div className='col-12 px-0 card' style={{ overflow: 'unset!important' }}>


                                        <Accordion fluid styled>

{
    //#region Products
}
                                            <Accordion.Title
                                                active={expandProducts}
                                                index={0}
                                                onClick={e => { e.preventDefault(); setExpandProducts(!expandProducts) }}
                                            > <Icon name='dropdown' />Products <span className='float-right'>Product Total: {Utilities.toDollar(totalProducts || 0)}</span>   </Accordion.Title>
                                            <Accordion.Content active={expandProducts}
                                            
                                            > 
                                            <table className='table table-borderless my-0 invoice-product-table'>
                                                {!isMobile ? <colgroup>
                                                    <col span="1" />
                                                    <col span="1" style={{ width: '250px' }} />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                </colgroup> : null}

                                                <thead>
                                                    <tr>
                                                        <th className='py-2'>Products</th>
                                                        <th className='py-2'>Provider</th>
                                                        <th className='py-2'>Unit Rate</th>
                                                        <th className='py-2'>Quantity</th>
                                                        <th className='py-2'>Discount</th>
                                                        <th className='py-2'>Tax(%)</th>
                                                        <th className='py-2'>Amount</th>
                                                        <th className='py-2'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {selectedProductList?.map((product, i) => {
                                                        return (
                                                            <tr key={product.id} className='product-line'>
                                                                <td className=''>
                                                                <label>
                                                                    {isMobile ? 'Product Name' : ''}
                                                                    <input type="text" disabled={true} value={product.name} />
                                                                </label>
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Provider</label> : null}
                                                                    <HPTSelect
                                                                        options={providerList}
                                                                        className="react-select-container"
                                                                        classNamePrefix="react-select"
                                                                        isDisabled={!providerList}
                                                                        name="doctorId"
                                                                        isSearchable
                                                                        value={providerList?.find(obj => obj.id === product.doctorId)}
                                                                        onChange={e => {
                                                                            if (e?.id) {
                                                                                changeProduct({
                                                                                    target:
                                                                                        { value: e.id, name: 'doctorId' }
                                                                                }, i)
                                                                            }
                                                                            else {
                                                                                changeProduct({
                                                                                    target: {
                                                                                        value: '', name: 'doctorId'
                                                                                    }
                                                                                }, i)
                                                                            }
                                                                        }}
                                                                        getOptionLabel={(option) => option.name}
                                                                        getOptionValue={(option) => option.id}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Unit Price</label> : null}
                                                                    <input value={product.unitPrice} type="number" name="unitPrice" onChange={e => { e.preventDefault(); changeProduct(e, i) }} />
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Quantity</label> : null}
                                                                    <input value={selectedProductList[i].quantity} type="number" name="quantity" onChange={e => { e.preventDefault(); changeProduct(e, i) }} />
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Discount</label> : null}
                                                                    <div className='input-group'>
                                                                        <input value={selectedProductList[i].discount} type="number" name="discount" onChange={e => { e.preventDefault(); changeProduct(e, i) }} />

                                                                        <select value={selectedProductList[i].discountType} onChange={e => { e.preventDefault(); changeProduct(e, i) }} className='form-select' name="discountType" style={{ maxWidth: '90px' }}>
                                                                            <option selected value={2}>%</option>
                                                                            <option value={1}>$</option>
                                                                            <option value={3}>Total $</option>
                                                                        </select>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Tax Percent</label> : null}
                                                                    <input value={selectedProductList[i].taxPercent} type="number" name="taxPercent" onChange={e => { e.preventDefault(); changeProduct(e, i) }} /></td>
                                                                <td>
                                                                    {isMobile ? <label>Total</label> : null}
                                                                    <input type="text" disabled value={Utilities.toDollar(isNaN(lineTotal(product)
                                                                    ) ? 0 : lineTotal(product)).toString()} />
                                                                </td>
                                                                <td className="button-cell"><button className='btn btn-transparent' onClick={e => { e.preventDefault(); removeProduct(product, i) }}>
                                                                    <i className='icon trash' />{isMobile ? ' Remove from Invoice' : ''}
                                                                </button></td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>
                                                        <td colSpan={8}>

                                                            <HPTSelect
                                                                options={productList}
                                                                onChange={e => {
                                                                    addProduct(e)
                                                                }}
                                                                value={null}
                                                                className="react-select-container"
                                                                classNamePrefix="react-select"
                                                                placeholder="Add Product"
                                                                getOptionLabel={(option) => option.name
                                                                +(option.manufacturerName ? (' | '+option.manufacturerName): "") 
                                                                +  (option.tags &&  Array.isArray(option.tags) && option.tags.length>0 ? (' | '+ option.tags?.map(({ name }) => name).join(', ')) : "") 
                                                                + ' | $' + option.unitPrice}
                                                                getOptionValue={(option) => option.id}
                                                            />
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                            </Accordion.Content>
{
//#endregion
}

{
    //#region Services
}
                                            <Accordion.Title
                                                active={expandServices}
                                                index={1}
                                                onClick={e => { e.preventDefault(); setExpandServices(!expandServices) }}
                                            > <Icon name='dropdown' />Services <span className='float-right'>Service Total: {Utilities.toDollar(totalServices || 0)}</span>   </Accordion.Title>
                                            <Accordion.Content active={expandServices}>

                                            <table className='table table-borderless my-0 invoice-product-table'>
                                                {!isMobile ? <colgroup>
                                                    <col span="1" />
                                                    <col span="1" style={{ width: '250px' }} />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                </colgroup> : null}


                                                    <tr className="thead">
                                                        <th>Services</th>
                                                        <th>Provider</th>
                                                        <th>Unit Rate</th>
                                                        <th>Quantity</th>
                                                        <th>Discount</th>
                                                        <th>Tax(%)</th>
                                                        <th>Amount</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                <tbody>

                                                    {selectedServiceList?.map((service, i) => {
                                                        return (
                                                            <tr className="product-line">
                                                                <td className='pb-0 pt-3'>
                                                                    {isMobile ? <label>Service Name</label> : null}
                                                                    <input value={service.name} disabled type="text" />
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Provider</label> : null}
                                                                    <HPTSelect
                                                                        options={providerList}
                                                                        className="react-select-container"
                                                                        classNamePrefix="react-select"
                                                                        isDisabled={!providerList}
                                                                        name="doctorId"
                                                                        isSearchable
                                                                        value={providerList?.find(obj => obj.id === service.doctorId)}
                                                                        onChange={e => {
                                                                            if (e?.id) {
                                                                                changeService({
                                                                                    target:
                                                                                        { value: e.id, name: 'doctorId' }
                                                                                }, i)
                                                                            }
                                                                            else {
                                                                                changeService({
                                                                                    target: {
                                                                                        value: null, name: 'doctorId'
                                                                                    }
                                                                                }, i)
                                                                            }
                                                                        }}
                                                                        getOptionLabel={(option) => option.name}
                                                                        getOptionValue={(option) => option.id}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Unit Price</label> : null}
                                                                    <input value={service.unitPrice} type="number" name="unitPrice" onChange={e => { e.preventDefault(); changeService(e, i) }} />
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Quantity</label> : null}
                                                                    <input value={selectedServiceList[i].quantity} type="number" name="quantity" onChange={e => { e.preventDefault(); changeService(e, i) }} /></td>
                                                                <td>
                                                                    {isMobile ? <label>Discount</label> : null}
                                                                    <div className='input-group'>
                                                                        <input value={selectedServiceList[i].discount} type="number" name="discount" onChange={e => { e.preventDefault(); changeService(e, i) }} />

                                                                        <select value={selectedServiceList[i].discountType} onChange={e => { e.preventDefault(); changeService(e, i) }} className='form-select' name="discountType" style={{ maxWidth: '90px' }}>
                                                                            <option selected value={2}>%</option>
                                                                            <option value={1}>$</option>
                                                                            <option value={3}>Total $</option>
                                                                        </select>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Tax Percent</label> : null}
                                                                    <input value={selectedServiceList[i].taxPercent} type="number" name="taxPercent" onChange={e => { e.preventDefault(); changeService(e, i) }} /></td>
                                                                <td>
                                                                    {isMobile ? <label>Total</label> : null}
                                                                    <input type="text" disabled value={Utilities.toDollar(isNaN(lineTotal(service)
                                                                    ) ? 0 : lineTotal(service)).toString()} />
                                                                </td>
                                                                <td className='button-cell'><button className='btn btn-transparent' onClick={e => { e.preventDefault(); removeService(service, i) }}>
                                                                    <i className='icon trash' />{isMobile ? ' Remove from Invoice' : ''}
                                                                </button></td>
                                                                <td className='button-cell'>
                                                                    {service?.canRedeem ?
                                                                        <button className='btn btn-primary me-2 not-mobile' name="redeem" onClick={e => { e.preventDefault(); setServiceToRedeem(i); setConfirmRedeemItem(true); }}>
                                                                        Redeem
                                                                        </button>
                                                                    : null }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>
                                                        <td colSpan={8}>
                                                            {/* <div className='input-group row align-items-center'>
                                                                <i className='icon plus col-auto my-2 mx-3' /> */}
                                                            <HPTSelect
                                                                options={serviceList}
                                                                onChange={e => {
                                                                    addService(e)
                                                                }}
                                                                value={null}
                                                                className="react-select-container"
                                                                classNamePrefix="react-select"
                                                                placeholder="Add Service"
                                                                getOptionLabel={(option) => option.name + ' | $' + option.unitPrice}
                                                                getOptionValue={(option) => option.id}
                                                            />
                                                            {/* </div> */}
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>

                                            </Accordion.Content>
{
    //#endregion
}

{
    //#region Purchase Memberships
}
                                            {membershipsList && membershipsList?.length > 0 ? 
                                            <>
                                            <Accordion.Title
                                                active={expandMemberships}
                                                index={4}
                                                onClick={e => { e.preventDefault(); setExpandMemberships(!expandMemberships) }}
                                            > <Icon name='dropdown' />Purchase Memberships <span className='float-right'>Membership Total: {Utilities.toDollar(totalMemberships || 0)}</span>   </Accordion.Title>
                                            <Accordion.Content active={expandMemberships}>

                                            <table className='table table-borderless my-0 invoice-product-table'>
                                                {!isMobile ? <colgroup>
                                                    <col span="1" />
                                                    <col span="1" style={{ width: '250px' }} />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                </colgroup> : null}

                                                <thead>
                                                    <tr>
                                                        <th className='py-2'>Memberships</th>
                                                        <th className='py-2'>Starts On</th>
                                                        <th className='py-2'>Provider</th>
                                                        <th className='py-2'>Unit Rate</th>
                                                        <th className='py-2'>Quantity</th>
                                                        <th className='py-2'>Discount</th>
                                                        <th className='py-2'>Tax(%)</th>
                                                        <th className='py-2'>Amount</th>
                                                        <th className='py-2'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {selectedMemberships?.map((membership, i) => {
                                                        return (
                                                            <tr className='product-line'>
                                                                <td className=''>
                                                                    {isMobile ? <label>Membership Name</label> : null}
                                                                    <input type="text" disabled={true} value={membership.name} />
                                                                </td>
                                                                <td className=''>
                                                                    {isMobile ? <label>Starts On</label> : null}
                                                                    <input value={selectedMemberships[i].membershipStartsOn} type="date" name="membershipStartsOn" onChange={e => { e.preventDefault(); changeMembership(e, i) }} />
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Provider</label> : null}
                                                                    <HPTSelect
                                                                        options={providerList}
                                                                        className="react-select-container"
                                                                        classNamePrefix="react-select"
                                                                        isDisabled={!providerList}
                                                                        name="doctorId"
                                                                        isSearchable
                                                                        value={providerList?.find(obj => obj.id === membership.doctorId)}
                                                                        onChange={e => {
                                                                            if (e?.id) {
                                                                                changeMembership({
                                                                                    target:
                                                                                        { value: e.id, name: 'doctorId' }
                                                                                }, i)
                                                                            }
                                                                            else {
                                                                                changeMembership({
                                                                                    target: {
                                                                                        value: '', name: 'doctorId'
                                                                                    }
                                                                                }, i)
                                                                            }
                                                                        }}
                                                                        getOptionLabel={(option) => option.name}
                                                                        getOptionValue={(option) => option.id}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Unit Price</label> : null}
                                                                    <input value={membership.unitPrice} type="number" name="unitPrice" onChange={e => { e.preventDefault(); changeMembership(e, i) }} />
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Quantity</label> : null}
                                                                    <input value={selectedMemberships[i].quantity} type="number" name="quantity" onChange={e => { e.preventDefault(); changeMembership(e, i) }} />
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Discount</label> : null}
                                                                    <div className='input-group'>
                                                                        <input value={selectedMemberships[i].discount} type="number" name="discount" onChange={e => { e.preventDefault(); changeMembership(e, i) }} />

                                                                        <select value={selectedMemberships[i].discountType} onChange={e => { e.preventDefault(); changeMembership(e, i) }} className='form-select' name="discountType" style={{ maxWidth: '90px' }}>
                                                                            <option selected value={2}>%</option>
                                                                            <option value={1}>$</option>
                                                                            <option value={3}>Total $</option>
                                                                        </select>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Tax Percent</label> : null}
                                                                    <input value={selectedMemberships[i].taxPercent} type="number" name="taxPercent" onChange={e => { e.preventDefault(); changeMembership(e, i) }} /></td>
                                                                <td>
                                                                    {isMobile ? <label>Total</label> : null}
                                                                    <input type="text" disabled value={Utilities.toDollar(isNaN(lineTotal(membership)
                                                                    ) ? 0 : lineTotal(membership)).toString()} />
                                                                </td>
                                                                <td className="button-cell"><button className='btn btn-transparent' onClick={e => { e.preventDefault(); removeMembership(membership, i) }}>
                                                                    <i className='icon trash' />{isMobile ? ' Remove from Invoice' : ''}
                                                                </button></td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>
                                                        <td colSpan={9}>

                                                            <HPTSelect
                                                                options={membershipsList}
                                                                onChange={e => {
                                                                    addMembership(e)
                                                                }}
                                                                value={null}
                                                                className="react-select-container"
                                                                classNamePrefix="react-select"
                                                                placeholder="Add Membership"
                                                                getOptionLabel={(option) => option.name
                                                                +(option.manufacturerName ? (' | '+option.manufacturerName): "") 
                                                                +  (option.tags ? (' | '+ option.tags?.map(({ name }) => name).join(', ')) : "") 
                                                                + ' | $' + option.unitPrice}
                                                                getOptionValue={(option) => option.id}
                                                            />
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                            </Accordion.Content>

                                            </> : null }
{
    //#endregion
}

{
    //#region Redeem Membership Items
}
                                        {membershipItemsList && membershipItemsList?.length > 0 ? 
                                        <>
                                        <Accordion.Title
                                                active={expandMembershipItems}
                                                index={3}
                                                onClick={e => { e.preventDefault(); setExpandMembershipItems(!expandMembershipItems) }}
                                            > <Icon name='dropdown' />Redeem Membership Items<span className='float-right'>Items Redeemed Total: {Utilities.toDollar(totalMembershipItems || 0)}</span>   </Accordion.Title>
                                            <Accordion.Content active={expandMembershipItems}>

                                            <table className='table table-borderless my-0 invoice-product-table'>
                                                {!isMobile ? <colgroup>
                                                    <col span="1" />
                                                    <col span="1" style={{ width: '250px' }} />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                </colgroup> : null}

                                                <thead>
                                                    <tr>
                                                        <th className='py-2'>Products</th>
                                                        <th className='py-2'>Provider</th>
                                                        <th className='py-2'>Available</th>
                                                        <th className='py-2'>Quantity</th>
                                                        <th className='py-2'>Unit Rate</th>
                                                        <th className='py-2'>Discount</th>
                                                        <th className='py-2'>Tax(%)</th>
                                                        <th className='py-2'>Apply Cash Value</th>
                                                        <th className='py-2'>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                {
                                                membershipItemsList?.map((membershipItem, i) => {
                                                        return membershipItem.membershipRow==true ? (
                                                            <>
                                                            {i>0 &&
                                                            <tr className='product-line'>
                                                                <td className='' colSpan={9}>
                                                                    <hr></hr>
                                                                </td>
                                                            </tr>
                                                            }
                                                            <tr className='product-line'>
                                                                <td className='' colSpan={2}>
                                                                    {isMobile ? <label>Membership</label> : null}
                                                                    <span className='py-2'>{membershipItem.membershipName}</span>
                                                                </td>
                                                                <td className=''>
                                                                </td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                </td>
                                                            </tr>
                                                            </>) 
                                                        : (
                                                            <tr className='product-line'>
                                                                <td className=''>
                                                                    {isMobile ? <label>Product/Service</label> : null}
                                                                    <input type="text" disabled={true} value={membershipItem.name} />
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Provider</label> : null}
                                                                    <HPTSelect
                                                                        options={providerList}
                                                                        className="react-select-container"
                                                                        classNamePrefix="react-select"
                                                                        isDisabled={!providerList}
                                                                        name="doctorId"
                                                                        isSearchable
                                                                        value={providerList?.find(obj => obj.id === membershipItem?.doctorId)}
                                                                        onChange={e => {
                                                                            if (e?.id) {
                                                                                changeMembershipItem({
                                                                                    target:
                                                                                        { value: e.id, name: 'doctorId' }
                                                                                }, i)
                                                                            }
                                                                            else {
                                                                                changeMembershipItem({
                                                                                    target: {
                                                                                        value: '', name: 'doctorId'
                                                                                    }
                                                                                }, i)
                                                                            }
                                                                        }}
                                                                        getOptionLabel={(option) => option.name}
                                                                        getOptionValue={(option) => option.id}
                                                                    />
                                                                </td>
                                                                <td className=''>
                                                                    {isMobile ? <label>Available</label> : null}
                                                                    <input type="text" disabled={true} value={membershipItem.available} /> 
                                                            </td>
                                                                <td>
                                                                    {isMobile ? <label>Quantity</label> : null}
                                                                    <input value={membershipItem.quantity} type="number" name="quantity" onChange={e => { e.preventDefault(); changeMembershipItem(e, i) }} /> 
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Unit Price</label> : null}
                                                                    <input value={membershipItem.unitPrice} type="number" disabled={membershipItem?.cashValueApplied} name="unitPrice" onChange={e => { e.preventDefault(); changeMembershipItem(e, i) }} /> 
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Discount</label> : null}
                                                                    <div className='input-group'>
                                                                        <input value={membershipItem.discount} type="number" disabled={membershipItem?.cashValueApplied} name="discount" onChange={e => { e.preventDefault(); changeMembershipItem(e, i) }} /> 

                                                                        <select value={membershipItem.discountType} disabled={membershipItem?.cashValueApplied} className='form-select' onChange={e => { e.preventDefault(); changeMembershipItem(e, i) }} name="discountType" style={{ maxWidth: '90px' }}>
                                                                            <option selected value={2}>%</option>
                                                                            <option value={1}>$</option>
                                                                            <option value={3}>Total $</option>
                                                                        </select>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Tax Percent</label> : null}
                                                                    <input value={membershipItem.taxPercent} disabled={membershipItem?.cashValueApplied} type="number" name="taxPercent" onChange={e => { e.preventDefault(); changeMembershipItem(e, i) }} /> 
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Cash Value</label> : null}
                                                                    {membershipItem.cashValue ?
                                                                    <div className='input-group'>
                                                                        <input style={{"display":"inline-block"}} value={membershipItem.cashValue} type="number" name="cashValue" disabled /> 
                                                                        <input style={{"display":"inline-block", marginLeft:"5px"}} type="checkbox" className='form-check-input' checked={membershipItem?.cashValueApplied} name="cashValueApplied" onChange={e => { changeMembershipItem(e, i)  }} />                                                                
                                                                    </div>
                                                                     : null }
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Total</label> : null}
                                                                    <input type="text" disabled value={Utilities.toDollar(isNaN(lineTotal(membershipItem)
                                                                    ) ? 0 : lineTotal(membershipItem)).toString()} /> 
                                                                </td>
                                                            </tr>

                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                            </Accordion.Content>

                                        </>:null}
{
    //#endregion
}

{
    //#region Fees
}    
                                            {feesList && feesList?.length > 0 ? 
                                                    <>

                                            <Accordion.Title
                                                active={expandFees}
                                                index={2}
                                                onClick={e => { e.preventDefault(); setExpandFees(!expandFees) }}
                                            > <Icon name='dropdown' />Fees<span className='float-right'>Fees Total: {Utilities.toDollar(totalFees||0)}</span></Accordion.Title>

                                            <Accordion.Content active={expandFees}>

                                            <table className='table table-borderless my-0 invoice-product-table'>
                                                {!isMobile ? <colgroup>
                                                    <col span="1" />
                                                    <col span="1" style={{ width: '250px' }} />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                </colgroup> : null}

                                                    <tr className="thead">
                                                        <th>Fees</th>
                                                        <th>Fees($)</th>
                                                        <th>Fees(%)</th>
                                                        <th>Sub Total</th>
                                                        {/* <th>Applicable to</th>
                                                        <th>Fee Required</th> */}
                                                        <th>Tax(%)</th>
                                                        <th></th>
                                                        <th>Amount</th>
                                                        <th></th>
                                                    </tr>
                                                <tbody>

                                                    {selectedFeesList?.map((fees, i) => {
                                                        return (
                                                            <tr className="product-line">
                                                                <td className='pb-0 pt-3'>
                                                                    {isMobile ? <label>Fees Name</label> : null}
                                                                    <input value={fees.name} disabled type="text" />
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Fees($)</label> : null}
                                                                    <input value={fees.feeAmount} type="number" disabled={fees.feeAmount === null || fees.feeAmount === 0} name="feeAmount" onChange={e => { e.preventDefault(); changeFees(e, i) }} />
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Fees(%)</label> : null}
                                                                    <input value={fees.feePercentage} type="number" disabled={fees.feePercentage === null || fees.feePercentage === 0} name="feePercentage" onChange={e => { e.preventDefault(); changeFees(e, i) }} />
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Sub Total</label> : null}
                                                                    <input value={fees.feesEachSubTotal} disabled type="number" name="feesEachSubTotal" />
                                                                </td>
                                                                {/* <td>
                                                                    {isMobile ? <label>Applicable to</label> : null}
                                                                    <input value={FeeApplicableTo.find(obj => obj.value === fees.feeApplicableTo)?.title} disabled type="text" name="feeApplicableTo"/></td>
                                                                <td>
                                                                    {isMobile ? <label>Fee Required</label> : null}
                                                                        <input value={fees.feeRequired === 1 ? "Y" : "N"} disabled type="text" name="feeRequired" />
                                                                </td> */}
                                                                <td>
                                                                    {isMobile ? <label>Tax(%)</label> : null}
                                                                    <input value={fees.taxPercent} disabled={fees.feeIsTaxable !== 1} type="number" name="taxPercent" onChange={e => { e.preventDefault(); changeFees(e, i) }} /></td>

                                                                <td></td>
                                                                <td>
                                                                    {isMobile ? <label>Total</label> : null}
                                                                    <input type="text" disabled value={Utilities.toDollar(feesCalculateTotal(fees)).toString()} />
                                                                </td>
                                                                <td className='button-cell'><button className='btn btn-transparent' onClick={e => { e.preventDefault(); removeFees(fees, 1) }}>
                                                                    <i className='icon trash' />{isMobile ? ' Remove from Invoice' : ''}
                                                                </button></td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>
                                                        <td colSpan={8}>
                                                            <HPTSelect
                                                                options={feesList}
                                                                onChange={e => {
                                                                    addFees(e)
                                                                }}
                                                                value={null}
                                                                className="react-select-container"
                                                                classNamePrefix="react-select"
                                                                placeholder="Add Fees"
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.id}
                                                            />
                                                            {/* </div> */}
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>

                                            </Accordion.Content>

                                            </>: null}                                            
{
    //#endregion
}    
                                        </Accordion>

{
    //#region Totals
}    
                                            <table className='table table-borderless my-0 invoice-product-table'>
                                                {!isMobile ? <colgroup>
                                                    <col span="1" />
                                                    <col span="1" style={{ width: '250px' }} />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                </colgroup> : null}

                                                    {totalDiscount > 0 || totalTax > 0 ? <tr className='thead totals'>
                                                        <th colSpan={5} className='text-end'>Sub Total</th>
                                                        <th colSpan={3} className='text-end amount'>{Utilities.toDollar(subTotal || 0)}</th>
                                                    </tr> : null}
                                                    {totalDiscount > 0 ? <tr className="thead totals">
                                                        <th colSpan={5} className='text-end'>Total Discount</th>
                                                        <th colSpan={3} className='text-end amount'>{Utilities.toDollar(totalDiscount || 0)}</th>
                                                    </tr> : null}
                                                    {totalTax > 0 && <tr className="thead totals">
                                                        <th colSpan={5} className='text-end'>Total Tax</th>
                                                        <th colSpan={3} className='text-end amount'>{Utilities.toDollar(totalTax || 0)}</th>
                                                    </tr>}
                                                    <tr className="thead totals grand-total">
                                                        <th colSpan={5} className='text-end'>Total Amount</th>
                                                        <th colSpan={3} className='text-end amount'>{Utilities.toDollar(total || 0)}</th>
                                                    </tr>
                                                <tbody>
                                                </tbody>
                                            </table>
{
    //#endregion
}

                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div > :
                        <div className="w-100">
                            <h5 className='text-center mt-3'>Prepaid Items</h5>
                            <div className='my-3'>
                                <div className='row d-flex justify-content-center mx-0'>
                                    <div className='col-md-auto col-12 px-3'>
                                        {selectedPrepaid?.length > 0 && selectedPrepaid.map(pre => {
                                            return (
                                                <div className='field required m-d-flex'>
                                                    <label className='label-mobile'>Amount</label>
                                                    <input className="input-mobile" value={pre?.unitPrice} type="number" name="unitPrice" onChange={e => { e.preventDefault(); changePrepaid(e) }} />
                                                </div>
                                            )
                                        })

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div >
                <div className="col-12 mt-3 ">
                    <div className="card p-3">
                        <div className="field col-12">
                            <label>Memo</label>
                            <textarea name="description" type="text" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData.description || "Thank you for your business"} />
                        </div>
                    </div>
                </div>
                <div className='col-12 d-flex justify-content-end mt-3 modal-button-bar three'>

                    {!props.isEdit && 
                    <div className='col-auto'>
                        {invoiceType == 0 && 
                        <button className='btn btn-primary me-2 not-mobile'
                            onClick={e => {
                                e.preventDefault(); inputChange({
                                    target: {
                                        name: 'invoiceStatus', value: 1
                                    }
                                });
                                props.submitHandler(inputData, selectedPatient, [...selectedProductList, ...selectedFeesList, ...selectedServiceList, ...selectedPrepaid, ...selectedMemberships, ...selectedMembershipItems ], getFirstProvider(), invoiceType)
                            }} disabled={!selectedPatient}>
                            Save
                        </button>}
                        <button disabled={!selectedPatient || (total??0)<=0} onClick={e => { 
                                e.preventDefault();
                                props.submitHandler(inputData, selectedPatient, [...selectedProductList, ...selectedFeesList, ...selectedServiceList, ...selectedPrepaid, ...selectedMemberships, ...selectedMembershipItems ], getFirstProvider(), invoiceType,true) 
                            }} className='btn btn-primary me-2 not-mobile'>
                            Pay Now
                        </button>
                    </div>}

                    {props.isEdit ? 
                    <div className='col-auto'>
                        <button className="btn btn-primary me-2 not-mobile" onClick={e => { 
                                e.preventDefault(); 
                                submitUpdate();
                            }}>
                            Update
                        </button>

                        {
                            inputData && InvoiceUtility.OperationAllowed({...inputData, total:total}, InvoiceOperation.MAKE_PAYMENT) 
                        ?
                        <button className='btn btn-primary me-2 not-mobile' disabled={!selectedPatient} onClick={e => { 
                                e.preventDefault(); 
                                props.submitHandler(inputData, selectedPatient, [...selectedProductList, ...selectedFeesList, ...selectedServiceList, ...selectedPrepaid, ...selectedMemberships, ...selectedMembershipItems ], getFirstProvider(), invoiceType, true) 
                            }}>
                            Pay Now
                        </button>
                        : null}

                        {
                            inputData && InvoiceUtility.OperationAllowed({...inputData, total:total}, InvoiceOperation.CLOSE)
                        &&
                            props?.onCloseInvoice
                        ?
                        <button className='btn btn-primary me-2 not-mobile' disabled={!selectedPatient || total>0 } onClick={e => { 
                                e.preventDefault(); 
                                props?.onCloseInvoice(inputData, selectedPatient, [...selectedProductList, ...selectedFeesList, ...selectedServiceList, ...selectedPrepaid, ...selectedMemberships, ...selectedMembershipItems ], getFirstProvider()) 
                            }}>
                            Close Invoice
                        </button>
                        : null}

                    </div> 
                    : null}

                    <div className='col-auto'>  {props.isModal &&
                        <button className='btn btn-secondary me-2' onClick={e => { e.preventDefault(); props.onClose() }}>Cancel</button>
                    }</div>
                </div>

                <ModalBox open={showAdd} onClose={() => { setShowAdd(false) }}>
                    {showAdd && <AddPatient onClose={() => { setShowAdd(false) }} isModal />}
                </ModalBox>

                <ModalBox open={invoiceTotalError} title="Invalid Invoice Total"  requireConfirm onClose={() => { setInvoiceTotalError(false) }} confirmButton="Check Out" cancelButton="Cancel">
                    <span>The invoice total cannot be less than the amount already paid: ${inputData.amountPaid}</span>
                </ModalBox>

                <ModalBox open={confirmRedeemItem} title="Redeem Item/Service"  requireConfirm onClose={() => { setConfirmRedeemItem(false) }} onCloseSuccess={() => {setConfirmRedeemItem(false); redeemService(); }} confirmButton="Redeem" cancelButton="Cancel">
                    <span>Do you wish to redeem a membership item for this item or service?</span>
                </ModalBox>

            </div >
        </div >
    )
}

InvoiceForm.propTypes = {
    onClose: PropTypes.func,
    onCloseInvoice: PropTypes.func,
    submitHandler: PropTypes.func,
    isModal: PropTypes.bool,
    initialData: PropTypes.object,
    isEdit: PropTypes.bool,
    isLoader: PropTypes.bool,
}

export default InvoiceForm