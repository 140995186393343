import moment from 'moment';
import React, { useEffect, useState, useContext } from 'react'
import { store } from '../../../../../context/StateProvider';
import InvoiceService from '../../../../../services/api/invoice.service';
import Utilities from '../../../../../services/commonservice/utilities';
import Module from '../../../../templates/components/Module';
import Table2 from '../../../../templates/components/Table2';
import DatePicker from '../../../../templates/components/DatePicker';
import DimLoader from '../../../../templates/components/DimLoader';

const InvoiceDetails = (props) => {
  const [invoiceDetails, setInvoiceDetails] = useState()
  const [isLoader, setIsLoader] = useState(false)
  const [startDate, setStartDate] = useState(moment().startOf("D"));
  const [endDate, setEndDate] = useState(moment().endOf("D"));
  const state = useContext(store).state
  const [initialMessage, setInitialMessage] = useState(true)
  const [locationId, setLocationId] = useState(state.practiceLocationId || null)

  // const for table 
  const columns = [
    {
      id: "invoiceNumber",
      Header: "Invoice No.",
      accessor: (tc) => tc.invoiceNumber,
      Footer: ()=>"Totals"
    },
    {
      id: "invoiceDate",
      Header: "Date",
      accessor: (tc) => moment(tc.invoiceDate).format("MM-DD-YYYY h:mm A"),
    },
    {
      id: "invoiceStatus",
      Header: "Invoice Status",
      accessor: (tc) => tc.invoiceStatus
    },
    // {
    //   id: "paymentStatus",
    //   Header: "Payment Status",
    //   accessor: (tc) => tc.paymentStatus
    // },
    {
      id: "item",
      Header: "Item",
      accessor: (tc) => tc.item
    },
    {
      id: "unitPrice",
      Header: "Unit Price",
      accessor: (tc) => Utilities.toDollar(tc.unitPrice)
    },
    {
      id: "quantity",
      Header: "Quantity",
      accessor: (tc) => tc.quantity
    },
    {
      id: "discountPercent",
      Header: "Discount %",
      accessor: (tc) => tc.discountPercent
    },
    {
      id: "discountAmount",
      Header: "Discount Amount",
      accessor: (tc) => Utilities.toDollar(tc.discountAmount)
    },
    {
      id: "taxPercent",
      Header: "Tax %",
      accessor: (tc) => tc.taxPercent
    },
    {
      id: "taxAmount",
      Header: "Tax Amount",
      accessor: (tc) => Utilities.toDollar(tc.taxAmount)
    },
    {
      id: "finalAmount",
      Header: "Final Amount",
      accessor: (tc) => Utilities.toDollar(tc.finalAmount),
      Footer: info => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
            () =>
                info.rows.reduce((sum, row) => row.original.finalAmount + sum, 0),
            [info.rows]
        )

        return <>{Utilities.toDollar(total)}</>
    },
    },

  ]

  const getInvoiceReports = () => {
    setIsLoader(true)
    setInitialMessage(false);
    let reqObj = {
      StartDate: moment(startDate).toISOString(),
      EndDate: moment(endDate).toISOString(),
      // PracticeLocationId: locationId
    }
    if (locationId) {
      reqObj.PracticeLocationId = locationId
    }
    InvoiceService.getInvoiceReport(reqObj)
      .then(data => {
        //console.log({ data })
        setInvoiceDetails(data)
        return setIsLoader(false)
      })
  }

  return (
    <>
    {isLoader &&
        <DimLoader loadMessage="Processing" />
    }    

    <div className='row d-flex g-3'>
      <div className='col-12 m-p-0'>
        <Module title="Filters">
          <div className='row d-flex'>
            <div className='col-12 row d-flex justify-content-center mx-0 align-items-end report-filters'>
            <DatePicker setLocationId={setLocationId} startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate}/>

              <div className="col-md-auto col-12">
                <button className="btn btn-primary" onClick={e => { e.preventDefault(); getInvoiceReports() }} title="Pull Report">
                  <i className="icon arrow circle right" />
                </button>
              </div>

            </div>
          </div>
        </Module>
      </div>
      <div className='col-12 m-p-0'>
        <Module title="Invoice Report">
          <Table2 export searchable title="Invoice Report" data={invoiceDetails} columns={columns} loading={isLoader} initialMessage={initialMessage}/>
        </Module>
      </div>
    </div>
    </>
  )
}

export default InvoiceDetails