import React, { useState } from 'react';
import APIResponse from '../../../../templates/components/APIResponse'
import DimLoader from '../../../../templates/components/DimLoader'
import FormValidatorService from '../../../../../services/validator/form-validator.service';
import ValidationConstant from '../../../../../services/validator/validation.constant';
import PlFormsService from '../../../../../services/api/plforms.service';
const SendPublicFormEmail = (props) => {
  const [isLoader, setIsLoader] = useState(false)
  const [apiResponse, setApiResponse] = useState()
  const [errors, setErrors] = useState({})
  const required = ['email']
  const [formData, setFormData] = useState({});
  const SendPublicFormEmailConfig = {
    config: {
      'Email': {
        required: { name: ValidationConstant.patient.add.email.name },
        maxlength: {
          name: ValidationConstant.patient.add.email.name,
          max: ValidationConstant.patient.add.email.maxLength.toString()
        },
        minlength: {
          name: ValidationConstant.patient.add.email.name,
          min: ValidationConstant.patient.add.email.minLength.toString()
        },
        pattern: {
          name: ValidationConstant.patient.add.email.name,
          regex: new RegExp(ValidationConstant.email_regex)
        }
      },
    }
  }
  const onSuccess = (message) => {
    setFormData({})
    // if (props.refresh) {
    //   props.refresh()
    // }
    // if (props.onClose) {
    //   props.onClose()
    // }
    // if (props.exitHandler) {
    //   props.exitHandler()
    // }
  }

  // function for input change
  const handleInputChange = (e) => {
    let newStateObject = { ...formData };
    newStateObject[e.target.name] = e.target.value;
    setErrors(FormValidatorService.setErrors(e, errors, SendPublicFormEmailConfig.config))
    return setFormData(newStateObject);
  };

  const sendEmail = () => {
    setIsLoader(true);
    let reqObj = {
      formId: props.form.id,
      emailId: formData.email,
    };
    PlFormsService.sendEmail(reqObj).then(res => {
      setApiResponse(res)
    })
      .catch(err => {
        setApiResponse(err)
      }).finally(() => {
        setIsLoader(false)
      })
  }

  return (
    <>
      {isLoader &&
        <DimLoader loadMessage="Sending" />
      }
      <div className=" row d-flex">
        <div
          className="col-12 required field"
        >
          <label>Email</label>
          <input
            placeholder="Email"
            type="email"
            name="email"
            className="form-control"
            value={formData.email?formData.email : ''}
            onChange={(e) => {
              e.preventDefault();
              handleInputChange(e);
            }}
          />
          <span className="error-message">{errors.email}</span>
        </div>
      </div>
      <div className='mt-3 d-flex justify-content-between modal-button-bar'>
        <div className='col-auto'>
          <button className="btn btn-primary" onClick={(e) => {
            e.preventDefault();
            sendEmail()
          }}
            disabled={!formData?.email || errors.email}
          >Send</button>
        </div>
        <div className='col-auto'>
          <button className="btn btn-secondary" onClick={(e) => { e.preventDefault(); if (props.onClose()) { props.onClose() } }}> Cancel </button>
        </div>
      </div>
      <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
    </>
  )
}
export default SendPublicFormEmail