import React, { useEffect, useState, useContext } from 'react'
import { Dropdown } from 'semantic-ui-react'
import moment from 'moment'
import Utilities from '../../../../../../../../services/commonservice/utilities'
import ModalBox from '../../../../../../../templates/components/ModalBox'
import InvoicePreview from '../../../../../invoices/invoice-preview/InvoicePreview'
import SendInvoice from '../../../../../invoices/send-invoice/SendInvoice'
import VirtualTerminalForm from '../../../../../transactions/terminals/virtual-terminal/virtual-terminal-form/VirtualTerminalForm'
import PaymentsList from '../../../../../report/paymentPlan-list/paymentPlan-card/payments-accordion/payments-list/PaymentsList'
import EditInvoice from '../../../../../invoices/edit-invoice/EditInvoice'
import InvoiceService from '../../../../../../../../services/api/invoice.service'
import CloseAndWriteOff from '../../../../../transactions/close-and-write-off/CloseAndWriteOff'
import { store } from '../../../../../../../../context/StateProvider'
import AddRecurringPlan from '../../../../../transactions/terminals/installment-terminal/AddRecurringPlan'
import { InvoiceUtility } from '../../../../../../../../services/commonservice/invoice.utility'
import { InvoiceOperation } from '../../../../../../../../services/commonservice/invoice.utility'
import APIResponse from '../../../../../../../templates/components/APIResponse'
import toast from 'react-hot-toast'

const OpenBalanceCard = (props) => {
  const [showPay, setShowPay] = useState(false)
  const [showPayPlan, setShowPayPlan] = useState(false)
  const [showSubscription, setShowSubscription] = useState(false)
  const [showInvoice, setShowInvoice] = useState(false)
  const [sendInvoice, setSendInvoice] = useState(false)
  const [showPayments, setShowPayments] = useState(false)
  const [editInvoice, setEditInvoice] = useState(false)
  const [closeWO, setCloseWO] = useState(false)
  const [cancel, setCancel] = useState(false)
  const [close, setClose] = useState(false)
  const [apiResponse, setApiResponse] = useState()
  const state = useContext(store).state
  const filters = [
    { title: "All", value: "", identifier: "all" },
    { title: "Ready To Send", value: 1, identifier: "readyToSend", color: 'lightgreen' },
    { title: "Unpaid", value: 2, identifier: "awaitingPayment", color: 'yellow' },
    { title: "Full Payment", value: 4, identifier: "fullPayment", color: 'blue' },
    { title: "Payment Plan", value: 5, identifier: "paymentPlan", color: 'blue' },
    { title: "Subscription Plan", value: 7, identifier: "subscriptionPlan", color: 'blue' },
    { title: "In Progress", value: 8, identifier: "subscriptionPlan", color: 'blue' },
    { title: "Unpaid", value: 10, identifier: "unPaid", color: 'lightgray' },
    //{ title: "Partially Paid", value: 13, identifier: "unPaid", color: 'lightgray' },
    { title: "Cancelled", value: 9, identifier: "cancelled", color: 'red' },
    { title: "Closed", value: 30, identifier: "closed" }
  ]

  const cancelHandler = () => {
    InvoiceService.deleteInvoice(props.invoice?.id)
      .then(res => {
        setApiResponse(res);
        console.log(res)
        toast.success("Invoice Canceled")
        setCancel(false)
      })
      .catch(err => {
        setApiResponse(err);
        console.log(err)
      })
  }
  
  const closeHandler = () => {
    let reqObj = { closeReason: 'Close invoice' }
    InvoiceService.closeAndWriteOff(props.invoice?.id, reqObj)
        .then(res => {
            setApiResponse(res);
            console.log(res)
            toast.success("Invoice Closed")
            setClose(false)
          })
        .catch(err => {
            setApiResponse(err);
            console.log(err)
        })
  }

  const onSuccess = (message) => {
    if (props.refresh) {
      props.refresh()
    }
    if (props.onClose) {
      props.onClose()
    }
    if (props.exitHandler) {
      props.exitHandler()
    }
  }

  return (
    <div className='card p-3'>
      <div className="row d-flex justify-content-between align-items-center mb-2">
        {props.invoice.invoiceNumber ? (
          <div className="col d-flex"><h5> <div className='d-flex row align-items-end'>
            {props.invoice?.invoiceType == 5 &&
              <div className='col-auto'><span title="Prepaid Invoice" className='text-icon'>P</span></div>
            }
            <div className='col-auto'>
              {props.invoice.invoiceNumber}
            </div>
            <div className='col-auto'><strong>
              {props.invoice?.amountPaid ? `${Utilities.toDollar(props.invoice?.finalAmount - props.invoice?.amountPaid)} of ` : null}{Utilities.toDollar(props.invoice?.finalAmount)}</strong></div>
          </div>
          </h5></div>
        ) : (
          <div className="col">Final Amount: -- | Invoice Number: --</div>
        )}
        <div className='col-auto'>
          <Dropdown
            button
            direction="left"
            icon="ellipsis horizontal"
            className="btn-primary icon btn p-o text-center"
          >
            <Dropdown.Menu>

              {
                state.permissions.paymentsModifyTransactions
              &&
                InvoiceUtility.OperationAllowed(props.invoice, InvoiceOperation.MAKE_PAYMENT)
                ? 
                  <Dropdown.Item onClick={e => { e.preventDefault(); setShowPay(true) }}>Collect Payment</Dropdown.Item> : null
              }

              {
                state.permissions.paymentsModifyInvoices
              &&
                InvoiceUtility.OperationAllowed(props.invoice, InvoiceOperation.UPDATE) ?
                  <Dropdown.Item onClick={e => { e.preventDefault(); setEditInvoice(true) }}>Edit</Dropdown.Item> : null
              }

              {
                state.permissions.paymentsModifySubscriptionsAndPlans
              &&
                InvoiceUtility.OperationAllowed(props.invoice, InvoiceOperation.SETUP_PAYMENT_PLAN) ?
                <Dropdown.Item onClick={e => { e.preventDefault(); setShowPayPlan(true) }}>Setup Payment Plan</Dropdown.Item> : null
              }

              {
                state.permissions.paymentsModifySubscriptionsAndPlans
              &&
                InvoiceUtility.OperationAllowed(props.invoice, InvoiceOperation.SETUP_SUBSCRIPTION) ?
                <Dropdown.Item onClick={e => { e.preventDefault(); setShowSubscription(true) }}>Setup Subscription</Dropdown.Item> : null
              }

              {
                state.permissions.paymentsViewInvoices
              &&
                <Dropdown.Item onClick={e => { e.preventDefault(); setShowInvoice(true) }}>View Invoice</Dropdown.Item>
              }

              {
                state.permissions.paymentsViewSubscriptionsAndPlans
              &&
                InvoiceUtility.OperationAllowed(props.invoice, InvoiceOperation.MANAGE_PAYMENTS) ?
                <Dropdown.Item onClick={e => { e.preventDefault(); setShowPayments(true) }}>Manage Payments</Dropdown.Item> : null
              }

              {
                state.permissions.patientModifyCommunications
              &&
                InvoiceUtility.OperationAllowed(props.invoice, InvoiceOperation.SEND_TO_PATIENT) &&
                <Dropdown.Item onClick={e => { e.preventDefault(); setSendInvoice(true) }}>Resend</Dropdown.Item>
              }

              {
                state.permissions.paymentsModifyInvoices
              &&
                InvoiceUtility.OperationAllowed(props.invoice, InvoiceOperation.CANCEL) &&
                <Dropdown.Item onClick={e => { e.preventDefault(); setCancel(true) }}>Cancel</Dropdown.Item>
              }

              {
                state.permissions.paymentsModifyInvoices
              &&
                InvoiceUtility.OperationAllowed(props.invoice, InvoiceOperation.CLOSE) &&
                <Dropdown.Item onClick={e => { e.preventDefault(); setClose(true) }}>Close</Dropdown.Item>
              }

              {
                state.permissions.paymentsModifyInvoices
              &&
                InvoiceUtility.OperationAllowed(props.invoice, InvoiceOperation.CLOSE_WRITEOFF) &&
                <Dropdown.Item onClick={e => { e.preventDefault(); setCloseWO(true) }}>Close and Writeoff</Dropdown.Item>
              }

            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {props.invoice && <div className="row d-flex">
        {props.invoice.invoiceStatus &&
          <span title={InvoiceUtility.GetInvoiceStatusDescription(props.invoice.invoiceStatus)}>
            <strong className='w-150px'>Status</strong>
            {InvoiceUtility.GetInvoiceStatusDescription(props.invoice.invoiceStatus)}
          </span>
        }
        {props.invoice.inProcessAmount && props.invoice.inProcessAmount > 0 ?
          <span>
            <strong className='w-150px'>Processing:</strong>
            {Utilities.toDollar(props.invoice.inProcessAmount)}
          </span> : null
        }
        <div className='col-12'>
          <strong className='w-150px'>Invoice Date:</strong>
          {props.invoice.createdOn ? moment(props.invoice.createdOn).format("MM/DD/YYYY") : '--'}
        </div>
        {/* <div className='col-12'>
          <strong className='w-150px'>Service Date:</strong>
          {props.invoice.serviceDate ? moment(props.invoice.serviceDate).format("MM/DD/YYYY") : '--'}
        </div> */}
        <div className='col-12'>
          <strong className='w-150px'>Due Date:</strong>
          {props.invoice.dueDate ? moment(props.invoice.dueDate).format("MM/DD/YYYY") : '--'}
        </div>
        <div className='col-12'>
          <strong className='w-150px'>Provider Name:</strong>
          {props.invoice.doctorName ? props.invoice.doctorName : '--'}
        </div>
        <div className='col-12'>
          <strong className='w-150px'>Location:</strong>
          {props.invoice.practiceLocationId ? state.practiceLocations?.find(obj => obj.practiceLocationId == props.invoice.practiceLocationId)?.practiceLocation : '--'}
        </div>
      </div>}
      <ModalBox open={showInvoice} onClose={() => setShowInvoice(false)} title="Invoice Preview">
        <InvoicePreview invoiceId={props.invoice.id} onClose={() => setShowInvoice(false)} />
      </ModalBox>
      <ModalBox open={showPay} onClose={() => { setShowPay(false) }} title="Collect a Payment">
        <VirtualTerminalForm initialData={props.invoice} onClose={() => { props.refresh(); return setShowPay(false) }} />
      </ModalBox>
      <ModalBox open={showPayPlan} onClose={() => setShowPayPlan(false)} title="Create a Payment Plan">
        <AddRecurringPlan transactionType={1} initialData={props.invoice} onClose={() => { props.refresh(); return setShowPayPlan(false) }} />
      </ModalBox>
      <ModalBox open={showSubscription} onClose={() => setShowSubscription(false)} title="Create a Subscription">
        <AddRecurringPlan transactionType={3} initialData={props.invoice} onClose={() => { props.refresh(); return setShowSubscription(false) }} />
      </ModalBox>
      <ModalBox open={sendInvoice} onClose={() => { setSendInvoice(false) }} title="Send Invoice">
        <SendInvoice patientId={props.invoice.patientId} invoiceId={props.invoice.id} transaction={props.invoice} onClose={() => { setSendInvoice(false) }} />
      </ModalBox>
      <ModalBox open={showPayments} onClose={() => { setShowPayments(false) }} title="Modify Payment">
        <PaymentsList invoiceId={props.invoice.id} pull={showPayments} paymentPlanId={props.invoice.paymentId} />
      </ModalBox>
      <ModalBox open={editInvoice} onClose={() => { setEditInvoice(false) }} title="Edit Invoice" size="fullscreen">
        <EditInvoice id={props.invoice.id} initialData={props.invoice} onClose={() => { props.refresh(); return setEditInvoice(false) }} />
      </ModalBox>
      <ModalBox open={cancel} onClose={() => { setCancel(false) }} size="tiny" title="Cancel Invoice">
        <div className='col-12'>
          Are you sure you want to cancel this invoice?
        </div>
        <div className='col-12 d-flex justify-content-between mt-3'>
          <div className='col-auto'>
            <button className='btn btn-primary' onClick={(e) => { e.preventDefault(); cancelHandler(); return setCancel(false) }}>Cancel Invoice</button>
          </div>
          <div className="col-auto">
            <button className='btn btn-secondary' onClick={e => { e.preventDefault(); setCancel(false) }}>Close</button>
          </div>
        </div>
      </ModalBox>

      <ModalBox open={closeWO} onClose={() => { setCloseWO(false) }} title="Close and Write Off">
        <CloseAndWriteOff refresh={() => { props.refresh() }} invoiceId={props.invoice.id} onClose={() => { setCloseWO(false); if (props.refresh()) { props.refresh() } }} />
      </ModalBox>

      <ModalBox open={close} onClose={() => { setClose(false) }} size="tiny" title="Cancel Invoice">
        <div className='col-12'>
            Are you sure you want to close this invoice?
        </div>
        <div className='col-12 d-flex justify-content-between mt-3'>
            <div className='col-auto'>
                <button className='btn btn-primary' onClick={(e) => { e.preventDefault(); closeHandler(); return setClose(false) }}>Yes</button>
            </div>
            <div className="col-auto">
                <button className='btn btn-secondary' onClick={e => { e.preventDefault(); setClose(false) }}>No</button>
            </div>
        </div>
      </ModalBox>      

      <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />

    </div>
  )
}

export default OpenBalanceCard