import React, { useEffect, useState, useContext, useMemo } from 'react'
import Select from 'react-select'
import AppointmentService from '../../../../../services/api/appointment.service'
import { store } from "../../../../../context/StateProvider";
import { useGetAppointmentStatusCodes } from "../../../../../services/cache/CacheService";

const AppointmentStatus = (props) => {
    const [isLoader, setIsLoader] = useState(false)
    const statusList = useGetAppointmentStatusCodes();
    const [selectedStatus, setSelectedStatus] = useState()

    const selectStatus = (status) => {
        setSelectedStatus(status)
    }

    const updateStatus = () => {
        setIsLoader(true)
        AppointmentService.updateAppintmentStatus({"practiceAppointmentStatusCodeId":selectedStatus.practiceAppointmentStatusCodeId}, props.appointmentId)
        .then(
            (response) => {
                props.onStatusUpdated(selectedStatus)
                setIsLoader(false)
            })
        .catch(
            (error) => {
                setIsLoader(false)
            }
        );
    }

    useEffect(() => {
        if (statusList) {
            statusList.map((status,i) => {
                if (status.practiceAppointmentStatusCodeId == props.appointment.practiceAppointmentStatusCodeId) selectStatus(status);
            });
        }
    }, [statusList])

    return (
        <>
            {isLoader && (
            <div className="ui active dimmer">
                <div className="ui indeterminate text loader">Loading...</div>
            </div>
            )}

            {statusList && (
                <div className='row d-flex align-items-end justify-content-between'>
                    <div className='col'>
                        <div style={{display: "flex", height: '70px', alignItems: 'stretch', justifyContent:'space-between'}}>
                            {
                                statusList.map((status) => (
                                    <>
                                        {props.appointment.parentGroupId && status.isCheckout ? null : 
                                        <i  style={{fontSize: selectedStatus && status.practiceAppointmentStatusCodeId == selectedStatus.practiceAppointmentStatusCodeId ? 'xxx-large' : 'xx-large', alignSelf:"center"}} 
                                            onClick={(e) => { 
                                                e.preventDefault();
                                                selectStatus(status); 
                                            }} 
                                            className={`ui icon ${status.icon} btn p-0 mb-2 apptStatusIcon`}
                                            title={status.appointmentStatusLabel}
                                        /> 
                                        }
                                    </>
                                ))
                            }
                        </div>
                    </div>
                    <div className="col-auto">
                        {props.extraButton && props.extraButton}
                        <button disabled={!selectedStatus || props.appointment.practiceAppointmentStatusCodeId==selectedStatus.practiceAppointmentStatusCodeId} className='btn btn-primary ms-3 col-auto' onClick={e => { e.preventDefault(); updateStatus() }}>{selectedStatus ? selectedStatus.appointmentStatusLabel : "Status Not Selected"}</button>
                        <button className='btn btn-secondary ms-3 col-auto' onClick={e => { e.preventDefault(); props.onClose() }}>{'Cancel'}</button>
                    </div>
                </div>
            )}
        </>
    )
}
export default AppointmentStatus