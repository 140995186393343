import React, { useState, useEffect } from 'react'
import label from '../../../../../../assets/i18n/en.json'
import FormValidatorService from '../../../../../services/validator/form-validator.service'
import toast from 'react-hot-toast'
import ValidationConstant from '../../../../../services/validator/validation.constant'

const PracticeLocationForm = (props) => {
    const { initialFormData, submitHandler } = props
    const [formData, setFormData] = useState(props.initialFormData || {})
    const required = [
        "practiceLocation"
    ];
    const [errors, setErrors] = useState({})
    const [isTwoWayEnabled, setIsTwoWayEnabled] = useState(props.initialFormData ? props.initialFormData.isTwoWayEnabled : false);
    const [ignoreDst, setIgnoreDst] = useState(props.initialFormData ? props.initialFormData.ignoreDst : false);

    const PracticeLocationConfig = {
        config: {
            'practiceLocation': {
                required: { name: ValidationConstant.location.add.practiceLocation.name },
            },

        },
        'practiceLocation': ['', []]
    }
    const handleIsTwoWayEnabled = (e) => {
        setIsTwoWayEnabled(!isTwoWayEnabled);
    };
    const handleIgnoreDst = (e) => {
        setIgnoreDst(!ignoreDst);
    };

    const inputChange = (e) => {
        let newStateObject = { ...formData };
        console.log(e)
        if (e.target.type === "number") {
            newStateObject[e.target.name] = parseFloat(e.target.value)
        }else if(e.target.name === "isTwoWayEnabled" || e.target.name === "ignoreDst" ){
            newStateObject[e.target.name] = e.target.value ? 1 : 0
        }
        else {
        newStateObject[e.target.name] = e.target.value
        }
        setErrors(FormValidatorService.setErrors(e, errors, PracticeLocationConfig.config))
        setFormData(newStateObject);
    }

    const submitHandlerCatch = (data) => {
        if (!FormValidatorService.checkForm(errors, data, required)) {
            toast.error("Please make sure the form is complete")
        }
        else {
            props.submitHandler(data)
        }
    }
    return (
        <div className="ui container">
            <div className="row d-flex ">
                <div className="field required col-12">
                    <label>Location</label>
                    <input
                        placeholder='Practice Location'
                        type='text'
                        name="practiceLocation"
                        value={formData.practiceLocation}
                        onChange={(e) => inputChange(e)}
                        required
                    />
                </div>
                {/* <div className="col-md-6 col-12 field">
                    <label>Merchant User Name</label>
                    <input
                        placeholder="User Name"
                        type="text"
                        name="merchantUserName"
                        value={formData.merchantUserName}
                        onChange={(e) => {
                            e.preventDefault();
                            inputChange(e);
                        }}
                    />
                </div> */}
                <div className="col-md-6 col-12 field">
                    <label>Timezone</label>
                    <input
                        placeholder="Timezone"
                        type="number"
                        name="timeZoneInfo"
                        value={formData.timeZoneInfo}
                        onChange={(e) => {
                            e.preventDefault();
                            inputChange(e);
                        }}
                    />
                </div>
                <div className="col-md-6 col-12 field">
                    <label>Country Code</label>
                    <input
                        placeholder="Country Code"
                        type="text"
                        name="countryCode"
                        value={formData.countryCode}
                        onChange={(e) => {
                            e.preventDefault();
                            inputChange(e);
                        }}
                    />
                </div>
                <div className="col-md-6 col-12 field">
                    <label>IoT Endpoint</label>
                    <input
                        placeholder="IoT Endpoint"
                        type="text"
                        name="iotEndpoint"
                        value={formData.iotEndpoint}
                        onChange={(e) => {
                            e.preventDefault();
                            inputChange(e);
                        }}
                    />
                </div>
                <div className="col-md-6 col-12 field">
                    <label>Logo</label>
                    <input
                        placeholder="Logo"
                        type="text"
                        name="logo"
                        value={formData.logo}
                        onChange={(e) => {
                            e.preventDefault();
                            inputChange(e);
                        }}
                    />
                </div>
                <div className="col-md-6 col-12 field">
                    <label>SID</label>
                    <input
                        placeholder="SID"
                        type="text"
                        name="messagingServiceSid"
                        value={formData.messagingServiceSid}
                        onChange={(e) => {
                            e.preventDefault();
                            inputChange(e);
                        }}
                    />
                </div>
                <div className="col-md-6 col-12 field">
                    <label>Off Hours Sms Message</label>
                    <input
                        placeholder="Off Hours Sms Message"
                        type="text"
                        name="offHoursSmsMessage"
                        value={formData.offHoursSmsMessage}
                        onChange={(e) => {
                            e.preventDefault();
                            inputChange(e);
                        }}
                    />
                </div>
                <div className="col-md-6 col-12 field">
                    <label>Twilio Account SID</label>
                    <input
                        placeholder="Twilio Account SID"
                        type="text"
                        name="twilioAccountSid"
                        value={formData.twilioAccountSid}
                        onChange={(e) => {
                            e.preventDefault();
                            inputChange(e);
                        }}
                    />
                </div>
                <div className="col-md-6 col-12 field">
                    <label>Twilio Auth Token</label>
                    <input
                        placeholder="Twilio Auth Token"
                        type="text"
                        name="twilioAuthToken"
                        value={formData.twilioAuthToken}
                        onChange={(e) => {
                            e.preventDefault();
                            inputChange(e);
                        }}
                    />
                </div>

                <div className="col-md-6 col-12 field">
                    <label>Twilio From Number</label>
                    <input
                        placeholder="Twilio From Number"
                        type="text"
                        name="twilioFromNumber"
                        value={formData.twilioFromNumber}
                        onChange={(e) => {
                            e.preventDefault();
                            inputChange(e);
                        }}
                    />
                </div>
                <div className="col-md-6 col-12 field">
                    <label>TwoWay Not Enabled Sms Message</label>
                    <input
                        placeholder="Message"
                        type="text"
                        name="twoWayNotEnabledSmsMessage"
                        value={formData.twoWayNotEnabledSmsMessage}
                        onChange={(e) => {
                            e.preventDefault();
                            inputChange(e);
                        }}
                    />
                </div>
                <div className="col-md-6 col-12 field">
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name="isTwoWayEnabled"
                            checked={isTwoWayEnabled}
                            onInputCapture={(e) => {
                                handleIsTwoWayEnabled(e.target.checked);
                                inputChange({
                                    target: {
                                        value: !isTwoWayEnabled,
                                        name: "isTwoWayEnabled",
                                    },
                                });
                            }}
                        />
                        <label className="form-check-label">
                            TwoWay SMS
                        </label>
                    </div>
                </div>
                <div className="col-md-6 col-12 field">
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name="ignoreDst"
                            checked={ignoreDst}
                            onInputCapture={(e) => {
                                handleIgnoreDst(e.target.checked);
                                inputChange({
                                    target: {
                                        value: !ignoreDst,
                                        name: "ignoreDst",
                                    },
                                });
                            }}
                        />
                        <label className="form-check-label">
                            Ignore Day Light's Saving
                        </label>
                    </div>
                </div>
            </div>

            <div className='mt-3 d-flex justify-content-between'>

                <div className='col-auto'>
                    <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); submitHandlerCatch(formData) }}>{label.user.add.save}</button>
                </div>
                <div className='col-auto'>
                    <button className="btn btn-secondary float-right" onClick={(e) => { e.preventDefault(); if (props.onClose) { props.onClose() } }}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default PracticeLocationForm