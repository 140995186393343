import React, { useState, useEffect } from 'react'
import PatientAccountService from '../../../../../services/api/patient-account.service'
import PatientAccountForm from '../patient-account-form/PatientAccountForm'
import APIResponse from '../../../../templates/components/APIResponse'
import DimLoader from '../../../../templates/components/DimLoader'
const PatientAccountEdit = (props) => {

    const [apiResponse, setApiResponse] = useState()
    const [isSubmitted, setIsSubmitted] = useState(false)
    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }
    const submitHandler = (type, data) => {
        setIsSubmitted(true)
        PatientAccountService.editPatientAccount(
            {
                "address": {
                    "addressLine1": data.address.addressLine1,
                    "addressLine2": data.address?.addressLine2 || "",
                    "city": data.address.city,
                    "state": data.address.state,
                    "country": 1,
                    "postalCode": data.address.zipCode || data.address.postalCode
                },
                "samePatientAddress": data.samePatientAddress === 1 ? true : false || false,
                "accountHolderName": data.accountHolderName,
                "cardNumber": data.cardNumber,
                "cardExpiry": data?.cardExpiry?.toString().includes("/") ? data?.cardExpiry?.toString().replace("/", "") : data?.cardExpiry.toString(),
                "cardType": data.cardType,
                "accountType": data.accountType,
                "isActive": true,
                "practiceLocationId": data.practiceLocationId
            }
            , props.patientId, props.account.id)
            .then(res => {
                setApiResponse(res)
            })
            .catch(err => {
                setApiResponse(err)
            })
            .finally(() => {
                setIsSubmitted(false)
            })

    }
    return (
        <>
            {isSubmitted ? <DimLoader loadMessage="Updating Account..." /> : null}
            <PatientAccountForm isEdit initialData={{ ...props.account, patientId: props.patientId }} onClose={() => props.onClose()} buttonLabel="Update" disableLocationEdit={true} submitHandler={submitHandler} />
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </>

    )
}

export default PatientAccountEdit