import axios from 'axios'
import AppSetting from '../../common/constants/appsetting.constant'
import CommonService from './common.service'
const PaymentOptionsService = {
    getPaymentOptions(reqObj) {
        let loggedInUserData = CommonService.getLoggedInData()
        let url = AppSetting.paymentOptions.get.replace('{parentId}', loggedInUserData.parentId)
        if (reqObj) {
            url = `${url}${CommonService.buildQuery(reqObj)}`
        }
        return axios.get(url)
            .then(res => {
                return res
            })
            .catch(err => {
                return err
            })
    },
    paymentOptionsLookup() {
        let loggedInUserData = CommonService.getLoggedInData()
        let url = AppSetting.paymentOptions.lookup.replace('{parentId}', loggedInUserData.parentId)
        return axios.get(url)
            .then(res => {
                return res
            })
            .catch(err => {
                return err
            })
    },
    activate(id) {
        let loggedInUserData = CommonService.getLoggedInData();
        let url;
    
        url = AppSetting.paymentOptions.activateDeactivate
          .replace('{parentId}', loggedInUserData.parentId)
          .replace('{paymentoptionId}', id);
    
        return axios.post(url, {})
          .then(res => { return res })
          .catch(err => {return err})
      },
    
      deactivate(id) {
        let loggedInUserData = CommonService.getLoggedInData();
        let url;
        url = AppSetting.paymentOptions.activateDeactivate
          .replace('{parentId}', loggedInUserData.parentId)
          .replace('{paymentoptionId}', id);
    
        return axios.delete(url)
          .then(res => { return res })
          .catch(err => { return err })
      },
      addPaymentOption(reqObj) {
        let loggedInUserData = CommonService.getLoggedInData();
        let url = AppSetting.paymentOptions.add
          .replace('{parentId}', loggedInUserData.parentId);
        return axios.post(url, reqObj)
          .then(a => { return a })
          .catch(err => { return err })
      },
    
      editPaymentOption(reqObj, id) {
        let loggedInUserData = CommonService.getLoggedInData();
        let url = '';
        url = AppSetting.paymentOptions.edit
          .replace('{parentId}', loggedInUserData.parentId)
          .replace('{paymentoptionId}', id);
        return axios.put(url, reqObj)
          .then(a => { return a })
          .catch(err => { return err })
      },
    
}
export default PaymentOptionsService