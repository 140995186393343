import moment from 'moment'
import React, { useEffect, useState, useCallback,useContext } from 'react'
import CommonService from '../../../../../services/api/common.service'
import Utilities from '../../../../../services/commonservice/utilities'
import AsyncSelect from 'react-select/async';
import PatientCard from './patient-card/PatientCard'
import { debounce } from 'lodash'
import PatientService from '../../../../../services/api/patient.service'
import DimLoader from '../../../../templates/components/DimLoader'
import PlFormsService from '../../../../../services/api/plforms.service'
import InvoiceService from '../../../../../services/api/invoice.service'
import ModalBox from '../../../../templates/components/ModalBox'
import AddPatient from '../add-patient/AddPatient'
import CreditBalanceService from '../../../../../services/api/credit-balance.service'
import { store } from '../../../../../context/StateProvider'
import PaginationTemplate from '../../../../templates/components/PaginationTemplate'

const PatientList = (props) => {
    const [patientList, setPatientList] = useState([])
    const [keyword, setKeyword] = useState(null)
    const [isLoader, setIsLoader] = useState(false)
    const [isLoader_Forms, setIsLoader_Forms] = useState(false)
    const [isLoader_Balance, setIsLoader_Balance] = useState(false)
    const [forms, setForms] = useState()
    const [balances, setBalances] = useState()
    const [patientTags, setPatientTags] = useState()
    const [showAdd, setShowAdd] = useState(false)
    const [allLocations, setAllLocations] = useState(true)
    const [credits, setCredits] = useState()
    const globalStateAndDispatch = useContext(store);
    const contextState = globalStateAndDispatch.state;
    const [pageCount, setPageCount] = useState()
    const [startRow, setStartRow] = useState()
    useEffect(() => {
        patientLookup()
      }, [allLocations, contextState.practiceLocationId,startRow])
    
      const onPageChange = (data) => {
        setStartRow(CommonService.calculatePageStartRow(data?.selected, 10))
      }
    const patientLookup = (keyword) => {  
        setForms()
        setIsLoader(true)
        if (keyword) {
            PatientService.getPatientById(keyword)
                .then(res => {
                    setPageCount(1)
                    setPatientList([res.data])
                    let patientIds = res.data.id
                    prepaidRetrieve(patientIds)
                    formRetrieve(patientIds)
                    pullInvoiceBalances(patientIds)
                    getPatientLookupTags(patientIds)
                    return setIsLoader(false)
                })
        }
        else {
            let reqObj = 
            { 
                SearchTerm: '', 
                isActive: true, 
                isRegistered: true, 
                StartRow: startRow || 0,
                PageSize: 10, 
                SortField: 'firstName', 
                'PracticeLocationId': contextState.practiceLocationId
            }
            if (allLocations) delete reqObj.PracticeLocationId;
            CommonService.patientLookup(reqObj,true)
                .then(res => {
                    if (res?.data?.data) {
                        setPageCount(Math.ceil(res.data.totalRowCount/10))
                        setPatientList(res?.data?.data)
                        let patientIds = res?.data?.data?.map(obj => { return obj.id })
                        formRetrieve(patientIds)
                        pullInvoiceBalances(patientIds)
                        prepaidRetrieve(patientIds)
                        getPatientLookupTags(patientIds)
                    }
                    return setIsLoader(false)
                })
                .catch(err => {
                    console.log(err);
                    setIsLoader(true)
                })
        }
    }
    const getPatientLookupTags = (patientIds) => {
        let reqObj = {
            PatientIds: patientIds
        }
        PatientService.getPatientLookupTags(reqObj)
          .then((response) => {
            if (response?.data?.length > 0) {
                setPatientTags(response.data)
            }
          })
      }
    const prepaidRetrieve = (patientIds) => {
        let reqObj = {
            PatientIds: patientIds
        }
        CreditBalanceService.lookupCreditBalance(reqObj)
            .then(res => {
                //console.log(res)
                setCredits(res.data)
            })
    }
    const formRetrieve = (patientIds) => {
        // console.log(patientIds)
        let reqObj = {
            PatientIds: patientIds
        }
        PlFormsService.getMapFormsWithPatient(reqObj)
            .then(res => {
                // console.log(res.data)
                if (res?.data?.length > 0) {
                    setForms(res.data)
                }
            })
    }

    const refreshCard = (index, patientId) => {
        PatientService.getPatientById(patientId)
            .then(res => {
                let newList = patientList
                newList[index] = res.data
                formRetrieve(patientId)
                pullInvoiceBalances(patientId)
                prepaidRetrieve(patientId)
                getPatientLookupTags(patientId)
                return setPatientList(newList)
            })
    }
    const pullInvoiceBalances = (patientIds) => {
        let reqObj = {
            sortField: 'dueDate',
            Asc: 'false',
            PatientIds: patientIds,
            StartRow: 0,
            InvoiceStatuses: '1,2,4,5,6,8,10',
        }
        InvoiceService.findInvoice(reqObj)
            .then((res) => {
                if (res?.data?.data?.length) {
                    if (Array.isArray(res?.data?.data) === true) {
                        setBalances(res?.data?.data.sort((a, b) => b.dueDate.localeCompare(a.dueDate)))
                    }
                    else {
                        setBalances([res?.data?.data]);
                    }
                }
                else {
                    setBalances();
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const rePatientLookup = useCallback(debounce(patientLookup, 500), [])
    useEffect(() => {
        if (keyword !== null) {
            rePatientLookup(keyword)
        }
    }, [keyword])

    const patientSearch = (inputText, callBack) => {
        if (inputText?.length < 3) return;
        let reqObj = { 
            SearchTerm: inputText, 
            isActive: true, 
            isRegistered: true, 
            SortField: 'firstName', 
            StartRow: startRow || 0,
            PageSize: 10, 
            Asc: true }
                
        CommonService.patientLookup(reqObj,true)
            .then(res => {
                if (res) {
                    setPageCount(Math.ceil(res.data?.totalRowCount/10))
                    callBack(res.data?.data)
                }
            }
            )
            .catch(err => console.log(err))
    }
    const rePatientSearch = useCallback(debounce(patientSearch, 500), [])

    return (
        <div className=''>
            <div className='d-flex m-3 row patient-search'>
                <div className='field'>
                    <div className='form-check'>
                        <input type="checkbox" className='form-check-input' checked={allLocations} name="allLocations" onChange={e => { setAllLocations(e.target.checked) }} />
                        <label className='form-check-label'>All Locations</label>
                    </div>
                </div>
                <div title="Patient" eventKey="patientId">
                    <div className='field'>
                        <label>Search by Patient</label>
                        <div className="input-group mx-0">
                            <AsyncSelect
                                classNamePrefix="react-select"
                                className="react-select-container"
                                name="patientId"
                                loadOptions={rePatientSearch}
                                placeholder="Search Patient"
                                onChange={e => {
                                    console.log(e)
                                    if (e?.id) {
                                        setKeyword(e.id)
                                    }
                                    else {
                                        setKeyword()
                                    }
                                }}
                                isClearable={true}
                                getOptionLabel={(option) => {
                                    return (
                                        <div className="d-flex row">
                                            <span className="col">{option.firstName} {option.lastName}</span>
                                            <span className='col text-start'><i className='icon birthday cake' />{moment.utc(option.dob).format("M/D/YYYY").toString()}</span>
                                            <span className='col text-end'><i className='icon phone' />{option.mobile && Utilities.toPhoneNumber(option.mobile) || option.patientPhone && Utilities.toPhoneNumber(option.patientPhone)}</span>
                                        </div>
                                    )
                                }}
                                getOptionValue={(option) => option.id}
                                noOptionsMessage={(e) => { if (keyword?.length > 3) { return <button className='btn btn-primary form-control' onClick={e => { e.preventDefault(); setShowAdd(true) }}>Add Patient</button> } else { return 'Begin typing to search patients...' } }}
                            />
                            <button className='btn btn-primary' title="Add Patient" onClick={e => { e.preventDefault(); setShowAdd(true) }}><i className='icon plus' /></button>
                        </div>
                        {/* <div className="col-auto">
                                <button className='btn btn-primary form-control' title="Add Patient" onClick={e => { e.preventDefault(); setShowAdd(true) }}><i className='icon plus' /></button>
                            </div> */}
                    </div>
                </div>
            </div>
            <div className='m-3 row d-flex patient-list'>
                {isLoader || isLoader_Balance || isLoader_Forms ? <DimLoader /> : null}
                {patientList && !isLoader && !isLoader_Forms && !isLoader_Balance ? patientList.map((patient, i) => {
                    return (
                        <div className="patient-card-wrap" onDoubleClick={e => { e.preventDefault(); refreshCard(i, patient.id) }}>
                            <PatientCard
                                patientTags= {patientTags?.find(obj => obj.patientId === patient.id) ? patientTags?.filter(obj => obj.patientId === patient.id) : null}
                                balance={balances?.find(obj => obj.patientId === patient.id) ? balances?.filter(obj => obj.patientId === patient.id) : null}
                                creditBalance={credits?.find(obj => obj.patientId === patient.id) ? credits?.filter(obj => obj.patientId === patient.id) : null}
                                forms={forms?.find(obj => obj.patientId === patient.id) ? forms?.filter(obj => obj.patientId === patient.id) : null}
                                id={patient.id}
                                patient={patient}
                                keyword={keyword}
                                key={i}
                                index={i}
                                refresh={() => refreshCard(i, patient.id)} />
                        </div>

                    )
                }) : null}
                {Array.isArray(patientList) && patientList.length > 0 &&
                    <PaginationTemplate onPageChange={onPageChange} pageCount={pageCount} />
                }
            </div>
            <ModalBox open={showAdd} onClose={() => { setShowAdd(false) }} title="Add Patient">
                <AddPatient isModal onClose={() => { setShowAdd(false) }} />
            </ModalBox>
        </div>
    )
}

export default PatientList