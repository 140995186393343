import React, { useState } from "react";
import label from "../../../../../../assets/i18n/en.json";
import PatientService from "../../../../../services/api/patient.service";
import PatientForm from "../patient-form/PatientForm";
import moment from 'moment'
import APIResponse from '../../../../templates/components/APIResponse';
import DimLoader from "../../../../templates/components/DimLoader";
import LeadSourceService from "../../../../../services/api/lead-source.service";

var moment2 = require("moment-timezone")
const AddPatient = (props) => {
  const { embed, patients } = props;
  const [formData, setFormData] = useState({
    firstName: props.isConverted ? props.initialData.firstName : undefined,
    lastName: props.isConverted ? props.initialData.lastName : undefined,
    phone: props.isConverted ? props.initialData.mobile : undefined,
    email: props.isConverted ? props.initialData.email : undefined,
    leadId: props.isConverted ? props.initialData.id : undefined,
    isOptIn: true,
    checkEligibility: false,
    isInsured: false,
    address: {},
    dob: null
  });
  const [errors, setErrors] = useState();
  const [apiResponse, setApiResponse] = useState()
  const [isSubmitted, setIsSubmitted] = useState(false)

  const onSuccess = (message) => {
    if (props.isConverted) {
      updateLeadStatus()
    }
    else {
      if (props.refresh) {
        props.refresh()
      }
      if (props.onClose) {
        props.onClose()
      }
      if (props.exitHandler) {
        props.exitHandler()
      }
    }
    setFormData(clearTemplate)
  }

  const clearTemplate =
  {
    "firstName": '',
    "lastName": '',
    "mrn": '',
    "ssn": '',
    "dob": '',
    "phone": '',
    "isOptIn": false,
    "isOptInChanged": false,
    "email": '',
    "patientTypeId": "",
    "leadSourceId":"",
    "referredById": "",
    "referredByUserTypeId": "",
    "preferredPracticeLocationId" : "",
    "timeZone": '',
    "address": {
      "addressLine1": '',
      "addressLine2": '',
      "city": '',
      "state": "",
      "country": '',
      "postalCode": ''
    },
    "isInsured": false,
    "checkEligibility": false,
    "doctorId": "",
    'patientTags':''
  }
  const submitHandler = (data) => {
    setIsSubmitted(true)
    return PatientService.addPatient(
      {
        "firstName": data.firstName || '',
        "lastName": data.lastName || '',
        "mrn": data.mrn || '',
        "ssn": data.ssn || '',
        "dob": moment(data.dob).format("YYYY-MM-DD") || '',
        "phone": data?.phone?.includes("(") ? data.phone.replace("(", "").replace(")", "").replace("-", "").replace(" ", "") : data.phone,
        "isOptIn": data.isOptIn || false,
        "isOptInChanged": data.isOptInChanged || false,
        "email": data.email || '',
        "patientTypeId": data.patientTypeId || '',
        "leadSourceId": data.leadSourceId || '',
        "referredById": data.referredById || '',
        "referredByUserTypeId": data.referredById ? +data.referredByUserTypeId : null,
        "preferredPracticeLocationId" : data.preferredPracticeLocationId,
        "timeZone": moment2.tz.guess() || '',
        "address": {
          "addressLine1": data.address.addressLine1 || '',
          "addressLine2": data.address.addressLine2 || '',
          "city": data.address.city || '',
          "state": data.address.state || '',
          "country": data.address.country || '',
          "postalCode": data.address.postalCode || ''
        },
        "isInsured": data.isInsured,
        "checkEligibility": false,
        "doctorId": "",
        "leadId":formData.leadId,
        "patientTags":data.patientTags && data.patientTags.length>0 ? data.patientTags : undefined
      }
    )
      .then((res) => {
        setApiResponse(res);
      })
      .catch((err) => {
        setApiResponse(err);
        console.log(err)
      })
      .finally(() => {
        setIsSubmitted(false)
      })

  };
  const updateLeadStatus = () => {
    setIsSubmitted(true)
    let reqObj = {
      "status": 5,
      "patientId": apiResponse?.data?.id
    }
    LeadSourceService.updateLeadStatus(reqObj, formData.leadId).then((res) => {
      console.log(res)
    })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsSubmitted(false)
        if (props.onClose) {
          props.onClose()
        }
      })
  }
  return (
    <>
      {isSubmitted ? <DimLoader loadMessage="Processing..." /> : null}
      <PatientForm
        submitHandler={submitHandler}
        submitLabel="Submit"
        initialData={formData}
        errors={errors}
        isModal={props.isModal}
        onClose={() => props.onClose()}
      />
      {/* {errors && errors.map(err=>{return <span className="form-alert">Missing {err.field}</span>})} */}
      <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
    </>
  );
};

export default AddPatient;