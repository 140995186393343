import React, { useEffect, useState } from 'react'
import { Accordion, Icon } from 'semantic-ui-react';
import DoctorService from '../../../../../../../services/api/doctor.service';
import EditProviderDetails from './EditProviderDetails';
import ProviderAvailabilityTabs from './provider-availability-tabs/ProviderAvailabilityTabs';

const ProviderDetails = (props) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const [provider, setProvider] = useState(props.provider)
    const getProvider = (id) =>{
        DoctorService.getById(id)
        .then(res=>{
            console.log(res)
            setProvider(res)
        })
    }

    useEffect(() => {
        if (props.provider) {
            setProvider(props.provider)
        }
        else if(props.id){
            getProvider(props.id)
        }
    }, [props.provider, props.id])

    return (
        <div className='d-flex row m-0 p-0'>
            <h5 className='bg-faded p-3 mb-0 text-center'>
                {/* {props.goBack ? <i className='point icon angle left float-left' onClick={e => { e.preventDefault(); props.goBack() }} title="Return to Provider List" /> : null} */}
                {provider?.firstName} {provider?.lastName}
            </h5>
            <ProviderAvailabilityTabs id={provider?.id} />
        </div>
    )
}

export default ProviderDetails