import React, { useEffect, useState, useContext } from 'react'
import { store } from '../../../../../../context/StateProvider';
import PracticeLocationServiceTypesService from '../../../../../../services/api/practice-ocation-service-types.service';
import ModalBox from '../../../../../templates/components/ModalBox';
import label from '../../../../../../../assets/i18n/en.json'
import APIResponse from '../../../../../templates/components/APIResponse';

const PracticeLocationServiceTable = (props) => {
    const [isLoader, setIsLoader] = useState(false)
    const [locationServiceTypesList, setLocationServiceTypesList] = useState([])
    const [list, setList] = useState()
    const [addAll, setAddAll] = useState(false)
    const [deleteAll, setDeleteAll] = useState(false)
    const [apiResponse, setApiResponse] = useState()
    const globalStateAndDispatch = useContext(store)
    const state = globalStateAndDispatch.state

    const onSuccess = (message) => {
        getPracticeLocationServiceTypes()
    }

    const getPracticeLocationServiceTypes = () => {
        setIsLoader(true);
        PracticeLocationServiceTypesService.getPossibleServiceTypes()
            .then((allServiceTypes) => {
                allServiceTypes.forEach(obj => {
                    obj.isActive = false
                    obj.practiceLocationServiceTypeId = 0
                })

                PracticeLocationServiceTypesService.getPossibleServiceTypesByLocation(props.id)
                    .then((selectedServices) => {
                        console.log(selectedServices);
                        // loop thru each selected and find it in the practice list
                        selectedServices.forEach(selectedService => {
                            allServiceTypes.forEach(obj => {
                                if (obj.practiceServiceTypeId === selectedService.practiceServiceTypeId) {
                                    obj.isActive = true
                                    obj.practiceLocationServiceTypeId = selectedService.practiceLocationServiceTypeId

                                }
                            })
                        })

                        setLocationServiceTypesList(selectedServices.sort((a, b) => a.practiceServiceType.localeCompare(b.practiceServiceType)));
                        setList(allServiceTypes.sort((a, b) => a.practiceServiceType.localeCompare(b.practiceServiceType)));
                        setIsLoader(false);

                    })
                    .catch(error => {
                        setIsLoader(false);
                        console.log(error)
                    })


            })
            .catch(error => {
                setIsLoader(false);
                console.log(error)
            })
    }

    useEffect(() => {
        if (state?.practiceLocationId) {
            getPracticeLocationServiceTypes()
        }
    }, [state?.practiceLocationId])


    const addAllService = (list) => {
        setIsLoader(true);
        let practiceServiceTypeIds = list.map(item => item.practiceServiceTypeId);
        let commaSeparatedString = practiceServiceTypeIds.join(',');
        PracticeLocationServiceTypesService.addAllPracticeService({ practiceLocationId: state.practiceLocationId, practiceServiceTypeIds: commaSeparatedString })
            .then((response) => {
                setApiResponse(response)
            })
            .catch(error => {
                setApiResponse(error)
            }).finally(() => {
                setIsLoader(false)
            });
    }
    const deleteAllService = (list) => {
        setIsLoader(true);
        let practiceLocationServiceTypeIds = list.map(item => item.practiceLocationServiceTypeId);
        let commaSeparatedString = practiceLocationServiceTypeIds.join(',');
        PracticeLocationServiceTypesService.deleteAllPracticeLocationService({ practiceLocationServiceTypeIds: commaSeparatedString })
            .then((response) => {
                setApiResponse(response)
            })
            .catch(error => {
                setApiResponse(error)
            }).finally(() => {
                setIsLoader(false)
            });
    }
    const updateNoSelfSchedule = (noSelfSchedule, practiceServiceTypeId, practiceLocationServiceTypeId) => {
        setIsLoader(true);
        PracticeLocationServiceTypesService.updateNoSelfScheduleForLocationService({ practiceLocationId: state.practiceLocationId, practiceServiceTypeId: practiceServiceTypeId, noSelfSchedule: noSelfSchedule }, practiceLocationServiceTypeId)
            .then((response) => {
                setApiResponse(response)
            })
            .catch(error => {
                setApiResponse(error)
            }).finally(() => {
                setIsLoader(false)
            });
    }
    return (
        <>
            {isLoader && <div className="ui">
                <div className="ui active dimmer">
                    <div className="ui indeterminate text loader">{label.common.processing}</div>
                </div>
            </div>}
            <div className='d-flex row'>
                <div className='col-md-8 col-12'>
                    <div className='card'>
                        <div className='card-header'>
                            <div className="row d-flex align-items-center justify-content-between">
                                <span className='col-md-auto col-12'>Active Services</span>
                                <div className='col-md-auto col-12'>
                                    <button className="btn btn-primary" onClick={e => { e.preventDefault(); setDeleteAll(true) }}>Delete All</button>
                                </div>
                            </div>


                        </div>
                        <div className='card-body row d-flex'>
                            {locationServiceTypesList.map(service => {
                                return (
                                    <div className='col-md-3 col-12'>
                                        <span title="Remove from Location" className='text-start point' onClick={e => { e.preventDefault(); deleteAllService([service]) }}><i className='icon minus small' /> {service.practiceServiceType} </span>
                                        <i onClick={e => { e.preventDefault(); updateNoSelfSchedule(service.noSelfSchedule === 0 ? 1 : 0, service.practiceServiceTypeId, service.practiceLocationServiceTypeId); }} className={service.noSelfSchedule ? 'icon calendar times' : 'icon calendar check'} title={service.noSelfSchedule ? "Not available for self-scheduling" : "Available for self-scheduling"} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='col-md-4 col-12'>
                    <div className='card'>
                        <div className='card-header'>

                            <div className="row d-flex align-items-center justify-content-between">
                                <span className='col-md-auto col-12'>Inactive Services</span>
                                <div className='col-md-auto col-12'>
                                    <button className="btn btn-primary" onClick={e => { e.preventDefault(); setAddAll(true) }}>Add All</button>
                                </div>
                            </div>
                        </div>
                        <div className='card-body row d-flex'>
                            {list && list.filter(obj => !obj.isActive).map(service => {
                                return (
                                    <div className='col-md-6 col-12'>
                                        <span title="Add to Location" className='text-start point' onClick={e => { e.preventDefault(); console.log(service); addAllService([service]) }}><i className='icon plus small' /> {service.practiceServiceType}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <ModalBox open={addAll} onClose={() => { setAddAll(false) }} title="Confirm">
                <div className="d-flex row justify-content-between">
                    <div className="col-12 mb-3">
                        Are you sure you want to add all practice services?
                    </div>
                    <div className="col-auto"><button className="btn btn-primary" onClick={e => { e.preventDefault(); setAddAll(false); addAllService(list) }}>Confirm</button></div>
                    <div className="col-auto"><button className="btn btn-secondary" onClick={e => { e.preventDefault(); setAddAll(false) }}>Cancel</button></div>
                </div>
            </ModalBox>
            <ModalBox open={deleteAll} onClose={() => { setDeleteAll(false) }} title="Confirm">
                <div className="d-flex row justify-content-between">
                    <div className="col-12 mb-3">
                        Are you sure you want to delete all practice services?
                    </div>
                    <div className="col-auto"><button className="btn btn-primary" onClick={e => { e.preventDefault(); setDeleteAll(false); deleteAllService(locationServiceTypesList) }}>Confirm</button></div>
                    <div className="col-auto"><button className="btn btn-secondary" onClick={e => { e.preventDefault(); setDeleteAll(false) }}>Cancel</button></div>
                </div>
            </ModalBox>
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </>
    )
}

export default PracticeLocationServiceTable