import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive';
import toast from 'react-hot-toast';
import AccessRightsService from '../../../../../../services/api/access-rights.service';
import FeaturesAccessService from '../../../../../../services/api/features-access.service';

const NotificationSettings = (props) => {
    const [formData, setFormData] = useState()
    const [notificationSettings, setNotificationSettings] = useState()
    const [originalSettings, setOriginalSettings] = useState()
    const [isLoader, setIsLoader] = useState(false)
    const isMobile = useMediaQuery({ query: `(max-width: 576px)` });
    // function for input change
    const inputChange = (e, i) => {

        let newStateObject = [...formData];
        newStateObject[i][e.target.name] = e.target.value
        console.log(newStateObject);
        return setFormData(newStateObject);
    };
    const getConfig = () => {
        setNotificationSettings()
        setIsLoader(true)
        let reqObj = { SortField: 'featureName' }
        AccessRightsService.getfeatureConfig(reqObj)
            .then(res => {
                console.log(res);

                let sortedResults = res.filter(obj => obj.hasSms == 1 || obj.hasEmail == 1).sort((a, b) => a.featureName?.trim().localeCompare(b.featureName.trim()));
                

                let uniqueFeatures = new Array();
                sortedResults.map((feature, i) => {
                    if (!uniqueFeatures.find((u,i) => { if (u.featureId === feature.featureId) return true;}))
                    {
                        uniqueFeatures.push(feature);                        
                    }
                });

                
                setNotificationSettings(uniqueFeatures);
                setFormData(uniqueFeatures)
                setOriginalSettings(uniqueFeatures)
                setIsLoader(false)
            })
            .catch(err => {
                console.log(err)
                setIsLoader(false)
            })
    }

    useEffect(() => {
        getConfig()
    }, [])

    const updateNotifs = () => {
        let includedData = formData.map(obj => {
            return (
                {
                    featureId: obj.featureId,
                    id: obj.id,
                    isEmailEnabled: obj.isEmailEnabled,
                    isSmsEnabled: obj.isSmsEnabled,
                    moduleId: obj.moduleId
                }
            )
        })
        console.log(includedData)
        FeaturesAccessService.postFeatureAccess({ featureConfig: includedData })
            .then(res => {
                console.log("update results", res)
                setNotificationSettings(res.filter(obj => obj.hasSms == 1 || obj.hasEmail == 1))
            })
    }

    const emailClickHandler = (e) => {
        console.log(e.target.checked)
        let newArray = notificationSettings?.map(notif => {
            return (
                { ...notif, isEmailEnabled: e.target.checked }
            )
        })
        setFormData(newArray)
        setNotificationSettings(newArray)
    }

    const smsClickHandler = (e) => {
        console.log(e.target.checked)
        let newArray = notificationSettings.map(notif => {
            return (
                { ...notif, isSmsEnabled: e.target.checked }
            )
        })
        setFormData(newArray)
        setNotificationSettings(newArray)
    }


    const resetNotifs = () => {
        setFormData(originalSettings)
        setNotificationSettings(originalSettings)
    }
    return (
        <div className='row d-flex m-p-0 notifications-settings'>
            <div className='col-12'>
                <div className="d-flex align-items-end justify-content-between">
                    <h5 className='mb-0'>Notification Settings</h5>
                    <div>
                        <button className='btn btn-secondary' onClick={e => { e.preventDefault(); resetNotifs() }}>Reset</button>
                        <button className='btn btn-primary ms-3' onClick={e => { e.preventDefault(); updateNotifs() }}>Update</button>
                    </div>
                </div>
                <div className='col-12 mt-3'>
                    <table className='table'>
                        {!isMobile ?
                            <colgroup>
                                <col span="1" />
                                <col span="1" style={{ width: '15%' }} />
                                <col span="1" style={{ width: '15%' }} />
                            </colgroup>
                            :
                            <colgroup>
                                <col span="1"/>
                                <col span="1" style={{width:'45px'}}/>
                                <col span="1" style={{width:'45px'}}/>
                            </colgroup>
                        }
                        <thead>
                            <tr>
                                <th className="d-flex justify-content-start">Feature Name</th>
                                <th className='d-flex justify-content-start field form-check form-switch notif-switch-row'>
                                    {/* <input className="form-check-input ms-2 me-2" type="checkbox" role="switch" id="flexSwitchCheckDefault" name="patientLink" /> */}

                                    <div className="px-0 form-check form-switch notif-switch-group" title="Select/Deselect All">
                                        <input type="checkbox" className="form-check-input notif-switch" id="customSwitch1" role="switch" onChange={e => { smsClickHandler(e) }} />
                                        <label className="form-control-label text-primary notif-label" for="customSwitch1">SMS</label>
                                    </div>
                                </th>
                                <th className='d-flex justify-content-start field form-check form-switch notif-switch-row'>
                                    {/* <input className="form-check-input ms-2 me-2" type="checkbox" role="switch" id="flexSwitchCheckDefault" name="patientLink" /> */}

                                    <div className="form-switch form-check notif-switch-group px-0" title="Select/Deselect All">
                                        <input type="checkbox" className="form-check-input notif-switch" id="customSwitch1" role="switch" onChange={e => { emailClickHandler(e) }} />
                                        <label className="form-control-label text-primary notif-label" for="customSwitch1">Email</label>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoader && <tr><td colSpan={3}>Notifications are Loading...</td></tr>}
                            {formData && formData.map((type, i) => {
                                return (
                                    <tr>
                                        <td className={!type.isSMSEnabled && !type.isEmailEnabled ? 'strike-through':''}>{type.featureName}</td>
                                        <td className="row d-flex field form-check form-switch notif-option justify-content-center">
                                            <input className="mx-0 form-check-input notif-option-input col-auto" onChange={e => { inputChange({ target: { value: e.target.checked, name: 'isSmsEnabled' } }, i) }}   checked={type?.isSmsEnabled}   name='isSmsEnabled'   type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                        </td>
                                        <td className="row d-flex field form-check form-switch notif-option">
                                            <input className="mx-0 form-check-input notif-option-input"          onChange={e => { inputChange({ target: { value: e.target.checked, name: 'isEmailEnabled' } }, i) }} checked={type?.isEmailEnabled} name='isEmailEnabled' type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default NotificationSettings