import React, { useState,useEffect } from 'react'
import DimLoader from '../../../../templates/components/DimLoader'
import PatientAccountService from '../../../../../services/api/patient-account.service';
import PatientAccountRenew from './PatientAccountRenew';
import APIResponse from '../../../../templates/components/APIResponse';

const PatientAccountRenewWrapper = (props) => {
    const [isLoading, setIsLoading] = useState();
    const [account, setAccount] = useState();

    const [accountId, setAccountId] = useState(props?.accountId);
    const [apiResponse, setApiResponse] = useState()
    const onSuccess = (message) => {
        setAccount(apiResponse.data);
    }
    const onError = (message) => {
        if(props.closeModal){
            props.closeModal()
        }
    }
    const fetchAccountDetails = async () => {
        setIsLoading(true);
        PatientAccountService.getPatientAccountById({patientId: props?.patientId,patientAccountId :props?.accountId})
            .then(res => {
                setApiResponse(res)
                setIsLoading(false)
            })
            .catch(err => {
                setApiResponse(err)
                setIsLoading(false) 
            })
    }

    useEffect(() => {
        if (accountId) fetchAccountDetails();
    }, [accountId])
    return (
        <>
            {isLoading ? <DimLoader loadMessage="Loading..." /> : null}
            {account ? 
                <PatientAccountRenew
                    account={account}
                    patientId={props.patientId}
                    onClose={props.onClose}
                    closeModal={props.closeModal}
                />
            : null }
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} onError={onError} toastOnFailure={true}/>
        </>
    )
}

export default PatientAccountRenewWrapper