import React, { useState, useEffect } from 'react'
import TabsTemplate from '../../../../templates/components/TabsTemplate'
import PaymentOptionTableSettings from './payment-option-table-settings/PaymentOptionTableSettings'

const PaymentSettings = (props) => {
    return (
        <TabsTemplate>
            <div title='Payment Options'>
                <PaymentOptionTableSettings/>
            </div>
        </TabsTemplate>
    )
}
export default PaymentSettings