import React, { useState, useEffect } from "react";
import label from "../../../../../../assets/i18n/en.json";
import moment from "moment";
import Select from "react-select";
import ProviderService from "../../../../../services/api/provider.service";
import AppointmentService from "../../../../../services/api/appointment.service";
import DoctorService from "../../../../../services/api/doctor.service";
import toast from "react-hot-toast";
import Utilities from "../../../../../services/commonservice/utilities";
import DimLoader from '../../../../templates/components/DimLoader'
const defaultState = {
    wholePractice: false,
    startWholeDay: false,
    endWholeDay: false,
    startDate: moment().format("YYYY-MM-DD"),
    startTime: '00:00',
    endDate: moment().format("YYYY-MM-DD"),
    endTime: '00:00'
}
import HPTSelect from "../../../../templates/components/HPTSelect";

const UnavailableBlockForm = (props) => {
    const [practiceLocations, setPracticeLocations] = useState();
    const [doctors, setDoctors] = useState();
    const [inputData, setInputData] = useState(props.initialData || {});
    const [unavailableBlockId, setUnavailableBlockId] = useState(props.unavailableBlockId || 0)
    const [validInput, setValidInput] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [blockType, setBlockType] = useState(1)
    const [tab, setTab] = useState(1);

    const [blockTypeOptions, setBlockTypeOptions] = useState([
        {
            value:1
        ,   label:'One Provider, One Location'
        },
        {
            value:2
        ,   label:'One Provider, All Locations'
        },
        {
            value:3
        ,   label:'All Providers, One Location'
        },
        {
            value:4
        ,   label:'All Locations and Providers'
        },
    ])


    useEffect(() => {

        // here check if unavailableBlockId

        if (unavailableBlockId || props.unavailableBlockId)
        {

            AppointmentService.getUnavailableBlock(unavailableBlockId || props.unavailableBlockId)
                .then(res => 
                    {
                        initializeData(res)
                    }
                )
                .catch(() => {
                        toast.error('Oops! Something went wrong');
                    }
                );

        }
        else
        {
            initializeData(inputData);
        }            
    }, []);


    const initializeData = (newInputData) => {
        let newStateObject = { ...newInputData };

        if (newStateObject.doctorId && newStateObject.practiceLocationId) {
            setTab(2);
            setBlockType(1)
        }
        // 'One Provider, All Locations'
        else if (newStateObject.doctorId && !newStateObject.practiceLocationId) {
            setTab(2);
            setBlockType(2)
        }
        // 'All Providers, One Location'
        else if (!newStateObject.doctorId && newStateObject.practiceLocationId) {
            setTab(2);
            setBlockType(3)
        }
        // 'All Locations and Providers'
        else if ((!newStateObject.doctorId && !newStateObject.practiceLocationId) || newStateObject.wholePractice) {
            setTab(2);
            setBlockType(4)
        }
        else if (newStateObject?.doctorId) {
            let newBlockTyps = blockTypeOptions;
            newBlockTyps.pop();
            newBlockTyps.pop();
            setBlockTypeOptions(newBlockTyps)
        }


        if (!newStateObject?.unavailableBlockId && !props.providerId) {
            newStateObject = defaultState
        }
        if (newStateObject.startWholeDay) {
            newStateObject["startTime"] = ''
        }
        // else {
        //     if (!newStateObject.startTime) {
        //         newStateObject["startTime"] = ''; 
        //         newStateObject["startDate"] = '';
        //     }
        //     else {
        //         // Make the start date a local date
        //         //let startDateTime = moment(newStateObject.startDate.replace('00:00:00', newStateObject.startTime).replace('Z',''));
        //         // newStateObject["startTime"] = startDateTime.format('HH:mm')
        //         //newStateObject["startDate"] = startDateTime.format("YYYY-MM-DD") + 'T00:00:00.000Z'
        //         //newStateObject.startTime = newStateObject.startTime.slice(0,5)
        //     }
        // }
        if (newStateObject.endWholeDay) {
            newStateObject["endTime"] = ''
        }
        // else {
        //     if (!newStateObject.endTime) {
        //         newStateObject["endTime"] = ''; 
        //         newStateObject["endDate"] = '';
        //     }
        //     else {
        //         // Make the start date a local date
        //         //let endDateTime = moment(newStateObject.endDate.replace('00:00:00', newStateObject.endTime).replace('Z',''));
        //         // newStateObject["endTime"] = endDateTime.format('HH:mm')
        //         //newStateObject["endDate"] = endDateTime.format("YYYY-MM-DD") + 'T00:00:00.000Z'
        //         //newStateObject.endTime = newStateObject.endTime.slice(0,5)
        //     }
        // }
        setInputData(newStateObject);
        AppointmentService.practiceLocationLookup()
            .then((res = []) => {
                const data = res.map(item => ({ label: item.practiceLocation, value: item.practiceLocationId }));
                setPracticeLocations(data);
            })
            .catch(err => {
                console.log(err)
            });
        const reqObj = { isRegistered: true, isActive: true };
        DoctorService.doctorLookup(reqObj)
            .then((res) => {
                const data = res.map(item => ({ label: item.name, value: item.id }));
                setDoctors(data);
            }).catch(console.log);

    };

    const onSubmit = () => {
        const payload = { ...inputData };
        setIsSubmitted(true)

        delete payload.wholePractice        

        // 'One Provider, One Location'
        if (blockType==1) {
            // 	ub.DoctorId is not null
            // 	ub.PracticeLocationId = _practiceLocationId
        }
        // 'One Provider, All Locations'
        if (blockType==2) {
            // 	ub.DoctorId is not null
            // 	ub.PracticeLocationId is null
            delete payload.practiceLocationId;
        }
        // 'All Providers, One Location'
        if (blockType==3) {
            delete payload.doctorId;
            // 	ub.DoctorId is null
            // 	ub.PracticeLocationId = _practiceLocationId
        }
        // 'All Locations and Providers'
        if (blockType==4) {
            delete payload.doctorId;
            delete payload.practiceLocationId;
            payload.wholePractice = 1;
            // 	ub.DoctorId is null
            // 	ub.PracticeLocationId is null
        }



        // if (payload.wholePractice) {
        //     delete payload.practiceLocationId;
        // }

        if (!payload.doctorId) {
            delete payload.doctorId;
        }

        let startDateTime = ''
        let endDateTime = ''

        // "fromDate": Utilities.toISOStringWithoutOffset(new Date(data.fromDate))+'.000Z',
        // "toDate": Utilities.toISOStringWithoutOffset(new Date(data.toDate))+'.000Z',

        if (!payload.startTime) payload.startTime = ''
        if (!payload.endTime) payload.endTime = ''
        payload.endTime = payload.endTime.length==5 ? payload.endTime+':00': payload.endTime;
        payload.startTime = payload.startTime.length==5 ? payload.startTime+':00': payload.startTime;

        // Add blank time in case its missing
        if (!payload.startDate.includes("T"))
        {
            payload.startDate = payload.startDate + "T00:00:00.000Z"
        }
        if (!payload.endDate.includes("T"))
        {
            payload.endDate = payload.endDate + "T00:00:00.000Z"
        }

        if (payload.startWholeDay) {
            startDateTime = moment(payload.startDate)
            delete payload.startTime;
        }
        else {
            // startDateTime = moment.utc(payload.startDate.replace('00:00:00', payload.startTime + ':00').replace('T', ' ').replace('Z', '').replace('.000', ''), "YYYY-MM-DD hh:mm:ss", false)
            startDateTime = moment(payload.startDate.replace('00:00:00', payload.startTime))
        }

        if (payload.endWholeDay) {
            endDateTime = moment.utc(payload.endDate).endOf("d")
            delete payload.endTime;
        }
        else {
            // endDateTime = moment.utc(payload.endDate.replace('00:00:00', payload.endTime + ':00').replace('T', ' ').replace('Z', '').replace('.000', ''), "YYYY-MM-DD hh:mm:ss", false)
            endDateTime = moment(payload.endDate.replace('00:00:00', payload.endTime))
        }

        if (startDateTime > endDateTime) {
            toast.error('Start date/time cannot be greater than End date/time');
            setIsSubmitted(false)
            return;
        }

        payload.startDate = startDateTime.utc().format("YYYY-MM-DD")
        payload.endDate =  endDateTime.utc().format("YYYY-MM-DD")
        //payload.startTime = startDateTime.utc().format('HH:mm')
        //payload.endTime = endDateTime.utc().format('HH:mm')


        payload.wholePractice = payload.wholePractice ? 1 : 0;
        payload.startWholeDay = payload.startWholeDay ? 1 : 0;
        payload.endWholeDay = payload.endWholeDay ? 1 : 0;

        if (inputData?.unavailableBlockId) {
            ProviderService.editUnavailableBlock(inputData?.unavailableBlockId, payload)
                .then(data => {
                    toast.success('Closed/Away Time edited successfully');
                    if (props.onClose()) {
                        props.onClose()
                    }
                }).catch(() => {
                    toast.error('Oops! Something went wrong');
                })
                .finally(() => {
                    setIsSubmitted(false)
                });
        } else {
            ProviderService.addUnavailableBlock(payload)
                .then(data => {
                    toast.success('Closed/Away Time added successfully');
                    if (props.onClose()) {
                        props.onClose()
                    }
                }).catch(() => {
                    toast.error('Oops! Something went wrong');
                })
                .finally(() => {
                    setIsSubmitted(false)
                });
        }
    }



    useEffect(() => {

        if (!inputData.description) { setValidInput(false); return; }
        if (!inputData.wholePractice && !inputData.practiceLocationId) { setValidInput(false); return; }
        if (!inputData.startDate) { setValidInput(false); return; }
        if (!inputData.endDate) { setValidInput(false); return; }

        if (!inputData.startTime && !inputData.startWholeDay) { setValidInput(false); return; }
        if (!inputData.endTime && !inputData.endWholeDay) { setValidInput(false); return; }

        setValidInput(true)

    }, [inputData]);



    const inputChange = (e) => {
        let newStateObject = { ...inputData };
        newStateObject[e.target.name] = e.target.value
        if (e.target.name == 'startWholeDay')
        {
            newStateObject['startTime'] = ''
        }
        if (e.target.name == 'endWholeDay')
        {
            newStateObject['endTime'] = ''
        }
        setInputData(newStateObject);
    };


    const blockTypeSelected = (newBlockType) => {
        setBlockType(newBlockType);
        setTab(2);
    };    
    


    return <>{isSubmitted ? <DimLoader loadMessage="Processing..." /> : null}
    
    <div className="row d-flex">



        <div className="col-12"><label> Closed/Away Type</label>
            <HPTSelect
                className='react-select-container col'
                classNamePrefix='react-select'
                isDisabled={tab==2}
                options={blockTypeOptions}
                name="blockType"
                value={blockTypeOptions.find(obj => obj.value === blockType)}
                getOptionLabel={(option) => { return (option.label) }}
                menuPortalTarget={document.body}
                // styles={{menuPortal: base => ({ ...base, zIndex: 9999 }),}}
                onChange={e => {
                    blockTypeSelected(e.value);
                }}
            />

        </div>




{tab==2 && 
<>

        <div className="col-12 required field">
            <label> Description</label>
            <input
                placeholder="Description"
                type="text"
                name="description"
                value={inputData.description}
                onChange={inputChange}
                required
            />
        </div>

        {(blockType==1 || blockType==3) && 
        <div className="col-10">
            <label> Location</label>
            <HPTSelect
                className='react-select-container col'
                // isDisabled={inputData.wholePractice}
                classNamePrefix='react-select'
                options={practiceLocations}
                name="practiceLocationId"
                value={practiceLocations && practiceLocations.find(obj => obj.value === inputData.practiceLocationId)}
                onChange={e => {
                    inputChange({
                        target:
                            { value: e.value, name: 'practiceLocationId' }
                    })
                }}
            />
        </div>
        }

        {(blockType==1 || blockType==2) && 
        <div className="col-12"><label> Provider</label>
            <HPTSelect
                className='react-select-container col'
                classNamePrefix='react-select'
                options={doctors}
                name="doctorId"
                isClearable
                isDisabled={props.initialData?.unavailableBlockId || props?.unavailableBlockId}
                value={doctors && doctors.find(obj => obj.value === inputData.doctorId)}
                onChange={e => {
                    inputChange({
                        target:
                            { value: e.value, name: 'doctorId' }
                    })
                }}
            />
        </div>
        }

        {/* <div className="col-2 d-flex align-items-center mt-4">
            <input
                type="checkbox"
                className="form-check-input"
                name="wholePractice"
                checked={inputData.wholePractice}
                onChange={e => {
                    e.preventDefault()
                    inputChange({
                        target:
                            { value: e.target.checked, name: 'wholePractice' }
                    })
                }}
            />
            <label className="form-check-label ms-2">
                Entire Practice
            </label>
        </div> */}

        <div className="field required col-6">
            <label>Start Date</label>
            <input type="date"
                className="form-control"
                value={moment.utc(inputData.startDate).format("YYYY-MM-DD")}
                min={moment().format("YYYY-MM-DD")}
                placeholder="MM/DD/YYYY"
                onChange={e => {
                    e.preventDefault();
                    inputChange({
                        target:
                            { value: e.target.value, name: 'startDate' }
                    })
                }}
            />
        </div>
        <div className="field required col-4">
            <label>Start Time</label>
            <input type="time"
                className="form-control"
                value={inputData.startTime}
                min="1900-01-01"
                onChange={e => {
                    e.preventDefault();
                    inputChange({
                        target:
                            { value: e.target.value, name: 'startTime' }
                    })
                }}
                disabled={inputData.startWholeDay}
            />
        </div>
        <div className="mt-4 col-2 d-flex align-items-center">
            <input
                type="checkbox"
                className="form-check-input"
                name="startWholeDay"
                checked={inputData.startWholeDay}
                onChange={e => {
                    // e.preventDefault()
                    inputChange({
                        target:
                            { value: e.target.checked, name: 'startWholeDay' }
                    })
                }}
            />
            <label className="form-check-label ms-4">
                From Start of Day
            </label>
        </div>
        <div className="field required col-6">
            <label>End Date</label>
            <input type="date"
                className="form-control"
                value={moment.utc(inputData.endDate).format("YYYY-MM-DD")}
                min={moment().format("YYYY-MM-DD")}
                placeholder="MM/DD/YYYY"
                onChange={e => {
                    e.preventDefault();
                    inputChange({
                        target:
                            { value: e.target.value, name: 'endDate' }
                    })
                }}
            />
        </div>
        <div className="field required col-4">
            <label>End Time</label>
            <input type="time"
                className="form-control"
                value={inputData.endTime}
                min="1900-01-01"
                // max={moment().format("YYYY-MM-DD")}
                // placeholder="MM/DD/YYYY"
                onChange={e => {
                    e.preventDefault();
                    inputChange({
                        target:
                            { value: e.target.value, name: 'endTime' }
                    })
                }}
                disabled={inputData.endWholeDay}
            />
        </div>
        <div className="col-2 mt-4 d-flex align-items-center">
            <input
                type="checkbox"
                className="form-check-input"
                name="endWholeDay"
                checked={inputData.endWholeDay}
                onChange={e => {
                    // e.preventDefault()
                    inputChange({
                        target:
                            { value: e.target.checked, name: 'endWholeDay' }
                    });
                }}
            />
            <label className="form-check-label ms-4">
                To End of Day
            </label>
        </div>

</>}


        <div className="mt-3 d-flex justify-content-between modal-button-bar">
            <div className="col-auto">
                {tab==2 &&
                <button
                    className="btn btn-secondary float-right"
                    onClick={(e) => {
                        e.preventDefault();
                        setTab(1);
                    }}
                > Previous
                </button>
                }
                {tab==1 &&
                <button
                    className="btn btn-secondary float-right"
                    onClick={(e) => {
                        e.preventDefault();
                        setTab(2);
                    }}
                > Next
                </button>
                }
            </div>
            <div className="col-auto">
                <button
                    className="btn btn-secondary float-right"
                    onClick={(e) => {
                        e.preventDefault();
                        if (props.onClose) {
                            props.onClose()
                        }
                    }}
                > Cancel
                </button>
                <button
                    disabled={!validInput}
                    className="btn btn-primary"
                    onClick={(e) => {
                        e.preventDefault();
                        onSubmit();
                    }}>
                    {props.initialData?.unavailableBlockId || props?.unavailableBlockId ? 'Update' : 'Save'}
                </button>
            </div>
        </div>



    </div></>
}

export default UnavailableBlockForm;