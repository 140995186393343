import React, { useState, useEffect, Fragment } from 'react'
import AppointmentService from '../../../../../services/api/appointment.service'
import AppointmentForm from '../appointment-form/AppointmentForm'
import moment from 'moment'
import Toaster from '../../../../templates/components/Toaster'
import APIResponse from '../../../../templates/components/APIResponse';
import * as moment1 from "moment-timezone";
import TimeZoneEnum from '../../../../../common/enum/time-zone.enum';
import Utilities from '../../../../../services/commonservice/utilities'

const EditAppointmentForm = (props) => {
    const [formData, setFormData] = useState()
    const [loader, setLoader] = useState(true)
    const [toasterMessage, setToasterMessage] = useState()
    const [apiResponse, setApiResponse] = useState()
    const [disabled, setDisabled] = useState(false)

    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
      }
    
    const submitHandler = (data) => {
        setDisabled(true);                
        //console.log('data',{data});
        let reqObj={
            "doctorId": data.doctorId && data.doctorId!==""? data.doctorId : undefined ,
            "fromDate": Utilities.convertUISchedulingDateToDBFormat(data.fromDate),
            "toDate": Utilities.convertUISchedulingDateToDBFormat(data.toDate),
            "day": moment(data.fromDate).day(),
            "duration": data.duration,
            "repeatOn": data.repeatOn,
            "aptTotalCount": data.aptTotalCount,
            "memo": data.memo,
            "phone": data.patientPhone,
            "email": data.email,
            "timeZone":  58, // data?.timeZone? data.timeZone : TimeZoneEnum[moment1.tz.guess()] != undefined ? TimeZoneEnum[moment1.tz.guess()] : TimeZoneEnum["Default"],
            "checkEligibility": data.checkEligibility,
            "patientInsuranceId": data.patientInsuranceId,
            "practicePatientTypeId": data.practicePatientTypeId,
            "practiceLocationId": data.practiceLocationId,
            "practiceServiceTypeId": data.practiceServiceTypeId,
            "practiceServiceType": data.practiceServiceType,
            "practiceAppointmentStatusCodeId": data.practiceAppointmentStatusCodeId,
            "equipmentId": data.equipmentId && data.equipmentId!==""? data.equipmentId : undefined,
            "reasonForVisit": data.memo,
            "visitStatus": null,
            "patientId": data.patientId,
            "id": data.id,
            "parentGroupId":data.parentGroupId //only in edit needed
        }
        if(data.practiceLocationRoomId != '0rbd3W69'){
            reqObj.practiceLocationRoomId = data.practiceLocationRoomId && data.practiceLocationRoomId!==""? data.practiceLocationRoomId : undefined
        }
        if (data.childAppointments && data.childAppointments.length > 0) {
            //reset sequence in correct order
            reqObj.childAppointments = data.childAppointments.map((item, index) => {
                item.sequence = index + 1
                item.doctorId = item.doctorId && item.doctorId !== "" ? item.doctorId : undefined
                item.equipmentId = item.equipmentId && item.equipmentId !== "" ? item.equipmentId : undefined
                item.practiceLocationRoomId = item.practiceLocationRoomId && item.practiceLocationRoomId !== "" && item.practiceLocationRoomId != '0rbd3W69' ? item.practiceLocationRoomId : undefined
                //delete item.sequenceId
                return item
            })
        } else {
            reqObj.childAppointments = [];
        }
        //console.log("appointment edit "+JSON.stringify(reqObj))  
        AppointmentService.editAppointment(reqObj)
            .then((res) => { 
                setDisabled(false);                
                setApiResponse(res);
            })
            .catch((err) => { 
                setDisabled(false);                
                setApiResponse(err);
                console.log(err) 
            });            
    }
    useEffect(() => {
        if (props.id) {
            AppointmentService.getAppointmentById(props.id)
                .then(res => {
                    console.log(res);
                    let arr = res
                    // Removed since dates are now stored as is in the DB and not converted to UTC
                    // arr.fromDate = new Date(arr.fromDate).toISOString()
                    // arr.toDate = new Date(arr.toDate).toISOString()
                    arr.mobile = arr.patientPhone
                    arr.email = arr.email
                    setFormData(arr)
                    return setLoader(false)
                })
                .catch(err => {
                    console.log(err)
                    return setLoader(false)
                })
        }
    }, [props.id])
    return (
        <Fragment>
            {toasterMessage && <Toaster message={toasterMessage} show={toasterMessage} autoClose onClose={() => { setToasterMessage() }} />}
            {!loader && <AppointmentForm disabled={disabled} isModal={props.isModal} submitHandler={submitHandler} initialData={formData} additionalButton={props.additionalButton || null} submitLabel="Update" isEdit onClose={() => { props.onClose() }} />}
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true}/>
        </Fragment>

    )
}

export default EditAppointmentForm