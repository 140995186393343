import React, { useState, useContext } from 'react'
import PracticeLocationServiceTable from '../../location-settings/practice-location-services-table/PracticeLocationServicesTable'
import PracticeLocationSelector from '../../../../../templates/components/PracticeLocationSelector'
import { store } from '../../../../../../context/StateProvider'
import { Accordion, Icon } from 'semantic-ui-react'

const LocationServices = (props) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const state = useContext(store).state

    return (
        <div>
            <div className='row d-flex mb-3'>
                <div className='col-md-auto col-12'>
                    <div className='field'>
                        <label>Select a Location</label>
                        <div className='input-group' style={{ minWidth: '300px' }}>
                            <PracticeLocationSelector />
                        </div>
                    </div>
                </div>
            </div>
            {state.practiceLocationId &&
                <Accordion fluid styled>
                    <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={e => { e.preventDefault(); if (activeIndex === 0) { setActiveIndex() } else { setActiveIndex(0) } }}
                    > <Icon name='dropdown' />Services</Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                        <div>
                            <PracticeLocationServiceTable id={state.practiceLocationId} />
                        </div>
                    </Accordion.Content>
                </Accordion>}
        </div>
    )}

export default LocationServices