import React, { useState, useEffect } from 'react'
import TabsTemplate from '../../../../templates/components/TabsTemplate'
import PracticePatientTypeTable from '../practice-settings/practice-patient-type/PracticePatientTypeTable'
import PatientTagSettings from '../practice-settings/patient-tag-settings/PatientTagSettings'
import PracticePatientSettings from './PracticePatientSettings'
import LeadSourceSettings from '../practice-settings/lead-source-settings/LeadSourceSettings'

const PatientSettings = (props) => {
    return (
        <div>
            <TabsTemplate id="patientSettings" accordionId="patientSettings">
                <div title="Patients">
                    <PracticePatientSettings/>
                </div>
                <div title="Patient Tags">
                    <PatientTagSettings />
                </div>
                <div title="Patient Types">
                    <PracticePatientTypeTable/>
                </div>
                <div title="Lead Source">
                    <LeadSourceSettings />
                </div>
            </TabsTemplate>
        </div>
    )
}

export default PatientSettings