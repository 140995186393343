export const ChannelTypeEnum = [
    { title: 'All', value: "" },
    { title: 'ACH', value: 2 },
    { title: 'Credit', value: 3 },
    { title: 'Debit', value: 4 },
    { title: 'Prepaid Balance', value: 5 },
    { title: 'Swipe', value: 7 },
    { title: 'Other', value: 8 },
    { title: 'Cash', value: 9 },
    { title: 'Check', value: 10 },
]