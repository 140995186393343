import React, { useState, useEffect } from 'react'
import APIResponse from '../../../../templates/components/APIResponse'
import PracticeAppointmentStatusCodeForm from '../practice-appointment-status-code-form/PracticeAppointmentStatusCodeForm'
import PracticeAppointmentStatusCodeService from '../../../../../services/api/practice-appointment-status-code.service'
import label from '../../../../../../assets/i18n/en.json'
const EditPracticeAppointmentStatusCode = (props) => {
    const [isLoaded_form, setIsLoaded_form] = useState(false)
    const [apiResponse, setApiResponse] = useState()
    const [formData, setFormData] = useState(props.initialData || {})
    const [isLoader, setIsLoader] = useState(false)
    useEffect(() => {
        //console.log(props.initialData)
        if (props.initialData) {
            setIsLoaded_form(true)
        }
    }, [props.initialData])
    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.onClose) {
            props.onClose()
        }
    }

    const submitHandler = (data) => {
        setIsLoader(true)
        let reqObj = {
            practiceAppointmentStatusCodeId: data.practiceAppointmentStatusCodeId,
            color: data.color,
            masterAppointmentStatusCodeId: data.masterAppointmentStatusCodeId,
            appointmentStatusCode: data.appointmentStatusCode,
            appointmentStatusLabel: data.appointmentStatusLabel
        }
        PracticeAppointmentStatusCodeService.updatePracticeAppointmentStatusCode(reqObj)
            .then(response => {
                //console.log(response)
                setApiResponse(response)
                setIsLoader(false)
            })
            .catch(error => {
                setApiResponse(error)
                setIsLoader(false)
            })
    }

    return (
        <>
            {isLoader && <div className="ui">
                <div className="ui active dimmer">
                    <div className="ui indeterminate text loader">{label.common.processing}</div>
                </div>
            </div>}
            {isLoaded_form && <PracticeAppointmentStatusCodeForm submitHandler={submitHandler} initialData={formData} submitLabel="Update" onClose={() => props.onClose()} />}
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </>
    )
}

export default EditPracticeAppointmentStatusCode