import moment from 'moment';
import React, { useEffect, useState, useContext, useCallback } from 'react'
import { store } from '../../../../../context/StateProvider';
import CommonService from '../../../../../services/api/common.service'
import TransactionService from '../../../../../services/api/transaction.service';
import Utilities from '../../../../../services/commonservice/utilities';
import Module from '../../../../templates/components/Module';
import Table2 from '../../../../templates/components/Table2';
import DatePicker from '../../../../templates/components/DatePicker';
import { InvoiceTypeEnum } from '../../../../../common/enum/invoice-type.enum';
import { debounce } from 'lodash'
import AsyncSelect from 'react-select/async'
import DimLoader from '../../../../templates/components/DimLoader'

const DeniedTransactionReportTable = (props) => {
    const globalStateAndDispatch = useContext(store);
    const contextState = globalStateAndDispatch.state;
    const [transactions, setTransactions] = useState()
    const [isLoader, setIsLoader] = useState(false)
    const [offsetHour, setOffsetHour] = useState(moment().utcOffset() >= 0 ? Math.floor(moment().utcOffset() / 60) : Math.ceil(moment().utcOffset() / 60),)
    const [offsetMinute, setOffsetMinute] = useState(moment().utcOffset() % 60)
    const [slabBy, setSlabBy] = useState('Month')
    const [startDate, setStartDate] = useState(moment().startOf("D"));
    const [endDate, setEndDate] = useState(moment().endOf("D"));
    const state = useContext(store).state
    const [keyword, setKeyword] = useState()
    const [initialMessage, setInitialMessage] = useState(true)
    const [locationId, setLocationId] = useState(state.practiceLocationId || null)

    // const [patientList, setPatientList] = useState()
    const transactionStatusList = [
        { 'statusName': 'Created', 'id': 0 },
        { 'statusName': 'Pending', 'id': 1 },
        { 'statusName': 'Authorized', 'id': 2 },
        { 'statusName': 'Posted', 'id': 3 },
        { 'statusName': 'Failed', 'id': 5 },
        { 'statusName': 'Void', 'id': 8 },
        { 'statusName': 'Refunded', 'id': 9 },
        { 'statusName': 'Approved', 'id': 10 },
        { 'statusName': 'Void attempted', 'id': 11 },
        { 'statusName': 'Refund attempted', 'id': 12 },
        { 'statusName': 'Hold', 'id': 13 },
        { 'statusName': 'Denied', 'id': 14 },
        { 'statusName': 'Success', 'id': 16 }, //Paid
        { 'statusName': 'Retried', 'id': 17 },
        { 'statusName': "Swipe Canceled", id: 20 },
        { 'statusName': "Partial Approved", id: 21 },
        { 'statusName': "Swipe Initiated", id: 22 },
        { 'statusName': 'Closed', 'id': 30 },
    ];

    // const for table 
    const columns = [
        // ["Name", "Equipment Type", "Room", "Actions"]
        {
            id: "transactionDate",
            Header: "Date",
            accessor: (tc) => moment(tc.transactionDate).format("MM-DD-YYYY h:mm A"),
            Footer: () => "Totals"
        },
        // {
        //     id: "id",
        //     Header: "Transaction Id",
        //     accessor: (tc) => tc.id
        // },
        {
            id: "patientName",
            Header: "Patient",
            accessor: (tc) => tc.patientName
        },
        {
            id: "invoiceNumber",
            Header: "Invoice Number",
            accessor: (tc) => tc.invoiceNumber
        },
        {
            id: "reasonCode",
            Header: "Reason Code",
            accessor: (tc) => tc.reasonCode
        },
        {
            id: "reasonStatus",
            Header: "Reason Status",
            accessor: (tc) => tc.reasonStatus
        },
        {
            id: "invoiceType",
            Header: "Invoice Type",
            accessor: (tc) => InvoiceTypeEnum.find(obj => obj.value === tc.invoiceType)?.title ? InvoiceTypeEnum.find(obj => obj.value === tc.invoiceType)?.title : tc.invoiceType
        },
        {
            id: "transactionStatus",
            Header: "Status",
            accessor: (tc) => transactionStatusList.find(obj => obj.id === tc.transactionStatus)?.statusName ? transactionStatusList.find(obj => obj.id === tc.transactionStatus)?.statusName : tc.transactionStatus
        },
        {
            id: "totalAmount",
            Header: "Amount",
            accessor: (tc) => Utilities.toDollar(tc.totalAmount),
            Footer: info => {
                // Only calculate total visits if rows change
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.totalAmount + sum, 0),
                    [info.rows]
                )

                return <>{Utilities.toDollar(total)}</>
            },
        },
    ]

    const transactionLookup = () => {
        setIsLoader(true)
        setInitialMessage(false);
        let reqObj = {
            sortField: 'createdOn',
            Asc: 'false',
            TransactionStatuses: '5,14',
            InvoiceTypes: '1,3',
            // PageSize: 20,
            StartRow: 0,
            StartDate: moment(startDate).toISOString(),
            EndDate: moment(endDate).toISOString(),
        }
        if (locationId) reqObj.PracticeLocationIds = locationId;
        if(keyword){
            reqObj.PatientIds= keyword
        }
        TransactionService.getDeniedTransaction(reqObj)
            .then(res => {
                setTransactions(res.data)
                return setIsLoader(false)
            })
    }

    const patientLoad = (inputText, callBack) => {
        if (inputText?.length < 3) return;
        let reqObj = { SearchTerm: inputText, isActive: true, isRegistered: true, SortField: 'firstName', isAsc: true }
        CommonService.patientLookup(reqObj)
            .then(res => {
                if (res) {
                    callBack(res.data)
                }
            }
            )
            .catch(err => console.log(err))
    }
    const rePatientLoad = useCallback(debounce(patientLoad, 500), [])
    return (
        <>
        {isLoader &&
            <DimLoader loadMessage="Processing" />
        }    
    
        <div className='row d-flex g-3'>
            <div className='col-12 m-p-0'>
                <Module title="Filters">
                    <div className='row d-flex'>
                        <div className='col-12 row d-flex mx-0 align-items-end report-filters'>
                            <DatePicker startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} setLocationId={setLocationId}/>
                            <div className='col-md col-12'>
                                <div className='ui field'>
                                    <label>Patient</label>
                                    <AsyncSelect
                                        classNamePrefix="react-select"
                                        className="react-select-container"
                                        name="patientId"
                                        loadOptions={rePatientLoad}
                                        placeholder="Search Patient"
                                        onChange={e => {
                                            console.log(e)
                                            if (e?.id) {
                                                setKeyword(e.id)
                                            }
                                            else {
                                                setKeyword()
                                            }
                                        }}
                                        isClearable={true}
                                        // getOptionLabel={(option) => {
                                        //     return (
                                        //         option.firstName + ' ' + option.lastName + ' | ' + moment.utc(option.dob).format("M/D/YYYY") + ' | ' + Utilities.toPhoneNumber(option.mobile) || Utilities.toPhoneNumber(option.patientPhone)
                                        //     )
                                        // }
                                        // }
                                        getOptionLabel={(option) => {
                                            return (
                                                <div className="d-flex row">
                                                    <span className="col">{option.firstName} {option.lastName}</span>
                                                    <span className='col text-start'><i className='icon birthday cake' />{moment.utc(option.dob).format("M/D/YYYY").toString()}</span>
                                                    <span className='col text-end'>
                                                        {option.mobile && option.mobile != '' || option.patientPhone && option.patientPhone != '' ?
                                                            <>
                                                                <i className='icon phone' />
                                                                {option.mobile && option.mobile != '' ? Utilities.toPhoneNumber(option.mobile) : option.patientPhone && option.patientPhone != '' ? Utilities.toPhoneNumber(option.patientPhone) : null}
                                                            </> : null}
                                                    </span>
                                                </div>
                                            )
                                        }}
                                        getOptionValue={(option) => option.id}
                                        noOptionsMessage={(e) => {
                                            if (!contextState.permissions.patientModifyPatients) {
                                                return null;
                                            }
                                            else {
                                                return <button className='btn btn-primary form-control' onClick={e => { e.preventDefault(); setShowAdd(true) }}>Add Patient</button>
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-md-auto col-12">
                                <button className="btn btn-primary" onClick={e => { e.preventDefault(); transactionLookup() }} title="Pull Report">
                                    <i className="icon arrow circle right" />
                                </button>
                            </div>

                        </div>
                    </div>
                </Module>
            </div>
            <div className='col-12 m-p-0'>
                <Module title="Denied Transaction Report">
                    <span style={{ color: "red", fontSize: "0.7rem" }}>
                        **Only Denied Payment Plan and Subscription Transactions are included.
                    </span>
                    <Table2 export searchable title="Transaction Report" data={transactions} columns={columns} loading={isLoader} initialMessage={initialMessage}/>
                </Module>
            </div>
        </div>
        </>
    )
}

export default DeniedTransactionReportTable