import React, { useEffect, useState } from "react";
import TransactionService from "../../../../../services/api/transaction.service";
import moment from 'moment'
import InputMask from "react-input-mask";
import Utilities from "../../../../../services/commonservice/utilities";
import DimLoader from "../../../../templates/components/DimLoader";
import toast from "react-hot-toast";
import APIResponse from "../../../../templates/components/APIResponse";
const RefundTransactionCard = (props) => {

  const [inputData, setInputData] = useState(props.initialData || {})
  const [paymentAmount, setPaymentAmount] = useState("partial")
  const [isLoader, setIsLoader] = useState(false)
  const [apiResponse, setApiResponse] = useState()
  const onSuccess = (message) => {
      if (props.refresh) {
        props.refresh()
      }
      if (props.onClose) {
        props.onClose()
      }
      if (props.exitHandler) {
        props.exitHandler()
      }
    }
  // formula for input change
  const inputChange = (e) => {
    if (e.target.name === 'amount' && e.target.value > parseFloat(inputData.tenderInfo.totalAmount)) {
      toast.error("You cannot refund more than the invoice amount")
      let newStateObject = { ...inputData };
      newStateObject.amount = inputData.tenderInfo.totalAmount
      setInputData(newStateObject);
      return console.log(inputData)
    }
    else if (e.target.name === 'amount') {
      let newStateObject = { ...inputData };
      newStateObject[e.target.name] = parseFloat(e.target.value)
      setInputData(newStateObject);
      return console.log(inputData)
    }
    else {
      let newStateObject = { ...inputData };
      newStateObject[e.target.name] = e.target.value
      setInputData(newStateObject);
      return console.log(inputData)
    }
  };
  const refundTc = () => {
    setIsLoader(true)
    let reqObj = {
      amount: parseFloat(inputData.amount),
      remarks: inputData.remarks
    }
    TransactionService.refundTransaction(
      inputData.id, reqObj
    )
      .then((res) => {
        setApiResponse(res)
        setIsLoader(false)
      })
      .catch(err => {
        setApiResponse(err)
        setIsLoader(false)
      })
  };

  useEffect(() => {
    if (paymentAmount === 'full') {
      inputChange({
        target: {
          name: 'amount', value: inputData.tenderInfo.totalAmount
        }
      })
    }
  }, [paymentAmount])
  return (
    <>
    <div className="row d-flex">
      {isLoader &&
        <DimLoader loadMessage="Processing" />
      }
      <div className="col-12">
        Are you sure you want to refund this transaction for &nbsp;
        {Utilities.toDollar(inputData.tenderInfo.totalAmount)} on {moment(inputData.createdOn).format('YYYY-MM-DD')} for patient &nbsp;
        {inputData.firstName} {inputData.lastName}?
      </div>
      <div className="col-12 d-flex my-3 align-items-end">
        <div className="col-auto">
          <div className="form-check">
            <input type="radio" className="form-check-input" checked={paymentAmount === 'partial'} onChange={e => { setPaymentAmount('partial') }} /> <span className="form-check-label">Partial Refund</span>
          </div>
        </div>
        <div className="col-auto ms-4">
          <div className="form-check">
            <input type="radio" className="form-check-input" checked={paymentAmount === 'full'} onChange={e => { setPaymentAmount('full') }} />
            <span className="form-check-label">Full Refund</span>
          </div>
        </div>
      </div>
      {paymentAmount === 'partial' && <div className="col-auto field required">
        <label>Refund Amount</label>
        <input type="number" value={inputData.amount} name="amount" onChange={e => { e.preventDefault(); inputChange(e) }} step=".01" />
      </div>}
      <div className="field required col">
        <label>Reason</label>
        <input type="text" value={inputData.remarks} name="remarks" onChange={e => { e.preventDefault(); inputChange(e) }} />
      </div>
      <div className="col-12 mt-3 d-flex justify-content-between">
        <div className="col-auto">
          <button className="btn btn-primary" onClick={e => { e.preventDefault(); refundTc() }}
          >Refund</button>
        </div>
        <div className="col-auto">
          <button className="btn btn-secondary" onClick={e => { e.preventDefault(); if (props.onClose) { props.onClose() } }}>Close</button>
        </div>
      </div>
    </div>
    <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
    </>
    
  );
};

export default RefundTransactionCard;
