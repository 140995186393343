import moment from 'moment'
import React, { useState, useEffect, useContext } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import { store } from '../../../../../context/StateProvider'
import AppointmentService from '../../../../../services/api/appointment.service'
import ProviderDashboardService from '../../../../../services/api/provider-dashboard.service'
import Utilities from '../../../../../services/commonservice/utilities'
import StorageService from '../../../../../services/session/storage.service'
import DatePicker from '../../../../templates/components/DatePicker'
import Module from '../../../../templates/components/Module'
import Table2 from '../../../../templates/components/Table2'
const PaymentsDashboard = (props) => {
    // context state and dispatch 
    const globalStateAndDispatch = useContext(store)
    const state = globalStateAndDispatch.state
    const [data, setData] = useState()
    const [offsetHour, setOffsetHour] = useState(moment().utcOffset() >= 0 ? Math.floor(moment().utcOffset() / 60) : Math.ceil(moment().utcOffset() / 60),)
    const [offsetMinute, setOffsetMinute] = useState(moment().utcOffset() % 60)
    const [slabBy, setSlabBy] = useState('Month')
    const [startDate, setStartDate] = useState(moment().startOf("D"));
    const [endDate, setEndDate] = useState(moment().endOf("D"));
    const [channelType, setChannelType] = useState(0)
    const [pointWidth, setPointWidth] = useState(3)
    const [typeData, setTypeData] = useState({ credit: [], ach: [], cash: [], check: [] })

    const [isLoader, setIsLoader] = useState(false)

    // States for report data 
    const [transactionStatusDetails, setTransactionStatusDetails] = useState()
    const [patientReportDetails, setPatientReportDetails] = useState()
    const [paymentCollectedTodayDetails, setPaymentCollectedTodayDetails] = useState()
    const [reportTransactionVolumeDetails, setReportTransactionVolumeDetails] = useState()
    const [outstandingReceivablesDetails, setOutstandingReceivablesDetails] = useState()
    const [selectedDateForReport, setSelectedDateForReport] = useState()

    // Graph states 
    const [graphDetails, setGraphDetails] = useState()
    const [lineChart, setLineChart] = useState()
    const [lineChartLabels, setLineChartLabels] = useState()
    const [dataSet, setDataSet] = useState()
    const [selectedTransactionVolumeType, setSelectedTransactionVolumeType] = useState("totalSale")
    // const [selectedDateRangeForGraph, setSelectedDateRangeForGraph] = useState("year")
    // const [displayChart, setDisplayChart] = useState(false)
    const [slabs, setSlabs] = useState()
    const [locationList, setLocationList] = useState(state.practiceLocations || null)
    const [locationId, setLocationId] = useState(state.practiceLocationId || null)
    const [thActiveIndex, setThActiveIndex] = useState(0)
    const [ptActiveIndex, setPtActiveIndex] = useState(0)
    // Constants for graph 
    const graphDetailsObject = {
        credit: {
            salesAmount: 0, transactionCount: 0, subTypes: {
                VISA: { channelSubType: 'VISA', salesAmount: 0, transactionCount: 0, average: 0 },
                MASTERCARD: { channelSubType: 'MASTERCARD', salesAmount: 0, transactionCount: 0, average: 0 },
                AMEX: { channelSubType: 'AMEX', salesAmount: 0, transactionCount: 0, average: 0 },
                DISCOVER: { channelSubType: 'DISCOVER', salesAmount: 0, transactionCount: 0, average: 0 },
                DINERS: { channelSubType: 'DINERS', salesAmount: 0, transactionCount: 0, average: 0 },
                JCB: { channelSubType: 'JCB', salesAmount: 0, transactionCount: 0, average: 0 }
            }
        },
        debit: {
            salesAmount: 0, transactionCount: 0, subTypes: {
                VISA: { channelSubType: 'VISA', salesAmount: 0, transactionCount: 0, average: 0 },
                MASTERCARD: { channelSubType: 'MASTERCARD', salesAmount: 0, transactionCount: 0, average: 0 },
                AMEX: { channelSubType: 'AMEX', salesAmount: 0, transactionCount: 0, average: 0 },
                DISCOVER: { channelSubType: 'DISCOVER', salesAmount: 0, transactionCount: 0, average: 0 },
                DINERS: { channelSubType: 'DINERS', salesAmount: 0, transactionCount: 0, average: 0 },
                JCB: { channelSubType: 'JCB', salesAmount: 0, transactionCount: 0, average: 0 }
            }
        },
        ach: {
            salesAmount: 0, transactionCount: 0, subTypes: {
                WEB: { channelSubType: 'WEB', salesAmount: 0, transactionCount: 0, average: 0 },
                TEL: { channelSubType: 'TEL', salesAmount: 0, transactionCount: 0, average: 0 },
                PPD: { channelSubType: 'PPD', salesAmount: 0, transactionCount: 0, average: 0 },
                CCD: { channelSubType: 'CCD', salesAmount: 0, transactionCount: 0, average: 0 }
            }
        },
        cash: {
            salesAmount: 0, transactionCount: 0, subTypes: {}
        },
        check: {
            salesAmount: 0, transactionCount: 0, subTypes: {}
        }
    };


    // jan - dec months wise graph
    const wordMonth = moment.monthsShort().map((value, index) => ({ index: index, month: value }));

    // Others
    const color = {
        red: '#ff0000',
        blue: '#2115ff',
        green: '#3b8004',
        pink: '#cc00ff',
        yellow: '#fdff00',
        purple: '#801580',
        orange: '#FFA500',
        black: '#000000'
    };

    // Api pull for report data 
    const getData = () => {
        setData()
        setIsLoader(true)
        let reqObj = {
            EndDate: moment(endDate).toISOString(),
            StartDate: moment(startDate).toISOString(),
            offsetHour: offsetHour,
            offsetMinute: offsetMinute,
            slabBy: slabBy,
            //PracticeLocationId: state.practiceLocationId
        };
        if (locationId) {
            reqObj.PracticeLocationId = locationId
        }
        setTransactionStatusDetails()
        setReportTransactionVolumeDetails()
        setOutstandingReceivablesDetails()
        setGraphDetails()
        setPaymentCollectedTodayDetails()
        return ProviderDashboardService.getTransactionVolume(reqObj)
            .then(response => {
                if (!response) {
                    setData()
                    setTransactionStatusDetails()
                    setIsLoader(false)
                }
                else {
                    setData(response)
                    if (response !== undefined && Array.isArray(response)) {
                        response.forEach(resp => {
                            if (resp.transactionStatusReport !== undefined) {
                                setTransactionStatusDetails(resp.transactionStatusReport)
                            }
                            if (resp.patientReport !== undefined) {
                                setPatientReportDetails({
                                    before24HrPatientCount: resp.patientReport.before24HrPatientCount,
                                    customPatientCount: resp.patientReport.customPatientCount,
                                    monthlyPatientRecord: resp.patientReport.monthlyPatientRecord,
                                    totalPatientRegistered: resp.patientReport.totalPatientRegistered,
                                    yearlyPatientRecord: resp.patientReport.yearlyPatientRecord
                                })
                            }
                            if (resp.paymentCollectedToday !== undefined && resp.paymentCollectedToday[0] !== undefined) {
                                setPaymentCollectedTodayDetails({
                                    totalAmount: resp.paymentCollectedToday[0].totalAmount,
                                    totalTransactionCount: resp.paymentCollectedToday[0].totalTransactionCount
                                })

                            }
                            if (resp.outstandingReceivables !== undefined && resp.outstandingReceivables[0] !== undefined) {
                                setOutstandingReceivablesDetails({ totalOutstandingBalance: resp.outstandingReceivables[0].totalOutstandingBalance })
                            }
                            if (resp.paymetRecords !== undefined) {
                                let transactionVolumeDetails = { totalSale: {}, decline: {}, refund: {}, inProcess: {} }
                                resp.paymetRecords.operations.forEach(element => {
                                    switch (element.operationType) {
                                        case 'Sales':
                                            transactionVolumeDetails.totalSale.operationType = element.operationType;
                                            transactionVolumeDetails.totalSale.salesAmount = element.salesAmount;
                                            transactionVolumeDetails.totalSale.transactionCount = element.transactionCount;
                                            element.channels.forEach(element1 => {
                                                if (element1 !== undefined) {
                                                    switch (element1.channelType) {
                                                        case 'CC':
                                                            transactionVolumeDetails.totalSale.creditCard = element1;
                                                            break;
                                                        case 'Debit':
                                                            transactionVolumeDetails.totalSale.debitCard = element1;
                                                            break;
                                                        case 'ACH':
                                                            transactionVolumeDetails.totalSale.ach = element1;
                                                            break;
                                                        case 'Cash':
                                                            transactionVolumeDetails.totalSale.cash = element1;
                                                            break;
                                                        case 'Check':
                                                            transactionVolumeDetails.totalSale.check = element1;
                                                            break;
                                                        case 'CreditBalance':
                                                            transactionVolumeDetails.totalSale.creditBalance = element1;
                                                            break;
                                                        case 'Terminal':
                                                            transactionVolumeDetails.totalSale.swipe = element1;
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }
                                            });
                                            break;
                                        case 'Denied':
                                            transactionVolumeDetails.decline.operationType = element.operationType;
                                            transactionVolumeDetails.decline.salesAmount = element.salesAmount;
                                            transactionVolumeDetails.decline.transactionCount = element.transactionCount;
                                            element.channels.forEach(element1 => {
                                                if (element1 !== undefined) {
                                                    switch (element1.channelType) {
                                                        case 'CC':
                                                            transactionVolumeDetails.decline.creditCard = element1;
                                                            break;
                                                        case 'Debit':
                                                            transactionVolumeDetails.decline.debitCard = element1;
                                                            break;
                                                        case 'ACH':
                                                            transactionVolumeDetails.decline.ach = element1;
                                                            break;
                                                        case 'Cash':
                                                            transactionVolumeDetails.decline.cash = element1;
                                                            break;
                                                        case 'Check':
                                                            transactionVolumeDetails.decline.check = element1;
                                                            break;
                                                        case 'CreditBalance':
                                                            transactionVolumeDetails.decline.creditBalance = element1;
                                                            break;
                                                        case 'Terminal':
                                                            transactionVolumeDetails.decline.swipe = element1;
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }
                                            });
                                            break;
                                        case 'Refunded':
                                            transactionVolumeDetails.refund.operationType = element.operationType;
                                            transactionVolumeDetails.refund.salesAmount = element.salesAmount;
                                            transactionVolumeDetails.refund.transactionCount = element.transactionCount;
                                            element.channels.forEach(element1 => {
                                                if (element1 !== undefined) {
                                                    switch (element1.channelType) {
                                                        case 'CC':
                                                            transactionVolumeDetails.refund.creditCard = element1;
                                                            break;
                                                        case 'Debit':
                                                            transactionVolumeDetails.refund.debitCard = element1;
                                                            break;
                                                        case 'ACH':
                                                            transactionVolumeDetails.refund.ach = element1;
                                                            break;
                                                        case 'Cash':
                                                            transactionVolumeDetails.refund.cash = element1;
                                                            break;
                                                        case 'Check':
                                                            transactionVolumeDetails.refund.check = element1;
                                                            break;
                                                        case 'CreditBalance':
                                                            transactionVolumeDetails.refund.creditBalance = element1;
                                                            break;
                                                        case 'Terminal':
                                                            transactionVolumeDetails.refund.swipe = element1;
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }
                                            });
                                            break;
                                        case 'InProcess':
                                            transactionVolumeDetails.inProcess.operationType = element.operationType;
                                            transactionVolumeDetails.inProcess.salesAmount = element.salesAmount;
                                            transactionVolumeDetails.inProcess.transactionCount = element.transactionCount;
                                            element.channels.forEach(element1 => {
                                                if (element1 !== undefined) {
                                                    switch (element1.channelType) {
                                                        case 'CC':
                                                            transactionVolumeDetails.inProcess.creditCard = element1;
                                                            break;
                                                        case 'Debit':
                                                            transactionVolumeDetails.inProcess.debitCard = element1;
                                                            break;
                                                        case 'ACH':
                                                            transactionVolumeDetails.inProcess.ach = element1;
                                                            break;
                                                        case 'Cash':
                                                            transactionVolumeDetails.inProcess.cash = element1;
                                                            break;
                                                        case 'Check':
                                                            transactionVolumeDetails.inProcess.check = element1;
                                                            break;
                                                        case 'CreditBalance':
                                                            transactionVolumeDetails.inProcess.creditBalance = element1;
                                                            break;
                                                        case 'Terminal':
                                                            transactionVolumeDetails.inProcess.swipe = element1;
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }
                                            });

                                            break;
                                        default:
                                            break;
                                    }
                                    setReportTransactionVolumeDetails(transactionVolumeDetails)
                                    setIsLoader(false)
                                    // prepareGraphObject(selectedDateRangeForGraph, data);
                                    // return prepareGraphDetailsObject()

                                });
                            }
                        });
                    }
                    return setIsLoader(false)
                }
            })
            .catch(err => {
                setData()
                setTransactionStatusDetails()
                setIsLoader(false)
            })
    }
    const columns = [
        {
            id: "channelType",
            Header: "Payment Method",
            accessor: (tc) => { return tc.channelType === 'CC' ? `Credit` : tc.channelType == 'CreditBalance' ? 'Prepaid Balance' : tc.channelType == 'Terminal' ? 'Swipe' : `${tc.channelType}` },
            Footer: () => 'Totals'
        },
        {
            // id: "operationType", //Only needed if accessor if function
            Header: "Transaction Type",
            accessor: (tc) => tc.operationType
        },
        {
            // id: "transactionStatus",
            Header: "Transaction Status",
            accessor: (tc) => tc.transactionStatus
        },
        {
            // id: "totalTransactions",
            Header: "Total Transactions",
            accessor: (tc) => tc.totalTransactions,
            Footer: info => {
                // Only calculate total visits if rows change
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.totalTransactions + sum, 0),
                    [info.rows]
                )

                return <>{total}</>
            },
        },
        {
            id: "totalSalesAmount",
            Header: "Total Sales Amount",
            accessor: (tc) => Utilities.toDollar(tc.totalSalesAmount),
            Footer: info => {
                // Only calculate total visits if rows change
                const total = React.useMemo(
                    () =>
                        info.rows.reduce((sum, row) => row.original.totalSalesAmount + sum, 0),
                    [info.rows]
                )

                return <>{Utilities.toDollar(total)}</>
            },
        },
    ]

    useEffect(() => {
        if (transactionStatusDetails && reportTransactionVolumeDetails) { prepareGraphDetailsObject() }
    }, [transactionStatusDetails, reportTransactionVolumeDetails])

    const onChangeSlabs = (data, { wordMonth: number }) => {
        let reqObj = {};
        let selectedTab = data;
        switch (data) {
            case 'year':
                reqObj.EndDate = moment(selectedDateForReport).endOf('y').toISOString();
                reqObj.StartDate = moment(selectedDateForReport).startOf('y').toISOString();
                reqObj.offsetHour = moment().utcOffset() >= 0 ? Math.floor(moment().utcOffset() / 60) : Math.ceil(moment().utcOffset() / 60);
                reqObj.offsetMinute = (moment().utcOffset() % 60);
                reqObj.slabBy = 'Month';

                break;
            case '3month':
                reqObj.EndDate = moment().endOf('d').toISOString();
                reqObj.StartDate = moment().subtract(3, 'months').startOf('d').toISOString();
                reqObj.offsetHour = moment().utcOffset() >= 0 ? Math.floor(moment().utcOffset() / 60) : Math.ceil(moment().utcOffset() / 60);
                reqObj.offsetMinute = (moment().utcOffset() % 60);
                reqObj.slabBy = 'Week';
                break;
            case '1month':
                reqObj.EndDate = moment(selectedDateForReport).endOf('month').toISOString();
                reqObj.StartDate = moment(selectedDateForReport).startOf('month').toISOString();
                reqObj.offsetHour = moment().utcOffset() >= 0 ? Math.floor(moment().utcOffset() / 60) : Math.ceil(moment().utcOffset() / 60);
                reqObj.offsetMinute = (moment().utcOffset() % 60);
                reqObj.slabBy = 'DayMonth';
                break;
            case 'newmonth':
                reqObj.EndDate = moment(selectedDateForReport).endOf('month').toISOString();
                reqObj.StartDate = moment(selectedDateForReport).startOf('month').toISOString();
                reqObj.offsetHour = moment().utcOffset() >= 0 ? Math.floor(moment().utcOffset() / 60) : Math.ceil(moment().utcOffset() / 60);
                reqObj.offsetMinute = (moment().utcOffset() % 60);
                reqObj.slabBy = 'DayMonth';
                break;
            case 'customDayOfMonth':
                reqObj.EndDate = moment(selectedCustomDateRangeForReport.endDate).endOf('d').toISOString();
                reqObj.StartDate = moment(selectedCustomDateRangeForReport.startDate).startOf('d').toISOString();
                reqObj.offsetHour = moment().utcOffset() >= 0 ? Math.floor(moment().utcOffset() / 60) : Math.ceil(moment().utcOffset() / 60);
                reqObj.offsetMinute = (moment().utcOffset() % 60);
                reqObj.slabBy = 'DayMonth';
                break;
            case 'customWeekOfMonth':
                reqObj.EndDate = moment(selectedCustomDateRangeForReport.endDate).endOf('d').toISOString();
                reqObj.StartDate = moment(selectedCustomDateRangeForReport.startDate).startOf('d').toISOString();
                reqObj.offsetHour = moment().utcOffset() >= 0 ? Math.floor(moment().utcOffset() / 60) : Math.ceil(moment().utcOffset() / 60);
                reqObj.offsetMinute = (moment().utcOffset() % 60);
                reqObj.slabBy = 'Week';
                break;
            case 'customMonthOfYear':
                reqObj.EndDate = moment(selectedCustomDateRangeForReport.endDate).endOf('d').toISOString();
                reqObj.StartDate = moment(selectedCustomDateRangeForReport.startDate).startOf('d').toISOString();
                reqObj.offsetHour = moment().utcOffset() >= 0 ? Math.floor(moment().utcOffset() / 60) : Math.ceil(moment().utcOffset() / 60);
                reqObj.offsetMinute = (moment().utcOffset() % 60);
                reqObj.slabBy = 'CustomYear';
                break;
            case 'week':
                reqObj.EndDate = moment(selectedDateForReport).endOf('d').toISOString();
                reqObj.StartDate = moment(selectedDateForReport).subtract(6, 'd').startOf('d').toISOString();
                reqObj.offsetHour = moment().utcOffset() >= 0 ? Math.floor(moment().utcOffset() / 60) : Math.ceil(moment().utcOffset() / 60);
                reqObj.offsetMinute = (moment().utcOffset() % 60);
                reqObj.slabBy = 'Day';
                break;
            case 'day':
                reqObj.EndDate = moment(selectedDateForReport).endOf('d').toISOString();
                reqObj.StartDate = moment(selectedDateForReport).startOf('d').toISOString();
                reqObj.offsetHour = moment().utcOffset() >= 0 ? Math.floor(moment().utcOffset() / 60) : Math.ceil(moment().utcOffset() / 60);
                reqObj.offsetMinute = (moment().utcOffset() % 60);
                reqObj.slabBy = 'Hour';
                break;
            default:
                break;
        }
        newInitialReqObject = reqObj;
        getTransactionVolume(reqObj);
    }


    const drawLineChart = () => {
        setLineChart({
            type: 'line',
            data: {
                labels: lineChartLabels || 'no labels',
                datasets: dataSet || 'no data'
            },
            options: {
                legend: { display: false },
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: false,
                            maxRotation: 90,
                            minRotation: 90
                        }
                    }],
                    yAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero: true,
                        }
                    }],
                },
                tooltips: {
                    mode: 'index', displayColors: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return data.datasets[tooltipItem.datasetIndex].label + ': $' + formatNumber(Number(tooltipItem.yLabel), "en-US", '1.2-2');
                        },
                        // footer: footer
                    }
                }
            }
        })
        return console.log(lineChart)
    }

    const resetTransactionVolumeDetailsObject = () => {
        setReportTransactionVolumeDetails({
            totalSale: {
                operationType: '',
                salesAmount: 0,
                transactionCount: 0,
                creditCard: { salesAmount: 0, slabs: [], transactionCount: 0 },
                debitCard: { salesAmount: 0, slabs: [], transactionCount: 0 },
                ach: { salesAmount: 0, slabs: [], transactionCount: 0 },
                cash: { salesAmount: 0, slabs: [], transactionCount: 0 },
                check: { salesAmount: 0, slabs: [], transactionCount: 0 },
                showDetails: (selectedTransactionVolumeType === 'totalSale') ? true : false
            },
            decline: {
                operationType: '',
                salesAmount: 0,
                transactionCount: 0,
                creditCard: { salesAmount: 0, slabs: [], transactionCount: 0 },
                debitCard: { salesAmount: 0, slabs: [], transactionCount: 0 },
                ach: { salesAmount: 0, slabs: [], transactionCount: 0 },
                cash: { salesAmount: 0, slabs: [], transactionCount: 0 },
                check: { salesAmount: 0, slabs: [], transactionCount: 0 },
                showDetails: (selectedTransactionVolumeType === 'decline') ? true : false
            },
            refund: {
                operationType: '',
                salesAmount: 0,
                transactionCount: 0,
                creditCard: { salesAmount: 0, slabs: [], transactionCount: 0 },
                debitCard: { salesAmount: 0, slabs: [], transactionCount: 0 },
                ach: { salesAmount: 0, slabs: [], transactionCount: 0 },
                cash: { salesAmount: 0, slabs: [], transactionCount: 0 },
                check: { salesAmount: 0, slabs: [], transactionCount: 0 },
                showDetails: (selectedTransactionVolumeType === 'refund') ? true : false
            },
            inProcess: {
                operationType: '', salesAmount: 0, transactionCount: 0,
                creditCard: { salesAmount: 0, slabs: [], transactionCount: 0 },
                debitCard: { salesAmount: 0, slabs: [], transactionCount: 0 },
                ach: { salesAmount: 0, slabs: [], transactionCount: 0 },
                cash: { salesAmount: 0, slabs: [], transactionCount: 0 },
                check: { salesAmount: 0, slabs: [], transactionCount: 0 },
                showDetails: (selectedTransactionVolumeType === 'inProcess') ? true : false
            },
        })
    }
    const prepareGraphDetailsObject = () => {
        let graphDetailsObject = {
            credit: {
                salesAmount: 0, transactionCount: 0, subTypes: {
                    VISA: { channelSubType: 'VISA', salesAmount: 0, transactionCount: 0, average: 0 },
                    MASTERCARD: { channelSubType: 'MASTERCARD', salesAmount: 0, transactionCount: 0, average: 0 },
                    AMEX: { channelSubType: 'AMEX', salesAmount: 0, transactionCount: 0, average: 0 },
                    DISCOVER: { channelSubType: 'DISCOVER', salesAmount: 0, transactionCount: 0, average: 0 },
                    DINERS: { channelSubType: 'DINERS', salesAmount: 0, transactionCount: 0, average: 0 },
                    JCB: { channelSubType: 'JCB', salesAmount: 0, transactionCount: 0, average: 0 }
                }
            },
            debit: {
                salesAmount: 0, transactionCount: 0, subTypes: {
                    VISA: { channelSubType: 'VISA', salesAmount: 0, transactionCount: 0, average: 0 },
                    MASTERCARD: { channelSubType: 'MASTERCARD', salesAmount: 0, transactionCount: 0, average: 0 },
                    AMEX: { channelSubType: 'AMEX', salesAmount: 0, transactionCount: 0, average: 0 },
                    DISCOVER: { channelSubType: 'DISCOVER', salesAmount: 0, transactionCount: 0, average: 0 },
                    DINERS: { channelSubType: 'DINERS', salesAmount: 0, transactionCount: 0, average: 0 },
                    JCB: { channelSubType: 'JCB', salesAmount: 0, transactionCount: 0, average: 0 }
                }
            },
            ach: {
                salesAmount: 0, transactionCount: 0, subTypes: {
                    WEB: { channelSubType: 'WEB', salesAmount: 0, transactionCount: 0, average: 0 },
                    TEL: { channelSubType: 'TEL', salesAmount: 0, transactionCount: 0, average: 0 },
                    PPD: { channelSubType: 'PPD', salesAmount: 0, transactionCount: 0, average: 0 },
                    CCD: { channelSubType: 'CCD', salesAmount: 0, transactionCount: 0, average: 0 }
                }
            },
            cash: {
                salesAmount: 0, transactionCount: 0, subTypes: {}
            },
            check: {
                salesAmount: 0, transactionCount: 0, subTypes: {}
            },
            creditBalance: {
                salesAmount: 0, transactionCount: 0, subTypes: {}
            },
            swipe: {
                salesAmount: 0, transactionCount: 0, subTypes: {}
            }
        };
        setGraphDetails(graphDetailsObject)
        let transactionVolumeDetails = reportTransactionVolumeDetails
        graphDetailsObject.credit.salesAmount = transactionVolumeDetails ? transactionVolumeDetails[selectedTransactionVolumeType].creditCard?.salesAmount : 0
        graphDetailsObject.credit.transactionCount = transactionVolumeDetails ? transactionVolumeDetails[selectedTransactionVolumeType].creditCard?.transactionCount : 0
        graphDetailsObject.credit.average = (graphDetailsObject.credit.transactionCount === 0) ? 0 : graphDetailsObject.credit.salesAmount / graphDetailsObject.credit.transactionCount
        graphDetailsObject.debit.salesAmount = transactionVolumeDetails[selectedTransactionVolumeType].debitCard ? transactionVolumeDetails[selectedTransactionVolumeType].debitCard?.salesAmount : 0
        graphDetailsObject.debit.transactionCount = transactionVolumeDetails[selectedTransactionVolumeType].debitCard ? transactionVolumeDetails[selectedTransactionVolumeType].debitCard?.transactionCount : 0
        graphDetailsObject.debit.average = (graphDetailsObject.debit.transactionCount === 0) ? 0 : graphDetailsObject.debit.salesAmount / graphDetailsObject.debit.transactionCount
        graphDetailsObject.ach.salesAmount = transactionVolumeDetails[selectedTransactionVolumeType].ach ? transactionVolumeDetails[selectedTransactionVolumeType].ach?.salesAmount : 0
        graphDetailsObject.ach.transactionCount = transactionVolumeDetails[selectedTransactionVolumeType].ach ? transactionVolumeDetails[selectedTransactionVolumeType].ach?.transactionCount : 0
        graphDetailsObject.ach.average = (graphDetailsObject.ach.transactionCount === 0) ? 0 : graphDetailsObject.ach.salesAmount / graphDetailsObject.ach.transactionCount

        graphDetailsObject.cash.salesAmount = transactionVolumeDetails[selectedTransactionVolumeType].cash ? transactionVolumeDetails[selectedTransactionVolumeType].cash?.salesAmount : 0
        graphDetailsObject.cash.transactionCount = transactionVolumeDetails[selectedTransactionVolumeType].cash ? transactionVolumeDetails[selectedTransactionVolumeType].cash?.transactionCount : 0
        graphDetailsObject.cash.average = (graphDetailsObject.cash.transactionCount === 0) ? 0 : graphDetailsObject.cash.salesAmount / graphDetailsObject.cash.transactionCount

        graphDetailsObject.check.salesAmount = transactionVolumeDetails[selectedTransactionVolumeType].check ? transactionVolumeDetails[selectedTransactionVolumeType].check?.salesAmount : 0
        graphDetailsObject.check.transactionCount = transactionVolumeDetails[selectedTransactionVolumeType].check ? transactionVolumeDetails[selectedTransactionVolumeType].check?.transactionCount : 0
        graphDetailsObject.check.average = (graphDetailsObject.check.transactionCount === 0) ? 0 : graphDetailsObject.check.salesAmount / graphDetailsObject.check.transactionCount

        graphDetailsObject.creditBalance.salesAmount = transactionVolumeDetails[selectedTransactionVolumeType].creditBalance ? transactionVolumeDetails[selectedTransactionVolumeType].creditBalance?.salesAmount : 0
        graphDetailsObject.creditBalance.transactionCount = transactionVolumeDetails[selectedTransactionVolumeType].creditBalance ? transactionVolumeDetails[selectedTransactionVolumeType].creditBalance?.transactionCount : 0
        graphDetailsObject.creditBalance.average = (graphDetailsObject.creditBalance.transactionCount === 0) ? 0 : graphDetailsObject.creditBalance.salesAmount / graphDetailsObject.creditBalance.transactionCount

        graphDetailsObject.swipe.salesAmount = transactionVolumeDetails[selectedTransactionVolumeType].swipe ? transactionVolumeDetails[selectedTransactionVolumeType].swipe?.salesAmount : 0
        graphDetailsObject.swipe.transactionCount = transactionVolumeDetails[selectedTransactionVolumeType].swipe ? transactionVolumeDetails[selectedTransactionVolumeType].swipe?.transactionCount : 0
        graphDetailsObject.swipe.average = (graphDetailsObject.swipe.transactionCount === 0) ? 0 : graphDetailsObject.swipe.salesAmount / graphDetailsObject.swipe.transactionCount

        let slabs1 = transactionVolumeDetails[selectedTransactionVolumeType].creditCard ? transactionVolumeDetails[selectedTransactionVolumeType].creditCard.slabs : null
        if (slabs1 !== null) {
            slabs1.forEach(slabElement => {
                slabElement?.subTypes.forEach(subTypeElement => {

                    if (graphDetailsObject.credit.subTypes[subTypeElement.channelSubType] == undefined) {
                        graphDetailsObject.credit.subTypes[subTypeElement.channelSubType] = {};
                    }
                    const salesAmount = (graphDetailsObject.credit.subTypes[subTypeElement.channelSubType].salesAmount == undefined) ? 0 : graphDetailsObject.credit.subTypes[subTypeElement.channelSubType].salesAmount;
                    const transactionCount = (graphDetailsObject.credit.subTypes[subTypeElement.channelSubType].transactionCount == undefined) ? 0 : graphDetailsObject.credit.subTypes[subTypeElement.channelSubType].transactionCount;
                    graphDetailsObject.credit.subTypes[subTypeElement.channelSubType].salesAmount = salesAmount + subTypeElement.salesAmount;
                    graphDetailsObject.credit.subTypes[subTypeElement.channelSubType].transactionCount = transactionCount + subTypeElement.transactionCount;
                    graphDetailsObject.credit.subTypes[subTypeElement.channelSubType].channelSubType = subTypeElement.channelSubType;
                    graphDetailsObject.credit.subTypes[subTypeElement.channelSubType].average = graphDetailsObject.credit.subTypes[subTypeElement.channelSubType].salesAmount / graphDetailsObject.credit.subTypes[subTypeElement.channelSubType].transactionCount;
                });
            });
        }
        let slabs2 = transactionVolumeDetails[selectedTransactionVolumeType].debitCard ? transactionVolumeDetails[selectedTransactionVolumeType].debitCard.slabs : null
        if (slabs2 !== null) {
            slabs2.forEach(slabElement => {
                slabElement?.subTypes.forEach(subTypeElement => {
                    if (graphDetailsObject.debit.subTypes[subTypeElement.channelSubType] == undefined) {
                        graphDetailsObject.debit.subTypes[subTypeElement.channelSubType] = {};
                    }
                    const salesAmount = (graphDetailsObject.debit.subTypes[subTypeElement.channelSubType].salesAmount == undefined) ? 0 : graphDetailsObject.debit.subTypes[subTypeElement.channelSubType].salesAmount;
                    const transactionCount = (graphDetailsObject.debit.subTypes[subTypeElement.channelSubType].transactionCount == undefined) ? 0 : graphDetailsObject.debit.subTypes[subTypeElement.channelSubType].transactionCount;
                    graphDetailsObject.debit.subTypes[subTypeElement.channelSubType].salesAmount = salesAmount + subTypeElement.salesAmount;
                    graphDetailsObject.debit.subTypes[subTypeElement.channelSubType].transactionCount = transactionCount + subTypeElement.transactionCount;
                    graphDetailsObject.debit.subTypes[subTypeElement.channelSubType].channelSubType = subTypeElement.channelSubType;
                    graphDetailsObject.debit.subTypes[subTypeElement.channelSubType].average = graphDetailsObject.debit.subTypes[subTypeElement.channelSubType].salesAmount / graphDetailsObject.debit.subTypes[subTypeElement.channelSubType].transactionCount;
                });
            });
        }
        let slabs3 = transactionVolumeDetails[selectedTransactionVolumeType].ach ? transactionVolumeDetails[selectedTransactionVolumeType].ach.slabs : null
        if (slabs3 !== null) {
            slabs3.forEach(slabElement => {
                slabElement?.subTypes.forEach(subTypeElement => {
                    if (graphDetailsObject.ach.subTypes[subTypeElement.channelSubType] == undefined) {
                        graphDetailsObject.ach.subTypes[subTypeElement.channelSubType] = {};
                    }
                    const salesAmount = (graphDetailsObject.ach.subTypes[subTypeElement.channelSubType].salesAmount == undefined) ? 0 : graphDetailsObject.ach.subTypes[subTypeElement.channelSubType].salesAmount;
                    const transactionCount = (graphDetailsObject.ach.subTypes[subTypeElement.channelSubType].transactionCount == undefined) ? 0 : graphDetailsObject.ach.subTypes[subTypeElement.channelSubType].transactionCount;
                    graphDetailsObject.ach.subTypes[subTypeElement.channelSubType].salesAmount = salesAmount + subTypeElement.salesAmount;
                    graphDetailsObject.ach.subTypes[subTypeElement.channelSubType].transactionCount = transactionCount + subTypeElement.transactionCount;
                    graphDetailsObject.ach.subTypes[subTypeElement.channelSubType].channelSubType = subTypeElement.channelSubType;
                    graphDetailsObject.ach.subTypes[subTypeElement.channelSubType].average = graphDetailsObject.ach.subTypes[subTypeElement.channelSubType].salesAmount / graphDetailsObject.ach.subTypes[subTypeElement.channelSubType].transactionCount;
                });
            });
        }
        console.log("finished graph Details", graphDetailsObject)
        // setSlabs(slabs)
        setGraphDetails(graphDetailsObject)
    }

    const icons = {
        blank: 'credit-card',
        AMEX: 'cc amex',
        DINERS: 'cc diners club',
        DISCOVER: 'cc discover',
        JCB: 'cc jcb',
        MASTERCARD: 'cc mastercard',
        VISA: 'cc visa'
    }

    const cards = [
        'AMEX', 'DINERS', 'DISCOVER', 'JCB', 'MASTERCARD', 'VISA'
    ]

    const banks = [
        'CCD', 'PAYFORM', 'PPD', 'TEL', 'WEB '
    ]

    const findLocations = () => {
        AppointmentService.practiceLocationLookup()
            .then((res) => {
                setLocationList(res)
                setLocationId(res[0].practiceLocationId)

            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        if (!locationList) {
            if (StorageService.get("session", "locale")) {
                let payload = JSON.parse(StorageService.get("session", "locale"))
                setLocationList(payload)
            }
            else {
                findLocations();
            }
        }
        getData()
    }, [])

    return (
        <div
            className="row-fluid"
            id="primary"
            role="tabpanel"
            aria-labelledby="primary-tab"
        >
            <div className="row g-3">
                <div className='col-12 m-p-0'>
                    <Module title="Filters">
                        <div className='row d-flex'>
                            <div className='col-12 row d-flex justify-content-center mx-0 align-items-end report-filters'>
                                <DatePicker locationClearable={true} setLocationId={setLocationId} startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} />
                                <div className="col-md-auto col-12"><button className="btn btn-primary" onClick={e => { e.preventDefault(); getData(); }} title="Pull Report"><i className="icon arrow circle right" /></button></div>
                            </div>
                        </div>
                    </Module>
                </div>
                <div className={`${props.stacked ? 'col-12' : 'col-md-6'} col-12 m-p-0`}>
                    <Module
                        title="Transaction History"
                        id="transactionHistory"
                        accordianId="transactionHistory"
                        icon="chart pie"
                        modalSize="small"
                    >
                        {!isLoader ? <Accordion fluid styled>
                            <Accordion.Title
                                active={thActiveIndex === 0}
                                index={0}
                                onClick={e => { e.preventDefault(); if (thActiveIndex === 0) { setThActiveIndex() } else { setThActiveIndex(0) } }}
                            > <Icon name='dropdown' />
                                <i className="icon calculator green" />
                                <span className="w-100px">
                                    Payments</span><span>{reportTransactionVolumeDetails?.totalSale?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails.totalSale.salesAmount) : '$0.00'}
                                </span>
                            </Accordion.Title>
                            <Accordion.Content active={thActiveIndex === 0}>
                                <div className="col-12 d-flex row ps-4">
                                    <div className="col-12">
                                        <i className="credit card outline icon" />
                                        <span className="w-100px">Credit</span><span>{reportTransactionVolumeDetails?.totalSale?.creditCard?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.totalSale?.creditCard?.salesAmount) : '$0.00'}</span>
                                    </div>
                                    <div className="col-12">
                                        <i className="credit card outline icon" />
                                        <span className="w-100px">Debit</span><span>{reportTransactionVolumeDetails?.totalSale?.debitCard?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.totalSale?.debitCard?.salesAmount) : '$0.00'}</span>
                                    </div>

                                    <div className="col-12">
                                        <i className="money bill alternate outline icon" />
                                        <span className="w-100px">ACH</span><span>{reportTransactionVolumeDetails?.totalSale?.ach?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.totalSale?.ach?.salesAmount) : '$0.00'}</span>
                                    </div>

                                    <div className="col-12">
                                        <i className="money bill alternate outline icon" />
                                        <span className="w-100px">Cash</span><span>{reportTransactionVolumeDetails?.totalSale?.cash?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.totalSale?.cash?.salesAmount) : '$0.00'}</span>
                                    </div>

                                    <div className="col-12">
                                        <i className="money bill alternate outline icon" />
                                        <span className="w-100px">Check</span><span>{reportTransactionVolumeDetails?.totalSale?.check?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.totalSale?.check?.salesAmount) : `$0.00`}</span>
                                    </div>
                                    <div className="col-12">
                                        <i className="money bill alternate outline icon" />
                                        <span className="w-100px">Credit Balance</span><span>{reportTransactionVolumeDetails?.totalSale?.creditBalance?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.totalSale?.creditBalance?.salesAmount) : `$0.00`}</span>
                                    </div>
                                    <div className="col-12">
                                        <i className="calculator icon" />
                                        <span className="w-100px">Swipe</span><span>{reportTransactionVolumeDetails?.totalSale?.swipe?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.totalSale?.swipe?.salesAmount) : `$0.00`}</span>
                                    </div>
                                </div>
                            </Accordion.Content>
                            <Accordion.Title
                                active={thActiveIndex === 1}
                                index={1}
                                onClick={e => { e.preventDefault(); if (thActiveIndex === 1) { setThActiveIndex() } else { setThActiveIndex(1) } }}
                            > <Icon name='dropdown' />
                                <i className="calendar minus outline middle aligned icon blue col-2" />
                                <span className="w-100px">Refunds </span><span>{reportTransactionVolumeDetails?.refund?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.refund?.salesAmount) : '$0.00'}</span>
                            </Accordion.Title>
                            <Accordion.Content active={thActiveIndex === 1}>
                                <div className="col-12 d-flex row ps-4">
                                    <div className="col-12">
                                        <i className="credit card outline icon" />
                                        <span className="w-100px">Credit</span><span>{reportTransactionVolumeDetails?.refund?.creditCard?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.refund?.creditCard?.salesAmount) : '$0.00'}</span>
                                    </div>
                                    <div className="col-12">
                                        <i className="credit card outline icon" />
                                        <span className="w-100px">Debit</span><span>{reportTransactionVolumeDetails?.refund?.debitCard?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.refund?.debitCard?.salesAmount) : '$0.00'}</span>
                                    </div>

                                    <div className="col-12">
                                        <i className="money bill alternate outline icon" />
                                        <span className="w-100px">ACH</span><span>{reportTransactionVolumeDetails?.refund?.ach?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.refund?.ach?.salesAmount) : '$0.00'}</span>
                                    </div>

                                    <div className="col-12">
                                        <i className="money bill alternate outline icon" />
                                        <span className="w-100px">Cash</span><span>{reportTransactionVolumeDetails?.refund?.cash?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.refund?.cash?.salesAmount) : '$0.00'}</span>
                                    </div>

                                    <div className="col-12">
                                        <i className="money bill alternate outline icon" />
                                        <span className="w-100px">Check</span><span>{reportTransactionVolumeDetails?.refund?.check?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.refund?.check?.salesAmount) : '$0.00'}</span>
                                    </div>
                                    <div className="col-12">
                                        <i className="money bill alternate outline icon" />
                                        <span className="w-100px">Credit Balance</span><span>{reportTransactionVolumeDetails?.refund?.creditBalance?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.refund?.creditBalance?.salesAmount) : `$0.00`}</span>
                                    </div>
                                    <div className="col-12">
                                        <i className="calculator icon" />
                                        <span className="w-100px">Swipe</span><span>{reportTransactionVolumeDetails?.refund?.swipe?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.refund?.swipe?.salesAmount) : `$0.00`}</span>
                                    </div>
                                </div>
                            </Accordion.Content>
                            <Accordion.Title
                                active={thActiveIndex === 2}
                                index={2}
                                onClick={e => { e.preventDefault(); if (thActiveIndex === 2) { setThActiveIndex() } else { setThActiveIndex(2) } }}
                            > <Icon name='dropdown' />
                                <i className="calendar plus outline middle aligned icon red col-2" />
                                <span className="w-100px">Declines</span><span>{reportTransactionVolumeDetails && Utilities.toDollar(parseInt(reportTransactionVolumeDetails.decline.salesAmount || 0))}</span>
                            </Accordion.Title>
                            <Accordion.Content active={thActiveIndex === 2}>
                                <div className="col-12 d-flex row ps-4">
                                    <div className="col-12">
                                        <i className="credit card outline icon" />
                                        <span className="w-100px">Credit</span><span>{reportTransactionVolumeDetails?.decline?.creditCard?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.decline?.creditCard?.salesAmount) : '$0.00'}</span>
                                    </div>
                                    <div className="col-12">
                                        <i className="credit card outline icon" />
                                        <span className="w-100px">Debit</span><span>{reportTransactionVolumeDetails?.decline?.debitCard?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.decline?.debitCard?.salesAmount) : '$0.00'}</span>
                                    </div>

                                    <div className="col-12">
                                        <i className="money bill alternate outline icon" />
                                        <span className="w-100px">ACH</span><span>{reportTransactionVolumeDetails?.decline?.ach?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.decline?.ach?.salesAmount) : '$0.00'}</span>
                                    </div>

                                    <div className="col-12">
                                        <i className="money bill alternate outline icon" />
                                        <span className="w-100px">Cash</span><span>{reportTransactionVolumeDetails?.decline?.cash?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.decline?.cash?.salesAmount) : '$0.00'}</span>
                                    </div>

                                    <div className="col-12">
                                        <i className="money bill alternate outline icon" />
                                        <span className="w-100px">Check</span><span>{reportTransactionVolumeDetails?.decline?.check?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.decline?.check?.salesAmount) : '$0.00'}</span>
                                    </div>
                                    <div className="col-12">
                                        <i className="money bill alternate outline icon" />
                                        <span className="w-100px">Credit Balance</span><span>{reportTransactionVolumeDetails?.decline?.creditBalance?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.decline?.creditBalance?.salesAmount) : `$0.00`}</span>
                                    </div>
                                    <div className="col-12">
                                        <i className="calculator icon" />
                                        <span className="w-100px">Swipe</span><span>{reportTransactionVolumeDetails?.decline?.swipe?.salesAmount ? Utilities.toDollar(reportTransactionVolumeDetails?.decline?.swipe?.salesAmount) : `$0.00`}</span>
                                    </div>
                                </div>
                            </Accordion.Content>
                        </Accordion> : <span>Please be patient while data loads...</span>}
                    </Module>
                </div >
                <div className={`${props.stacked ? 'col-12' : 'col-md-6'} col-12 m-p-0`}>
                    <Module
                        title="Payments by Type"
                        icon=""
                        modalSize="small"
                    >
                        {!isLoader ? <Accordion fluid styled>
                            <Accordion.Title
                                active={ptActiveIndex === 0}
                                index={0}
                                onClick={e => { e.preventDefault(); if (ptActiveIndex === 0) { setPtActiveIndex() } else { setPtActiveIndex(0) } }}
                            >
                                {graphDetails && <Icon name='dropdown' />}
                                <i className={`credit card outline icon ${!graphDetails && 'ps-4 pe-3'}`} />
                                <span className="w-100px">Credit</span>{Utilities.toDollar(graphDetails?.credit?.salesAmount || 0)}
                            </Accordion.Title>
                            <Accordion.Content active={ptActiveIndex === 0 && graphDetails}>
                                {graphDetails && cards.map((card, i) => {
                                    if (graphDetails.credit.subTypes[card]) {
                                        return (
                                            <div className="col-12 ps-4">
                                                <i className={`icon ${icons[graphDetails.credit.subTypes[card].channelSubType]}`} />
                                                <span className="w-100px">{graphDetails.credit.subTypes[card].channelSubType}</span><span>{graphDetails.credit.subTypes[card].salesAmount ? Utilities.toDollar(graphDetails.credit.subTypes[card].salesAmount) : '$0.00'}</span>
                                            </div>
                                        )
                                    }
                                })}
                            </Accordion.Content>
                            <Accordion.Title
                                active={ptActiveIndex === 1}
                                index={1}
                                onClick={e => { e.preventDefault(); if (ptActiveIndex === 1) { setPtActiveIndex() } else { setPtActiveIndex(1) } }}
                            >
                                {graphDetails && <Icon name='dropdown' />}
                                <i className={`credit card outline icon ${!graphDetails && 'ps-4 pe-3'}`} />
                                <span className="w-100px">ACH</span>{Utilities.toDollar(graphDetails?.ach?.salesAmount || 0)}
                            </Accordion.Title>
                            <Accordion.Content active={ptActiveIndex === 1 && graphDetails}>
                                {graphDetails && banks.map((bank, i) => {
                                    if (graphDetails.ach.subTypes[bank]) {
                                        return (
                                            <div className="col-12 ps-4">
                                                <i className={`icon dollar`} />
                                                <span className="w-100px">{graphDetails.ach.subTypes[bank].channelSubType}</span><span>{graphDetails.ach.subTypes[bank].salesAmount ? Utilities.toDollar(graphDetails.ach.subTypes[bank].salesAmount) : '$0.00'}</span>
                                            </div>
                                        )
                                    }
                                })}
                            </Accordion.Content>
                            <Accordion.Title
                                active={true}
                                index={2}
                            // onClick={e => { e.preventDefault(); if (ptActiveIndex === 2) { setPtActiveIndex() } else { setPtActiveIndex(2) } }}
                            >
                                {/* <Icon name='dropdown' /> */}
                                <i className="money bill alternate outline icon ms-4" />
                                <span className="w-100px">Cash</span>{Utilities.toDollar(graphDetails?.cash?.salesAmount || 0)}
                            </Accordion.Title>
                            <Accordion.Content
                            // active={ptActiveIndex === 2}
                            >
                                <div className="col-12 ps-4">
                                    <i className={`icon dollar`} />
                                    <span>Cash - {Utilities.toDollar(graphDetails?.cash?.salesAmount || 0)}</span>
                                </div>
                            </Accordion.Content>
                            <Accordion.Title
                                active={true}
                                index={3}
                            // onClick={e => { e.preventDefault(); if (ptActiveIndex === 3) { setPtActiveIndex() } else { setPtActiveIndex(3) } }}
                            >
                                {/* <Icon name='dropdown' /> */}
                                <i className="money bill alternate outline icon ms-4" />
                                <span className="w-100px">Check</span>{Utilities.toDollar(graphDetails?.check?.salesAmount || 0)}
                            </Accordion.Title>
                            <Accordion.Content
                            //  active={ptActiveIndex === 3}
                            >
                                <div className="col-12 ps-4">
                                    <i className={`icon dollar`} />
                                    <span>Check - {Utilities.toDollar(graphDetails?.check?.salesAmount || 0)}</span>
                                </div>
                            </Accordion.Content>
                            <Accordion.Title
                                active={true}
                                index={4}
                            >
                                <i className="money bill alternate outline icon ms-4" />
                                <span className="w-100px">Credit Balance</span>{Utilities.toDollar(graphDetails?.creditBalance?.salesAmount || 0)}
                            </Accordion.Title>
                            <Accordion.Content
                            >
                                <div className="col-12 ps-4">
                                    <i className={`icon dollar`} />
                                    <span>Credit Balance - {Utilities.toDollar(graphDetails?.creditBalance?.salesAmount || 0)}</span>
                                </div>
                            </Accordion.Content>
                            <Accordion.Title
                                active={true}
                                index={5}
                            >
                                <i className="calculator icon ms-4" />
                                <span className="w-100px">Swipe</span>{Utilities.toDollar(graphDetails?.swipe?.salesAmount || 0)}
                            </Accordion.Title>
                            <Accordion.Content
                            >
                                <div className="col-12 ps-4">
                                    <i className={`icon calculator`} />
                                    <span>Swipe - {Utilities.toDollar(graphDetails?.swipe?.salesAmount || 0)}</span>
                                </div>
                            </Accordion.Content>
                        </Accordion> : <span>Please be patient while data loads...</span>}
                    </Module>
                </div>
                <div className={`${props.stacked ? 'col-12' : 'col-md-6'} col-12 m-p-0`}>
                    <Module
                        title="Scheduled Payments Collected Today"
                        icon="calendar outline check"
                        modalSize="small"
                    >
                        {!isLoader ? <><div className="d-flex justify-content-between align-items-center mb-2">
                            <span>Total Amount</span>
                            <span className="badge bg-primary">{paymentCollectedTodayDetails?.totalAmount ? Utilities.toDollar(paymentCollectedTodayDetails.totalAmount) : '$0.00'}</span>
                        </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <span>Total Count</span>
                                <span className="badge bg-primary">{paymentCollectedTodayDetails && paymentCollectedTodayDetails.totalTransactionCount || 0}</span>
                            </div></> : <span>Please be patient while data loads...</span>}
                    </Module>
                </div>
                <div className={`${props.stacked ? 'col-12' : 'col-md-6'} col-12 m-p-0`}>
                    <Module
                        title="Outstanding Receivables"
                        icon="money bill alternate outline"
                        modalSize="small"
                    >
                        {!isLoader ? <div className="d-flex justify-content-between align-items-center mb-2">
                            <span>Total Outstanding Balance</span>
                            <span className="badge bg-primary">{outstandingReceivablesDetails && Utilities.toDollar(outstandingReceivablesDetails.totalOutstandingBalance || 0)}</span>
                        </div> : <span>Please be patient while data loads...</span>}
                    </Module>
                </div>
                <div className='col-12 m-p-0'>
                    <Module title="Transaction Status">
                        <Table2 export title="Transaction Status" loading={isLoader} columns={columns} data={transactionStatusDetails} />
                    </Module>
                </div>
            </div >
        </div >
    )
}
export default PaymentsDashboard