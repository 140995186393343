import React from 'react'

const PatientDashboard = (props) => {
    return(
        <div>
            <h1>Patient Dashboard</h1>
        </div>
    )
}

export default PatientDashboard