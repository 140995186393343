import React, { useEffect, useState, useContext, useCallback, useRef } from 'react'
import { store } from '../../../../../context/StateProvider'
import Select, { createFilter } from 'react-select'
import CommonService from '../../../../../services/api/common.service'
import DoctorService from '../../../../../services/api/doctor.service'
import AppointmentService from '../../../../../services/api/appointment.service'
import moment from 'moment';
import Utilities from '../../../../../services/commonservice/utilities'
import ModalBox from '../../../../templates/components/ModalBox'
import AddPatient from '../../patient/add-patient/AddPatient'
import InputMask from 'react-input-mask'
import PracticeLocationSelector from '../../../../templates/components/PracticeLocationSelector'
import AppointmentFormConfig from './appointment-form-config'
import toast from 'react-hot-toast'
import FormValidatorService from '../../../../../services/validator/form-validator.service'
import PatientService from '../../../../../services/api/patient.service'
import { debounce, transform } from 'lodash'
import AsyncSelect from 'react-select/async';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { createPortal } from 'react-dom';
import DimLoader from '../../../../templates/components/DimLoader'
import { 
    useGetAppointmentStatusCodes, 
    useGetPracticePatientTypes, 
    useGetLocationEquipment, 
    useGetLocationRooms,
    useGetLocationServiceTypes,
    useGetLocationProviderServices
 } from "../../../../../services/cache/CacheService";
import CacheUtilities from '../../../../../services/cache/CacheUtilities'

const AppointmentForm = (props) => {
    // context state and dispatch 
    const globalStateAndDispatch = useContext(store)
    const state = globalStateAndDispatch.state
    const [serviceType, setServiceType] = useState()

    const [inputData, setInputData] = useState(props.initialData || {})
    const [showAdd, setShowAdd] = useState(false)
    // populate form 
    const [patientList, setPatientList] = useState()
    const [selectedPatient, setSelectedPatient] = useState()

    const patientTypeList = useGetPracticePatientTypes()
    const statusList = useGetAppointmentStatusCodes();

    const [doctorList, setDoctorList] = useState();
    const [equipmentList, setEquipmentList] = useState()
    const [roomList, setRoomList] = useState()
    const [serviceTypeList, setServiceTypeList] = useState()

    const [allEquipmentList, setAllEquipmentList] = useState()

    const stateDoctorList = useGetLocationProviderServices();
    const stateEquipmentList = useGetLocationEquipment()
    const stateRoomList = useGetLocationRooms()
    const stateServiceTypeList = useGetLocationServiceTypes()

    const [locationId, setLocationId] = useState(); // (props?.initialData?.practiceLocationId || state.practiceLocationId)

    const [isLoader, setIsLoader] = useState(false)
    const [isLoader_Patients, setIsLoader_Patients] = useState(false)
    const [isLoader_PatientType, setIsLoader_PatientType] = useState(false)
    const [isLoader_ServiceType, setIsLoader_ServiceType] = useState(false)
    const [isLoader_Status, setIsLoader_Status] = useState(false)
    const [isLoader_Provider, setIsLoader_Provider] = useState(false)
    const [isLoader_Equipment, setIsLoader_Equipment] = useState(false)
    const [isLoader_Room, setIsLoader_Room] = useState(false)
    const [errors, setErrors] = useState({})
    const [childAppointments, setChildAppointments] = useState([])
    const  headerColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-light');

    //added to correct css for dragging and dropping of child apt over model
    const useDraggableInPortal = () => {
        const self = useRef({}).current;

        useEffect(() => {
            const div = document.createElement('div');
            div.style.position = 'absolute';
            div.style.pointerEvents = 'none';
            div.style.top = '0';
            div.style.width = '100%';
            div.style.height = '100%';
            self.elt = div;
            document.body.appendChild(div);
            return () => {
                document.body.removeChild(div);
            };
        }, [self]);

        return (render) => (provided, ...args) => {
            const element = render(provided, ...args);
            if (provided.draggableProps.style.position === 'fixed') {
                return createPortal(element, self.elt);
            }
            return element;
        };
    };

    const renderDraggable = useDraggableInPortal();
    const required = [
        "patientId",
        "practicePatientTypeId",
        // "doctorId",
        "practiceServiceTypeId",
        // "practiceAppointmentStatusCodeId",
        "fromDate",
        "duration",
        "toDate",
        // "email",
    ];

    useEffect(()=>{
        if (allEquipmentList && Array.isArray(allEquipmentList)) {
            let newArray1 = allEquipmentList.filter(obj => obj.isActive === 1);
            setEquipmentList([...newArray1])
        }
    },[allEquipmentList])

    useEffect(()=>{
        if(!inputData?.practiceAppointmentStatusCodeId && statusList){
            let newId = statusList.find(obj=>obj.appointmentStatusLabel =='Booked')?.practiceAppointmentStatusCodeId
            if(newId){
                inputChange({target:{name:'practiceAppointmentStatusCodeId', value:newId}})
            }
        }

    },[inputData?.practiceAppointmentStatusCodeId, statusList])
    const getFormErrors = () => {
        const newErrors = {};
        if (
            !inputData['patientId']
        ) {
            newErrors['patientId'] = 'Patient is required'
        }
        if (
            !inputData['practicePatientTypeId']
        ) {
            newErrors['practicePatientTypeId'] = 'Patient Type is required'
        }
        // if (
        //     !inputData['doctorId']
        // ) {
        //     newErrors['doctorId'] = 'Provider is required'
        // }
        if (
            !inputData['practiceServiceTypeId']
        ) {
            newErrors['practiceServiceTypeId'] = 'Service Type is required'
        }
        if (
            !inputData['practiceAppointmentStatusCodeId']
        ) {
            newErrors['practiceAppointmentStatusCodeId'] = 'Status is required'
        }
        // if (
        //     !inputData['patientPhone']
        // ) {
        //     newErrors['patientPhone'] = 'Phone is required'
        // }
        // if (
        //     !inputData['email']
        // ) {
        //     newErrors['email'] = 'Email is required'
        // }

        if (
            !inputData['fromDate']
        ) {
            newErrors['fromDate'] = 'Start Date is required'
        }

        if (
            !inputData['toDate']
        ) {
            newErrors['toDate'] = 'End Date is required'
        }
        if (
            !inputData['duration']
        ) {
            newErrors['duration'] = 'Duration is required'
        }
        return newErrors;
    }

    const submitHandlerCatch = (data) => {
        // const newErrors = getFormErrors();
        // if (!!Object.keys(newErrors).length) {
        //     toast.error("Please make sure the form is complete");
        //     setErrors(newErrors);
        // }
        if (props.disabled) {
            return;
        }


        if (!FormValidatorService.checkForm(errors, data, required)) {
            toast.error("Please make sure the form is complete")
        }
        else {
            props.submitHandler(data)
        }
    }


    const filterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        trim: true,
        matchFrom: 'any'
    };

    // useEffect(() => {
    //     if (doctorList && doctorList.length > 0 && !inputData.doctorId) {
    //         return inputChange({ target: { value: doctorList[0].id, name: 'doctorId' } })
    //     }
    // }, [doctorList])

    useEffect(() => {
        if (props?.initialData) {
            setInputData(props.initialData);
        }

    }, [props?.initialData])

    const patientLookup = () => {
        setIsLoader_Patients(true)
        if (props.initialData.patientId) {
            PatientService.getPatientById(props.initialData.patientId)
                .then(res => {
                    setPatientList([res.data])
                    setIsLoader_Patients(false)
                })
        }
        else {
            setPatientList()
        }
    }


    const patientLoad = (inputText, callBack) => {
        if (inputText?.length < 3 && !props.initialData.patientId) return;
        let reqObj = { SearchTerm: inputText, isActive: true, isRegistered: true, SortField: 'firstName', Asc: true }
        if (props.initialData.patientId) {
            reqObj.PatientIds = props.initialData.patientId
        }
        CommonService.patientLookup(reqObj)
            .then(res => {
                if (res) {
                    callBack(res.data)
                }
            }
            )
            .catch(err => console.log(err))
    }
    const rePatientLoad = useCallback(debounce(patientLoad, 500), [])



    const onPatientSelect = () => {
        let patient = patientList.find(obj => obj.id === inputData.patientId)
        if(patient){
            setSelectedPatient(patient)
        }
        let newStateObject = { ...inputData };
        if (patient && !props.isEdit) {
            if (patient.mobile) { newStateObject.patientPhone = patient.mobile }
            if (patient.email) { newStateObject.email = patient.email }
            if (patient.patientTypeId) { newStateObject.practicePatientTypeId = patient.patientTypeId }
            return setInputData(newStateObject);
        }
    }

    useEffect(() => {
        if (patientList && inputData.patientId) {
            onPatientSelect()
        }
    }, [patientList, inputData.patientId])

    useEffect(() => {
        if (inputData?.startDate && inputData?.duration) {

        }
    }, [inputData?.startDate])
    // formula for input change
    const inputChange = (e) => {
        let newStateObject = { ...inputData };
        newStateObject[e.target.name] = e.target.value
        setInputData(newStateObject)
        // setTimeout(() => {
        //     const newErrors = getFormErrors();
        //     setErrors(newErrors);
        // }, 4000);
        setErrors(FormValidatorService.setErrors(e, errors, AppointmentFormConfig.config))
        return console.log(inputData)
    };

    const inputSubAptChange = (e) => {
        let newStateObject = { ...inputData };
        //console.log("inputSubAptChange::"+JSON.stringify(e))
        //console.log("inputSubAptChange::"+JSON.stringify(inputData))
        // console.log(e.target.index)
        newStateObject.childAppointments.map((item, i) => {
            if (i === e.target.index) {
                if (e.target.name == "duration") {
                    item[e.target.name] = parseInt(e.target.value)
                } else {
                    item[e.target.name] = e.target.value
                }
            }
        });
        setInputData(newStateObject)
        //setErrors(FormValidatorService.setErrors(e, errors, AppointmentFormConfig.config))
        return console.log(inputData)
    };

    useEffect(() => {
        if (serviceType) {
            updateTime({ target: { name: 'duration', value: serviceType?.defaultDuration } })
        }
    }, [serviceType])

    useEffect(() => {

        if (props?.initialData?.practiceLocationId) {
            setLocationId(props.initialData.practiceLocationId)
        } 
        else {
            setLocationId(state.practiceLocationId)
        }

        if (props?.initialData?.patientId) {
            patientLookup()
        }

        if(props.initialData && props.initialData.id){
            findChildAppointment(props.initialData)
        }
        if (props.initialData.startDate && props.initialData.endDate) {
            inputChange({
                target: {
                    name: 'duration', value: moment(props.initialData.startDate).diff(moment(props.initialData.fromDate), "minutes")
                }
            })

        }
        if (props.initialData.startDate && !props.initialData.endDate && props.initialData.duration) {
            inputChange({
                target: {
                    name: 'endDate', value: moment(props.initialData.startDate).add(props.initialData.duration, "minutes")
                }
            })
        }
        if (props.initialData.duration) {
            inputChange({
                target: {
                    name: 'duration', value: props.initialData.duration
                }
            })
        }
    }, [])

    useEffect(() => {
        addChildApt()  
    }, [childAppointments]);

    const findChildAppointment = () => {
        let reqObj = {
            ParentGroupId: props.initialData.id,
            PracticeLocation: props.initialData.practiceLocationId
        }
        return AppointmentService.findNextAppointment(reqObj)
            .then(res => {
                //console.log(JSON.stringify(res))
                let subApts = []
                //sorting response to add sequence number
                res.sort((a, b) => a.fromDate > b.fromDate ? 1 : -1).map((item, index) => {
                    let localItem = {
                        sequenceId: `item-${index}`,
                        "sequence": index,
                        id: item.id,
                        "duration": item.fromDate && item.toDate ? moment(item.toDate).diff(moment(item.fromDate), "minutes") : 0,
                        "doctorId": item.doctorId,
                        "equipmentId": item.equipmentId,
                        "practiceLocationRoomId": item.practiceLocationRoomId,
                        "practiceServiceTypeId": item.practiceServiceTypeId
                    }
                    subApts.push(localItem)
                })
                setChildAppointments(subApts)
            })
            .catch((err) => {
                setChildAppointments([])
            })
    }
    const updateTime = (e) => {
        let newStateObject = { ...inputData };
        newStateObject[e.target.name] = e.target.value
        if (e.target.name == 'duration') {
            newStateObject.toDate = moment(inputData.fromDate).add(e.target.value, "m")
        }
        if (e.target.name == 'toDate') {
            newStateObject.duration = moment(e.target.value).diff(moment(inputData.fromDate),"m")
        }
        if (e.target.name == 'fromDate') {
            newStateObject.toDate = moment(e.target.value).add(inputData.duration, "m")
        }
        return setInputData(newStateObject)
    }

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const appointments = reorder(
            childAppointments,
            result.source.index,
            result.destination.index
        );
        setChildAppointments(appointments);
        //console.log("after reorder ::"+JSON.stringify(appointments))
    }

    const getItems = () => {
        let localItem = {
            sequenceId: `item-${childAppointments.length}`,
            "sequence": childAppointments.length,
            "duration": 30,
            "doctorId": null,
            "equipmentId": null,
            "practiceLocationRoomId": null,
            "practiceServiceTypeId": null
        }
        setChildAppointments(prevChildAppointments => [...prevChildAppointments,localItem])
    };
    const addChildApt = () => {
        let newStateObject = { ...inputData };
        newStateObject.childAppointments = childAppointments
        setInputData(newStateObject)
    };

    // function to help us with reordering the result while dragging and dropping child apt
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };


    // const getItemStyle = (isDragging, draggableStyle) => ({
    //     // some basic styles to make the items look a bit nicer
    //     //userSelect: "none",
    //     //padding: grid * 2,
    //     //margin: `0 0 ${grid}px 0`,

    //     // change background colour if dragging
    //     //background: isDragging ? "lightgreen" : "",
    //     left: isDragging?  "auto":"",
    //     top: isDragging?  "auto":"",
    //     // styles we need to apply on draggables
    //     ...draggableStyle
    // });
    
    const onLocationChanged = (newLocationId) => {
        setLocationId(newLocationId)
    }
    useEffect(async () => {
        if (locationId) {
            if (locationId == state.practiceLocationId) {
                setDoctorList(stateDoctorList)
                setAllEquipmentList(stateEquipmentList)
                setRoomList(stateRoomList)
                setServiceTypeList(stateServiceTypeList)
            }   
            else {
                const [dummy, locEquipment, locProviderServices, locRooms, locServiceTypes] = await CacheUtilities.pullAllLocationData(locationId);
                setDoctorList(locProviderServices)
                setAllEquipmentList(locEquipment)
                setRoomList(locRooms)
                setServiceTypeList(locServiceTypes)
            }         
        }
    }, [locationId]);    

    
    const getListStyle = isDraggingOver => ({
        // left: isDraggingOver?  "auto":"",
        // top: isDraggingOver?  "auto":"",
         background: isDraggingOver ? headerColor : "",
        // padding: grid,
        // width: 250
      });
    return (
        <>
        {props.disabled ?
            <DimLoader loadMessage="Saving appointment(s)..." /> : null}
        <div className=" fields p-3">
            <div className="row">
                {/* <button type="button" className="accordion-header">Patient Details</button> */}
                <div className="required field col-12">
                    {/* {inputData.patientId} */}
                    <label><i className="icon user outline" /> Choose Patient</label>
                    <div className='col-12 input-group'>
                        {/* <Select
                            options={patientList && patientList}
                            name="patientId"
                            // 
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder={props.initialData.patientId ? 'Patient Information Loading' : 'Search Patients'}
                            isDisabled={props.initialData.patientId || isLoader_Patients}
                            isLoading={isLoader_Patients}
                            loadingMessage="Patients Loading"
                            value={patientList && patientList.find(obj => obj.id === inputData.patientId)}
                            isSearchable

                            filterOption={createFilter(filterConfig)}
                            onChange={e => {
                                if (e?.id) {
                                    inputChange({
                                        target:
                                            { value: e.id, name: 'patientId' }

                                    });
                                }
                                else {
                                    inputChange({
                                        target:
                                            { value: e.patientId, name: 'patientId' }

                                    });
                                }
                            }}
                            getOptionLabel={(option) => {
                                return (
                                    // <div className="d-flex row">
                                    //     <span className="col">{option.firstName} {option.lastName}</span>
                                    //     <span className='col text-center'><i className='icon birthday cake' />{moment.utc(option.dob).format("M/D/YYYY").toString()}</span>
                                    //     <span className='col text-end'><i className='icon phone' />{option.mobile && Utilities.toPhoneNumber(option.mobile) || option.patientPhone && Utilities.toPhoneNumber(option.patientPhone)}</span>
                                    // </div>
                                    option.firstName + ' ' + option.lastName + ' | ' + moment.utc(option.dob).format("M/D/YYYY") + ' | ' + Utilities.toPhoneNumber(option.mobile) || Utilities.toPhoneNumber(option.patientPhone)
                                )
                            }
                            }
                            getOptionValue={(option) => option.id}
                            // styles={{option:(styles)=>{
                            //     return({
                            //         ...styles,
                            //         justifyContent: 'space-between'
                            //     })
                            // }}}
                            noOptionsMessage={(e) => { return <button className='btn btn-primary form-control'>Add Patient</button> }}
                        />
                        {!props.initialData.patientId && <button className='btn btn-primary' title="Add Patient" onClick={e => { e.preventDefault(); setShowAdd(true) }}><i className='icon plus' /></button>} */}
                        <AsyncSelect
                            classNamePrefix="react-select"
                            className="react-select-container"
                            value={(patientList && inputData.patientId ? patientList.find(obj => obj.id === inputData.patientId) : null)}
                            name="patientId"
                            loadOptions={rePatientLoad}
                            placeholder="Search Patient"
                            onChange={e => {
                                if (e?.id) {
                                    inputChange({
                                        target:
                                            { value: e.id, name: 'patientId' }

                                    });
                                    setPatientList([e])
                                }
                                else if (e?.patientId) {
                                    inputChange({
                                        target:
                                            { value: e.patientId, name: 'patientId' }

                                    });
                                    setPatientList([e])
                                }
                                else {
                                    inputChange({
                                        target: {
                                            value: null, name: 'patientId'
                                        }
                                    })
                                }
                            }}
                            // getOptionLabel={(option) => {
                            //     return (
                            //         option.firstName + ' ' + option.lastName + ' | ' + moment.utc(option.dob).format("M/D/YYYY") + ' | ' + Utilities.toPhoneNumber(option.mobile) || Utilities.toPhoneNumber(option.patientPhone)
                            //     )
                            // }
                            // }
                            getOptionLabel={(option) => {
                                return (
                                    <div className="d-flex row">
                                        <span className="col">{option.firstName} {option.lastName}</span>
                                        <span className='col text-start'><i className='icon birthday cake' />{moment.utc(option.dob).format("M/D/YYYY").toString()}</span>
                                        {state.permissions.patientContactInfo && <>
                                        <span className='col text-end'>
                                            {option.mobile && option.mobile != '' || option.patientPhone && option.patientPhone != '' ?
                                                <>
                                                    <i className='icon phone' />
                                                    {option.mobile && option.mobile != '' ? Utilities.toPhoneNumber(option.mobile) : option.patientPhone && option.patientPhone != '' ? Utilities.toPhoneNumber(option.patientPhone) : null}
                                                </> : null}
                                        </span>
                                        </>}
                                    </div>
                                )
                            }}
                            getOptionValue={(option) => option.id}
                            noOptionsMessage={(e) =>
                                <button className='btn btn-primary form-control' onClick={e => { e.preventDefault(); setShowAdd(true) }}>Add Patient</button>}
                        />
                    </div>
                    <span className="error-message">{errors.patientId}</span>
                </div>
                {selectedPatient && <div className="required field col-12">
                    {selectedPatient.patientTags && selectedPatient.patientTags.map(tag => {
                            return (
                                <span className={`badge text-white me-1 bg-info`}>{tag.name}</span>
                            )
                    })}
                </div>}
                <div className="required field col-md-6 col-12">
                    <label>Patient Type</label>
                    {patientTypeList !== [] && <Select
                        options={patientTypeList}
                        name="patientType"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isLoading={isLoader_PatientType}
                        value={patientTypeList && inputData.practicePatientTypeId ? patientTypeList.find(obj => obj.practicePatientTypeId === inputData.practicePatientTypeId) : null}
                        onChange={e => {
                            inputChange({
                                target:
                                    { value: e.practicePatientTypeId, name: 'practicePatientTypeId' }
                            })
                        }}
                        getOptionLabel={(option) => option.patientType}
                        getOptionValue={(option) => option.patientTypeId}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'} 
                        styles={{menuPortal: base => ({ ...base, zIndex: 9999 }),menu: provided => ({ ...provided, zIndex: 9999 })}}
                    />}
                    <span className="error-message">{errors.practicePatientTypeId}</span>
                </div>
                <div className="required field col-md-6 col-12">
                    <label>Location</label>
                    <PracticeLocationSelector locationId={props.isEdit ? props?.initialData?.practiceLocationId : null} disabled={props.isEdit} dontResetState={true} onSelected={onLocationChanged}/>
                </div>
                <div className="field col-12">
                    <label>Search Provider</label>
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={doctorList}
                        isDisabled={!doctorList}
                        isClearable={true}
                        isSearchable
                        name="doctorId"
                        isLoading={isLoader_Provider}
                        value={doctorList && (inputData.doctorId) ? doctorList.find(obj => obj.id === (inputData.doctorId)) : null}
                        onChange={e => {
                            if (e?.id) {
                                inputChange({
                                    target:
                                        { value: e.id, name: 'doctorId' }
                                })
                            }
                            else {
                                inputChange({
                                    target:
                                        { value: null, name: 'doctorId' }
                                })
                            }
                        }}
                        getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
                        getOptionValue={(option) => option.doctorId}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'} 
                        styles={{menuPortal: base => ({ ...base, zIndex: 9999 }),menu: provided => ({ ...provided, zIndex: 9999 })}}
                    />
                    {/* <span className="error-message">{errors.doctorId ? 'Provider is a required field' : null}</span> */}
                </div>
                <div className="required field col-md-6 col-12">
                    <label>Service Type</label>
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isLoading={isLoader_ServiceType}
                        options={serviceTypeList && serviceTypeList}
                        name="practiceServiceTypeId"
                        isDisabled={!props.edit ? false : true || inputData.doctorId}
                        value={serviceTypeList && inputData.practiceServiceTypeId ? serviceTypeList.find(obj => obj.practiceServiceTypeId === inputData.practiceServiceTypeId) : null}
                        onChange={e => {
                            inputChange({
                                target:
                                    { value: e.practiceServiceTypeId, name: 'practiceServiceTypeId' }
                            })
                            setServiceType(e)
                        }}
                        getOptionLabel={(option) => option.practiceServiceType}
                        getOptionValue={(option) => option.practiceServiceTypeId}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'} 
                        styles={{menuPortal: base => ({ ...base, zIndex: 9999 }),menu: provided => ({ ...provided, zIndex: 9999 })}}
                    />
                    <span className="error-message">{errors.practiceServiceTypeId}</span>
                </div>
                <div className="required field col-md-6 col-12">
                    <label>Status</label>
                    <Select
                        options={statusList}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="status"
                        isLoading={isLoader_Status}
                        value={statusList && inputData.practiceAppointmentStatusCodeId ? statusList.find(obj => obj.practiceAppointmentStatusCodeId == inputData.practiceAppointmentStatusCodeId) : null}
                        onChange={e => {
                            inputChange({
                                target:
                                    { value: e.practiceAppointmentStatusCodeId, name: 'practiceAppointmentStatusCodeId' }
                            })
                        }}
                        getOptionLabel={(option) => option.appointmentStatusLabel}
                        getOptionValue={(option) => option.practiceAppointmentStatusCodeId}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'} 
                        styles={{menuPortal: base => ({ ...base, zIndex: 9999 }),menu: provided => ({ ...provided, zIndex: 9999 })}}
                    />
                    <span className="error-message">{errors.practiceAppointmentStatusCodeId}</span>
                </div>
                {state.permissions.patientContactInfo && <>
                <div className="field col-lg-6 col-12">
                    <label>Phone</label>
                    <InputMask
                        placeholder="Phone"
                        type="text"
                        name="phone"
                        mask="(999) 999-9999"
                        unmask={true}
                        value={inputData.patientPhone}
                        onChange={(e) => {
                            e.preventDefault();
                            inputChange(e);
                        }}
                        editable
                    />
                    <span className="error-message">{errors.phone}</span>
                </div>
                {/* {inputData.patientPhone} */}
                <div className="field required col-lg-6 col-12">
                    <label>Email</label>
                    <input type="text"
                        value={inputData.email}
                        onChange={e => { e.preventDefault(); inputChange(e) }}
                        name="email"
                        placeholder="Email" />
                    <span className="error-message">{errors.email}</span>
                </div>
                </>}
                <div className="field required col-lg-5 col-12">
                    <label>Start Date</label>
                    <input type="datetime-local"
                        className="form-control"
                        value={inputData.fromDate && moment(inputData.fromDate).format('YYYY-MM-DDTHH:mm')}
                        min={Utilities.toDateTimeLocale((new Date()))}
                        onChange={e => { e.preventDefault(); updateTime(e); }}
                        name="fromDate"
                    />
                    <span className="error-message">{errors.fromDate}</span>
                </div>
                <div className="field required col-lg-2 col-md-4 col-12">
                    <label>Duration</label>
                    <input type="number"
                        value={inputData.duration}
                        onChange={e => {
                            e.preventDefault();
                            updateTime(e);
                        }}
                        name="duration"
                        min={30}
                        step={15} />
                    <span className="error-message">{errors.duration}</span>
                </div>
                <div className="field required col-lg-5 col-12">
                    <label>End Date</label>
                    <input type="datetime-local"
                        className="form-control"
                        value={inputData.toDate && moment(inputData.toDate).format('YYYY-MM-DDTHH:mm')}
                        onChange={e => {
                            e.preventDefault(); updateTime(e);
                        }}
                        name="toDate"
                    // disabled
                    />
                    <span className="error-message">{errors.toDate}</span>
                </div>

                <div className="field col-md-6 col-12">
                    <label>Room</label>
                    <Select
                        options={roomList}
                        isLoading={isLoader_Room}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        name="practiceLocationRoomId"
                        value={roomList && inputData.practiceLocationRoomId ? roomList.find(obj => obj.practiceLocationRoomId === inputData.practiceLocationRoomId) : null}
                        onChange={e => {
                            inputChange({
                                target:
                                    { value: e.practiceLocationRoomId, name: 'practiceLocationRoomId' }
                            })
                        }}
                        getOptionLabel={(option) => option.room}
                        getOptionValue={(option) => option.practiceLocationRoomId}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'} 
                        styles={{menuPortal: base => ({ ...base, zIndex: 9999 }),menu: provided => ({ ...provided, zIndex: 9999 })}}
                    />
                </div>
                <div className="field col-md-6 col-12">
                    <label>Equipment</label>
                    {equipmentList && <Select
                        options={props.isEdit 
                            && inputData.equipmentId 
                            && equipmentList.find(obj => obj.equipmentId === inputData.equipmentId)===undefined 
                            ? [ ...equipmentList,allEquipmentList.find(obj => obj.equipmentId === inputData.equipmentId)]:equipmentList}
                        isLoading={isLoader_Equipment}
                        classNamePrefix="react-select"
                        className="react-select-container"
                        name="equipmentId"
                        value={equipmentList && inputData.equipmentId ? (props.isEdit ? allEquipmentList.find(obj => obj.equipmentId === inputData.equipmentId): equipmentList.find(obj => obj.equipmentId === inputData.equipmentId)) : null}
                        onChange={e => {
                            inputChange({
                                target:
                                    { value: e.equipmentId, name: 'equipmentId' }
                            })
                        }}
                        getOptionLabel={(option) => option.isActive === 1 ? option.description : `${option.description}(Inactive)`}
                        getOptionValue={(option) => option.equipmentId}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'} 
                        styles={{menuPortal: base => ({ ...base, zIndex: 9999 }),menu: provided => ({ ...provided, zIndex: 9999 })}}
                    />}
                    {/* {inputData.equipmentId && inputData.equipmentId} */}
                </div>
                <div className="field col-12">
                    <label>Memo</label>
                    <textarea placeholder="Memo"
                        className="form-control"
                        value={inputData.memo}
                        onChange={e => { e.preventDefault(); inputChange(e) }}
                        name="memo"
                        rows={2}
                    ></textarea>
                </div>
                {/* show add child apt button only for new appointment or for updating main apt, hide for child apt while editing */}
                {!(props.isEdit
                    && props.initialData
                    && props.initialData.parentGroupId && props.initialData.parentGroupId !== "") ? <div style={{ textAlign: 'center', padding: "10px 0px 10px 0px" }}>
                    <button disabled={props.disabled} className="btn btn-primary" onClick={e => { e.preventDefault(); getItems(); }}>{'Add Service'}</button>
                </div > : ""}

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                 style={getListStyle(snapshot.isDraggingOver)}
                                {...provided.droppableProps}>
                                {childAppointments && childAppointments.length > 0 && childAppointments.map((item, index) => {
                                    return (<Draggable key={item.sequenceId} draggableId={item.sequenceId} index={index}   >
                                        {renderDraggable((provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                // style={getItemStyle(
                                                //     snapshot.isDragging,
                                                //     provided.draggableProps.style
                                                // )}
                                                className="ui segment"
                                            >
                                                <div className='row'>
                                                    <div className="field col-md-6 col-12">
                                                        <label>Search Provider</label>
                                                        <Select
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            options={doctorList}
                                                            isDisabled={!doctorList}
                                                            isClearable={true}
                                                            isSearchable
                                                            name="doctorId"
                                                            isLoading={isLoader_Provider}
                                                            value={doctorList && inputData.childAppointments && inputData.childAppointments[index]?.doctorId ? doctorList.find(obj => obj.id === inputData.childAppointments[index]?.doctorId) : null}
                                                            onChange={e => {
                                                                if (e?.id) {
                                                                    inputSubAptChange({
                                                                        target:
                                                                            { index: index, value: e.id, name: "doctorId" }
                                                                    })
                                                                }
                                                                else {
                                                                    inputSubAptChange({
                                                                        target:
                                                                            { index: index, value: null, name: 'doctorId' }
                                                                    })
                                                                }
                                                            }}
                                                            getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
                                                            getOptionValue={(option) => option.doctorId}
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'fixed'} 
                                                            styles={{menuPortal: base => ({ ...base, zIndex: 9999 }),menu: provided => ({ ...provided, zIndex: 9999 })}}
                                                        />
                                                        {/* <span className="error-message">{errors.childAppointments[index]?.doctorId ? 'Provider is a required field' : null}</span> */}
                                                    </div>
                                                    <div className="required field col-md-6 col-12">
                                                        <label>Service Type</label>
                                                        <Select
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            isLoading={isLoader_ServiceType}
                                                            options={serviceTypeList && serviceTypeList}
                                                            name="practiceServiceTypeId"
                                                            isDisabled={!props.edit ? false : true || (inputData.childAppointments && inputData.childAppointments[index]?.doctorId)}
                                                            value={serviceTypeList && inputData.childAppointments && inputData.childAppointments[index]?.practiceServiceTypeId ? serviceTypeList.find(obj => obj.practiceServiceTypeId === inputData.childAppointments[index]?.practiceServiceTypeId) : null}
                                                            onChange={e => {
                                                                inputSubAptChange({
                                                                    target:
                                                                        { index: index, value: e.practiceServiceTypeId, name: 'practiceServiceTypeId' }
                                                                })
                                                            }}
                                                            getOptionLabel={(option) => option.practiceServiceType}
                                                            getOptionValue={(option) => option.practiceServiceTypeId}
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'fixed'} 
                                                            styles={{menuPortal: base => ({ ...base, zIndex: 9999 }),menu: provided => ({ ...provided, zIndex: 9999 })}}
                                                        />
                                                        {/* <span className="error-message">{errors.childAppointments[index]?.practiceServiceTypeId}</span> */}
                                                    </div>
                                                </div>

                                                <div className='row'>

                                                    <div className="field required col-md-3 col-12">
                                                        <label>Duration</label>
                                                        <input type="number"
                                                            value={inputData.childAppointments && inputData.childAppointments[index]?.duration}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                inputSubAptChange({
                                                                    target:
                                                                        { index: index, value: e.target.value, name: 'duration' }
                                                                })
                                                            }}
                                                            name="duration"
                                                            min={30}
                                                            step={15} />
                                                        {/* <span className="error-message">{errors.childAppointments[index]?.duration}</span> */}
                                                    </div>
                                                    <div className="field col-md-3 col-12">
                                                        <label>Room</label>
                                                        <Select
                                                            options={roomList}
                                                            isLoading={isLoader_Room}
                                                            classNamePrefix="react-select"
                                                            className="react-select-container"
                                                            name="practiceLocationRoomId"
                                                            value={roomList && inputData.childAppointments && inputData.childAppointments[index]?.practiceLocationRoomId ? roomList.find(obj => obj.practiceLocationRoomId === inputData.childAppointments[index]?.practiceLocationRoomId) : null}
                                                            onChange={e => {
                                                                inputSubAptChange({
                                                                    target:
                                                                        { index: index, value: e.practiceLocationRoomId, name: 'practiceLocationRoomId' }
                                                                })
                                                            }}
                                                            getOptionLabel={(option) => option.room}
                                                            getOptionValue={(option) => option.practiceLocationRoomId}
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'fixed'} 
                                                            styles={{menuPortal: base => ({ ...base, zIndex: 9999 }),menu: provided => ({ ...provided, zIndex: 9999 })}}
                                                        />
                                                    </div>
                                                    <div className="field col-md-6 col-12">
                                                        <label>Equipment</label>

                                                        <div className='input-group mx-0'>

                                                            {equipmentList && <Select
                                                                options={props.isEdit 
                                                                    && inputData.childAppointments
                                                                    && inputData.childAppointments[index]?.equipmentId
                                                                    && equipmentList.find(obj => obj.equipmentId === inputData.childAppointments[index]?.equipmentId)===undefined 
                                                                    ? [ ...equipmentList,allEquipmentList.find(obj => obj.equipmentId === inputData.childAppointments[index]?.equipmentId)]:equipmentList}
                                                                isLoading={isLoader_Equipment}
                                                                classNamePrefix="react-select"
                                                                className="react-select-container"
                                                                name="equipmentId"
                                                                value={equipmentList && inputData.childAppointments && inputData.childAppointments[index]?.equipmentId ? (props.isEdit ? allEquipmentList.find(obj => obj.equipmentId === inputData.childAppointments[index]?.equipmentId):equipmentList.find(obj => obj.equipmentId === inputData.childAppointments[index]?.equipmentId)) : null}
                                                                onChange={e => {
                                                                    inputSubAptChange({
                                                                        target:
                                                                            { index: index, value: e.equipmentId, name: 'equipmentId' }
                                                                    })
                                                                }}
                                                                getOptionLabel={(option) => option.isActive === 1 ? option.description : `${option.description}(Inactive)`}
                                                                getOptionValue={(option) => option.equipmentId}
                                                                menuPortalTarget={document.body}
                                                            menuPosition={'fixed'} 
                                                            styles={{menuPortal: base => ({ ...base, zIndex: 9999 }),menu: provided => ({ ...provided, zIndex: 9999 })}}
                                                            />}
                                                            <button className='btn btn-primary' disabled={props.disabled} onClick={e => {
                                                                e.preventDefault();
                                                                const newChildAppointments = [...childAppointments];
                                                                newChildAppointments.splice(index, 1);
                                                                setChildAppointments(
                                                                    newChildAppointments
                                                                );
                                                            }}>
                                                                <i className="icon trash" title="Delete Appointment" style={{ float: 'right' }} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Draggable>)
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className="mt-3 d-flex justify-content-between modal-button-bar">
                    <div className='col-auto'>
                        <button disabled={props.disabled} className="btn btn-primary" onClick={e => { e.preventDefault(); submitHandlerCatch({ ...inputData, ...serviceType }) }}>{props.submitLabel || 'Update'}</button>
                        {props.additionalButton}
                    </div >
                    <div className='col-auto'>
                        {props.onClose && <button className="btn btn-secondary float-right" onClick={e => { e.preventDefault(); props.onClose() }}>Close</button>}
                    </div>
                </div >
            </div>
            <ModalBox open={showAdd} onClose={() => { setShowAdd(false) }} title="Add Patient">
                <AddPatient isModal onClose={() => { setShowAdd(false) }} />
            </ModalBox>
        </div >
        </>
    )
}

export default AppointmentForm