import AppSetting from '../../common/constants/appsetting.constant';
import axios from 'axios'
import StorageService from '../session/storage.service';
import StorageType from '../session/storage.enum';
import CommonService from './common.service';
import Utilities from './../commonservice/utilities'

let loggedInUserData = {};

const NotificationService = {

  getLoggedInData() {
    return JSON.parse(StorageService.get(StorageType.session, 'userDetails'));
  },

  getPendingMessagesCount(practiceLocationId) {

    practiceLocationId = null; // Temp change until fully implememted in API

    let loggedInUserData = this.getLoggedInData();
    
    const url = AppSetting.patient.pendingMessageCount.replace('{parentId}', loggedInUserData['parentId']);
    return axios.get(url,
      {params:{practiceLocationId:practiceLocationId}})
      .then(a => {
        return a
      })
      .catch(err => { 
        return err
      })

  },

  getConversations(patientId,practiceLocationId) {

    practiceLocationId = null; // Temp change until fully implememted in API

    let loggedInUserData = this.getLoggedInData();
    
    let url = AppSetting.patient.getConversations.replace('{parentId}', loggedInUserData['parentId']);

    // if (patientId) {
    //   url = AppSetting.patient.getPatientConversation.replace('{patientId}', patientId).replace('{parentId}', loggedInUserData['parentId']);
    // }

    return axios.get(url, {
      params: { PatientId: patientId,PracticeLocationId:practiceLocationId },

    })
      .then(a => {
        return a
      })
      .catch(err => { 
        return err
      })

  },


 
  sendSMS(reqObj, patientId) {

    let loggedInUserData = this.getLoggedInData();
    
    const url = AppSetting.patient.sendSMS.replace('{patientId}', patientId).replace('{parentId}', loggedInUserData['parentId']);

    return axios.post(url, reqObj)
      .then(a => {
        return a
      })
      .catch(err => { 
        return err
      })

  },


  clearPendingMessages(patientId,reqObj) {

    reqObj.practiceLocationId = 0; // Temp change until fully implememted in API

    let loggedInUserData = this.getLoggedInData();
    
    const url = AppSetting.patient.clearPendingMessages.replace('{patientId}', patientId).replace('{parentId}', loggedInUserData['parentId']);

    return axios.put(url, reqObj)
      .then(a => {
        // send message to refresh pending count
        Utilities.trigger("refreshPendingMessageCount",{});
        return a
      })
      .catch(err => { 
        return err
      })

  },

  allNotifications(reqObj) {
    let loggedInUserData = this.getLoggedInData();
    let url = '';

    url = AppSetting.plforms.submissionsProvider.replace('{parentId}', loggedInUserData.parentId);

    url = `${url}${CommonService.buildQuery(reqObj)}`;

    return axios.get(url)
      .then(a => { return a.data.data })
      .catch(error => { console.log(error) })
  },
  communicationsLookup(reqObj) {
    let loggedInUserData = this.getLoggedInData();
    let url = '';

    reqObj.practiceLocationId = null; // Temp change until fully implememted in API

    url = AppSetting.notification.getCommunications.replace('{parentId}', loggedInUserData.parentId);

    url = `${url}${CommonService.buildQuery(reqObj)}`;

    return axios.get(url)
      .then(a => { return a })
      .catch(error => { return error })
  },

  dismiss(submissionId, data) {
    let loggedInUserData = this.getLoggedInData();

    const reqObj = {
      ...data,
      providerId: loggedInUserData.parentId,
    };

    let url;
    url = AppSetting.plforms.updateSubmission
      .replace('{submissionId}', submissionId);

    return axios.put(url, reqObj)
      .then(a => { return a.data })
      .catch(error => { console.log(error) })
  },
  allAlerts(reqObj) {
    let loggedInUserData = this.getLoggedInData();
    let url = '';

    url = AppSetting.alert.get.replace('{parentId}', loggedInUserData.parentId);

    url = `${url}${CommonService.buildQuery(reqObj)}`;

    return axios.get(url)
      .then(a => { return a })
      .catch(error => { console.log(error) })
  },
  updateAlertStatuses(reqObj){
    let loggedInUserData = this.getLoggedInData();
    let url = AppSetting.alert.statusUpdate
      .replace('{parentId}', loggedInUserData.parentId);
    return axios.put(url, reqObj)
      .then(a => { return a })
      .catch(error => { console.log(error)})
  },
  getAlertsCount(practiceLocationId) {
    let loggedInUserData = this.getLoggedInData();
    const url = AppSetting.alert.alertCount.replace('{parentId}', loggedInUserData['parentId']);
    return axios.get(url,{})
      //{params:{PracticeLocationId:practiceLocationId}})
      .then(a => {
        return a
      })
      .catch(err => { 
        return err
      })

  },
}

export default NotificationService