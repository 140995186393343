import moment from "moment";
import React, { useState, useEffect } from "react";
import TransactionService from "../../../../../../../services/api/transaction.service";
import Utilities from "../../../../../../../services/commonservice/utilities";
import RefundTransactionCard from "../../../../transactions/refund-transaction/RefundTransactionCard";
import ModalBox from "../../../../../../templates/components/ModalBox";
import TransactionReceipt from "../../../../transactions/transaction-receipt/TransactionReceipt";
import SendInvoice from "../../../../invoices/send-invoice/SendInvoice";
import Table2 from "../../../../../../templates/components/Table2";
import APIResponse from "../../../../../../templates/components/APIResponse";
import DimLoader from "../../../../../../templates/components/DimLoader";
import { TransactionOperationTypeEnum as operationTypeFilter } from '../../../../../../../common/enum/Conversions/transaction-operation.enum';
const TransactionHistoryList = (props) => {
  const [transactions, setTransactions] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [selectedTc, setSelectedTc] = useState()
  const [transactionView, setTransactionView] = useState(false)
  const [cancel, setCancel] = useState(false)
  const [showSend, setShowSend] = useState(false)
  const [apiResponse, setApiResponse] = useState()
  const onSuccess = (message) => {
    if (props.refresh) {
      props.refresh()
    }
    if (props.onClose) {
      props.onClose()
    }
    if (props.exitHandler) {
      props.exitHandler()
    }
  }
  const cancelTc = () => {
    setCancel(false)
    setIsLoading(true)
    TransactionService.voidTransaction(selectedTc.id)
      .then(res => {
        setApiResponse(res);
        transactionLookup();
      }).catch((err) => {
        setApiResponse(err);
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  useEffect(() => {
    if (props.autoPull) {
      transactionLookup();
    } else if (props.pull) {
      transactionLookup();
    }
  }, [props.autoPull, props.pull, props.keyword]);

  const hiddenColumns = ["createdOn","channelType","vendor","accountId"]
  const transactionLookup = () => {
    setIsLoader(true);
    let reqObj = {
      sortField: "transactionDate",
      Asc: "false",
      // PageSize: 10,
      PatientIds: props.patientId,
      StartRow: 0,
      InvoiceStatus: [2, 16]
    };
    TransactionService.findTransaction(reqObj).then((res) => {
      if (Array.isArray(res.data.data)) {
        let newArray = res.data.data.map((item) => {
          return { ...item, amount: item.tenderInfo.totalAmount };
        });
        console.log(newArray);
        setTransactions(newArray.sort((a, b) => b.transactionDate.localeCompare(a.transactionDate)));
      }
      return setIsLoader(false);
    });
  };

  const filters = [
    { statusName: "Created", id: 0 },
    { statusName: "Pending", id: 1 },
    { statusName: "Authorized", id: 2 },
    { statusName: "Posted", id: 3 },
    { statusName: "Accepted", id: 4 },
    { statusName: "Failed", id: 5 },
    { statusName: "Returned", id: 6 },
    { statusName: "Canceled", id: 8 },
    { statusName: "Refunded", id: 9 },
    { statusName: "Approved", id: 10 },
    { statusName: "Cancel Attempted", id: 11 },
    { statusName: "Refund Attempted", id: 12 },
    { statusName: "Hold", id: 13 },
    { statusName: "Denied", id: 14 },
    { statusName: "Paid", id: 16 },
    { statusName: "Retried", id: 17 },
    { statusName: "Swipe canceled", id: 20 },
    { statusName: "Partial approved", id: 21 },
    { statusName: "Swipe Initiated", id: 22 },
    { statusName: "Closed", id: 30 },
  ];


  const channelType = [
    { title: 'All', value: 0 },
    { title: 'ACH', value: 2 },
    { title: 'CC', value: 3 },
    { title: 'Debit', value: 4 },
    { title: 'Prepaid Balance', value: 5 },
    { title: 'Swipe', value: 7 },
    { title: 'Cash', value: 9 },
    { title: 'Check', value: 10 },
  ]

  const tableColumns = [
    {
      id: "createdOn",
      Header: "Created On",
      accessor: (cell) => moment(cell.createdOn).format("MM/DD/YYYY h:mm a"),
    },
    {
      id: "invoiceNumber",
      Header: "Invoice",
      accessor: (cell) => {
        return (
          <span className="d-flex align-items-center">
            {cell.credit > 0 &&
              <span title="Prepaid Invoice" className='text-icon'>P</span>
            }
            {cell.invoiceNumber}
          </span>
        )
      }
    },
    {
      id: "amount",
      Header: "Amount",
      accessor: (cell) => Utilities.toDollar(cell.amount),
    },
    {
      id: "transactionStatus",
      Header: "Status",
      accessor: (cell) => { return filters.find((obj) => obj.id === cell.transactionStatus)?.statusName }
    },
    {
      id: "channelType",
      Header: "Payment Type",
      accessor: (cell => { return channelType?.find(obj => obj.value === cell.tenderInfo.channelType)?.title }
      )
    },
    {
      id: "operationType",
      Header: "Operation Type",
      accessor: (cell => { return operationTypeFilter?.find(obj => obj.value === cell.operationType)?.title }
      )
    },
    {
      id: "vendor",
      Header: "Vendor",
      accessor: (cell => { return cell.tenderInfo.cardType ? cell.tenderInfo.cardType : cell.tenderInfo.bankName ? cell.tenderInfo.bankName : null })
    },
    {
      id: "accountId",
      Header: "Account/Check #",
      accessor: (cell => { return cell.tenderInfo.maskCardNumber ? cell.tenderInfo.maskCardNumber : cell.tenderInfo.maskAccount ? cell.tenderInfo.maskAccount : cell.tenderInfo.checkNumber })
    },
    {
      id: "actionEquipmentType",
      Header: "Action",
      disableSortBy: true,
      textAlign: "center",
      accessor: (item, i) => {
        // console.log(equipmentType)
        return (
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className="btn-group">
                {/* no logic present onclick event */}
                {/* {item.transactionStatus !== 9 && item.transactionStatus !== 16 ? <button className="p-0 ps-1 btn btn-primary">
                  <i className="icon pencil" title="Edit Transaction" />
                </button> : null} */}
                {item.transactionStatus === 16 
                && item.operationType!==7 //Refund
                && item.tenderInfo.channelType !== 9 
                && item.tenderInfo.channelType !== 10 
                && item.tenderInfo.channelType !== 8 ? <button
                  className="p-0 ps-1 btn btn-primary"
                  onClick={(e, data) => {
                    e.preventDefault;
                    setSelectedTc(item)
                    setRefundModal(true);
                  }}
                >
                  <i className="icons icon dont " title="Refund Transaction">
                  </i>
                </button> : null}
                {item.transactionStatus === 2 
                && item.operationType!==7 //Refund
                ?
                  <button
                    className="p-0 ps-1 btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault;
                      setSelectedTc(item)
                      return setCancel(true)
                    }}
                  >
                    <i className="icons icon dont " title="Cancel Transaction">
                    </i>
                  </button>: null
                }
                {[9,10].includes(item.tenderInfo.channelType) //Void Cash or check
                && item.transactionStatus === 16
                ?
                  <button
                    className="p-0 ps-1 btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault;
                      setSelectedTc(item)
                      return setCancel(true)
                    }}
                  >
                    <i className="icons icon dont " title="Cancel Transaction">
                    </i>
                  </button>: null
                }
                {/* Needs Work */}
                {/* {item.transactionStatus !== 16 && item.transactionStatus !== 9 ? <button className="p-0 ps-1 btn btn-primary">
                  <i className="icon redo" title="Retry" />
                  
                </button> : null} */}
                {/* // Needs Work */}
                <button className="p-0 ps-1 btn btn-primary" onClick={e => { e.preventDefault(); setSelectedTc(item); setTransactionView(true) }}>
                  <i className="icon eye" title="View Receipt" />
                </button>
                <button className="p-0 ps-1 btn btn-primary" onClick={e => { e.preventDefault(); setSelectedTc(item); return setShowSend(true); }}>
                  <i className="icon send" title="Resend Invoice" />
                </button>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div title="Transaction History" className="py-3">
      <Table2
        hiddenColumns={hiddenColumns}
        data={transactions}
        columns={tableColumns}
        loading={isLoader}
      />
      <ModalBox
        open={refundModal}
        onClose={() => {
          setRefundModal(false);
        }}
        title="Refund"
      >
        <RefundTransactionCard
          isModal
          onClose={() => {
            setRefundModal(false);
          }}
          initialData={selectedTc}
        />
      </ModalBox>
      <ModalBox open={transactionView} onClose={() => { setTransactionView(false) }}>
        <TransactionReceipt transaction={{ ...selectedTc }} onClose={() => { setTransactionView(false) }} />
      </ModalBox>
      <ModalBox open={cancel} title="Cancel" onClose={() => { setCancel(false); }} size="small" confirmButton="Confirm" onCloseSuccess={() => { cancelTc(); }}>
        Are you sure you want to cancel?
      </ModalBox>
      <ModalBox open={showSend} onClose={() => { setShowSend(false) }}>
        <SendInvoice transaction={selectedTc} invoiceId={selectedTc?.invoiceId} onClose={() => { setShowSend(false) }} />
      </ModalBox>
      {isLoading ? <DimLoader /> : null}
      <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
    </div>
  );
};

export default TransactionHistoryList;
