import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import defaultLogo from '../../../../assets/images/logo_login.png'
import { Transition } from 'semantic-ui-react';
import ForgotPasswordService from '../../../services/api/forgot-password.service';
import toast from 'react-hot-toast';
import APIResponse from '../../templates/components/APIResponse'

const ResetPassword = (props) => {
    const [show, setShow] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [username, setUsername] = useState()

    const params =new URLSearchParams(document.location.search).toString().split('=')
    const navigate = useNavigate()
    const [apiResponse,setApiResponse] = useState()

    const onSuccess = (message) => {
        if (window.location.host.includes("logindev.") || window.location.host.includes("login.uat") || window.location.host.includes("login.hellopatients") || window.location.host.includes("login.revique") || window.location.pathname.includes('patient')) {
            navigate('/patient/login')
        }
        else {
            navigate('/login')
        }
    }

    useEffect(() => {
        if(params?.length==2){
            if(params[0]=='username'){
                setUsername(params[1])
            }
        }
    }, [params])
    const isPatient = useMemo(() => {
        if (window.location.host.includes("logindev.") || window.location.host.includes("login.uat") || window.location.host.includes("login.hellopatients") || window.location.host.includes("login.revique") || window.location.pathname.includes('patient')) {
            return true
        }
        else {
            return false
        }

    }, [window.location])

    const submitHandler = () => {
        let reqObj = { isReset: true }
        ForgotPasswordService.forgotPassword(username, reqObj)
            .then(res => {
                setApiResponse(response)

                // toast.success("Password reset email has been sent")
                // if (window.location.host.includes("logindev.") || window.location.host.includes("login.uat") || window.location.host.includes("login.hellopatients") || window.location.pathname.includes('patient')) {
                //     navigate('/patient/login')
                // }
                // else {
                //     navigate('/login')
                // }
            })
            .catch(err => {
                setApiResponse(err)
                // toast.error("Failed to reset password")
            })
    }


    useEffect(() => {
        setInterval(() => {
            setShow(true)
        }, 200)
    }, [])
    return (
        <div className="limiter">
            <div className="container-login100 img-bg">
                <Transition.Group animation="drop" duration={600}>
                    {show && <div className="wrap-login100">
                        <img className="logo-center" src={defaultLogo} />
                        <br />
                        {!isLoader && isPatient ?
                            <div className="d-flex row card p-4">
                                <div className='col-12'>
                                    <h4>Forgot Password</h4>
                                    <p className='mt-3'>Please enter your Email/Phone, a temporary password will be sent to your registered email id.
                                    </p>
                                </div>
                                <div className='col-12 mt-3'>
                                    <div className='required field'>
                                        <label>Email/Phone</label>
                                        <input type="text" onChange={e => { e.preventDefault(); setUsername(e.target.value) }} value={username} />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end mt-3'>
                                    <button className='btn btn-primary' onClick={e => { e.preventDefault(); submitHandler() }}>Reset Password</button>
                                </div>
                            </div> : !isLoader && !isPatient ?
                                <div className="d-flex row card p-4">
                                    <div className='col-12'>
                                        <h4>Forgot Password</h4>
                                        <p className='mt-3'>Please enter your Username. A temporary password will be sent to your registered email address.
                                        </p>
                                    </div>
                                    <div className='col-12 mt-3'>
                                        <div className='required field'>
                                            <label>Username</label>
                                            <input type="text" onChange={e => { e.preventDefault(); setUsername(e.target.value) }} value={username} />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end mt-3'>
                                        <button className='btn btn-primary' onClick={e => { e.preventDefault(); submitHandler() }}>Reset Password</button>
                                    </div>
                                </div > : null}
                    </div>}
                </Transition.Group>
            </div >
            {/* } */}
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true}/>
        </div>
    )
}

export default ResetPassword