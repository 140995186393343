import React, { useState, useRef, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'

function PaperComponent(props) { 
    return (
        <Paper {...props} style={{ margin: 0, maxHeight: '100%' }} />
    );
}

const ModelessBox = (props) => {
    const { open, children, title, menuIcon } = (props)
    const [ init, setInit] = useState(false);
    const modeLess = useRef(null);


    useEffect(() => {
        if (!init && modeLess.current && open) {
            setInit(true);
            document.body.style.overflowY = 'auto';
          }
    });

    const onClose = () => {
        props.onClose();
        setInit(false); 
    };
    
    return (
        <div>
            <Draggable 
                handle={'[class*="MuiDialog-root"]'} 
                cancel={'[class*="MuiDialogContent-root"]'}>
                <Dialog
                    ref={modeLess}
                    open={props.open}
                    onClose={onClose}
                    disableEnforceFocus
                    hideBackdrop
                    disableBackdropClick
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    style={{
                        height: 'fit-content',  // Ensures that the dialog is 
                        width: 'fit-content',   // exactly the same size as its contents
                    }}>
                    <DialogTitle id="draggable-dialog-title">
                        <div className='d-flex flex-row text-primary' style={{fontSize:"14px"}}> 
                            <i className={menuIcon} onClick={(e) => { e.preventDefault(); props.onMenuClick(); }}/>
                            {title}
                            <i style={{marginLeft:'auto', padding:'unset'}} className="btn icon x" onClick={(e) => { e.preventDefault(); onClose(); }} />
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {children}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {/* Future - pass in content for actions */}
                    </DialogActions>
                </Dialog>
            </Draggable>                
        </div >
    )
}

export default ModelessBox