import React, { useState, useEffect } from 'react'
import FormValidatorService from '../../../../../../services/validator/form-validator.service'
import ValidationConstant from '../../../../../../services/validator/validation.constant'
import toast from "react-hot-toast";
FormValidatorService
ValidationConstant
const ProductTagForm = (props) => {
    //console.log(JSON.stringify(props.initialData))
    const [formData, setFormData] = useState(props.initialData || {})
    const [errors, setErrors] = useState({})
    const required = ['name']
    const TagFormConfig = {
        config: {
    
            'name': {
                required: { name: ValidationConstant.product.tag.add.name.name },
                maxlength: {
                    name: ValidationConstant.product.tag.add.name.name,
                    max: ValidationConstant.product.tag.add.name.maxLength.toString()
                },
                // minlength: {
                //     name: ValidationConstant.patient.add.firstName.name,
                //     min: ValidationConstant.patient.add.firstName.minLength.toString()
                // },
            },
    
        },
        'name': ['', []]
    }
    // formula for input change
    const inputChange = (e) => {
        let newStateObject = { ...formData };
        newStateObject[e.target.name] = [e.target.value]
        setErrors(FormValidatorService.setErrors(e, errors, TagFormConfig.config))
        return setFormData(newStateObject);
    };
    return (
        <div className='d-flex row'>
            <div className='col-12'>
                <div className='field required'>
                    <label>Tag Name</label>
                    <input type="text" value={formData.name} name="name"
                        onChange={(e) => { e.preventDefault(); inputChange(e) }} 
                        required/>
                    <span className="error-message">{errors.name}</span>
                </div>
            </div>
            <div className='mt-3 d-flex justify-content-between'>
                <div className='col-auto'>
                    <button className='btn btn-secondary' onClick={e=>{e.preventDefault(); if(props.onClose){props.onClose()}}}>Close</button>
                    </div>
                <div className='col-auto'>
                    <button className='btn btn-primary' onClick={e => {
                        e.preventDefault();
                        if (!FormValidatorService.checkForm(errors, formData, required)) {
                            toast.error("Please make sure the form is complete")
                        }
                        else {
                            props.submitHandler(formData);
                        }
                    }}>Save</button>
                </div>
            </div>
        </div>
    )
}
export default ProductTagForm