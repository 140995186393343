import React, { useEffect, useState, useContext } from 'react'
import PracticeLocationSelector from '../../../../../templates/components/PracticeLocationSelector'
import { store } from '../../../../../../context/StateProvider'
import { Accordion, Icon } from 'semantic-ui-react'
import PracticeLocationUser from '../../location-settings/practice-location-user/PracticeLocationUser'

const LocationUsers = (props) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const state = useContext(store).state
    const [locationId, setLocationId] = useState(state.practiceLocationId);
    const onLocationChanged = (newLocationId) => {
        setLocationId(newLocationId)
    }
    return (
        <div>
            <div className='row d-flex mb-3'>
                <div className='col-md-auto col-12'>
                    <div className='field'>
                        <label>Select a Location</label>
                        <div className='input-group' style={{minWidth:'300px'}}>
                            <PracticeLocationSelector dontResetState={true} onSelected={onLocationChanged}/>
                        </div>
                    </div>
                </div>
            </div>
            {locationId && <Accordion fluid styled>
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={e => { e.preventDefault(); if (activeIndex === 1) { setActiveIndex() } else { setActiveIndex(1) } }}
                > <Icon name='dropdown' />Users</Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                    <div>
                        <PracticeLocationUser id={locationId} />
                    </div>
                </Accordion.Content>
            </Accordion>}
        </div>
    )
}

export default LocationUsers