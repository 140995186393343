import React, { useState } from 'react'
import PatientService from '../../../../../../services/api/patient.service'
import APIResponse from '../../../../../templates/components/APIResponse'
import PatientTagForm from './PatientTagForm'
import DimLoader from '../../../../../templates/components/DimLoader'

const AddPatientTag = (props) => {
    const [apiResponse, setApiResponse] = useState()
    const [isLoader, setIsLoader] = useState(false)
    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }

    const submitHandler = (reqObj) => {
        setIsLoader(true)
        return PatientService.addPatientTag(reqObj)
            .then(res => {
                setApiResponse(res);
            })
            .catch(err => {
                setApiResponse(err);
            }).finally(() => {
                setIsLoader(false)
              });
    }
    return (
        <>{isLoader? <DimLoader loadMessage="Saving"/>:null}
            <PatientTagForm
                onClose={() => { () => props.onClose() }}
                initialData={props.initialData}
                submitHandler={submitHandler} />
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </>
    )
}

export default AddPatientTag