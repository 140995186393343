import AppSetting from '../../common/constants/appsetting.constant';
import axios from 'axios'
import StorageService from '../session/storage.service';
import StorageType from '../session/storage.enum';

const DocumentsService = {

  getLoggedInData() {
    return JSON.parse(StorageService.get(StorageType.session, 'userDetails'));
  },

  getDocumentsList(patientId, params) {
    let url;
    url = AppSetting.documents.getAll
      .replace('{parentId}', this.getLoggedInData().parentId)
      .replace('{patientId}', patientId)

    return axios.get(url, params)
      .then(a => {
        return a.data;
      })
      .catch(error => { console.log(error) })
  },

  deleteDocument(patientId, docId) {
    let url = AppSetting.documents.getById
      .replace('{parentId}', this.getLoggedInData().parentId)
      .replace('{patientId}', patientId)
      .replace('{docId}', docId)
    return axios.delete(url)
      .then(res => { return res })
      .catch(err => { return err })
  },

  downloadDocuments(downloadUrl) {
    return axios.get(downloadUrl, {})
      .then(a => {
        return a;
      })
      .catch(error => { return error })
  },
  getUploadURL(patientId, reqObj) {
    let url;
    url = AppSetting.documents.getUploadURL
      .replace('{parentId}', this.getLoggedInData().parentId)
      .replace('{patientId}', patientId)
    return axios.post(url, reqObj)
      .then(a => {
        return a;
      })
      .catch(error => { return error })
  },
  getDownloadURL(patientId, docId) {
    let url;
    url = AppSetting.documents.getDownloadURL
      .replace('{parentId}', this.getLoggedInData().parentId)
      .replace('{patientId}', patientId)
      .replace('{docId}', docId)
    return axios.get(url)
      .then(a => {
        return a;
      })
      .catch(error => { return error })
  },
  addDocument(data, presignedURL) {
    return axios.put(presignedURL, data)
      .then(a => { return a })
      .catch(error => { return error });
  },
}

export default DocumentsService
