import React, { useState } from 'react'
import Dashboard from '../../../templates/layouts/Dashboard'
import PracticeLocationAvailablityList from './PracticeLocationAvailablityList'

const PracticeLocationAvailabilityDashboard = (props) => {

    const [refresh, setRefresh] = useState(false)

    const refreshList = () => {
        setRefresh(true)
        setRefresh(false)
    }

    return (
        <div>
            {!refresh &&
                <div>
                    <PracticeLocationAvailablityList side='right' />
                </div>
            }
        </div>
    )
}

export default PracticeLocationAvailabilityDashboard