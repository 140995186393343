import React, { useState } from 'react'
import FeesTable from './FeesTable'

const FeesSettings = (props) => {
    const [keyword, setKeyword] = useState()
    return (
        <div title="Fees">
            <FeesTable title="Fees" />
        </div>
    )
}

export default FeesSettings