import './formio-builder.scss'
import React, { useState, useEffect } from 'react'
import { Form, FormBuilder } from '@formio/react';
import { getConfig } from './formio-builder-options';
import FormsService from '../../../../../services/api/forms.service';
import HPTSelect from '../../../../templates/components/HPTSelect';
import DimLoader from '../../../../templates/components/DimLoader';


const FormFormBuilder = (props) => {
    const [form, setForm] = useState(props.form?.fields || [])
    const [inputData, setInputData] = useState(props?.form || undefined)
    const [isLinked, setIsLinked] = useState(false)
    const [globalFormList, setGlobalFormList] = useState()
    const [selectedGlobalForm, setSelectedGlobalForm] = useState()
    const [showFormPreview, setShowFormPreview] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [isLoader_Form, setIsLoader_Form] = useState(false)
    const [isEditable, setIsEditable] = useState(props?.form?.isEditable || false)
    // formula for input change
    const inputChange = (e) => {
        if (e.target.name === "formId") {
            let newStateObject = { ...inputData };
            newStateObject[e.target.name] = e.target.value;
            newStateObject['formTitle'] = "";
            newStateObject['formDescription'] = "";
            newStateObject['fields'] = "";
            setInputData(newStateObject);
        }
        else {
            let newStateObject = { ...inputData };
            newStateObject[e.target.name] = e.target.value;
            setInputData(newStateObject);
        }
    };

    useEffect(() => {
        if (isLinked) {
            getGlobalForm()
        }
    }, [isLinked])

    useEffect(() => {
        if (selectedGlobalForm) {
            getFormById()
        }
    }, [selectedGlobalForm])

    const getGlobalForm = () => {
        setIsLoader(true)
        let reqObj = {
            isRegistered: false
        }
        FormsService.getLookupList(reqObj)
            .then(res => {
                setGlobalFormList(res)
            })
            .catch(err => {
                console.log(err)
            }).finally(() => {
                setIsLoader(false)
            })
    }

    const getFormById = () => {
        setIsLoader_Form(true)
        FormsService.getFormById(selectedGlobalForm)
            .then(res => {
                if (res?.isEditable) {
                    setShowFormPreview(false);
                } else {
                    setShowFormPreview(true);
                }
                let newStateObject = { ...inputData, ...res };
                newStateObject["formTitle"] = res?.formTitle;
                newStateObject["formDescription"] = res?.formDescription;
                setForm(res?.fields);
                setInputData(newStateObject);
            })
            .catch(err => {
                console.log(err)
            }).finally(() => {
                setIsLoader_Form(false)
            })
    }

    const resetForm = () => {
        setShowFormPreview(false)
        setSelectedGlobalForm()
        inputChange({ target: { value: null, name: 'formId' } })
        setForm([])
    }
    return (
        <>
            {isLoader_Form && <DimLoader />}
            <div className='d-flex row'>

                <div className='col-12 mb-3'>
                    {!props?.isEdit && props?.userType===1 && <div>
                        <div className='field'>
                            <div className='form-check'>
                                <input type="checkbox" className='form-check-input' checked={isLinked} name="isLink" onChange={e => {
                                    resetForm()
                                    setIsLinked(e.target.checked)
                                }} />
                                <label className='form-check-label'>Use global template</label>
                            </div>
                        </div>
                        {isLinked && <div className='field required'>
                            <label>Form</label>
                            <HPTSelect
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={globalFormList}
                                name="formId"
                                isLoading={isLoader}
                                isClearable={true}
                                value={globalFormList ? globalFormList.find(obj => obj.id === inputData?.form?.id) : null}
                                onChange={e => {
                                    if (e?.id) {
                                        setSelectedGlobalForm(e.id)
                                        inputChange({ target: { value: e.id, name: 'formId' } })
                                    } else {
                                        resetForm()
                                    }

                                }}
                                getOptionLabel={(option) => option.formTitle}
                                getOptionValue={(option) => option.id}
                                placeholder="Select Form"
                            />
                        </div>}
                    </div>}
                    <div className='field required'>
                        <label>Title</label>
                        <input type="text" placeholder="Form Title" value={inputData?.formTitle} name="formTitle" onChange={e => { e.preventDefault(); inputChange(e) }} />
                    </div>
                    <div className='field'>
                        <label>Description</label>
                        <input type="text" placeholder="Description" value={inputData?.formDescription} name="formDescription" onChange={e => { e.preventDefault(); inputChange(e) }} />
                    </div>
                    {props?.userType===2 &&
                        <div className='field'>
                            <div className='form-check'>
                                <input type="checkbox" className='form-check-input' checked={isEditable} name="isEditable" onChange={e => {
                                    setIsEditable(e.target.checked)
                                }} />
                                <label className='form-check-label'>Make form editable for provider</label>
                            </div>
                        </div>}
                </div>
                {showFormPreview ?
                    <Form form={{ display: 'form', components: form?.components }} 
                    //options={{ readOnly: true }} 
                    ></Form>
                    : <FormBuilder
                        form={{ display: 'form', components: form?.components }}
                        options={getConfig()}
                        onChange={(schema) => {
                            setForm(schema)
                        }} />}
                <div className='mt-3 d-flex justify-content-between'>
                    <div className='col-auto'>
                        <button className="btn btn-primary" onClick={(e) => {
                            e.preventDefault();
                            // if (!FormValidatorService.checkForm(errors, userData, required)) {
                            //     toast.error("Please make sure the form is complete")
                            //   }
                            //   else {
                            props.submitHandler(inputData, form, isLinked,isEditable)
                            //}
                        }}>
                            {isLinked ? "Link" : props.saveText}
                        </button>
                    </div>
                    <div className='col-auto'>
                        <button className='btn btn-secondary float-right' onClick={e => { e.preventDefault(); if (props.onClose()) { props.onClose() } }}>Close</button>
                    </div>
                </div>
            </div>
        </>

    )
}
export default FormFormBuilder