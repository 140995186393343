import React, { useState, useEffect } from 'react'
import FormForm from "./FormForm";
import CacheUtilities from '../../../../../../../../services/cache/CacheUtilities';
import PatientService from '../../../../../../../../services/api/patient.service';
import DimLoader from '../../../../../../../templates/components/DimLoader';
import PlFormsService from '../../../../../../../../services/api/plforms.service';

const AlertFormView = (props) => {
    const [patientId, setPatientId] = useState(props?.patientId);
    const [submissionId, setSubmissionId] = useState(props?.submissionId);
    const [isLoading, setIsLoading] = useState();
    const [patient, setPatient] = useState();
    const [submission, setSubmission] = useState();

    const getRecords = async () => {
        setIsLoading(true);
        const [Patient,Submissions] = await Promise.all([

            CacheUtilities.pullAPIData(
                {
                    isObject: true,
                    APIService: "getPatientById",
                    Service: PatientService,
                    Parameters: patientId
                }
            ),
            CacheUtilities.pullAPIData(
                {   
                    isObject: true,
                    APIService: "getSubmissionById",
                    Service: PlFormsService,
                    Parameters: submissionId
                }
            ),

        ])
        setPatient(Patient);
        setSubmission(Submissions);
        setIsLoading(false);
    }
    useEffect(() => {
        if (props.patientId) getRecords();
    }, [])
    return (
        <>{isLoading ? <DimLoader loadMessage="Loading..." /> : null}
            {patient && submission ?
                <FormForm formTitle={submission?.submission?.data?.formTitle} patient={patient} submission={submission} readOnly={true} onClose={props.onClose} submissionId={props.submissionId} patientId={props.patientId} />
                : null}
        </>
    )
}

export default AlertFormView
