import React, { useEffect, useContext, useState } from 'react';
import { Link, useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import defaultLogo from '../../../../assets/images/logo_login.png'
import label from '../../../../assets/i18n/en.json'
import { Transition } from 'semantic-ui-react';
import PasswordSettings from '../../secure/provider/settings-dashboard/account-settings/password-settings/PasswordSettings';
import CommonService from '../../../services/api/common.service';

const ChangePassword = (props) => {
    const [show, setShow] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [success, setSuccess] = useState(false)
    const [userType, setUserType] = useState()
    const [providerName, setProviderName] = useState()
    const [userId, setUserId] = useState()

    const navigate = useNavigate()
    const params = useParams()

    useEffect(() => {
        setIsLoader(true)
        if (!params?.userType || !params?.userId) {
            let data = CommonService.getAuthData()
            let providerData = CommonService.getSettingsData()
            navigate(`/change-password/${data.parentId}/${data.userType}/${data.id}/true/`)
        }
        else {
            setUserType(params?.userType)
            setProviderName(params?.providerName)
            setUserId(params?.userId)
            return setIsLoader(false)
        }
    }, [params])

    // formula for input change
    const inputChange = (e) => {
        e.preventDefault()
        let newStateObject = { ...loginData };
        newStateObject[e.target.name] = e.target.value;
        setLoginData(newStateObject);
        // console.log("new data", loginData);
    };

    useEffect(() => {
        setInterval(() => {
            setShow(true)
        }, 200)
    }, [])

    // useState(() => {
    //     if (success == true) {
    //         navigate('./login')
    //     }
    // }, [success])
    return (
        <div className="limiter">
            {/* <div className='card'>
                parentId: {params.parentId}
                userType: {params.userType}
                userId: {params.userId}
                isReset: {params.isReset}
            </div> */}
            <div className="container-login100 img-bg">
                <Transition.Group animation="drop" duration={600}>
                    {show && <div className="wrap-login100">
                        <img className="logo-center" src={defaultLogo} />
                        <br />
                        {!isLoader && <div className="">
                            <PasswordSettings isLogin providerName={providerName} userId={userId} userType={userType} setSuccess={setSuccess} />
                        </div >}
                    </div>}
                </Transition.Group>
            </div >
        </div>
    )
}

export default ChangePassword