import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import RoleService from '../../../../../../../services/api/role.service';
import UserRolesForm from '../user-roles-form/UserRolesForm';
import APIResponse from '../../../../../../templates/components/APIResponse'
 
const UserRolesEdit = (props) => {
    const [isLoader, setIsLoader] = useState(false)
    const [initialData, setInitialData] = useState(props.initialData)
    // function for input change
    const [apiResponse, setApiResponse] = useState()
    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }
    const getRole = () => {
        setIsLoader(true)
        RoleService.getById(props.id,props.practiceId)
            .then(res => {
                setIsLoader(false)
                console.log(res.data)
                setInitialData(res.data)
            })
            .catch(err=>{
                console.log(err)
                setIsLoader(false)
            })
    }
    const submitHandler = (data) => {
        RoleService.update(data,props.id, props.practiceId)
            .then(res => {
                setApiResponse(res);
            })
            .catch(err => {
                setApiResponse(err);
            })
    };

    useEffect(() => {
        if (props.id) {
            getRole()
        }
    }, [props.id])
    return (
        <div className='row d-flex'>
           {!isLoader && initialData ? <UserRolesForm practiceId={props.practiceId} initialData={initialData} onClose={props.onClose} submitHandler={submitHandler} submitButton="Update" />:null}
           {!isLoader && initialData ?<APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />:null}
        </div>
    )
}
export default UserRolesEdit