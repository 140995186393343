import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import Utilities from "../../../../../services/commonservice/utilities";
import { store } from "../../../../../context/StateProvider";
import ReportService from "../../../../../services/api/report.service";
import DoctorService from "../../../../../services/api/doctor.service";
import Select from "react-select";
import Module from "../../../../templates/components/Module";
import Table2 from "../../../../templates/components/Table2";
import DatePicker from "../../../../templates/components/DatePicker";
import DimLoader from "../../../../templates/components/DimLoader";

const EmployeeSalesTotal = (props) => {
  const [tickets, setTickets] = useState();
  const [startDate, setStartDate] = useState(moment().startOf("D"));
  const [endDate, setEndDate] = useState(moment().endOf("D"));
  const [providerId, setProviderId] = useState();
  const [providerList, setProviderList] = useState();
  const stateAndDispatch = useContext(store);
  const state = stateAndDispatch.state;

  const [isLoader, setIsLoader] = useState(false)
  const [initialMessage, setInitialMessage] = useState(true)
  const [locationId, setLocationId] = useState(state.practiceLocationId || null)

  const columns = [
    {
      id: "date",
      Header: "Date",
      accessor: (tc) => moment(tc.date).format("M-D-YYYY"),
      Footer:()=> 'Totals'
    },
    {
      id: "client",
      Header: "Client",
      accessor: tc => tc.client
    },
    {
      id: "productOrService",
      Header: "Item",
      accessor: tc => tc.productOrService
    },
    {
      id: "invoiceNumber",
      Header: "Invoice",
      accessor: tc => tc.invoiceNumber
    },
    {
      id: "quantity",
      Header: "Qty",
      accessor: tc => tc.quantity
    },
    {
      id: "unitPrice",
      Header: "Price",
      accessor: (tc) => Utilities.toDollar(tc.unitPrice)
    },
    {
      id: "subTotal",
      Header: "Subtotal",
      accessor: (tc) => Utilities.toDollar(tc.subTotal)
    },
    {
      id: "discount",
      Header: "Discount",
      accessor: (tc) => Utilities.toDollar(tc.discount)
    },
    {
      id: "total",
      Header: "Total",
      accessor: (tc) => Utilities.toDollar(tc.total),
      Footer: info => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
            () =>
                info.rows.reduce((sum, row) => row.original.total + sum, 0),
            [info.rows]
        )

        return <>{Utilities.toDollar(total)}</>
    },
    },
  ]

  const fetchReport = () => {
    setIsLoader(true)
    let reqObj = {
      StartDate: moment(startDate).toISOString(),
      EndDate: moment(endDate).toISOString(),
      EmployeeIds: [providerId],
    };
    if (locationId) {
      reqObj.PracticeLocationId = locationId
    }    
    setInitialMessage(false);
    ReportService.getEmployeeSalesReport(reqObj)
      .then((res) => {
        setTickets(res);
        setIsLoader(false)
      })
      .catch((err) => {
        console.log(err);
        setTickets();
        setIsLoader(false)
      });
  };

  useEffect(() => {
    if (locationId) {
      setIsLoader(true)
      let reqObj = {
        isRegistered: true,
        isActive: true,
        PracticeLocationId: locationId,
      };
      DoctorService.doctorLookup(reqObj)
        .then((res) => {
          console.log(res);
          setProviderList(res);
          setProviderId(res[0].id);
          setIsLoader(false)
        })
        .catch((err) => {
          console.log(err);
          setProviderList();
          setIsLoader(false)
        });
    }
  }, [locationId]);


  return (
    <>
    {isLoader &&
        <DimLoader loadMessage="Processing" />
    }    
    <div className="row d-flex g-3">
      <div className='col-12 m-p-0'>
        <Module title="Filters">
          <div className='row d-flex'>
            <div className='col-12 row d-flex justify-content-center mx-0 align-items-end report-filters'>
              
              <DatePicker startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} setLocationId={setLocationId}/>

              <div className="col">
                <div className='field required'>
                  <label>Search Provider</label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={providerList}
                    name="doctorId"
                    value={providerList && providerList.find(obj => obj.id === providerId) || null}
                    onChange={e => {
                      setProviderId(e.id)
                    }}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                  />
                </div>
              </div>

              <div className="col-md-auto col-12"><button className="btn btn-primary" onClick={e => { e.preventDefault(); fetchReport() }} title="Pull Report"><i className="icon arrow circle right" /></button></div>

            </div>
          </div>
        </Module>
      </div>
      <div className="col-12 m-p-0">
        <Module title="Employee Sales Total">
          <Table2 export searchable title="Employee Sales Total" columns={columns} data={tickets} loading={isLoader} initialMessage={initialMessage}/>
        </Module>
      </div>
    </div>
    </>
  );
};

export default EmployeeSalesTotal;
