import React, { useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import Module from '../components/Module'

const SideTabs = (props) => {
    const [activeTab, setActiveTab] = useState(props.defaultActiveKey || props.children[0].props.eventKey || 'nav-0')
    let items = Array.isArray(props.children) === false ? [props.children] : props.children
    console.log(items)
    return (
        <Tab.Container id={props.id || 'side-menu'} defaultActiveKey={props.defaultActiveKey || 'nav-0'} activeKey={props.activeKey || activeTab} mountOnEnter unmountOnExit onSelect={(k) => { if (props.onSelect) { props.onSelect(k) } else { setActiveTab(k) } }}>
            <div className='container-fluid side-bar-template'>
                <div className='row d-flex mt-3'>
                    <div className='col-md-auto col-12 mb-3' style={{maxWidth:'250px', width:'15%'}}>
                        <Module dashboard title={props.menuTitle || null} startOpen>
                            <Nav className='row d-flex align-items-center justify-content-center side-bar-template-menu g-1' variant="pills" collapseOnSelect expand="lg">
                                {items && items.filter(obj=> obj.props.hide!=true).map((item, idx) => {
                                        return (
                                            <Nav.Item className='col-lg-12 col-4 side-bar-template-button'>
                                                <Nav.Link className="btn" eventKey={item.props.eventKey || `nav-${idx}`} disabled={item.props.disabled}>{item.props?.title}</Nav.Link>
                                            </Nav.Item>
                                        )
                                    // }
                                })}
                            </Nav>
                        </Module>
                    </div>
                    <div className='col' style={{width:'15%'}}>
                        <Tab.Content>
                            {items && items.map((item, idx) => {
                                return (
                                    <Tab.Pane eventKey={item.props.eventKey || `nav-${idx}`}>
                                        {item}
                                    </Tab.Pane>
                                )
                            })
                            }
                        </Tab.Content>
                    </div>
                </div>
            </div>
        </Tab.Container >
    )
}

export default SideTabs