const FeaturesEnum = {
    "addLinkPatient" : 101,
    "editPatient" : 102,
    "viewAllPatient" : 103,
    "resetPatientPassword" : 104,
    "addNotes" : 105,
    "patientInsuranceManagement" : 106,
    "bulkUploadPatients" : 107,
    "addPaymentAccount" : 108,
    "editPaymentAccount" : 109,
    "viewPaymentAccount" : 110,
    "deletePaymentAccount" : 111,
    "patientCheckInCheckOut" : 112,
    "optInOut" : 113,
    "uploadAttachments" : 114,
    "viewAttachments" : 115,
    "viewNotes" : 116,
    "productServicesManagement" : 201,
    "itemLookup" : 202,
    "viewAllInvoices" : 203,
    "addInvoice" : 204,
    "editInvoice" : 205,
    "sendToPatientInvoice" : 206,
    "resendInvoice" : 207,
    "closeAndWritreOff" : 208,
    "cancelInvoice" : 209,
    "invoicePayInFull" : 210,
    "createPaymentPlan" : 211,
    "createSubscriptionPlan" : 212,
    "viewAllClaims" : 301,
    "addNewClaim" : 302,
    "editClaim" : 303,
    "reScheduleClaim" : 304,
    "checkClaimStatus" : 305,
    "cancelClaim" : 306,
    "viewAllTransaction" : 401,
    "transactionReceipt" : 402,
    "voidTransaction" : 403,
    "refundTransaction" : 404,
    "cashAndCheckPayments" : 405,
    "updateCashAndCheckPayments" : 406,
    "achPayments" : 407,
    "viewAllplans" : 501,
    "viewReceipt" : 502,
    "viewTransactionHistory" : 503,
    "updatePlan" : 504,
    "updateAccount" : 505,
    "sendPaymentPlanReceipt" : 506,
    "24HourReminderScheduledPaymentNotifications(OTR)" : 507,
    "viewProviderSettings" : 601,
    "uploadLogo" : 602,
    "skins" : 603,
    "profile" : 604,
    "changePassword" : 605,
    "emailSetting" : 606,
    "viewNotificationSetting" : 607,
    "updateNotificationSetting" : 608,
    // "viewEquipmentType" : 609,
    // "updateEquipmentType" : 610,
    // "addEquipmentType" : 611,
    // "viewEquipment" : 612,
    // "updateEquipment" : 613,
    // "addEquipment" : 614,
    "viewAllInsurance" : 701,
    "viewInsuranceDetail" : 702,
    "addInsurance" : 703,
    "editInsurance" : 704,
    "viewAllUsers" : 801,
    "addUser" : 802,
    "editUser" : 803,
    "activatedUser" : 804,
    "deactivateUser" : 805,
    "userResetPassword" : 806,
    "viewAllPractitioner" : 901,
    "addPractitioner" : 902,
    "editPractitioner" : 903,
    "activatePractitioner" : 904,
    "deactivatePractitioner" : 905,
    "viewAllForms" : 1001,
    "addForms" : 1002,
    "editForms" : 1003,
    "linkForm" : 1004,
    "deactivateForm" : 1005,
    "activateForm" : 1005,
    "duplicateForm" : 1006,
    "viewPublicSubmissions" : 1007,
    "viewFormUrlAndQRCode" : 1008,
    "publicFormSubmissionEmailNotification" : 1009,
    "publicFormURLSenttoPatient" : 1010,
    "addsubmission" : 1011,
    "viewTrainingVideos" : 1101,
    "viewAllAppointment" : 1401,
    "addAppointment" : 1402,
    "editAppointment" : 1403,
    "deleteAppointment" : 1404,
    "24hAutoAppointmentReminder" : 1405,
    "viewEquipmentEquipmentType" : 1406,
    "addEditEquipmentEquipmentType" : 1407,
    "viewMasterEquipmentType" : 1408,
    "addEditMasterEquipmentType" : 1409,
    "transactionDashboardReports" : 1501,
    "patientReports" : 1502,
    "appointmentReports" : 1503,
    "invoiceReports" : 1504,
    "taxReports" : 1505,
    "viewEligibility" : 1601,
    "addEligibility" : 1602,
    "viewRoles" : 1701,
    "addUpdateRoles" : 1702,
    "activateDeactivateRole" : 1703,
    "roleLookup" : 1704,

    'patientModifyCommunications':9010010,
    'patientViewCommunications':9010020,
    'patientModifyDocuments':9010030,
    'patientViewDocuments':9010040,
    'patientModifyForms':9010050,
    'patientViewForms':9010060,
    'patientViewInsurance':9010070,
    'patientModifyNotes':9010080,
    'patientViewNotes':9010090,
    'patientModifyPatients':9010100,
    'patientViewPatients':9010110,
    'patientModifyWallet':9010120,
    'patientViewWallet':9010130,
    'patientContactInfo':9010111,

    'paymentsModifyInvoices':9020010,
    'paymentsNotifyInvoices':9020020,
    'paymentsViewInvoices':9020030,
    'paymentsModifySubscriptionsAndPlans':9020040,
    'paymentsViewSubscriptionsAndPlans':9020050,
    'paymentsModifyTransactions':9020060,
    'paymentsViewTransactions':9020070,

    'schedulingModifyAppointments':9140010,
    'schedulingNotifyAppointments':9140020,
    'schedulingViewAppointments':9140030,
    
    'settingsAllEquipment':9060010,
    'settingsAllForms':9060020,
    'settingsAllPatients':9060030,
    'settingsAllPracticeLocations':9060040,
    'settingsAllProductsServicesFees':9060050,
    'settingsAllProviders':9060060,
    'settingsAllScheduling':9060070,
    'settingsAllSecurity':9060080,
    'settingsAllPractice':9060090,
        

}
export default FeaturesEnum