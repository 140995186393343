import React, { useState, useEffect, useRef, useCallback } from 'react'
// import moment from 'moment'
import SMSConversationCard from './SMSConversationCard'
import AsyncSelect from 'react-select/async';
import CommonService from '../../../../services/api/common.service';
import { debounce } from 'lodash';

const SMSConversations = (props) => {

    const {conversations, onSelectPatient, patientId, filteredId} = props;
    const [showAddButton, setShowAddButton] = useState(false);

    const patientLoad = (inputText, callBack) => {
        if (inputText?.length < 3) return;
        let reqObj = { SearchTerm: inputText, isActive: true, isRegistered: true, SortField: 'firstName', Asc: true }
        CommonService.patientLookup(reqObj)
            .then(res => {
                if (res) {
                    callBack(res.data)
                }
            })
            .catch(err => console.log(err))
    }
    const rePatientLoad = useCallback(debounce(patientLoad, 500), [])


    useEffect(() => {
        if (filteredId) {
            // check if patient is in conversation list.  If not, enable add button
            if (conversations && conversations.findIndex(x => x.patientId == filteredId)==-1 ){
                setShowAddButton(true);
            }
            else {
                setShowAddButton(false);
            }
        }
    }, [filteredId]);    


    return (
        <div style={{backgroundColor: "whitesmoke"}} className="two-way-conversations">
            <div className='sms-section-headers'>
                <div className='field'>
                    <div className="input-group mx-0">
                        <AsyncSelect
                            classNamePrefix="react-select"
                            className="react-select-container"
                            name="patientId"
                            loadOptions={rePatientLoad}
                            placeholder="Search Patient"
                            onChange={e => {
                                console.log(e)
                                if (e?.id) {
                                    props.onFilterPatient(e)
                                }
                                else {
                                    props.setClearPatientName(true)
                                    props.onFilterPatient()
                                    
                                }
                            }}
                            isClearable={true}
                            getOptionLabel={(option) => {
                                return (
                                    <div className="d-flex row">
                                        <span className="col">{option.firstName} {option.lastName}</span>
                                        {/* Keep the following in case its requested */}
                                        {/* <span className='col text-start'><i className='icon birthday cake' />{moment.utc(option.dob).format("M/D/YYYY").toString()}</span> */}
                                        {/* <span className='col text-end'><i className='icon phone' />{option.mobile && Utilities.toPhoneNumber(option.mobile) || option.patientPhone && Utilities.toPhoneNumber(option.patientPhone)}</span> */}
                                    </div>
                                )
                            }}
                            getOptionValue={(option) => option.id}
                            noOptionsMessage={(e) => { 
                                return 'Begin typing to search patients...' 
                            }}
                        />
                        {showAddButton ? 
                            <button className='btn btn-primary' title="New conversation" onClick={e => { e.preventDefault(); props.startNewConversation() }}><i className='icon plus' /></button>
                        :null}
                    </div>
                </div>
            </div>
            <div className="two-way-conversations-list">
                {conversations ? 
                conversations.map((conversation, i) => {
                    return (
                    <div>
                        <SMSConversationCard conversation={conversation} onSelectPatient={onSelectPatient} patientId={patientId} filteredId={filteredId}/>
                    </div>
                    )
                }) : null}
            </div>
        </div>
    );

}

export default SMSConversations;