import React, { useState } from 'react'
import PatientService from '../../../../../services/api/patient.service'
import APIResponse from '../../../../templates/components/APIResponse'
import DimLoader from '../../../../templates/components/DimLoader'
import NoteForm from '../note-form/NoteForm'

const NoteEdit = (props) => {
    const [noteFormData, setNoteFormData] = useState(props.initialData)
    const [apiResponse, setApiResponse] = useState()
    const [isLoader, setIsLoader] = useState(false)

    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }

    const submitHandler = (data) => {
        setIsLoader(true)
        const reqObj = {
            description: data?.description,
            title: data?.title
        }
        PatientService.editNote(reqObj, noteFormData?.patientId, noteFormData?.id)
            .then(response => {
                setApiResponse(response)
            })
            .catch(error => {
                setApiResponse(error)
            })
            .finally(() => {
                setIsLoader(false)
            });
    }
    return (
        <>
            {isLoader &&
                <DimLoader loadMessage="Processing" />
            }
            {noteFormData && <NoteForm submitHandler={submitHandler} initialFormData={noteFormData} submitLabel="Update" onClose={() => { props.onClose() }}/>}
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </>

    )
}

export default NoteEdit