import React, { useState, useEffect } from 'react'
import CommonService from '../../../../services/api/common.service'
import BookKeepingDashboard from './BookKeepingDashboard'
import CallCenterDashboard from './CallCenterDashboard'
import DefaultDashboard from './DefaultDashboard'
import FrontDeskDashboard from './FrontDeskDashboard'
import ProviderAdminDashboard from './ProviderAdminDashboard'
import ProviderDashboard from './ProviderDashboard'
import StorageService from '../../../../services/session/storage.service'
import Module from '../../../templates/components/Module'
const MainDashboard = (props) => {
    const [role, setRole] = useState(0)
    const [display, setDisplay] = useState(0)
    const [isAdmin, setIsAdmin] = useState(false)
    const user = CommonService.getAuthData()
    useEffect(() => {
        console.log(user)
        if (user?.masterRoleId) {
            setRole(user.masterRoleId)
            setDisplay(user.masterRoleId)
            if(user.masterRoleId==1 || user.masterRoleId==0){
                setIsAdmin(true)
            }
        }
        if(user?.isAdmin==true){
            setIsAdmin(true)
        }
    }, [user?.masterRoleId])
    const Display = (props) => {
        switch (display) {
            case 0:
                return <DefaultDashboard />
            case 1:
                return <ProviderAdminDashboard />
            case 2:
                return <FrontDeskDashboard />
            case 3:
                return <ProviderDashboard isAdmin={isAdmin}/>
            case 4:
                return <CallCenterDashboard />
            case 5:
                return <BookKeepingDashboard />
            default:
                return <DefaultDashboard />
        }
    }
    return (
        <div className='d-flex row' id="main-dashboard">
            <div className='col-12 d-flex mt-3' id="dashboard-nav-wrap">
                {1==0 && isAdmin?
                    <>
                        <Module title="Dashboards" className="w-100 mobile" startOpen>
                            <div className='w-100 btn-group' id="dashboard-nav">
                                <button className={`btn ${display == 0 ? 'btn-primary' : 'btn-secondary'}`} onClick={e => { e.preventDefault(); setDisplay(0) }} value={0}>Default</button>
                                <button className={`btn ${display == 1 ? 'btn-primary' : 'btn-secondary'}`} onClick={e => { e.preventDefault(); setDisplay(1) }} value={1}>Admin</button>
                                <button className={`btn ${display == 2 ? 'btn-primary' : 'btn-secondary'}`} onClick={e => { e.preventDefault(); setDisplay(2) }} value={2}>Front Desk</button>
                                <button className={`btn ${display == 3 ? 'btn-primary' : 'btn-secondary'}`} onClick={e => { e.preventDefault(); setDisplay(3) }} value={3}>Provider</button>
                                <button className={`btn ${display == 4 ? 'btn-primary' : 'btn-secondary'}`} onClick={e => { e.preventDefault(); setDisplay(4) }} value={4}>Call Center</button>
                                <button className={`btn ${display == 5 ? 'btn-primary' : 'btn-secondary'}`} onClick={e => { e.preventDefault(); setDisplay(5) }} value={5}>Book Keeper</button>
                            </div>
                        </Module>
                        <div className='w-100 btn-group not-mobile' id="dashboard-nav">
                            {/* <div className='bg-white p-3 b-1'>Dashboards</div> */}
                            <button className={`btn ${display == 0 ? 'btn-primary' : 'btn-secondary'}`} onClick={e => { e.preventDefault(); setDisplay(0) }} value={0}>Default</button>
                            <button className={`btn ${display == 1 ? 'btn-primary' : 'btn-secondary'}`} onClick={e => { e.preventDefault(); setDisplay(1) }} value={1}>Admin</button>
                            <button className={`btn ${display == 2 ? 'btn-primary' : 'btn-secondary'}`} onClick={e => { e.preventDefault(); setDisplay(2) }} value={2}>Front Desk</button>
                            <button className={`btn ${display == 3 ? 'btn-primary' : 'btn-secondary'}`} onClick={e => { e.preventDefault(); setDisplay(3) }} value={3}>Provider</button>
                            <button className={`btn ${display == 4 ? 'btn-primary' : 'btn-secondary'}`} onClick={e => { e.preventDefault(); setDisplay(4) }} value={4}>Call Center</button>
                            <button className={`btn ${display == 5 ? 'btn-primary' : 'btn-secondary'}`} onClick={e => { e.preventDefault(); setDisplay(5) }} value={5}>Book Keeper</button>
                        </div>
                    </>
                    : null}
            </div>
            <div className='col-12 mt-3'>
                <DefaultDashboard />
                {/* <Display role={role} /> */}
            </div>
        </div>
    )
    // let role = CommonService.getLoggedInData()?.masterRoleId

}
export default MainDashboard