import React from 'react'
import PracticeLocationAvailabilityDashboard from '../../../practice-location-availability/PracticeLocationAvailabilitydashboard'
 
const PracticeLocationAvailability = (props) => {
    return(
        <div>
           <PracticeLocationAvailabilityDashboard keyword=""/>
        </div>
    )
}
 
export default PracticeLocationAvailability