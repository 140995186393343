import Select from 'react-select'

const HPTSelect = (props) => {

    return (
        <Select
            {...props}
            menuPortalTarget={document.body}
            menuPosition={'fixed'} 
            styles={{menuPortal: base => ({ ...base, zIndex: 9999 }),menu: provided => ({ ...provided, zIndex: 9999 })}}
        />
    )

}
export default HPTSelect
