import { useEffect, useState, useContext } from 'react'
import AppointmentService from "../api/appointment.service" // DO NOT REMOVE!
import PatientService from '../api/patient.service';
import PracticeLocationAvailabilityService from '../api/practice-location-availablity.service';
import CacheUtilities from './CacheUtilities';

import { store } from "../../context/StateProvider" ;

  const useGetAppointmentStatusCodes = () => {
    const data = useGetPracticeTypes({
        stateProperty: "practiceAppointmentStatusCodes",
        dispatchKey:"setAppointmentStatusCodes",
        APIService: "statusLookup"
    })
    return data;
  };  

  const useGetPracticePatientTypes = () => {
    const data = useGetPracticeTypes({
        stateProperty: "practicePatientTypes",
        dispatchKey:"setPracticePatientTypes",
        APIService: "practicePatientTypeLookup"
    })
    return data;
  };    

  const useGetPracticePatientTags = () => {
    const data = useGetPracticeTypes({
        stateProperty: "practicePatientTags",
        dispatchKey:"setPracticePatientTags",
        APIService: "getAllLookupTags",
        Service: PatientService
    })
    return data;
  };    

  const useGetLocationAvailability = () => {
    const globalStateAndDispatch = useContext(store);
    const contextState = globalStateAndDispatch.state;
    const data = useGetLocationTypes({
      stateProperty: "locationAvailability",
      dispatchKey:"setLocationAvailability",
      APIService: "practiceLocationAvailablity",
      Service: PracticeLocationAvailabilityService,
      Parameters: { PracticeLocationId: contextState.practiceLocationId }
    })
    return data;
  };

  const useGetLocationEquipment = () => {
    const data = useGetLocationTypes({
      stateProperty: "locationEquipment",
      dispatchKey:"setLocationEquipment",
      APIService: "equipmentLookup",
      SortBy: ""
    })
    return data;
  };

    const useGetLocationProviderServices = () => {
        const globalStateAndDispatch = useContext(store);
        const contextState = globalStateAndDispatch.state;
        const data = useGetLocationTypes({
            stateProperty: "locationProviderServices",
            dispatchKey:"setLocationProviderServices",
            APIService: "findWithServices",
            SortBy: "",
            Parameters: { isRegistered: true, isActive: true, PracticeLocationId: contextState.practiceLocationId, SortField: 'firstName' }
        })
        return data;
    };

    const useGetLocationRooms = () => {
        const data = useGetLocationTypes({
            stateProperty: "locationRooms",
            dispatchKey:"setLocationRooms",
            APIService: "practiceLocationRoomLookup",
            SortBy: ""
        })
        return data;
    };

    const useGetLocationServiceTypes = () => {
        const data = useGetLocationTypes({
            stateProperty: "locationServiceTypes",
            dispatchKey:"setLocationServiceTypes",
            APIService: "getPracticeServiceTypeForLocation",
            SortBy: "practiceServiceType"
        })
        return data;
    };


    const useGetPracticeTypes = (props) => {
        const globalStateAndDispatch = useContext(store);
        const contextState = globalStateAndDispatch.state;
        const contextDispatch = globalStateAndDispatch.dispatch;
        const [data, setData] = useState()
    
        const pullDataWhenNeeded = async () => {
            let alreadyExists = false;
            if (contextState && contextState.hasOwnProperty(props.stateProperty)) {
                let stateValues = contextState[props.stateProperty];
                if (stateValues
                    && Array.isArray(stateValues)) {
                        alreadyExists = true;
                        setData(stateValues);
                }
            }
    
            if (!alreadyExists) {
                const [data] = await Promise.all([
                    CacheUtilities.pullAPIData(props)
                ]);
                contextDispatch({ type: props.dispatchKey, payload: data });
                setData(data)
            }
        }
        useEffect(() => {
            if (contextState?.providerId && contextState?.providerId != contextState?.noProviderId) {
                pullDataWhenNeeded();
            }
        }, [contextState?.providerId]);
    
        return data;
    };
        

  const useGetLocationTypes = (props) => {
    const globalStateAndDispatch = useContext(store);
    const contextState = globalStateAndDispatch.state;
    const contextDispatch = globalStateAndDispatch.dispatch;
    const [data, setData] = useState()

    const pullDataWhenNeeded = async () => {
        let alreadyExists = false;
        if (contextState && contextState.hasOwnProperty(props.stateProperty)) {
            let stateLocationValues = contextState[props.stateProperty];
            if (stateLocationValues?.values
                && stateLocationValues?.locationId
                && Array.isArray(stateLocationValues.values) 
                && stateLocationValues.locationId == contextState.practiceLocationId) {
                    alreadyExists = true;
                    setData(stateLocationValues.values);
            }
        }

        if (!alreadyExists) {
            const [data] = await Promise.all([
                CacheUtilities.pullAPIData(
                    {
                        ...props,
                        practiceLocationId:contextState.practiceLocationId
                    }
                ),
            ]);
            contextDispatch({ type: props.dispatchKey, payload: data });
            setData(data)
        }
    }
    useEffect(() => {
        if (contextState.practiceLocationId) {
            pullDataWhenNeeded();
        }
    }, []);
    useEffect(() => {
        if (contextState.practiceLocationId) {
            pullDataWhenNeeded();
        }
    }, [contextState.practiceLocationId]);

    return data;
  };

export { 
    useGetAppointmentStatusCodes, 
    useGetPracticePatientTypes, 
    useGetPracticePatientTags,
    useGetLocationEquipment, 
    useGetLocationRooms,
    useGetLocationServiceTypes,
    useGetLocationTypes,
    useGetLocationProviderServices,
    useGetLocationAvailability,
};

