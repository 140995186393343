import React, { createContext, useReducer } from 'react'

const initialState = {
    skinData: {},
    settingsData: {},
    providerSelected: {},
    practiceLocationId: undefined,
    practiceLocations: [],
    isEmulate: false,
    providerId:"",
    practiceAppointmentStatusCodes:null,
    practicePatientTypes:null,
    practicePatientTags:null,
    locationAvailability:null,
    locationEquipment:null,
    locationProviderServices:null,
    locationRooms:null,
    locationServiceTypes:null,
    permissions: {unset:""},
    noProviderId: '0rbd3W69'
}
//this instance holds 2 components
// 1 - Provider -  component that is the parent to all needing access to context
// 2 - Consumer - your componenets
export const store = createContext(initialState)

const { Provider } = store

const StateProvider = (props) => {
     
    const { children } = props

    // Example of sign in/sign out functionality
    const [state, dispatch] = useReducer((state, action) => {
        if (action.type === "sign-out") {
            return initialState
        }
        if (action.type === "setSkin") {
            let newState = { ...state }
            newState.skinData = action.payload
            return { ...newState }
        }
        if (action.type === "setProviderSelected") {
            let newState = { ...state }
            newState.providerSelected = action.payload
            newState.providerId = action.payload.parentId
            return { ...newState }
        }
        if (action.type === "setSettingsData") {
            let newState = { ...state }
            newState.settingsData = action.payload
            return { ...newState }
        }
        if (action.type === "setPermissions") {
            let newState = { ...state }
            newState.permissions = action.payload
            return { ...newState }
        }
        if (action.type === "setLocationIds") {
            let newState = { ...state }
            newState.practiceLocations = action.payload
            // console.log(newState)
            return { ...newState }
        }
        if (action.type === "setPracticeLocationId") {
            let newState = { ...state }
            newState.practiceLocationId = action.payload
            return { ...newState }
        }

        if (action.type === "setAppointmentStatusCodes") {
            let newState = { ...state }
            if (action?.payload && Array.isArray(action.payload))
                newState.practiceAppointmentStatusCodes = action.payload
            else
                newState.practiceAppointmentStatusCodes = []
            return { ...newState }
        }
        if (action.type === "setPracticePatientTags") {
            let newState = { ...state }
            if (action?.payload && Array.isArray(action.payload))
                newState.practicePatientTags = action.payload
            else
                newState.practicePatientTags = null
            return { ...newState }
        }
        if (action.type === "setPracticePatientTypes") {
            let newState = { ...state }
            if (action?.payload && Array.isArray(action.payload))
                newState.practicePatientTypes = action.payload
            else
                newState.practicePatientTypes = null
            return { ...newState }
        }

        if (action.type === "setLocationAvailability") {
            let newState = { ...state }
            if (action?.payload && Array.isArray(action.payload))
                newState.locationAvailability = {
                    locationId: state.practiceLocationId,
                    values: action.payload
                }
            else
                newState.locationAvailability = null
            return { ...newState }
        }
        if (action.type === "setLocationEquipment") {
            let newState = { ...state }
            if (action?.payload && Array.isArray(action.payload))
                newState.locationEquipment = {
                    locationId: state.practiceLocationId,
                    values: action.payload
                }
            else
                newState.locationEquipment = null
            return { ...newState }
        }
        if (action.type === "setLocationProviderServices") {
            let newState = { ...state }
            if (action?.payload && Array.isArray(action.payload))
                newState.locationProviderServices = {
                    locationId: state.practiceLocationId,
                    values: action.payload
                }
            else
                newState.locationProviderServices = null
            return { ...newState }
        }
        if (action.type === "setLocationRooms") {
            let newState = { ...state }
            if (action?.payload && Array.isArray(action.payload))
                newState.locationRooms = {
                    locationId: state.practiceLocationId,
                    values: action.payload
                }
            else
                newState.locationRooms = null
            return { ...newState }
        }
        if (action.type === "setLocationServiceTypes") {
            let newState = { ...state }
            if (action?.payload && Array.isArray(action.payload))
                newState.locationServiceTypes = {
                    locationId: state.practiceLocationId,
                    values: action.payload
                }
            else
                newState.locationServiceTypes = null
            return { ...newState }
        }

        

        if (action.type === "setAllPracticeAndLocationValues") {
            let newState = { ...state }
            if (action?.payload) {

                newState.practiceAppointmentStatusCodes = action.payload.appointmentStatuses ?? null
                newState.practicePatientTypes = action.payload.patientTypes ?? null
                newState.practicePatientTags = action.payload.patientTags ?? null

                newState.practiceLocationId = action.payload.practiceLocationId
                newState.locationServiceTypes = {
                    locationId: action.payload.practiceLocationId,
                    values: action.payload.serviceTypes ?? null
                }
                newState.locationRooms = {
                    locationId: action.payload.practiceLocationId,
                    values: action.payload.rooms ?? null
                }
                newState.locationProviderServices = {
                    locationId: action.payload.practiceLocationId,
                    values: action.payload.providerServices ?? null
                }
                newState.locationEquipment = {
                    locationId: action.payload.practiceLocationId,
                    values: action.payload.equipment ?? null
                }
                newState.locationAvailability = {
                    locationId: action.payload.practiceLocationId,
                    values: action.payload.locationAvailability ?? null
                }
            }
            return { ...newState }
        }



        if (action.type === "setAllLocationValues") {
            let newState = { ...state }
            if (action?.payload) {
                newState.practiceLocationId = action.payload.practiceLocationId
                newState.locationServiceTypes = {
                    locationId: action.payload.practiceLocationId,
                    values: action.payload.serviceTypes
                }
                newState.locationRooms = {
                    locationId: action.payload.practiceLocationId,
                    values: action.payload.rooms
                }
                newState.locationProviderServices = {
                    locationId: action.payload.practiceLocationId,
                    values: action.payload.providerServices
                }
                newState.locationEquipment = {
                    locationId: action.payload.practiceLocationId,
                    values: action.payload.equipment
                }
                newState.locationAvailability = {
                    locationId: action.payload.practiceLocationId,
                    values: action.payload.locationAvailability
                }
            }
            return { ...newState }
        }

        if (action.type === "setEmulate") {
            let newState = { ...state }
            newState.isEmulate = action.payload
            return { ...newState }
        }
        if (action.type === "setAll") {
            let newState = action.payload
            newState.providerId = action.payload.providerSelected.parentId
            return { ...newState }
        }
        if (action.type === "reset") {
            return { initialState}
        }
        if (action.type === "clearLocationCache") {
            let newState = { ...state }
            newState.locationAvailability=null
            newState.locationEquipment=null
            newState.locationProviderServices=null
            newState.locationRooms=null
            newState.locationServiceTypes=null
            return { ...newState }
        }
    }, initialState)

    return (
        <Provider value={{ state, dispatch }}>
            {children}
        </Provider>
    )
}

export default StateProvider