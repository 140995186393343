import moment from 'moment';
import React, { useEffect, useState, useContext, useCallback } from 'react'
import Select from 'react-select'
import { store } from '../../../../context/StateProvider';
import CommonService from '../../../../services/api/common.service'
import Utilities from '../../../../services/commonservice/utilities';
import Module from '../../../templates/components/Module';
import Table2 from '../../../templates/components/Table2';
import DatePicker from '../../../templates/components/DatePicker';
import { AlertTypeEnum } from '../../../../common/enum/alert-type.enum';
import { AlertStatusEnum } from '../../../../common/enum/alert-status.enum';
import { debounce } from 'lodash'
import AsyncSelect from 'react-select/async'
import DimLoader from '../../../templates/components/DimLoader';
import NotificationService from '../../../../services/api/notification.service';
import APIResponse from '../../../templates/components/APIResponse';
import { Checkbox } from 'semantic-ui-react';

import ModalBox from '../../../templates/components/ModalBox';
import PatientAccountRenewWrapper from '../patient-account/patient-account-renew/PatientAccountRenewWrapper';
import PatientCardWrapper from '../patient/patient-list/patient-card/PatientCardWrapper';
import AlertFormView from '../patient/patient-list/patient-card/forms/forms-list/AlertFormView';
import LeadWrapper from '../patient/lead-settings/LeadWrapper';


const AlertTable = (props) => {
    const globalStateAndDispatch = useContext(store);
    const contextState = globalStateAndDispatch.state;
    const [alerts, setAlerts] = useState()
    const [isLoader, setIsLoader] = useState(false)
    const [startDate, setStartDate] = useState(moment().startOf("D"));
    const [endDate, setEndDate] = useState(moment().endOf("D"));

    const [keyword, setKeyword] = useState()
    const [initialMessage, setInitialMessage] = useState(true)
    const [openLeadModal, setOpenLeadModal] = useState(false);
    const [renewCardModal, setRenewCardModal] = useState(false);
    const [formModal, setFormModal] = useState(false);

    const [selectedRow, setSelectedRow] = useState();
    const [typeFilter, setTypeFilter] = useState()
    const [statusFilter, setStatusFilter] = useState(1)
    const [updateFlag, setUpdateFlag] = useState([])
    const [apiResponse, setApiResponse] = useState()
    const state = useContext(store).state
    const locationList = state.practiceLocations
    const [locationId, setLocationId] = useState()
    const [patientCard, setPatientCard] = useState()
    const [patientId, setPatientId] = useState()
    // const for table 
    const columns = [
        {
            id: "patientName",
            Header: "Patient",
            accessor: (tc) => tc.patientName ?
                <button className="btn btn-primary"
                    onClick={(e) => {
                        e.preventDefault();
                        setPatientId(tc.patientId);
                        setPatientCard(true)
                    }}>
                    {tc.patientName}
                </button> : null
        },
        {
            id: "description",
            Header: "Description",
            accessor: (tc) => tc.description
        },
        {
            id: "note",
            Header: "Note",
            accessor: (tc) => tc.note
        },
        {
            id: "createdOn",
            Header: "Created On",
            accessor: (tc) => moment(tc.createdOn).format("MM-DD-YYYY h:mm A"),
        },
        {
            id: "modifiedOn",
            Header: "Modified On",
            accessor: (tc) => tc.modifiedOn ? moment(tc.modifiedOn).format("MM-DD-YYYY h:mm A") : "",
        },
        {
            id: "alertType",
            Header: "Alert Type",
            accessor: (tc) => AlertTypeEnum.find(obj => obj.value === tc.alertType)?.title ? AlertTypeEnum.find(obj => obj.value === tc.alertType)?.title : tc.alertType
        },
        {
            id: "status",
            Header: "Status",
            accessor: (tc) => AlertStatusEnum.find(obj => obj.value === tc.status)?.title ? AlertStatusEnum.find(obj => obj.value === tc.status)?.title : tc.status
        },
        {
            id: "isRead",
            Header: "Read",
            textAlign: 'center',
            accessor: (tc, i) => {
                return tc.status === 1 ?
                    <div key={i} className='w-100 d-flex justify-content-center'>
                        <Checkbox checked={tc.isChecked} onChange={e => handleCheckboxClick(e, tc.id)} />
                    </div> : null
            }
        },
        {
            id: "action",
            Header: "Action",
            disableSortBy: true,
            accessor: (tc, i) => {
                return (
                    <>{tc.alertType === 2 ? <div className="d-flex justify-content-center">
                        <button
                            className="p-0 ps-1 btn btn-primary"
                            title="Edit Lead"
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedRow(tc);
                                return setOpenLeadModal(true);
                            }}
                        >
                            <i className="icon pencil" />
                        </button>
                    </div> : (tc.alertType === 1 ? <div className="d-flex justify-content-center">
                        <button
                            className="p-0 ps-1 btn btn-primary"
                            title="Renew Patient Account"
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedRow(tc);
                                return setRenewCardModal(true);
                            }}
                        >
                            <i className="icon pencil" />
                        </button>
                    </div> : ([3, 4].includes(tc.alertType) ? <div className="d-flex justify-content-center">
                        <button
                            className="p-0 ps-1 btn btn-primary"
                            title="View Form"
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedRow(tc);
                                return setFormModal(true);
                            }}
                        >
                            <i className="icon eye" />
                        </button>
                    </div> : null))}</>
                );
            },
        }
    ]
    const onSuccess = (message) => {
        alertLookup();
    }
    useEffect(() => {
        let x = alerts && alerts.find(x => x.isChecked === true)
        if (x) {
            setUpdateFlag(true)
        } else setUpdateFlag(false)
    }, [alerts])
    useEffect(() => {
        alertLookup()
    }, [])
    const handleCheckboxClick = (e, alertId) => {
        const newState = alerts.map(obj =>
            obj.id === alertId ? { ...obj, isChecked: !obj.isChecked } : obj
        );
        setAlerts([...newState])
    }
    const updateAlertStatuses = () => {
        setIsLoader(true);
        let updateStatusList = alerts.filter(obj => obj.isChecked === true).map(x => { return { status: 2, alertId: x.id } }
        );
        NotificationService.updateAlertStatuses(updateStatusList)
            .then((response) => {
                setApiResponse(response)
            })
            .catch(error => {
                setApiResponse(error)
            }).finally(() => {
                setIsLoader(false);
            })
    }
    const alertLookup = () => {
        setIsLoader(true)
        setInitialMessage(false);
        let reqObj = {
            //sortField: 'createdOn', defaulted in backend
            Asc: 'false',
            // FromDate: moment(startDate).toISOString(),
            // ToDate: moment(endDate).toISOString()
        }
        if (typeFilter) reqObj.AlertTypes = typeFilter;
        if (statusFilter) reqObj.Statuses = statusFilter;
        if (locationId) reqObj.PracticeLocationId = locationId;
        if (keyword) {
            reqObj.PatientIds = keyword
        }
        NotificationService.allAlerts(reqObj)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    res.data.map(x => {
                        x.isChecked = false;
                        return x
                    })
                }
                setAlerts([...res.data])
                return setIsLoader(false)
            })
    }

    const patientLoad = (inputText, callBack) => {
        if (inputText?.length < 3) return;
        let reqObj = { SearchTerm: inputText, isActive: true, isRegistered: true, SortField: 'firstName', isAsc: true }
        CommonService.patientLookup(reqObj)
            .then(res => {
                if (res) {
                    callBack(res.data)
                }
            }
            )
            .catch(err => console.log(err))
    }
    const rePatientLoad = useCallback(debounce(patientLoad, 500), [])
    return (
        <>
            {isLoader &&
                <DimLoader loadMessage="Processing" />
            }

            <div className='row d-flex g-3'>
                <div className='col-12 m-p-0'>
                    <Module title="Filters">
                        <div className='row d-flex'>
                            <div className='col-12 row d-flex mx-0 align-items-end report-filters'>
                                {/* <DatePicker startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} setLocationId={setLocationId} /> */}
                                <div className='col-md col-12'>
                                    <label>Practice Location</label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={locationList && locationList}
                                        isLoading={!locationList}
                                        isClearable={true}
                                        loadingMessage="loading..."
                                        name="practiceLocationId"
                                        placeholder="All"
                                        value={locationList && Array.isArray(locationList) ? locationList.find(obj => obj.practiceLocationId === locationId) : null}
                                        onChange={e => {
                                            if (e?.practiceLocationId) {
                                                setLocationId(e.practiceLocationId)
                                            }
                                            else {
                                                setLocationId()
                                            }
                                        }}
                                        getOptionLabel={(option) => option.practiceLocation}
                                        getOptionValue={(option) => option.practiceLocationId}
                                    >
                                    </Select>
                                </div>
                                <div className='col-md col-12'>
                                    <label>Alert Type</label>
                                    <select className='form-select' value={typeFilter || null} onChange={e => { e.preventDefault(); setTypeFilter(e.target.value) }}>
                                        {AlertTypeEnum.map((obj, i) => {
                                            return (
                                                <option value={obj.value}>
                                                    {obj.title}
                                                </option>
                                            )
                                        })
                                        }
                                    </select>
                                </div>
                                <div className='col-md col-12'>
                                    <label>Status</label>
                                    <select className='form-select' value={statusFilter || null} onChange={e => { e.preventDefault(); setStatusFilter(e.target.value) }}>
                                        {AlertStatusEnum.map((obj, i) => {
                                            return (
                                                <option value={obj.value}>
                                                    {obj.title}
                                                </option>
                                            )
                                        })
                                        }
                                    </select>
                                </div>
                                <div className='col-md col-12'>
                                    <div className='ui field'>
                                        <label>Patient</label>
                                        <AsyncSelect
                                            classNamePrefix="react-select"
                                            className="react-select-container"
                                            name="patientId"
                                            loadOptions={rePatientLoad}
                                            placeholder="Search Patient"
                                            onChange={e => {
                                                console.log(e)
                                                if (e?.id) {
                                                    setKeyword(e.id)
                                                }
                                                else {
                                                    setKeyword()
                                                }
                                            }}
                                            isClearable={true}
                                            getOptionLabel={(option) => {
                                                return (
                                                    <div className="d-flex row">
                                                        <span className="col">{option.firstName} {option.lastName}</span>
                                                        <span className='col text-start'><i className='icon birthday cake' />{moment.utc(option.dob).format("M/D/YYYY").toString()}</span>
                                                        <span className='col text-end'>
                                                            {option.mobile && option.mobile != '' || option.patientPhone && option.patientPhone != '' ?
                                                                <>
                                                                    <i className='icon phone' />
                                                                    {option.mobile && option.mobile != '' ? Utilities.toPhoneNumber(option.mobile) : option.patientPhone && option.patientPhone != '' ? Utilities.toPhoneNumber(option.patientPhone) : null}
                                                                </> : null}
                                                        </span>
                                                    </div>
                                                )
                                            }}
                                            getOptionValue={(option) => option.id}
                                            noOptionsMessage={(e) => {
                                                if (!contextState.permissions.patientModifyPatients) {
                                                    return null;
                                                }
                                                else {
                                                    return <button className='btn btn-primary form-control' onClick={e => { e.preventDefault(); setShowAdd(true) }}>Add Patient</button>
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-auto col-12">
                                    <button className="btn btn-primary" onClick={e => { e.preventDefault(); alertLookup() }} title="Pull Report">
                                        <i className="icon arrow circle right" />
                                    </button>
                                </div>

                            </div>
                        </div>
                    </Module>
                </div>
                <div className='col-12 m-p-0'>
                    <Module title="Alert Report">
                        <Table2 export searchable title="Alert Report" data={alerts} columns={columns} loading={isLoader} initialMessage={initialMessage}
                            extraButtons={[
                                {
                                    className: updateFlag ? 'btn btn-primary ' : 'btn btn-secondary disabled', children: 'Update Status', onClick: () => { return updateAlertStatuses() }
                                },
                            ]}
                        />

                    </Module>
                </div>
            </div>
            <ModalBox open={openLeadModal} onClose={() => setOpenLeadModal(false)} title="Edit Lead">
                {selectedRow && <LeadWrapper
                    leadId={selectedRow.alertKey}
                    closeModal={setOpenLeadModal}
                    onClose={() => {
                        alertLookup()
                        return setOpenLeadModal(false);
                    }}
                />}
            </ModalBox>
            <ModalBox open={renewCardModal} onClose={() => { setRenewCardModal(false) }} title="Renew Patient Account">
                {selectedRow && <PatientAccountRenewWrapper
                    accountId={selectedRow.alertKey}
                    patientId={selectedRow.patientId}
                    closeModal={setRenewCardModal}
                    onClose={() => {
                        alertLookup()
                        return setRenewCardModal(false)
                    }}
                />}
            </ModalBox>
            <ModalBox open={patientCard} onClose={() => { setPatientId(); setPatientCard(false); }} size="fullscreen" title="Patient">
                <PatientCardWrapper patientId={patientId} />
            </ModalBox>
            <ModalBox open={formModal} onClose={() => { setPatientId(); setFormModal(false); }} size="fullscreen" title="View Form">
                {/* "60f6b8285065a80008542211" */}
                {selectedRow && <AlertFormView patientId={selectedRow.patientId} submissionId={selectedRow.alertKey} onClose={() => { setPatientId(); setFormModal(false); }} />}
            </ModalBox>
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </>
    )
}

export default AlertTable