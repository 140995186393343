import React, { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import defaultLogo from "../../../../assets/images/logo_login.png";
// import defaultLogo from "../../../../assets/images/revitalized-logo.png";
import ProviderMenu from "../../../menus/ProviderMenu";
import LoginService from "../../../services/api/login.service";
import StorageService from "../../../services/session/storage.service";
import { store } from "../../../context/StateProvider";
import AppointmentService from "../../../services/api/appointment.service";
import { Container, Nav, Navbar } from "react-bootstrap";
import AddInvoice from "../../secure/provider/invoices/add-invoice/AddInvoice";

import IdleTimer from "react-idle-timer";
import LogoutModal from "../../templates/components/LogoutModal";
import ModalBox from "../../templates/components/ModalBox";
import PatientMenu from "../../../menus/PatientMenu.js";
import AdminMenu from "../../../menus/AdminMenu";
import ThemeService from "../../../services/api/theme.service";
import toast from "react-hot-toast";

import Badge from '@material-ui/core/Badge';

import Utilities from "../../../services/commonservice/utilities";
import NotificationService from "../../../services/api/notification.service";
import TwoWay from "../../secure/provider/TwoWay/TwoWay";
import AccountSettings from "../../secure/provider/settings-dashboard/account-settings/AccountSettings.js";

const TopNav = (props) => {
  const navigate = useNavigate();
  const globalStateAndDispatch = useContext(store);
  const contextState = globalStateAndDispatch.state;
  const contextDispatch = globalStateAndDispatch.dispatch;
  const [logo, setLogo] = useState(defaultLogo);
  const [menu, setMenu] = useState();
  const [pendingMessagesCount, setPendingMessagesCount] = useState(0);
  const [alertsCount, setAlertsCount] = useState(0);
  const [twoWay, setTwoWay] = useState(false);
  const [providerId, setProviderId] = useState();

  const [expanded, setExpanded] = useState(false);
  const [addInvoice, setAddInvoice] = useState(false);
  const [accountSetting, setAccountSetting] = useState(false);
  const [screenLocked, setScreenLocked] = useState(false)
  


  const getProviderId = () => {
    let loggedInUserData = JSON.parse(StorageService.get('session', "userDetails"));
    setProviderId(loggedInUserData.parentId);
  };

  // Initial data formulas
  const practiceLocationLookup = () => {
    let data = JSON.parse(StorageService.get('session', 'userDetails'))
    if (data?.userType === 1) {
      AppointmentService.practiceLocationLookup()
        .then((res) => {
          // console.log(res)
          contextDispatch({ type: "setLocationIds", payload: res });
          contextDispatch({
            type: "setPracticeLocationId",
            payload: res[0].practiceLocationId,
          });
          StorageService.save("session", "locale", JSON.stringify(res))
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const getLogo = () => {
    let logoPath = StorageService.getLogo(contextState.practiceLocationId);
    if (logoPath) {
      setLogo(logoPath)
    }
    else {
      setLogo(defaultLogo)
    }
  }


  const logout = () => {
    toast.success("Logout")
    LoginService.logOut()
  };

  const logoutEmulate = () => {
    LoginService.logOut()
  };

  useEffect(() => {
    if (StorageService.get("session", "locale")) {
      contextDispatch({ type: "setLocationIds", payload: JSON.parse(StorageService.get("session", "locale")) });
    }
    else {
      practiceLocationLookup();
    }
    getLogo();
    getProviderId();
  }, []);

  const getPendingMessagesCount = () => {
    NotificationService.getPendingMessagesCount(contextState.practiceLocationId)
    .then(res => {
        if (res) {
          setPendingMessagesCount(res?.data?.unReadMessageCount||0)
        }
    })
    .catch(err => {
        console.log(err);
    })
  };

  const getAlertsCount = () => {
    //For now we want the count shown in the nav bar to be for all locations
    NotificationService.getAlertsCount()//(contextState.practiceLocationId)
    .then(res => {
        if (res) {
          setAlertsCount(res?.data?.unReadCount||0)
        }
    })
    .catch(err => {
        console.log(err);
    })
  };

  useEffect(() => {
    if (window.location.pathname != '/provider/settings/practice') {
      let check = JSON.parse(StorageService.get('session', 'settingsData'))
      if (check) {
        getLogo()
        if (check.skin) {
          ThemeService.changeTheme(check.skin)
        }
        else {
          ThemeService.changeTheme(7)
        }
      }
    }
  }, [JSON.parse(StorageService.get('session', 'settingsData'))])
  useEffect(() => {
    let data = JSON.parse(StorageService.get('session', 'userDetails'))
    setUserType(data?.userType)
    switch (data?.userType) {
      case 0:
        setMenu(PatientMenu)
        break
      case 1:
        setMenu(ProviderMenu)
        break
      case 2:
        setMenu(AdminMenu)
        break
      default:
        setMenu(ProviderMenu)
        break
    }
  }, [])

  const [showWarning, setShowWarning] = useState(false);
  const [idleTimer, setIdleTimer] = useState();
  const [logoutTimer, setLogoutTimer] = useState();
  const [userType, setUserType] = useState(1)
  const [seconds, setSeconds] = useState(600); // 10 min total 2 min warning in nav bar to auto log out in 

  useEffect(() => {
    Utilities.on("patientAlert", (data)=>{
      getPendingMessagesCount();
    });
    Utilities.on("refreshPendingMessageCount", (data)=>{
      getPendingMessagesCount();
    });
    getPendingMessagesCount();
    getAlertsCount() //For now we want the count shown in the nav bar to be for all locations
  }, [userType]);

  // useEffect(() => {
  //   if(contextState.practiceLocationId){
  //     //fetch count every location update
  //     getAlertsCount()
  //   }
  // }, [contextState.practiceLocationId]);    

  useEffect(() => {
    const timer = setTimeout(() => {
      setSeconds(seconds - 1);
    }, 1000);
  });



  useEffect(() => {
    setScreenLocked(window.location.pathname.includes('screenlock'));
  });

  const onIdle = () => {
    // if screen already locked, reset
    if (screenLocked) {
      idleTimer?.reset();
    }
    else {
      setShowWarning(true);
      setLogoutTimer(
        setTimeout(() => {
          setShowWarning(false);
          idleTimer?.reset();
          navigate('provider/screenlock')
        }, 30000) // 30 seconds till screen lock
      );
  
    }

  };

  const togglePopUp = () => {
    setShowWarning((prevState) => ({ showWarning: !prevState.showWarning }));
  };
  // test
  const handleStayLoggedIn = () => {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
      setLogoutTimer();
    }
    // clearInterval(timer);
    idleTimer?.reset();
    togglePopUp();
  };


  return (
    <div>
      <IdleTimer
        className="d-block"
        ref={(ref) => {
          setIdleTimer(ref);
        }}
        element={document}
        stopOnIdle={true}
        onIdle={onIdle}
        timeout={600000} // 600000
      // modalbax pops up at 10 min
      />
      <ModalBox
        open={showWarning}
        onClose={() => {
          setShowWarning(false);
        }}
      >
        <LogoutModal />
        <div className="col-auto mt-3" onMouseMove={() => { handleStayLoggedIn(); return setShowWarning(false) }}>
          <button
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              handleStayLoggedIn(e);
              setShowWarning(false);
            }}
          >
            Resume
          </button>
        </div>

      </ModalBox>

      <ModalBox open={addInvoice} onClose={() => setAddInvoice(false)} size="fullscreen" title="Add Invoice">
        <AddInvoice onClose={()=>{setAddInvoice(false); }} isModal/>
      </ModalBox>

      {twoWay ? 
        <TwoWay onClose={() => { setTwoWay(false); }} />
      :null}
      <ModalBox open={accountSetting} onClose={() => setAccountSetting(false)} size="medium" title="Account Settings">
        <AccountSettings onClose={() => { setAccountSetting(false); }} />
      </ModalBox>

      <div>
        <div className="navbar-wrap">
          <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark"
            // fixed="top"
            sticky="top"
            expanded={expanded} onToggle={() => { setExpanded(!expanded) }}>
            <Container fluid className="bg-primary">
              <div className={`navbar-mobilenav d-flex row align-items-center justify-content-between ${expanded?'expanded':''}`}>
                {expanded?<div className="col-2"></div>:null}
                <div className="col-auto d-flex align-items-center justify-content-start">
                  <Navbar.Brand className="navbar-brand"><NavLink to={`./${userType === 1 ? 'provider' : userType === 2 ? 'admin' : 'patient'}/`} onClick={() => {getAlertsCount(); }}>
                    <div className="nav-image" style={{ backgroundImage: `url(${logo || defaultLogo})` }} /></NavLink>
                  </Navbar.Brand>
                </div>
                <div className="col-2 text-center d-flex justify-content-end">
                  <Navbar.Toggle className="force-square" 
                  children={expanded ? <i className="icon close" /> : <i className="icon bars stacked" />}
                   aria-controls="responsive-navbar-nav" />
                </div>
              </div>
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="col-lg-12">
                  <div className="col-12 d-flex row mx-0 justify-content-around align-items-center">
                    {menu && menu.map((routes, i) => { 
                        return routes.access && routes.access(contextState) &&
                        <Nav.Item className="col-lg-auto col-md-4 col-6 nav-item text-center" key={i} onClick={() => { getAlertsCount(); setExpanded(false) }}>
                          <NavLink className="nav-link menu-link" to={`${userType === 1 ? 'provider' : userType === 2 ? 'admin' : 'patient'}/` + routes.path}>
                            <div>
                              {routes.icon ? <i className={`ui icon large ${routes.icon} mb-1`}></i> : null}
                              <br />
                              <span className="d-inline-block">{routes.title}</span>
                            </div>
                          </NavLink>
                        </Nav.Item>
                    })
                    }
                    {userType!==2?<Nav.Item className="col-lg-auto col-md-4 col-6 nav-item text-center align-items-center" onClick={() => {getAlertsCount(); }}>
                      <NavLink href="" className="nav-link menu-link btn-group" to={`${userType === 1 ? 'provider' : userType === 2 ? 'admin' : 'patient'}/alerts`}>
                        <div> <i className="ui icons ms-2 mb-1">
                        <Badge badgeContent={alertsCount} overlap='circle'>
                        <i className="icon large bell" />
                          </Badge>
                          
                          <i className="icon p-0 pe-1 text-primary">3</i>
                        </i>
                          <br />
                          <span>Alerts</span>
                        </div>
                      </NavLink>
                    </Nav.Item>:null}
                    {!screenLocked && (contextState.permissions.patientModifyCommunications||contextState.permissions.patientViewCommunications) && userType!==2
                    && contextState.settingsData?.practiceLocationDetails?.find(obj => obj.practiceLocationId === contextState.practiceLocationId)?.isTwoWayEnabled   ? // TODO Add logic to use module access.
                      <Nav.Item className="col-lg-auto col-md-4 col-6 nav-item text-center align-items-center">
                      <a className="nav-link menu-link" onClick={e => { e.preventDefault();getAlertsCount(); setTwoWay(true) }}>
                        <div> 
                          <i className="ui icons ms-2 mb-1">
                            <Badge badgeContent={pendingMessagesCount} overlap='circle'>
                            <i className="icon large comment" />
                            </Badge>
                            <i className="icon p-0 pe-1 text-primary">3</i>
                          </i>
                          <br />
                          <span>Messages</span>
                        </div>
                      </a>
                    </Nav.Item>:null}

                    {!screenLocked && (contextState.permissions.paymentsModifyInvoices) && userType!==2 ? 
                    <Nav.Item className="col-lg-auto col-md-4 col-6 nav-item text-center align-items-center">
                      <a className="nav-link menu-link" style={{cursor:"pointer"}} onClick={e => { e.preventDefault(); getAlertsCount(); setAddInvoice(true) }}>
                        <div>
                          <i className="ui icon cart large mb-1"></i>
                          <br />
                          <span>
                            Checkout
                          </span>
                          </div>
                      </a>
                    </Nav.Item>:null}
                    {!screenLocked && userType !== 2 ? <Nav.Item className="col-lg-auto col-md-4 col-6 nav-item text-center point">
                      <a className="nav-link menu-link" onClick={e => { e.preventDefault();getAlertsCount(); setAccountSetting(true) }}>
                        <div>
                          <i className="ui icon user large mb-1"></i>
                          <br />
                          <span>
                            Account
                          </span>
                        </div>
                      </a>
                    </Nav.Item> : null}
                    {userType===2?<Nav.Item className="col-lg-auto col-md-4 col-6 nav-item text-center align-items-center">
                      <NavLink href="" className="nav-link menu-link btn-group" to={`admin/forms`}>
                        <div> 
                        <i className="ui icon file alternate large mb-1"></i>
                          <br />
                          <span>Form Management</span>
                        </div>
                      </NavLink>
                    </Nav.Item>:null}
                    {contextState?.isEmulate ?
                      <Nav.Item className="col-lg-auto col-md-4 col-6 nav-item text-center point">
                        <a className="nav-link menu-link" onClick={e => { e.preventDefault(); logoutEmulate(e) }}>
                          <div>
                            <i className="ui icon lock large mb-1"></i>
                            <br />
                            <span>
                              Leave Emulation
                            </span>
                          </div>
                        </a>
                      </Nav.Item>
                      : <Nav.Item className="col-lg-auto col-md-4 col-6 nav-item text-center point">
                        <a className="nav-link menu-link" onClick={e => { e.preventDefault(); logout() }}>
                          <div>
                            <i className="ui icon lock large mb-1"></i>
                            <br />
                            <span>
                              Logout
                            </span>
                          </div>
                        </a>
                      </Nav.Item>}
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    </div >
  );
};

export default TopNav;
