import React, { useState, useContext } from 'react'
import Module from '../../../../../templates/components/Module';
import { store } from '../../../../../../context/StateProvider';
import APIResponse from '../../../../../templates/components/APIResponse';
import CommonService from '../../../../../../services/api/common.service';
import ModalBox from '../../../../../templates/components/ModalBox';
import UserService from '../../../../../../services/api/user.service';
import StorageService from '../../../../../../services/session/storage.service';

const TextNotificationSettings = (props) => {

    const globalStateAndDispatch = useContext(store)
    const state = globalStateAndDispatch.state
    const contextDispatch = globalStateAndDispatch.dispatch
    const [isOptIn, setIsOptIn] = useState(state.providerSelected.optIn);
    const [optInValue, setOptInValue] = useState(state.providerSelected.optIn);
    const [apiResponse, setApiResponse] = useState()
    const [isLoader, setIsLoader] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [loggedInUserData, setLoggedInUserData] = useState(JSON.parse(StorageService.get('session', "userDetails")));
    const onSuccess = (message) => {
        setOptInValue(isOptIn)
        StorageService.save('session', 'userDetails', JSON.stringify(loggedInUserData));
        contextDispatch({ type: 'setProviderSelected', payload: loggedInUserData })
    }
    const handleIsOptIn = () => {
        setIsOptIn(!isOptIn)
        setLoggedInUserData({ ...loggedInUserData, optIn: !isOptIn });
    }
    const updateOptInStatus = () => {
        setIsLoader(true)
        let reqObj = {
            optIn: isOptIn ? 1 : 0,
        }
        UserService.updateOptInStatus(reqObj, CommonService.getLoggedInData().id)
            .then((res) => {
                setIsLoader(false)
                setApiResponse(res);
            })
            .catch((err) => {
                setIsLoader(false)
                setApiResponse(err);
            });
    }
    return (
        <div className='col m-p-0'>

            <Module title="Text Message Notifications">
                {isLoader && <div className="ui">
                    <div className="ui active dimmer">
                        <div className="ui indeterminate text loader">Processing...</div>
                    </div>
                </div>}
                <div className="col-12 field">
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name="isOptIn"
                            checked={isOptIn}
                            onInputCapture={(e) => {
                                handleIsOptIn();
                            }}
                        />
                        <label className="form-check-label">
                            Opted-in to text messages
                        </label>
                    </div>
                </div>
                <div className='col-12 d-flex flex-row-reverse'>
                    <div className='col-auto'>
                        <button className='btn btn-primary'
                            style={{ justifyContent: 'flex-end' }}
                            onClick={e => { e.preventDefault(); setShowConfirm(true) }}
                            disabled={optInValue === isOptIn}
                        >Update</button>
                    </div>
                </div>
            </Module>
            <ModalBox open={showConfirm} onClose={() => { setShowConfirm(false) }} onCloseSuccess={() => { setShowConfirm(false); updateOptInStatus() }} title="Confirm" confirmButton="Confirm" cancelButton="Cancel">
                {isOptIn ? <span>I agree to receive messages from <a href="https://revique.io/" target="_blank">Revique.io</a> at the phone number I provided. I understand that I will receive management alerts and messages, and that data rates may apply. Please reply STOP to opt out.</span>
                    : <span>By confirming, I understand that I will no longer receive messages from <a href="https://revique.io/" target="_blank">Revique.io</a>.</span>}
            </ModalBox>
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </div>
    )
}

export default TextNotificationSettings