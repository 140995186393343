import React, { useEffect, useState, useContext } from 'react'
import PracticeLocationAvailabilityService from '../../../../services/api/practice-location-availablity.service';
import List from '../../../templates/components/List'
import Select from "react-select"
import toast from 'react-hot-toast';
import ModalBox from '../../../templates/components/ModalBox';
import PracticeLocationSelector from '../../../templates/components/PracticeLocationSelector'
import { store } from "../../../../context/StateProvider";
import Utilities from '../../../../services/commonservice/utilities';
import moment from "moment";

const PracticeLocationAvailablityList = (props) => {
    const state = useContext(store).state;
    const [isLoader, setIsLoader] = useState(false)
    const [sortBy, setSortBy] = useState('')

    const [locationId, setLocationId] = useState()
    const [locationLookupList, setLocationLookupList] = useState([])
    const [deleteModal, setDeleteModal] = useState(0)


    const dowList = [
        {
            label: 'Sun',
            value: 1
        },
        {
            label: 'Mon',
            value: 2
        },
        {
            label: 'Tue',
            value: 3
        },
        {
            label: 'Wed',
            value: 4
        },
        {
            label: 'Thu',
            value: 5
        },
        {
            label: 'Fri',
            value: 6
        },
        {
            label: 'Sat',
            value: 7
        },
    ]

    const onSort = (e) => {
        setSortBy(e.target.value)
    }


    const getLocationAvailablity = () => {
        setIsLoader(true);
        PracticeLocationAvailabilityService.practiceLocationAvailablity({ PracticeLocationId: locationId })
            .then((response) => {
                response.selectResponse.sort((a, b) => (a.dow > b.dow) ? 1 : ((b.dow > a.dow) ? -1 : 0))
                response.selectResponse.forEach(obj => {
                    obj.startTime = formatAsLocalTime(obj.startTime)
                    obj.endTime = formatAsLocalTime(obj.endTime)
                })
                setLocationLookupList(response.selectResponse);
                setIsLoader(false);
            })
            .catch(error => {
                setIsLoader(false);
                console.log(error)
                // setCheckException(error);
            })
    }

    const formatAsLocalTime = (time) => {
        let timeParts = time.split(":")
        let localDate = moment('1900-01-01T'+timeParts[0]+":"+timeParts[1]+":00.000").format("HH:mm")
        return localDate
    }
    

    const addHandler = (e) => {
        e.preventDefault();
        let newStateObject = [...locationLookupList];
        newStateObject.push({ dow: 0, startTime: '', endTime: '' });
        setLocationLookupList(newStateObject);
    }

    const inputChange = (e, i) => {
        let newStateObject = [...locationLookupList];
        newStateObject[i][e.target.name] = e.target.value;
        return setLocationLookupList(newStateObject);
    }

    const saveRow = (src) => {
        if (isOverlapping(locationLookupList)) {
            toast.error('Invalid Time')
        } else {
            console.log('VALID')
            if (src.dow && src.startTime && src.endTime && locationId) {
                setIsLoader(true);
                const reqObj = { 
                    dow: src.dow, 
                    startTime: formatAsLocalTime(src.startTime), 
                    endTime: formatAsLocalTime(src.endTime), 
                    practiceLocationId: locationId 
                }
                const resObj = src.practiceLocationAvailabilityId ? PracticeLocationAvailabilityService.updateAvailability(reqObj, src.practiceLocationAvailabilityId) : PracticeLocationAvailabilityService.createAvailability(reqObj)
                resObj.then((response) => {
                    // console.log(response)
                    toast.success('Record Updated')
                    setIsLoader(false);
                    console.log(error)
                })
                    .catch(error => {
                        setIsLoader(false);
                        console.log(error)
                    })
            }
        }
    }

    const deleteAvailability = (id) => {

        PracticeLocationAvailabilityService.deleteAvailability(id)
            .then(res => {
                toast.success('Delete Availability successful');
                getLocationAvailablity()
            })
            .catch(err => console.log(err))
    }

    const overlapping = (a, b) => {
        const getMinutes = s => {
            const p = s.split(':').map(Number);
            return p[0] * 60 + p[1];
        };
        return getMinutes(a.endTime) > getMinutes(b.startTime) && getMinutes(b.endTime) > getMinutes(a.startTime);
    };

    const isOverlapping = (arr) => {
        let i, j;
        for (i = 0; i < arr.length - 1; i++) {
            for (j = i + 1; j < arr.length; j++) {
                if ((arr[i].practiceLocationId == arr[j].practiceLocationId) && (arr[j].practiceLocationAvailabilityId != arr[i].practiceLocationAvailabilityId) && (arr[i].dow == arr[j].dow) && overlapping(arr[i], arr[j])) {
                    return true;
                }
            };
        };
        return false;
    };



    useEffect(async () => {
        if (state?.practiceLocationId) {
            setLocationId(state.practiceLocationId)
        }
    }, [state?.practiceLocationId])

    useEffect(() => {
        if (locationId) {
            getLocationAvailablity();
        }
    }, [locationId])

    return (
        <div className='w-100'>
            {isLoader && <div>
                <div className="ui active dimmer">
                    <div className="ui indeterminate text loader"></div>
                </div>
            </div>}
            <div className='col-12'>
                <div className='field mb-3 input-group'>
                    <PracticeLocationSelector />
                    <button className='btn btn-primary' title="Add Location" onClick={addHandler}>Add</button>
                </div>
            </div>
            {!isLoader && locationLookupList ? <List
                className='col-12'
                sortFunc={onSort}
                // pageSize={3}
                noPaginate={true}
                isLoading={isLoader}
            >
                {locationLookupList.length && locationLookupList.map((src, i) => {
                    // console.log(src.practiceLocationAvailabilityId)
                    return (

                        <div class="card mb-3 p-3 availability-bar" key={src.id}>
                            <div class="row d-flex justify-content-between align-items-center g-3">
                                <div class="col-md-3 col-12">
                                    <div class="content">
                                        <div class="ui sub">
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                value={dowList.find(obj => obj.value === src.dow)}
                                                options={dowList}
                                                onChange={e => { inputChange({ target: { value: e.value, name: 'dow' } }, i) }}
                                                isClearable={true}
                                                getOptionLabel={(option) => { return (option.label) }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 col-12">
                                    <div className="content">
                                        <div className="ui">
                                            <input
                                                type='time'
                                                className="form-control"
                                                name="startTime"
                                                value={src.startTime}
                                                onInput={(e) => { e.preventDefault(); inputChange(e, i) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="item col-md-3 col-12">
                                    <div className="content">
                                        <div className="ui">
                                            <input
                                                type='time'
                                                className="form-control"
                                                name="endTime"
                                                value={src.endTime}
                                                onInput={(e) => { e.preventDefault(); inputChange(e, i) }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 col-12">
                                    <div className="content">
                                        <div className="btn-group m-w-100">
                                            <button className='btn btn-primary' title="Save Availability" onClick={e => { saveRow(src) }}>Save</button>
                                            <button className='btn btn-secondary' title="Delete Availability" onClick={e => {setDeleteModal(src.practiceLocationAvailabilityId); }}>Delete</button>
                                        </div>
                                    </div>
                                </div>
                                <ModalBox open={deleteModal} onClose={() => { setDeleteModal(0) }} title="Delete Block">
                                    {/* {locationLookupList.practiceLocationAvailabilityId && <div className='row d-flex align-items-center justify-content-between'> */}
                                    <div className='col'>
                                        Are you sure you want to delete ?
                                    </div>
                                    <div className='col-auto'>
                                        <button className='btn btn-secondary' onClick={e => { e.preventDefault(); deleteAvailability(deleteModal); setDeleteModal(0) }}>Yes</button>
                                        <button className='btn btn-secondary' onClick={e => { e.preventDefault(); return setDeleteModal(0) }}>No</button>
                                    </div>
                                    {/* </div>} */}
                                </ModalBox>
                            </div>
                        </div>)
                }
                )}
            </List> : null}
        </div>
    )
}

export default PracticeLocationAvailablityList