import React from 'react'
import EquipmentSettings from '../practice-settings/equipment-settings/EquipmentSettings'
import TabsTemplate from '../../../../templates/components/TabsTemplate'
import RoomSettingsTable from './room-settings-table/RoomSettingsTable'

import LocationSettingsTable from './location-settings-table/LocationSettingsTable'
import PracticeLocationAvailability from './practice-location-availablity/PracticeLocationAvailablity'
import FeesSettings from './fees-settings/FeesSettings'
import EquipmentTypeSettings from './equipment-type-settings/EquipmentTypeSettings'


const LocationSettings = (props) => {
    return (
        <div>
            <TabsTemplate id="locationSettings" accordionId="locationSettings">
                <div title="Manage Locations">
                    <LocationSettingsTable/>
                </div>
                <div title="Rooms">
                    <RoomSettingsTable/>
                    {/* <PracticeLocationRoomDashboard/> */}
                </div>
                <div title="Equipment">
                    <EquipmentSettings />
                </div>
                <div title="Equipment Types">
                    <EquipmentTypeSettings />
                </div>

                {/* <div title="Services">
                    <ServicesDashboard/>
                </div> */}
                {/* <div title="Practice Location Services">
                    <PracticeLocationServiceTableOld/>
                </div> */}
                {/* <div title="Practice Location Provider">
                    <PracticeLocationProviderOld/>
                </div> */}
            </TabsTemplate>
        </div>
    )
}

export default LocationSettings