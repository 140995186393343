import React, { useEffect, useRef, useContext, forwardRef,useImperativeHandle} from 'react'
import moment from 'moment'
import SMSInput from './SMSInput'
import label from  './../../../../../assets/i18n/en.json'
import { store } from "../../../../context/StateProvider";

const SMSMessages = forwardRef((props,ref) => {
    const globalStateAndDispatch = useContext(store)
    const state = globalStateAndDispatch.state
  
    const {messages, onSendMessage, patientName,patientId, isLoading,setIsLoading} = props;
    const messagesEndRef = useRef(null)
    const childRef = useRef()
    let currentDate;
    const onChange = () => {
        childRef.current.onChange()
    };
    useImperativeHandle(ref, () => ({
        onChange,
    }));

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollTo({
            top: messagesEndRef.current.scrollHeight,
        })
    }

    useEffect(() => {
        scrollToBottom()
    }, [messages]);

    const renderMessage = (notification) => {
        const {message, isIncoming, createdOn, createdBy} = notification;

        let showDate = false;
        if (!currentDate || moment(createdOn).format('L')!=moment(currentDate).format('L')){
            currentDate=createdOn;
            showDate = true;
        }

        const className = !isIncoming ?
        "Messages-message currentMember" : "Messages-message";
        let senderName = moment.utc(createdOn).local().format("LT"); 
        if (!isIncoming && createdBy) {
            senderName = createdBy +' '+ senderName  
        }

        return (

            <div>
                {showDate?
                    <li >
                    <div >
                        <div style={{
                            width:"100%",
                            textAlign:"center",
                            fontWeight: "bold"
                        }}>{moment.utc(createdOn).format("LL")}</div>
                    </div>
                    </li>
                :null}

                <li className={className}>
                    <div className="Message-content">
                        <div className="username">
                            {senderName}
                        </div>
                        <div className="text">{message}</div>
                    </div>
                </li>
            </div>
        );
    };

    return (
        <div className='two-way-messages'>
            {isLoading  && <div className="ui">
                    <div className="ui active dimmer">
                        <div className="ui indeterminate text loader">{label.common.processing}</div>
                    </div>
            </div>}
            <div>
                <div className='sms-section-headers' style={{marginLeft:"10px", textAlign:"middle", fontWeight:"bold"}}>
                    To: {patientName}
                </div>
                <div className="two-way-messages-list" ref={messagesEndRef}>
                    {messages && 
                    <ul style={{paddingLeft:"0px"}} >
                        <li>
                            <div >
                                <div style={{
                                    width:"100%",
                                    textAlign:"center",
                                    fontWeight: "bold"
                                }}>Beginning of the conversation</div>
                            </div>
                        </li>
                        {messages.map(m => renderMessage(m))}
                    </ul>}
                </div>
            </div>
            {state.permissions.patientModifyCommunications &&
            <SMSInput onSendMessage={onSendMessage} patientId={patientId} isLoading={isLoading} setIsLoading={setIsLoading} ref={childRef}/>
            }
        </div>
    );
});

export default SMSMessages;