import React, { useContext, useEffect, useState } from 'react';
import label from "../../../../../../../assets/i18n/en.json"
import UserService from '../../../../../../services/api/user.service';
import { store } from '../../../../../../context/StateProvider';

const PracticeLocationUser = (props) => {
    const [isLoader, setIsLoader] = useState(false)
    const [userList, setUserList] = useState()
    const [selectedUser, setSelectedUser] = useState()
    const state = useContext(store).state;
    const findUser = () => {
        setIsLoader(true);
        return UserService.findUser({ isActive: true })
            .then((resp) => {
                let allUsers = resp.data
                UserService.lookUpPracticeLocationUsers({
                    PracticeLocationId: props.id,
                    IsActive: true
                }).then(response => {
                    let selectedUsers = response.data
                    selectedUsers.forEach(selectedUser => {
                        allUsers.forEach(obj => {
                            if (obj.userName === selectedUser.userName) {
                                obj.isChecked = true
                                obj.practiceLocationUserId = selectedUser.id
                            }
                        })
                    })
                    setSelectedUser(selectedUsers)
                    setUserList(allUsers)
                    setIsLoader(false);
                }).catch(error => {
                    console.log(error)
                })
            })
            .catch(error => {
                setIsLoader(false);
                console.log(error)
            })
    }

    useEffect(() => {
        findUser();
    }, [props.id]);

    const removeUser = (userName, practiceLocationUserId) => {
        UserService.updateUsersForSelectedLocation({
            practiceLocationId: props.id,
            userName: userName,
            isDeleted: 1
        }, practiceLocationUserId).then((res) => {
            findUser();
        }).catch(error => {
            console.log(error)
        })
    }

    const addUser = (userName) => {
        UserService.createUsersForSelectedLocation({
            practiceLocationId: props.id,
            userName: userName,
        }).then((res) => {
            findUser();
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <div>
            {isLoader && <div className="ui">
                <div className="ui active dimmer">
                    <div className="ui indeterminate text loader">{label.common.processing}</div>
                </div>
            </div>}
            <div className='d-flex row'>
                <div className='col-md-8 col-12'>
                    <div className='card'>
                        <div className='card-header'>Active Users</div>
                        <div className='card-body row d-flex'>
                            {userList && userList.filter(obj => obj.isChecked).map(user => {
                                return (
                                    <div className='col-md-3 col-12'>
                                        <span title="Remove from Location" className='text-start point' onClick={e => { e.preventDefault(); removeUser(user.userName, user.practiceLocationUserId) }}><i className='icon minus small' /> {user.contact.name.firstName} {user.contact.name.lastName} </span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='col-md-4 col-12'>
                    <div className='card'>
                        <div className='card-header'>Inactive Users</div>
                        <div className='card-body row d-flex'>
                            {userList && userList.filter(obj => !obj.isChecked).map(user => {
                                return (
                                    <div className='col-md-6 col-12'>
                                        <span title="Add to Location" className='text-start point' onClick={e => { e.preventDefault(); console.log(user); addUser(user.userName) }}><i className='icon plus small' /> {user.contact.name.firstName} {user.contact.name.lastName}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PracticeLocationUser;