import React, { useState, useEffect } from "react";

import List from "../../../../../../../templates/components/List";
import TransactionService from "../../../../../../../../services/api/transaction.service";
import TransactionStatus from "../../../../../transactions/transaction-status/TransactionStatus";


const PaymentHistoryList = (props) => {
  const [paymentHistoryList, setPaymentHistoryList] = useState();


  useEffect(() => {
    if (props.autoPull) {
      pullPaymentHistoryList();
    } else if (props.pull) {
      pullPaymentHistoryList();
    }
  }, [props.autoPull, props.pull, props.keyword]);


  const pullPaymentHistoryList = () => {
    if (props.paymentPlan.invoiceNumber) {
      let reqObj = {
        InvoiceNo: props.paymentPlan.invoiceNumber,
        SortField: 'transactionDate',
        Asc: false
      }
      return TransactionService.findTransaction(reqObj)
        .then((res) => {
          console.log(res.data.data);
          if (Array.isArray(res.data.data)) {
            setPaymentHistoryList(res.data.data);
          }
          else {
            setPaymentHistoryList([res.data.data])
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };



  return (
    <div>
      <List noPaginate noResultsMessage="No Payment History Available">
        {paymentHistoryList && paymentHistoryList.map((payment, i) => {
          return (
          <TransactionStatus payment={payment}/>
          )
        })}
      </List >

    </div>
  );
};

export default PaymentHistoryList;


