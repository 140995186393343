import React, { useState, useEffect, useContext } from 'react'
import TabsTemplate from '../../../../../../templates/components/TabsTemplate';
import Select from 'react-select'
import PatientService from '../../../../../../../services/api/patient.service';
import moment from 'moment';
import CommonService from '../../../../../../../services/api/common.service';
import Utilities from '../../../../../../../services/commonservice/utilities';
import ModalBox from '../../../../../../templates/components/ModalBox';
import PatientAccountAdd from '../../../../patient-account/patient-account-add/PatientAccountAdd';
import InvoiceService from '../../../../../../../services/api/invoice.service';
import DimLoader from '../../../../../../templates/components/DimLoader';
import toast from 'react-hot-toast';
import CreditBalanceService from '../../../../../../../services/api/credit-balance.service';
import PaymentOptionsService from '../../../../../../../services/api/payment-options.service';
import PatientFinancialHeader from './PatientFinancialHeader';
import InvoicePreview from '../../../../invoices/invoice-preview/InvoicePreview';
import TransactionStatus from '../../../transaction-status/TransactionStatus';
import APIResponse from '../../../../../../templates/components/APIResponse';
import SwipeTerminalService from '../../../../../../../services/api/swipe-terminal.service';
import { store } from '../../../../../../../context/StateProvider'
import { Loader } from 'semantic-ui-react';

const VirtualTerminalForm = (props) => {

    const [inputData, setInputData] = useState(props.initialData)
    const [accounts, setAccounts] = useState()
    const [channelType, setChannelType] = useState(2)
    const [selectedAccount, setSelectedAccount] = useState()
    const [selectedPatient, setSelectedPatient] = useState()
    const [patientList, setPatientList] = useState()
    const [accountType, setAccountType] = useState('credit')
    const [showAdd, setShowAdd] = useState(false)
    const [isLoader_ProcessTransaction, setIsLoader_ProcessTransaction] = useState(false)
    const [isLoader_Accounts, setIsLoader_Accounts] = useState(false)
    const [transactionReceipt, setTransactionReceipt] = useState()
    const [creditBalance, setCreditBalance] = useState(0)
    const [creditInfo, setCreditInfo] = useState()
    const [isLoader_PartialPayment, setIsLoader_PartialPayment] = useState(false)
    const [paymentOptions, setPaymentOptions] = useState()
    const [swipeTerminals, setSwipeTerminals] = useState()
    const [showReceipt, setShowReceipt] = useState(false)
    const [captureToken, setCaptureToken] = useState(false)
    const [showConfirmTerminal, setShowConfirmTerminal] = useState(false)
    const [isLoader_TerminalStatus, setIsLoader_TerminalStatus] = useState(false)
    // plan one time transaction
    let minStartDate = new Date(new Date().setHours(0, 0, 0, 0));

    const globalStateAndDispatch = useContext(store)
    const state = globalStateAndDispatch.state

    const [apiResponse, setApiResponse] = useState()
    const [apiCheckTerminalResponse, setApiCheckTerminalResponse] = useState()
    
    const [collectPayment, setCollectPayment] = useState(false)
    const onSuccess = (message) => {
        getCreditBalance()
        if (apiResponse.data.paymentStatus != 5 && apiResponse.data.paymentStatus != 14 && apiResponse.data.paymentStatus != 20) {
            if (apiResponse.data.paymentStatus == 22) {
                toast.success("Swipe Transaction Initiated")
            } else {
                toast.success("Transaction Successful")
            }
            let newObj = { ...inputData }
            let totalAmountPaid = apiResponse.data.totalAmountPaid && apiResponse.data.totalAmountPaid != null ? apiResponse.data.totalAmountPaid : 0
            newObj.remainingBalance = 0
            newObj.paymentAmount = apiResponse.data.openBalance ? (apiResponse.data.openBalance - apiResponse.data.inProcessAmount).toFixed(2) : (apiResponse.data.totalAmount - (totalAmountPaid + apiResponse.data.inProcessAmount)).toFixed(2)
            newObj.amountPaid = totalAmountPaid
            newObj.inProcessAmount = apiResponse.data.inProcessAmount ? apiResponse.data.inProcessAmount : 0
            newObj.finalAmount = apiResponse.data.totalAmount
            setInputData(newObj)
            if (props.onClose && apiResponse.data && apiResponse.data.openBalance && apiResponse.data.openBalance == 0) {
                props.onClose()
            }
            if (props.onClose && apiResponse.data && apiResponse.data.totalAmount && apiResponse.data.totalAmountPaid && apiResponse.data.totalAmount - apiResponse.data.totalAmountPaid == 0) {
                props.onClose()
            }
            if (props.onClose && apiResponse.data && apiResponse.data.paymentStatus == 22) {
                let totalAmountPaid = apiResponse.data.totalAmountPaid && apiResponse.data.totalAmountPaid != null ? apiResponse.data.totalAmountPaid : 0
                if (apiResponse.data.totalAmount && apiResponse.data.inProcessAmount && apiResponse.data.totalAmount - totalAmountPaid - apiResponse.data.inProcessAmount == 0) {
                    props.onClose()
                }
            }
        }
        else {
            setTransactionReceipt(apiResponse.data)
            if (accountType == 'credit') {
                resetCvv()
            }
        }
        if (props.refresh) {
            props.refresh()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }
    let loggedInUserData = CommonService.getLoggedInData();

    const resetCvv = () => {
        let newData = { ...inputData }
        newData.cvv = ''
        setInputData(newData)
    }
    const accountTypes = {
        1: 'Credit',
        2: 'ACH',
        3: 'credit',
        4: 'debit',
    }
    
    const checkTerminalStatus = () => {
        return true;
        // setIsLoader_TerminalStatus(true)
        // SwipeTerminalService.checkTerminalStatus(inputData.terminalId)
        //   .then((res) => {
        //     setApiCheckTerminalResponse(res)
        //     setIsLoader_TerminalStatus(false)
        //   })
        //   .catch(err => {
        //     setApiCheckTerminalResponse(err)
        //     setIsLoader_TerminalStatus(false)
        //   })
      };

    const getPaymentOptions = () => {
        PaymentOptionsService.getPaymentOptions()
            .then(res => {
                setPaymentOptions(res?.data?.data)
            })
    }
    const swipeTerminalLookup = () => {
        let reqObj = {
            PracticeLocationId: inputData?.practiceLocationId
        }
        SwipeTerminalService.getAllSwipeTerminals(reqObj)
            .then(res => {
                setSwipeTerminals(res?.data)
            })
    }
    useEffect(() => {
        if(inputData.terminalId){
            checkTerminalStatus()
        }
    }, [inputData.terminalId])
    useEffect(() => {
        patientLookup()
        getPaymentOptions()
        swipeTerminalLookup()
    }, [])

    useEffect(() => {
        if (transactionReceipt) {
            return inputChange({ target: { value: transactionReceipt.totalAmountPaid, name: 'amountPaid' } })
        }
    }, [transactionReceipt])

    const addInvoicePayment = async (isCreditBalance, isTerminalConfirmed) => {

        let reqObj = {};
        if (+inputData.remainingBalance > 0) {
            setIsLoader_PartialPayment(true)
        }
        else {
            setIsLoader_ProcessTransaction(true)
        }


    //Assuming not using AMP terminal in future, to allow PAX terminal trasaction
        // if (accountType == 'swipe' && !isTerminalConfirmed) {
        //     let selectedTerminal = swipeTerminals?.find(obj => obj.id === inputData.terminalId)
        //     let terminalReady = await InvoiceService.checkTerminal(selectedTerminal.serialNumber, selectedTerminal.authCode);
        //     if (!terminalReady) {
        //         setIsLoader_PartialPayment(false)
        //         setIsLoader_ProcessTransaction(false)
        //         setShowConfirmTerminal(true);
        //         return;
        //     }
        // }

        if (isCreditBalance === true) {
            reqObj = {
                "email": inputData.transactionEmail,
                "remarks": inputData?.memo,
                "providerId": inputData?.providerId,
                "channelType": 5,
                "creditBalanceId": creditInfo.creditBalanceId
            };
            let amountPaid = parseFloat(returnPrepaidAmount())
            if (amountPaid <= inputData?.paymentAmount) {
                reqObj.amountPaid = amountPaid
            }
        }
        else {
            if (accountType === 'credit') {
                reqObj = {
                    'paymentAccountId': inputData?.paymentAccountId,
                    'channelType': channelType,
                    'cvv': inputData.cvv,
                    'email': inputData.email,
                    'remarks': inputData?.memo,
                };

            } else if (accountType === 'ach') {
                reqObj = {
                    'paymentAccountId': inputData?.paymentAccountId,
                    'channelType': channelType,
                    'email': inputData.transactionEmail,
                    'remarks': inputData?.memo,
                };

            } else if (accountType === 'cash') {
                if (inputData.transactionDate !== undefined &&
                    inputData.transactionDate !== null &&
                    inputData.transactionDate !== '') {
                    startDate = moment(inputData.transactionDate)
                        .add(moment().hour(), 'hour')
                        .add(moment().minutes(), 'minute')
                        .add(moment().seconds(), 'second')
                        .toISOString();
                }

                reqObj = {
                    'transactionDate': moment(inputData.transactionDate).toISOString(),
                    'channelType': channelType,
                    'remarks': inputData?.memo,
                };

            } else if (accountType === 'check') {
                let startDate = null;
                if (
                    inputData.transactionDate !== undefined &&
                    inputData.transactionDate !== null &&
                    inputData.transactionDate !== ''
                ) {
                    startDate = moment(inputData.transactionDate)
                        .add(moment().hour(), 'hour')
                        .add(moment().minutes(), 'minute')
                        .add(moment().seconds(), 'second')
                        .toISOString();
                }

                reqObj = {
                    'transactionDate': moment(inputData.transactionDate).toISOString(),
                    'channelType': channelType,
                    'bankName': inputData.institutionName,
                    'checkType': null,
                    'checkNumber': inputData.checkNumber,
                    'remarks': inputData?.memo,
                };
            }
            else if (accountType == 'other') {
                reqObj = {
                    "transactionDate": moment(inputData.transactionDate).toISOString(),
                    "channelType": 8,
                    "paymentOptionId": inputData.paymentOptionId,
                    "account": inputData?.account,
                    "transactionNo": inputData?.transactionNo,
                    "noOfPoints": inputData?.noOfPoints,
                    "misc": inputData?.misc,
                }
            }
            else if (accountType == 'swipe') {
                reqObj = {
                    "terminalId": inputData.terminalId,
                    "channelType": 7,
                    "email": inputData.email,
                    'remarks': inputData?.memo,
                }
                if (swipeTerminals?.find(obj => obj.id === inputData.terminalId)?.canCaptureToken) {
                    reqObj.captureToken = captureToken
                }
            }
            if (loggedInUserData.userType === 1) {
                reqObj.providerId = loggedInUserData.parentId;
            } else if (loggedInUserData.userType === 0) {
                reqObj.patientId = loggedInUserData.parentId;
            }
            if (inputData.finalAmount > inputData.paymentAmount) {
                reqObj.amountPaid = inputData.paymentAmount ? parseFloat(inputData.paymentAmount) : 0
            }
            //if split payment for prepaid balance
            if (inputData?.invoiceType === 5) {
                reqObj.isCreditBalance = true
            }
        }
        reqObj.practiceLocationId = inputData?.practiceLocationId
        InvoiceService.payment(props.initialData.id, reqObj)
            .then(response => {
                setApiResponse(response);
            })
            .catch(error => {
                setApiResponse(error);
            })
            .finally(() => {
                setIsLoader_ProcessTransaction(false)
                setIsLoader_PartialPayment(false)
            });
    }

    useEffect(() => {
        setTimeout(() => {
            if (isLoader_ProcessTransaction) {
                setIsLoader_ProcessTransaction(false)
            }
        }, 90000)
    }, [isLoader_ProcessTransaction])

    const updateChannelType = (account) => {
        switch (account) {
            case 'credit':
                inputChange({
                    target: {
                        name: 'channelType', value: 3
                    }
                })
                setChannelType(3)
                break
            case 'ach':
                inputChange({
                    target: {
                        name: 'channelType', value: 2
                    }
                })
                setChannelType(2)
                break
            case 'check':
                inputChange({
                    target: {
                        name: 'channelType', value: 10
                    }
                })
                setChannelType(10)
                break
            case 'cash':
                inputChange({
                    target: {
                        name: 'channelType', value: 9
                    }
                })
                setChannelType(9)
                break
            case 'other':
                inputChange({
                    target: {
                        name: 'channelType', value: 8
                    }
                })
                setChannelType(8)
                break
            case 'swipe':
                inputChange({
                    target: {
                        name: 'channelType', value: 7
                    }
                })
                setChannelType(7)
                break
            default:
                break;
        }
    }

    const findAccounts = () => {
        setIsLoader_Accounts(true)
        let reqObj = { PracticeLocationIds: inputData?.practiceLocationId, IsActive: 1 }
        PatientService.fetchPatientAccount(inputData.patientId, reqObj)
            .then(res => {
                setAccounts(res)
                setIsLoader_Accounts(false)
            })
    }
    // formula for input change
    const inputChange = (e) => {
        if (e.target.name === "cardExpiry") {
            let newStateObject = { ...inputData };
            newStateObject[e.target.name] = e.target.value.replace("/", "")
            setInputData(newStateObject);
        }
        else {
            let newStateObject = { ...inputData };
            newStateObject[e.target.name] = e.target.value
            setInputData(newStateObject);
        }
    };
    const patientLookup = () => {
        if (props.patientId || props.initialData.patientId) {
            let patientId = props.patientId || props.initialData.patientId
            PatientService.getPatientById(patientId)
                .then(res => {
                    setPatientList([res.data])
                    setSelectedPatient(res.data)
                })
        }
        else {
            let reqObj = { SearchTerm: '', isActive: true, isRegistered: true, SortField: 'firstName' }
            CommonService.patientLookup(reqObj)
                .then(res => {
                    if (res) {
                        setPatientList(res)
                        if (inputData.patientId) {
                            setSelectedPatient(res.data.find(obj => obj.id === inputData.patientId))
                        }
                    }
                    setIsLoader_Patients(false)
                }
                )
                .catch(err => {
                    console.log(err)
                    setIsLoader_Patients(false)
                })

        }
    }

    useEffect(() => {
        if (inputData.patientId) {
            findAccounts()
        }
        if (selectedPatient) {
            inputChange({ target: { name: 'email', value: selectedPatient.email } })
            getCreditBalance()
        }
    }, [selectedPatient])

    useEffect(() => {
        if (inputData?.patientId) {
            findAccounts()
        }
    }, [inputData?.practiceLocationId])

    useEffect(() => {
        let account = { ...selectedAccount }
        delete account.createdOn
        if (selectedAccount) {
            setAccountType(accountTypes[account.accountType].toLowerCase())
            updateChannelType(accountTypes[account.accountType].toLowerCase())
            return setInputData({ ...inputData, ...account, ...selectedPatient })
        }
        else {
            let newObj = inputData
            newObj.accountHolderName = ''
            newObj.maskedCardNumber = ''
            newObj.maskedAccountNumber = ''
            newObj.cardExpiry = ''
            setInputData({ ...newObj })
        }
    }, [selectedAccount])

    useEffect(() => {
        //console.log(props.initialData)
        let inProcessAmount = inputData.inProcessAmount && inputData.inProcessAmount > 0 ? inputData.inProcessAmount : 0
        if (inputData.amountPaid) {
            // let newObj = { ...inputData }
            // newObj.openBalance = parseFloat(props.initialData.finalAmount - props.initialData.amountPaid)
            // newObj.paymentAmount = parseFloat(props.initialData.openBalance).toFixed(2)
            // setInputData(newObj)
            inputData.openBalance = parseFloat(props.initialData.finalAmount - props.initialData.amountPaid)
            if (!inputData.hasOwnProperty('paymentAmount')) {
                inputData.paymentAmount = parseFloat(props.initialData.finalAmount - props.initialData.amountPaid - inProcessAmount).toFixed(2)
            }
        }
        else {
            // let newObj = { ...inputData }
            // newObj.openBalance = parseFloat(props.initialData.finalAmount)
            // newObj.paymentAmount = parseFloat(props.initialData.finalAmount).toFixed(2)
            // setInputData(newObj)
            inputData.openBalance = parseFloat(props.initialData.finalAmount)
            if (!inputData.hasOwnProperty('paymentAmount')) {
                inputData.paymentAmount = parseFloat(props.initialData.finalAmount - inProcessAmount).toFixed(2)
            }
        }
    }, [props.initialData])

    useEffect(() => {
        if (inputData.paymentAmount) {
            // console.log("if")
            // console.log(inputData.finalAmount)
            // console.log(+inputData.paymentAmount)
            // console.log(inputData.amountPaid)
            // console.log(inputData.inProcessAmount)
            inputChange({ target: { value: (((inputData.finalAmount - inputData?.paymentAmount).toFixed(2) - inputData?.amountPaid).toFixed(2) - inputData?.inProcessAmount).toFixed(2), name: 'remainingBalance' } })
        } else {
            // console.log("else")
            // console.log(inputData.finalAmount)
            // console.log(inputData.amountPaid)
            // console.log(inputData.inProcessAmount)
            inputChange({ target: { value: ((inputData.finalAmount - inputData?.amountPaid).toFixed(2) - inputData?.inProcessAmount).toFixed(2), name: 'remainingBalance' } })
        }
    }, [inputData.paymentAmount])

    const getCreditBalance = () => {
        CreditBalanceService.lookupCreditBalance({ PatientIds: props.patientId || selectedPatient.id })
            .then(res => {
                //console.log(res.data)
                if (res.data?.length >= 1) {
                    setCreditBalance(res.data[0].currentBalance)
                    setCreditInfo(res.data[0])
                }
            })
    }

    const returnPrepaidAmount = () => {
        let prepaidBalance = 0
        let remainingBalance;
        remainingBalance = inputData?.paymentAmount
        if (creditBalance >= remainingBalance) {
            prepaidBalance = remainingBalance
        }
        else if (creditBalance < remainingBalance) {
            prepaidBalance = creditBalance
        }
        prepaidBalance = prepaidBalance ? parseFloat(prepaidBalance).toFixed(2) : 0
        return prepaidBalance
    }

    const formatPayAmount = () => {
        if (inputData?.paymentAmount) {
            let newAmount = parseFloat(inputData.paymentAmount)
            let owed = (inputData?.finalAmount - inputData?.amountPaid).toFixed(2) - inputData?.inProcessAmount
            if (newAmount > owed) {
                inputChange({ target: { value: owed.toFixed(2), name: 'paymentAmount' } })
            }
            else {
                inputChange({ target: { value: newAmount.toFixed(2), name: 'paymentAmount' } })
            }
        }

    }

    return (
        <div className='m-0 p-0'>
            <div className=''>
                {isLoader_ProcessTransaction && <DimLoader />}
                {isLoader_PartialPayment && <DimLoader loadMessage="Processing Transaction. Please wait to process additional payment type" />}
                <form id="form">
                    <div className='row d-flex align-items-start'>
                        <div className='col-12 m-0 p-3 row'>
                            <PatientFinancialHeader disableLocationEdit disablePatientEdit patientId={selectedPatient?.id} patient={patientList && patientList?.find(obj => obj?.id == inputData?.patientId)} locationId={inputData?.practiceLocationId} onLocationChange={location => { inputChange({ target: { name: 'practiceLocationId', value: location.practiceLocationId } }) }} creditBalance={creditBalance} accounts={accounts} />
                            <div className="col-12">
                                <div className="card p-3 mb-3 ">
                                    <div className="d-flex row align-items-center">
                                        <div className="col-auto">
                                            <i className="icon dollar big text-primary" />
                                        </div>
                                        <div className="col d-flex row justify-content-between align-items-center">
                                            <div className="col-md-auto col-12">
                                                <h5>  {inputData.invoiceNumber || inputData.refNo}</h5>
                                            </div>
                                            {inputData?.amountPaid > 0 ? <div className="col-auto row align-items-center">

                                                <div className="col-md-auto col-12">Invoice Total<span className='badge bg-primary mb-1 ms-3'>{Utilities.toDollar(inputData?.finalAmount || 0)}</span></div>

                                                <div className="col-md-auto col-12">Paid Amount <span className="badge bg-primary ms-3 mb-1">{Utilities.toDollar(inputData?.amountPaid)}</span></div>
                                                {inputData?.inProcessAmount && inputData?.inProcessAmount > 0 ? <div className="col-md-auto col-12">Processing <span className="badge bg-primary ms-3 mb-1">{Utilities.toDollar(inputData?.inProcessAmount)}</span></div> : null}
                                                <div className="col-md-auto col-12"><strong>Remaining Balance</strong> <span className="btn btn-primary ms-3">{Utilities.toDollar((inputData?.finalAmount - inputData?.amountPaid).toFixed(2) - inputData?.inProcessAmount)}</span></div>
                                            </div> :
                                                <div className='col-md-auto col-12 row align-items-center'>
                                                    {inputData?.inProcessAmount && inputData?.inProcessAmount > 0 ? <div className="col-md-auto col-12">Processing <span className="badge bg-primary ms-3 mb-1">{Utilities.toDollar(inputData?.inProcessAmount)}</span></div> : null}
                                                    <div className="col-md-auto col-12 d-flex justify-content-between align-items-center"><strong>Invoice Total</strong><span className='btn btn-primary ms-3'>{Utilities.toDollar(inputData?.finalAmount || 0)}</span></div>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex mt-3">
                                    <div className='col-12'>
                                        <div className="col-12 d-flex mb-3">
                                            <div className='card p-3 w-100'>
                                                <div className='d-flex row'>
                                                    <div className='field required col-6'>
                                                        <label>Payment Amount</label>
                                                        <div className='input-group align-items-center'>
                                                            <input name="paymentAmount" type="number" value={inputData.paymentAmount} onChange={e => { e.preventDefault(); inputChange(e) }} onBlur={e => { e.preventDefault(); formatPayAmount() }} />
                                                        </div>
                                                    </div>
                                                    <div className='field col-6'>
                                                        <label>Remaining Balance</label>
                                                        <div className='input-group align-items-center'>
                                                            <input name="remainingBalance" type="text" value={Utilities.toDollar(inputData.remainingBalance)} onChange={e => { e.preventDefault(); inputChange(e) }} disabled />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 mb-3'>
                                        <div className='card p-3 d-flex'>
                                            <div className='col-12 d-flex justify-content-between align-items-end mb-2'>
                                                <h5 className="col-auto">Payment Method</h5>
                                            </div>
                                            {creditBalance > 0 && inputData?.invoiceType != 5 ? <div className='col-12 align-items-end justify-content-start d-flex'>
                                                <div className='col-auto'>
                                                    <button className="btn btn-primary me-2 mb-2 badge" onClick={e => { e.preventDefault(); setCollectPayment(true); addInvoicePayment(true) }}>Apply Balance</button>
                                                    Apply prepaid balance of {Utilities.toDollar(returnPrepaidAmount())}
                                                </div>
                                            </div> : null}
                                            <TabsTemplate noMobileOpt activeKey={accountType} onSelect={k => { setAccountType(k); return updateChannelType(k) }}>
                                                <div eventKey="credit" disabled={selectedAccount && selectedAccount.accountType !== 1} title={<span><i className='icon credit card' />Card</span>}>
                                                    {accounts?.length > 0 ?
                                                        <div className='d-flex row'>
                                                            <div className='col field mb-3'>
                                                                <label>Existing Account</label>
                                                                <div className="input-group w-100">
                                                                    <Select
                                                                        classNamePrefix="react-select"
                                                                        className="react-select-container"
                                                                        options={accounts.filter(obj => obj.accountType == 1)}
                                                                        name="paymentAccountId"
                                                                        isLoading={isLoader_Accounts}
                                                                        isClearable
                                                                        value={accounts ? accounts.find(obj => obj.id === inputData.account?.id) : null}
                                                                        onChange={e => {
                                                                            if (e?.id) {
                                                                                inputChange({
                                                                                    target: {
                                                                                        name: 'paymentAccountId', value: e.id
                                                                                    }
                                                                                })
                                                                                setSelectedAccount(e)
                                                                            }
                                                                            else {
                                                                                setSelectedAccount()
                                                                            }
                                                                        }}
                                                                        placeholder="Select Account"
                                                                        isDisabled={props.isDisabled}
                                                                        getOptionLabel={(option) => {
                                                                            let label = ""
                                                                            if (option.accountType === 1) {
                                                                                label = accountTypes[option.accountType] + ' | ' + option.cardType + ' ' + option.maskedCardNumber + ' | ' + option.accountHolderName
                                                                            }
                                                                            else if (option.accountType === 2) {
                                                                                label = accountTypes[option.accountType] + ' | ' + option.bankName + ' ' + option.maskedAccountNo + ' | ' + option.accountHolderName
                                                                            }
                                                                            return label
                                                                        }
                                                                        }
                                                                        getOptionValue={(option) => option.id}
                                                                        noOptionsMessage={() => { return <button className='btn btn-primary form-control' onClick={e => { e.preventDefault(); setShowAdd(true) }}>Add Account</button> }}
                                                                    />
                                                                    {!props.isDisabled && <button className='btn btn-primary col-auto' title="Add Account" onClick={e => { e.preventDefault(); setShowAdd(true) }}><i className='icon plus' /></button>}
                                                                </div>
                                                            </div>
                                                            <div className='field required col-md-2 col-12'>
                                                                <label>CVV</label>
                                                                <input name="cvv" type="text" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData.cvv} />
                                                                {inputData?.cvv ? <span className='error-message'>{selectedAccount?.cardType == 'AMEX' && inputData?.cvv?.length < 4 ? 'Must be 4 digits' : inputData?.cvv?.length < 3 ? 'Must be 3 digits' : inputData?.cvv?.length > 4 && selectedAccount?.cardType == 'AMEX' ? 'Too many digits' : selectedAccount?.cardType != 'AMEX' && inputData?.cvv?.length > 3 ? 'Too many digits' : null}</span> : null}
                                                            </div>
                                                        </div>
                                                        : null}

                                                    {state.permissions.patientModifyWallet ?
                                                        <div className="row d-flex mt-3">
                                                            <div className="col-12">
                                                                <button onClick={e => { e.preventDefault(); setShowAdd(true) }} className="btn btn-primary mb-3">
                                                                    <i className="icon plus" /> Add Payment Account
                                                                </button>
                                                            </div>
                                                        </div>
                                                        : null}

                                                </div>
                                                <div eventKey="ach" disabled={selectedAccount && selectedAccount.accountType !== 2} title={<span><i className='icon credit card' />ACH</span>}>
                                                    {accounts?.length > 0 ?
                                                        <div className='d-flex row'>
                                                            <div className='col field mb-3'>
                                                                <label>Existing Account</label>
                                                                <div className="input-group w-100">
                                                                    <Select
                                                                        classNamePrefix="react-select"
                                                                        className="react-select-container"
                                                                        options={accounts.filter(obj => obj.accountType == 2)}
                                                                        name="paymentAccountId"
                                                                        isLoading={isLoader_Accounts}
                                                                        isClearable
                                                                        value={accounts ? accounts.find(obj => obj.id === inputData.account?.id) : null}
                                                                        onChange={e => {
                                                                            if (e?.id) {
                                                                                inputChange({
                                                                                    target: {
                                                                                        name: 'paymentAccountId', value: e.id
                                                                                    }
                                                                                })
                                                                                setSelectedAccount(e)
                                                                            }
                                                                            else {
                                                                                setSelectedAccount()
                                                                            }
                                                                        }}
                                                                        placeholder="Select Account"
                                                                        isDisabled={props.isDisabled}
                                                                        getOptionLabel={(option) => {
                                                                            let label = ""
                                                                            if (option.accountType === 1) {
                                                                                label = accountTypes[option.accountType] + ' | ' + option.cardType + ' ' + option.maskedCardNumber + ' | ' + option.accountHolderName
                                                                            }
                                                                            else if (option.accountType === 2) {
                                                                                label = accountTypes[option.accountType] + ' | ' + option.bankName + ' ' + option.maskedAccountNo + ' | ' + option.accountHolderName
                                                                            }
                                                                            return label
                                                                        }
                                                                        }
                                                                        getOptionValue={(option) => option.id}
                                                                        noOptionsMessage={() => { return <button className='btn btn-primary form-control' onClick={e => { e.preventDefault(); setShowAdd(true) }}>Add Account</button> }}
                                                                    />
                                                                    {!props.isDisabled && <button className='btn btn-primary col-auto' title="Add Account" onClick={e => { e.preventDefault(); setShowAdd(true) }}><i className='icon plus' /></button>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}

                                                    {state.permissions.patientModifyWallet ?
                                                        <div className="row d-flex mt-3">
                                                            <div className="col-12">
                                                                <button onClick={e => { e.preventDefault(); setShowAdd(true) }} className="btn btn-primary mb-3">
                                                                    <i className="icon plus" /> Add Payment Account
                                                                </button>
                                                            </div>
                                                        </div>
                                                        : null}

                                                </div>
                                                <div eventKey="check" disabled={selectedAccount && selectedAccount.accountType !== 3} title={<span><i className='icon money bill alternate outline' />Check</span>}>
                                                    <div className='row align-items-end'>
                                                        <div className="field col-md-8 col-12">
                                                            <label>Name of Institution</label>
                                                            <input name="nameOfInstitution" type="text" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData.nameOfInstitution} />
                                                        </div>
                                                        <div className="col-md-4 col-12">
                                                            <label>Check Number</label>
                                                            <input name="checkNumber" type="text" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData.checkNumber} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div eventKey="cash" disabled={selectedAccount && selectedAccount.accountType !== 4} title={<span><i className='icon money bill alternate outline' />Cash</span>}>
                                                    No Information Needed
                                                </div>
                                                <div eventKey="other" disabled={selectedAccount && selectedAccount.accountType !== 5} title={<span><i className='icon money bill alternate outline' />Other</span>}>
                                                    {paymentOptions?.length ? <div className="d-flex row">
                                                        <div className="col-12 field required">
                                                            <label>Payment Type</label>
                                                            <Select
                                                                classNamePrefix="react-select"
                                                                className="react-select-container"
                                                                options={paymentOptions}
                                                                name="paymentOptionId"
                                                                isClearable
                                                                value={paymentOptions?.find(obj => obj.paymentOptionId === inputData.paymentOptionId)}
                                                                onChange={e => {
                                                                    inputChange({
                                                                        target: {
                                                                            name: 'paymentOptionId', value: e?.paymentOptionId
                                                                        }
                                                                    })
                                                                }}
                                                                placeholder="Select Payment Option"
                                                                getOptionLabel={(option) => option.paymentOption}
                                                                getOptionValue={(option) => option.paymentOptionId}
                                                            />
                                                        </div>
                                                        <div className='col-md-4 col-12 field'>
                                                            <label>Account</label>
                                                            <input type="text" value={inputData.account} onChange={e => { e.preventDefault(); inputChange(e) }} name="account" />
                                                        </div>
                                                        <div className='col-md-4 col-12 field'>
                                                            <label>Transaction No</label>
                                                            <input type="text" value={inputData.transactionNo} onChange={e => { e.preventDefault(); inputChange(e) }} name="transactionNo" />
                                                        </div>
                                                        <div className='col-md-4 col-12 field'>
                                                            <label># of Points</label>
                                                            <input type="text" value={inputData.noOfPoints} onChange={e => { e.preventDefault(); inputChange(e) }} name="noOfPoints" />
                                                        </div>
                                                        <div className='col-12 field'>
                                                            <label>Misc</label>
                                                            <input type="text" value={inputData.misc} onChange={e => { e.preventDefault(); inputChange(e) }} name="misc" />
                                                        </div>
                                                    </div> : 'There are no other payment types'}
                                                </div>
                                                <div eventKey="swipe" hide={!swipeTerminals?.length > 0} disabled={selectedAccount && selectedAccount.accountType !== 5} title={<span><i className='icon calculator' />Swipe</span>}>
                                                    {swipeTerminals?.length ? <><div className="d-flex row">
                                                        <div className="col-12 field required">
                                                            <label>Terminal</label>
                                                            <div className="input-group w-100">
                                                            <Select
                                                                classNamePrefix="react-select"
                                                                className="react-select-container"
                                                                options={swipeTerminals}
                                                                name="terminalId"
                                                                isClearable
                                                                value={swipeTerminals?.find(obj => obj.id === inputData.terminalId)}
                                                                onChange={e => {
                                                                    inputChange({
                                                                        target: {
                                                                            name: 'terminalId', value: e?.id
                                                                        }
                                                                    })
                                                                }}
                                                                placeholder="Select Swipe Terminal"
                                                                getOptionLabel={(option) => `${option.deviceName}-${option.serialNumber}`}
                                                                getOptionValue={(option) => option.id}
                                                            />

                                                                {/* isLoader_TerminalStatus ? <button className='btn btn-primary col-auto'>
                                                                    <Loader size='mini' active inline='centered' />
                                                                    </button>
                                                                 :<button className='btn btn-primary col-auto' disabled={inputData.terminalId ? false : true} title="Verify terminal status" onClick={e => { e.preventDefault(); checkTerminalStatus() }}>
                                                                     <i className='icon refresh' />
                                                                </button> */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                        {swipeTerminals?.find(obj => obj.id === inputData.terminalId)?.canCaptureToken === 1 &&
                                                            <div className="d-flex row">
                                                                <div className="col-12 field">
                                                                    <div className='form-check'>
                                                                        <input type="checkbox" className='form-check-input' checked={captureToken} name="captureToken" onChange={e => { setCaptureToken(e.target.checked) }} />
                                                                        <label className='form-check-label'>Capture CC</label>
                                                                    </div>
                                                                </div>
                                                            </div>}

                                                    </> : 'No Record Found'}
                                                </div>
                                            </TabsTemplate>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="card p-3">
                                    <div className="field col-12">
                                        <label>Memo</label>
                                        <textarea name="memo" type="text" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData.memo} />
                                    </div>
                                </div>
                            </div>
                            {transactionReceipt ? <div className='col-12 mt-3'><TransactionStatus paymentId={transactionReceipt.paymentId} /></div> : null}
                            <div className='col-12 d-flex justify-content-between mt-3 modal-button-bar'>
                                <div className='col-auto'>
                                    <button className="btn btn-primary" onClick={e => { e.preventDefault(); setShowReceipt(true); }}>View Invoice</button>
                                    <button className="ms-3 btn btn-primary" disabled={(accountType == "swipe" && isLoader_TerminalStatus) ? true : false} onClick={e => { e.preventDefault(); setTransactionReceipt(); setApiResponse(); setCollectPayment(true); addInvoicePayment(); }}>Collect Payment</button>
                                </div>
                                <div className='col-auto'>
                                    <button className="btn btn-secondary" onClick={e => { e.preventDefault(); props.onClose() }}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div >
                </form >
            </div >
            <ModalBox open={showAdd} onClose={() => { setShowAdd(false) }} title="Add Patient Account">
                <PatientAccountAdd patientId={selectedPatient?.id || inputData.patientId} disableLocationEdit={true} practiceLocationId={inputData?.practiceLocationId} onClose={() => { setShowAdd(false); findAccounts() }} />
            </ModalBox>
            <ModalBox open={showReceipt} onClose={() => { setShowReceipt(false) }} title={`Invoice${props.initialData?.invoiceNumber ? ` ${props.initialData.invoiceNumber}` : ''}`}>
                <InvoicePreview invoiceId={props.initialData.id} />
            </ModalBox>
            {collectPayment && <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={false} />}
            <APIResponse apiResponse={apiCheckTerminalResponse} toastOnSuccess={true} successMessage={"Terminal is Available."} />
            <ModalBox open={showConfirmTerminal} onClose={() => { setShowConfirmTerminal(false) }} title="Confirm Terminal">
                <div className='row d-flex'>
                    <div className='col-12'>
                        Unable to detect terminal.  Do you wish to continue with this terminal and submit the transaction anyway?
                    </div>
                    <div className='col-12'>
                        <div className='row d-flex mt-3 modal-button-bar justify-content-between'>
                            <div className='col-auto'>
                                <button className='btn btn-primary' onClick={e => { e.preventDefault(); setShowConfirmTerminal(false); addInvoicePayment(false, true) }}>Continue</button>
                            </div>
                            <div className='col-auto'>
                                <button className='btn btn-secondary' onClick={e => { e.preventDefault(); setShowConfirmTerminal(false) }}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>

        </div >
    )
}

export default VirtualTerminalForm