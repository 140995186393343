import React, { useState, useEffect } from 'react';
import PlFormsService from '../../../../../services/api/plforms.service';
import Table2 from '../../../../templates/components/Table2';
import Module from '../../../../templates/components/Module';
import moment from 'moment';
import ModalBox from '../../../../templates/components/ModalBox';

import { Form } from '@formio/react';
import BasicFormConfig from './basic-form-config';

const ViewPublicFormSubmissions = (props) => {
  const [isLoader, setIsLoader] = useState(false)
  const [submissionList, setSubmissionList] = useState()
  const [selectedForm, setSelectedForm] = useState()

  const [showForm, setShowForm] = useState(false)
  const [inputData, setInputData] = useState([BasicFormConfig.basicInfo, ...props?.form?.fields?.components])

  useEffect(() => {
    getSubmissions()
  }, [])

  const getSubmissions = () => {
    setIsLoader(true);
    let params = {
      FormId: props?.form?.id,
      IsPublicFormSubmission: true,
      SortField:'createdDate',
      Asc:false
    };
    PlFormsService.getSubmission(params).then(res => {
      setSubmissionList(res?.data?.data)
    })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setIsLoader(false)
      })
  }

  const columns = [
    {
      id: "submittedBy",
      Header: "Submitted By",
      accessor: form => form.submittedBy
    },
    {
      id: "createdDate",
      Header: "Created On",
      accessor: (form) => moment(form.createdDate).format("ddd hh:mma  MM-DD-YYYY")
    },
    {
      id: "modifiedDate",
      Header: "Modified On",
      accessor: (form) => moment(form.modifiedDate).format("ddd hh:mma  MM-DD-YYYY")
    },
    {
      id: "actions",
      Header: "Actions",
      textAlign: 'center',
      disableSortBy: true,
      accessor: (form) => {
        return (<div className='d-flex justify-content-center'>
          <div className='btn-group'>
            <button className='btn btn-primary' title="View Form" onClick={e => { e.preventDefault(); setSelectedForm(form); return setShowForm(true) }}><i className='icon eye' /></button>
          </div>
        </div>
        )
      }
    },
  ];
  return (
    <>
      <div className='row d-flex'>
        <div className='col-12'>
          <Module title="Form Submissions">
            <Table2 export searchable data={submissionList} loading={isLoader} columns={columns} />
          </Module>
        </div></div>
      <ModalBox title={props?.form?.formTitle} open={showForm} onClose={() => { setShowForm(false) }}>
        <Form
          submission={selectedForm?.submission}
          form={{ display: 'form', components: inputData }}
          options={{ readOnly: true, viewOnly: true }} />
      </ModalBox>
    </>
  )
}
export default ViewPublicFormSubmissions