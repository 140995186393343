import React, { useState } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import ProviderServicesTable from './provider-services-table/ProviderServicesTable'

const ProviderServices = (props) => {
    const [activeIndex, setActiveIndex] = useState(0)

    return (
        <div>
            <Accordion fluid styled>
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={e => { e.preventDefault(); if (activeIndex === 0) { setActiveIndex() } else { setActiveIndex(0) } }}
                > <Icon name='dropdown' />Services</Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                    <div>
                        <ProviderServicesTable id={props?.provider?.id} />
                    </div>
                </Accordion.Content>

            </Accordion>
        </div>
    )
}

export default ProviderServices