import React, { useState } from 'react'
import FormValidatorService from '../../../../../../services/validator/form-validator.service'
import ValidationConstant from '../../../../../../services/validator/validation.constant'
import toast from "react-hot-toast";
const LeadSourceForm = (props) => {
    const [formData, setFormData] = useState(props.initialData || {})
    const [errors, setErrors] = useState({})
    const required = ['leadSource']
    const LeadSourceFormConfig = {
        config: {
            'leadSource': {
                required: { name: ValidationConstant.patient.tag.add.name.name },
                maxlength: {
                    name: ValidationConstant.patient.tag.add.name.name,
                    max: ValidationConstant.patient.tag.add.name.maxLength.toString()
                },
            },
        },
        'leadSource': ['', []]
    }
    // formula for input change
    const inputChange = (e) => {
        let newStateObject = { ...formData };
        newStateObject[e.target.name] = e.target.value
        setErrors(FormValidatorService.setErrors(e, errors, LeadSourceFormConfig.config))
        return setFormData(newStateObject);
    };
    return (
        <div className='d-flex row'>
            <div className='col-12'>
                <div className='field required'>
                    <label>Lead Source</label>
                    <input type="text" value={formData.leadSource} name="leadSource"
                        onChange={(e) => { e.preventDefault(); inputChange(e) }}
                        required />
                    <span className="error-message">{errors.leadSource}</span>
                </div>
            </div>
            <div className='mt-3 d-flex justify-content-between'>
                
                <div className='col-auto'>
                    <button className='btn btn-primary' onClick={e => {
                        e.preventDefault();
                        if (!FormValidatorService.checkForm(errors, formData, required)) {
                            toast.error("Please make sure the form is complete")
                        }
                        else {
                            props.submitHandler(formData);
                        }
                    }}>Save</button>
                </div>
                <div className='col-auto'>
                    <button className='btn btn-secondary' onClick={e => { e.preventDefault(); props.closeModal(false);}}>Close</button>
                </div>
            </div>
        </div>
    )
}
export default LeadSourceForm