import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment';
import Utilities from '../../../../../services/commonservice/utilities';
import ModalBox from '../../../../templates/components/ModalBox';
import TransactionStatusEnum from '../../../../../common/enum/transaction-status.enum';
import Module from '../../../../templates/components/Module';
import VirtualTerminalForm from '../terminals/virtual-terminal/virtual-terminal-form/VirtualTerminalForm';
import RefundTransactionCard from '../refund-transaction/RefundTransactionCard';
import TransactionService from '../../../../../services/api/transaction.service';
import InvoicePreview from '../../invoices/invoice-preview/InvoicePreview';
import { ChannelTypeEnum as filters } from '../../../../../common/enum/channel-type.enum';
import { TransactionOperationTypeEnum as operationTypeFilter } from '../../../../../common/enum/Conversions/transaction-operation.enum';
import DimLoader from '../../../../templates/components/DimLoader';
import APIResponse from '../../../../templates/components/APIResponse';
import { store } from '../../../../../context/StateProvider';

const TransactionsCardList = (props) => {
    const state = useContext(store).state

    const [show, setShow] = useState(false)
    const [preview, setPreview] = useState(false)
    const [edit, setEdit] = useState(false)
    const [pay, setPay] = useState(false)
    const [cancel, setCancel] = useState(false)
    const [refund, setRefund] = useState(false)
    const [apiResponse, setApiResponse] = useState()
    const [isLoader, setIsLoader] = useState(false)
  const onSuccess = (message) => {
      if (props.refresh) {
        props.refresh()
      }
      if (props.onClose) {
        props.onClose()
      }
      if (props.exitHandler) {
        props.exitHandler()
      }
    }
    const cancelTc = () => {
        setCancel(false)
        setIsLoader(true)
        TransactionService.voidTransaction(props.transaction.id)
        .then((res) => {
            setApiResponse(res)
            setIsLoader(false)
          })
          .catch(err => {
            setApiResponse(err)
            setIsLoader(false)
          })
    }

    const icons = {
        blank: 'credit-card',
        AMEX: 'cc amex',
        DINERS: 'cc diners club',
        DISCOVER: 'cc discover',
        JCB: 'cc jcb',
        MASTERCARD: 'cc mastercard',
        VISA: 'cc visa'
    }
    return (
        <div className='card mb-3 p-3 transaction-card'>
            {isLoader &&
        <DimLoader loadMessage="Processing" />
      }
            <div className='row align-items-center' >
                <div className='col-12 text-start mb-3 point' onClick={e => { e.preventDefault(); setShow(!show) }} title="Click for Preview">
                    <div className='row d-flex align-items-center transaction-card__header'>
                        <h5 className='col-md-8 col-12'>
                            <strong onClick={(e) => { e.preventDefault(); console.log(props.transaction) }}>{props.transaction.firstName} {props.transaction.lastName}</strong> | {Utilities.toDollar(props.transaction.tenderInfo.totalAmount)}
                        </h5>
                        <div className='col-md-4 col-12 justify-content-end d-flex'>
                            <div className='btn-group'>
                                <div className='btn bg-secondary d-flex align-items-center'>
                                    <div className='col text-white'>
                                        {props.transaction.tenderInfo.maskCardNumber ? <i className={`icon big ${icons[props.transaction.tenderInfo.cardType]}`} title={props.transaction.tenderInfo.cardType} /> : <span className='bank-marker my-2' title={props.transaction.tenderInfo.bankName || filters.find(obj => obj.value === props.transaction.tenderInfo.channelType)?.title}>{props.transaction.tenderInfo.bankName || filters.find(obj => obj.value === props.transaction.tenderInfo.channelType)?.title}</span>}
                                        {props.transaction.tenderInfo.maskCardNumber && props.transaction.tenderInfo.maskCardNumber}
                                        {props.transaction.tenderInfo.maskAccount && props.transaction.tenderInfo.maskAccount}
                                    </div>
                                </div>

                                {state.permissions.paymentsViewInvoices && 
                                <button onClick={e => { e.preventDefault(); setPreview(true) }} className="btn btn-primary"><i className="icon eye" /></button>
                                }

                                {TransactionStatusEnum[props.transaction.transactionStatus] === "Authorized" 
                                && props.transaction.operationType!=7 //Refund
                                && state.permissions.paymentsModifyTransactions
                                ? <button onClick={(e) => { e.preventDefault(); setCancel(true) }} className="btn btn-primary" title="Cancel"><i className="icon dont" /></button>:null}
                                {TransactionStatusEnum[props.transaction.transactionStatus] === "Paid" 
                                && [9,10].includes(props.transaction.tenderInfo.channelType)
                                && state.permissions.paymentsModifyTransactions
                                ? <button onClick={(e) => { e.preventDefault(); setCancel(true) }} className="btn btn-primary" title="Cancel"><i className="icon dont" /></button>:null}
                                {state.permissions.paymentsModifyTransactions
                                && props.transaction.tenderInfo.channelType !== 9 
                                && props.transaction.tenderInfo.channelType != 10 
                                && props.transaction.tenderInfo.channelType!=8 
                                && props.transaction.operationType!=7 //Refund
                                && TransactionStatusEnum[props.transaction.transactionStatus] === "Paid"? <button onClick={(e) => { e.preventDefault(); setRefund(true) }} className="btn btn-primary" title="Refund"><i className="icon undo alternate" /></button> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 text-start row d-flex justify-content-between'>
                    <span className='col-md-4 col-12'><strong>Transaction Id: </strong>{props.transaction?.id}</span>
                    <span className='col-md-4 col-12'><strong>Transaction Date: </strong>{moment(props.transaction.transactionDate).format("M-D-YYYY")}</span>
                    <span className='col-md-4 col-12'><strong>Status: </strong>{TransactionStatusEnum[props.transaction.transactionStatus]}</span>
                    <span className='col-md-4 col-12'><strong>Type: </strong>{filters.find(obj => obj.value === props.transaction.tenderInfo.channelType)?.title}</span>
                    <span className='col-md-4 col-12'><strong>Operation Type: </strong>{operationTypeFilter.find(obj => obj.value === props.transaction.operationType)?.title}</span>
                    <span className='col-md-4 col-12'>{props.transaction?.tenderInfo?.cardType || props.transaction?.tenderInfo?.bankName ? <strong>Vendor: </strong> : null}{props.transaction?.tenderInfo?.cardType || props.transaction?.tenderInfo?.bankName}</span>
                    {props.transaction.tenderInfo.nameOnCheckOrCard ? <span className='col-md-4 col-12'><strong className=''>Name on Card: </strong>{props.transaction.tenderInfo.nameOnCheckOrCard}</span> : <div className='col-md-4 col-12'></div>}
                </div>
            </div >
            {show && <Module className='col-12'>
                <InvoicePreview invoice={props.transaction} invoiceId={props.transaction.invoiceId} />
            </Module>}
            <ModalBox open={preview} onClose={() => { setPreview(false) }}>
                <InvoicePreview invoiceId={props.transaction.invoiceId} invoice={props.transaction} />
            </ModalBox>
            <ModalBox open={pay} onClose={() => { setPay(false) }}>
                <VirtualTerminalForm initialData={props.transaction || {}} onClose={() => { if (props.refresh) { props.refresh() } return setPay(false) }} />
            </ModalBox>
            <ModalBox open={refund} onClose={() => { setRefund(false) }} title="Refund">
                <RefundTransactionCard initialData={props.transaction} refresh={() => { props.refresh() }} onClose={() => {setRefund(false) }} />
            </ModalBox>
            <ModalBox open={cancel} onClose={() => { setCancel(false) }} title="Cancel">
                <div className='d-flex row justify-content-between'>
                    <div className='col-12'>
                        Are you sure you want to cancel?
                    </div>
                    <div className='col-auto mt-3'><button className='btn btn-primary' onClick={e => { e.preventDefault(); cancelTc() }}>Confirm</button></div>
                    <div className='col-auto mt-3'><button className='btn btn-secondary' onClick={e => { e.preventDefault(); setCancel(false) }}>Close</button></div>
                </div>
            </ModalBox>
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </div >
    )
}
export default TransactionsCardList