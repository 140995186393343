import React, { useEffect, useState } from "react";
import label from "../../../../../../../assets/i18n/en.json";
import moment from "moment";
import ManufacturerService from "../../../../../../services/api/manufacturer.service";
import ManufacturerAdd from "./manufacturer/manufacturer-add/ManufacturerAdd";
import ManufacturerEdit from "./manufacturer/manufacturer-edit/ManufacturerEdit";
import ModalBox from "../../../../../templates/components/ModalBox";
import Table2 from "../../../../../templates/components/Table2";
import APIResponse from "../../../../../templates/components/APIResponse";

const ManufacturerTable = (props) => {
  const [isLoader, setIsLoader] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState();
  const [filter, setFilter] = useState()
  const hiddenColumns = ["isActive","actionPracticePatient"]
  const [apiResponse, setApiResponse] = useState()

  const onSuccess = (message) => {
    if (props.refresh) {
        props.refresh()
    }
    if (props.onClose) {
        props.onClose()
    }
    if (props.exitHandler) {
        props.exitHandler()
    }
}

  const findManufacturer = () => {
    setIsLoader(true);
    ManufacturerService.getAllManufacturer()
      .then((response) => {
        setManufacturerList(response.data);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };
  const statusHandler = () => {
    setStatusModal(false);
    setIsLoader(true)
    if (selectedManufacturer.isActive == 1) {
      ManufacturerService.deactivateManufacturer(selectedManufacturer.manufacturerId).then(response => {
        setApiResponse(response)
        findManufacturer();
      })
        .catch(error => {
          setApiResponse(error)
        })
        .finally(() => {
          setIsLoader(false)
        });

    } else {
      ManufacturerService.activateManufacturer(selectedManufacturer.manufacturerId).then(response => {
        setApiResponse(response)
        findManufacturer();
      })
        .catch(error => {
          setApiResponse(error)
        })
        .finally(() => {
          setStatusModal(false);
          setIsLoader(false)
        });
    }
  };

  useEffect(() => {
    findManufacturer();
  }, []);

  const columns = [
    {
      id: "createdOn",
      Header: "Created On",
      accessor: (manufacturer) => moment(manufacturer.createdOn).format("MM/DD/YYYY"),
      //Footer: () => 'Totals'
    },
    {
      id: "manufacturer",
      Header: "Manufacturer",
      accessor: item => item.manufacturer,
    },
    {
      id: "isActive",
      Header: "Active",
      align: "center",
      sortable: true,
      accessor: (manufacturer, i) =>
        manufacturer.isActive === 1 ? (
          <span className="btn btn-success text-white w-100">Active</span>
        ) : (
          <span className="btn btn-danger text-white w-100">Inactive</span>
        ),
    },
    {
      id: "actionManufacturer",
      Header: "Action",
      disableSortBy: true,
      accessor: (manufacturer, i) => {
        return (
          <div className="d-flex justify-content-center">
            <button
              className="p-0 ps-1 btn btn-primary"
              title="Edit Manufacturer"
              onClick={(e) => {
                e.preventDefault();
                setSelectedManufacturer(manufacturer);
                return setOpenModal(true);
              }}
            >
              <i className="icon pencil" />
            </button>
            <button
              className="p-0 ps-1 btn btn-primary ms-1"
              title={
                manufacturer.isActive === 1 ? "Deactivate Manufacturer" : "Activate Manufacturer"
              }
              onClick={(e) => {
                e.preventDefault();
                setSelectedManufacturer(manufacturer);
                return setStatusModal(true);
              }}
            >
              {manufacturer.isActive === 1 ? <i className="icon dont" /> : <i className="icon check" />}
            </button>
          </div>
        );
      },
    },
  ];



  return (
    <>
      {manufacturerList ? (
        <div className="row d-flex col-12 mb-3">
          <div>{manufacturerList ? (
            <Table2
              hiddenColumns={hiddenColumns}
              export
              searchable
              loading={isLoader}
              columns={columns}
              data={manufacturerList.filter(obj => { if (filter === '' || filter === null || !filter || filter === 'All') { return obj } else { return obj.isActive === parseInt(filter) } })}
              extraButtons={[
                {
                  className: 'btn btn-primary mt-auto',
                  children: <i className="icon plus" />,
                  title: "Add Manufacturer",
                  onClick: () => { setShowAdd(true) }
                }
              ]}
            extraElements={
              [
                {
                  children: <div className="field">
                    <label>Filter By</label>
                    <select className="form-select" value={filter} onChange={e => { setFilter(e.target.value) }}>
                      <option value={null} selected>All</option>
                      <option value={1}>Active Only</option>
                      <option value={0}>Inactive Only</option>
                    </select>
                  </div>
                }
              ]
            }
            />
          ) :
            null}
          </div>
        </div>
      ) : null}
      <ModalBox open={openModal} onClose={() => setOpenModal(false)} title="Edit Manufacturer">
        {selectedManufacturer && <ManufacturerEdit 
          id={selectedManufacturer.manufacturerId}
          onClose={() => {
            findManufacturer();
            return setOpenModal(false);
          }}
        />}
      </ModalBox>

      <ModalBox open={statusModal} onClose={() => setStatusModal(false)} title={selectedManufacturer?.isActive === 1 ? "Deactivate Manufacturer":"Activate Manufacturer"}>
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col">
            {selectedManufacturer?.isActive === 1 ? (
              <p>Would you like to deactivate {selectedManufacturer?.manufacturer}</p>
            ) : (
              <p>Would you like to activate {selectedManufacturer?.manufacturer} </p>
            )}
          </div>
          <div className="col-auto">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                statusHandler();
              }}
            >
              OK
            </button>
            <button
              className="btn btn-secondary ms-3"
              onClick={(e) => {
                e.preventDefault();
                setStatusModal(false);
              }}
            >
              {label.common.cancel}
            </button>
          </div>
        </div>
      </ModalBox>
      <ModalBox
        open={showAdd}
        onClose={() => {
          setShowAdd(false);
        }}
        title="Add Manufacturer"
      >
        <ManufacturerAdd 
          onClose={() => {
            findManufacturer();
            return setShowAdd(false);
          }}
        />
      </ModalBox>
      <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
    </>
  );
};

export default ManufacturerTable;
