import React, { useState, useEffect, useContext } from 'react'
import label from '../../../../../../../../../assets/i18n/en.json'
import { MembershipType as MembershipType } from '../../../../../../../../common/enum/membership-type.enum';
import HPTSelect from '../../../../../../../templates/components/HPTSelect';
import PracticeLocationSelector from '../../../../../../../templates/components/PracticeLocationSelector';
import Utilities from '../../../../../../../../services/commonservice/utilities';
import ProductService from '../../../../../../../../services/api/product.service';
import { useMediaQuery } from 'react-responsive'
import { store } from '../../../../../../../../context/StateProvider';
import FormValidatorService from '../../../../../../../../services/validator/form-validator.service';
import toast from "react-hot-toast";
import { isEmpty, isNull } from 'lodash';
const MembershipForm = (props) => {
    // Pull properties form parent
    const { isEdit, submitHandler, isLoader } = props;
    // Set states for form
    //const [isLoader, setIsLoader] = useState(false)
    const stateAndDispatch = useContext(store)
    const state = stateAndDispatch.state
    const [formData, setFormData] = useState(props.initialData || {})
    const [locationId, setLocationId] = useState(props?.isEdit ? props.initialData?.practiceLocationId : state.practiceLocationId);
    const [errors, setErrors] = useState({})
    const [productList, setProductList] = useState()
    const [serviceList, setServiceList] = useState()
    // const [subTotal, setSubTotal] = useState(0)
    // const [totalDiscount, setTotalDiscount] = useState(0)
    // const [total, setTotal] = useState(0)
    // const [isTotalError, setIsTotalError] = useState(true)
    const isMobile = useMediaQuery({ query: `(max-width: 576px)` });
    const required = ['name',
        'membershipType',
        'membershipPeriodQty',
        'unitPrice',
        'membershipPeriodUnit',
        'membershipOfferBeginsOn'
    ]

    const MembershipPeriodUnitList = [
        {
            label: 'Month(s)',
            value: 'Month'
        },
        {
            label: 'Quarter(s)',
            value: 'Quarter'
        },
        {
            label: 'Year(s)',
            value: 'Year'
        }
    ]
    const ItemType = [
        {
            label: 'Service',
            value: 'Service'
        },
        {
            label: 'Product',
            value: 'Product'
        }
    ]
    const [selectedPeriodUnit, setSelectedPeriodUnit] = useState(formData.membershipPeriodUnit ? MembershipPeriodUnitList.find(obj => obj.value === formData.membershipPeriodUnit) : null);
    const [itemsList, setItemsList] = useState([])

    const serviceLookup = () => {
        const reqObj = {
            PracticeLocationId: locationId,
            isActive: 1
        };
        ProductService.serviceLookup(reqObj)
            .then(res => {
                setServiceList(res.data);
            }
            ).catch(error => {
                console.log(error)
            })
    }
    const productLookup = () => {
        const reqObj = {
            practiceLocationId: locationId,
            includePracticeItems: 'yes',
            isActive: true,
            itemType: 1
        };
        ProductService.productLookup(reqObj)
            .then(res => {
                if (res) {
                    setProductList(res)
                }
            }
            )
    }
    // formula for input change
    const inputChange = (e) => {
        let newStateObject = { ...formData };
        if (e.target.name === "unitPrice") {
            newStateObject[e.target.name] = e.target.value ? parseFloat(e.target.value) : 0
            return setFormData(newStateObject);
        }
        else if (e.target.type === "number") {
            newStateObject[e.target.name] = e.target.value ? parseFloat(e.target.value) : null
            return setFormData(newStateObject);
        }
        else {
            newStateObject[e.target.name] = e.target.value
            return setFormData(newStateObject);
        }
    };

    const onLocationChanged = (newLocationId) => {
        setLocationId(newLocationId)
    }


    useEffect(() => {
        if (props.isEdit) {
            if (props.initialData?.membershipItems?.length > 0) {
                let localItemList = []
                props.initialData?.membershipItems.forEach((item, i) => {
                    let newItem = {
                        sequenceId: `item-${i}`,
                        "sequence": i,
                        membershipItemId: item.membershipItemId,
                        parentItemId: item.parentItemId,
                        providerId: item.providerId,
                        itemId: item.itemId,
                        name: item.name,
                        discount: item.discount,
                        discountType: item.discountType,
                        discountOverride: item.discount == null ? false : true,
                        unitPrice: item.unitPrice ? +item.unitPrice : item.unitPrice,
                        priceOverride: item.unitPrice == null ? false : true,
                        cashValue: item.cashValue,
                    }
                    if (item.itemType === 1) {
                        newItem.type = "Product"
                    } else {
                        newItem.type = "Service"
                    }
                    if ([1, 2].includes(formData.membershipType)) {
                        newItem.perPeriodQtyLimit = item.perPeriodQtyLimit
                        newItem.rollOverQtyLimit = item.rollOverQtyLimit
                        newItem.rollOverTimeLimit = item.rollOverTimeLimit
                    }
                    if ([3].includes(formData.membershipType)) {
                        newItem.countsAs = item.countsAs
                    }
                    localItemList.push(newItem)
                })
                setItemsList(localItemList)
            }
        }
    }, [])
    useEffect(() => {
        if (locationId) {
            serviceLookup()
            productLookup()
            inputChange({
                target: {
                    name: 'practiceLocationId', value: locationId
                }
            })
        }
    }, [locationId])
    // useEffect(() => {
    //     if (!formData.membershipOfferBegin	sOn) {
    //         inputChange({ target: { value: "", name: 'membershipOfferEndsOn' } })
    //     }
    // }, [formData.membershipOfferBeginsOn])
    // useEffect(() => {
    //     if (itemsList) {
    //         sumItems()
    //     }
    // }, [itemsList])

    // useEffect(() => {
    //     if (total && formData?.unitPrice) {
    //         if (total > formData.unitPrice) {
    //             setIsTotalError(true)
    //         } else {
    //             setIsTotalError(false)
    //         }
    //     } else {
    //         setIsTotalError(false)
    //     }
    // }, [total, formData.unitPrice])

    const removeItems = (removeItem) => {
        setItemsList((current) =>
            current.filter((item) => item.sequenceId !== removeItem.sequenceId)
        );
    }
    const addItemToList = () => {
        let localItem = {
            sequenceId: `item-${itemsList.length}`,
            "sequence": itemsList.length,
            "itemId": null,
            "name": null,
            "unitPrice": null,
            "discount": null,
            "discountType": null,
            "priceOverride": null,
            "discountOverride": null,
            "cashValue": null
        }
        if ([1, 2].includes(formData.membershipType)) {
            localItem.perPeriodQtyLimit = null
            localItem.rollOverQtyLimit = null
            localItem.rollOverTimeLimit = null
        }
        if ([3].includes(formData.membershipType)) {
            localItem.countsAs = null
        }
        if (props.isEdit) {
            localItem.parentItemId = props.membershipId
        }
        setItemsList(prevItemList => [...prevItemList, localItem])
    };

    const addItem = (item, i) => {
        let newList = [...itemsList]
        newList[i].itemId = item.id
        newList[i].name = item.name
        newList[i].discount = item.discount || 0
        newList[i].discountType = parseInt(item.discountType) || 2
        newList[i].unitPrice = +item.unitPrice
        newList[i].priceOverride = true
        newList[i].discountOverride = true
        newList[i].cashValue = null
        if ([1, 2].includes(formData.membershipType)) {
            newList[i].perPeriodQtyLimit = 0; // parseInt(item.perPeriodQtyLimit) || 1
            newList[i].rollOverQtyLimit = 0; // parseInt(item.rollOverQtyLimit) || 0
            newList[i].rollOverTimeLimit = 0; // parseInt(item.rollOverTimeLimit) || 0
        }
        if ([3].includes(formData.membershipType)) {
            newList[i].countsAs = 0;
        }
        setItemsList([...newList])
    }
    const changeItemList = (e, i) => {
        let newList = [...itemsList]
        if (e.target.name === "discountType" || e.target.type === "number") {
            if (e.target.name === "perPeriodQtyLimit" && isEmpty(e.target.value)) {
                newList[i]["rollOverQtyLimit"] = 0
            }
            if (e.target.name === "countsAs" && isEmpty(e.target.value)) {
                newList[i]["countsAs"] = null
            }
            newList[i][e.target.name] = e.target.value ? parseFloat(e.target.value) : null
            return setItemsList([...newList])
        }
        else if (e.target.name === "priceOverride") {
            newList[i]["priceOverride"] = e.target.checked
            if (!e.target.checked) {
                newList[i]["unitPrice"] = null
            }
            return setItemsList([...newList])
        }
        else if (e.target.name === "discountOverride") {
            newList[i]["discountOverride"] = e.target.checked
            if (!e.target.checked) {
                newList[i]["discount"] = null
            }
            return setItemsList([...newList])
        }
        else {
            newList[i][e.target.name] = e.target.value
            return setItemsList([...newList])
        }
    }

    // const lineTotal = (element) => {
    //     let total = 0;
    //     total = element.unitPrice * (formData.membershipType === 3 ? 1 : element.perPeriodQtyLimit)

    //     let discountAmt = 0
    //     if (+element.discount > 0) {
    //         if (element.discountType == 2) {
    //             discountAmt = Math.round((formData.membershipType === 3 ? 1 : parseFloat(element.perPeriodQtyLimit)) * element.unitPrice * (element.discount / 100) * 100) / 100
    //         }
    //         else {
    //             discountAmt = element.discount * (formData.membershipType === 3 ? 1 : parseFloat(element.perPeriodQtyLimit))
    //         }
    //     }

    //     total = total - discountAmt

    //     return total
    // }

    // const sumItems = () => {
    //     let subTotal = 0
    //     let discountTotal = 0
    //     itemsList.forEach((item, i) => {
    //         subTotal = subTotal + (item.unitPrice * (formData.membershipType === 3 ? 1 : item.perPeriodQtyLimit))
    //         let discountAmt = 0
    //         if (parseFloat(item.discount) > 0) {
    //             if (item.discountType == 2) {
    //                 discountAmt = Math.round((formData.membershipType === 3 ? 1 : parseFloat(item.perPeriodQtyLimit)) * item.unitPrice * (item.discount / 100) * 100) / 100
    //             }
    //             else {
    //                 discountAmt = item.discount * (formData.membershipType === 3 ? 1 : parseFloat(item.perPeriodQtyLimit))
    //             }
    //         }
    //         discountTotal = discountTotal + discountAmt
    //     })

    //     setTotalDiscount(discountTotal)
    //     setSubTotal(subTotal)
    //     setTotal(subTotal - discountTotal)
    // }

    return (
        <>
            {props.isLoader && <div className="ui">
                <div className="ui active dimmer">
                    <div className="ui indeterminate text loader">{label.common.processing}</div>
                </div>
            </div>}
            <div className=" fields p-3">
                <div className="row">

                    <div className="col-md-3 col-3 required field">
                        <label>Name</label>
                        <input
                            placeholder="Name"
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={(e) => {
                                e.preventDefault();
                                inputChange(e);
                            }}
                            required
                        />
                    </div>
                    <div className="required field col-md-3 col-3">
                        <label>Location</label>
                        <PracticeLocationSelector locationId={props.isEdit ? props?.initialData?.practiceLocationId : null} disabled={props.isEdit} dontResetState={true} onSelected={onLocationChanged} />
                    </div>

                    <div className="required field col-md-3 col-3">
                        <label>Type</label>
                        <HPTSelect
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="membershipType"
                            value={MembershipType.find(obj => obj.value === formData.membershipType)}
                            options={MembershipType}
                            onChange={e => {
                                //e.preventDefault();
                                setItemsList([])
                                inputChange({ target: { value: e.value, name: 'membershipType' } })
                            }}
                            getOptionLabel={(option) => { return (option.title) }}
                        />
                    </div>
                    <div className="required field col-lg-3 col-3">
                        <label>Price</label>
                        <input
                            placeholder="Price"
                            type="number"
                            name="unitPrice"
                            value={formData.unitPrice}
                            onChange={(e) => {
                                e.preventDefault();
                                inputChange(e);
                            }}
                        />
                    </div>

                </div>

                <div className="row">

                    <div className="required field col-lg-3 col-3">
                        <label>Duration</label>
                        <input
                            placeholder="Duration"
                            type="number"
                            name="membershipPeriodQty"
                            value={formData.membershipPeriodQty}
                            onChange={(e) => {
                                e.preventDefault();
                                inputChange(e);
                            }}
                        />
                    </div>
                    <div className="field required col-lg-3 col-3">
                        <label>Period</label>
                        <HPTSelect
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="membershipPeriodUnit"
                            value={selectedPeriodUnit}
                            options={MembershipPeriodUnitList}
                            onChange={e => {
                                setSelectedPeriodUnit(e);
                                inputChange({ target: { value: e.value, name: 'membershipPeriodUnit' } })
                            }}
                            getOptionLabel={(option) => { return (option.label) }}
                        />
                    </div>

                    <div className="field required col-lg-3 col-3">
                        <label>Start Date</label>
                        <input type="date"
                            className="form-control"
                            value={formData.membershipOfferBeginsOn ? Utilities.toDate(formData.membershipOfferBeginsOn) : null}
                            onChange={e => {
                                e.preventDefault();
                                inputChange(e);
                            }}
                            name="membershipOfferBeginsOn"
                        />
                    </div>
                    <div className="field col-lg-3 col-3">
                        <label>End Date</label>
                        <input type="date"
                            className="form-control"
                            disabled={formData.membershipOfferBeginsOn ? false : true}
                            min={formData.membershipOfferBeginsOn}
                            value={formData.membershipOfferEndsOn ? Utilities.toDate(formData.membershipOfferEndsOn) : ""}
                            onChange={e => {
                                e.preventDefault();
                                inputChange(e);
                            }}
                            name="membershipOfferEndsOn"
                        />
                    </div>

                </div>
                <div className="row">

                    {[3].includes(formData.membershipType) ? <>
                        <div className="required field col-lg-3 col-3">
                            <label>Quantity/Period</label>
                            <input value={formData.perPeriodQtyLimit} type="number" min={1} name="perPeriodQtyLimit"
                                onChange={e => {
                                    e.preventDefault();
                                    inputChange(e)
                                }} ></input>
                        </div>
                        <div className="field col-lg-3 col-3">
                            <label>Rollover Quantity</label>
                            <input disabled={formData.perPeriodQtyLimit ? false : true} value={formData.rollOverQtyLimit} type="number" name="rollOverQtyLimit" onChange={e => {
                                e.preventDefault();
                                if (e.target.value <= formData.perPeriodQtyLimit) {
                                    inputChange(e)
                                } else {
                                    inputChange({ target: { value: "", name: 'rollOverQtyLimit' } })

                                }
                            }} /> </div>
                        <div className="field col-lg-3 col-3">
                            <label>Rollover Time Limit</label>
                            <input disabled={formData.membershipPeriodQty ? false : true} value={formData.rollOverTimeLimit} type="number" name="rollOverTimeLimit"
                                onChange={e => {
                                    e.preventDefault();

                                    if (e.target.value <= formData.membershipPeriodQty) {
                                        inputChange(e)
                                    } else {
                                        inputChange({ target: { value: "", name: 'rollOverTimeLimit' } })

                                    }
                                }} />
                        </div>
                    </> : null}

                    <div className="field col-12">
                        <label>Instructions</label>
                        <textarea placeholder="Instructions"
                            className="form-control"
                            value={formData.membershipInstructions}
                            onChange={e => { e.preventDefault(); inputChange(e) }}
                            name="membershipInstructions"
                            rows={2}
                        ></textarea>
                    </div>

                    {formData.membershipType &&
                        <div className="field col-12" style={{ marginTop: 15 }}>
                            <span>Products/Services </span>
                            <button disabled={props.disabled} className="btn btn-primary" onClick={e => { e.preventDefault(); addItemToList(); }}>
                                <i className="icon plus"></i>
                            </button>
                        </div>
                    }

                    {itemsList && itemsList.length > 0 &&
                        <div className="row d-flex">
                            <div className='container-fluid mt-3'>
                                <div className='row d-flex'>
                                    <div className='col-12 px-0 card' style={{ overflow: 'unset!important' }}>
                                        <table className='table table-borderless my-0 invoice-product-table'>
                                            {[3].includes(formData.membershipType) ?
                                                <>{!isMobile ? <colgroup>
                                                    <col span="1" style={{ width: '400px' }} />
                                                    <col span="1" style={{ width: '400px' }} />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                </colgroup> : null} </> :
                                                <>{!isMobile ? <colgroup>
                                                    <col span="1" style={{ width: '250px' }} />
                                                    <col span="1" style={{ width: '250px' }} />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                    <col span="1" />
                                                </colgroup> : null}</>}

                                            <thead>
                                                {[3].includes(formData.membershipType) ?
                                                    <tr>
                                                        <th className='py-2'>Type</th>
                                                        <th className='py-2'>Name</th>
                                                        <th className='py-2'>Unit Price</th>
                                                        <th className='py-2'></th>
                                                        <th className='py-2'>Discount</th>
                                                        <th className='py-2'></th>
                                                        <th className='py-2'>Count As</th>
                                                        <th className='py-2'>Cash Value</th>
                                                        {/* <th className='py-2'>Total</th> */}
                                                        <th className='py-2'></th>
                                                    </tr> : <tr>
                                                        <th className='py-2'>Type</th>
                                                        <th className='py-2'>Name</th>
                                                        <th className='py-2'>Unit Price</th>
                                                        <th className='py-2'></th>
                                                        <th className='py-2'>Discount</th>
                                                        <th className='py-2'></th>
                                                        <th className='py-2'>Quantity/Period</th>
                                                        <th className='py-2'>Rollover Quantity</th>
                                                        <th className='py-2'>Rollover Time Limit</th>
                                                        <th className='py-2'>Cash Value</th>
                                                        {/* <th className='py-2'>Total</th> */}
                                                        <th className='py-2'></th>

                                                    </tr>}
                                            </thead>
                                            <tbody>
                                                {itemsList && itemsList.map((item, i) => {
                                                    return (
                                                        <tr className='product-line'>
                                                            <td className=''>
                                                                {isMobile ? <label>Type</label> : null}
                                                                <HPTSelect
                                                                    options={ItemType}
                                                                    className="react-select-container"
                                                                    classNamePrefix="react-select"
                                                                    name="type"
                                                                    value={ItemType && ItemType.find(obj => obj.value === item.type)}
                                                                    onChange={e => {
                                                                        changeItemList({
                                                                            target:
                                                                                { value: e.value, name: 'type' }
                                                                        }, i)
                                                                    }}
                                                                    getOptionLabel={(option) => option.label}
                                                                    getOptionValue={(option) => option.value}
                                                                />
                                                            </td>
                                                            <td>
                                                                {isMobile ? <label>Name</label> : null}
                                                                {item.type === "Product" ? <HPTSelect
                                                                    options={productList}
                                                                    isLoading={!productList}
                                                                    onChange={e => {
                                                                        addItem(e, i)
                                                                    }}
                                                                    value={productList && productList.find(obj => obj.id === item.itemId)}
                                                                    className="react-select-container"
                                                                    classNamePrefix="react-select"
                                                                    placeholder="Select Item"
                                                                    getOptionLabel={(option) => option.name
                                                                        + (option.manufacturerName ? (' | ' + option.manufacturerName) : "")
                                                                        + (option.tags && option.tags.length > 0 ? (' | ' + option.tags?.map(({ name }) => name).join(', ')) : "")
                                                                    }
                                                                    getOptionValue={(option) => option.id}
                                                                    isDisabled={item.type ? false : true}
                                                                /> : item.type === "Service" ? <HPTSelect
                                                                    options={serviceList}
                                                                    isLoading={!serviceList}
                                                                    onChange={e => {
                                                                        addItem(e, i)
                                                                    }}
                                                                    value={serviceList && serviceList.find(obj => obj.id === item.itemId)}
                                                                    className="react-select-container"
                                                                    classNamePrefix="react-select"
                                                                    placeholder="Select Item"
                                                                    getOptionLabel={(option) => option.name}
                                                                    getOptionValue={(option) => option.id}
                                                                    isDisabled={item.type ? false : true}
                                                                /> : <select className='form-select'
                                                                    value={null} disabled={true} />}
                                                            </td>

                                                            <td>
                                                                {isMobile ? <label>Unit Price</label> : null}
                                                                <input value={item.unitPrice} type="number"
                                                                    disabled={item.type && item.priceOverride ? false : true}
                                                                    name="unitPrice"
                                                                    onChange={e => { e.preventDefault(); changeItemList(e, i) }} />
                                                            </td>

                                                            <td style={{
                                                                textAlign: "center",
                                                                verticalAlign: "middle"
                                                            }}>
                                                                <input
                                                                    type="checkbox"
                                                                    name="priceOverride"
                                                                    checked={item.priceOverride}
                                                                    onClick={(e) => { changeItemList(e, i) }}
                                                                />
                                                            </td>

                                                            <td>
                                                                {isMobile ? <label>Discount</label> : null}
                                                                <div className='input-group'>
                                                                    <input value={item.discount}
                                                                        type="number" name="discount"
                                                                        onChange={e => { e.preventDefault(); changeItemList(e, i) }}
                                                                        disabled={item.type && item.discountOverride ? false : true} />

                                                                    <select
                                                                        value={item.discountType}
                                                                        onChange={e => { e.preventDefault(); changeItemList(e, i) }}
                                                                        className='form-select'
                                                                        name="discountType"
                                                                        style={{ maxWidth: '90px' }}
                                                                        disabled={item.type && item.discountOverride ? false : true}
                                                                    >
                                                                        <option selected value={2}>%</option>
                                                                        <option value={1}>$</option>
                                                                    </select>
                                                                </div>
                                                            </td>

                                                            <td style={{
                                                                textAlign: "center",
                                                                verticalAlign: "middle"
                                                            }}>
                                                                <input
                                                                    type="checkbox"
                                                                    name="discountOverride"
                                                                    checked={item.discountOverride}
                                                                    onClick={(e) => { changeItemList(e, i) }}
                                                                />
                                                            </td>
                                                            {[3].includes(formData.membershipType) ? <>  <td>
                                                                {isMobile ? <label>Count As</label> : null}
                                                                <div className='input-group'>
                                                                    <input disabled={item.type && formData.perPeriodQtyLimit ? false : true} value={item.countsAs} type="number" name="countsAs"
                                                                        onChange={e => {
                                                                            e.preventDefault();
                                                                            if (e.target.value <= formData.perPeriodQtyLimit) {
                                                                                changeItemList(e, i)
                                                                            } else {
                                                                                changeItemList({ target: { value: "", name: 'countsAs' } }, i)

                                                                            }
                                                                        }} ></input>
                                                                </div>
                                                            </td></> : null}

                                                            {[1, 2].includes(formData.membershipType) ? <>  <td>
                                                                {isMobile ? <label>Quantity/Period</label> : null}
                                                                <div className='input-group'>
                                                                    <input disabled={item.type ? false : true} value={item.perPeriodQtyLimit} type="number" min={1} name="perPeriodQtyLimit"
                                                                        onChange={e => {
                                                                            e.preventDefault();
                                                                            changeItemList(e, i)
                                                                        }} ></input>
                                                                    {formData.membershipType === 2 && formData.membershipPeriodUnit && <label style={{ marginLeft: "5px" }}>per {formData.membershipPeriodUnit}</label>}
                                                                    {formData.membershipType === 1 && formData.membershipPeriodUnit && formData.membershipPeriodQty && formData.membershipPeriodQty !== null && formData.membershipPeriodQty !== "" && <label style={{ marginLeft: "5px" }}>for {formData.membershipPeriodQty} {formData.membershipPeriodUnit}(s)</label>}
                                                                </div>

                                                            </td>
                                                                <td>
                                                                    {isMobile ? <label>Rollover Quantity</label> : null}
                                                                    <div className='input-group'>
                                                                        <input disabled={item.type && item.perPeriodQtyLimit ? false : true} value={item.rollOverQtyLimit} type="number" name="rollOverQtyLimit" onChange={e => {
                                                                            e.preventDefault();
                                                                            if (e.target.value <= item.perPeriodQtyLimit) {
                                                                                changeItemList(e, i)
                                                                            } else {
                                                                                changeItemList({ target: { value: "", name: 'rollOverQtyLimit' } }, i)

                                                                            }
                                                                        }} />
                                                                        {formData.membershipType === 2 && formData.membershipPeriodUnit && <label style={{ marginLeft: "5px" }}>per {formData.membershipPeriodUnit}</label>}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {isMobile ? <label>Rollover Time Limit</label> : null}
                                                                    <div className='input-group'>
                                                                        <input disabled={item.type && formData.membershipPeriodQty ? false : true} value={item.rollOverTimeLimit} type="number" name="rollOverTimeLimit"
                                                                            onChange={e => {
                                                                                e.preventDefault();

                                                                                if (e.target.value <= formData.membershipPeriodQty) {
                                                                                    changeItemList(e, i)
                                                                                } else {
                                                                                    changeItemList({ target: { value: "", name: 'rollOverTimeLimit' } }, i)

                                                                                }
                                                                            }} />
                                                                        {formData.membershipPeriodUnit && <label style={{ marginLeft: "5px" }}>{formData.membershipPeriodUnit}(s)</label>}
                                                                    </div>
                                                                </td></> : null}
                                                            <td>
                                                                {isMobile ? <label>Cash Value</label> : null}
                                                                <div className='input-group'>
                                                                    <input disabled={item.type ? false : true} value={item.cashValue} type="number" name="cashValue"
                                                                        onChange={e => {
                                                                            e.preventDefault();
                                                                            changeItemList(e, i)
                                                                        }} />
                                                                </div>
                                                            </td>
                                                            {/* <td>
                                                                {isMobile ? <label>Total</label> : null}
                                                                <input type="text" disabled value={Utilities.toDollar(isNaN(lineTotal(item)
                                                                ) ? 0 : lineTotal(item)).toString()} />
                                                            </td> */}

                                                            <td className="button-cell"><button className='btn btn-transparent' onClick={e => { e.preventDefault(); removeItems(item) }}>
                                                                <i className='icon trash' />{isMobile ? ' Remove' : ''}
                                                            </button></td>
                                                        </tr>
                                                    )
                                                })}

                                                {/* {totalDiscount > 0 ? <tr className='thead totals'>
                                                    <th colSpan={formData.membershipType === 3? 6 :8} className='text-end'>Sub Total</th>
                                                    <th colSpan={2} className='text-end amount'>{Utilities.toDollar(subTotal || 0)}</th>
                                                </tr> : null}
                                                {totalDiscount > 0 ? <tr className="thead totals">
                                                    <th colSpan={formData.membershipType === 3? 6 :8} className='text-end'>Total Discount</th>
                                                    <th colSpan={2} className='text-end amount'>{Utilities.toDollar(totalDiscount || 0)}</th>
                                                </tr> : null}

                                                <tr className="thead totals grand-total">
                                                    <th colSpan={formData.membershipType === 3? 6 : 8} className='text-end'>Total Amount</th>
                                                    <th colSpan={2} className='text-end amount'>{Utilities.toDollar(total || 0)}</th>
                                                </tr> */}

                                            </tbody>
                                        </table>
                                    </div >
                                </div >
                            </div >

                        </div >}


                    <div className="mt-3 d-flex justify-content-between modal-button-bar">
                        <div className='col-auto'>
                            <button className="btn btn-primary" onClick={e => {
                                e.preventDefault();
                                if (!FormValidatorService.checkForm(errors, formData, required)) {
                                    toast.error("Please make sure the form is complete")
                                }
                                else if (itemsList.length <= 0) {
                                    toast.error("Please add at least one item")
                                }

                                // else if (isTotalError) {
                                //     toast.error("Total amount should not be greater than Membership price")
                                // }

                                else if (formData.membershipType == 3 && (formData?.rollOverQtyLimit || 0) > 0 && (formData?.rollOverTimeLimit || 0) <= 0) {
                                    toast.error("When providing a Rollover Quantity, a Rollover Time Limit must be provided.")
                                }

                                else {
                                    submitHandler(formData, itemsList)
                                }
                            }}>{isEdit ? 'Update' : 'Save'}</button>
                            {props.additionalButton}
                        </div >
                        <div className='col-auto'>
                            {props.onClose && <button className="btn btn-secondary float-right" onClick={e => { e.preventDefault(); props.onClose() }}>Close</button>}
                        </div>
                    </div >
                </div>
            </div >
        </>
    )
}

export default MembershipForm