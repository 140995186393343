import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import LoginService from '../../../../../../services/api/login.service'
import ProviderService from '../../../../../../services/api/provider.service'
import DimLoader from '../../../../../templates/components/DimLoader'
import PracticeForm from '../practice-form/PracticeForm'
import Module from '../../../../../templates/components/Module'
import ModuleAccess from '../module-access/ModuleAccess'
import UserRolesTable from '../../../../provider/settings-dashboard/security-settings/user-roles/user-roles-table/UserRolesTable'
import AccordionTemplate from '../../../../../templates/components/AccordionTemplate'
import UserSettingsTable from '../../../../provider/settings-dashboard/security-settings/user/user-settings-table/UserSettingsTable'
import SideTabs from '../../../../../templates/layouts/SideTabs'

const PracticeSettingsGlobal = (props) => {
    const id = useParams()?.practiceId
    const [practice, setPractice] = useState()
    const [isLoader, setIsLoader] = useState(true)
    const [activeTab, setActiveTab] = useState()

    const navigate = useNavigate()
    useEffect(() => {
        changeTab(props.tab)
    }, [props.tab])

    const changeTab = (tab) => {
        if (tab === 'practice-details') {
            navigate(`/admin/${id}`)
            setActiveTab(tab)
        }
        else {
            navigate(`/admin/${id}/${tab}`)
            setActiveTab(tab)
        }
    }
    useEffect(() => {
        if (id) {
            ProviderService.getProviderById(id)
                .then(res => {
                    console.log(res.data)
                    setPractice(res.data)
                    setIsLoader(false)
                })
        }
    }, [id])
    return (
        <div>
            {isLoader ?
                <DimLoader loadMessage="Practice Information Loading" /> : null}
            <div className='row d-flex'>
                <div className='col-12'>
                    <h1 className='text-center my-3'>{practice?.name}</h1>
                </div>
                {/* <div className='col-md-6 col-12'>
                    <Module title="Practice Details">
                        <PracticeForm practiceId={id} isEdit noModules />
                    </Module>
                </div>
                <div className='col-md-6 col-12'>
                    <Module title="Module Access">
                        <ModuleAccess practiceId={id} isEdit />
                    </Module>
                </div>
                <div className='col-md-6 col-12'>
                    <Module title="Users">
                        <AccordionTemplate id="users" accordionId="users">
                            <div title="Users">
                                <UserSettingsTable practice={practice} practiceId={id} isEmbedded />
                            </div>
                            <div title="User Types">
                                <UserRolesTable practice={practice} practiceId={id} />
                            </div>
                        </AccordionTemplate>
                    </Module>
                </div> */}
                <SideTabs title="Settings" menuTitle="Settings Menu" contentTitle='Settings' activeKey={activeTab} onSelect={k => changeTab(k)}>
                    <Module title="Practice Details" eventKey="practice-details">
                        <PracticeForm practiceId={id} isEdit noModules />
                    </Module>
                    <Module title="Module Access" eventKey="modules">
                        <ModuleAccess practiceId={id} isEdit />
                    </Module>
                    <Module eventKey="users" title="Users">
                        <AccordionTemplate id="users" accordionId="users">
                            <div title="Users">
                                <UserSettingsTable practice={practice} practiceId={id} isEmbedded />
                            </div>
                            <div title="User Types">
                                <UserRolesTable practice={practice} practiceId={id}/>
                            </div>
                        </AccordionTemplate>
                    </Module>
                </SideTabs>
            </div>
        </div >
    )
}

export default PracticeSettingsGlobal