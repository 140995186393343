import moment from 'moment-timezone';
import React, { useState, Fragment, useEffect, useContext } from 'react'
import { Popup, Modal } from 'semantic-ui-react';
import AppointmentService from '../../../../../services/api/appointment.service';
import NotificationService from '../../../../../services/api/notification.service';
import PatientService from '../../../../../services/api/patient.service';
import ModalBox from '../../../../templates/components/ModalBox';
import Toaster from '../../../../templates/components/Toaster';
import EditAppointmentForm from '../edit-appointment-form/EditAppointmentForm';
import Utilities from '../../../../../services/commonservice/utilities';
import toast from 'react-hot-toast';
import AddInvoice from '../../invoices/add-invoice/AddInvoice';
import EditInvoice from '../../invoices/edit-invoice/EditInvoice';
import AppointmentNotify from '../appointment-notify/AppointmentNotify';
import $ from 'jquery'
import { store } from '../../../../../context/StateProvider'
import AppointmentStatus from '../appointment-status/AppointmentStatus'
import PatientCardWrapper from '../../patient/patient-list/patient-card/PatientCardWrapper';
import InvoicePreview from '../../invoices/invoice-preview/InvoicePreview';
import UnavailableBlockForm from '../../unavailable-block/unavailable-block-form/UnavailableBlockForm';

const EventTile = (props) => {
    // console.log(props)
    const globalStateAndDispatch = useContext(store)
    const state = globalStateAndDispatch.state
    const [toolTip, setToolTip] = useState(false)
    const [editForm, setEditForm] = useState(false)
    const [editUnavailableForm, setEditUnavailableForm] = useState(false)
    const [toasterMessage, setToasterMessage] = useState()
    const [readOnly, setReadOnly] = useState(props?.readOnly || false)
    const [status, setStatus] = useState(
        // props.event.checkOutDate ? 3 : props.event.doctorCheckInDate ? 2 : props.event.checkInDate ? 1 : 0
        props.event.visitStatus !== null && props.event.visitStatus
    )
    const [patientCard, setPatientCard] = useState();
    const [event, setEvent] = useState(props.event)
    const [visitId, setVisitId] = useState(props.event.patientVisitId)
    const [currentVisit, setCurrentVisit] = useState()
    const [checkInView, setCheckInView] = useState(false)
    const [checkOutView, setCheckOutView] = useState(false)
    const [checkOutInvoice, setCheckOutInvoice] = useState(null)
    const [showEditInvoice, setShowEditInvoice] = useState(false)
    const [showPreviewInvoice, setShowPreviewInvoice] = useState(false)
    const [confirmCreateInvoice, setConfirmCreateInvoice] = useState(false)
    const [providerInView, setProviderInView] = useState(false)
    const [deleteView, setDeleteView] = useState(false)
    const [notifyView, setNotifyView] = useState(false)
    const [statusView, setStatusView] = useState(false)
    const [deleteUnavailable, setDeleteUnavailable] = useState(false)
    const [copyUnavailable, setCopyUnavailable] = useState(false)
    const [reason, setReason] = useState("")
    const [cancelType, setCancelType] = useState(1)
    const [notificationMethod, setNotificationMethod] = useState()
    const [checkOutEvent, setCheckOutEvent] = useState(null)
        $(".rbc-time-content").on('scroll',()=>{setToolTip(false)})
    const deleteEvent = () => {
        AppointmentService.deleteAppointment({
            id: props.event.id,
            providerId: props.event.providerId,
            reason: reason,
            cancelledTypeId: parseInt(cancelType) || 1
        })
            .then(res => {
                console.log(res)
                toast.success("Event Deleted")
                if(props.refresh){
                    props.refresh()
                }
                setDeleteView(false)
            })
    }
    
    const deleteUnavailableBlock = () => {
        AppointmentService.deleteUnavailableBlock(props.event.unavailableBlockId)
            .then(res => {
                console.log(res)
                toast.success("Away Time Deleted")
                if(props.refresh){
                    props.refresh()
                }
                setDeleteUnavailable(false)
            })
    }

    const copyUnavailableBlock = () => {
        props.onCopyUnavailable(props.event.unavailableBlockId);
    }
   

    // console.log(props.event)
    // const checkIn = () => {
    //     let reqObj = {
    //         checkInDate: moment().format("YYYY-MM-DDThh:mm:ss.sssZ"),
    //         doctorId: props.event.doctorId,
    //         patientName: props.event.firstName,
    //         visitStatus: 1,
    //         appointmentId: props.event.id,
    //     }
    //     PatientService.addVisits(reqObj, props.event.patientId)
    //         .then(res => {
    //             // console.log(res)
    //             setVisitId(res?.id)
    //             setCurrentVisit(res)
    //             setCheckInView(false)
    //             toast.success("Patient Checked In")
    //             return setStatus(1)
    //         })
    // }

    const checkOut = (noToast) => {
        let reqObj = {
            ParentGroupId: event.id,
            PracticeLocation: event.practiceLocationId
        }
        AppointmentService.findNextAppointment(reqObj)
            .then(res => {
                let newEvent = {...event};
                newEvent.childServices = [];
                
                if (res && Array.isArray(res)) {
                    newEvent.childServices = res.map(appt => 
                        {
                            let newObj = 
                            {
                                practiceServiceTypeId: appt.practiceServiceTypeId,
                                doctorId: appt.doctorId
                            }
                            return newObj;
                        } 
                    );
                }
                // setCurrentVisit(res)
                // setCheckOutView(false)
                if (!noToast) toast.success("Patient Checked Out")
                // setStatus(3)
                return setCheckOutInvoice(newEvent)
            })
            .catch((err) => {
                toast.error("Patient Checked Out Failed")
            })
    }

    // const doctorCheckIn = () => {
    //     let reqObj = {
    //         id: visitId,
    //         visitStatus: 2,
    //         doctorCheckInDate: new Date(),
    //         doctorId: props.event.doctorId,
    //     }
    //     PatientService.updateVisits(reqObj, props.event.patientId, visitId)
    //         .then(res => {
    //             console.log(res)
    //             setCurrentVisit(res)
    //             setProviderInView(false)
    //             toast.success("Patient is with Provider")
    //             return setStatus(2)
    //             // checkOut()
    //         })
    // }
    

    // const findVisit = () => {
    //     let reqObj = {
    //         appointmentId: props.event.id,
    //     }
    //     // console.log(props.event.patientId)
    //     PatientService.findVisits(reqObj, props.event.patientId)
    //         .then(res => {
    //             if (res.length) {
    //                 setStatus(res[0].visitStatus || 0);
    //                 setVisitId(res[0].id || null)
    //                 setCurrentVisit(res[0])
    //             }
    //         }
    //         )
    // }

    useEffect(() => {
        // if (props.event.practiceServiceType !== "**unavailable**") { findVisit() }
    }, [status])

    const notify = () => {
        let reqObj = {
            "patientId": props.event.patientId,
            "fromDate": props.event.fromDate,
            "toDate": props.event.toDate,
            "id": props.event.id,
            "sendNotificationMode": notificationMethod || 1
        }
        if (notificationMethod === 1) {
            reqObj.patientEmail = props.event.patientEmail
            reqObj.email = props.event.patientEmail
        }
        return AppointmentService.sendAptNotification(reqObj)
            .then(res => { toast.success("Patient Notified"); setNotifyView(false) })
            .catch(err => { console.log(err); setNotifyView(false) })
    }


    const onStatusUpdated = (selectedStatus) => {

        setStatusView(false);
        props.event.icon = selectedStatus.icon;
        props.event.fillColour = selectedStatus.color;
        props.event.practiceAppointmentStatusCodeId = selectedStatus.practiceAppointmentStatusCodeId;
        props.event.appointmentStatusCode = selectedStatus.appointmentStatusLabel;

        if (selectedStatus.isCheckout)
        {
            if (props.event.invoiceId) {
                if (props.event.invoiceType == null || props.event.invoiceType == 4 ||  props.event.invoiceType == 0) {
                    setShowEditInvoice(true);
                }
            }
            else {
                checkOut();
            }
        }
        else {
            props.onEventUpdate(props.event);
        }
    }

    const ToolTip = () => {
        return (
            <div className="event-tooltip">
                {props.event.firstName && <span>
                    {props.event.firstName} {props.event.lastName}
                </span>}
                {props.event.toolTip && <span>
                    {props.event.toolTip}
                </span>}
                {props.event.doctor && <span>
                    {props.event.doctor.name}
                </span>}
                {props.event.practiceServiceType && <span>
                    {props.event.practiceServiceType}
                </span>}
                {props.event.room && <span>
                    {props.event.room}
                </span>}
                {state.permissions.patientContactInfo && props.event.patientPhone && <span>
                    {Utilities.toPhoneNumber(props.event.patientPhone)}
                </span>}
                {props.event.equipmentDescription && <span>
                    {props.event.equipmentDescription}
                </span>}
                < div >
                {!readOnly && !props.event.parentGroupId && <hr className="my-2" />}
                    {!readOnly && <div className="row d-flex">
                        <div className="col mx-auto">
                            {/* {props.event.start < moment().endOf("d") && !props.event.parentGroupId && <>
                                {status === 0 || !status && moment().subtract(1, "h") < props.event.start ? <i onClick={e => { e.preventDefault(); setToolTip(false); setCheckInView(true) }} className="ui icon calendar check outline btn p-0 mb-2" title="Check In" /> : null}
                                {status === 1 && <i onClick={e => { e.preventDefault(); setToolTip(false); setProviderInView(true) }} className="ui icon stethoscope btn p-0 mb-2" title="With Provider" />}
                                {status === 2 && <i onClick={e => { e.preventDefault(); setToolTip(false); setCheckOutView(true) }} className="ui icon calendar times outline btn p-0 mb-2" title="Check Out" />}
                            </>} */}
                            {props.event.icon && <i onClick={e => { e.preventDefault(); setToolTip(false); setStatusView(true) }} className={`ui icon ${props.event.icon} btn p-0 mb-2`} title={props.event.appointmentStatusCode} />}
                            {new Date() < props.event.start && !props.event.parentGroupId && <i onClick={e => { e.preventDefault(); setToolTip(false); setNotifyView(true) }} className="ui icon bell btn p-0 mb-2" title="Notify" />}
                            {!props.event.parentGroupId && <i onClick={e => { e.preventDefault(); setToolTip(false); setEditForm(true); }} className="ui icon pencil btn p-0 mb-2" title="Edit Appointment" style={{ float: 'right' }} />}
                            {/* <i onClick={e => { e.preventDefault(); setToolTip(false); deleteEvent() }} className="ui times circle outline btn p-0 mb-2" title="Delete Appointment" style={{ float: 'right' }} /> */}
                            {!props.event.parentGroupId && <i onClick={e => { e.preventDefault(); setToolTip(false); showInvoice() }} className="ui icon dollar btn p-0 mb-2" title="Invoice" style={{ float: 'right' }} />}                            
                            {!props.event.parentGroupId && <i onClick={e => { e.preventDefault(); setToolTip(false); setDeleteView(true) }} className="ui icon trash btn p-0 mb-2" title="Cancel/No Show" style={{ float: 'right' }} />}                            
                            {!props.event.parentGroupId && <i onClick={e => { e.preventDefault(); setToolTip(false); setPatientCard(true) }} className="ui icon user btn p-0 mb-2" title="Patient" style={{ float: 'right' }} />}
                            {!props.event.parentGroupId && <i onClick={e => { e.preventDefault(); setToolTip(false); props.copyEvent(props.event) }} className="ui icon copy btn p-0 mb-2" title="Copy Appointment" style={{ float: 'right' }} />}
                        </div>
                    </div>}
                </div>
            </div >)
    }


    const UnavailableToolTip = () => {
        return (
            <div className="event-tooltip">
                {props.event.reasonForVisit && <span>
                    {props.event.reasonForVisit}
                </span>}
                {props.event.fromDate && <span>
                    {moment(props.event.fromDate).format('h:mm A')} - {moment(props.event.toDate).format('h:mm A')}
                </span>}
                < div >
                    <div className="row d-flex">
                        <div className="col mx-auto">
                            {<i onClick={e => { e.preventDefault(); setToolTip(false); setEditUnavailableForm(true); }} className="ui icon pencil btn p-0 mb-2" title="Edit Away Time" style={{ float: 'right' }} />}
                            {<i onClick={e => { e.preventDefault(); setToolTip(false); setDeleteUnavailable(true) }} className="ui icon trash btn p-0 mb-2" title="Delete Away Time" style={{ float: 'right' }} />}                            
                            {<i onClick={e => { e.preventDefault(); setToolTip(false); setCopyUnavailable(true) }} className="ui icon copy btn p-0 mb-2" title="Copy Away Time" style={{ float: 'right' }} />}
                        </div>
                    </div>
                </div>
            </div >)
    }



    // console.log(props.event)
    const showInvoice = () => {
        if (props.event.invoiceId) {
            if (props.event.invoiceType == null || props.event.invoiceType == 4 ||  props.event.invoiceType == 0) {
                setShowEditInvoice(true);
            }
            else
            {
                setShowPreviewInvoice(true);
            }
        }
        else {
            setConfirmCreateInvoice(true);
        }
    }
    return (
        <div style={{height: "inherit"}}
        onContextMenu={e => {
            e.preventDefault();
            setStatusView(true);
        }}
        onDoubleClick={e => { e.preventDefault(); setToolTip(false); 
            if (!readOnly && props.event.unavailableBlockId) 
            {
                setEditUnavailableForm(true) 
            }
            else if (!readOnly)
            {
                setEditForm(true) 
            }
            // if(!props.event.parentGroupId){
            //     setEditForm(true) 
            // }else{
            //     toast.error("Editing is not allowed for child appointments.")
            // }
        }} 
        >
            {toasterMessage && <Toaster autoClose show={toasterMessage} message={toasterMessage} onClose={() => { setToasterMessage() }} />}
            {props.event.id && < Popup
                on="click"
                content={ToolTip}
                position="top center"
                open={toolTip}
                onClose={() => { setToolTip(false) }}
                onOpen={() => { setToolTip(true) }}
                trigger={
                    <div className="event">
                        {props.event.icon && <i className={`ui icon ${props.event.icon} btn p-0 mb-2`} style={{ float: 'right' }} title={props.event.appointmentStatusCode} />}
                        {/* {status === 0 && <i className="ui icon calendar check outline btn m-0 p-0" style={{ float: 'right' }} />} */}
                        {/* {status === 1 && <i className="ui icon calendar check outline btn m-0 p-0" style={{ float: 'right' }} title="Checked In" />}
                        {status === 2 && <i className="ui icon stethoscope btn m-0 p-0" style={{ float: 'right' }} title="With Provider" />}
                        {status === 3 && <i className="ui icon calendar times outline btn m-0 p-0" style={{ float: 'right' }} title="Checked Out" />} */}
                        {(props.event.finalAmount > 0 && props.event.openBalance == 0 ) ? <i className="ui icon check btn m-0 p-0" style={{ float: 'right' }} title="Cashed Out" /> : null}
                        {props.event.lastEmailSent !== null && <i className="ui icon bell btn m-0 p-0" style={{ float: 'right' }} title={`Emailed on ${moment(props.event.lastEmailSent).format("M/D/YYYY H:mm A")}`} />}
                        {/* <span>{props.event.patientEmail}</span> */}
                        {props.resource !== "provider" ? <span>
                            {props.event.doctor && props.event.doctor.fullName}
                        </span> : null}
                        {props.event.firstName && <span>
                            {props.event.firstName} {props.event.lastName[0]}.
                        </span>}
                        {props.event.practiceServiceType && <span>
                            {props.event.practiceServiceType}
                        </span>}
                        {props.event.duration && <span>
                            {props.event.duration} minutes
                        </span>}
                        {props.resource !== "room" && props.event.room ? <span>
                            {props.event.room}
                        </span> : null}
                        {props.resource !== "equipment" && props.event.equipmentDescription ? <span>
                            {props.event.equipmentDescription}
                        </span> : null}
                        {/* {props.event.patientPhone && <span>
                            {props.event.patientPhone}
                        </span>} */}
                    </div>
                }
            />}
            {props.event.unavailableBlockId && < Popup
                on="click"
                content={UnavailableToolTip}
                position="top center"
                open={toolTip}
                onClose={() => { setToolTip(false) }}
                onOpen={() => { setToolTip(true) }}
                trigger={
                    <div className="event">
                        {props.event.reasonForVisit}
                    </div>
                }
            />}
            {!props.event.unavailableBlockId && !props.event.Id && <span className='event'>
                &nbsp;
            </span>}

            <ModalBox open={statusView} title="Status" requireConfirm onClose={() => { setStatusView(false); }} >
                <AppointmentStatus appointmentId={props.event.id} appointment={props.event} onStatusUpdated={onStatusUpdated} onClose={() => { setStatusView(false); }}  ></AppointmentStatus>
            </ModalBox>
            {/* <ModalBox open={providerInView} title="With Provider" requireConfirm onClose={() => { setProviderInView(false) }} onCloseSuccess={() => { doctorCheckIn() }} confirmButton="With Provider" cancelButton="Cancel">
                <span> Is the patient with a provider?</span>
            </ModalBox>
            <ModalBox open={checkOutView} title="Check Out"  requireConfirm onClose={() => { setCheckOutView(false) }} onCloseSuccess={() => { checkOut() }} confirmButton="Check Out" cancelButton="Cancel">
                <span>Are you sure you want to Check Out?</span>
            </ModalBox> */}
            <ModalBox open={deleteView} onClose={() => { setDeleteView(false) }}>
                <span>Are you sure you want to Cancel this Appointment?</span>
                <div className='field required mt-4'>
                    <label>Type of Cancellation</label>
                    <select className='form-select' value={cancelType} onChange={e => { e.preventDefault(); setCancelType(e.target.value); }}>
                        <option selected value={1}>Cancelled</option>
                        <option value={2}>No Show</option>
                    </select>
                </div>
                <div className='field required'>
                    <label>Reasons</label>
                    <input type="text" value={reason} onChange={e => { e.preventDefault(); setReason(e.target.value); }} />
                </div>
                <div className='d-flex justify-content-between mt-3'>
                    <div className='col-auto'><button className='btn btn-primary' onClick={e=>{e.preventDefault(); deleteEvent(); if (props.refresh()) { props.refresh() } }}>Confirm</button></div>
                    <div className='col-auto'><button className="btn btn-secondary" onClick={e=>{e.preventDefault(); setDeleteView(false) }}>Close</button></div>
                </div>
            </ModalBox>
            
            <ModalBox open={deleteUnavailable} onClose={() => { setDeleteUnavailable(false) }}>
                <span>Are you sure you want to Delete this Away Time?</span>
                <div className='d-flex justify-content-between mt-3'>
                    <div className='col-auto'><button className='btn btn-primary' onClick={e=>{e.preventDefault(); deleteUnavailableBlock(); if (props.refresh()) { props.refresh() } }}>Confirm</button></div>
                    <div className='col-auto'><button className="btn btn-secondary" onClick={e=>{e.preventDefault(); setDeleteUnavailable(false) }}>Close</button></div>
                </div>
            </ModalBox>

            <ModalBox open={copyUnavailable} onClose={() => { setCopyUnavailable(false) }}>
                <span>Are you sure you want to Copy this Away Time?</span>
                <div className='d-flex justify-content-between mt-3'>
                    <div className='col-auto'><button className='btn btn-primary' onClick={e=>{e.preventDefault(); copyUnavailableBlock(); if (props.refresh()) { props.refresh() } }}>Confirm</button></div>
                    <div className='col-auto'><button className="btn btn-secondary" onClick={e=>{e.preventDefault(); setCopyUnavailable(false) }}>Close</button></div>
                </div>
            </ModalBox>

            <ModalBox open={notifyView} onClose={() => { setNotifyView(false) }} title="Notify">
                <AppointmentNotify onClose={() => { setNotifyView(false) }} event={props.event} appointmentId={props.event.id} />
            </ModalBox>

            <ModalBox open={editUnavailableForm} onClose={() => { setEditUnavailableForm(false) }} onOpen={() => { setToolTip(false); setEditUnavailableForm(true) }} title="Edit Away Time">
                <UnavailableBlockForm unavailableBlockId={props.event.unavailableBlockId} onClose={() => { setEditUnavailableForm(false); if (props.refresh()) { props.refresh() } }} buttonLabel="Update" />
            </ModalBox>

            <ModalBox open={editForm} onClose={() => { setEditForm(false) }} onOpen={() => { setToolTip(false); setEditForm(true) }} title="Edit Appointment">
                <EditAppointmentForm id={props.event.parentGroupId || props.event.id} onClose={() => { setEditForm(false); if (props.refresh()) { props.refresh() } }} buttonLabel="Update" />
            </ModalBox>
            <ModalBox open={checkOutInvoice != null} onClose={()=>{setCheckOutInvoice(null);if (props.refresh()) { props.refresh() }}} size="fullscreen" title="Add Invoice">
                <AddInvoice initialData={checkOutInvoice} onClose={()=>{setCheckOutInvoice(null);if (props.refresh()) { props.refresh() }}} isModal/>
            </ModalBox>
            <ModalBox open={confirmCreateInvoice} title="Create Invoice"  requireConfirm onClose={() => { setConfirmCreateInvoice(false) }} onCloseSuccess={() => { checkOut(true) }} confirmButton="Create Invoice" cancelButton="Cancel">
                <span>Do you wish to create an invoice for the appointment at this time?</span>
            </ModalBox>
            <ModalBox open={showEditInvoice} onClose={() => setShowEditInvoice(false)} size="fullscreen">
                <EditInvoice id={props.event.invoiceId} pull={showEditInvoice} onClose={() => { if (props.refresh()) { props.refresh() }; return setShowEditInvoice(false) }} />
            </ModalBox>
            <ModalBox open={showPreviewInvoice} onClose={() => { setShowPreviewInvoice(false) }} title={`Invoice`}>
                <InvoicePreview invoiceId={props.event.invoiceId} />
            </ModalBox>
            <ModalBox open={patientCard} onClose={()=>{setPatientCard(false);}} size="fullscreen" title="Patient">
                <PatientCardWrapper patientId={props.event.patientId} />
            </ModalBox>
        </div>

    )
}

export default EventTile