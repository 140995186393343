import React, { useEffect, useState } from 'react'
import ModalBox from '../../../../templates/components/ModalBox'
import Table2 from '../../../../templates/components/Table2'
import LeadSourceService from '../../../../../services/api/lead-source.service'
import EditLead from './EditLead'
import Utilities from '../../../../../services/commonservice/utilities'
import AddPatient from '../../patient/add-patient/AddPatient'
import MatchedPatientTable from '../../settings-dashboard/patient-settings/matched-patient-table/MatchedPatientTable'
import HPTSelect from '../../../../templates/components/HPTSelect'
import CommonService from '../../../../../services/api/common.service'
import { LeadStatusEnum as leadStatusList } from '../../../../../common/enum/lead-status.enum';
import PatientCardWrapper from '../patient-list/patient-card/PatientCardWrapper'
import DatePicker from '../../../../templates/components/DatePicker'
import Module from '../../../../templates/components/Module'
import * as moment from 'moment';
const LeadSettings = (props) => {

    const [isLoader, setIsLoader] = useState(false)
    const [allList, setAllList] = useState()
    const [selectedRow, setSelectedRow] = useState()

    const [showMatchedPatient, setShowMatchedPatient] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [showAddPatient, setShowAddPatient] = useState(false)
    const [allUserList, setAllUserList] = useState()
    const [isLoader_User, setIsLoader_User] = useState()
    const hiddenColumns = ["action"]
    const [selectedAssignedTo, setSelectedAssignedTo] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [patientCard, setPatientCard] = useState()
    const [patientId, setPatientId] = useState()
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const userLookup = () => {
        setIsLoader_User(true)
        let reqObj = {
            isActive: true
        }
        CommonService.allUserLookup(reqObj)
            .then((response) => {
                setAllUserList(response.data)
            })
            .catch(error => {
                console.log(error)
            }).finally(() => {
                setIsLoader_User(false)
            })
    }
    const getAllLead = () => {
        setIsLoader(true);
        let params = {
            AssignedToId: selectedAssignedTo || null,
            Status: selectedStatus || null,
            StartDate: startDate ? moment(startDate).toISOString() : null,
            EndDate: endDate ? moment(endDate).toISOString() : null
        }
        LeadSourceService.getAllLead(params)
            .then((response) => {
                setAllList(response.data)
            })
            .catch(error => {
                console.log(error)
            }).finally(() => setIsLoader(false))
    }

    useEffect(() => {
        userLookup()
        getAllLead()
    }, [])

    const columns = [
        {
            id: "firstName",
            Header: "First Name",
            accessor: lead => lead.firstName
        },
        {
            id: "lastName",
            Header: "Last Name",
            accessor: lead => lead.lastName
        }, {
            id: "mobile",
            Header: "Phone",
            accessor: lead => Utilities.toPhoneNumber(lead.mobile)
        }, {
            id: "email",
            Header: "Email",
            accessor: lead => lead.email
        }, {
            id: "source",
            Header: "Source",
            accessor: lead => lead.source
        }, {
            id: "assignedToId",
            Header: "Assigned To",
            accessor: lead => lead.assignedToId ? (lead.assignedToFirstName + " " + lead.assignedToLastName) : null
        }, {
            id: "status",
            Header: "Status",
            accessor: lead => leadStatusList.find(obj => obj.value === lead.status)?.title ? leadStatusList.find(obj => obj.value === lead.status)?.title : lead.status
        }, {
            id: "comments",
            Header: "Comment",
            accessor: lead => lead.comments
        }, {
            id: "action",
            Header: "Action",
            disableSortBy: true,
            accessor: (lead, i) => {
                return (
                    <div className='btn-group'>
                        <button className="p-0 ps-1 btn btn-primary" title="Edit" onClick={e => { e.preventDefault(); setSelectedRow(lead); return setShowEdit(true) }}><i className="icon pencil" /></button>
                        {!lead.patientId &&
                            <button className="p-0 ps-1 btn btn-primary" title="Add New Patient" onClick={e => { e.preventDefault(); setSelectedRow(lead); return setShowConfirm(true) }}><i className="icon add" /></button>
                        }
                        {lead.patientId &&
                            <button className="p-0 ps-1 btn btn-primary" title="Patient Record" onClick={e => {
                                e.preventDefault();
                                setPatientId(lead.patientId);
                                setPatientCard(true)
                            }}><i className="icon vcard" /></button>
                        }
                    </div>
                )
            }
        }
    ]

    return (
        <>

            <div className='row d-flex g-3'>
                <div className='col-12 m-p-0'>
                    <Module title="Filters">
                        <div className='row d-flex'>
                            <div className='col-12 row d-flex mx-0 align-items-end report-filters'>
                                <DatePicker timePeriod={"all"} includeAll startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} />

                                <div className='col-md col-12'>
                                    <label>Assigned To</label>
                                    <HPTSelect
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={allUserList}
                                        name="assignedToId"
                                        isClearable={true}
                                        value={allUserList && selectedAssignedTo ? allUserList.find(obj => obj.id === selectedAssignedTo) : null}
                                        onChange={e => {
                                            if (e?.id) {
                                                setSelectedAssignedTo(e.id)
                                            } else {
                                                setSelectedAssignedTo(null)
                                            }
                                        }}
                                        getOptionLabel={(option) => `${option.contact?.name?.firstName} ${option.contact?.name?.lastName}`}
                                        getOptionValue={(option) => option.id}
                                        placeholder="All"
                                    />
                                </div>
                                <div className='col-md col-12'>
                                    <label>Status</label>
                                    <HPTSelect
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="status"
                                        isClearable={true}
                                        value={leadStatusList && selectedStatus ? leadStatusList.find(obj => obj.value === selectedStatus) : null}
                                        options={leadStatusList}
                                        onChange={e => {
                                            if (e?.value) {
                                                setSelectedStatus(e.value)
                                            } else {
                                                setSelectedStatus(null)
                                            }
                                        }}
                                        getOptionLabel={(option) => { return (option.title) }}
                                        placeholder="All"
                                    />
                                </div>

                                <div className="col-md-auto col-12">
                                    <button className="btn btn-primary" onClick={e => {
                                        e.preventDefault();
                                        setAllList();
                                        getAllLead()
                                    }}
                                        title="Pull Records">
                                        <i className="icon arrow circle right" />
                                    </button>
                                </div>

                            </div>
                        </div>
                    </Module>
                </div>
            </div>
            <div className='col-12 m-p-0'>
                <Module title="Leads">
                    <Table2
                        hiddenColumns={hiddenColumns}
                        export
                        searchable
                        data={allList}
                        columns={columns}
                        loading={isLoader || isLoader_User}
                        extraButtons={[
                            { className: 'btn btn-primary', children: <i className='icon searchengin' />, onClick: () => { setShowMatchedPatient(true) }, title: "Check For Patient Matches" }
                        ]}
                    />
                </Module>
            </div>
            <ModalBox open={showEdit} onClose={() => { setShowEdit(false) }} title="Edit Lead">
                <EditLead initialData={selectedRow} closeModal={setShowEdit} onClose={() => { getAllLead(); return setShowEdit(false) }} />
            </ModalBox>
            <ModalBox open={showMatchedPatient} onClose={() => { setShowMatchedPatient(false) }} title="Matched Patient" size={"large"}>
                <MatchedPatientTable closeModal={setShowMatchedPatient} onClose={() => { return setShowMatchedPatient(false) }} />
            </ModalBox>

            <ModalBox open={showConfirm} onClose={() => { setShowConfirm(false) }} onCloseSuccess={() => { setShowConfirm(false); setShowAddPatient(true) }} title="Confirm" confirmButton="Yes" cancelButton="Cancel">
                <span>Do you wish to create a new patient from this lead?</span>
            </ModalBox>
            <ModalBox open={showAddPatient} onClose={() => { setShowAddPatient(false) }} title="Add Patient">
                <AddPatient initialData={selectedRow} isConverted isModal onClose={() => { getAllLead(); return setShowAddPatient(false) }} />
            </ModalBox>
            <ModalBox open={patientCard} onClose={() => { setPatientId(); setPatientCard(false); }} size="fullscreen" title="Patient">
                <PatientCardWrapper patientId={patientId} />
            </ModalBox>
        </>
    )
}

export default LeadSettings