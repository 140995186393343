import moment from "moment";
import React, { useState, useEffect } from "react";
import ModalBox from "./components/ModalBox";
import Table2 from "./components/Table2";
import EmailTemplates from './EmailTemplates.json'
import TextTemplates from './TextTemplates.json'
import defaultLogo from '../../../assets/images/logo_login.png'
import Utilities from "../../services/commonservice/utilities";
const EmailSMSTemplates = (props) => {
    const [view, setView] = useState(false)
    const [viewData, setViewData] = useState()
    const print = () => {
        Utilities.printWindow("preview", "Notification Preview")
    }
    const columnsSMS = [
        {
            id: "TemplateName",
            Header: "Template",
            accessor: cell=>cell.TemplateName
        },
        {
            id: "Description",
            Header: "Description",
            accessor: cell=>cell.Description
        },
        {
            id: "action",
            Header: "Actions",
            disableSortBy: true,
            textAlign: 'center',
            accessor: (cell) => {
                // console.log(cell);
                return (<span className="w-100 d-flex justify-content-center">
                    <div className="col-auto btn-group">
                        <button title="View Notification" className="btn btn-primary" onClick={e => { e.preventDefault(); setViewData(cell); return setView(true) }}><i className="icon eye" /></button>
                    </div>
                </span>)
            }
        },
    ]

    const columnsEmail = [
        {
            id: "TemplateName",
            Header: "Template",
            accessor: cell=>cell.TemplateName
        },
        {
            id: "Description",
            Header: "Description",
            accessor: cell=>cell.Description
        },
        {
            id: "Subject",
            Header: "Subject",
            accessor: cell=>cell.Subject
        },
        {
            id: "userType",
            Header: "User Type",
            accessor: (cell)=> cell.UserType === 0?'Patient':cell.UserType===1?'Practice':cell.UserType===2?'Global':null
        },
        {
            id: "action",
            Header: "Actions",
            disableSortBy: true,
            textAlign:'center',
            accessor: (cell) => {
                // console.log(cell);
                return (<span className="w-100 d-flex justify-content-center">
                    <div className="col-auto btn-group">
                        <button title="View Notification" className="btn btn-primary" onClick={e => { e.preventDefault(); setViewData(cell); return setView(true) }}><i className="icon eye" /></button>
                    </div>
                </span>)
            }
        },
    ]


    return (
        <div className="d-flex row justify-content-center m-5 p-3">
            <div className="card p-3 col-12 mb-3">
                <h5 className="primary-header d-flex align-items-center">Text Templates</h5>
                <Table2 export searchable data={TextTemplates} columns={columnsSMS} />
            </div>
            <div className="card p-3 col-12">
                <h5 className="primary-header d-flex align-items-center">Email Templates</h5>
                <Table2 export searchable data={EmailTemplates} columns={columnsEmail} />
            </div>
            <ModalBox open={view} onClose={() => { setView(false) }} title={viewData?.Subject || viewData?.Description}>
                <div className="row d-flex justify-content-end mb-3">
                    <div className="col-auto">
                        <button onClick={e => { e.preventDefault(); print() }} className="btn btn-primary" text="Print Preview"><i className="icon print" /></button>
                    </div>
                </div>
                <div className="card p-3" id="preview">
                    {viewData?.Subject && <h5 className="primary-header d-flex align-items-center">Subject: {viewData?.Subject}</h5>}
                    <div dangerouslySetInnerHTML={{ __html: viewData?.Body?.replace("https://s3.us-east-2.amazonaws.com/hellopaymentui/assets/images/bottom_bar.png", "https://admin.revique.io/assets/images/bottom_bar.png")?.replace("https://s3.us-east-2.amazonaws.com/hellopaymentui/assets/images/logo_header.png", defaultLogo)?.replace("[[providerLogoUrl]]", defaultLogo)?.replace('[[ProviderLogoUrl]]',defaultLogo) || viewData?.SMS }} />
                </div>
            </ModalBox>
        </div>
    );
};

export default EmailSMSTemplates;
