import React, { useState } from 'react'
import DimLoader from '../../../../templates/components/DimLoader'
import APIResponse from '../../../../templates/components/APIResponse'
import FormFormBuilder from '../form-form-builder/FormFormBuilder'
import FormsService from '../../../../../services/api/forms.service'

const EditForm = (props) => {
    const [isLoader, setIsLoader] = useState(false)
    const [apiResponse, setApiResponse] = useState()
    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }
    const saveForm = (data,form, isLinked,isEditable) => {
        setIsLoader(true);
        let reqObj = {
            formId: data.id,
            formDescription: data.formDescription,
            formTitle: data.formTitle,
            fields: data.fields,
            isEditable: isEditable
        };
        FormsService.editForm(reqObj).then(res => {
            setApiResponse(res)
        })
            .catch(err => {
                setApiResponse(err)
            }).finally(() => {
                setIsLoader(false)
            })
    }
    return (
        <>
            {isLoader && <DimLoader />}
            <FormFormBuilder
                saveText="Update"
                submitHandler={saveForm}
                onClose={props.onClose}
                form={props?.form}
                isEdit
                userType={props?.userType}
            />
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </>
    )
}

export default EditForm