import React, { useState, useEffect } from 'react'
import ManufacturerService from '../../../../../../../../services/api/manufacturer.service';
import APIResponse from '../../../../../../../templates/components/APIResponse';
import ManufacturerForm from '../manufacturer-form/ManufacturerForm';

const ManufacturerEdit = (props) => {
    const { embed, id, exitEdit, inputChange, refresh } = props;
    const [formData, setFormData] = useState({})
    const [messages, setMessages] = useState({})
    const [isLoaded_form, setIsLoaded_form] = useState(false)
    const [apiResponse,setApiResponse] = useState()
    
    useEffect(() => {
        setIsLoaded_form(false)
        ManufacturerService.getManufacturerById({id:props.id})
            .then(res => {
                setFormData(res)
                return setIsLoaded_form(true)
            })
            .catch(err => { console.log(err); setIsLoaded_form(true) })
    }, [])

    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }

    const submitHandler = (data) => {
        let reqObj = {}
        reqObj.manufacturer = data.manufacturer;
        reqObj.manufacturerId = data.manufacturerId;
        // setIsLoader(true)
        ManufacturerService.editManufacturer(reqObj)
            .then(response => {
                setApiResponse(response)
            })
            .catch(error => {
                setApiResponse(error)
            })
    }

    return (
        <div>
            {isLoaded_form && <ManufacturerForm submitHandler={submitHandler} initialData={formData} messages={messages} inputChange={inputChange}
             submitLabel="Update" loaded={isLoaded_form} onClose={() => props.onClose()} />}    
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true}/>    
        </div>
    )
}

export default ManufacturerEdit