import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import Utilities from "../../../../../../../services/commonservice/utilities";
import Table2 from "../../../../../../templates/components/Table2";
import APIResponse from "../../../../../../templates/components/APIResponse";
import { store } from "../../../../../../../context/StateProvider";
import PatientService from "../../../../../../../services/api/patient.service";

const MembershipList = (props) => {
  const [memberships, setMemberships] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [apiResponse, setApiResponse] = useState()
  const globalStateAndDispatch = useContext(store)
  const state = globalStateAndDispatch.state
  const onSuccess = (message) => {
    setMemberships(apiResponse.data);
    if (props.refresh) {
      props.refresh()
    }
    if (props.onClose) {
      props.onClose()
    }
    if (props.exitHandler) {
      props.exitHandler()
    }
  }

  useEffect(() => {
      membershipsLookup();
  }, []);

  const membershipsLookup = () => {
    setIsLoader(true);
    let reqObj = {
      patientId: props.patientId,
      practiceLocationId: state.practiceLocationId || '',
    };
    PatientService.getAllMembership(reqObj).then((res) => {
      setApiResponse(res)
      return setIsLoader(false);
    });
  };

  const tableColumns = [
    {
      id: "membershipName",
      Header: "Membership Name",
      accessor: (cell) => cell.membershipName
    },
    {
      id: "name",
      Header: "Name",
      accessor: (cell) => cell.name
    },
    {
      id: "lastActivityDate",
      Header: "Last Activity Date",
      accessor: (cell) => moment(cell.lastActivityDate).format("MM/DD/YYYY h:mm a"),
    },
    {
      id: "lastActivityDescription",
      Header: "Last Activity Description",
      accessor: (cell) => cell.lastActivityDescription
    },
    {
      id: "lastActivityQuantity",
      Header: "Last Activity Quantity",
      accessor: (cell) => cell.lastActivityQuantity
    },
    {
      id: "balance",
      Header: "Balance",
      accessor: (cell) => cell.balance,
    },
  ];

  return (
    <div title="Memberships" className="py-3">
      <Table2
        data={memberships}
        columns={tableColumns}
        loading={isLoader}
      />
      <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={false} />
    </div>
  );
};

export default MembershipList;
