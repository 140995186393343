import React, { useState, useEffect } from 'react'
import MembershipForm from '../membership-form/MembershipForm'
import APIResponse from '../../../../../../../templates/components/APIResponse'
import ProductService from '../../../../../../../../services/api/product.service'
import moment from 'moment'

const MembershipAdd = (props) => {
    const { embed, refresh, exitHandler } = props;
    const [formData, setFormData] = useState({ })
    const [messages, setMessages] = useState({})
    const [isLoader, setIsLoader] = useState(false)
    const [apiResponse,setApiResponse] = useState()

    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }
    const submitHandler = (data,items) => {
            let reqObj = { ...data }
            reqObj.itemType = 5;
            reqObj.membershipInstructions = reqObj.membershipInstructions || ""
            reqObj.membershipItems = items.filter(x => x.itemId !== null).map(x => {
                if(x.priceOverride && (x.unitPrice === "" || x.unitPrice == null)){
                    x.unitPrice = 0
                }
                else if (!x.priceOverride)
                {
                    x.unitPrice = null
                }
                if(x.discountOverride && (x.discount === "" || x.discount == null)){
                    x.discount = 0
                }
                else if (!x.discountOverride)
                {
                    x.discount = null
                }
                return x
            })
            reqObj.membershipOfferBeginsOn = moment(reqObj.membershipOfferBeginsOn).startOf('d').toISOString()
            reqObj.membershipOfferEndsOn = moment(reqObj.membershipOfferEndsOn).startOf('d').toISOString()
            setIsLoader(true)
            ProductService.addProduct(reqObj)
                .then(response => {
                    setApiResponse(response)
                    clearForm()
                    setIsLoader(false)
                })
                .catch(error => {
                    setApiResponse(error)
                    setIsLoader(false)
                })
    }

    const clearForm = () => {
        setFormData({ })
    }
    return (
        <div>
            <MembershipForm  submitHandler={submitHandler} onClose={()=>props.onClose()} initialData={formData} isLoader={isLoader} messages={messages} />
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true}/>
        </div>
    )
}

export default MembershipAdd