import React, { useEffect, useState, useContext } from 'react'
import label from '../../../../../../../../assets/i18n/en.json'
import PracticeServiceTypeService from '../../../../../../../services/api/practice-service-type.service';
import ProviderServicesService from '../../../../../../../services/api/provider-services.service';
import APIResponse from '../../../../../../templates/components/APIResponse';
import MessageSetting from '../../../../../../../common/constants/message-setting.constant';
import DimLoader from '../../../../../../templates/components/DimLoader';
const ProviderServicesTable = (props) => {
    const [services, setServices] = useState()
    const [isLoader, setIsLoader] = useState(false)
    const [isLookupLoader, setIsLookupLoader] = useState(false)
    const [serviceList, setServiceList] = useState()
    const [apiResponse, setApiResponse] = useState()
    const [successMessage, setSuccessMessage] = useState()

    const onSuccess = (message) => {
        pullServices()
    }
    useEffect(() => {
        pullServices()
    }, [])

    const pullServices = () => {
        setServiceList()
        setServices()
        setIsLookupLoader(true)
        ProviderServicesService.findServicesByProvider(props.id)
            .then(res => {
                let selectedServices = res.data
                setServices(selectedServices)
                PracticeServiceTypeService.practiceServiceTypeLookup()
                    .then(
                        (response) => {
                            let allServiceTypes = response.data
                            selectedServices.forEach(selectedService => {
                                allServiceTypes.forEach(obj => {
                                    if (obj.practiceServiceTypeId == selectedService.practiceServiceTypeTypeDetails.practiceServiceTypeId) {
                                        obj.providerHas = 1
                                    }
                                })
                            })
                            //console.log(allServiceTypes.filter(obj => obj.providerHas == 1))
                            return setServiceList(allServiceTypes.filter(obj => obj.providerHas != 1))
                        })
                    .catch((error) => {
                    })
            })
            .finally(() => {
                setIsLookupLoader(false)
            });
    }

    const removeService = (service) => {
        setIsLoader(true)
        let reqObj = {
            practiceServiceTypeId: service.practiceServiceTypeTypeDetails.practiceServiceTypeId,
            practitionerServiceTypeId: service.practitionerServiceTypeId,
            doctorId: props.id
        }
        ProviderServicesService.deleteProviderServiceById(reqObj)
            .then(res => {
                setSuccessMessage(MessageSetting.ProviderService.delete)
                setApiResponse(res)
            })
            .catch(err => {
                setApiResponse(err)
            })
            .finally(() => {
                setIsLoader(false)
            });
    }
    const addService = (practiceServiceTypeId) => {
        setIsLoader(true)
        let reqObj = { practiceServiceTypeId: practiceServiceTypeId, doctorId: props.id }
        ProviderServicesService.addProviderService(reqObj)
            .then(res => {
                setSuccessMessage(MessageSetting.ProviderService.add)
                setApiResponse(res)
            })
            .catch(err => {
                setApiResponse(err)
            })
            .finally(() => {
                setIsLoader(false)
            });
    }
    return (
        <>
            {isLoader || isLookupLoader ? <DimLoader loadMessage={isLookupLoader ? "Loading..." : label.common.processing} /> : null}
            <div className='d-flex row'>
                <div className='col-md-8 col-12'>
                    <div className='card'>
                        <div className='card-header'>Active Services</div>
                        <div className='card-body row d-flex'>
                            {services && services.map(service => {
                                return (
                                    <div className='col-md-3 col-12'>
                                        <span title="Remove Service" className='text-start point' onClick={e => { e.preventDefault(); removeService(service) }}><i className='icon minus small' /> {service?.practiceServiceTypeTypeDetails?.practiceServiceType} </span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='col-md-4 col-12'>
                    <div className='card'>
                        <div className='card-header'>Inactive Services</div>
                        <div className='card-body row d-flex'>
                            {serviceList && serviceList.map(service => {
                                return (
                                    <div className='col-md-6 col-12'>
                                        <span title="Add Service" className='text-start point' onClick={e => { e.preventDefault(); addService(service.practiceServiceTypeId) }}><i className='icon plus small' /> {service?.practiceServiceType}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} successMessage={successMessage} />
        </>
    )
}

export default ProviderServicesTable