import moment from 'moment'
import React, { useState, useEffect } from 'react'
import FormsService from '../../../../../../services/api/forms.service'
import ModalBox from '../../../../../templates/components/ModalBox'
import Module from '../../../../../templates/components/Module'
import Table2 from '../../../../../templates/components/Table2'
import EditForm from '../../../forms/edit-form/EditForm'
import AddForm from '../../../forms/add-form/AddForm'
import APIResponse from '../../../../../templates/components/APIResponse'
import { Icon, IconGroup } from 'semantic-ui-react'
import URLForm from '../../../forms/url-form/URLForm'
import DimLoader from '../../../../../templates/components/DimLoader'
import PreviewForm from '../../../forms/preview-form/PreviewForm'
import SendPublicFormEmail from '../../../forms/send-public-form-email/SendPublicFormEmail'
import ViewPublicFormSubmissions from '../../../forms/view-public-form-submissions/ViewPublicFormSubmissions'
import StorageService from '../../../../../../services/session/storage.service'

const FormsManagement = (props) => {
    const [loader, setLoader] = useState(false)
    const [formsList, setFormsList] = useState()
    const [selectedForm, setSelectedForm] = useState()
    const [showForm, setShowForm] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showAdd, setShowAdd] = useState(false)
    const [showPublicURL, setShowPublicURL] = useState(false)
    const [apiResponse, setApiResponse] = useState()
    const [statusModal, setStatusModal] = useState(false);
    const [showCopy, setShowCopy] = useState(false);
    const [isLoader, setIsLoader] = useState(false)
    const [sendEmail, setSendEmail] = useState(false)
    const [viewSubmissions, setViewSubmissions] = useState(false)
    const [userType, setUserType] = useState(1)
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    
    const onSuccess = (message) => {
        pullForms()
    }
    const pullForms = () => {
        setLoader(true)
        let reqObj = {
            SortField:'modifiedDate',
            Asc:false
        }
        FormsService.getFormsList(reqObj)
            .then(res => {
                setFormsList(res?.data)
                setLoader(false)
            })
    }

    useEffect(() => {
        let data = JSON.parse(StorageService.get('session', 'userDetails'))
        setUserType(data?.userType)
        pullForms()
    }, [])

    const statusHandler = () => {
        setStatusModal(false);
        setIsLoader(true)
        let reqObj = {
            formId: selectedForm.id,
            isActivated: selectedForm.isActivated === 1 ? 0 : 1,
        };
        FormsService.editForm(reqObj).then((response) => {
            setApiResponse(response);
        }).catch(error => {
            setApiResponse(error);
        })
            .finally(() => {
                setIsLoader(false)
            });
    };

    const deleteForm = () => {
        setDeleteConfirm(false);
        setIsLoader(true)
        FormsService.deleteForm(selectedForm.id).then((response) => {
            setApiResponse(response);
        }).catch(error => {
            setApiResponse(error);
        })
            .finally(() => {
                setIsLoader(false)
            });
    };
    // to generate public form url for older forms
    const callCreatePublicURL = (selectedForm) => {
        setIsLoader(true);
        const reqObj = {
            fields: selectedForm.fields,
            formDescription: selectedForm.formDescription,
            formId: selectedForm.id,
            formTitle: selectedForm.formTitle,
            isLink: selectedForm.isLink,
        };
        FormsService.editForm(reqObj).then(res => {
            setApiResponse(res)
        })
            .catch(err => {
                setApiResponse(err)
            }).finally(() => {
                setIsLoader(false)
            })
    }

    const createFormCopy = (data) => {
        setShowCopy(false)
        setIsLoader(true);
        let reqObj = {
            formDescription: data.formDescription,
            formTitle: `${data.formTitle}-Copy`,
            status: 0,
            fields: data.fields,
            isEditable: data.isEditable
        }
        FormsService.addForm(reqObj).then(res => {
            setApiResponse(res)
        })
            .catch(err => {
                setApiResponse(err)
            }).finally(() => {
                setIsLoader(false)
            })
    }
    const columns = [
        {
            id: "formTitle",
            Header: "Title",
            accessor: form => form.formTitle
        },
        {
            id: "createdDate",
            Header: "Created On",
            accessor: (form) => moment(form.createdDate).format("MM/DD/YYYY")
        },
        {
            id: "modifiedDate",
            Header: "Modified On",
            accessor: (form) => moment(form.modifiedDate).format("MM/DD/YYYY")
        },
        {
            id: "actions",
            Header: "Actions",
            textAlign: 'center',
            disableSortBy: true,
            accessor: (form) => {
                return (<div className='d-flex justify-content-center'>
                    <div className='btn-group'>
                        <button className='btn btn-primary' title="Edit Form" onClick={e => { e.preventDefault(); setSelectedForm(form); return setShowEdit(true) }} disabled={userType===1 ? !form?.isEditable : false}><i className='icon pencil' /></button>
                        
                        <button className='btn btn-primary' title="View Form" onClick={e => { e.preventDefault(); setSelectedForm(form); return setShowForm(true) }}><i className='icon eye' /></button>
                        {userType===1 && <><button className='btn btn-primary' title="Create Copy" onClick={e => { e.preventDefault(); setSelectedForm(form); return setShowCopy(true) }} disabled={!form?.isEditable}><i className='icon copy' /></button>
                        {!form.publicUrl && <button className='btn btn-primary' title="Create public form URL & QR code" onClick={e => { e.preventDefault(); callCreatePublicURL(form) }}>
                            <IconGroup >
                                <Icon name='qrcode' />
                                <Icon corner='top right' name='add' color='black' />
                            </IconGroup>
                        </button>}
                        {form.publicUrl &&
                            <button className='btn btn-primary'
                                title="Show public form URL & QR code"
                                onClick={e => { e.preventDefault(); setSelectedForm(form); return setShowPublicURL(true) }}>
                                <i className='icon qrcode' />
                            </button>}
                        <button className='btn btn-primary'
                            title="Send Email"
                            onClick={e => { e.preventDefault(); setSelectedForm(form); return setSendEmail(true) }}
                            disabled={!form?.publicUrl}
                        >
                            <i className='icon mail' />
                        </button>
                        <button className='btn btn-primary'
                            title="View Public Submissions"
                            onClick={e => { e.preventDefault(); setSelectedForm(form); return setViewSubmissions(true) }}
                            disabled={!form?.publicUrl}
                        >
                            <IconGroup >
                                <Icon name='file alternate' />
                                <Icon corner='bottom right' name='check' color='green' />
                            </IconGroup>
                        </button></>}
                        <button
                            className="btn btn-primary"
                            title={
                                form.isActivated === 1 ? "Deactivate Form" : "Activate Form"
                            }
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedForm(form);
                                return setStatusModal(true);
                            }}
                        >
                            {form.isActivated === 1 ? <i className="icon dont" /> : <i className="icon check" />}
                        </button>
                        {userType===2 && <button className='btn btn-primary'
                            title="Delete Form"
                            onClick={e => { e.preventDefault(); setSelectedForm(form); return setDeleteConfirm(true) }}>
                            <i className='icon delete' />
                        </button>}
                    </div>
                </div>
                )
            }
        },
        {
            id: "isActivated",
            Header: "Active",
            textAlign: 'center',
            disableSortBy: true,
            accessor: (form, i) =>
                form.isActivated === 1 ? (
                    <span className="btn btn-success text-white w-100">Active</span>
                ) : (
                    <span className="btn btn-danger text-white w-100">Inactive</span>
                ),
        },
    ];
    return (
        <>{isLoader && <DimLoader />}
            <div className='row d-flex'>
                <div className='col-12'>
                        <Table2 export searchable data={formsList} loading={loader} columns={columns}
                            extraButtons={[
                                {
                                    className: 'btn btn-primary mt-auto',
                                    children: <i className="icon plus" />,
                                    title: "Add Form",
                                    onClick: () => { setShowAdd(true) }
                                }
                            ]}
                        />
                </div>
                <ModalBox title={selectedForm?.formTitle + " Preview"} open={showForm} onClose={() => { setShowForm(false) }}>
                    <PreviewForm form={selectedForm} onClose={() => { setShowForm(false) }} />
                </ModalBox>
                <ModalBox title="Edit Form" open={showEdit} onClose={() => { setSelectedForm(); return setShowEdit(false) }} size="fullscreen">
                    <EditForm userType={userType} form={selectedForm} onClose={() => { setSelectedForm(); return setShowEdit(false) }} refresh={() => pullForms()} />
                </ModalBox>
                <ModalBox title="Add Form" open={showAdd} onClose={() => { setSelectedForm(); return setShowAdd(false) }} size="fullscreen">
                    <AddForm userType={userType} onClose={() => { setSelectedForm(); return setShowAdd(false) }} refresh={() => pullForms()} />
                </ModalBox>
                <ModalBox title="Form URL & QR code" open={showPublicURL} onClose={() => { setSelectedForm(); return setShowPublicURL(false) }} size="small">
                    <URLForm form={selectedForm} onClose={() => { setSelectedForm(); return setShowPublicURL(false) }} />
                </ModalBox>
                <ModalBox title="Send Email" open={sendEmail} onClose={() => { setSelectedForm(); return setSendEmail(false) }} size="small">
                    <SendPublicFormEmail form={selectedForm} onClose={() => { setSelectedForm(); return setSendEmail(false) }} />
                </ModalBox>
                <ModalBox title="View Public Submissions" open={viewSubmissions} onClose={() => { setSelectedForm(); return setViewSubmissions(false) }}>
                    <ViewPublicFormSubmissions form={selectedForm} onClose={() => { setSelectedForm(); return setViewSubmissions(false) }} />
                </ModalBox>
                <ModalBox open={showCopy} onClose={() => setShowCopy(false)} title={"Confirm"}>
                    <div className="row d-flex justify-content-between align-items-center">
                        <div className="col">
                            <p>Are you sure, you would like to create a copy of this form?</p>
                        </div>
                        <div className="col-auto">
                            <button
                                className="btn btn-primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    createFormCopy(selectedForm);
                                }}
                            >
                                OK
                            </button>
                            <button
                                className="btn btn-secondary ms-3"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowCopy(false);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </ModalBox>
                <ModalBox open={deleteConfirm} onClose={() => setDeleteConfirm(false)} title={"Confirm"}>
                    <div className="row d-flex justify-content-between align-items-center">
                        <div className="col">
                            <p>Are you sure, you would like to delete form {selectedForm?.formTitle}?</p>
                        </div>
                        <div className="col-auto">
                            <button
                                className="btn btn-primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    deleteForm();
                                }}
                            >
                                Confirm
                            </button>
                            <button
                                className="btn btn-secondary ms-3"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setDeleteConfirm(false);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </ModalBox>
                <ModalBox open={statusModal} onClose={() => setStatusModal(false)} title={selectedForm?.isActivated === 1 ? "Deactivate Form" : "Activate Form"}>
                    <div className="row d-flex justify-content-between align-items-center">
                        <div className="col">
                            {selectedForm?.isActivated === 1 ? (
                                <p>Would you like to deactivate {selectedForm?.formTitle}</p>
                            ) : (
                                <p>Would you like to activate {selectedForm?.formTitle} </p>
                            )}
                        </div>
                        <div className="col-auto">
                            <button
                                className="btn btn-primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    statusHandler();
                                }}
                            >
                                OK
                            </button>
                            <button
                                className="btn btn-secondary ms-3"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setStatusModal(false);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </ModalBox>
                <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
            </div>
        </>
    )
}
export default FormsManagement