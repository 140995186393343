import axios from 'axios'
import AppSetting from '../../common/constants/appsetting.constant'
import CommonService from './common.service'

const CreditBalanceService = {
    lookupCreditBalance(reqObj){
        let loggedInUserData = CommonService.getLoggedInData()
        let url= AppSetting.creditBalance.lookupBalance.replace('{parentId}',loggedInUserData.parentId)
        if(reqObj){
            url=`${url}${CommonService.buildQuery(reqObj)}`
        }
        return axios.get(url)
        .then(res=>{
            return res
        })
        .catch(err=>{
            return err
        })
    },
    createCreditBalance(reqObj){
        let loggedInUserData = CommonService.getLoggedInData()
        let url= AppSetting.creditBalance.createBalance.replace('{parentId}',loggedInUserData.parentId)
        return axios.post(url, reqObj)
        .then(res=>{
            return res
        })
        .catch(err=>{
            return err
        })
    },
    updateCreditBalance(reqObj){
        let loggedInUserData = CommonService.getLoggedInData()
        let url= AppSetting.creditBalance.updateBalance.replace('{parentId}',loggedInUserData.parentId)
        return axios.put(url, reqObj)
        .then(res=>{
            return res
        })
        .catch(err=>{
            return err
        })
    }
}

export default CreditBalanceService