import React, { useEffect, useState, useContext } from 'react'
import moment from "moment";
import Utilities from '../../../services/commonservice/utilities'
import { store } from './../../../context/StateProvider'
import Select from 'react-select'

const DatePicker = (props) => {
    const state = useContext(store).state
    const locationList = state.practiceLocations
    const [locationId, setLocationId] = useState(state.practiceLocationId || null)
    const [timePeriod, setTimePeriod] = useState(props.timePeriod || "day")

    const [month, setMonth] = useState(moment(props.startDate).month())
    const [year, setYear] = useState(moment(props.startDate).year())
    const [quarter, setQuarter] = useState(moment(props.startDate).quarter())

    const [startDate, setStartDate] = useState(Utilities.toDate(props.startDate))
    const [endDate, setEndDate] = useState(Utilities.toDate(props.endDate))

    const [displayStartDate, setDisplayStartDate] = useState()
    const [displayEndDate, setDisplayEndDate] = useState()

    const updateLocation = (newLocationId) => {
        setLocationId(newLocationId);
        props.setLocationId(newLocationId);
    }

    const checkStartAndEndDates = () => {

        if (timePeriod !== "all" && !moment(displayStartDate).isValid) return; 

        let newStartDate = timePeriod === "all" ? null : moment(displayStartDate).startOf("D");
        let newEndDate = timePeriod === "all" ? null : moment(displayEndDate).endOf("D");

        // TODO needs to be the time zone of the practice!!!!!

        if (timePeriod === "all") { 
            setStartDate();
            setDisplayStartDate();
            setEndDate();
            setDisplayEndDate();
            return;
        }
        else if (timePeriod === "day"){
            // Day
            //     StartDate is beginning of StartDate
            //     EndDate is the end of the day of StartDate
            newStartDate = moment(displayStartDate).startOf("D")
            newEndDate = moment(displayStartDate).endOf("D")
        }
        else if (timePeriod === "week"){
            // Week
            //     StartDate is the first day of the week of the StartDate selected
            //     StartDate is the last day of the week of the StartDate selected
            newStartDate = moment(displayStartDate).startOf("W"); 
            newEndDate = moment(displayStartDate).endOf('W');
        }
        else if (timePeriod === "month"){
            // Month
            //     StartDate is the first of the month of the month and year selected
            //     EndDate is the last day of the month of the month and year selected
            let newDate = new Date(year, month, 1); 
            newStartDate = moment(newDate).startOf("M"); 
            newEndDate = moment(newDate).endOf('M');
        }
        else if (timePeriod === "quarter"){
            // Quarter
            //     StartDate is the first day of the first month of the quarter and year selected
            //     EndDate is the last day of the last month of the quarter and year selected
            let newMonth;
            if (quarter == 1) newMonth = 0;
            if (quarter == 2) newMonth = 3;
            if (quarter == 3) newMonth = 6;
            if (quarter == 4) newMonth = 9;
            let newDate = new Date(year, newMonth, 1); 
            newStartDate = moment(newDate).startOf("quarter");
            newEndDate = moment(newDate).endOf("quarter").endOf("M");
        }
        else if (timePeriod === "year"){
            // Year
            //     StartDate is January 1 of the year selected
            //     EndDate is December 31 of the year selected
            let newDate = new Date(year, 1, 1); 
            newStartDate = moment(newDate).startOf('year'); 
            newEndDate = moment(newDate).endOf('year');
        }
        else {
            // need to check of display is different.  If it is use it instead
        }

        if (moment(newStartDate).isValid) {
            if (moment(newStartDate).diff(startDate, 'minutes') != 0) {
                setStartDate(newStartDate);
            }

            let newDisplayStartDate = moment(newStartDate).format('YYYY-MM-DD');
            if (!displayStartDate || newDisplayStartDate != displayStartDate) {
                setDisplayStartDate(newDisplayStartDate);
            }
    
        }

        if (moment(newEndDate).isValid) {
            if (moment(newEndDate).diff(endDate, 'minutes') != 0) {
                setEndDate(newEndDate);
            }

            let newDisplayEndDate = moment(newEndDate).format('YYYY-MM-DD');
            if (!displayEndDate || newDisplayEndDate != displayEndDate) {
                setDisplayEndDate(newDisplayEndDate);
            }
        }

    }

    useEffect(() => {
        if (startDate) {
            if (props.setStartDate) props.setStartDate(moment(startDate).startOf("D"))
            let newDisplayStartDate = moment(startDate).format('YYYY-MM-DD');
            if (!displayStartDate || newDisplayStartDate != displayStartDate) {
                setDisplayStartDate(newDisplayStartDate);
            }
        }
        else {
            if (props.setStartDate) 
                props.setStartDate()
            setDisplayStartDate();
        }
    }, [startDate]) 

    useEffect(() => {
        if (endDate) {
            if (props.setEndDate) props.setEndDate(moment(endDate).endOf("D"))
            let newDisplayEndDate = moment(endDate).format('YYYY-MM-DD');
            if (!displayEndDate || newDisplayEndDate != displayEndDate) {
                setDisplayEndDate(newDisplayEndDate);
            }
        }
        else {
            if (props.setEndDate) 
                props.setEndDate()
            setDisplayEndDate();
        }
    }, [endDate]) 

    useEffect(() => {
        if (props.setTimePeriod) props.setTimePeriod(timePeriod)
    }, [timePeriod]) 

    useEffect(() => {
        checkStartAndEndDates()
    }, [quarter,month,timePeriod])
    useEffect(() => {
        if (year<2015 || year>2055) return;
        checkStartAndEndDates()
    }, [year])

    const changeTimePeriod = (newTimePeriod) => {

        if (props.setSlabBy) {
            switch (newTimePeriod) {
                case 'year':
                    props.setSlabBy("M")
                    break;
                case 'quarter':
                    props.setSlabBy("M")
                    break;
                case 'month':
                    props.setSlabBy("W")
                    break;
                case 'day':
                    props.setSlabBy("H")
                    break;
                case 'week':
                    props.setSlabBy("D")
                    break;
                default:
                    props.setSlabBy("D")
                    break;
            }
        }

        let newDate = Utilities.toDate(new Date())
        setDisplayStartDate(newDate)

        if (newTimePeriod === "month"){
            let newMonth = moment(newDate).month(); 
            setMonth(newMonth); 
        }
        else if (newTimePeriod === "quarter"){
            let newQuarter = moment(newDate).quarter(); 
            setQuarter(newQuarter); 
        }
        else if (newTimePeriod === "year"){
            let newYear = moment(newDate).year();
            setYear(newYear); 
        }

        setTimePeriod(newTimePeriod)

    }

    return (
        <>
            <div className='col-md-auto col-12'>
                <div className='ui field'>
                    <label>Time Period</label>
                    <select className='form-select' value={timePeriod} onChange={e => { e.preventDefault(); changeTimePeriod(e.target.value); }}>
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="quarter">Quarter</option>
                        <option value="year">Year</option>
                        <option value="custom">Custom</option>
                        {props.includeAll && <option value="all">All</option>}
                    </select>
                </div>
            </div>
            {timePeriod === 'quarter' || timePeriod === 'year' || timePeriod === 'month' ? <div className='col-md-auto col-12'>
                <div className='ui field'>
                    <label>Year</label>
                    <input type="number" value={year} onChange={e => { e.preventDefault(); setYear(e.target.value); }} />
                </div>
            </div> : null}
            {timePeriod === 'quarter' && <div className='col-md-auto col-12'>
                <div className='ui field'>
                    <label>Quarter</label>
                    <select className="form-select" value={quarter} onChange={e => { e.preventDefault(); setQuarter(parseInt(e.target.value)); }}>
                        <option value={1}>Q1</option>
                        <option value={2}>Q2</option>
                        <option value={3}>Q3</option>
                        <option value={4}>Q4</option>
                    </select>
                </div>
            </div>}
            {timePeriod === 'month' && <div className='col-md-auto col-12'>
                <div className='ui field'>
                    <label>Month</label>
                    <select className="form-select" value={month} onChange={e => { e.preventDefault(); setMonth(parseInt(e.target.value)); }}>
                        <option value={0}>January</option>
                        <option value={1}>February</option>
                        <option value={2}>March</option>
                        <option value={3}>April</option>
                        <option value={4}>May</option>
                        <option value={5}>June</option>
                        <option value={6}>July</option>
                        <option value={7}>August</option>
                        <option value={8}>September</option>
                        <option value={9}>October</option>
                        <option value={10}>November</option>
                        <option value={11}>December</option>
                    </select>
                </div>
            </div>}
            {timePeriod === 'week' || timePeriod === 'day' || timePeriod === 'custom' ? <div className='col-md-auto col-12'>
                <div className='ui field'>
                    <label>Start Date</label>
                    <input type="date" 
                        value={displayStartDate} 
                        onChange={e => { 
                            e.preventDefault(); 
                            setDisplayStartDate(e.target.value); 
                        }}
                        onBlur={e => { 
                            checkStartAndEndDates();
                        }}
                        disabled={timePeriod !== 'day' && timePeriod !== 'week' && timePeriod !== 'custom'} />
                </div>
            </div> : null}
            {timePeriod === 'custom' && <div className='col-md-auto col-12'>
                <div className='ui field'>
                    <label>End Date</label>
                    <input type="date" 
                        value={displayEndDate} 
                        onChange={e => { 
                            e.preventDefault(); 
                            setDisplayEndDate(e.target.value); 
                        }}
                        onBlur={e => { 
                            e.preventDefault(); 
                            setEndDate(e.target.value); 
                        }}
                        disabled={timePeriod !== "custom"} />
                </div>
            </div>}

            {props.setLocationId ?
            <div className='col-md-auto col-12'>
                <div className='ui field'>
                    <label>Practice Location</label>
                    <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={locationList && locationList}
                    isLoading={!locationList}
                    isDisabled={!locationList}
                    isClearable={props.locationClearable?props.locationClearable:false}
                    loadingMessage="loading..."
                    name="practiceLocationId"
                    value={locationList && Array.isArray(locationList) ? locationList.find(obj => obj.practiceLocationId === locationId) : null}
                    onChange={e => {
                        if (e?.practiceLocationId) {
                            updateLocation(e.practiceLocationId)
                        }
                        else {
                            updateLocation()
                        }
                    }}
                    getOptionLabel={(option) => option.practiceLocation}
                    getOptionValue={(option) => option.practiceLocationId}
                    >
                    </Select>
                </div>                
            </div> : null}
        </>
    )
}
export default DatePicker