import React from 'react'
import TabsTemplate from '../../../../templates/components/TabsTemplate'
import BrandSettingsBeta from './brand-settings/BrandSettingsBeta'
import ProfileSettings from '../account-settings/profile-settings/ProfileSettings'
import EmailSettings from '../account-settings/email-settings/EmailSettings'
import NotificationSettings from '../account-settings/notification-settings/NotificationSettings'

const PracticeSettings = (props) => {
    return (
        <div>
            <TabsTemplate id="practiceSettings" accordionId="practiceSettings">
                <div title="Profile">
                    <ProfileSettings />
                </div>
                <div title="Branding">
                    <BrandSettingsBeta/>
                </div>
                <div title="From Email">
                    <EmailSettings />
                </div>
                <div title="Notification Settings">
                    <NotificationSettings />
                </div>
            </TabsTemplate>
        </div>
    )
}

export default PracticeSettings