import React, { useState } from 'react'
import label from '../../../../../../../../../assets/i18n/en.json'
import FormValidatorService from '../../../../../../../../services/validator/form-validator.service';
import toast from "react-hot-toast";
const PaymentOptionForm = (props) => {
    // Pull properties form parent
    const { isEdit, submitHandler, loaded } = props;

    const [formData, setFormData] = useState(props.initialData || {})
    const required = ['paymentOption']
    const [errors, setErrors] = useState({})
    // formula for input change
    const inputChange = (e) => {
        let newStateObject = { ...formData };
        newStateObject[e.target.name] = e.target.value;
        setFormData(newStateObject);
        return
    };
    return (
        <div>
            {!loaded && <div className="ui">
                <div className="ui active dimmer">
                    <div className="ui indeterminate text loader">{label.common.processing}</div>
                </div>
            </div>}
            <form className=''>
                <div className="">
                    <div className="">
                        <div className="required field">
                            <label>Payment Option</label>
                            <input
                                onChange={(e) => {
                                    e.preventDefault();
                                    inputChange(e);
                                }}
                                placeholder="Payment Option"
                                type="text"
                                name="paymentOption"
                                value={formData.paymentOption}
                                required
                            />
                            {/* <span>{errors.paymentOption}</span> */}
                        </div>
                    </div>
                </div >
            </form >
            <div className="mt-3 d-flex justify-content-between">
                <div className='col-auto'>
                    <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); 
                        if (!FormValidatorService.checkForm(errors, formData, required)) {
                            toast.error("Please make sure the form is complete")
                        }
                        else {
                        submitHandler(formData) 
                    }}}> {isEdit ? 'Update' : 'Save'}</button>
                </div>
                <div className='col-auto'>
                    <button className="btn btn-secondary float-right" onClick={(e) => { e.preventDefault(); props.onClose() }}> Cancel </button>
                </div>
            </div>
        </div >
    )
}

export default PaymentOptionForm