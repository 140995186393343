import React, { useState, useEffect } from 'react'
import ProductForm from '../product-form/ProductForm'
import ProductService from '../../../../../services/api/product.service'
import APIResponse from '../../../../templates/components/APIResponse'
import label from '../../../../../../assets/i18n/en.json'
const ProductAdd = (props) => {
    const { embed, inputChange, refresh, exitHandler } = props;
    const [formData, setFormData] = useState({})
    const [messages, setMessages] = useState({})
    const [isLoader, setIsLoader] = useState(false)
    const [apiResponse, setApiResponse] = useState()

    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }
    const submitHandler = (data) => {
        let reqObj = { ...data }
        reqObj.itemType = 1;
        reqObj.unitPrice = parseFloat(reqObj.unitPrice) || 0;
        reqObj.taxPercent = parseFloat(reqObj.taxPercent) || 0;
        reqObj.quantity = parseFloat(reqObj.quantity) || 0;
        reqObj.discount = parseFloat(reqObj.discount) || 0;
        reqObj.cost = parseFloat(reqObj.cost) || 0;
        reqObj.discountType = 2
        reqObj.mfg = reqObj.mfg || '';
        reqObj.sku = reqObj.sku || '';
        setIsLoader(true)
        ProductService.addProduct(reqObj)
            .then(response => {
                setApiResponse(response)
                clearForm()
                setIsLoader(false)
            })
            .catch(error => {
                setApiResponse(error)
                setIsLoader(false)
            })
    }

    const clearForm = () => {
        setFormData({})
    }
    return (
        <>
            {isLoader && <div className="ui">
                <div className="ui active dimmer">
                    <div className="ui indeterminate text loader">{label.common.processing}</div>
                </div>
            </div>}
            <ProductForm submitHandler={submitHandler} onClose={() => props.onClose()} initialData={formData} inputChange={inputChange} loaded={!isLoader} messages={messages} />
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </>
    )
}

export default ProductAdd