import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const ModuleTitle = (props) => {
    const { title, children } = (props)
    const isMobile = useMediaQuery({ query: `(max-width: 576px)` });
    const arrowClick = () => {
        props.setCollapse(!props.collapse)
    }
    // useEffect(() => {
    //     if (isMobile && !props.dontCollapse && !props.startOpen) {
    //         props.setCollapse(true)
    //     }
    //     else {
    //         props.setCollapse(false)
    //     }
    // }, [isMobile])
    return (
        
        <div className="ui ribbon label mb-3" onClick={e => { e.preventDefault(); arrowClick() }} >
            {props.icon && !isMobile ? <i className={`ui icon ${props.icon}`} />
                : props.collapse && isMobile && !props.dontCollapse ? <i className='icon  angle up float-right'/> : isMobile && !props.dontCollapse ? <i className='icon angle down float-right'/> : null}
            {children && children}

        </div>
    )
}

export default ModuleTitle