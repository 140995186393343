import React from 'react'
import FormsManagement from '../practice-settings/forms-management/FormsManagement'
import Dashboard from '../../../../templates/layouts/Dashboard'

const FormsAdminSettings = (props) => {
    return (
        <Dashboard title="Forms Management" Modules stacked>
                <div className='p-3' title="Forms Management">
                    <FormsManagement/>
                </div>
        </Dashboard>
    )
}

export default FormsAdminSettings