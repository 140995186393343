import React, { useState, useEffect } from 'react'
import AppointmentService from '../../../../../services/api/appointment.service'
import AppointmentForm from '../appointment-form/AppointmentForm'
import moment from 'moment'
import APIResponse from '../../../../templates/components/APIResponse';
import * as moment1 from "moment-timezone";
import TimeZoneEnum from '../../../../../common/enum/time-zone.enum';

const AddAppointmentForm = (props) => {
    const [apiResponse, setApiResponse] = useState()
    const [inputData, setInputData] = useState(props.initialData || {})
    const [disabled, setDisabled] = useState(false)

    const onSuccess = (message) => {
        setInputData(clearTemplate)
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }

    const clearTemplate = {
        "aptTotalCount": "",
        "checkEligibility": "",
        "day": "",
        "doctorId": "",
        "duration": "",
        "email": "",
        "equipmentId": "",
        "fromDate": "",
        "memo": "",
        "patientId": "",
        "patientInsuranceId": "",
        "phone": "",
        // "practiceAppointmentStatusCodeId": "",
        // "practiceLocationId": "",
        "practiceLocationRoomId": "",
        "practicePatientTypeId": "",
        "practiceServiceTypeId": "",
        "reasonForVisit": "",
        "repeatOn": "",
        "timeZone": 58,
        "toDate":"",
        "patientPhone":"",
        "clear":true
    }
  
    const submitHandler = (data) => {

        setDisabled(true);

        console.log('data',{data});

        let array={
        "aptTotalCount": data.aptTotalCount,
        "checkEligibility": data.checkEligibility,
        "day": moment(data.fromDate).day(),
        "doctorId": data.doctorId && data.doctorId!==""? data.doctorId : undefined ,
        "duration": data.duration,
        "email": data.email,
        "equipmentId": data.equipmentId && data.equipmentId!==""? data.equipmentId : undefined,
        "fromDate": new Date(data?.fromDate).toISOString(),
        "memo": data.memo,
        "patientId": data.patientId,
        "patientInsuranceId": data.patientInsuranceId,
        "phone": data.patientPhone,
        "practiceAppointmentStatusCodeId": data.practiceAppointmentStatusCodeId,
        "practiceLocationId": data.practiceLocationId,
        "practiceLocationRoomId": data.practiceLocationRoomId && data.practiceLocationRoomId!=="" && data.practiceLocationRoomId!=="0rbd3W69" ? data.practiceLocationRoomId : undefined,
        "practicePatientTypeId": data.practicePatientTypeId,
        "practiceServiceTypeId": data.practiceServiceTypeId,
        "reasonForVisit": data.memo,
        "repeatOn": data.repeatOn,
        "timeZone":  58, // TimeZoneEnum[moment1.tz.guess()] != undefined ? TimeZoneEnum[moment1.tz.guess()] : TimeZoneEnum["Default"],
        "toDate": new Date(data.toDate).toISOString()
    }
        if (data.childAppointments && data.childAppointments.length > 0) {
            //reset sequence in correct order
            array.childAppointments = data.childAppointments.map((item, index) => {
                item.sequence = index + 1
                item.doctorId = item.doctorId && item.doctorId !== "" ? item.doctorId : undefined
                item.equipmentId = item.equipmentId && item.equipmentId !== "" ? item.equipmentId : undefined
                item.practiceLocationRoomId = item.practiceLocationRoomId && item.practiceLocationRoomId !== "" && item.practiceLocationRoomId!=="0rbd3W69" ? item.practiceLocationRoomId : undefined
                //delete item.sequenceId
                return item
            })
        } else {
            array.childAppointments = [];
        }
        return AppointmentService.addAppointment(array)
        .then((res) => { 
            setDisabled(false);                
            setApiResponse(res);
        })
        .catch((err) => { 
            setDisabled(false);                
            setApiResponse(err);
            console.log(err) 
        });
    }
    return (
        <div>
            <AppointmentForm disabled={disabled} submitHandler={submitHandler} onClose={props.onClose} isModal={props.isModal} initialData={inputData} submitLabel="Submit" additionalButton={props.additionalButton} />
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true}/>
        </div>
    )
}

export default AddAppointmentForm