import React, { useState } from 'react'
import MembershipTable from './MembershipTable'

const MembershipSettings = (props) => {
    return (
        <div title="Membership">
            <MembershipTable title="Membership" />
        </div>
    )
}

export default MembershipSettings