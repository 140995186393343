import React, { useState, useEffect } from "react";
import CacheUtilities from "../../../../../../services/cache/CacheUtilities";
import PatientService from "../../../../../../services/api/patient.service";
import PlFormsService from "../../../../../../services/api/plforms.service";
import InvoiceService from "../../../../../../services/api/invoice.service";
import CreditBalanceService from "../../../../../../services/api/credit-balance.service";
import PatientCard from "./PatientCard";
import DimLoader from "../../../../../templates/components/DimLoader";

const PatientCardWrapper = (props) => {

    const [isLoading, setIsLoading] = useState();
    const [patient, setPatient] = useState();
    const [forms, setForms] = useState();
    const [balances, setBalances] = useState();
    const [creditBalance, setCreditBalance] = useState();
    const [patientTags, setPatientTags] = useState();

    const [patientId, setPatientId] = useState(props?.patientId);
    const [keyword, setKeyword] = useState();
    const [index, setIndex] = useState();

    const refreshCard = async () => {

        setIsLoading(true);

        const [Patient, Forms, Balances, CreditBalance, PatientTags] = await Promise.all([

            CacheUtilities.pullAPIData(
                {
                    isObject: true,
                    APIService: "getPatientById",
                    Service: PatientService,
                    Parameters: patientId
                }
            ),

            CacheUtilities.pullAPIData(
                {
                    APIService: "getMapFormsWithPatient",
                    Service: PlFormsService,
                    Parameters: { PatientIds: patientId }
                }
            ),

            CacheUtilities.pullAPIData(
                {
                    APIService: "findInvoice",
                    Service: InvoiceService,
                    Parameters: {
                        sortField: 'dueDate',
                        Asc: 'false',
                        PatientIds: patientId,
                        StartRow: 0,
                        InvoiceStatuses: '1,2,4,5,6,8,10',
                    }                    
                }
            ),

            CacheUtilities.pullAPIData(
                {
                    APIService: "lookupCreditBalance",
                    Service: CreditBalanceService,
                    Parameters: {
                        PatientIds: patientId
                    }      
                }
            ),

            CacheUtilities.pullAPIData(
                {
                    APIService: "getPatientLookupTags",
                    Service: PatientService,
                    Parameters: {
                        PatientIds: patientId
                    }
                }
            ),

        ])

        setPatient(Patient);
        setForms(Forms)
        setBalances(Balances)
        setCreditBalance(CreditBalance)
        setPatientTags(PatientTags)

        setIsLoading(false);

    }

    useEffect(() => {
        if (patientId) refreshCard();
    }, [patientId])


    return (
        <>
            {isLoading ? <DimLoader loadMessage="Loading..." /> : null}
            {patient ? 
                <PatientCard
                    patientTags={patientTags}
                    balance={balances}
                    creditBalance={creditBalance}
                    forms={forms}
                    id={patientId}
                    patient={patient}
                    keyword={keyword}
                    key={index}
                    index={index}
                    refresh={() => refreshCard()} 
                    alwaysExpanded = {true}
                />
            : null }
        </>
  );
};

export default PatientCardWrapper;






