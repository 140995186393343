import AppSetting from "../../common/constants/appsetting.constant"
import CommonService from "./common.service"
import axios from 'axios'

const ProviderServicesService = {
    addProviderService(reqObj) {
        let loggedInUserData = CommonService.getLoggedInData()
        let url = AppSetting.providerServices.addProviderService.replace("{parentId}", loggedInUserData.parentId)
        return axios.post(url, reqObj)
            .then(res => {
                return res
            })
            .catch(err => {
                return err
            })
    },
    updateProviderService(reqObj) {
        let loggedInUserData = CommonService.getLoggedInData()
        let url = AppSetting.providerServices.updateProviderService.replace("{parentId}", loggedInUserData.parentId).replace("{practictionerServiceTypeId}", reqObj.practictionerServiceId)
        return axios.put(url, reqObj)
            .then(res => {
                return res
            })
            .catch(err => {
                return err
            })
    },
    findServicesByProvider(providerId) {
        let loggedInUserData = CommonService.getLoggedInData()
        let url = AppSetting.providerServices.findServicesByProvider.replace("{parentId}", loggedInUserData.parentId).replace("{doctorId}", providerId)
        return axios.get(url)
            .then(res => {
                return res
            })
            .catch(err => {
                return err
            })
    },
    getProviderServiceById(practictionerServiceId) {
        let loggedInUserData = CommonService.getLoggedInData()
        let url = AppSetting.providerServices.updateProviderService.replace("{parentId}", loggedInUserData.parentId).replace("{practictionerServiceTypeId}", practictionerServiceId)
        return axios.get(url)
            .then(res => {
                return res
            })
            .catch(err => {
                return err
            })
    },
    deleteProviderServiceById(service) {
        console.log(service)
        let reqObj = {...service}
        reqObj.isDeleted=1
        let loggedInUserData = CommonService.getLoggedInData()
        let url = AppSetting.providerServices.deleteProviderServiceById.replace("{parentId}", loggedInUserData.parentId).replace("{practitionerServiceTypeId}", reqObj.practitionerServiceTypeId)
        return axios.put(url, reqObj)
            .then(res => {
                return res
            })
            .catch(err => {
                return err
            })
    },
    getAllProviderServiceType(query) {
        let loggedInUserData = CommonService.getLoggedInData()
        let url = AppSetting.providerServices.updateProviderService.replace("{parentId}", loggedInUserData.parentId)
        if(query){
            url=`${url}${CommonService.buildQuery(query)}`
        }
        return axios.get(url)
            .then(res => {
                return res
            })
            .catch(err => {
                return err
            })
    },
}

export default ProviderServicesService