import AppSetting from '../../common/constants/appsetting.constant';
import axios from 'axios'
import StorageService from '../session/storage.service';
import StorageType from '../session/storage.enum';
import CommonService from './common.service';

const SwipeTerminalService = {

  getLoggedInData() {
    return JSON.parse(StorageService.get(StorageType.session, 'userDetails'));
  },

  getAllSwipeTerminals(payload) {
    let loggedInUserData = this.getLoggedInData();
    let url = AppSetting.provider.getAllSwipeTerminals(loggedInUserData.parentId);
    return axios.get(url, {
      params: payload
    }).then(a => {
      return a
    }).catch(err => {
      { return err }
    })
  },
  
  checkTerminalStatus(terminalId) {
    let loggedInUserData = this.getLoggedInData();
    let url = AppSetting.provider.checkTerminalStatus(loggedInUserData.parentId,terminalId);
    return axios.get(url).then(a => {
      return a
    }).catch(err => {
      { return err }
    })
  }
}
export default SwipeTerminalService
