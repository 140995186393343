import React, { useEffect, useState } from "react";
import moment from "moment";
import { Accordion, Icon } from "semantic-ui-react";
import PaymentsList from "../paymentPlan-card/payments-accordion/payments-list/PaymentsList";
import PaymentHistoryList from "../paymentPlan-card/payment-history-accordion/payment-history-list/PaymentHistoryList"
import Utilities from "../../../../../../services/commonservice/utilities";
import InvoicePreview from "../../../invoices/invoice-preview/InvoicePreview";
import ModalBox from "../../../../../templates/components/ModalBox";
import EditRecurringPlan from "../../../transactions/terminals/installment-terminal/EditRecurringPlan";

const PaymentPlanCard = (props) => {
    const [showMore, setShowMore] = useState(false);
    const [recurringPlan, setRecurringPlan] = useState(false);
    
    const [activeIndex, setActiveIndex] = useState();
    const [paymentPlan, setPaymentPlan] = useState(props.paymentPlan);
    const [preview, setPreview] = useState(false)
    const [closeReason, setCloseReason] = useState()
    const [errors, setErrors] = useState()

    const setIndex = (i) => {
        if (i === activeIndex) {
            setActiveIndex();
        } else {
            setActiveIndex(i);
        }
    };
    
    const paymentPlanStatus = [
        { 'title': 'Active', 'value': 2 },
        { 'title': 'Created', 'value': 1 }, // pending
        { 'title': 'Completed', 'value': 3 }, // paid
        { 'title': 'Cancelled', 'value': 8 }, // cancelled
        { 'title': 'Failed', 'value': 5 },
        { 'title': 'Closed', 'value': 30 }
      ];
    

    useEffect(() => {
        if (closeReason?.length <= 10) {
            setErrors("Your reason for closing must be at least 10 characters long")
        }
        else if (!closeReason || closeReason == "") {
            setErrors("A reason for closing is required")
        }
        else {
            setErrors()
        }
    }, [closeReason])

    useEffect(() => {
        setPaymentPlan(props.paymentPlan);
    }, [props.paymentPlan])


    return (
        <div className='container-flex card mb-3 bg-light'>
            {paymentPlan &&
                <div className='row d-flex p-3'>
                    <div className='col-12 row-fluid d-flex justify-content-between'>
                        <div className='col-12 row d-flex align-items-start'>
                            <div className='col-md-8 col-12 btn text-start' onClick={e => { e.preventDefault(); setShowMore(!showMore) }}>
                                <h5 className='mt-2'>{paymentPlan.invoiceNumber ? `${paymentPlan.invoiceNumber} | ` : null}{paymentPlan.firstName} {paymentPlan.lastName}
                                </h5>
                                <div className='row'>
                                    {props.type == 3 ?
                                        <div className="col-md-6 col-12"><span className="w-150px"><strong>Total</strong></span>{paymentPlan.noOfPayments} of payments {Utilities.toDollar(paymentPlan.paymentAmount)}</div> : <span className='col-md-6 col-12'><span className="w-150px"><strong>Total</strong></span>{Utilities.toDollar(paymentPlan.totalAmount ? paymentPlan.totalAmount : 0)}</span>}
                                    {props.type == 3 ? <span className='col-md-6 col-12'><span className="w-150px"><strong>Remaining Balance</strong></span>{paymentPlan.totalPaymentLeft} payments of {Utilities.toDollar(paymentPlan.paymentAmount)}</span> : <span className='col-md-6 col-12'><span className="w-150px"><strong>Remaining Balance</strong></span>{Utilities.toDollar(paymentPlan.totalDueAmount ? paymentPlan.totalDueAmount : 0)}</span>}
                                    {/* <span className='col-md-6 col-12'><span className="w-150px"><strong><i className='icon clipboard' />Payment Type</strong></span> {InvoiceTypeEnum[paymentPlan.transactionType]}</span> */}
                                </div>
                                <div className='row'>
                                    {paymentPlan.createdOn && <span className='col-md-6 col-12'><span className="w-150px"><strong>Created On</strong></span> {moment(paymentPlan.createdOn).format("M/D/YYYY")}</span>}
                                    {paymentPlan.nextTransactionDate && <span className='col-md-6 col-12'><span className="w-150px"><strong>Next Transaction Date</strong></span> {moment.utc(paymentPlan.nextTransactionDate).format("M/D/YYYY")}</span>}
                                </div>
                            </div>

                            <div className='col-md-4 col-12 d-flex justify-content-end'>
                                {/* {props.transaction.status} */}
                                <div className='btn-group'>
                                    {paymentPlan?.status && <div
                                        className='btn btn-secondary'
                                        title={paymentPlanStatus.find(obj => obj.value == paymentPlan?.status)?.title}
                                    >
                                    {paymentPlanStatus.find(obj => obj.value == paymentPlan?.status)?.title || paymentPlan?.status}</div>}
                                    {![8,5,3].includes(paymentPlan?.status) && paymentPlan?.updateCount <= 1
                                     ? <button className='btn btn-primary' title={paymentPlan?.updateCount == 0? "Update Payment Plan": "Update Payment Account"} onClick={e => { e.preventDefault(); setRecurringPlan(true) }}><i className={`icon pencil`} /></button> : null}
                                    <button className='btn btn-primary' title="View Details" onClick={e => { e.preventDefault(); setPreview(true) }}><i className="icon eye" /></button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {showMore && <div className='mt-3'>
                        <Accordion fluid styled>
                            <Accordion.Title
                                active={activeIndex === 0}
                                index={0}
                                onClick={e => { e.preventDefault(); setIndex(0) }}
                            > <Icon name='dropdown' />Preview</Accordion.Title>
                            <Accordion.Content active={activeIndex === 0}>
                                {activeIndex === 0 && <InvoicePreview invoiceId={paymentPlan.invoiceId} pull={activeIndex === 0} />}
                            </Accordion.Content>


                            <Accordion.Title
                                active={activeIndex === 1}
                                index={1}
                                onClick={e => { e.preventDefault(); setIndex(1) }}
                            > <Icon name='dropdown' />Upcoming Payments</Accordion.Title>
                            <Accordion.Content active={activeIndex === 1}>
                                {activeIndex === 1 && <PaymentsList paymentPlanId={paymentPlan.id} pull={activeIndex === 1} />}
                            </Accordion.Content>

                            <Accordion.Title
                                active={activeIndex === 2}
                                index={2}
                                onClick={e => { e.preventDefault(); setIndex(2) }}
                            > <Icon name='dropdown' />Payment History{activeIndex === 2 && <Icon name="plus" style={{ float: 'right' }} title="Add Subscription Details" className='btn p-2' onClick={e => { e.preventDefault(); setMembershipAdd(true) }} />}</Accordion.Title>
                            <Accordion.Content active={activeIndex === 2}>
                                {activeIndex === 2 && <PaymentHistoryList paymentPlanId={paymentPlan.id} paymentPlan={paymentPlan} pull={activeIndex === 2} />}
                            </Accordion.Content>
                        </Accordion>
                    </div>}
                </div>
            }
            <ModalBox open={preview} onClose={() => setPreview(false)} size="large" title="Preview">
                <InvoicePreview invoiceId={paymentPlan.invoiceId} pull={preview} onClose={() => setPreview(false)} />
            </ModalBox>
            <ModalBox open={recurringPlan} onClose={() => { setRecurringPlan(false) }} title="Update Installment Plan">
                <EditRecurringPlan transactionType={paymentPlan?.transactionType} initialData={paymentPlan} isEdit submitLabel="Update" onClose={() => { setRecurringPlan(false); if (props.refresh()) { props.refresh() } }} isModal />
            </ModalBox>
        </div>
    );
};

export default PaymentPlanCard;
