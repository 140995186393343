import moment from 'moment'
import React, { useState, useEffect } from 'react'
import PatientService from '../../../../../../services/api/patient.service'
import Utilities from '../../../../../../services/commonservice/utilities'
import ModalBox from '../../../../../templates/components/ModalBox'
import Table2 from '../../../../../templates/components/Table2'
import AddPatient from '../../../patient/add-patient/AddPatient'
import EditPatient from '../../../patient/edit-patient/EditPatient'
const PatientTable = (props) => {
    const [patients, setPatients] = useState()
    const [isLoader, setIsLoader] = useState(false)
    const [selectedPatient, setSelectedPatient] = useState()
    const [showEdit, setShowEdit] = useState(false)
    const [showAdd, setShowAdd] = useState(false)
    const hiddenColumns = ["email","dob","mobile","mrn","isOptIn","addressLine1","city","country","state","postalCode"]
    const pullPatients = () => {
        setIsLoader(true)
        let reqObj = { SearchTerm: '', isActive: true, isRegistered: true, SortField: 'firstName', StartRow: 0 }
        PatientService.findPatient(reqObj)
            .then(res => {
                setPatients(res)
                setIsLoader(false)
            }
            )
            .catch(err => {
                console.log(err)
                setIsLoader(false)
            })
    }
    const columns = [
        {
            id: "firstName",
            Header: "First Name",
            accessor: patient => patient.firstName
        },
        {
            id: "lastName",
            Header: "Last Name",
            accessor: patient => patient.lastName
        },
        {
            id: "mrn",
            Header: "MRN",
            accessor: patient => patient.mrn
        },
        {
            id: "dob",
            Header: "DOB",
            accessor: patient => moment.utc(patient.dob).format("MM/DD/YYYY")
        },
        {
            id: "mobile",
            Header: "Phone",
            accessor: patient => Utilities.toPhoneNumber(patient.mobile)
        },
        {
            id: "email",
            Header: "Email",
            accessor: patient => patient.email
        },
        {
            id: "isOptIn",
            Header: "Opt In",
            accessor: patient => patient.isOptIn === 1 ? 'Y' : 'N'
        },
        {
            id: "addressLine1",
            Header: "Address Line 1",
            accessor: patient => patient.address.addressLine1
        },
        {
            id: "city",
            Header: "City",
            accessor: patient => patient.address.city
        },
        {
            id: "state",
            Header: "State",
            accessor: patient => patient.address.state
        },
        {
            id: "country",
            Header: "Country",
            accessor: patient => patient.address.country == 0 ? 'USA' : ''
        },
        {
            id: "postalCode",
            Header: "Postal Code",
            accessor: patient => patient.address.postalCode
        },
        {
            id: "actions",
            Header: "Actions",
            textAlign: 'center',
            disableSortBy: true,
            accessor: patient => <span className='w-100 d-flex justify-content-center'><div className="btn-group">
                <button className='btn btn-primary' onClick={e => { e.preventDefault(); setSelectedPatient(patient); return setShowEdit(true) }} title="Edit Patient"><i className="icon pencil" /></button>
            </div></span>
        },
    ];

  
    useEffect(() => {
        pullPatients()
    }, [])
    return (
        <div>
            <Table2 hiddenColumns={hiddenColumns} export searchable loading={isLoader} data={patients} columns={columns}
                extraButtons={[
                    {
                        className: 'btn btn-primary',
                        children: <i className="icon plus" />,
                        title: 'Add Patient',
                        onClick: () => { setShowAdd(true) }
                    }
                ]} />
            <ModalBox open={showAdd} onClose={() => setShowAdd(false)} title="Add Patient">
                <AddPatient onClose={() => { pullPatients(); return setShowAdd(false) }} isModal/>
            </ModalBox>
            <ModalBox open={showEdit} onClose={() => { setShowEdit(false) }} title="Edit Patient">
                <EditPatient id={selectedPatient?.id} onClose={() => { pullPatients(); return setShowEdit(false) }} isModal/>
            </ModalBox>
        </div>
    )
}

export default PatientTable