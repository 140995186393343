import React, { useState, useEffect } from 'react'
import PaymentOptionService from '../../../../../../../../services/api/payment-options.service';
import APIResponse from '../../../../../../../templates/components/APIResponse';
import PaymentOptionForm from '../payment-option-form/PaymentOptionForm';

const PaymentOptionEdit = (props) => {
    const [formData, setFormData] = useState(props.initialData||{})
    const [messages, setMessages] = useState({})
    const [apiResponse, setApiResponse] = useState()
    const [isLoader, setIsLoader] = useState(false)
    useEffect(()=>{
        if(props.initialData){
            setFormData(props.initialData)
        }
    },[props.initialData])

    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }

    const submitHandler = (data) => {
        let reqObj = {}
        reqObj.paymentOption = data.paymentOption;
        setIsLoader(true)
        PaymentOptionService.editPaymentOption(reqObj,data.paymentOptionId)
            .then(response => {
                setApiResponse(response)
            })
            .catch(error => {
                setApiResponse(error)
            }).finally(()=>{setIsLoader(false)})
    }

    return (
        <div>
            <PaymentOptionForm submitHandler={submitHandler} initialData={formData} messages={messages} loaded={!isLoader}
                submitLabel="Update" onClose={() => props.onClose()} />
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </div>
    )
}

export default PaymentOptionEdit