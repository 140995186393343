import Utilities from "./utilities";


export const InvoiceUtility = {

    allowedOperations(invoice) {

        let openBalance = 0;

        if (!Utilities.isNullOrUndefined(invoice.openBalance) && Utilities.isNullOrUndefined(invoice.total)) {
            // Always use openBalance unless total is present
            openBalance = invoice.openBalance;
        }
        else
        {
            if (!Utilities.isNullOrUndefined(invoice.finalAmount)) {
                openBalance = invoice.finalAmount
            }
            if (!Utilities.isNullOrUndefined(invoice.total)) {
                // If total exists, it should be used because invoice is being modified and hasnt been saved
                openBalance = invoice.total
            }
            if (!Utilities.isNullOrUndefined(invoice.inProcessAmount)) openBalance = openBalance - invoice.inProcessAmount;
            if (!Utilities.isNullOrUndefined(invoice.amountPaid)) openBalance = openBalance - invoice.amountPaid;
        }

        var invoiceType = invoice.invoiceType;
        if (Utilities.isNullOrUndefined(invoiceType)) invoiceType = InvoiceType.OneTime;
        
        var operations = [];

        // - CLOSE - Just for one time invoice with no balance
        if (
            [
                InvoiceStatus.Draft,
                InvoiceStatus.Finalize
            ].includes(invoice.invoiceStatus) 
        && 
            openBalance == 0) 
        {
            operations.push(InvoiceOperation.CLOSE)
        }

        // - CLOSE_WRITEOFF - Same API as CLOSE but this operation is just for installments
        if 
        (
            [
                InvoiceType.Installment,
            ].includes(invoiceType) 
        && [
                InvoiceStatus.Unpaid,
                InvoiceStatus.InProgress,
                InvoiceStatus.PaymentPlanCreated,
                InvoiceStatus.OneTimeScheduledCreated,   
                InvoiceStatus.SubscriptionPlanCreated,
            ].includes(invoice.invoiceStatus)
        )
        {
            operations.push(InvoiceOperation.CLOSE_WRITEOFF)
        }

        // - CANCEL
        if 
        (
            (
                [
                    InvoiceStatus.Draft,
                    InvoiceStatus.Finalize,
                    InvoiceStatus.PaymentPlanCreated,
                    InvoiceStatus.OneTimeScheduledCreated,
                    InvoiceStatus.SubscriptionPlanCreated,
                ].includes(invoice.invoiceStatus)
            ||
                (
                    invoiceType != InvoiceType.Installment 
                && 
                [
                    InvoiceStatus.InProgress,
                    InvoiceStatus.Unpaid
                ].includes(invoice.invoiceStatus))
            )
        &&
            true // removed below because couldnt cancel a sub
            // openBalance>0 // added this condition otherwise user may get both close and cancel options
        ) 
        {
            operations.push(InvoiceOperation.CANCEL)
        }

        // - FINALIZE
        if (invoice.invoiceStatus === InvoiceStatus.Draft) {
            operations.push(InvoiceOperation.FINALIZE)
        }


        // - SEND_TO_PATIENT
        if (invoice.invoiceStatus === InvoiceStatus.Finalize) {
            operations.push(InvoiceOperation.SEND_TO_PATIENT)
        }

        // - UPDATE
        if 
        (
            [
                InvoiceType.OneTime, 
                InvoiceType.PartialPay
            ].includes(invoiceType)
        &&  [
                InvoiceStatus.Draft,
                InvoiceStatus.Finalize,
                InvoiceStatus.InProgress,
                InvoiceStatus.Paid,
                InvoiceStatus.Unpaid
            ].includes(invoice.invoiceStatus)
        )
        {
            operations.push(InvoiceOperation.UPDATE)
        }

        // - MAKE PAYMENT
        if 
        (
            [
                InvoiceType.OneTime,
                InvoiceType.PartialPay,
                InvoiceType.CreditBalance,
                // ScheduledOneTime ??
            ].includes(invoiceType) 
        &&  [
                InvoiceStatus.Finalize,
                InvoiceStatus.InProgress,
                InvoiceStatus.Unpaid,
                // InvoiceStatus.PaymentInitiated ??
            ].includes(invoice.invoiceStatus)
        &&
            openBalance>0
        )
        {
            operations.push(InvoiceOperation.MAKE_PAYMENT)
        }


        // - CREATE SUB OR PAYMENT PLAN
        if 
        (
            [
                InvoiceType.OneTime,
                InvoiceType.PartialPay,
            ].includes(invoiceType) 
        &&  [
                InvoiceStatus.Finalize,
                // InvoiceStatus.InProgress, FUTURE - Current API will calculate payments on invoice total, not the open balance
                InvoiceStatus.Unpaid,
            ].includes(invoice.invoiceStatus)
        &&
            openBalance>0
        )
        {
            operations.push(InvoiceOperation.SETUP_PAYMENT_PLAN)
            operations.push(InvoiceOperation.SETUP_SUBSCRIPTION)
        }



        // - MANAGE PAYMENTS
        if 
        (
            [
                InvoiceType.Installment,
                InvoiceType.ScheduledOneTime,
                InvoiceType.Subscription,
            ].includes(invoiceType) 
        &&  [
                InvoiceStatus.PaymentPlanCreated,
                InvoiceStatus.OneTimeScheduledCreated,
                InvoiceStatus.SubscriptionPlanCreated,
                InvoiceStatus.InProgress,
            ].includes(invoice.invoiceStatus)
        )
        {
            operations.push(InvoiceOperation.MANAGE_PAYMENTS)
        }

        return operations;
    },
    OperationAllowed(invoice, operation) {
        var allowed = this.allowedOperations(invoice);
        return allowed.includes(operation)
    },
    GetInvoiceStatusDescription(invoiceStatus) {


        const invoiceStatusMapping = new Map([
            [InvoiceStatus.Draft, 'Open'],
            [InvoiceStatus.Finalize, 'Open'],
        
            [InvoiceStatus.Cancelled, 'Cancelled'],
        
            [InvoiceStatus.PaymentInitiated, 'In Progress'],  
            [InvoiceStatus.PaymentPlanCreated, 'In Progress'],
            [InvoiceStatus.OneTimeScheduledCreated, 'In Progress'],   
            [InvoiceStatus.SubscriptionPlanCreated, 'In Progress'],
            [InvoiceStatus.InProgress, 'In Progress'],
        
            [InvoiceStatus.Paid, 'Paid'],
            [InvoiceStatus.Unpaid, 'Open'], 
            [InvoiceStatus.Unsubscribed, 'Unsubscribed'], 
        
            [InvoiceStatus.Closed, 'Closed']
        ]);


        if (invoiceStatusMapping.has(invoiceStatus)) 
        {
            return invoiceStatusMapping.get(invoiceStatus);
        }
        return 'Unknown'
    }

}

export const InvoiceType = {
    OneTime: 0,
    Installment: 1,
    ScheduledOneTime: 2,
    Subscription: 3,
    PartialPay: 4,
    CreditBalance: 5
}

export const InvoiceOperation = {
    UPDATE:1,
    CANCEL:2,
    DELETE:3,
    FINALIZE:4,
    SEND_TO_PATIENT:5,
    MAKE_PAYMENT:6,
    SETUP_PAYMENT_PLAN:7,
    SETUP_SUBSCRIPTION:8,
    MANAGE_PAYMENTS:9,
    CLOSE:10,
    CLOSE_WRITEOFF:11,
}

export const InvoiceStatus = {
    Draft: 1,
    Finalize: 2,
    Cancelled: 3,
    PaymentInitiated: 4,  
    PaymentPlanCreated: 5,
    OneTimeScheduledCreated: 6,   
    SubscriptionPlanCreated: 7,
    InProgress: 8, 
    Paid: 9, 
    Unpaid: 10, 
    Unsubscribed: 11, 
    Closed: 30, 
}



