import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import States from '../../../../../common/constants/states.constant'
import Utilities from '../../../../../services/commonservice/utilities'
import AccordionTemplate from '../../../../templates/components/AccordionTemplate'
import InputMask from "react-input-mask";
import PatientService from '../../../../../services/api/patient.service'
import PatientFinancialHeader from '../../transactions/terminals/virtual-terminal/virtual-terminal-form/PatientFinancialHeader'

const PatientAccountForm = (props) => {
    const [cardType, setCardType] = useState()
    const [paymentMethod, setPaymentMethod] = useState("credit-debit")
    const [inputData, setInputData] = useState(props.initialData)
    const [patientList, setPatientList] = useState([])
    const [selectedPatient, setSelectedPatient] = useState(props.patient || null)
    const [errors, setErrors] = useState({})
    const [isLoader, setIsLoader] = useState(false);
    const icons = {
        blank: 'credit-card',
        AMEX: 'cc amex',
        DINERS: 'cc diners club',
        DISCOVER: 'cc discover',
        JCB: 'cc jcb',
        MASTERCARD: 'cc mastercard',
        VISA: 'cc visa'
    }

    // const updatePatientAddress = () => {
    //     let reqObj = { ...selectedPatient }
    //     reqObj.address = inputData.address
    //     PatientService.editPatient(reqObj, selectedPatient?.id)
    //         .then(res => {
    //             console.log(res)
    //             setPatientUpdateConfirm(true)
    //         })
    // }

    // formula for input change
    const inputChange = (e) => {
        let newStateObject = { ...inputData };
        newStateObject[e.target.name] = e.target.value
        setInputData(newStateObject);
        return console.log(inputData)
    };
    const addressInputChange = (e) => {
        let newStateObject = { ...inputData };
        newStateObject.address[e.target.name] = e.target.value
        setInputData(newStateObject);
        return console.log(inputData)
    };

    useEffect(() => {
        setIsLoader(true)
        if (props.patientId) {
            PatientService.getPatientById(props.patientId)
                .then(res => {
                    setPatientList([res.data])
                    setSelectedPatient(res.data)
                }).finally(()=>{
                    setIsLoader(false)
                })
        }
        else {
            let reqObj = { SearchTerm: '', isActive: true, isRegistered: true }
            PatientService.findPatient(reqObj)
                .then(res => {
                    if (res) {
                        console.log(res)
                        setPatientList(res)
                    }
                    setSelectedPatient(res.find(obj => obj.id === inputData.patientId))
                }
                )
                .catch(err => console.log(err))
                .finally(()=>{
                    setIsLoader(false)
                })
        }
    }, [])

    useEffect(() => {
        if (selectedPatient) {
            let newData = { ...inputData }
            if (inputData.sameAsPatientAddress === true) {
                newData.address = selectedPatient.address
                newData.samePatientAddress = 1
                setInputData(newData)
            }else{
                newData.samePatientAddress = 0
                setInputData(newData)
            }
        }
    }, [inputData.sameAsPatientAddress])
    useEffect(() => {
        let cardValue = inputData.cardNumber
        if (cardValue != null && cardValue?.length > 3) {
            let cardType = Utilities.getCardType(cardValue.replace(" ", "").replace(" ", "").replace(" ", ""))
            inputChange({
                target: {
                    value: cardType, name: "cardType"
                }
            })
            return setCardType(cardType)
        } else {
            setCardType()
        }
    }, [inputData.cardNumber])

    useEffect(() => {
        if (inputData.patientId) {
            setSelectedPatient(patientList.find(obj => obj.id === inputData.patientId))
        }
        if (props.initialData.patientId) {
            setSelectedPatient(patientList.find(obj => obj.id === props.initialData.patientId))
        }
    }, [])

    useEffect(() => {
        if (selectedPatient) {
            let newData = { ...inputData }
            if(!props.isEdit && !props.isRenew){
                newData.accountHolderName = selectedPatient?.firstName + ' ' + selectedPatient?.lastName
            }
            if (selectedPatient?.address?.addressLine1) {
                newData.sameAsPatientAddress = newData.samePatientAddress === 1 ? true : false
            }
            setInputData(newData)
        }

    }, [selectedPatient])

    const onPatientChange = (patient) => {
        if (patient) {
            inputChange({ target: { value: patient.id, name: 'patientId' } })
        }
        else {
            inputChange({ target: { value: '', name: 'patientId' } })
        }
        setSelectedPatient(patient)
    }

    const onLocationChange = (location) => {
        console.log(location)
        if (location?.practiceLocationId) {
            console.log("has Id")
            inputChange({ target: { value: location.practiceLocationId, name: 'practiceLocationId' } })
        }
        else {
            console.log("no id")
            inputChange({ target: { value: '', name: 'practiceLocationId' } })
        }
    }

    const submitHandler = () => {
        if (!inputData?.practiceLocationId && !props.isRenew) {
            setErrors({ practiceLocationId: 'Please select a practice location in order to save' })
        }
        else {
            setErrors({})
            props.submitHandler(paymentMethod, inputData)
        }
    }
    return (
        <>
            {isLoader && (
                <div className="ui active dimmer">
                    <div className="ui indeterminate text loader">Loading...</div>
                </div>
            )}
            <PatientFinancialHeader patient={selectedPatient} disablePatientEdit disableLocationEdit={props.disableLocationEdit} patientId={selectedPatient?.id} isRenew={props?.isRenew}  onPatientChange={patient => { onPatientChange(patient) }} locationId={inputData?.practiceLocationId} onLocationChange={location => { onLocationChange(location) }} />
            <AccordionTemplate id="patientAccountForm" accordionId="patientAccountForm">
                <div title="Account Details">
                    <div className='col-12'>
                        <div className="row d-flex">
                            {!props.isEdit && !props.isRenew  && <div className="col-12">
                                <label className="me-3 mb-3">Payment Type</label>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input mt-2" type="radio" name="paymentType" id="inlineRadio1" defaultChecked={paymentMethod === "credit-debit" ? true : false} onInputCapture={e => { e.preventDefault(); inputChange(e); return setPaymentMethod("credit-debit") }} value="credit-debit" />
                                    <label className="form-check-label mt-1" for="inlineRadio1">Card</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input mt-2" type="radio" name="paymentType" id="inlineRadio2" defaultChecked={paymentMethod === "ach" ? true : false} onInputCapture={e => { e.preventDefault(); inputChange(e); return setPaymentMethod("ach") }} value="ach" />
                                    <label className="form-check-label mt-1" for="inlineRadio2">ACH</label>
                                </div>
                            </div>}
                            {paymentMethod === "credit-debit" ? <div className="col-12">
                                <div className="d-flex row">
                                    <div className="col-12 field required">
                                        <label>Name on Card</label>
                                        <input
                                            type="text"
                                            name="accountHolderName"
                                            value={inputData.accountHolderName}
                                            onChange={e => { e.preventDefault(); inputChange(e) }}
                                        />
                                    </div>
                                    <div className={`col field ${props.isEdit ? '' : 'required'}`}>
                                        <label>{!props.isEdit ? 'Credit Card Number' : 'Account Number'}</label>
                                        <div className="input-group">
                                            <div className='input-group-text bg-transparent'>
                                                <i className={`icon text-primary large ${cardType ? icons[cardType] : 'credit card'}`} ></i>

                                            </div>
                                            {!props.isEdit ?
                                                <InputMask
                                                    type="text"
                                                    mask={cardType === 'AMEX' ? '9999 9999 9999 999' : '9999 9999 9999 9999'}
                                                    unmask={true}
                                                    // className="form-control"
                                                    placeholder="Credit Card"
                                                    aria-describedby="basic-addon1"
                                                    name="cardNumber"
                                                    value={inputData.cardNumber}
                                                    onChange={e => { e.preventDefault(); inputChange(e) }}
                                                />
                                                :

                                                <InputMask
                                                    style={{ width: 'auto' }}
                                                    type="text"
                                                    mask={cardType === 'AMEX' ? 'XXXX XXXX XXXX 999' : 'XXXX XXXX XXXX 9999'}
                                                    unmask={true}
                                                    disabled
                                                    placeholder="Credit Card"
                                                    name="maskedCardNumber"
                                                    value={inputData?.maskedCardNumber}
                                                    onChange={e => { e.preventDefault(); inputChange(e) }}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className='col-auto' style={{ width: '7rem' }}>
                                        <label>Expiration</label>
                                        <InputMask
                                            type="text"
                                            name="cardExpiry"
                                            mask="99/99"
                                            unmask={true}
                                            value={inputData.cardExpiry}
                                            onChange={e => { e.preventDefault(); inputChange(e) }}
                                            disabled={
                                                props.isEdit ? true : null
                                            }
                                        />
                                    </div>
                                </div>
                            </div> : null}
                            {paymentMethod === "ach" &&
                                <div className="row d-flex">
                                    <div className='col-12'>
                                        <div className="col-6 field required">
                                            <label>Account Name</label>
                                            <input type="text"
                                                name="accountHolderName"
                                                value={inputData.accountHolderName}
                                                onChange={e => { e.preventDefault(); inputChange(e) }} />
                                        </div>
                                        <div className="col-6 field required">
                                            <label>Account Type</label>
                                            <select className="form-control"
                                                name="isCheckingAccount"
                                                value={inputData.isCheckingAccount}
                                                onChange={e => { e.preventDefault(); inputChange(e) }}
                                            >
                                                <option value={true}>Checking</option>
                                                <option value={false}>Savings</option>
                                            </select>
                                        </div>
                                        <div className="col-6 field required">
                                            <label>Routing Number</label>
                                            <input type="number"
                                                // mask="999 999 999"
                                                // unmask={true}
                                                name="routingNumber"
                                                value={inputData.routingNumber}
                                                onChange={e => { e.preventDefault(); inputChange(e) }}
                                            />
                                        </div>
                                        <div className="col-6 field required">
                                            <label>Account Number</label>
                                            <input type="number"
                                                name="accountNumber"
                                                // mask="9999 9999 9999"
                                                // unmask={true}
                                                value={inputData.accountNumber}
                                                onChange={e => { e.preventDefault(); inputChange(e) }} />
                                        </div>
                                        <div className="col-6 field required">
                                            <label>Bank Name</label>
                                            <input type="text"
                                                name="bankName"
                                                value={inputData.bankName}
                                                onChange={e => { e.preventDefault(); inputChange(e) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div title="Billing Address Details">
                    <div className="row d-flex my-3">
                        {selectedPatient?.address?.addressLine1 ? <div className="col-12 field form-check form-switch ms-3 ps-0">
                            <input className="form-check-input ms-0 me-3 mt-2" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                name="sameAsPatientAddress"
                                checked={inputData.sameAsPatientAddress}
                                onChange={e => {
                                    inputChange({ target: { value: e.target.checked, name: "sameAsPatientAddress" } })
                                }} />
                            <label className="form-check-label mt-1" for="flexSwitchCheckDefault">Same as Patient Address</label>
                        </div> : null}
                        {!inputData.sameAsPatientAddress && <div className="col-12 required field">
                            <label>Address Line 1</label>
                            <input type="text"
                                name="addressLine1"
                                value={inputData.address.addressLine1}
                                onChange={e => { addressInputChange(e) }} />
                        </div>}
                        {!inputData.sameAsPatientAddress &&<div className="col-12 field">
                            <label>Address Line 2</label>
                            <input type="text"
                                name="addressLine2"
                                value={inputData.address.addressLine2}
                                onChange={e => { addressInputChange(e) }} />
                        </div>}
                        {!inputData.sameAsPatientAddress && <div className="col-md-6 col-12 required field">
                            <label>City</label>
                            <input type="text"
                                name="city"
                                value={inputData.address.city}
                                onChange={e => { addressInputChange(e) }} />
                        </div>}
                        {!inputData.sameAsPatientAddress && <div className="col-md-3 col-12 required field">
                            <label>State</label>
                            <Select
                                classNamePrefix="react-select"
                                className="react-select-container"
                                options={States[1]}
                                name="state"
                                value={States[1].find((obj) => obj.abbreviation === inputData.address.state)}
                                onChange={(e) => {
                                    addressInputChange({
                                        target: {
                                            value: e.abbreviation,
                                            name: "state",
                                        },
                                    });
                                }}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.abbreviation}
                                disabled={
                                    inputData.sameAsPatientAddress === "true" ? true : null
                                }
                            />
                        </div>}
                        {!inputData.sameAsPatientAddress && <div className="col-md-3 col-12 required field">
                            <label>ZipCode</label>
                            <InputMask
                                // className="form-control"
                                type="text"
                                mask="99999"
                                unmask={true}
                                name="zipCode"
                                value={inputData.address.zipCode || inputData.address.postalCode}
                                onChange={e => { e.preventDefault(); addressInputChange(e) }} />
                        </div>}
                        {/* {!selectedPatient?.address?.addressLine1 && inputData?.address?.addressLine1 && inputData?.address?.city && inputData?.address?.state && inputData?.address?.zipCode?.length >= 5 ?
                            <div className='col-12 my-3'>

                                {patientUpdateConfirm ?
                                    <span className='warning'>Patient Address Updated</span>
                                    :
                                    <span className='warning row remove-gutter d-flex justify-content-between align-items-center'>
                                        <span className='col'>
                                            Would you like to save this address?
                                        </span>
                                        <button href="#" className='col-auto btn btn-primary' onClick={e => { e.preventDefault(); updatePatientAddress() }}>
                                            Yes
                                        </button>
                                    </span>}
                            </div> :
                            null} */}
                    </div>
                </div>
            </AccordionTemplate >
            {errors?.practiceLocationId ? <div className='col-12 my-3 w-100 text-center'><span className='warning'>{errors.practiceLocationId}</span></div> : null}
            <div className='d-flex mt-3 justify-content-between'>
                
                <div className='col-auto'>
                    <button className="btn btn-primary" onClick={e => { e.preventDefault(e); submitHandler() }}>{props.buttonLabel || 'Save'}</button>
                    {props.additionalButton && props.additionalButton}
                </div>
                <div className='col-auto'>
                    <button className="btn btn-secondary" onClick={e => { e.preventDefault(e); if (props.onClose()) { props.onClose() } }}>Close</button>
                </div>
            </div>
        </>
    )
}

export default PatientAccountForm