import React, { useState } from "react";
import PlFormsService from "../../../../../services/api/plforms.service";
import AddFormsPatient from "../add-forms-patient/AddFormsPatient";
import toast from "react-hot-toast";
import DimLoader from "../../../../templates/components/DimLoader";
import APIResponse from "../../../../templates/components/APIResponse";

const AddForms = (props) => {

  const [formData, setFormData] = useState();
  const [errors, setErrors] = useState({});
  const [reloader, setReloader] = useState(false);
  const [isLoader, setIsLoader] = useState(false)
  const [apiResponse, setApiResponse] = useState()
  const onSuccess = (message) => {
      if (props.refresh) {
          props.refresh()
      }
      if (props.onClose) {
          props.onClose()
      }
      if (props.exitHandler) {
          props.exitHandler()
      }
  }
  // // if forms array is empty, use add on submit PlFormsService.createMapFormsWithPatient(???)

  // // if forms array contains forms, check appropriate boxes, use edit submit PlFormsService.editMapFormsWithPatient(???)

  // const submitHandler = (data, mappingId) => {
  //   if(mappingId===undefined || !mappingId || mappingId===""){
  //     PlFormsService.createMapFormsWithPatient({formIds: data, patientId:props.patientId})
  //     .then(res=>{toast.success("Forms Sent");props.onClose()})
  //   }
  //   else{
  //   PlFormsService.editMapFormsWithPatient({formIds: data, patientId:props.patientId}, mappingId)
  //   .then(res=>{toast.success("Forms Updated");props.onClose()})
  //     .catch((err) => { console.log(err) });
  //   }
  // };


  const onSaveFormsMappings = (data, mappingId) => {
    setIsLoader(true)
    if (mappingId == undefined || !mappingId || mappingId == "" || mappingId == null) {
      PlFormsService.createMapFormsWithPatient({formIds:data.map(form=>{return {...form, formId:form?.id || form?.formId}}),patientId:props.patientId})
        .then(res => {
          setApiResponse(res)
        })
        .catch(
          (error) => {
            setApiResponse(error)
        })
        .finally(() => {
          setIsLoader(false)
      });
    } else {
      PlFormsService.editMapFormsWithPatient({formIds:data.map(form=>{return {...form, formId:form?.id || form?.formId}}),patientId:props.patientId}, mappingId)
        .then(res => {
          setApiResponse(res)
        })
        .catch(
          (error) => {
            setApiResponse(error)
        })
        .finally(() => {
          setIsLoader(false)
      });
    }
    // PlFormsService.addSubmission(data)
    //   .then(res => {
    //     console.log(res)
    //   })

  }


  const handleSetReloader = () => {
    setReloader(true)
  }

  return (
    <div>
      {isLoader? <DimLoader loadMessage="Saving"/>:null}
      <AddFormsPatient
        submitHandler={onSaveFormsMappings}
        submitLabel="Submit"
        initialData={formData}
        errors={errors}
        reloader={handleSetReloader}
        isModal={props.isModal}
        onClose={() => props.onClose()}
        patientId={props.patientId}
      />
      <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
    </div>
  );
};

export default AddForms;