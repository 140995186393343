import React, { useState, useEffect } from 'react'
import ProductService from '../../../../../../services/api/product.service'
import APIResponse from '../../../../../templates/components/APIResponse'
import ProductTagForm from './ProductTagForm'

const AddProductTag = (props) => {
    const [apiResponse, setApiResponse] = useState()
    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }

    const submitHandler = (reqObj) => {
        return ProductService.addcustomTags(reqObj)
            .then(res => {
                console.log(res)
                setApiResponse(res);
            })
            .catch(err => {
                setApiResponse(err);
                console.log(err)
            })
    }
    return (
        <div>
            <ProductTagForm
                onClose={() => { () => props.onClose() }}
                initialData={props.initialData}
                submitHandler={submitHandler} />
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </div>
    )
}

export default AddProductTag