import React, { useState, useEffect } from 'react'
import FeesForm from '../fees-form/FeesForm'
import ProductService from '../../../../../../../../services/api/product.service';
import APIResponse from '../../../../../../../templates/components/APIResponse';

const FeesEdit = (props) => {
    const { embed, id, exitEdit, inputChange, refresh } = props;
    const [formData, setFormData] = useState({})
    const [messages, setMessages] = useState({})
    const [isLoaded_form, setIsLoaded_form] = useState(false)
    const [apiResponse,setApiResponse] = useState()
    
    useEffect(() => {
        setIsLoaded_form(false)
        ProductService.getProductById({id:props.id})
            .then(res => {
                setFormData(res)
                return setIsLoaded_form(true)
            })
            .catch(err => { console.log(err); setIsLoaded_form(true) })
    }, [])

    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }

    const submitHandler = (data) => {
        let reqObj = {}
        reqObj.itemType = 4;
        reqObj.feeAmount = data.feeType === "1" ? (parseFloat(data.fee) || 0) : 0;
        reqObj.feePercentage = data.feeType === "2" ? (parseFloat(data.fee) || 0) : 0;
        reqObj.taxPercent = data.feeIsTaxable ? (parseFloat(data.taxPercent) || 0) : 0;
        reqObj.name = data.name;
        reqObj.practiceLocationId = data.practiceLocationId;
        reqObj.feeApplicableTo = +data.feeApplicableTo;
        reqObj.feeRequired = Boolean(data.feeRequired) === true ? 1 : 0;
        reqObj.feeIsTaxable = Boolean(data.feeIsTaxable) === true ? 1 : 0;
        reqObj.id = data.id;
        // setIsLoader(true)
        ProductService.editProduct(reqObj)
            .then(response => {
                setApiResponse(response)
            })
            .catch(error => {
                setApiResponse(error)
            })
    }

    return (
        <div>
            {isLoaded_form && <FeesForm submitHandler={submitHandler} initialData={formData} messages={messages} inputChange={inputChange}
             submitLabel="Update" loaded={isLoaded_form} onClose={() => props.onClose()} />}    
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true}/>    
        </div>
    )
}

export default FeesEdit