import React, { useEffect, useState, useContext } from 'react'
import List from '../../../../../../../templates/components/List';
import Select from "react-select"
import toast from 'react-hot-toast';
import ModalBox from '../../../../../../../templates/components/ModalBox';
import PracticeLocationSelector from '../../../../../../../templates/components/PracticeLocationSelector';
import { store } from '../../../../../../../../context/StateProvider';
import Utilities from '../../../../../../../../services/commonservice/utilities';
import DoctorService from '../../../../../../../../services/api/doctor.service';
import AppointmentService from '../../../../../../../../services/api/appointment.service';
import moment from 'moment';

const ProviderAvailability = (props) => {
    const state = useContext(store).state;
    const [isLoader, setIsLoader] = useState(false)
    const [sortBy, setSortBy] = useState('')

    const [locationId, setLocationId] = useState()
    const [availabilityList, setAvailabilityList] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [practiceLocationPractitionerId, setPracticeLocationPractitionerId] = useState()
    const [selectedSlot, setSelectedSlot] = useState()
    const [doctors, setDoctors] = useState()
    const [selectedDoctorId, setSelectedDoctorId] = useState()

    const dowList = [
        {
            label: 'Sun',
            value: 1
        },
        {
            label: 'Mon',
            value: 2
        },
        {
            label: 'Tue',
            value: 3
        },
        {
            label: 'Wed',
            value: 4
        },
        {
            label: 'Thu',
            value: 5
        },
        {
            label: 'Fri',
            value: 6
        },
        {
            label: 'Sat',
            value: 7
        },
    ]

    useEffect(()=>{
        // should run first time?
        if(!doctors && !props.id){
            doctorLookup();
        }
        else
        if (props.id && !selectedDoctorId) {
            setSelectedDoctorId(props.id)
        }
    },[])

    const doctorLookup = () => {
        const reqObj = { isActive: true, PracticeLocationId: state.practiceLocationId, isRegistered: true };
        DoctorService.doctorLookup(reqObj)
        .then(
            (response) => {
            if (response) {
                setDoctors(response)
            }
            })
        .catch((error) => {
            console.log(error);
        })
    }    

    const onSort = (e) => {
        setSortBy(e.target.value)
    }


    const getPracticeLocationPractitionerId = () => {
        DoctorService.getProviderLocationDetails({ PracticeLocationId: state.practiceLocationId })
            .then((res) => {
                let results;
                if (res?.data) {
                    results = res.data?.find(obj => obj.doctorId == selectedDoctorId && obj.practiceLocationId == state.practiceLocationId)
                    setPracticeLocationPractitionerId(results?.practiceLocationPractitionerId)
                }
                console.log()
            })
            .catch(error => {
                console.log(error)
            })
    }
    const getLocationAvailablity = () => {
        setIsLoader(true);
        let req = {
            PracticeLocationId: state.practiceLocationId,
            DoctorId: selectedDoctorId
        }
        DoctorService.getLocationAvailablity(req)
            .then((res) => {
                console.log(res)

                let response = res?.data?.data?.selectResponse.filter(obj => obj.practiceLocationPractitionerId == practiceLocationPractitionerId)

                response.sort((a, b) => (a.dow > b.dow) ? 1 : ((b.dow > a.dow) ? -1 : 0))
                response.forEach(obj => {
                    obj.startTime = formatAsLocalTime(obj.startTime)
                    obj.endTime = formatAsLocalTime(obj.endTime)
                })

                setAvailabilityList(response);

                setIsLoader(false);
            })
            .catch(error => {
                setIsLoader(false);
                console.log(error)
                // setCheckException(error);
            })
    }

    const formatAsLocalTime = (time) => {
        let timeParts = time.split(":")
        let localDate = moment('1900-01-01T'+timeParts[0]+":"+timeParts[1]+":00.000").format("HH:mm")
        return localDate
    }

    const addHandler = (e) => {
        e.preventDefault();
        let newStateObject = [...availabilityList];
        newStateObject.push({ dow: 0, startTime: '', endTime: '' });
        setAvailabilityList(newStateObject);
    }

    const inputChange = (e, i) => {
        let newStateObject = [...availabilityList];
        newStateObject[i][e.target.name] = e.target.value;
        return setAvailabilityList(newStateObject);
    }

    const saveRow = (src) => {
        if (isOverlapping(availabilityList)) {
            toast.error('Times are overlapping')
        } else {
            if (src.dow && src.startTime && src.endTime && practiceLocationPractitionerId) {
                setIsLoader(true);

                const reqObj = { 
                    dow: src.dow, 
                    startTime: formatAsLocalTime(src.startTime), 
                    endTime: formatAsLocalTime(src.endTime), 
                    practiceLocationPractitionerId:practiceLocationPractitionerId,
                    isEnabled:true,
                    isDeleted:0
                }
                const resObj = src.practiceLocationPractitionerAvailabilityId 
                    ? DoctorService.updateAvailability(src.practiceLocationPractitionerAvailabilityId, reqObj) 
                    : DoctorService.addAvailability(reqObj)

                resObj.then((response) => {
                    // console.log(response)
                    toast.success('Record Updated')
                    setIsLoader(false);
                    console.log(response)
                })
                    .catch(error => {
                        setIsLoader(false);
                        console.log(error)
                    })
            }
        }
    }

    const deleteAvailability = (id, slot) => {
        let reqObj = slot
        reqObj.isDeleted = true
        reqObj.isEnabled = slot.isEnabled == 1 ? true : false
        console.log(id)
        DoctorService.updateAvailability(id, reqObj)
            .then(res => {
                toast.success('Delete Availability successful');
                console.log(res)
                getLocationAvailablity()
            })
            .catch(err => console.log(err))
    }

    const overlapping = (a, b) => {
        const getMinutes = s => {
            const p = s.split(':').map(Number);
            return p[0] * 60 + p[1];
        };
        return getMinutes(a.endTime) > getMinutes(b.startTime) && getMinutes(b.endTime) > getMinutes(a.startTime);
    };

    const isOverlapping = (arr) => {
        let i, j;
        for (i = 0; i < arr.length - 1; i++) {
            for (j = i + 1; j < arr.length; j++) {
                if ((arr[i].practiceLocationId == arr[j].practiceLocationId) && (arr[j].practiceLocationAvailabilityId != arr[i].practiceLocationAvailabilityId) && (arr[i].dow == arr[j].dow) && overlapping(arr[i], arr[j])) {
                    return true;
                }
            };
        };
        return false;
    };

    const doctorSelected = (newDoctorId) => {
        setAvailabilityList();
        setSelectedDoctorId(newDoctorId);
    }
    const locationSelected = (newLocationId) => {
        setAvailabilityList();
        setLocationId(newLocationId);
    }

    useEffect(() => {
        if (!selectedDoctorId && doctors && doctors.length>0) {
            setSelectedDoctorId(doctors[0].id)
        }
    }, [doctors])

    useEffect(async () => {
        if (state?.practiceLocationId) {
            setLocationId(state.practiceLocationId)
        }
    }, [state?.practiceLocationId])

    useEffect(() => {
        if (locationId && selectedDoctorId) {
            getPracticeLocationPractitionerId();
        }
    }, [locationId, selectedDoctorId])
    
    useEffect(() => {
        if (practiceLocationPractitionerId) {
            getLocationAvailablity();
        }
    }, [practiceLocationPractitionerId])


    useEffect(()=>{
        if(selectedSlot){
            console.log(selectedSlot)
        }
    },[selectedSlot])
    return (
        <div className='w-100'>
            {isLoader && <div>
                <div className="ui active dimmer">
                    <div className="ui indeterminate text loader"></div>
                </div>
            </div>}
            <div className='col-12'>
                <div className='field mb-3 input-group'>
                    <PracticeLocationSelector onSelected={locationSelected} />
                </div>
            </div>
            {!props.id && 
            <>
                <div className='col-12'>
                        <label> Provider</label>
                </div>
                <div className='col-12'>
                    <div className='field mb-3 input-group'>
                        <Select
                            options={doctors}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isDisabled={!doctors}
                            name="doctorId"
                            isSearchable
                            placeholder="All Providers"
                            value={doctors && doctors.find(obj => obj.id === selectedDoctorId)}
                            onChange={e => {
                                doctorSelected(e.id);
                            }}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            />
                        <button className='btn btn-primary' title="Add Location" onClick={addHandler}>Add</button>
                    </div>
                </div>
            </> }
            {!isLoader && availabilityList ? <List
                className='col-12'
                sortFunc={onSort}
                // pageSize={3}
                noPaginate={true}
                isLoading={isLoader}
            >
                {availabilityList?.length && availabilityList.map((src, i) => {
                    console.log(src)
                    return (

                        <div class="card mb-3 p-3 availability-bar" key={src.id}>
                            <div class="row d-flex justify-content-between align-items-center">
                                <div class="col-md-3 col-12">
                                    <div class="content">
                                        <div class="ui sub">
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                value={dowList.find(obj => obj.value === src.dow)}
                                                options={dowList}
                                                onChange={e => { inputChange({ target: { value: e.value, name: 'dow' } }, i) }}
                                                isClearable={true}
                                                getOptionLabel={(option) => { return (option.label) }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 col-12">
                                    <div className="content">
                                        <div className="ui">
                                            <input
                                                type='time'
                                                className="form-control"
                                                name="startTime"
                                                value={src.startTime}
                                                onInput={(e) => { e.preventDefault(); inputChange(e, i) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="item col-md-3 col-12">
                                    <div className="content">
                                        <div className="ui">
                                            <input
                                                type='time'
                                                className="form-control"
                                                name="endTime"
                                                value={src.endTime}
                                                onInput={(e) => { e.preventDefault(); inputChange(e, i) }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 col-12">
                                    <div className="content">
                                        <div className="btn-group m-w-100">
                                            <button className='btn btn-secondary' title="Delete Availability" onClick={e => { setSelectedSlot(src); return setDeleteModal(true); }}>Delete</button>
                                            <button className='btn btn-primary' title="Save Availability" onClick={e => { saveRow(src) }}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                }
                )}
            </List> : null}
            <ModalBox open={deleteModal} onClose={() => { setDeleteModal(false) }} title="Delete Block">
                {/* {availabilityList.practiceLocationAvailabilityId && <div className='row d-flex align-items-center justify-content-between'> */}
                <div className='col'>
                    Are you sure you want to delete ?
                </div>
                <div className='col-auto'>
                    <button className='btn btn-secondary' onClick={e => { e.preventDefault(); return setDeleteModal(false) }}>No</button>
                    <button className='btn btn-secondary' onClick={e => { e.preventDefault(); deleteAvailability(selectedSlot?.practiceLocationPractitionerAvailabilityId, selectedSlot); setDeleteModal(false) }}>Yes</button>
                </div>
                {/* </div>} */}
            </ModalBox>
        </div>
    )
}

export default ProviderAvailability