import React from 'react'
import TabsTemplate from '../../../../templates/components/TabsTemplate'
import FormsManagement from '../practice-settings/forms-management/FormsManagement'

const FormsSettings = (props) => {
    return (
        <>
            <TabsTemplate id="formSettings" accordionId="formSettings">
                <div title="Forms Management">
                    <FormsManagement/>
                </div>
            </TabsTemplate>
        </>
    )
}

export default FormsSettings