import React, { useEffect, useState, useContext } from 'react'

import ModalBox from '../../../../../templates/components/ModalBox'
// Common service
import Table2 from '../../../../../templates/components/Table2'

import toast from 'react-hot-toast'
import PaymentOptionsService from '../../../../../../services/api/payment-options.service'
import Module from '../../../../../templates/components/Module'
import PaymentOptionAdd from './payment-option/payment-option-add/PaymentOptionAdd'
import PaymentOptionEdit from './payment-option/payment-option-edit/PaymentOptionEdit'

const PaymentOptionTableSettings = (props) => {
    const { keyword } = props
    const [isLoader, setIsLoader] = useState(false)
    const [editPaymentOption, setEditPaymentOption] = useState(false)
    const [showAdd, setShowAdd] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [paymentOptionList, setPaymentOptionList] = useState([])
    const [selectedPaymentOption, setSelectedPaymentOption] = useState()
    const [activateModal, setActivateModal] = useState(false)
    const hiddenColumns = ["status,action"]

    const activate = (id, paymentOption) => {
        if (paymentOption?.isActive) {
            PaymentOptionsService.deactivate(id)
                .then(res => {
                    toast.success("Payment option is Deactivated")
                    getPaymentOptions()
                })
                .catch(err => {
                    toast.error("Could not Deactivate")
                }).finally(()=>{
                    setActivateModal(false)
                })
        }
        else {
            PaymentOptionsService.activate(id)
                .then(res => {
                    toast.success("Payment Option is Activated")
                    getPaymentOptions()
                })
                .catch(err => {
                    toast.error("Could not Deactivate")
                }).finally(()=>{
                    setActivateModal(false)
                })
        }
    }

  
    const getPaymentOptions = () => {
        setIsLoader(true);
        PaymentOptionsService.getPaymentOptions({})
            .then((response) => {
                setPaymentOptionList(response?.data?.data)
                setIsLoader(false);
            })
            .catch(error => {
                setIsLoader(false);
            })
    }
    useEffect(() => {
            getPaymentOptions()
    }, [])


    const columns = [
        {
            id: "paymentOption",
            Header: "Payment Option",
            accessor: options => options.paymentOption
        }, {
            id: "status",
            Header: "Status",
            align: "center",
            sortable: true,
            accessor: (options) => options.isActive ? <span className='btn btn-success text-white w-100'>Active</span> : <span className='btn btn-danger text-white w-100'>Inactive</span>
        },
        {
            id: "action",
            Header: "Action",
            textAlign: 'center',
            disableSortBy: true,
            accessor: (options) => {
                return (
                    <div className='btn-group'>
                        <button className="p-0 ps-1 btn btn-primary ms-1" title="Edit" onClick={e => {
                            e.preventDefault(); 
                            setSelectedPaymentOption(options);
                            setShowEdit(true);
                            return;
                        }}><i className="icon pencil" /></button>

                        <button className="p-0 ps-1 btn btn-primary ms-1" title={options.isActive ? 'Deactivate' : 'Activate'} onClick={e => { 
                            e.preventDefault(); 
                            setSelectedPaymentOption(options); 
                            return setActivateModal(true) 
                        }}>{options.isActive ? <i className="icon dont" /> : <i className="icon check" />}</button>
                    </div>
                )
            }
        }
    ]
    return (
        <div className='row d-flex'>
                <div className='col-12'>
                <Module title="Payment Options">
                    <Table2 hiddenColumns={hiddenColumns} export searchable columns={columns} data={paymentOptionList} loading={isLoader}
                        extraButtons={[
                            { className: 'btn btn-primary', children: <i className='icon plus' />, onClick: () => { setShowAdd(true) }, title: "Add Payment Option" }
                        ]} />
                        </Module>
                </div>
            <ModalBox open={activateModal} onClose={() => { setActivateModal(false) }} title={selectedPaymentOption?.isActive ? 'Deactivate' : 'Activate'}>
                <div className='row d-flex align-items-center justify-content-between remove-gutter'>
                    <div className='col-md-auto col-12 py-3'>
                        Are you sure you want to {selectedPaymentOption?.isActive ? 'Deactivate' : 'Activate'} {selectedPaymentOption?.paymentOption}?
                    </div>
                    <div className='col-md-auto col-12'>
                        <div className='row d-flex justify-content-between modal-button-bar remove-gutter'>
                            <div className='col-auto'>
                                <button className='btn btn-primary' onClick={e => { e.preventDefault(); activate(selectedPaymentOption?.paymentOptionId, selectedPaymentOption) }}>Yes</button>
                            </div>
                            <div className='col-auto'>
                                <button className='btn btn-secondary ms-3 m-m-0' onClick={e => { e.preventDefault(); setSelectedPaymentOption(); return setActivateModal(false) }}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox open={showAdd} onClose={() => { setShowAdd(false) }} title="Add Payment Option">
                <PaymentOptionAdd onClose={() => { getPaymentOptions(); return setShowAdd(false); }} />
            </ModalBox>
            <ModalBox open={showEdit && selectedPaymentOption} onClose={() => { setShowEdit(false) }} title="Edit Payment Option">
                <PaymentOptionEdit initialData={selectedPaymentOption} onClose={() => { getPaymentOptions(); return setShowEdit(false); }} />
            </ModalBox>
        </div>
    )
}

export default PaymentOptionTableSettings