import React, { useEffect, useState } from 'react'
import InvoiceService from '../../../../../services/api/invoice.service'
import ModalBox from '../../../../templates/components/ModalBox'
import VirtualTerminalForm from '../../transactions/terminals/virtual-terminal/virtual-terminal-form/VirtualTerminalForm'
import InvoiceForm from '../invoice-form/InvoiceForm'
import APIResponse from '../../../../templates/components/APIResponse';
const AddInvoice = (props) => {
    const [showVirtTrans, setShowVirtTrans] = useState(false)
    const [invoice, setInvoice] = useState()
    const [isLoader, setIsLoader] = useState(false)
    const [invoiceStatus, setInvoiceStatus] = useState()
    const [apiResponse, setApiResponse] = useState()
    const [initialData, setInitialData] = useState(props.initialData||{})
    const [collectPaymentClicked, setCollectPaymentClicked] = useState(false)
    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }

    const addInvoiceSubmit = (data, selectedPatient, items,providerId, invoiceType,isPaymentClicked) => {
        setIsLoader(true)
        let reqObj = data
        if (reqObj.id) {
            reqObj.appointmentId = reqObj.id;
        }
        reqObj.autoClaimStatus = false
        reqObj.doctorId = items.find(obj => obj.doctorId !== undefined)?.doctorId || providerId
        reqObj.invoiceDate = data.invoiceDate ? new Date(data.invoiceDate) : new Date()
        reqObj.dueDate = data.dueDate ? new Date(data.dueDate) : new Date()
        reqObj.serviceDate = data.serviceDate ? new Date(data.serviceDate) : new Date()
        reqObj.operationType = 2
        reqObj.patientId = selectedPatient.id
        reqObj.patientName = data.patientName || selectedPatient.name || selectedPatient.firstName + ' ' + selectedPatient.lastName
        reqObj.invoiceStatus = data.invoiceStatus || 2
        reqObj.practiceLocationId = data.practiceLocationId
        reqObj.visitDate = new Date(data.serviceDate)
        reqObj.items = items.map(item=> {
            let newItem = item;
            if(item.itemType === 4 ){
                //calculated from ui
                newItem.unitPrice = item.feesEachSubTotal ? parseFloat(item.feesEachSubTotal) : 0  
                newItem.taxAmount =item.feesEachTaxAmount ? parseFloat(item.feesEachTaxAmount) : 0
            }else{
                newItem.unitPrice = item.unitPrice ? parseFloat(item.unitPrice) : 0
            }
            
            newItem.taxPercent =item.taxPercent ? parseFloat(item.taxPercent) : 0
            newItem.discount = item.discount ? parseFloat(item.discount) : 0
            newItem.quantity = item.quantity ? parseFloat(item.quantity) : 1
            newItem.feeAmount = item.feeAmount ? parseFloat(item.feeAmount) : 0
            newItem.feePercentage = item.feePercentage ? parseFloat(item.feePercentage) : 0
            return newItem
        })
        reqObj.taxAmount = data.itemTotalTax
        if (invoiceType == 1) {
            reqObj.isCreditBalance = true
        }
        setInvoiceStatus(data.invoiceStatus)
        return InvoiceService.addInvoice(reqObj)
            .then((res) => {
                setIsLoader(false)
                setInvoice(res.data)
                setCollectPaymentClicked(isPaymentClicked?isPaymentClicked:false)
                setApiResponse(res)
                if(isPaymentClicked)
                {   
                    return setShowVirtTrans(true)
                }
            })
            .catch((err) => {
                setIsLoader(false)
                setApiResponse(err);
            });
    }

    useEffect(() => {
        if (isLoader === true) {
            setTimeout(() => {
                if (isLoader === true) {
                    setIsLoader(false)
                }
            }, 30000)
        }
    }, [isLoader])

    useEffect(()=>{
        if(props.initialData){
            setInitialData(props.initialData)
        }
    }
    ,[props.initialData])
    return (
        <div>
            <InvoiceForm isLoader={isLoader} selectPatientDisabled={props.selectPatientDisabled} hideCancel={props.hideCancel} initialData={initialData} isModal={props.isModal} onClose={() => { if (props.onClose) { props.onClose() } }} submitLabel="Add" submitHandler={addInvoiceSubmit} />
            <ModalBox open={showVirtTrans && invoice} onClose={() => {
                setShowVirtTrans(false); if (props.onClose) {
                    props.onClose()
                }
            }}>
                <VirtualTerminalForm initialData={{ ...invoice, taxAmount: invoice?.itemTotalTax, adjustedUnpaidAmount: invoice?.adjustedUnpaidAmount ? invoice?.adjustedUnpaidAmount : invoice?.finalAmount }} onClose={() => {
                    setShowVirtTrans(false); if (props.onClose) {
                        props.onClose()
                    }
                }} size="large" isModal />
            </ModalBox>
            <APIResponse apiResponse={apiResponse} toastOnSuccess={true} onSuccess={collectPaymentClicked?'':onSuccess} />
        </div>
    )
}
export default AddInvoice