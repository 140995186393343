import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import AccessRightsService from '../../../../../../services/api/access-rights.service'
import FeaturesAccessService from '../../../../../../services/api/features-access.service'

const ModuleAccess = (props) => {
    const [isLoader, setIsLoader] = useState(true)
    const [moduleAccess, setModuleAccess] = useState()

    const getModules = (newModules) => {
        setIsLoader(true)
        AccessRightsService.getModuleConfig(props.practiceId)
            .then(res => {
                console.log(res.data.data)
                let accessList = [...res.data.data]
                newModules.forEach(obj => {
                    obj.parentId = props.practiceId
                    accessList.forEach(access => {
                        if (obj.name == access.name && access.hasAccess == 1) {
                            obj.hasAccess = true
                        }
                        if (obj.name == access.name) {
                            obj.id = access.id
                        }
                    })
                })
                setIsLoader(false)
                return setModuleAccess(newModules)
            })
            .catch(err => {
                console.log(err)
                setIsLoader(true)
            })
    }

    const getDefaultModules = () => {
        FeaturesAccessService.getDefaultFeaturesAccess(1)
            .then(res => {
                let newModules = res.data.map(obj => {
                    let newObj = { hasAccess: false, moduleId: obj.id, name: obj.name, userType: 1 }
                    if (obj.name == 'Patient Management' || obj.name == 'Settings') {
                        newObj.hasAccess = true;
                    }
                    return newObj
                })
                if (props.practiceId) {
                    getModules(newModules)
                }
                else {
                    setModuleAccess(newModules)
                }
            })
    }

    const updateModule = (i, checked) => {
        if (props.isEmbedded && props.updateModule) {
            props.updateModule(i, checked)
        }
        else {
            let newObj = [...moduleAccess]
            newObj[i].hasAccess = checked
            return setModuleAccess(newObj)
        }
    }

    useEffect(() => {
        if (props.practiceId && !props.isEmbedded) {
            getDefaultModules()
        }
        else if (props.moduleAccess) {
            setModuleAccess(props.moduleAccess)
        }
    }, [props.practiceId, props.moduleAccess])

    const submitHandler = () => {
        FeaturesAccessService.postModuleAccess({ moduleConfig: moduleAccess }, props.practiceId)
            .then(res => {
                console.log(res)
                toast.success("Practice is Updated")
                if (props.onClose) {
                    props.onClose()
                }
                getDefaultModules()
            })
            .catch(err => {
                console.log(err)
            })
    }
return (
    <div className='row d-flex'>
        <div className='col-12'>
            <div className='col-12 mt-3'>
                <table className='table'>
                    <colgroup>
                        <col span="1" />
                        <col span="1" style={{ width: '45px' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th className="d-flex justify-content-start">Module Name</th>
                            <th className='d-flex justify-content-start field form-check form-switch notif-switch-row'>
                                Access
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoader && <tr><td colSpan={2}>Modules are Loading...</td></tr>}
                        {moduleAccess && moduleAccess.map((module, i) => {
                            return (
                                <tr>
                                    <td>{module.name}</td>
                                    <td className="row d-flex field form-check form-switch notif-option">
                                        <input className="mx-0 form-check-input notif-option-input" onChange={e => { updateModule(i, e.target.checked) }} checked={module?.hasAccess} name='hasAccess' type="checkbox" role="switch" id="flexSwitchCheckDefault" disabled={module?.isDisabled} />
                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>
        </div>
        <div className='col-12'>
            <div className='row d-flex mt-3 justify-content-between modal-button-bar'>
                <div className="col-auto">
                    {!props.isEdit ? <button onClick={e => { e.preventDefault(); props.onClose() }} className="btn btn-secondary">Close</button> : null}
                </div>
                <div className="col-auto">
                    {!props.isEmbedded ? <button onClick={e => { e.preventDefault(); submitHandler() }} className="btn btn-primary">{props.isEdit ? 'Update' : 'Submit'}</button> : null}
                </div>
            </div>
        </div>
    </div>
)
}

export default ModuleAccess