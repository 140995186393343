import './preview-form.scss'
import React, { useState } from 'react'
import { Form } from '@formio/react';
const PreviewForm = (props) => {
  return (
    <>
      <h2>{props?.form?.formTitle}</h2>
      <p>{props?.form?.formDescription}</p>
      <Form form={{ display: 'form', components: props?.form?.fields?.components }}/>
    </>
  )
}

export default PreviewForm