import React, { useContext, useEffect, useState } from 'react';
import ProviderService from "../../../../../../services/api/provider.service";
import DoctorService from "../../../../../../services/api/doctor.service";
import ModalBox from "../../../../../templates/components/ModalBox";
import EditDoctor from "../../../doctor/edit-doctor/EditDoctor";
import AddDoctor from "../../../doctor/add-doctor/AddDoctor";
import label from "../../../../../../../assets/i18n/en.json"

const PracticeLocationProvider = (props) => {
    const [isLoader, setIsLoader] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [editProvider, setEditProvider] = useState(false)
    const [showAdd, setShowAdd] = useState(false)
    const [providerList, setProviderList] = useState()
    const [selectedProvider, setSelectedProvider] = useState()
    const getProviders = () => {
        setIsLoader(true);
        DoctorService.find({isActive: true, isRegistered: true, SortField: 'firstName'})
            .then((resp) => {
                let allProviders = resp.data
                console.log(allProviders)
                ProviderService.getProvidersForSelectedLocation({
                    PracticeLocationId: props.id,
                }).then(response => {
                    let selectedProviders = response.data
                    console.log(selectedProviders)
                    selectedProviders.forEach(selectedProvider => {
                        allProviders.forEach(obj => {
                            if (obj.id === selectedProvider.doctorId) {
                                obj.isChecked = true
                                obj.practiceLocationPractitionerId = selectedProvider.practiceLocationPractitionerId
                                obj.noSelfSchedule = selectedProvider.noSelfSchedule
                            }
                        })
                    })
                    setSelectedProvider(selectedProviders)
                    setProviderList(allProviders)
                    setIsLoader(false);
                }).catch(console.log);

            })
            .catch(error => {
                setIsLoader(false);
            })
    }



    useEffect(() => {
        getProviders();
    }, [props.id]);


const removeProvider = (providerId,practiceLocationPractitionerId) =>{
    ProviderService.updateProvidersForSelectedLocation({
        practiceLocationId: props.id,
        doctorId: providerId,
        isDeleted: 1
    }, practiceLocationPractitionerId).then((res) => {
        getProviders();
    }).catch(console.log);
}
const updateNoSelfScheduleForProvider = (providerId,practiceLocationPractitionerId,noSelfSchedule) =>{
    ProviderService.updateProvidersForSelectedLocation({
        practiceLocationId: props.id,
        doctorId: providerId,
        noSelfSchedule: noSelfSchedule
    }, practiceLocationPractitionerId).then((res) => {
        getProviders();
    }).catch(console.log);
}
const addProvider = (providerId) =>{
    ProviderService.createProvidersForSelectedLocation({
        practiceLocationId: props.id,
        doctorId: providerId,
    }).then((res) => {
        getProviders();
    }).catch(console.log);
}
    return (
        <div>
            {isLoader && <div className="ui">
                <div className="ui active dimmer">
                    <div className="ui indeterminate text loader">{label.common.processing}</div>
                </div>
            </div>}
            <div className='d-flex row'>
                <div className='col-md-8 col-12'>
                    <div className='card'>
                        <div className='card-header'>Active Providers</div>
                        <div className='card-body row d-flex'>
                            {providerList && providerList.filter(obj => obj.isChecked).map(provider => {
                                return (
                                    <div className='col-md-3 col-12'>
                                        <span title="Remove from Location" className='text-start point' onClick={e => { e.preventDefault(); removeProvider(provider.id,provider.practiceLocationPractitionerId) }}><i className='icon minus small' /> {provider.firstName} {provider.lastName} </span>
                                        <i onClick={e => { e.preventDefault(); updateNoSelfScheduleForProvider(provider.id,provider.practiceLocationPractitionerId,provider.noSelfSchedule === 0 ? 1 : 0); }} className={provider.noSelfSchedule ? 'icon calendar times' : 'icon calendar check'} title={provider.noSelfSchedule ? "Not available for self-scheduling" : "Available for self-scheduling"} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='col-md-4 col-12'>
                    <div className='card'>
                        <div className='card-header'>Inactive Providers</div>
                        <div className='card-body row d-flex'>
                            {providerList && providerList.filter(obj => !obj.isChecked).map(provider => {
                                return (
                                    <div className='col-md-6 col-12'>
                                        <span title="Add to Location" className='text-start point' onClick={e => { e.preventDefault(); console.log(provider); addProvider(provider.id) }}><i className='icon plus small' /> {provider.firstName} {provider.lastName}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <ModalBox open={deleteModal} onClose={() => { setDeleteModal(false) }}>
                <div className='row d-flex align-items-center justify-content-between'>
                    <div className='col'>
                        Are you sure you want to delete {selectedProvider?.name}?
                    </div>
                    <div className='col-auto'>
                        <button className='btn btn-primary' onClick={e => { e.preventDefault(); deleteItem(selectedProvider?.id) }}>Yes</button>
                        <button className='btn btn-secondary ms-3' onClick={e => { e.preventDefault(); setSelectedProvider(); return setDeleteModal(false) }}>No</button>
                    </div>
                </div>
            </ModalBox>
            <ModalBox open={editProvider} onClose={() => { setEditProvider(false) }} title="Edit Provider">
                <EditDoctor id={selectedProvider?.id} onClose={() => { setEditProvider(false); getProviders() }} />
            </ModalBox>
            <ModalBox open={showAdd} onClose={() => { setShowAdd(false) }} title="Add Provider">
                <AddDoctor onClose={() => { setShowAdd(false); getProviders() }} />
            </ModalBox>
        </div>
    )
}

export default PracticeLocationProvider;