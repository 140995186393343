import React, { useState, useEffect, useContext } from 'react'
import toast from 'react-hot-toast'
import { Dropdown } from 'semantic-ui-react'
import PatientAccountService from '../../../../../../../../services/api/patient-account.service'
import ModalBox from '../../../../../../../templates/components/ModalBox'
import PatientAccountEdit from '../../../../../patient-account/patient-account-edit/PatientAccountEdit'
import { store } from '../../../../../../../../context/StateProvider'
import APIResponse from '../../../../../../../templates/components/APIResponse'
import PatientAccountRenew from '../../../../../patient-account/patient-account-renew/PatientAccountRenew'
const WalletCard = (props) => {
    let detail = props.detail
    const [editCard, setEditCard] = useState(false)
    const [renewCard, setRenewCard] = useState(false)
    const [deactivate, setDeactivate] = useState(false)
    const [activate, setActivate] = useState(false)
    const [deleteAccount, setDeleteAccount] = useState(false)
    const state = useContext(store).state
    const [apiResponse, setApiResponse] = useState()

    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }
    const activatePatientAccount = () => {
        PatientAccountService.activatePatientAccount(props.patientId, props.detail.id)
        .then(response => {
            setApiResponse(response)
        })
        .catch(error => {
            setApiResponse(error)
        })
        .finally(() => {
            setActivate(false)
        });
    }
    const deactivatePatientAccount = () => {
        PatientAccountService.inactivatePatientAccount(props.patientId, props.detail.id)
        .then(response => {
            setApiResponse(response)
        })
        .catch(error => {
            setApiResponse(error)
        })
        .finally(() => {
            setDeactivate(false)
        });
    }
    const deletePatientAccount = () => {
        PatientAccountService.deletePatientAccount(props.patientId, props.detail.id)
        .then(response => {
            setApiResponse(response)
        })
        .catch(error => {
            setApiResponse(error)
        })
        .finally(() => {
            setDeleteAccount(false)
        });
    }
    return (
        <div className="card p-3 justify-content-center">
            <div className="bg-white">
                <div className="row g-3">
                    <strong>
                        <h5 className="col-12 text-center pt-3 mb-3">
                            <span className='d-inline-block text-truncate'>
                                {detail.cardType === "VISA" && (
                                    <i className="icon cc visa"></i>
                                )}
                                {detail.cardType === "MASTERCARD" && (
                                    <i className="icon cc mastercard"></i>
                                )}
                                {detail.cardType === "DISCOVER" && (
                                    <i className="icon cc visa"></i>
                                )}
                                {detail.cardType === "AMEX" && (
                                    <i className="icon cc amex"></i>
                                )}
                                {detail.cardType === "blank" && (
                                    <i className="icon credit-card"></i>
                                )}
                                {detail.cardType === "DINERS" && (
                                    <i className="icon cc diners club"></i>
                                )}
                                {detail.cardType === "JCB" && (
                                    <i className="icon cc jcb"></i>
                                )}
                                {!detail.cardType && (
                                    <i className="icon money bill"></i>
                                )}
                                {detail.maskedCardNumber} {detail.maskedAccountNo}
                                <div className='col-md-6 col-12'>{detail.accountHolderName}</div>
                            </span>
                        </h5>
                    </strong>
                </div>
            </div>

            <div className="row text-center">
                {detail.maskedCardNumber && <span className="w-100">****-****-****-{detail.maskedCardNumber}</span>}
                {detail.maskedAccountNo && <span className="w-100">Account Ending In {detail.maskedAccountNo}</span>}
                <br />
                {detail.cardExpiry && <span className="w-100">Valid Through: {detail?.cardExpiry?.toString().substr(0, 2)}/{detail?.cardExpiry?.toString().substr(2, 2)}</span>}
                {detail.bankName && <span className="w-100">{detail.bankName}</span>}
                {detail.practiceLocationId && state.practiceLocations?.length>1 ? <span className="w-100">{state?.practiceLocations?.find(obj=>obj.practiceLocationId ==detail.practiceLocationId)?.practiceLocation}</span>:null}
                <div className="col-12 row d-flex justify-content-center m-0 mt-3">
                    <div className="btn-group col-auto">
                        <div className={props.detail.isActive ? 'btn btn-primary' : 'btn btn-secondary'}>{props.detail.isActive === 0 ? 'Inactive' : 'Active'}</div>
                        {props.detail.isActive === 1 ? <button className="btn btn-primary" title="Deactivate" onClick={e => { e.preventDefault(); setDeactivate(true) }}>
                            <i className="icon ban" />
                        </button>:<button className="btn btn-primary" title="Activate" onClick={e => { e.preventDefault(); setActivate(true) }}>
                            <i className="icon power" />
                        </button>}
                        {props.detail.isActive === 1 && <button className="btn btn-primary" title="Change Card Info" onClick={e => { e.preventDefault(); setEditCard(true) }}>
                            <i className="icon pencil" />
                        </button>}
                        <button className="btn btn-primary" title="Delete Account" onClick={e => { e.preventDefault(); setDeleteAccount(true) }}>
                            <i className="icon trash alternate outline" /></button>
                            {props.detail.isActive === 1 &&<button className="btn btn-primary" title="Renew Account" onClick={e => { e.preventDefault(); setRenewCard(true) }}>
                            <i class="sync icon"></i></button>}
                            
                    </div>
                </div>
            </div>
            <ModalBox open={editCard} onClose={() => { setEditCard(false) }} title="Edit Patient Account">
                <PatientAccountEdit account={props.detail} patientId={props.patientId} onClose={() => {props.refresh(); return setEditCard(false) }} />
            </ModalBox>
            <ModalBox open={renewCard} onClose={() => { setRenewCard(false) }} title="Renew Patient Account">
                <PatientAccountRenew account={props.detail} patientId={props.patientId} onClose={() => {props.refresh(); return setRenewCard(false) }} />
            </ModalBox>
            <ModalBox open={deactivate} onClose={() => { setDeactivate(false) }} size="small" confirmButton="Yes" onCloseSuccess={() => { deactivatePatientAccount(); }}>
                Are you sure you want to deactivate this card?
            </ModalBox>
            <ModalBox open={activate} onClose={() => { setActivate(false) }} size="small" confirmButton="Yes" onCloseSuccess={() => { activatePatientAccount(); }}>
                Are you sure you want to activate this card?
            </ModalBox>
            <ModalBox open={deleteAccount} onClose={() => { setDeleteAccount(false) }} size="small" confirmButton="Yes" onCloseSuccess={() => { deletePatientAccount(); }}>
                Are you sure you want to permanently delete this card?
            </ModalBox>
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </div>
    )
}

export default WalletCard