import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import CommonService from '../../../../../../services/api/common.service'
import EmailSettingsService from '../../../../../../services/api/email-settings.service'
import ProviderService from '../../../../../../services/api/provider.service'
import SettingsService from '../../../../../../services/api/settings.service'
import Utilities from '../../../../../../services/commonservice/utilities'
import StorageService from '../../../../../../services/session/storage.service'
import Module from '../../../../../templates/components/Module'

const EmailSettings = (props) => {
    const [newEmail, setNewEmail] = useState()
    const [email, setEmail] = useState()
    const [isVerified, setIsVerified] = useState(true)

    const updateEmail = () => {
        EmailSettingsService.putEmailSettings({ fromEmail: newEmail })
            .then(res => {
                let userData = JSON.parse(StorageService.get('session', 'userDetails'))
                userData.contact.email === newEmail
                StorageService.save('session', "userDetails", JSON.stringify(userData));
                console.log(res)
                setNewEmail("")
                verifyIdentity()
                return getEmail()
            })
    }
    const verifyIdentity = () => {
        EmailSettingsService.verifyIdentity()
            .then(res => {
                toast.success("Verification Email Sent")
                console.log(res)
            })
    }
    const getEmail = () => {
        ProviderService.getProviderDetails(CommonService.getLoggedInData().parentId)
            .then(res => {
                setEmail(res.fromEmail?.toLocaleLowerCase() || "None")
            })
        EmailSettingsService.isVerifiedIdentity()
            .then(res => {
                console.log(res)
                setIsVerified(res.data.isEmailVerified)
            })
    }
    useEffect(() => {
        getEmail()
    }, [])
    return (

        <div className='col m-p-0'>
            <Module title="Change Email">
                <div className='col-12 field'>
                    <label>Current Email</label>
                    <div className='input-group'>
                        <input type="email" name="currentemail" disabled value={email} placeholder='Loading Email Address...'/>
                        {(!isVerified && email!="None") ? <button className='btn btn-danger' title='Verify Identity' onClick={e => { e.preventDefault(); verifyIdentity(); }}>Verify Identity</button> : null}
                    </div>
                </div>
                <div className='col-12 field required'>
                    <label>New email</label>
                    <input type="email" name="newemail" value={newEmail} onChange={e => { e.preventDefault(); setNewEmail(e.target.value.toLocaleLowerCase()) }} />
                </div>
                <div className='col-12 d-flex justify-content-end mt-3 '>
                    <div className='col-auto'>
                        <button className='btn btn-primary' onClick={e => { e.preventDefault(); updateEmail() }}>Change Email</button>
                    </div>
                </div>
            </Module>
        </div>
    )
}

export default EmailSettings