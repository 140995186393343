import React, { useEffect, useState, useContext } from "react";
import ProviderService from "../../../../../services/api/provider.service";
import moment from 'moment'
import Utilities from "../../../../../services/commonservice/utilities";
import { store } from "../../../../../context/StateProvider";
import Module from "../../../../templates/components/Module";
import Table2 from "../../../../templates/components/Table2";
import DatePicker from "../../../../templates/components/DatePicker";
import DimLoader from "../../../../templates/components/DimLoader";

const NoShow = (props) => {
    const [noShowData, setNoShowData] = useState();
    const [isLoader, setIsLoader] = useState(false)
    const [offsetHour, setOffsetHour] = useState(moment().utcOffset() >= 0 ? Math.floor(moment().utcOffset() / 60) : Math.ceil(moment().utcOffset() / 60),)
    const [offsetMinute, setOffsetMinute] = useState(moment().utcOffset() % 60)
    const [slabBy, setSlabBy] = useState('Month')
    const [startDate, setStartDate] = useState(moment().startOf("D"));
    const [endDate, setEndDate] = useState(moment().endOf("D"));
    const state = useContext(store).state
    const [initialMessage, setInitialMessage] = useState(true)
    const [locationId, setLocationId] = useState(state.practiceLocationId || null)
  

    const getNoShow = () => {
        setInitialMessage(false);
        setIsLoader(true)
        var reqObj = {
            FromDate: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
            ToDate: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
        }
        if (locationId) reqObj.LocationIds = locationId
        ProviderService.getNoShow(reqObj).then(data => {
            setNoShowData(data);
            setIsLoader(false)
        }).catch(console.log);
    }


    const equipmentTypeColumns = [
        {
            id: "fromDate",
            Header: "Date",
            accessor: record => {
                return moment(record.fromDate).format('l')
            }
        },
        {
            id: "patientName",
            Header: "Client",
            accessor: record => record.patientName
        },
        {
            id: "patientPhone",
            Header: "Mobile Phone",
            accessor: (patient) => Utilities.toPhoneNumber(patient.patientPhone)
        },
        {
            id: "practiceServiceType",
            Header: "Service",
            accessor: record => record.practiceServiceType
        },
        {
            id: "doctorName",
            Header: "Provider",
            accessor: record => record.doctorName
        }
    ]

    return (
        <>
        {isLoader &&
            <DimLoader loadMessage="Processing" />
        }    
    
        <div className="row d-flex g-3">
            <div className='col-12 m-p-0'>
                <Module title="Filters">
                    <div className='row d-flex'>
                        <div className='col-12 row d-flex justify-content-center mx-0 align-items-end report-filters'>
                            <DatePicker setLocationId={setLocationId} startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} />
                            <div className="col-md-auto col-12"><button className="btn btn-primary" onClick={e => { e.preventDefault(); getNoShow() }} title="Pull Report"><i className="icon arrow circle right" /></button></div>
                        </div>
                    </div>
                </Module>
            </div>
            <div className="col-12 m-p-0">
                <Module title="No Show Report">
                    <Table2
                        export
                        searchable
                        data={noShowData}
                        columns={equipmentTypeColumns}
                        loading={false}
                        title='No Show Report'
                        initialMessage={initialMessage}
                    />
                </Module>
            </div>
        </div>
        </>
        )
}

export default NoShow;