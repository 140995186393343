import './TwoWay.scss';
import React, { useState, useEffect,useContext,useRef } from 'react'
import Utilities from './../../../../services/commonservice/utilities';
import SMSMessages from './SMSMessages'
import SMSConversations from './SMSConversations'
import ModelessBox from "./../../../../components/templates/components/ModelessBox"
import NotificationService from "./../../../../services/api/notification.service";
import { store } from '../../../../context/StateProvider';
import APIResponse from '../../../templates/components/APIResponse';

const TwoWay = (props) => {
    const globalStateAndDispatch = useContext(store);
    const contextState = globalStateAndDispatch.state;
    const [messages, setMessages] = useState();
    const [patientId, setPatientId] = useState();
    const [conversations, setConversations] = useState();
    const [showConversations, setShowConversations] = useState(true);
    const [menuIcon, setMenuIcon] = useState(`icon angle right`);
    const [patientName, setPatientName] = useState('');
    const [filteredId, setFilteredId] = useState();
    const [filteredName, setFilteredName] = useState();
    const [isLoading, setIsLoading ] = useState(false);
    const [clearPatientName, setClearPatientName ] = useState();
    
    const [apiResponse, setApiResponse] = useState()
    // const [isLoadingConversations, setIsLoadingConversations ] = useState(false);
    const childRef = useRef();

    const onSendMessage = (message) => {
        let notificationTracking = { message: message,
            practiceLocationId: contextState.practiceLocationId }
        NotificationService.sendSMS(notificationTracking, patientId)
        .then((res) => {
            setApiResponse(res);
        })
        .catch((err) => {
            setApiResponse(err);
        });
    };

    useEffect(() => {
        Utilities.on("patientAlert", (data)=>{
            getConversations();
        });
        // getConversations();
    }, []);    

    useEffect(() => {
        if (patientId){
            getMessagesForPatient();
            childRef.current.onChange()
        }
    }, [patientId]);          

    const getMessagesForPatient = (_patientId) => {
        if (patientId || _patientId) {
            let reqObj = {
                PatientId:patientId || _patientId,
                NotificationMethod:2,
                practiceLocationId:contextState.practiceLocationId
            }
            setIsLoading(true)
            NotificationService.communicationsLookup(reqObj)
            .then((res) => {
                setIsLoading(false)
                if (res?.data?.length>0) {
                    let newMessages = new Array(...(res.data.sort((a, b) => { return new Date(a.createdOn) - new Date(b.createdOn) } )));
                    setMessages(newMessages);
                    setPatientName(res?.data[0].fullName);
                    let reqObj = { practiceLocationId: contextState.practiceLocationId }
                    NotificationService.clearPendingMessages(patientId || _patientId,reqObj)
                    .then((res) => {
                    })
                    .catch((err) => {
                    });

                } 
                else {
                    setMessages();
                    //added to fix HPT-13757
                    if(clearPatientName){
                        setPatientName();
                    }
                }
            })
            .catch((err) => {
                setIsLoading(false)
            });
        }
        else {
            // setMessages();
        }
    }    

    let isLoadingConversations = false;
    const getConversations = () => {
        if (isLoadingConversations) return;
        isLoadingConversations = true;
        setConversations()
        NotificationService.getConversations(filteredId,contextState.practiceLocationId)
            .then(res => {
                if (res) {
                    setConversations(res?.data)
                    if (!patientId && res?.data && res?.data.length>0 && res?.data[0].patientId)
                    {
                        setPatientId(res?.data[0].patientId);   
                    }
                    setTimeout(getMessagesForPatient(res?.data[0].patientId), 1000);                        
                    return;
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(()=>{                
                isLoadingConversations = false;
            })
    }

    const onSelectPatient = (newPatientId) => {
        setPatientId(newPatientId);
    }

    const onFilterPatient = (patient) => {
        if (patient?.id) {
            setFilteredId(patient.id);
            setFilteredName(patient.firstName + ' ' + patient.lastName);
        }
        else
        {
            setFilteredId();
            setFilteredName();
        }
    }

    useEffect(() => {
        getConversations();
    }, [filteredId]);         

    const startNewConversation = () => {
        setPatientId(filteredId);
        setPatientName(filteredName);
    }

    const onToggleConversations = () => {
        if (showConversations) {
            setMenuIcon(`icon angle left`);
        }
        else {
            setMenuIcon(`icon angle right`);
        }
        setShowConversations(!showConversations);
    }

    return ( 
        <div>
            <ModelessBox open={true} onClose={props.onClose} menuIcon={menuIcon} title={'Messages'} onMenuClick={onToggleConversations} >
                <div className="two-way">
                    {showConversations ? 
                        <SMSConversations conversations={conversations} setClearPatientName={setClearPatientName} startNewConversation={startNewConversation} onFilterPatient={onFilterPatient} filteredId={filteredId} onSelectPatient={onSelectPatient} patientId={patientId} />
                    :null}
                    <SMSMessages ref={childRef} setIsLoading={setIsLoading} isLoading={isLoading} patientName={patientName} patientId={patientId} messages={messages} onSendMessage={onSendMessage} />
                    <APIResponse apiResponse={apiResponse} toastOnFailure={true}/>
                </div>
            </ModelessBox>
        </div>
    )
}

export default TwoWay