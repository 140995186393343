import React, { useEffect, useState } from 'react'
import { Accordion, Icon } from 'semantic-ui-react';
import Select from 'react-select'
import AccessRightsService from '../../../../../../../services/api/access-rights.service';
import RoleService from '../../../../../../../services/api/role.service';
import axios from 'axios';
import label from '../../../../../../../../assets/i18n/en.json'

const UserRolesForm = (props) => {
    const [inputData, setInputData] = useState(props.initialData)
    const [isLoader, setIsLoader] = useState(false)
    const [featureList, setFeatureList] = useState()
    const [activeIndex, setActiveIndex] = useState()
    const [masterRoles, setMasterRoles]=useState()
    // function for input change
    const inputChange = (e) => {
        let newStateObject = { ...inputData };
        newStateObject[e.target.name] = e.target.value;
        console.log(newStateObject);
        return setInputData(newStateObject);
    };

    const ModuleEnum = {
        1: 'Patient Management',
        2: 'Patient Checkout',
        3: 'Claims Management',
        4: "Transactions",
        5: "Payment Plans",
        6: "Settings",
        7: "Insurance Management",
        8: "User Management",
        9: "Provider Management",
        10: "Forms Management",
        11: "Training Videos",
        12: "SMS Notifications",
        13: "Email Notifications",
        14: "Appointment Management",
        15: "Reports",
        16: "Eligibility Management",
        17: "Role Management"
    }
    useEffect(() => {
        RoleService.getMasterRole()
        .then(res=>{console.log(res);
            setMasterRoles(res.data.data)})
        .catch(
            err=>console.log(err)
            )
    },[])

    useEffect(() => {
        setIsLoader(true)
        let reqObj = {}
        if (props.practiceId) {
            reqObj.practiceId = props.practiceId
        }  
        AccessRightsService.getfeatureConfig(reqObj)
            .then(res => {
                setIsLoader(false)
                setFeatureList(res)
                return console.log(featureList)
        })
    }, [])

    useEffect(() => {
        if (props.initialData) {
            setInputData(props.initialData)
        }
    }, [props.initialData])
    return (
        <div>
        {isLoader  && <div className="ui">
                <div className="ui active dimmer">
                    <div className="ui indeterminate text loader">{label.common.processing}</div>
                </div>
        </div>}
        <div className='row d-flex'>
            <div className='col-12'>
                <div className='field required'>
                    <label>Name</label>
                    <input type="text" name="roleName" value={inputData.roleName} onChange={e => { e.preventDefault(); inputChange(e) }} />
                </div>
            </div>
            <div className='col-12'>
                <div className='field'>
                    <label>Description</label>
                    <textarea name="description" value={inputData.description} onChange={e => { e.preventDefault(); inputChange(e) }} />
                </div>
            </div>
            <div className='col-12'>
                <div className='field'>
                    <label>Master Role</label>
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={masterRoles}
                        name="masterRoleId"
                        value={masterRoles && masterRoles.find(obj => obj.masterRoleId === inputData.masterRoleId)}
                        onChange={e => {
                            inputChange({
                                target:
                                    { value: e.masterRoleId, name: 'masterRoleId' }
                            })
                        }}
                        getOptionLabel={(option) => option.masterRole}
                        getOptionValue={(option) => option.masterRoleId}
                        menuPortalTarget={document.body}
                        styles={{menuPortal: base => ({ ...base, zIndex: 9999 }),}}
                    />
                </div>
            </div>

            <div className='col-12 d-flex justify-content-between mt-3'>
                <div className='col-auto'>
                    <button className='btn btn-secondary' onClick={e => { e.preventDefault(); props.onClose() }}>Close</button>
                </div>
                <div className='col-auto'>
                    <button className='btn btn-primary' onClick={e => { e.preventDefault(); props.submitHandler({ ...inputData, featuresList: featureList?.length>=1?featureList.map(feature => feature.featureId) :[]}) }}>{props.submitButton || "Save"}</button>
                </div>
            </div>
        </div>
        </div>
    )
}
export default UserRolesForm