import React, { useEffect, useState } from "react";
import PatientList from "../patient-list/PatientList";
import { useNavigate } from "react-router-dom";
import SideTabs from "../../../../templates/layouts/SideTabs";
import LeadSettings from "../lead-settings/LeadSettings";
import Module from '../../../../templates/components/Module'

const ProviderPatientDashboard = (props) => {
  const [activeTab, setActiveTab] = useState()

  const navigate = useNavigate()
  useEffect(() => {
      changeTab(props.tab)
  }, [props.tab])

  const changeTab = (tab) => {
      if (tab === 'patient') {
          navigate('/provider/patient')
          setActiveTab(tab)
      }
      else {
          navigate(`/provider/patient/${tab}`)
          setActiveTab(tab)
      }
  }
  return (
      <SideTabs title="Patient Dashboard" menuTitle="Patient Menu" activeKey={activeTab} onSelect={k => changeTab(k)}>
          <Module title="Patients" eventKey="patient">
              <PatientList title="Patients List"/>
          </Module>
          <Module title="Leads" eventKey="lead">
              <LeadSettings title="Leads" />
          </Module>
      </SideTabs>
  )
};

export default ProviderPatientDashboard;
