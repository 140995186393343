import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Module from '../../../templates/components/Module'
import SideTabs from '../../../templates/layouts/SideTabs'
import LocationSettings from './location-settings/LocationSettings'
import PracticeSettings from './practice-settings/PracticeSettings'
import ProviderSettings from './provider-settings/ProviderSettings'
import Utilities from '../../../../services/commonservice/utilities'
import SecuritySettings from './security-settings/SecuritySettings'
import FormsSettings from './forms-settings/FormsSettings'
import SchedulingSettings from './scheduling-settings/SchedulingSettings'
import ProductsServicesFeesSettings from './products-services-fees-settings/ProductsServicesFeesSettings'
import PatientSettings from './practice-patient-settings/PatientSettings'
import PaymentSettings from './payment-settings/PaymentSettings'
const SettingsDashboard = (props) => {
    const masterRole = useMemo(()=>{
        return Utilities.getMasterRole()
    },[])
    const [activeTab, setActiveTab] = useState()

    const navigate = useNavigate()
    useEffect(() => {
        changeTab(props.tab)
    }, [props.tab])

    const changeTab = (tab) => {
        if (tab === 'practice') {
            navigate('/provider/settings')
            setActiveTab(tab)
        }
        else {
            navigate(`/provider/settings/${tab}`)
            setActiveTab(tab)
        }
    }
    return (
        <SideTabs title="Settings" menuTitle="Settings Menu" contentTitle='Settings' activeKey={activeTab} onSelect={k => changeTab(k)}>

            <Module title="Practice" eventKey="practice">
                <PracticeSettings />
            </Module>
            <Module title="Security" eventKey="security">
                <SecuritySettings />
            </Module>
            <Module title="Locations" eventKey="location">
                <LocationSettings/>
            </Module>
            <Module title="Patients" eventKey="patients">
                <PatientSettings/>
            </Module>
            <Module title="Forms" eventKey="forms">
                <FormsSettings/>
            </Module>
            <Module title="Scheduling" eventKey="scheduling">
                <SchedulingSettings/>
            </Module>
            <Module title="Products, Services, Fees" eventKey="productsservicesfees">
                <ProductsServicesFeesSettings/>
            </Module>
            <Module title="Providers" eventKey="provider" hide={masterRole==3}>
                <ProviderSettings/>
            </Module>
            <Module title="Payments" eventKey="payment">
                <PaymentSettings/>
            </Module>
        </SideTabs>
    )

}

export default SettingsDashboard