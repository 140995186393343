import React, { useState} from 'react'
import APIResponse from '../../../../../templates/components/APIResponse';
import RecurringPaymentsService from '../../../../../../services/api/recurring-payments.service';
import RecurringPlanForm from './installment-form/RecurringPlanForm';

const EditRecurringPlan = (props) => {
    const [isLoader, setIsLoader] = useState(false)
    const [apiResponse, setApiResponse] = useState()

    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }

    const updateBoth = (data,firstTransactionDate) => {
        setIsLoader(true)
        let startDate = new Date(firstTransactionDate).toISOString();
        if(startDate === new Date(props.initialData.firstTransactionDate).toISOString()){
            //only call to update account
            updateAccount(data)
        }else{
            const recReqObj = {
                "sendReceiptTo": data?.email,
                "firstTransactionDate": startDate,
                "recurringId": props.initialData.id
            }
            if (data?.oldSeclectedAccount?.id != data?.selectedAccount?.id) {
                recReqObj.paymentAccountId = data?.selectedAccount?.id
                //accountType=1 for CC and accountType=2 for ACH 
                recReqObj.accountType = data?.account?.accountType
                if (data?.account?.accountType == 1 && data?.cvv) {
                    recReqObj.cvv = data?.cvv;
                }
            }
            RecurringPaymentsService.updateRecurringPayment(recReqObj)
            .then(
                (response) => {
                    setApiResponse(response);
                })
            .catch(error => {
                setApiResponse(error);
            })
            .finally(() => {
                setIsLoader(false)
            });

        }
    }

    const updateAccount = (data) => {
        setIsLoader(true)
        //accountType=1 for CC and accountType=2 for ACH
        const recReqObj = {
            "paymentAccountId": data?.account?.id,
            "accountType": data?.account?.accountType
          }

        if (data?.account?.accountType == 1 && data?.cvv) {
            recReqObj.cvv = data?.cvv;
        }

        RecurringPaymentsService.updateAccount(props.initialData.id, recReqObj)
            .then(
                (response) => {
                    setApiResponse(response);
                })
            .catch(error => {
                setApiResponse(error);
            })
            .finally(() => {
                setIsLoader(false)
            });
    }
    return (
        <>
        <RecurringPlanForm submitHandler={updateBoth} isLoader={isLoader} isEdit onClose={props.onClose} isModal={props.isModal} initialData={props.initialData} transactionType={props.transactionType} submitLabel="Update" />
        <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </>
    )
}

export default EditRecurringPlan