import React, { useEffect, useState } from 'react'
import InvoiceService from '../../../../../services/api/invoice.service'
import InvoiceForm from '../invoice-form/InvoiceForm'
import APIResponse from '../../../../templates/components/APIResponse';
import VirtualTerminalForm from '../../transactions/terminals/virtual-terminal/virtual-terminal-form/VirtualTerminalForm';
import ModalBox from '../../../../templates/components/ModalBox';
import toast from 'react-hot-toast';

const EditInvoice = (props) => {

    const [initialData, setInitialData] = useState()
    const [apiResponse, setApiResponse] = useState()
    const [nextOperation, setNextOperation] = useState(0)
    const [showVirtualTerminal, setShowVirtualTerminal] = useState(false)
    const [invoice, setInvoice] = useState()
    const [close, setClose] = useState()

    const closeHandler = () => {
        let reqObj = { closeReason: 'Close invoice' }
        InvoiceService.closeAndWriteOff(invoice?.id, reqObj)
            .then(res => {
                setApiResponse(res);
                console.log(res)
                toast.success("Invoice Closed")
                setClose(false)
              })
            .catch(err => {
                setApiResponse(err);
                console.log(err)
            })
    }

    const pullInvoice = () =>{
        InvoiceService.getInvoiceById(props.id)
        .then(res=>{
            let newData = res
            console.log(newData)
            setInitialData(newData)
        })
    }

    const onSuccess = (message) => {

        if (nextOperation==1) {
            setNextOperation(0)
            setShowVirtualTerminal(true)
        } 
        else if (nextOperation==2) {
            setNextOperation(0)
            setClose(true)
        }
        else 
        {
            if (props.refresh) {
                props.refresh()
            }
            if (props.onClose) {
                props.onClose()
            }
            if (props.exitHandler) {
                props.exitHandler()
            }
        }

      }

    const editInvoice = (data, selectedPatient, items, providerId, invoiceType, virtualTerminal) => {
        if (virtualTerminal) setNextOperation(1);
        return saveInvoice(data, selectedPatient, items, providerId)
            .then((res) => { 
                if (res?.data) setInvoice(res?.data);
                setApiResponse(res);
            })
            .catch((err) => { 
                setApiResponse(err);
                console.log(err) 
            });        
    }

    const onCloseInvoice = (data, selectedPatient, items, providerId) => {
        setNextOperation(2);
        return saveInvoice(data, selectedPatient, items, providerId)
            .then((res) => { 
                if (res?.data) setInvoice(res?.data);
                setApiResponse(res);
            })
            .catch((err) => { 
                setApiResponse(err);
                console.log(err) 
            });        
    }

    const saveInvoice = (data, selectedPatient, items, providerId) => {
        let reqObj = data
        reqObj.items = items.map(item=> {
            let newItem = item;
            if(item.itemType === 4 ) {
                //calculated from ui
                newItem.unitPrice = item.feesEachSubTotal ? parseFloat(item.feesEachSubTotal) : 0  
                newItem.taxAmount =item.feesEachTaxAmount ? parseFloat(item.feesEachTaxAmount) : 0
                delete newItem.doctorId
            }   
            else {
                newItem.unitPrice = item.unitPrice ? parseFloat(item.unitPrice) : 0
            }
            newItem.taxPercent =item.taxPercent ? parseFloat(item.taxPercent) : 0
            newItem.discount = item.discount ? parseFloat(item.discount) : 0
            newItem.quantity = item.quantity ? parseFloat(item.quantity) : 1
            newItem.feeAmount = item.feeAmount ? parseFloat(item.feeAmount) : 0
            newItem.feePercentage = item.feePercentage ? parseFloat(item.feePercentage) : 0
            newItem.discountType = newItem.discountType ? newItem.discountType : 1
            return newItem
        })
        reqObj.doctorId = items.find(obj => obj.doctorId !== undefined)?.doctorId || providerId
        reqObj.invoiceId = data.id
        reqObj.operationType = data.operationType || 2
        reqObj.autoClaimStatus = data.autoClaimStatus || false
        reqObj.practiceLocationId = data.practiceLocationId
        reqObj.patientName = data?.patientName ? data.patientName : selectedPatient.firstName+' '+selectedPatient?.lastName
        reqObj.patientId = selectedPatient?.id || data.patientId
        return InvoiceService.editInvoice(reqObj);
    }


    useEffect(()=>{
        pullInvoice()
    },[props.id])

    return (
        <div>
            {initialData && <InvoiceForm onCloseInvoice={onCloseInvoice} initialData={initialData} isModal  isEdit submitLabel="Add" submitHandler={editInvoice} onClose={() => { if (props.onClose) { props.onClose() } }}/>}
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true}/>

            <ModalBox open={showVirtualTerminal && invoice} onClose={() => {
                setShowVirtualTerminal(false); 
                onSuccess()
            }}>
                <VirtualTerminalForm initialData={{ ...invoice, taxAmount: invoice?.itemTotalTax, adjustedUnpaidAmount: invoice?.adjustedUnpaidAmount ? invoice?.adjustedUnpaidAmount : invoice?.finalAmount }} 
                    onClose={() => {
                    setShowVirtualTerminal(false); 
                    onSuccess()
                }} size="large" isModal />
            </ModalBox>

            <ModalBox open={close} onClose={() => { setClose(false) }} size="tiny" title="Cancel Invoice">
                <div className='col-12'>
                    Are you sure you want to close this invoice?
                </div>
                <div className='col-12 d-flex justify-content-between mt-3'>
                    <div className='col-auto'>
                        <button className='btn btn-primary' onClick={(e) => { e.preventDefault(); closeHandler(); return setClose(false) }}>Yes</button>
                    </div>
                    <div className="col-auto">
                        <button className='btn btn-secondary' onClick={e => { e.preventDefault(); setClose(false) }}>No</button>
                    </div>
                </div>
            </ModalBox>      

        </div>
    )
}
export default EditInvoice