import React, { useState, useEffect } from 'react'
import PaymentOptionForm from '../payment-option-form/PaymentOptionForm'
import PaymentOptionService from '../../../../../../../../services/api/payment-options.service'
import APIResponse from '../../../../../../../templates/components/APIResponse'

const PaymentOptionAdd = (props) => {
    const [formData, setFormData] = useState({})
    const [messages, setMessages] = useState({})
    const [isLoader, setIsLoader] = useState(false)
    const [apiResponse, setApiResponse] = useState()

    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }
    const submitHandler = (data) => {
        let reqObj = {}
        reqObj.paymentOption = data.paymentOption;
        setIsLoader(true)
        PaymentOptionService.addPaymentOption(reqObj)
            .then(response => {
                setApiResponse(response)
                clearForm()
                setIsLoader(false)
            })
            .catch(error => {
                setApiResponse(error)
                setIsLoader(false)
            })
    }

    const clearForm = () => {
        setFormData({})
    }
    return (
        <div>
            <PaymentOptionForm submitHandler={submitHandler} onClose={() => props.onClose()} loaded={!isLoader} messages={messages} />
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </div>
    )
}

export default PaymentOptionAdd