import React, { useState } from 'react'
import LeadSourceService from '../../../../../../services/api/lead-source.service'
import APIResponse from '../../../../../templates/components/APIResponse'
import LeadSourceForm from './LeadSourceForm'
import DimLoader from '../../../../../templates/components/DimLoader'

const AddLeadSource = (props) => {
    const [apiResponse, setApiResponse] = useState()
    const [isLoader, setIsLoader] = useState(false)
    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }

    const submitHandler = (reqObj) => {
        setIsLoader(true)
        return LeadSourceService.addLeadSource(reqObj)
            .then(res => {
                setApiResponse(res);
            })
            .catch(err => {
                setApiResponse(err);
            }).finally(() => {
                setIsLoader(false)
              });
    }
    return (
        <>{isLoader? <DimLoader loadMessage="Saving"/>:null}
            <LeadSourceForm
                closeModal={props.closeModal}
                initialData={props.initialData}
                submitHandler={submitHandler} />
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </>
    )
}

export default AddLeadSource