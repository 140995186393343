import React, { useEffect, useState, useContext } from 'react'
import EquipmentService from '../../../../../../services/api/equipment.service'
import AccordionTemplate from '../../../../../templates/components/AccordionTemplate'
import AddEquipmentType from '../../../equipment-type/add-equipment-type/AddEquipmentType'
import EditEquipmentType from '../../../equipment-type/edit-equipment-type/EditEquipmentType'
import AddEquipment from '../../../equipment/add-equipment/AddEquipment'
import EditEquipment from '../../../equipment/edit-equipment/EditEquipment'
import ModalBox from '../../../../../templates/components/ModalBox'
import Table2 from '../../../../../templates/components/Table2'
import { store } from '../../../../../../context/StateProvider'
import PracticeLocationSelector from '../../../../../templates/components/PracticeLocationSelector'
import toast from 'react-hot-toast'
import APIResponse from '../../../../../templates/components/APIResponse'
const EquipmentSettings = (props) => {
    const globalStateAndDispatch = useContext(store)
    const contextState = globalStateAndDispatch.state

    const [isLoader_Equipment, setIsLoader_Equipment] = useState(false)
    const [isLoader_EquipmentType, setIsLoader_EquipmentType] = useState(false)
    const [equipmentList, setEquipmentList] = useState()
    const [equipmentTypeList, setEquipmentTypeList] = useState()
    const [selectEquipment, setSelectedEquipment] = useState()
    const [deleteModal, setDeleteModal] = useState(false)
    // For forms 
    const [showEquipmentAdd, setShowEquipmentAdd] = useState(false)
    const [showEquipmentTypeAdd, setShowEquipmentTypeAdd] = useState(false)
    const [masterEquipmentTypeList, setMasterEquipmentTypeList] = useState()

    const [showEquipmentEdit, setShowEquipmentEdit] = useState(false)
    const [equipmentId, setEquipmentId] = useState()
    const [equipmentTypeId, setEquipmentTypeId] = useState()
    const [showEquipmentTypeEdit, setShowEquipmentTypeEdit] = useState(false)
    const [equipmentTypeInitial, setEquipmentTypeInitial] = useState()
    const [selectEquipmentType, setSelectedEquipmentType] = useState()
    const [deleteTypeModal, setDeleteTypeModal] = useState(false)
    const [statusModal, setStatusModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [override, setOverride] = useState(false);
    const [apiResponse, setApiResponse] = useState()
    const hiddenColumns = ["isActive","actionEquipment"]
    const onSuccess = (message) => {
        equipmentLookup();
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }
    const onError = (message) => {
        if (selectEquipment.isActive == 1) {
            if(apiResponse.response?.data?.message === "Key_EquipmentInUseInAppointments"){
                setConfirmModal(true)
            }
        }
    }
    const statusHandler = () => {
        setConfirmModal(false);
        setStatusModal(false);
        setIsLoader_Equipment(true)
        if (selectEquipment.isActive == 1) {
            let reqObj ={}
            if(override){
                reqObj.override = override
            }
            EquipmentService.deactivateEquipment(selectEquipment.equipmentId,reqObj).then(response => {
                setApiResponse(response)
            })
                .catch(error => {
                    setApiResponse(error)
                })
                .finally(() => {
                    setOverride(false)
                    setIsLoader_Equipment(false)
                });

        } else {
            EquipmentService.activateEquipment(selectEquipment.equipmentId).then(response => {
                setApiResponse(response)
            })
                .catch(error => {
                    setApiResponse(error)
                })
                .finally(() => {
                    setStatusModal(false);
                    setIsLoader_Equipment(false)
                });
        }
    };
    // console.log(contextState)
    const equipmentLookup = () => {
        setIsLoader_Equipment(true);
        EquipmentService.equipmentLookup(contextState.practiceLocationId)
            .then((response) => {
                console.log(response)
                setEquipmentList(response);
                setIsLoader_Equipment(false);
            })
            .catch(error => {
                setIsLoader_Equipment(false);
                // console.log(error)
                // setCheckException(error);
            })
    }

    const equipmentTypeLookup = () => {
        setIsLoader_EquipmentType(true);
        EquipmentService.equipmentTypeLookup()
            .then((response) => {
                // console.log(response)
                setEquipmentTypeList(response);
                setIsLoader_EquipmentType(false);
            })
            .catch(error => {
                setIsLoader_EquipmentType(false);
                // console.log(error)
                // setCheckException(error);
            })
    }

    const pullMasterEquipmentType = () => {
        EquipmentService.masterEquipmentTypeLookupForProvider()
            .then(res => {
                // console.log(res)
                setMasterEquipmentTypeList(res)
            })
    }

    const deleteEquipment = (id) => {
        EquipmentService.deleteEquipment(id)
            .then(res => {
                toast.success("Equipment deleted")
                equipmentLookup()
            })
            .catch(err => toast.error("Error in deleting Equipment"))
    }

    const deleteTypeEquipment = (id) => {
        EquipmentService.deleteEquipmentType(id)
            .then(res => {
                console.log("deleted");
                equipmentTypeLookup()
            }
            )

    }
    useEffect(() => {
        if(override){
            statusHandler();
        }
}, [override])
    useEffect(() => {
        // console.log(contextState.practiceLocationId.practiceLocationId)
        if (contextState.practiceLocationId) {
            equipmentLookup()
            equipmentTypeLookup()
            pullMasterEquipmentType()
        }
    }, [contextState.practiceLocationId])

    const equipmentColumns = [
        {
            id: "description",
            Header: "Name",
            accessor:equipment=>equipment.description
        },
        {
            id: "equipmentTypeId",
            Header: "Equipment Type",
            accessor: (equipment) => { if (equipmentTypeList) { return equipmentTypeList.find(obj => obj.equipmentTypeId === equipment.equipmentTypeId)?.equipmentType } }
        },{
            id: "isActive",
            Header: "Active",
            align: "center",
            sortable: true,
            accessor: (equipment, i) =>
                equipment.isActive === 1 ? (
                    <span className="btn btn-success text-white">Active</span>
                ) : (
                    <span className="btn btn-danger text-white">Inactive</span>
                ),
        },
        {
            id: "actionEquipment",
            Header: "Action",
            textAlign: 'center',
            disableSortBy: true,
            accessor: (equipment, i) => {
            return (
                    <div className='d-flex justify-content-center'>
                        <button className="p-0 ps-1 btn btn-primary" title="edit" onClick={e => { e.preventDefault(); setEquipmentId(equipment.equipmentId); return setShowEquipmentEdit(true) }}><i className="icon pencil" /></button>
                        <button className="p-0 ps-1 btn btn-primary ms-1" title="delete" onClick={e => { e.preventDefault(); setSelectedEquipment(equipment); return setDeleteModal(true) }}><i className="icon trash" /></button>
                        <button
                            className="p-0 ps-1 btn btn-primary ms-1"
                            title={
                                equipment.isActive === 1 ? "Deactivate Equipment" : "Activate Equipment"
                            }
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedEquipment(equipment);
                                return setStatusModal(true);
                            }}
                        >
                            {equipment.isActive === 1 ? <i className="icon dont" /> : <i className="icon check" />}
                        </button>
                    </div>
                )
            }
        }
    ]

    return (
        <div>
            <div title="Equipment" className='py-3'>
                <Table2 
                hiddenColumns={hiddenColumns}
                export searchable 
                data={equipmentList} 
                columns={equipmentColumns} 
                loading={isLoader_Equipment} 
                extraButtons={[
                    {
                        className: 'btn btn-primary',
                        title: "Add Equipment",
                        children:
                            <span><i className='icon plus' /></span>
                        ,
                        onClick: () => {
                            setShowEquipmentAdd(true)
                        }
                    }

                ]}
                    extraElements={[
                        { children: <div style={{ width: '250px' }}> <PracticeLocationSelector /> </div> },
                    ]} />
            </div>
            <ModalBox open={showEquipmentAdd} onClose={() => { setShowEquipmentAdd(false) }} title="Add Equipment">
                <AddEquipment initialData={{ practiceLocationId: contextState.practiceLocationId }} onClose={() => { equipmentLookup(); return setShowEquipmentAdd(false) }} />
            </ModalBox>
            <ModalBox open={showEquipmentTypeAdd} onClose={() => { setShowEquipmentTypeAdd(false) }} title="Add Equipment Type">
                <AddEquipmentType initialData={{ practiceLocationId: contextState.practiceLocationId }} onClose={() => { equipmentTypeLookup(); return setShowEquipmentTypeAdd(false) }} />
            </ModalBox>
            <ModalBox open={showEquipmentEdit} onClose={() => { setShowEquipmentEdit(false) }} title="Edit Equipment">
                <EditEquipment id={equipmentId} onClose={() => { equipmentLookup(); return setShowEquipmentEdit(false) }} />
            </ModalBox>
            <ModalBox open={showEquipmentTypeEdit} onClose={() => { setShowEquipmentTypeEdit(false) }} title="Edit Equipment Type">
                <EditEquipmentType initialData={equipmentTypeInitial} onClose={() => { equipmentTypeLookup(); return setShowEquipmentTypeEdit(false) }} />
            </ModalBox>
            <ModalBox open={deleteModal} onClose={() => { setDeleteModal(false) }} title="Delete Equipment">
                {selectEquipment?.equipmentId && <div className='row d-flex align-items-center justify-content-between'>
                    <div className='col'>
                        Are you sure you want to delete {selectEquipment.description} ?
                    </div>
                    <div className='col-auto'>
                        <button className='btn btn-secondary' onClick={e => { e.preventDefault(); setEquipmentId(equipment.equipmentId); return setDeleteModal(false) }}>No</button>
                        <button className='btn btn-primary ms-3' onClick={e => { e.preventDefault(); deleteEquipment(selectEquipment.equipmentId); equipmentLookup(); return setDeleteModal(false) }}>Yes</button>
                    </div>
                </div>}
            </ModalBox>
            <ModalBox open={deleteTypeModal} onClose={() => { setDeleteTypeModal(false) }} title="Delete Equipment Type">
                {selectEquipmentType?.equipmentTypeId && <div className='row d-flex align-items-center justify-content-between'>
                    <div className='col'>
                        Are you sure you want to delete  ?
                    </div>
                    <div className='col-auto'>
                        <button className='btn btn-secondary' onClick={e => { e.preventDefault(); setEquipmentTypeId(selectEquipmentType.equipmentTypeId); return setDeleteTypeModal(false) }}>No</button>
                        <button className='btn btn-primary ms-3' onClick={e => { e.preventDefault(); deleteTypeEquipment(selectEquipmentType.equipmentTypeId); return setDeleteTypeModal(false) }}>Yes</button>
                    </div>
                </div>}
            </ModalBox>
            <ModalBox open={statusModal} onClose={() => setStatusModal(false)} title={selectEquipment?.isActive === 1 ? "Deactivate Equipment" : "Activate Equipment"}>
                <div className="row d-flex justify-content-between align-items-center">
                    <div className="col">
                        {selectEquipment?.isActive === 1 ? (
                            <p>Would you like to deactivate {selectEquipment?.description}</p>
                        ) : (
                            <p>Would you like to activate {selectEquipment?.description} </p>
                        )}
                    </div>
                    <div className="col-auto">
                        <button
                            className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault();
                                statusHandler();
                            }}
                        >
                            OK
                        </button>
                        <button
                            className="btn btn-secondary ms-3"
                            onClick={(e) => {
                                e.preventDefault();
                                setStatusModal(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </ModalBox>
            <ModalBox open={confirmModal} onClose={() => setConfirmModal(false)} title="Confirm">
                <div className="row d-flex justify-content-between align-items-center">
                    <div className="col">
                            <p>Do you want to deactivate {selectEquipment?.description} while it's in use?</p>
                    </div>
                    <div className="col-auto">
                        <button
                            className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault();
                                setOverride(true);
                            }}
                        >
                            OK
                        </button>
                        <button
                            className="btn btn-secondary ms-3"
                            onClick={(e) => {
                                e.preventDefault();
                                setConfirmModal(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </ModalBox>
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true}  toastOnFailure={true} onError={onError}/>
        </div>
    )
}

export default EquipmentSettings