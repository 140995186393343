import React, { useEffect, useState, useContext, useMemo } from 'react'
import Table2 from "../../../../templates/components/Table2"
import ProviderService from "../../../../../services/api/provider.service";
import moment from "moment";
import PracticeLocationSelector from '../../../../templates/components/PracticeLocationSelector';
import { store } from '../../../../../context/StateProvider';
import Module from '../../../../templates/components/Module';
import Utilities from '../../../../../services/commonservice/utilities';
import DoctorService from '../../../../../services/api/doctor.service';
import ModalBox from '../../../../templates/components/ModalBox';
import AddUnavailableBlock from '../../unavailable-block/add-unavailable-block/AddUnavailableBlock';
import UnavailableBlockForm from '../../unavailable-block/unavailable-block-form/UnavailableBlockForm';
import toast from 'react-hot-toast';
import Select from 'react-select'
import DatePicker from '../../../../templates/components/DatePicker';


const UnavailableBlockTable = (props) => {
  const [isLoader, setIsLoader] = useState(false);
  const [isLoader_Provider, setIsLoader_Provider] = useState(false)
  const [unavailableBlockList, setUnavailableBlockList] = useState([]);
  const [providerList, setProviderList] = useState()
  const [startDate, setStartDate] = useState(moment().startOf("D"));
  const [endDate, setEndDate] = useState(moment().endOf("D"));
  const [selectedBlock, setSelectedBlock] = useState({})
  const [show, setShow] = useState(false)
  const [wholePractice, setWholePractice] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState()

  const deleteBlock = (id) => {
    return ProviderService.deleteUnavailableBlock(id)
      .then(res => {
        console.log(res)
        toast.success("Block Deleted")
        getUnavailableBlocks()
      })
  }

  const hiddenColumns = useMemo(() => {
    if (props.providerId) {
      return ['startTime', 'endTime', 'doctorId']
    }
    else {
      return ['startTime', 'endTime']
    }
  }, [props.providerId])

  const desktopHiddenColumns = useMemo(() => {
    if (props.providerId) {
      return ['doctorId']
    }
    else {
      return null
    }
  }, [props.providerId])

  const state = useContext(store).state
  const doctorLookup = () => {
    if (props.providerId) {
      DoctorService.getById(props.providerId)
        .then(res => {
          let newProvider = res
          newProvider.name = res.firstName + ' ' + res.lastName
          setProviderList([newProvider])
        })
    }
    else {
      const reqObj = { isActive: true, PracticeLocationId: state.practiceLocationId, isRegistered: true };
      DoctorService.doctorLookup(reqObj)
        .then(
          (response) => {
            if (response) {
              setProviderList(response)
            }
          })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  const getUnavailableBlocks = () => {

    let fromDate = Utilities.toISOStringWithoutOffset(startDate)+'.000Z'
    let toDate = Utilities.toISOStringWithoutOffset(endDate)+'.000Z';

    let reqObj = {
      PracticeLocationId: state.practiceLocationId,
      FromDate: fromDate,
      ToDate: toDate,
      WholePractice: wholePractice
    }

    // delete reqObj.PracticeLocationId

    if (wholePractice) {
      delete reqObj.PracticeLocationId
    }
    if (!wholePractice) {
      delete reqObj.WholePractice
    }
    if (selectedProvider) {
      if (Array.isArray(selectedProvider)) {
        reqObj.DoctorId = selectedProvider.map(obj => obj.id)
      }
      else {
        reqObj.DoctorId = selectedProvider.id
      }
    }
    if (props.providerId) {
        reqObj.DoctorId = props.providerId
    }
    ProviderService.getUnavailableBlock(reqObj).then(res => {
      setUnavailableBlockList(res?.data?.selectResponse.sort((a, b) => new Date(a.startDate) - new Date(b.startDate)))
    }).catch(() => {
      toast.error('Oops! Something went wrong');
      if (typeof cb === 'function') {
        cb();
      }
    });
  }

  const timeToLocalDate = (time) => {
    let timeparts = time.split(":")
    let localdate = moment('1900-01-01t'+timeparts[0]+":"+timeparts[1]+":00.000") 
    return localdate
  }
  

  useEffect(() => {
    if (state?.practiceLocationId) {
      doctorLookup()
      getUnavailableBlocks();
    }
  }, [wholePractice, selectedProvider, state.practiceLocationId, startDate, endDate])

  const columns = [
    {
      id: "startDate",
      Header: "From Date",
      accessor: (block) => moment(block.startDate).utc().format("MM/DD/YYYY")
    },
    {
      id: "startTime",
      Header: "Time",
      accessor: (block) => block.startWholeDay == 1 ? 'All Day' : moment(timeToLocalDate(block.startTime)).format("h:mm A")
    },
    {
      id: "endDate",
      Header: "To Date",
      accessor: (block) => moment(block.endDate).utc().format("MM/DD/YYYY")
    },
    {
      id: "endTime",
      Header: "Time",
      accessor: (block) => moment(timeToLocalDate(block.endTime)).format("h:mm A")
    },
    {
      id: "doctorId",
      Header: "Provider",
      align: "left",
      sortable: true,
      accessor: (block) => { return block.wholePractice === 1 ? 'Whole Practice' : providerList && providerList.find(obj => obj.id === block.doctorId)?.name || 'Provider Only' }
    },
    {
      id: "actions",
      Header: "Actions",
      textAlign: 'center',
      disableSortBy: true,
      accessor: (block) => {
        return (<div className='d-flex justify-content-center'>
          <div className='btn-group'>
            <button className='p-1 btn btn-primary' title="Edit Block" onClick={e => { 
              e.preventDefault(); 
              setSelectedBlock(block); 
              return setShow(true) 
              }}><i className='icon pencil' /></button>
            <button className="p-1 btn btn-primary" title="Delete Block" onClick={e => { e.preventDefault(); setSelectedBlock(block); return setDeleteModal(true) }}><i className="icon trash" /></button>
            {/* <button className='btn btn-primary' title="Delete Block" onClick={e => { e.preventDefault(); setSelectedBlock(block); return deleteBlock(block.unavailableBlockId) }}><i className='icon trash' /></button> */}
          </div>
        </div>
        )
      }
    },
  ];


  return (

    <div className="row d-flex g-3">
      <div className='col-12 px-0'>
        <Module title="Filters">
          <div className='row d-flex'>
            <div className='col-12 row d-flex justify-content-center mx-0'>
            <DatePicker startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate}/>
              {!props.providerId ? <div className='col-md-auto col-12'>
                <label>Provider</label>
                <Select
                  options={providerList}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isDisabled={!providerList}
                  name="doctorId"
                  isSearchable
                  placeholder="All Providers"
                  value={selectedProvider}
                  isMulti
                  isClearable
                  onChange={e => {
                    setSelectedProvider(e)
                  }}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                />
              </div> : null}
              {!wholePractice ? <div className='col-md-auto col-12'>
                <div className='ui field'>
                  <label>Practice Location</label>
                  <PracticeLocationSelector disabled={wholePractice} />
                </div>
              </div> : null}
              <div className='col-md-auto col-12'>
                <div className='ui field boolean-input'>
                  <label>Whole Practice</label>
                  <input
                    type="checkbox"
                    className="form-check-input mt-2"
                    name="wholePractice"
                    checked={wholePractice}
                    onChange={e => {
                      setWholePractice(e.target.checked)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Module>
      </div>
      <div className='col-12 px-0'>
        <Module title="Closed/Away Times">
          <Table2
            hiddenColumns={hiddenColumns}
            export
            desktopHiddenColumns={desktopHiddenColumns}
            searchable
            loading={isLoader}
            columns={columns}
            data={unavailableBlockList}
            extraButtons={[
              {
                className: 'btn btn-primary',
                onClick: () => { setSelectedBlock({}); return setShow(true) },
                children: <i className="icon add" title="Add Block" />
              },
            ]}

          />
        </Module>
      </div>
      <ModalBox open={show} onClose={() => { setShow(false) }} title={Object.keys(selectedBlock).length === 0?"Add Time Block":"Edit Time Block"}>
        <UnavailableBlockForm
          initialData={props.providerId ? { doctorId: props.providerId, ...selectedBlock } : selectedBlock}
          providerId={props.providerId ? props.providerId : null}
          onClose={() => {
            getUnavailableBlocks();
            return setShow(false)
          }}
          {...props}
        />
      </ModalBox>
      <ModalBox open={deleteModal} onClose={() => { setDeleteModal(false) }} title="Delete Block">
        {selectedBlock?.unavailableBlockId && <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-md-auto col-12'>
            Are you sure you want to delete {selectedBlock.description} ?
          </div>
          <div className='col-auto'>
            <button className='btn btn-secondary' onClick={e => { e.preventDefault(); setSelectedBlock({}); return setDeleteModal(false) }}>No</button>
            <button className='btn btn-primary ms-3' onClick={e => { e.preventDefault(); deleteBlock(selectedBlock?.unavailableBlockId); setDeleteModal(false) }}>Yes</button>
          </div>
        </div>}
      </ModalBox>
    </div>

  );
};

export default UnavailableBlockTable;

