import React, { useState } from 'react'
import LeadService from '../../../../../services/api/lead-source.service'
import APIResponse from '../../../../templates/components/APIResponse'
import LeadForm from './LeadForm'
import DimLoader from '../../../../templates/components/DimLoader'

const EditLead = (props) => {
    const [apiResponse, setApiResponse] = useState()
    const [isLoader, setIsLoader] = useState(false)
    const onSuccess = (message) => {
        if (props.refresh) {
            props.refresh()
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.exitHandler) {
            props.exitHandler()
        }
    }

    const submitHandler = (data) => {
        setIsLoader(true)
        //console.log(JSON.stringify(data))
        let reqObj = {
            comments: data.comments || null,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            mobile: data?.mobile?.includes("(") ? data.mobile.replace("(", "").replace(")", "").replace("-", "").replace(" ", "") : data.mobile,
            source: data.source || null,
            status: data.status || null,
            assignedToId: data.assignedToId  || null,
            metadata: data.metadata || null
        }
        return LeadService.editLead(reqObj,data.id)
            .then(res => {
                setApiResponse(res);
            })
            .catch(err => {
                setApiResponse(err);
            }).finally(() => {
                setIsLoader(false)
              });
    }
    return (
        <>{isLoader? <DimLoader loadMessage="Updating.."/>:null}
            <LeadForm
                closeModal={props.closeModal}
                initialData={props.initialData}
                submitHandler={submitHandler} />
            <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
        </>
    )
}

export default EditLead