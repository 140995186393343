import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment';

const SMSConversationCard = (props) => {
    const { conversation, onSelectPatient, patientId } = props;

    const getDateString = (createdOn) => {

        let createdMoment = moment.utc(createdOn).local().startOf('day');

        let today = moment.utc(new Date()).local().startOf('day');
        let yesterday = today.clone().add('day',-1);
        let thisWeek = today.clone().add('day',-7);

        // if its today, time only
        if (createdMoment.isSame(today)) {
            return moment.utc(createdOn).local().format("LT");
        } else 
        // if its yesterday
        if (createdMoment.isSame(yesterday)) {
            return 'Yesterday';
        } else
        // if its within a week, name of day
        if (createdMoment.isSameOrAfter(thisWeek)) {
            return moment.utc(createdOn).local().format('dddd');
        } else
        // otherwise, date only
        {
            return moment.utc(createdOn).local().format("LL");
        }

    }

    return (
        <div onClick={e => { e.preventDefault(); onSelectPatient(conversation.patientId) }} className={'two-way-conversation-card' + (conversation.patientId == patientId ? ' selected-patient' : '') } >
            <div className='two-way-conversations-card-name'>
                <strong>
                    {conversation.unread && ((patientId ?? 0) != conversation.patientId ) ? <i style={{'fontSize':'12px'}} class="circle icon"></i> : null}
                    {conversation.patient}
                </strong>
                <span className='two-way-conversations-card-datetime'  >{getDateString(conversation.createdOn)}</span>
            </div>
            <div className="two-way-conversations-msg">
                {conversation.message}
            </div>
        </div>
    )
}

export default SMSConversationCard