import moment from "moment";
import React, { useEffect, useState } from "react";
import RecurringPaymentsService from "../../../../../../../services/api/recurring-payments.service";
import Utilities from "../../../../../../../services/commonservice/utilities";
import ModalBox from "../../../../../../templates/components/ModalBox";
import PaymentHistoryList from "../../../../report/paymentPlan-list/paymentPlan-card/payment-history-accordion/payment-history-list/PaymentHistoryList";
import PaymentsList from "../../../../report/paymentPlan-list/paymentPlan-card/payments-accordion/payments-list/PaymentsList";
import TransactionStatusEnum from "../../../../../../../common/enum/transaction-status.enum";
import InvoicePreview from "../../../../invoices/invoice-preview/InvoicePreview";
import Table2 from "../../../../../../templates/components/Table2";
const PaymentPlanList = (props) => {
  const [paymentPlanList, setPaymentPlanList] = useState();
  const [isLoader, setIsLoader] = useState(false)
  const [showPayments, setShowPayments] = useState(false)
  const [showPaymentHistory, setShowPaymentHistory] = useState(false)
  const [selectedPP, setSelectedPP] = useState()
  const [viewInvoice, setViewInvoice] = useState(false)
  const paymentPlanLookup = () => {
    setIsLoader(true);
    let reqObj = {
      SearchTerm: "",
      isActive: true,
      isRegistered: true,
      SortField: "createdOn",
      Asc: false,
      PatientIds: props.patientId
    };
    RecurringPaymentsService.findRecurringPayments(reqObj)
      .then((res) => {
        if (res?.data?.data) {
          setPaymentPlanList(res?.data?.data);
          setIsLoader(false);
        }
      })
      .catch((err) => console.log(err));
    setIsLoader(false);
  };

  useEffect(() => {
    if (props.pull) {
      paymentPlanLookup();
    }
  }, [props.pull]);

  const columns = [
    {
      id: "createdOn",
      Header: "Created On",
      accessor: (pp) => moment(pp.createdOn).format("MM-DD-YYYY")
    },
    {
      id: "invoiceNumber",
      Header: "Invoice Number",
      accessor: pp=> pp.invoiceNumber
    },
    {
      id: "transactionType",
      Header: "Type",
      accessor: (pp) => pp.transactionType === 1 ? "Payment Plan" : "Subscription"
    },
    {
      id: "totalPaymentsMade",
      Header: "Payments Made",
      accessor: (pp) => pp.totalPaymentsMade ? pp.totalPaymentsMade : 0
    },
    {
      id: "totalPaymentLeft",
      Header: "Payments Left",
      accessor: pp=> pp.totalPaymentLeft
    },
    {
      id: "paymentAmount",
      Header: "Payment Amount",
      accessor: (pp) => Utilities.toDollar(pp.paymentAmount)
    },
    {
      id: "totalAmountPaid",
      Header: "Paid",
      accessor: (pp) => Utilities.toDollar(pp.totalAmountPaid)
    },
    {
      id: "totalDueAmount",
      Header: "Open Balance",
      accessor: (pp) => Utilities.toDollar(pp.totalDueAmount)
    },
    {
      id: "totalAmount",
      Header: "Total",
      accessor: (pp) => Utilities.toDollar(pp.totalAmount)
    },
    {
      id: "status",
      Header: "Status",
      accessor: (pp) => TransactionStatusEnum[pp.status]
    },
    {
      id: "action",
      Header: "Actions",
      disableSortBy: true,
      accessor: (pp) => {
        return <span className="w-100 d-flex justify-content-center">
          <div className="col-auto btn-group">
            <button className="btn btn-primary" title="View Payment History" onClick={e => { e.preventDefault(); setSelectedPP(pp); return setShowPaymentHistory(true) }}><i className="icon history" /></button>
            <button className="btn btn-primary" title="View Upcoming Payments" onClick={e => { e.preventDefault(); setSelectedPP(pp); return setShowPayments(true) }}><i className="icon calendar alternate outline" /></button>
            <button className="btn btn-primary" title="View Invoice" onClick={e => { e.preventDefault(); setSelectedPP(pp); return setViewInvoice(true) }}><i className="icon eye" /></button>
          </div>
        </span>
      }
    },

  ]

  return (
    <div className="d-flex row">
      <Table2 data={paymentPlanList} loading={isLoader} columns={columns} />
      <ModalBox open={showPaymentHistory} onClose={() => { setShowPaymentHistory(false) }} title="Payment History">
        {showPaymentHistory && <PaymentHistoryList paymentPlan={selectedPP} pull={true} />}
      </ModalBox>
      <ModalBox open={viewInvoice} onClose={() => { setViewInvoice(false) }} title="View Invoice">
        {viewInvoice && <InvoicePreview invoiceId={selectedPP?.invoiceId} />}
      </ModalBox>
      <ModalBox open={showPayments} onClose={() => { setShowPayments(false) }} title="Scheduled Payments">
        {showPayments && <PaymentsList paymentPlanId={selectedPP?.id}  pull={true}/>}
      </ModalBox>
    </div>
  );
};

export default PaymentPlanList;
