import React, { useState } from "react";



const LogoutModal = (props) => {
  

  

  return (
    <div className="ui ">
      <div>
        <div className="row">
          <h5>Screen Lock</h5>
        <p>Locking screen in 30 seconds due to inactivity</p>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;