import React, { useEffect, useState, useMemo } from 'react'
import ProviderService from '../../../../../../services/api/provider.service'
import AccordionTemplate from '../../../../../templates/components/AccordionTemplate'
import Select from 'react-select'
import CommonService from '../../../../../../services/api/common.service'
import TimeZoneEnum from '../../../../../../common/enum/time-zone.enum'
import States from '../../../../../../common/constants/states.constant'
import InputMask from 'react-input-mask'
import AccessRightsService from '../../../../../../services/api/access-rights.service'
import SettingsService from '../../../../../../services/api/settings.service'
import FeaturesAccessService from '../../../../../../services/api/features-access.service'
import toast from 'react-hot-toast'
import ModuleAccess from '../module-access/ModuleAccess'
const PracticeForm = (props) => {
    const [isLoader, setIsLoader] = useState(false)
    const [isLoader_Module, setIsLoader_Module] = useState(false)
    const [inputData, setInputData] = useState({ contact: { name: {}, address: {} } })
    const [moduleAccess, setModuleAccess] = useState()
    const [parentId, setParentId] = useState(props.practiceId)

    const getPracticeInfo = () => {
        ProviderService.getProviderById(props.practiceId)
            .then(res => {
                console.log(res.data)
                let newObj = { ...res.data }
                delete newObj.createdOn
                delete newObj.facilityName
                delete newObj.fromEmail
                delete newObj.isActive
                delete newObj.loginURL
                delete newObj.logo
                delete newObj.notificationReciepentEmail
                delete newObj.providerActivatedOn
                delete newObj.providerUrlSuffix
                delete newObj.resellerId
                delete newObj.merchantUserName
                delete newObj.skin
                delete newObj.branchName
                delete newObj.contact.name.title
                delete newObj.contact.name.middleName
                setInputData(newObj)
            })
    }

    const getModules = (newModules) => {
        setIsLoader_Module(true)
        AccessRightsService.getModuleConfig(props.practiceId)
            .then(res => {
                console.log(res.data.data)
                let accessList = [...res.data.data]
                newModules.forEach(obj => {
                    obj.parentId = props.practiceId
                    accessList.forEach(access => {
                        if (obj.name == access.name && access.hasAccess == 1) {
                            obj.hasAccess = true
                        }
                        if (obj.name == access.name) {
                            obj.id = access.id
                        }
                    })
                })
                setIsLoader_Module(false)
                return setModuleAccess(newModules)
            })
            .catch(err => {
                console.log(err)
                setIsLoader_Module(true)
            })
    }

    const getDefaultModules = () => {
        FeaturesAccessService.getDefaultFeaturesAccess(1)
            .then(res => {
                let newModules = res.data.map(obj => {
                    let newObj = { hasAccess: false, moduleId: obj.id, name: obj.name, userType: 1 }
                    if (obj.name == 'Patient Management' || obj.name == 'Settings') {
                        newObj.hasAccess = true;
                    }
                    return newObj
                })
                if (props.practiceId) {
                    getModules(newModules)
                }
                else {
                    setModuleAccess(newModules)
                }
            })
    }


    // const timeZones = useMemo(() => {
    //     console.log(CommonService.getTimeZoneList())
    //     return CommonService.getTimeZoneList()
    // }, [])

    useEffect(() => {
        if (props.practiceId) {
            getPracticeInfo()
        }
        getDefaultModules()
    }, [props.practiceId])

    // function for input change
    const inputChange = (e) => {
        let newStateObject = { ...inputData };
        newStateObject[e.target.name] = e.target.value;
        return setInputData(newStateObject);
    };
    const contactChange = (e) => {
        let newValue = e.target.value
        if (e.target.name == 'phone') {
            newValue = e.target.value.replaceAll(/[^a-zA-Z0-9]/g, "")
            console.log(newValue)
        }
        let newStateObject = { ...inputData };
        newStateObject.contact[e.target.name] = newValue;
        return setInputData(newStateObject);
    }
    const nameChange = (e) => {
        let newStateObject = { ...inputData };
        newStateObject.contact.name[e.target.name] = e.target.value;
        return setInputData(newStateObject);
    }
    const addressChange = (e) => {
        let newStateObject = { ...inputData };
        newStateObject.contact.address[e.target.name] = e.target.value;
        return setInputData(newStateObject);
    }

    const updateModule = (i, checked) => {
        let newObj = [...moduleAccess]
        newObj[i].hasAccess = checked
        return setModuleAccess(newObj)
    }

    const submitHandler = () => {
        if (props.practiceId) {
            let newData = { ...inputData }
            ProviderService.editProvider(newData)
                .then(res => {
                    console.log(res)
                })
            if (!props.noModules) {
                FeaturesAccessService.postModuleAccess({ moduleConfig: moduleAccess }, props.practiceId)
                    .then(res => {
                        console.log(res)
                        toast.success("Practice is Updated")
                        if (props.onClose) {
                            props.onClose()
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        }
        else {
            let newData = { ...inputData }
            if (newData?.contact?.phone) {
                newData.contact.phone = inputData.contact.phone?.replaceAll("\\W+", "", "")
            }
            ProviderService.addProvider(newData)
                .then(res => {
                    console.log(res)
                    FeaturesAccessService.postModuleAccess({ moduleConfig: moduleAccess }, res.id)
                        .then(res => {
                            console.log(res)
                            toast.success("Practice is Added")
                            if (props.onClose) {
                                props.onClose()
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                })
                .catch(err => {
                    console.log(err)
                })

        }
    }

    return (
        <div className='row d-flex'>
            <AccordionTemplate id="practiceForm" accordionId="practiceForm">
                <div title="Primary Details">
                    <div className='row d-flex'>
                        <div className='col-md-6 col-12'>
                            <div className='field required'>
                                <label>Practice Name</label>
                                <input type="text" name="name" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData?.name} />
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='field required'>
                                <label>Provider Access Key</label>
                                <input type="text" name="merchantKey" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData?.merchantKey} />
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='field required'>
                                <label>Provider Phone</label>
                                <InputMask
                                    placeholder="Phone"
                                    type="text"
                                    name="phone"
                                    mask="(999) 999-9999"
                                    unmask={true}
                                    onUnmask
                                    value={inputData?.contact?.phone}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        contactChange(e);
                                    }}
                                    editable
                                />
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='field'>
                                <label>Provider URL</label>
                                <input type="text" name="url" onChange={e => { e.preventDefault(); contactChange(e) }} value={inputData?.contact?.url} />
                            </div>
                        </div>
                        {/* <div className='col-md-6 col-12'>
                            <div className='field required'>
                                <label>Time Zone</label>
                                <Select 
                                className='react-select-container'
                                classNamePrefix='react-select'
                                options={timeZones} 
                                getOptionLabel={(tz)=>`${tz.name} (${tz.abbrev})`}
                                getOptionValue={tz=>TimeZoneEnum[tz.name]}
                                />
                            </div>
                        </div> */}
                        <div className='col-md-6 col-12'>
                            <div className='field required'>
                                <label>Admin First Name</label>
                                <input type="text" name="firstName" onChange={e => { e.preventDefault(); nameChange(e) }} value={inputData?.contact?.name?.firstName} />
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='field required'>
                                <label>Admin Last Name</label>
                                <input type="text" name="lastName" onChange={e => { e.preventDefault(); nameChange(e) }} value={inputData?.contact?.name?.lastName} />
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='field required'>
                                <label>Admin Username</label>
                                <input type="text" name="providerAdminUser" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData?.providerAdminUser} />
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='field required'>
                                <label>Admin Email</label>
                                <input type="text" name="email" onChange={e => { e.preventDefault(); contactChange(e) }} value={inputData?.contact?.email} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='field'>
                                <label>NPI Number</label>
                                <input type="text" name="providerNpi" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData?.providerNpi} />
                            </div>
                        </div>
                    </div>
                </div>
                <div title="Address Details">
                    <div className='row d-flex'>
                        <div className='col-12'>
                            <div className='field required'>
                                <label>Address Line 1</label>
                                <input type="text" name="addressLine1" onChange={e => { e.preventDefault(); addressChange(e) }} value={inputData?.contact?.address?.addressLine1} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='field'>
                                <label>Address Line 2</label>
                                <input type="text" name="addressLine2" onChange={e => { e.preventDefault(); addressChange(e) }} value={inputData?.contact?.address?.addressLine2} />
                            </div>
                        </div>
                        <div className="field required col-12">
                            <label>City</label>
                            <input
                                placeholder="City"
                                type="text"
                                name="city"
                                value={inputData?.contact?.address?.city}
                                onChange={(e) => {
                                    e.preventDefault();
                                    addressChange(e);
                                }}
                            />
                        </div>
                        <div className="field col-md-4 col-12">
                            <label>Country</label>
                            <input
                                name="country"
                                placeholder="USA"
                                type="text"
                                value="USA"
                                onChange={(e) => { e.preventDefault(); addressChange(e) }}
                                disabled
                            />
                        </div>
                        <div className="field required col-md-4 col-12">
                            <label>State</label>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={
                                    inputData?.contact?.address?.country === "CANADA"
                                        ? States[2]
                                        : States[1]
                                }
                                name="state"
                                value={
                                    inputData?.contact?.address?.country === "CANADA"
                                        ? States[2].find(
                                            (obj) =>
                                                obj.abbreviation === inputData?.contact?.address?.state
                                        )
                                        : States[1].find(
                                            (obj) =>
                                                obj.abbreviation === inputData?.contact?.address?.state
                                        )
                                }
                                onChange={(e) => {
                                    addressChange({
                                        target: {
                                            value: e.abbreviation,
                                            name: "state",
                                        },
                                    });
                                }}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.abbreviation}
                            />
                        </div>
                        <div className="field required col-md-4 col-12">
                            <label>Postal Code</label>
                            <input
                                placeholder="Postal Code"
                                type="text"
                                name="postalCode"
                                value={inputData?.contact?.address?.postalCode}
                                onChange={(e) => {
                                    e.preventDefault();
                                    addressChange(e);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div title="Module Access Details" hide={props.noModules}>
                    <ModuleAccess practiceId={props.practiceId} isEdit={props.isEdit} isEmbedded={true} updateModule={updateModule} moduleAccess={moduleAccess} />
                </div>
            </AccordionTemplate>
            <div className='col-12'>
                <div className='row d-flex mt-3 justify-content-between modal-button-bar'>
                    <div className="col-auto">
                        <button onClick={e => { e.preventDefault(); submitHandler() }} className="btn btn-primary">{props.isEdit ? 'Update' : 'Submit'}</button>
                    </div>
                    <div className="col-auto">
                        {!props.isEdit ? <button onClick={e => { e.preventDefault(); props.onClose() }} className="btn btn-secondary">Close</button> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PracticeForm