import AppSetting from '../../common/constants/appsetting.constant';
import StorageType from '../session/storage.enum';
import StorageService from '../session/storage.service';
import axios from 'axios'

const LeadSourceService = {
  getLoggedInData() {
    return JSON.parse(StorageService.get(StorageType.session, 'userDetails'));
  },
  addLeadSource(reqObj) {
    let loggedInUserData = this.getLoggedInData();
    let url = AppSetting.settings.addLeadSource
      .replace('{providerId}', loggedInUserData.parentId);
    return axios.post(url, reqObj)
      .then(a => { return a })
      .catch(err => {return err})
  },

  editLeadSource(reqObj, id) {
    let loggedInUserData = this.getLoggedInData();
    let url = '';
    url = AppSetting.settings.editLeadSource
      .replace('{providerId}', loggedInUserData.parentId)
      .replace('{leadSourceId}', id);
    return axios.put(url, reqObj)
      .then(a => { return a })
      .catch(err => {return err})
  },

  deleteLeadSource(id) {
    let loggedInUserData = this.getLoggedInData();
    let url;
    url = AppSetting.settings.deleteLeadSource
      .replace('{providerId}', loggedInUserData.parentId)
      .replace('{leadSourceId}', id);

    return axios.delete(url)
      .then(a => { return a })
      .catch(err => {return err})

  },

getAllLeadSource() {
    let loggedInUserData = this.getLoggedInData();
    let url = AppSetting.settings.getAllLeadSource
      .replace('{providerId}', loggedInUserData.parentId);

    return axios.get(url)
      .then(a => { return a })
      .catch(err => {return err})
  },

  getAllLead(params) {
    let loggedInUserData = this.getLoggedInData();
    let url = AppSetting.settings.getAllLead
      .replace('{providerId}', loggedInUserData.parentId)
      + this.buildQuery(params);
    return axios.get(url)
      .then(a => { return a })
      .catch(err => {return err})
  },
  editLead(reqObj, id) {
    let loggedInUserData = this.getLoggedInData();
    let url = AppSetting.settings.editLead
      .replace('{providerId}', loggedInUserData.parentId)
      .replace('{leadId}', id);
    return axios.put(url, reqObj)
      .then(a => { return a })
      .catch(err => {return err})
  },
  updateLeadStatus(reqObj, id) {
    let loggedInUserData = this.getLoggedInData();
    let url = AppSetting.settings.updateLeadStatus
      .replace('{providerId}', loggedInUserData.parentId)
      .replace('{leadId}', id);
    return axios.put(url, reqObj)
      .then(a => { return a })
      .catch(err => {return err})
  },
  getLeadById(id) {
    let loggedInUserData = this.getLoggedInData();
    let url = AppSetting.settings.getLeadById
      .replace('{providerId}', loggedInUserData.parentId)
      .replace('{leadId}', id);

    return axios.get(url)
      .then(res => { return res })
      .catch(err => { return err })

  },
  getAllMatchedPatient() {
    let loggedInUserData = this.getLoggedInData();
    let url = AppSetting.settings.getAllMatchedPatient
      .replace('{providerId}', loggedInUserData.parentId);

    return axios.get(url)
      .then(a => { return a })
      .catch(err => {return err})
  },
  buildQuery(data) {
    let queryData = '';
    for (const prop in data) {
      if (data[prop] !== '' && data[prop] !== 'undefined' && data[prop] !== null) {
        if (queryData === '') {
          queryData = '?' + prop + '=' + data[prop];
        } else {
          queryData += '&' + prop + '=' + data[prop];
        }
      }
    } return queryData;
  },
}

export default LeadSourceService