import React, { useState, useEffect } from 'react'
import moment from 'moment'
import ModalBox from '../../../../templates/components/ModalBox'
// import AddInvoice from '../../invoices/add-invoice/AddInvoice'
// import PatientService from '../../../../../services/api/patient.service'
// import AppointmentPreview from '../../patient/patient-list/patient-card/appointment-list/AppointmentPreview'
// import DoctorService from '../../../../../services/api/doctor.service'
// import toast from 'react-hot-toast'
// import Utilities from '../../../../../services/commonservice/utilities'
import EditAppointmentForm from '../edit-appointment-form/EditAppointmentForm'


const AppointmentCard = (props) => {
    let startTime = moment(props.appointment.fromDate).format('h:mm')
    let endTime = moment(props.appointment.toDate).format('h:mm A')
    // console.log(props.appointment)
    // const [open, setOpen] = useState(false)
    // const [showPayment, setShowPayment] = useState(false)
    // const [view, setView] = useState(false)
    // const [provider, setProvider] = useState()
    // const [checkInView, setCheckInView] = useState(false)
    // const [checkOutView, setCheckOutView] = useState(false)
    // const [providerInView, setProviderInView] = useState(false)
    // const [checkOutInvoice, setCheckOutInvoice] = useState(false)
    // const [openBalance, setOpenBalance] = useState(0)
    const [editAppt, setEditAppt] = useState(false)


    // const checkIn = () => {
    //     let reqObj = {
    //         checkInDate: moment().format("YYYY-MM-DDThh:mm:ss.sssZ"),
    //         doctorId: props.appointment.doctorId,
    //         patientName: props.appointment.firstName,
    //         visitStatus: 1,
    //         appointmentId: props.appointment.id,
    //     }
    //     PatientService.addVisits(reqObj, props.appointment.patientId)
    //         .then(res => {
    //             setCheckInView(false)
    //                 .then(res => {
    //                     console.log(res)
    //                 })
    //             toast.success("Patient Checked In")
    //             if (props.refresh) {
    //                 props.refresh()
    //             }
    //         })
    // }

    // const checkOut = () => {
    //     let reqObj = {
    //         id: props.appointment.patientVisitId,
    //         visitStatus: 3,
    //         checkOutDate: new Date(),
    //         items: [],
    //     }
    //     PatientService.updateVisits(reqObj, props.appointment.patientId, props.appointment.patientVisitId)
    //         .then(res => {
    //             setCheckOutView(false)
    //             toast.success("Patient Checked Out")
    //             setCheckOutInvoice(true)
    //             if (props.refresh) {
    //                 props.refresh()
    //             }
    //         })
    // }

    // const doctorCheckIn = () => {
    //     let reqObj = {
    //         id: props.appointment.patientVisitId,
    //         visitStatus: 2,
    //         doctorCheckInDate: new Date(),
    //         doctorId: props.appointment.doctorId,
    //     }
    //     PatientService.updateVisits(reqObj, props.appointment.patientId, props.appointment.patientVisitId)
    //         .then(res => {
    //             setProviderInView(false)
    //             toast.success("Patient is with Provider")
    //             if (props.refresh) {
    //                 props.refresh()
    //             }
    //         })
    // }

    // useEffect(() => {
    //     if (checkInView) {
    //         PatientService.getPatientBalance({ PatientIds: props.appointment.patientId })
    //             .then(res => {
    //                 console.log(res)
    //                 if (res?.data[0]?.openBalance) {
    //                     setOpenBalance(res?.data[0]?.openBalance)
    //                 }
    //             })
    //     }
    // }, [checkInView])
    return (
        <div>
            <div className="row-fluid px-4 ui segment">
                <div className='btn p-0 col-12 d-flex justify-content-between align-items-center'>
                    <div className='col-12 row d-flex align-items-center'>
                        {props.appointment.parentGroupId && <span className="col-lg-2 col-md-6 col-12"><i className="icon dot circle"></i></span>}
                        {!props.appointment.parentGroupId && <span className="col-lg-2 col-md-6 col-12 text-start">{props.appointment.firstName} {props.appointment.lastName}</span>}
                        <span className="col-lg-2 col-md-6 col-12 text-start">{props.appointment.drFirstName} {props.appointment.drLastName}</span>
                        <span className="col-lg-2 col-md-6 col-12 text-start">{props.appointment.appointmentStatusCode}</span>
                        <span className="col-lg-2 col-md-6 col-12 text-start">{props.appointment.practiceServiceType}</span>
                        <span className="col-lg-2 col-md-6 col-12 text-start">{startTime}-{endTime}</span>
                        {!props.appointment.parentGroupId && <div className='col-md-auto col-12 btn-group'>
                            {/* {props.appointment.visitStatus === 0 || !props.appointment.visitStatus ?
                                <button className="btn btn-primary" onClick={e => { e.preventDefault(); setCheckInView(true) }}><i className="ui icon text-white calendar check outline" title="Check In" /></button>
                                : null}

                            {props.appointment.visitStatus === 1 &&
                                <button className="btn btn-primary" onClick={e => { e.preventDefault(); setProviderInView(true) }} ><i className="ui icon text-white stethoscope" title="With Provider" /></button>}

                            {props.appointment.visitStatus === 2 && <button className="btn btn-primary" onClick={e => { e.preventDefault(); setCheckOutView(true) }}><i className="ui icon text-white calendar times outline" title="Check Out" /></button>}
                            <button className='btn btn-primary' onClick={e => { e.preventDefault(); setShowPayment(true) }} title="Collect Payment">
                                <i className='icon dollar' />
                            </button>
                            <button className='btn btn-primary' onClick={e => { e.preventDefault(); setView(true) }} title="View Details">
                                <i className='icon eye' />
                            </button> */}
                            <button className='btn btn-primary' onClick={e => { e.preventDefault(); setEditAppt(true) }} title="Edit">
                                <i className='icon pencil' />
                            </button>
                        </div>}
                    </div>
                </div>
                {/* {open && <div className='col-12 d-flex justify-content-between align-items-center mt-3'>
                </div>} */}
            </div>
            {/* <ModalBox open={showPayment} onClose={() => { setShowPayment(false) }} size="fullscreen" title="Add Invoice">
                <AddInvoice initialData={props.appointment} onClose={() => { setShowPayment(false) }} isModal />
            </ModalBox>
            <ModalBox open={view} onClose={() => { setView(false); }} title='Appointment Preview' size="small">
                <AppointmentPreview id={props.appointment.id} onClose={() => { setView(false); }} />
            </ModalBox>
            <ModalBox open={checkOutInvoice} onClose={() => { setCheckOutInvoice(false) }} size="fullscreen" title="Add Invoice">
                <AddInvoice initialData={props.appointment} onClose={() => { setCheckOutInvoice(false); }} isModal/>
            </ModalBox>
            <ModalBox open={checkInView} onClose={() => { setCheckInView(false); }}>
                <div className='row d-flex'>
                    {openBalance > 0 ? <div className='col-12 mb-3'>Patient has an open balance of {Utilities.toDollar(openBalance)}</div> : null}
                    <div className='col-12 mb-3'>Are you sure you want to Check In?</div>
                    <div className='col-12 d-flex row justify-content-between'>
                        <div className="col-auto"><button className="btn btn-primary" onClick={e => { e.preventDefault(); checkIn() }}>Check In</button></div>
                        <div className="col-auto"><button className="btn btn-secondary" onClick={e => { e.preventDefault(); setCheckInView(false) }}>Close</button></div>
                    </div>
                </div>
            </ModalBox >
            <ModalBox open={providerInView} requireConfirm onClose={() => { setProviderInView(false) }} onCloseSuccess={() => { doctorCheckIn() }} confirmButton="With Provider" cancelButton="Cancel">
                <span> Is the patient with a provider?</span>
            </ModalBox>
            <ModalBox open={checkOutView} requireConfirm onClose={() => { setCheckOutView(false) }} onCloseSuccess={() => { checkOut() }} confirmButton="Check Out" cancelButton="Cancel">
                <span>Are you sure you want to Check Out?</span>
            </ModalBox> */}
            <ModalBox open={editAppt} onClose={() => { setEditAppt(false) }} title="Edit Appointment">
                <EditAppointmentForm id={props.appointment.parentGroupId || props.appointment.id} onClose={() => { setEditAppt(false); if (props.refresh()) { props.refresh() } }} buttonLabel="Update" />
            </ModalBox>
        </div >
    )
}

export default AppointmentCard