import React, { useState } from 'react'
import { Modal, Transition } from 'semantic-ui-react'
import ConfirmCloseModal from './ConfirmCloseModal'

const ModalBox = (props) => {
    const { open, onClose, onOpen, children } = (props)
    const [confirmModal, setConfirmModal] = useState(false)
    return (
        <div>
            <Transition.Group animation="drop" duration={600}>
                {props.open && <Modal
                    style={{ overflow: 'unset !important' }}
                    open={open}
                    centered={true}
                    size={props.size && props.size}
                    onClose={() => {
                        props.onClose()
                    }}
                    onOpen={e => { 
                        e.preventDefault(); 
                        onOpen(); 
                    }}
                    closeOnEscape={true}
                    closeOnDimmerClick={true}
                    closeIcon={false && !props.title && <i className="btn btn-transparent p-0 m-0 icon x text-primary float-right" />}>
                    {<span className='modal-header'> 
                        <span className='modal-title'>{props.title}&nbsp;</span>
                        {props.onClose && <i className="btn btn-transparent p-1 m-0 icon x text-primary mb-2 modal-close-icon" onClick={(e) => { e.preventDefault(); props.onClose() }} />}
                    </span>}
                    <Modal.Content>
                        <div style={{ maxHeight: '75vh' }} className="scroll-list p-4">
                            {!props.onCloseSuccess && children}

                            {props.onCloseSuccess &&
                                <div className='row d-flex align-items-end justify-content-between'>
                                    <div className='col'>
                                        {children}
                                    </div>
                                    <div className="col-auto">
                                        {props.extraButton && props.extraButton}
                                        <button className='btn btn-primary ms-3 col-auto' onClick={e => { e.preventDefault(); props.onCloseSuccess() }}>{props.confirmButton || 'Confirm'}</button>
                                        <button className='btn btn-secondary ms-3 col-auto' onClick={e => { e.preventDefault(); props.onClose() }}>{props.cancelButton || 'Cancel'}</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </Modal.Content>
                </Modal>}
            </Transition.Group>
        </div >
    )
}

export default ModalBox