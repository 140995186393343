import React, { useEffect, useState } from "react";
import AddInvoice from "../../invoices/add-invoice/AddInvoice";
import ModalBox from "../../../../templates/components/ModalBox";
import RecurringPaymentsService from "../../../../../services/api/recurring-payments.service";
import Module from "../../../../templates/components/Module";
import moment from "moment";
import Utilities from "../../../../../services/commonservice/utilities";
import CloseAndWriteOff from "../../transactions/close-and-write-off/CloseAndWriteOff";
import InvoiceService from "../../../../../services/api/invoice.service";
import PaymentHistoryList from "../paymentPlan-list/paymentPlan-card/payment-history-accordion/payment-history-list/PaymentHistoryList";
import InvoicePreview from "../../invoices/invoice-preview/InvoicePreview";
import PaymentsList from "../paymentPlan-list/paymentPlan-card/payments-accordion/payments-list/PaymentsList";
import Table2 from "../../../../templates/components/Table2";
import DatePicker from "../../../../templates/components/DatePicker";
import DimLoader from "../../../../templates/components/DimLoader";

const PaymentPlanTable = (props) => {
  const [paymentPlanList, setPaymentPlanList] = useState();
  // const [keyword, setKeyword] = useState("64Q2M1xn")
  const [keyword, setKeyword] = useState("");
  const [sortBy, setSortBy] = useState('Desc')
  const [addInvoice, setAddInvoice] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [cancel, setCancel] = useState(false)
  const [cancelWO, setCancelWO] = useState(false)
  const [closeReason, setCloseReason] = useState()
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState()
  const [startDate, setStartDate] = useState(moment().startOf("D"));
  const [endDate, setEndDate] = useState(moment().endOf("D"));
  const [showPayments, setShowPayments] = useState(false)
  const [showPaymentHistory, setShowPaymentHistory] = useState(false)
  const [viewInvoice, setViewInvoice] = useState(false)
  const [initialMessage, setInitialMessage] = useState(true)




  const cancelHandler = () => {
    let reqObj = { closeReason: closeReason }
    InvoiceService.closeAndWriteOff(selectedPaymentPlan?.invoiceId, reqObj)
      .then(res => {
        console.log(res)
      })
  }

  const paymentPlanLookup = () => {
      let reqObj = {
      SearchTerm: "",
      isActive: true,
      isRegistered: true,
      SortField: "createdOn",
      Asc: false,
    };
    if (startDate) reqObj.StartDate = moment(startDate).toISOString()
    if (endDate) reqObj.EndDate = moment(endDate).toISOString()
    setIsLoader(true)
    setInitialMessage(false);
    RecurringPaymentsService.findRecurringPayments(reqObj)
      .then((res) => {
        if (res?.data?.data) {
          setPaymentPlanList(res?.data?.data);
          setIsLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const columns = [
  {
    Header: "Type",
    accessor: (tc) => tc.recurringType
  },
  {
    Header: "Invoice Number",
    accessor: (tc) => tc.invoiceNumber,
    minWidth: "150px"
  },
  {
    id: "status",
    Header: "Status",
    accessor: (pp) => pp.recurringStatus, 
    minWidth: "150px"
  },
  {
    Header: "First Name",
    accessor: (tc) => tc.firstName,
    minWidth: "150px"
  },
  {
    Header: "Last Name",
    accessor: (tc) => tc.lastName,
    minWidth: "150px"
  },

  {
    id: "nextTransactionDate",
    Header: "Next Date",
    accessor: (pp) => { 
      return pp.nextTransactionDate ? moment.utc(pp.nextTransactionDate).format("M/D/YYYY") : 'none'
    },
    minWidth: "150px"
  },
  {
    id: "nextTransactionAmount",
    textAlign: 'right',
    Header: "Next Amount",
    accessor: (pp) => { return Utilities.toDollar(pp.nextTransactionAmount) },
    Footer: () => "Totals",
    minWidth: "150px"
  },

  {
    id: "TotalTxnAmount",
    textAlign: 'right',
    Header: "Total Amount",
    accessor: (pp) => { return Utilities.toDollar(pp.fullAmountDue) },
    Footer: info => {
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => row.original.fullAmountDue + sum, 0),
        [info.rows]
      )

      return <>{Utilities.toDollar(total)}</>
    },
    minWidth: "150px"
  },
  {
    id: "TotalTxnCount",
    textAlign: 'right',
    Header: "Total Count",
    accessor: (pp) => pp.noOfPayments,
    Footer: info => {
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => row.original.noOfPayments + sum, 0),
        [info.rows]
      )

      return <>{total}</>
    },
    minWidth: "150px"
  },

  {
    id: "PaidTxnAmount",
    textAlign: 'right',
    Header: "Paid Amount",
    accessor: (pp) => { return Utilities.toDollar(pp.paidTxnAmount) },
    Footer: info => {
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => row.original.paidTxnAmount + sum, 0),
        [info.rows]
      )

      return <>{Utilities.toDollar(total)}</>
    },
    minWidth: "150px"
  },
  {
    id: "PaidTxnCnt",
    textAlign: 'right',
    Header: "Paid Cnt",
    accessor: (pp) => pp.paidTxnCnt,
    Footer: info => {
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => row.original.paidTxnCnt + sum, 0),
        [info.rows]
      )

      return <>{total}</>
    },
    minWidth: "150px"
  },

  {
    id: "problemTxnAmount",
    textAlign: 'right',
    Header: "Problem Amount",
    accessor: (pp) => { return Utilities.toDollar(pp.problemTxnAmount) },
    Footer: info => {
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => row.original.problemTxnAmount + sum, 0),
        [info.rows]
      )

      return <>{Utilities.toDollar(total)}</>
    },
    minWidth: "150px"
  },
  {
    id: "problemTxnCnt",
    textAlign: 'right',
    Header: "Problem Cnt",
    accessor: (pp) => pp.problemTxnCnt,
    Footer: info => {
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => row.original.problemTxnCnt + sum, 0),
        [info.rows]
      )

      return <>{total}</>
    },
    minWidth: "150px"
  },

  {
    id: "PendingTxnAmount",
    textAlign: 'right',
    Header: "Pending Amount",
    accessor: (pp) => { return Utilities.toDollar(pp.pendingTxnAmount) },
    Footer: info => {
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => row.original.pendingTxnAmount + sum, 0),
        [info.rows]
      )

      return <>{Utilities.toDollar(total)}</>
    },
    minWidth: "150px"
  },
  {
    id: "PendingTxnCount",
    textAlign: 'right',
    Header: "Pending Count",
    accessor: (pp) => pp.pendingTxnCount,
    Footer: info => {
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => row.original.pendingTxnCount + sum, 0),
        [info.rows]
      )

      return <>{total}</>
    },
    minWidth: "150px"
  },

    {
      Header: "Actions",
      disableSortBy: true,
      textAlign: 'center',
      accessor: (pp) => {
        return <div className="w-100 d-flex justify-content-center"><div className="btn-group">{pp.status === 1 ? <button className="btn btn-primary" onClick={e => { e.preventDefault(); setSelectedPaymentPlan(pp); return setCancel(true) }} title="cancel"><i className="icon dont" /></button>
          : <button className="btn btn-primary" onClick={e => { e.preventDefault(); setSelectedPaymentPlan(pp); return setCancelWO(true) }} title="Cancel & Write Off"><i className="icon dont" /></button>}
          <button className="btn btn-primary" title="View Payment History" onClick={e => { e.preventDefault(); setSelectedPaymentPlan(pp); return setShowPaymentHistory(true) }}><i className="icon history" /></button>
          <button className="btn btn-primary" title="View Upcoming Payments" onClick={e => { e.preventDefault(); setSelectedPaymentPlan(pp); return setShowPayments(true) }}><i className="icon calendar alternate outline" /></button>
          <button className="btn btn-primary" title="View Invoice" onClick={e => { e.preventDefault(); setSelectedPaymentPlan(pp); return setViewInvoice(true) }}><i className="icon eye" /></button></div></div>
      }
    },
  ]

  return (
    <>
    {isLoader &&
        <DimLoader loadMessage="Processing" />
    }    

    <div className="row d-flex g-3">
      <div className="col-12 m-p-0">
        <Module title="Filters">
          <div className='row d-flex'>
            <div className='col-12 mx-0  report-filters'>
              <div className="d-flex row justify-content-center align-items-end">
                <DatePicker startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} includeAll />
                <div className="col-md-auto col-12"><button className="btn btn-primary" onClick={e => { e.preventDefault(); paymentPlanLookup() }} title="Pull Report"><i className="icon arrow circle right" /></button></div>
              </div>
            </div>
          </div>
        </Module>
      </div>
      <div className="col-12 m-p-0">
        <Module title="Payment Plan Report">
          <Table2 export searchable title="Payment Plan Report" data={paymentPlanList} loading={isLoader} columns={columns} initialMessage={initialMessage}/>
        </Module>
      </div>
      <ModalBox open={addInvoice} onClose={() => setAddInvoice(false)}>
        <AddInvoice />
      </ModalBox>
      <ModalBox open={cancel} onClose={() => { setCancel(false) }} onCloseSuccess={() => { cancelHandler(); if (props.refresh) { props.refresh() }; return setCancel(false) }} size="tiny">
        Why are you cancelling this Invoice? <input type="text" className="mt-3" minLength={10} value={closeReason} onChange={e => { e.preventDefault(); setCloseReason(e.target.value) }} />
      </ModalBox>
      <ModalBox open={cancelWO} onClose={() => { setCancelWO(false) }} size="tiny">
        <CloseAndWriteOff refresh={() => { if (props.refresh) { props.refresh() } }} invoiceId={selectedPaymentPlan?.invoiceId} onClose={() => { setCancelWO(false); if (props.refresh) { props.refresh() } }} />
      </ModalBox>
      <ModalBox open={showPaymentHistory} onClose={() => { setShowPaymentHistory(false) }} title="Payment History">
        {showPaymentHistory && <PaymentHistoryList paymentPlan={selectedPaymentPlan} pull={true} />}
      </ModalBox>
      <ModalBox open={viewInvoice} onClose={() => { setViewInvoice(false) }} title="View Invoice">
        {viewInvoice && <InvoicePreview invoiceId={selectedPaymentPlan?.invoiceId} />}
      </ModalBox>
      <ModalBox open={showPayments} onClose={() => { setShowPayments(false) }} title="Scheduled Payments">
        {showPayments && <PaymentsList paymentPlanId={selectedPaymentPlan?.id} pull={true} />}
      </ModalBox>
    </div>
    </>
  );
};

export default PaymentPlanTable;
