import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import label from "../../../../../../../assets/i18n/en.json";
import moment from "moment";
import ProductService from "../../../../../../services/api/product.service";
import FeesAdd from "./fees/fees-add/FeesAdd";
import FeesEdit from "./fees/fees-edit/FeesEdit";
import MessageSetting from "../../../../../../common/constants/message-setting.constant";
import ModalBox from "../../../../../templates/components/ModalBox";
import Utilities from "../../../../../../services/commonservice/utilities";
import Table2 from "../../../../../templates/components/Table2";
import { FeeApplicableTo as FeeApplicableTo } from "../../../../../../common/enum/fee-applicable-to.enum"
import APIResponse from "../../../../../templates/components/APIResponse";

const FeesTable = (props) => {
  const [isLoader, setIsLoader] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [feesList, setFeesList] = useState([]);
  const [selectedFees, setSelectedFees] = useState();
  const [feesLookupList, setFeesLookupList] = useState([]);
  const [filter, setFilter] = useState()
  const hiddenColumns = ["isActive","actionPracticePatient"]
  const [apiResponse, setApiResponse] = useState()

  const onSuccess = (message) => {
    if (props.refresh) {
        props.refresh()
    }
    if (props.onClose) {
        props.onClose()
    }
    if (props.exitHandler) {
        props.exitHandler()
    }
}

  const findFees = () => {
    let reqObj = { ItemType: 4 }
    setIsLoader(true);
    ProductService.findProduct(reqObj)
      .then((response) => {
        setFeesList(response.data);
        setFeesLookupList(response.data);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };
  const statusHandler = () => {
    setStatusModal(false);
    setIsLoader(true)
    if (selectedFees.status == 1) {
      ProductService.deactivateProduct(selectedFees.id).then(response => {
        setApiResponse(response)
        findFees();
      })
        .catch(error => {
          setApiResponse(error)
        })
        .finally(() => {
          setIsLoader(false)
        });

    } else {
      ProductService.activateProduct(selectedFees.id).then(response => {
        setApiResponse(response)
        findFees();
      })
        .catch(error => {
          setApiResponse(error)
        })
        .finally(() => {
          setStatusModal(false);
          setIsLoader(false)
        });
    }
  };

  useEffect(() => {
    findFees();
  }, []);

  const columns = [
    {
      id: "createdOn",
      Header: "Created On",
      accessor: (fees) => moment(fees.createdOn).format("MM/DD/YYYY"),
      //Footer: () => 'Totals'
    },
    {
      id: "name",
      Header: "Name",
      accessor: item => item.name,
    },
    {
      id: "feeRequired",
      Header: "Required",
      accessor: fees => fees.feeRequired === 1 ? "Yes" : "No"
    },
    {
      id: "feeAmount",
      Header: "Fee($)",
      accessor: fees => fees.feeAmount ? Utilities.toDollar(fees.feeAmount) : null
    },
    {
      id: "feePercentage",
      Header: "Fee(%)",
      accessor: fees => fees.feePercentage ? `${fees.feePercentage}%` : null
    },
    {
      id: "feeIsTaxable",
      Header: "Taxable",
      accessor: item => item.feeIsTaxable === 1 ? "Yes" : "No"
    },
    {
      id: "taxPercent",
      Header: "Tax(%)",
      accessor: item => item.taxPercent ? `${item.taxPercent}%` : null
    },

    {
      id: "feeApplicableTo",
      Header: "Applicable To",
      accessor: (item) => FeeApplicableTo.find(obj => obj.value === item.feeApplicableTo)?.title
    },
    {
      id: "isActive",
      Header: "Active",
      align: "center",
      sortable: true,
      accessor: (fees, i) =>
        fees.status === 1 ? (
          <span className="btn btn-success text-white w-100">Active</span>
        ) : (
          <span className="btn btn-danger text-white w-100">Inactive</span>
        ),
    },
    {
      id: "actionPracticePatient",
      Header: "Action",
      disableSortBy: true,
      accessor: (fees, i) => {
        return (
          <div className="d-flex justify-content-center">
            <button
              className="p-0 ps-1 btn btn-primary"
              title="Edit Fees"
              onClick={(e) => {
                e.preventDefault();
                setSelectedFees(fees);
                return setOpenModal(true);
              }}
            >
              <i className="icon pencil" />
            </button>
            <button
              className="p-0 ps-1 btn btn-primary ms-1"
              title={
                fees.status === 1 ? "Deactivate Fees" : "Activate Fees"
              }
              onClick={(e) => {
                e.preventDefault();
                setSelectedFees(fees);
                return setStatusModal(true);
              }}
            >
              {fees.status === 1 ? <i className="icon dont" /> : <i className="icon check" />}
            </button>
          </div>
        );
      },
    },
  ];



  return (
    <>
      {feesList ? (
        <div className="row d-flex col-12 mb-3">
          <div>{feesList ? (
            <Table2
              hiddenColumns={hiddenColumns}
              export
              searchable
              loading={isLoader}
              columns={columns}
              data={feesList.filter(obj => { if (filter === '' || filter === null || !filter || filter === 'All') { return obj } else { return obj.status === parseInt(filter) } })}
              extraButtons={[
                {
                  className: 'btn btn-primary mt-auto',
                  children: <i className="icon plus" />,
                  title: "Add Fees",
                  onClick: () => { setShowAdd(true) }
                }
              ]}
            extraElements={
              [
                {
                  children: <div className="field">
                    <label>Filter By</label>
                    <select className="form-select" value={filter} onChange={e => { setFilter(e.target.value) }}>
                      <option value={null} selected>All</option>
                      <option value={1}>Active Only</option>
                      <option value={0}>Inactive Only</option>
                    </select>
                  </div>
                }
              ]
            }
            />
          ) :
            null}
          </div>
        </div>
      ) : null}
      <ModalBox open={openModal} onClose={() => setOpenModal(false)} title="Edit Fees">
        {selectedFees && <FeesEdit 
          id={selectedFees.id}
          onClose={() => {
            findFees();
            return setOpenModal(false);
          }}
        />}
      </ModalBox>

      <ModalBox open={statusModal} onClose={() => setStatusModal(false)} title={selectedFees?.status === 1 ? "Deactivate Fees":"Activate Fees"}>
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col">
            {selectedFees?.status === 1 ? (
              <p>Would you like to deactivate {selectedFees?.name}</p>
            ) : (
              <p>Would you like to activate {selectedFees?.name} </p>
            )}
          </div>
          <div className="col-auto">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                statusHandler();
              }}
            >
              OK
            </button>
            <button
              className="btn btn-secondary ms-3"
              onClick={(e) => {
                e.preventDefault();
                setStatusModal(false);
              }}
            >
              {label.common.cancel}
            </button>
          </div>
        </div>
      </ModalBox>
      <ModalBox
        open={showAdd}
        onClose={() => {
          setShowAdd(false);
        }}
        title="Add Fees"
      >
        <FeesAdd 
          onClose={() => {
            findFees();
            return setShowAdd(false);
          }}
        />
      </ModalBox>
      <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
    </>
  );
};

export default FeesTable;
