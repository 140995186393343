import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import ProductService from '../../../../../../../../services/api/product.service';
import label from '../../../../../../../../../assets/i18n/en.json'
import Form from 'react-bootstrap/Form';
const FeesForm = (props) => {
    // Pull properties form parent
    const { initialData, messages, isEdit, submitHandler, loaded, exitHandler } = props;
    // Set states for form
    const [isLoader, setIsLoader] = useState(false)

    const [formData, setFormData] = useState(props.initialData || {})
    const [formErrors, setFormErrors] = useState(messages.errors)
    const [practiceLocation, setPracticeLocation] = useState([])

    const [feeIsTaxable, setFeeIsTaxable] = formData.feeIsTaxable ? useState(Boolean(formData.feeIsTaxable)) : useState(false)
    const [feeRequired, setFeeRequired] = formData.feeRequired ? useState(Boolean(formData.feeRequired)) : useState(false)
    const [feeType, setFeeType] = formData.feeAmount !== null && formData.feeAmount > 0 ? useState("1") : useState("2")
    const [feeApplicableTo, setFeeApplicableTo] = formData.feeApplicableTo ? useState(formData.feeApplicableTo) : useState(2)
    
    useEffect(() => {
        setIsLoader(true);
        ProductService.practiceLocationLookup()
            .then((response) => {
                setPracticeLocation(response);
                setIsLoader(false);
            })
            .catch(error => {
                setIsLoader(false);
            })
    }, [])

    useEffect(() => {
        formData.feeType = feeType
        formData.fee = feeType === "1" ? (formData.feeAmount || 0) : (formData.feePercentage || 0)
        formData.feeApplicableTo = feeApplicableTo
    }, [setFeeType])
    // formula for input change
    const inputChange = (e) => {
        if (e.target.name === 'feeRequired') {
            let newStateObject = { ...formData };
            newStateObject[e.target.name] = e.target.checked;
            setFormData(newStateObject);
            setFeeRequired(e.target.checked)
            return (console.log(formData))
        }
        else if (e.target.name === 'fee') {
            let newStateObject = { ...formData };
            newStateObject[e.target.name] =  parseFloat(e.target.value);
            setFormData(newStateObject);
            return (console.log(formData))
        }
        else  if (e.target.name === 'taxPercent') {
            let newStateObject = { ...formData };
            newStateObject[e.target.name] = parseFloat(e.target.value);
            setFormData(newStateObject);
            return (console.log(formData))
        }
        else {
            let newStateObject = { ...formData };
            newStateObject[e.target.name] = e.target.value;
            setFormData(newStateObject);
            return (console.log(formData))
        }
    };
    const feeIsTaxableClickHandler = (e) => {
        let newStateObject = { ...formData };
        newStateObject[e.target.name] = e.target.checked;
        setFormData(newStateObject);
        setFeeIsTaxable(e.target.checked)
        return (console.log(formData))
    }


    return (
        <div>
            {isLoader && !loaded && <div className="ui">
                <div className="ui active dimmer">
                    <div className="ui indeterminate text loader">{label.common.processing}</div>
                </div>
            </div>}
            <form className=''>
                <div className="">
                    <div className="">
                        <div className="required field">
                            <label> {label.product.add.name}</label>
                            <input
                                onChange={(e) => {
                                    e.preventDefault();
                                    inputChange(e);
                                }}
                                placeholder="Name"
                                type="text"
                                name="name"
                                value={formData.name}
                                required
                            />
                            {/* <span>{formErrors.name}</span> */}
                        </div>
                        <div className="field">
                            <div className="form-check">
                                <input type="checkbox" className='form-check-input' checked={feeRequired} name="feeRequired" onChange={e => { setFeeRequired(e.target.checked); inputChange(e) }} />
                                <label className="form-check-label">
                                    Fee Required
                                </label>
                            </div>
                        </div>
                        <label>Fee</label>
                        <div className='input-group'>
                            <input value={formData.fee} type="number" name="fee" onChange={e => { e.preventDefault(); inputChange(e) }} />


                            <select value={feeType} onChange={e => { e.preventDefault(); setFeeType(e.target.value); inputChange(e) }} className='form-select' name="feeType" style={{ maxWidth: '60px' }}>
                                <option value={"2"}>%</option>
                                <option value={"1"}>$</option>
                            </select>
                        </div>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Fee is Taxable?"
                            onChange={e => { feeIsTaxableClickHandler(e) }}
                            name="feeIsTaxable"
                            checked={feeIsTaxable}
                        />

                        {feeIsTaxable ? <div className="required field">
                            <label> {label.product.add.taxPercent}</label>
                            <input
                                onChange={(e) => {
                                    e.preventDefault();
                                    inputChange(e);
                                }}
                                type="number"
                                name="taxPercent"
                                value={formData.taxPercent}
                            />
                            {/* <span>{formErrors.taxPercent}</span> */}
                        </div> : ""}
                        <div className="required field">
                            <label> {label.product.add.practiceLocation}</label>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={practiceLocation}
                                name="practiceLocationId"
                                value={practiceLocation && practiceLocation.find((obj) => obj.practiceLocationId === formData.practiceLocationId)}
                                onChange={(e) => {
                                    inputChange({
                                        target: {
                                            value: e.practiceLocationId,
                                            name: "practiceLocationId",
                                        },
                                    });
                                }}
                                getOptionLabel={(option) => option.practiceLocation}
                                getOptionValue={(option) => option.practiceLocationId}
                            ></Select>
                            {/* <span>{formErrors.practiceLocation}</span> */}
                        </div>
                        <div className="required field">
                    <label>Fee Applicable To:</label>
                    <select className="form-select" name="feeApplicableTo" value={feeApplicableTo} onChange={e => { e.preventDefault();setFeeApplicableTo(e.target.value);inputChange(e) }}>
                      <option value={2}>Both</option>
                      <option value={1}>Services</option>
                      <option value={0}>Products</option>
                    </select>
                  </div>
                    </div>
                </div >
            </form >
            <div className="mt-3 d-flex justify-content-between">
                <div className='col-auto'>
                    <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); submitHandler(formData) }}> {isEdit ? label.product.edit.save : label.product.add.save}</button>
                </div>
                <div className='col-auto'>
                    <button className="btn btn-secondary float-right" onClick={(e) => { e.preventDefault(); props.onClose() }}> Cancel </button>
                </div>
            </div>
        </div >
    )
}

export default FeesForm