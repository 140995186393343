import React, { useEffect, useState, useContext } from "react";
import moment from 'moment'
import Utilities from "../../../../../services/commonservice/utilities";
import { store } from "../../../../../context/StateProvider";
import Module from "../../../../templates/components/Module";
import PracticeLocationSelector from "../../../../templates/components/PracticeLocationSelector";
import Table2 from "../../../../templates/components/Table2";
import ProviderService from "../../../../../services/api/provider.service";
import Select from 'react-select'
import DimLoader from "../../../../templates/components/DimLoader";

const ExpireCreditCard = (props) => {
    const [expiryCreditCardData, setExpiryCreditCardData] = useState();
    const [isLoader, setIsLoader] = useState(false)
    const [showExpired, setShowExpired] = useState(true)
    const [month, setMonth] = useState(2)
    const [title,setTitle] =useState("Expired Credit Card")
    const state = useContext(store).state
    const [initialMessage, setInitialMessage] = useState(true)
    const [locationId, setLocationId] = useState(state.practiceLocationId || null)
    const [locationList, setLocationList] = useState(state?.practiceLocations || null)

    const getExpiryCreditCard = () => {
        setIsLoader(true)
        setInitialMessage(false)
        var reqObj = {
            Months:showExpired==="false" ? +month : null,
            ShowExpired: showExpired
        }
        if (locationId) reqObj.PracticeLocationId = locationId
        ProviderService.getExpiryCreditCard(reqObj).then(data => {
            setExpiryCreditCardData(data);
            setTitle(showExpired===true || showExpired==="true"?"Expired Credit Card":"Expiring Credit Card")
            setIsLoader(false)
        }).catch(console.log);
    }


    const expiryCreditCardColumns = [
        {
            id: "accountHolderName",
            Header: "Account Holder Name",
            accessor: record => {
                return record.accountHolderName
            }
        },
        {
            id: "cardType",
            Header: "Card Type",
            accessor: record => record.cardType
        },
        {
            id: "mobile",
            Header: "Phone Number",
            accessor: (record) => Utilities.toPhoneNumber(record.mobile)
        },
        {
            id: "email",
            Header: "Email",
            accessor: record => record.email
        },
        {
            id: "expiryDate",
            Header: "Expiry Date",
            accessor: record => record.expiryDate
        },
        {
            id: "patientName",
            Header: "Patient Name",
            accessor: record => record.patientFirst+" "+record.patientLast
        },
        {
            id: "maskedCardNumber",
            Header: "Masked Card Number",
            accessor: record => "****"+record.maskedCardNumber
        }
    ]


    return (
        <>
        {isLoader &&
            <DimLoader loadMessage="Processing" />
        }    
    
        <div className="row d-flex g-3">
            <div className='col-12 m-p-0'>
                <Module title="Filters">
                    <div className='row d-flex'>
                        <div className='col-12 row d-flex justify-content-center mx-0 align-items-end report-filters'>
                        <div className='col-md-auto col-12'>
                                <div className='ui field'>
                                    <label>Expiry</label>
                                    <select className='form-select' value={showExpired} onChange={e => { e.preventDefault(); setShowExpired(e.target.value) }}>
                                        <option value="true">Expired</option>
                                        <option value="false">Expiring</option>
                                    </select>
                                </div>
                            </div>
                            {showExpired==="false" && <div className='col-md-auto col-12'>
                                <div className='ui field'>
                                    <label>Month</label>
                                    <select className="form-select" value={month} onChange={e => { e.preventDefault(); setMonth(e.target.value) }}>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                    </select>
                                </div>
                            </div>}
                            <div className='col-md-auto col-12'>
                                <div className='ui field'>
                                    <label>Practice Location</label>

                                    <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={locationList && locationList}
                                    isLoading={!locationList}
                                    isDisabled={!locationList}
                                    loadingMessage="loading..."
                                    name="practiceLocationId"
                                    value={locationList && Array.isArray(locationList) ? locationList.find(obj => obj.practiceLocationId === locationId) : null}
                                    onChange={e => {
                                        if (e?.practiceLocationId) {
                                            setLocationId(e.practiceLocationId)
                                        }
                                        else {
                                            setLocationId()
                                        }
                                    }}
                                    getOptionLabel={(option) => option.practiceLocation}
                                    getOptionValue={(option) => option.practiceLocationId}
                                    >
                                </Select>

                                </div>
                            </div>
                            <div className="col-md-auto col-12"><button className="btn btn-primary" onClick={e => { e.preventDefault(); getExpiryCreditCard() }} title="Pull Report"><i className="icon arrow circle right" /></button></div>
                        </div>
                    </div>
                </Module>
            </div>
            <div className="col-12 m-p-0">
                <Module title={title}>
                    <Table2
                    export
                    searchable
                        data={expiryCreditCardData}
                        columns={expiryCreditCardColumns}
                        loading={false}
                        title={title}
                        initialMessage={initialMessage}
                    />
                </Module>
            </div>
        </div>
        </>
        )
}

export default ExpireCreditCard;