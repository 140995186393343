import React, { useState,useContext, useEffect } from 'react'
import TabsTemplate from '../../../../../../templates/components/TabsTemplate';
import Select from 'react-select'
import PatientService from '../../../../../../../services/api/patient.service';
import moment from 'moment';
import CommonService from '../../../../../../../services/api/common.service';
import Utilities from '../../../../../../../services/commonservice/utilities';
import RecurringPaymentsService from '../../../../../../../services/api/recurring-payments.service';
import DimLoader from '../../../../../../templates/components/DimLoader';
import InvoiceService from '../../../../../../../services/api/invoice.service';
import ModalBox from '../../../../../../templates/components/ModalBox';
import PatientAccountAdd from '../../../../patient-account/patient-account-add/PatientAccountAdd';
import AddPatient from '../../../../patient/add-patient/AddPatient';
import DatePicker from "react-multi-date-picker"
import { store } from '../../../../../../../context/StateProvider';

// both subscription and installment plan
const RecurringPlanForm = (props) => {
    const [inputData, setInputData] = useState(props.isEdit?undefined:{ ...props.initialData })
    const [accounts, setAccounts] = useState()
    const [selectedAccount, setSelectedAccount] = useState()
    const [oldSeclectedAccount, setOldSelectedAccount] = useState()
    
    const [selectedPatient, setSelectedPatient] = useState()
    const [patientList, setPatientList] = useState()
    const [accountType, setAccountType] = useState()
    const [loadMessage, setloadMessage] = useState()
    const [allowUpdate,setAllowUpdate] = useState(false)
    const [showAddAccount, setShowAddAccount] = useState(false)
    const [showAdd, setShowAdd] = useState(false)
    const [calcPayment, setCalcPayment] = useState()
    const [isLoader_Accounts, setIsLoader_Accounts] = useState(false)
    const [isLoader_Patients, setIsLoader_Patients] = useState(false)
    const [isLoader_Invoice, setIsLoader_Invoice] = useState(false)
    const [isLoader_PaymentSchedule, setIsLoader_PaymentSchedule] = useState(false)
    
    const [calcTotal, setCalcTotal] = useState(inputData?.subTotal)
    const [validInput, setValidInput] = useState(false);
    const [firstTransactionDate, setFirstTransactionDate] = useState();
    const [noOfPayments, setNoOfPayments] = useState();
    const globalStateAndDispatch = useContext(store)
    const contextState = globalStateAndDispatch.state
    const icons = {
        blank: 'credit-card',
        AMEX: 'cc amex',
        DINERS: 'cc diners club',
        DISCOVER: 'cc discover',
        JCB: 'cc jcb',
        MASTERCARD: 'cc mastercard',
        VISA: 'cc visa'
    }
    const accountTypes = [
        // 0:'all',
        { value: 1, title: 'Credit' },
        { value: 2, title: 'ACH' },
        // 3: 'credit',
        // 4: 'debit',
        // 5:'cash',
        // 6:'check',
    ]
    // console.log(props.initialData)
    const FrequencyEnum = [
        'Daily',
        'Weekly',
        'BiWeekly',
        'Monthly',
        'Quarterly',
        'HalfYearly',
        'Annually'
    ]
    // 1 is payment plan, 3 is Subscription
    // const updateChannelType = (accounttype) => {
    //     switch (accounttype) {
    //         case 'Credit':
    //             inputChange({
    //                 target: {
    //                     name: 'channelType', value: 3
    //                 }
    //             })
    //             break
    //         case 'ACH':
    //             inputChange({
    //                 target: {
    //                     name: 'channelType', value: 2
    //                 }
    //             })
    //             break
    //         default:
    //             inputChange({
    //                 target: {
    //                     name: 'channelType', value: 3
    //                 }
    //             })
    //             break;
    //     }

    // }
    const findAccounts = () => {
        let reqObj = { IsActive: 1, PracticeLocationId: contextState.practiceLocationId}
        setIsLoader_Accounts(true)
        PatientService.fetchPatientAccount(props.initialData.patientId || inputData?.patientId || props.patientId, reqObj)
            .then(res => {
                setAccounts(res)
                setIsLoader_Accounts(false)
            })
            .catch(err => {
                setIsLoader_Accounts(false)
            })
    }

    // formula for input change
    const inputChange = (e) => {
        let newStateObject = { ...inputData };
        newStateObject[e.target.name] = e.target.value
        setInputData(newStateObject);
        return console.log(inputData)
    };

    const patientLookup = () => {
        setIsLoader_Patients(true)
        if (props.patientId || props.initialData?.patientId) {
            let patientId = props.patientId || props.initialData?.patientId
            PatientService.getPatientById(patientId)
                .then(res => {
                    setPatientList([res.data])
                    setIsLoader_Patients(false)
                })
                .catch(err => {
                    setIsLoader_Patients(false)
                })
        }
        else {
            let reqObj = { SearchTerm: '', isActive: true, isRegistered: true, SortField: 'firstName' }
            CommonService.patientLookup(reqObj)
                .then(res => {
                    if (res) {
                        setPatientList(res.data)
                        if (inputData?.patientId) {
                            setSelectedPatient(res.data.find(obj => obj.id === inputData?.patientId))
                        }
                        setIsLoader_Patients(false)
                    }
                }
                )
                .catch(err => {
                    //console.log(err)
                    setIsLoader_Patients(false)
                })
        }
    }

    useEffect(() => {
        patientLookup()
        if(!props.isEdit) {
            setAllowUpdate(true)
            inputChange({
                target: {
                    name: 'noOfPayments', value: 3
                }
            })
            setNoOfPayments(3) //default
        }
    }, [])
    useEffect(() => {
        console.log("inputData :::"+JSON.stringify(inputData))
    }, [inputData])
    useEffect(() => {
        if(props.isEdit && accounts){
            pullPaymentSchedule() //pulling payment schedule to fetch account id
        }
    }, [accounts])
    
    useEffect(() => {
        if (props.initialData.patientId || inputData?.patientId || props.patientId || selectedPatient) {
            findAccounts()
        }
        if (selectedPatient) {
            inputChange({ target: { name: 'email', value: selectedPatient.email } })
        }
    }, [selectedPatient])

    useEffect(() => {
        let account = { ...selectedAccount }
        delete account.createdOn
        if (selectedAccount) {
            let accountType = accountTypes.find(obj => obj.value === account.accountType).title.toLowerCase()
            setAccountType(accountType)
            //updateChannelType(accountType)
            if(props.isEdit){
                checkAllowUpdate()
            }
        }
        setInputData({ ...inputData, ...selectedAccount, ...selectedPatient })
    }, [selectedAccount])

    const checkAllowUpdate = () => {
        let dateChanged = firstTransactionDate ? (new Date(firstTransactionDate).toISOString() != new Date(props.initialData.firstTransactionDate).toISOString()) : false;
        if (oldSeclectedAccount?.id !== selectedAccount?.id || dateChanged) {
            setAllowUpdate(true);
        }
        else {
            setAllowUpdate(false);
        }
    }

    useEffect(() => {
        if(props.isEdit){
            // props.initialData - get recurring payment data
            setIsLoader_Invoice(true)
            //console.log("props.initialData ::: "+JSON.stringify(props))
            setloadMessage("Loading...")
            pullInvoice() //pulling invoice details
        }
        else {
            //console.log(props.initialData)
            setInputData(props.initialData)
        }
    }, [props.initialData])

    const pullInvoice = () =>{
        setIsLoader_Invoice(true)
        InvoiceService.getInvoiceById(props.initialData?.invoiceId)
        .then(res=>{
            let newData = res
            newData.noOfPayments = props.initialData?.noOfPayments
            newData.firstTransactionDate = props.initialData?.firstTransactionDate
            newData.frequency = props.initialData?.frequency
            newData.description = props.initialData?.description
            setInputData(newData)
            setNoOfPayments(props.initialData?.noOfPayments)
        }).catch((err) => { 
            console.log(err) 
        }).finally(() => {
            setIsLoader_Invoice(false)
        });
    }
    const pullPaymentSchedule = () =>{
        setIsLoader_PaymentSchedule(true)
        RecurringPaymentsService.getPaymentSchedule({recurringId:props.initialData?.id})
        .then(res=>{
            let schedule = res?.find(obj => obj.transactionId === null)
            if(schedule){
                let account = accounts.find(obj => obj.id === schedule.paymentAccountId)
                if(account){
                    setOldSelectedAccount(account)
                    setSelectedAccount(account)
                }
            }
        }).catch((err) => { 
            console.log(err) 
        }).finally(() => {
            setIsLoader_PaymentSchedule(false)
        });
    }
    const calcPaymentFunc = () => {
        let amount = parseFloat(inputData?.subTotal + inputData?.itemTotalTax)
        if (noOfPayments > 0 && props.transactionType == 1) {
            setCalcPayment(parseFloat(amount / noOfPayments).toFixed(2))
        }
        else setCalcPayment(amount)

    }
    const calcTotalFunc = () => {
        let amount = parseFloat(inputData?.subTotal + inputData?.itemTotalTax)
        if (props.transactionType == 3 && noOfPayments) {
            setCalcTotal(parseFloat(amount * noOfPayments).toFixed(2))
        }
        else setCalcTotal(amount)
    }

    useEffect(() => {
        calcPaymentFunc()
        calcTotalFunc()
    }, [inputData?.amount])

    useEffect(() => {
        if (!noOfPayments || isNaN(noOfPayments)) { setValidInput(false); }
        else if (String(noOfPayments).match("^(0?[1-9]|[1-9][0-9])$") === null) {
            setValidInput(false);
        } else setValidInput(true)
        calcPaymentFunc()
        calcTotalFunc()
    }, [noOfPayments])

    useEffect(() => {
        if (!inputData?.firstTransactionDate) {
            setFirstTransactionDate(new Date().setDate(new Date().getDate() + 1))
        }else{
            setFirstTransactionDate(inputData?.firstTransactionDate)
        }
    }, [inputData?.firstTransactionDate])

    useEffect(() => {
        if (props.isEdit && firstTransactionDate) {
            checkAllowUpdate()
        }
    }, [firstTransactionDate])
    return (
        <>
            <div className='m-0 p-0'>
                { isLoader_PaymentSchedule ||isLoader_Invoice || props.isLoader ? <DimLoader loadMessage={loadMessage? loadMessage : "Processing..."} /> : null}
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-3 card m-0 p-0 bg-light'>
                            <div className='card-body p-3'>
                                <h5 className='mb-3'>Payment Summary</h5>
                                <div>
                                    <div className='field'>
                                        <label>Reference No</label>
                                        <input name="invoiceNumber" type="text" value={inputData?.invoiceNumber || inputData?.invoiceNumber} disabled />
                                    </div>
                                    <div className='field'>
                                        <label>Sub-Total</label>
                                        <input name="subTotal" type="text" disabled value={Utilities.toDollar(inputData?.subTotal)} />
                                    </div>
                                    <div className='field'>
                                        <label>Tax Total</label>
                                        <input name="taxAmount" type="text" value={Utilities.toDollar(inputData?.itemTotalTax)} disabled />
                                    </div>
                                    <div className='field'>
                                        <label>Invoice Total</label>
                                        <input name="amount" type="text" value={Utilities.toDollar(inputData?.subTotal + inputData?.itemTotalTax)} disabled />
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer m-0 align-items-end bg-dark mt-3 text-white p-3'>
                                <div className='field'>
                                    <label className="text-white text-end">Payment Amount</label>
                                    <input name="payAmount" type="text" className='text-end' value={Utilities.toDollar(calcPayment)} disabled />
                                </div>
                                <div className='field'>
                                    <label className="text-white text-end">Total Payment Plan Amount</label>
                                    <input name="amount" type="text" className='text-end' value={Utilities.toDollar(calcTotal)} disabled />
                                </div>
                            </div>
                        </div>
                        <div className='col-9 card m-0 p-3'>
                            <h5 className='card-title '>Search Patient</h5>
                            <div className='field mb-3'>
                                <div className='col-12 input-group'>
                                    <Select
                                        options={patientList}
                                        name="patientId"
                                        value={patientList && patientList.find(obj => obj.id === inputData?.patientId)}
                                        isSearchable
                                        classNamePrefix="react-select"
                                        className="react-select-container"
                                        isLoading={isLoader_Patients}
                                        isDisabled={props.patientId || props.initialData.patientId}
                                        onChange={e => {
                                            inputChange({
                                                target:
                                                    { value: e.id, name: 'patientId' }
                                            })
                                            setSelectedPatient(e)
                                        }}
                                        loadingMessage="Patient Loading..."
                                        getOptionLabel={(option) => {
                                            option.firstName + ' ' + option.lastName + ' | ' + moment.utc(option.dob).format("M/D/YYYY") + ' | ' + Utilities.toPhoneNumber(option.mobile) || Utilities.toPhoneNumber(option.patientPhone)
                                            return (
                                                option.firstName + ' ' + option.lastName + ' | ' + moment.utc(option.dob).format("M/D/YYYY") + ' | ' + Utilities.toPhoneNumber(option.mobile) || Utilities.toPhoneNumber(option.patientPhone)
                                            )
                                        }
                                        }
                                        getOptionValue={(option) => option.id}
                                        noOptionsMessage={(e) => { return <button className='btn btn-primary form-control'>Add Patient</button> }}
                                    />
                                    {!props.patientId && !props.initialData.patientId ? <button className='btn btn-primary' title="Add Patient" onClick={e => { e.preventDefault(); setShowAdd(true) }}><i className='icon plus' /></button> : null}
                                </div>
                            </div>
                            {accounts?.length >= 1 ? <div className='field mb-3 input-group'>
                                <Select
                                    options={accounts}
                                    name="account"
                                    isClearable
                                    isLoading={isLoader_Accounts}
                                    classNamePrefix="react-select"
                                    className="react-select-container"
                                    value={selectedAccount}
                                    onChange={e => {
                                        inputChange({
                                            target: {
                                                name: 'account', value: e
                                            }
                                        })
                                        setSelectedAccount(e)
                                    }}
                                    placeholder="Select Account"
                                    isDisabled={props.isDisabled || !accounts}
                                    getOptionLabel={(option) => {
                                        let label = ""
                                        if (option.accountType === 1) {
                                            label = accountTypes.find(obj => obj.value === option.accountType).title + ' | ' + option.cardType + ' ' + option.maskedCardNumber + ' | ' + option.accountHolderName
                                        }
                                        else if (option.accountType === 2) {
                                            label = accountTypes.find(obj => obj.value === option.accountType).title + ' | ' + option.bankName + ' ' + option.maskedAccountNo + ' | ' + option.accountHolderName
                                        }
                                        return label
                                    }
                                    }
                                    getOptionValue={(option) => option.id}
                                    noOptionsMessage={() => { return <button className='btn btn-primary form-control' onClick={e => { e.preventDefault(); setShowAddAccount(true) }}>Add Account</button> }}
                                />
                                {!props.isDisabled && <button className='btn btn-primary col-auto' title="Add Account" onClick={e => { e.preventDefault(); setShowAddAccount(true) }}><i className='icon plus' /></button>}
                            </div> :
                                <button className='btn btn-primary col-auto mb-3' title="Add Account" onClick={e => { e.preventDefault(); setShowAddAccount(true) }}><i className='icon plus' /> Add Account</button>}
                            <TabsTemplate activeKey={accountType} onSelect={k => {setAccountType(k);
                                 //return updateChannelType(k) 
                                 }}>
                                <div disabled={selectedAccount && selectedAccount.accountType !== 1} eventKey='credit' title={<span><i className='icon credit card' />Card</span>}>
                                    <div className='ui segment row d-flex align-items-end pb-4'>
                                        <div className='field required col-12'>
                                            <label>Name on Card</label>
                                            <input name="accountHolderName" type="text" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData?.accountHolderName} disabled/>
                                        </div>
                                        <div className='field required col-5'>
                                            <label>Last 4 of Card</label>
                                            <div className='input-group'>
                                                <input name="maskedCardNumber" type="text" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData?.maskedCardNumber} disabled/>

                                                <span className="input-group-text bg-transparent" id="basic-addon1">
                                                    <i className={`mx-auto icon bg-transparent text-primary large ${inputData?.cardType ? icons[inputData?.cardType] : 'credit card'}`} ></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='field col-2'>
                                            <label>CVV</label>
                                            <input name="cvv" type="text" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData?.cvv} />
                                        </div>
                                        <div className='field required col-5'>
                                            <label>Exp.</label>
                                            <input name="cardExpiry" type="text" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData?.cardExpiry} disabled/>
                                        </div>
                                    </div>
                                </div>
                                <div disabled={selectedAccount && selectedAccount.accountType !== 2} eventKey='ach' title={<span><i className='icon credit card' />ACH</span>}>
                                    <div className='ui segment row d-flex align-items-end pb-4'>
                                        <div className="field required col-6">
                                            <label>Routing Number</label>
                                            <input name="routingNumber" placeholder='Routing/Transit Number' type="text" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData?.routingNumber} disabled/>
                                        </div>
                                        <div className="field required col-6">
                                            <label>Account Number</label>
                                            <input name="maskedAccountNo" placeholder='Routing/Transit Number' type="text" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData?.maskedAccountNo} disabled/>
                                        </div>
                                        <div className="field required col-6">
                                            <label>Account Name</label>
                                            <input name="accountHolderName" type="text" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData?.accountHolderName} disabled/>
                                        </div>
                                        <div className="field required col-6">
                                            <label>Account Type</label>
                                            <select name="nameOfInstitution" className='form-select' type="text" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData?.nameOfInstitution} disabled>
                                                <option value="checking">Checking</option>
                                                <option value="savings">Savings</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </TabsTemplate>
                            <div className='px-3 row'>
                                <div className="field required col-4">
                                    <label>Payment Cycle</label>
                                    <select className="form-select" name="frequency" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData?.frequency} defaultValue={3} disabled={props.isEdit? true:""}>
                                        {FrequencyEnum.map((freq, i) => {
                                            return (
                                                <option value={i}>{freq}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="field required col-4">
                                    <label>Number of Payments</label>
                                    <input name="noOfPayments" type="number" onChange={e => { e.preventDefault(); setNoOfPayments(e.target.value); inputChange(e); }} value={noOfPayments} disabled={props.isEdit? true:""}/>
                                </div>
                                <div className="field required col-4">
                                    <label>Schedule Transactions On</label>
                                    <DatePicker
                                        name="firstTransactionDate"
                                        value={firstTransactionDate}
                                        format={"MM-DD-YYYY"}
                                        onChange={setFirstTransactionDate}
                                        minDate={new Date()}
                                        readOnly={props.isEdit && props.initialData?.updateCount && props.initialData?.updateCount>=1 ? true:false}
                                    />
                                </div>
                                <div className="field col-12">
                                    <label>Email</label>
                                    <input name="email" type="email" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData?.toEmail || inputData?.email} />
                                </div>
                                <div className='field col-12'>
                                    <label>Memo</label>
                                    <textarea className='form-control' name="description" onChange={e => { e.preventDefault(); inputChange(e) }} value={inputData?.description} disabled={props.isEdit? true:""}/>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
                {/* {transactionType} */}
                <div className='mt-3 d-flex justify-content-between'>
                    <div className='col-auto'>
                        <button
                            disabled={props.isEdit ? !allowUpdate : !validInput}
                            className="btn btn-primary"
                            onClick={e => { e.preventDefault(); props.submitHandler(inputData,firstTransactionDate)}}>
                            {props.submitLabel || 'Add'}
                        </button>
                    </div>
                    <div className='col-auto'>
                        <button className="btn btn-secondary" onClick={e => { e.preventDefault(); props.onClose() }}>Cancel</button>
                    </div>
                </div>
                <ModalBox open={showAddAccount} onClose={() => setShowAddAccount(false)}>
                    <PatientAccountAdd patientId={inputData?.patientId || props.initialData.patientId} onClose={() => { setShowAddAccount(false) }} />
                </ModalBox>
                <ModalBox open={showAdd} onClose={() => setShowAdd(false)}>
                    <AddPatient onClose={() => { setShowAdd(false) }} />
                </ModalBox>
            </div >
        </>

    )
}

export default  RecurringPlanForm