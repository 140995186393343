
import React, { useState } from 'react'
import FormFormBuilder from '../form-form-builder/FormFormBuilder'
import FormsService from '../../../../../services/api/forms.service'
import APIResponse from '../../../../templates/components/APIResponse'
import DimLoader from '../../../../templates/components/DimLoader'

const AddForm = (props) => {
  const [isLoader, setIsLoader] = useState(false)
  const [apiResponse, setApiResponse] = useState()

  const onSuccess = (message) => {
    if (props.refresh) {
      props.refresh()
    }
    if (props.onClose) {
      props.onClose()
    }
    if (props.exitHandler) {
      props.exitHandler()
    }
  }

  const saveForm = (data, form, isLinked,isEditable) => {
    setIsLoader(true);
    let reqObj = {
      formDescription: data.formDescription,
      formTitle: data.formTitle,
      status: 0,
      fields: data.fields ? data.fields : form,
      isEditable:isEditable
    }
    reqObj.status = 0
    if (isLinked) {
      FormsService.linkForm(reqObj, data.formId).then(res => {
        setApiResponse(res)
      })
        .catch(err => {
          setApiResponse(err)
        }).finally(() => {
          setIsLoader(false)
        })
    } else {
      FormsService.addForm(reqObj).then(res => {
        setApiResponse(res)
      })
        .catch(err => {
          setApiResponse(err)
        }).finally(() => {
          setIsLoader(false)
        })
    }
  }
  return (
    <>
      {isLoader && <DimLoader />}
      <FormFormBuilder
        saveText="Save"
        submitHandler={saveForm}
        onClose={props?.onClose}
        userType={props?.userType}
      />
      <APIResponse apiResponse={apiResponse} onSuccess={onSuccess} toastOnSuccess={true} />
    </>
  )
}

export default AddForm