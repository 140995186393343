import React, { useEffect, useState } from 'react'
import ModalBox from '../../../../../templates/components/ModalBox'
import Table2 from '../../../../../templates/components/Table2'
import PracticeAppointmentStatusCodeService from '../../../../../../services/api/practice-appointment-status-code.service'
import EditPracticeAppointmentStatusCode from '../../../practice-appointment-status-code/edit-practice-appointment-status-code/EditPracticeAppointmentStatusCode'

const PracticeAppointmentStatusCodeSettings = (props) => {

    const [isLoader, setIsLoader] = useState(false)
    const [allList, setAllList] = useState()
    const [selectedRow, setSelectedRow] = useState()

    const [showEdit, setShowEdit] = useState(false)
    const hiddenColumns = ["action"]

    const getAll = () => {
        setIsLoader(true);
        PracticeAppointmentStatusCodeService.getAllPracticeAppointmentStatusCode()
            .then((response) => {
                setAllList(response.data.data);
                setIsLoader(false);
            })
            .catch(error => {
                setIsLoader(false);
            })
    }

    useEffect(() => {
        getAll()
    }, [])

    const columns = [
        {
            id: "appointmentStatusCode",
            Header: "Code",
            accessor: code =>code.appointmentStatusCode
        },
        {
            id: "appointmentStatusLabel",
            Header: "Label",
            accessor: (code) => code.appointmentStatusLabel
        },{
            id: "color",
            Header: "Color",
            align: "center",
            sortable: true,
            accessor:(code, i) => {
                return (
                    <div className="d-flex align-items-center">
                        <span className="me-3" style={{height:'15px',width:'15px',display:'block', backgroundColor:code.color}} title={code.color}></span> <p>{code.color}</p>
                    </div>
                )
            }
        },{
            id: "action",
            Header: "Action",
            disableSortBy: true,
            accessor:(code, i) => {
                return (
                    <div className='btn-group'>
                        <button className="p-0 ps-1 btn btn-primary" title="edit" onClick={e => { e.preventDefault();console.log(code); setSelectedRow(code); return setShowEdit(true) }}><i className="icon pencil" /></button>
                    </div>
                )
            }
        }
    ]

    return (
        <div>
            <div title="Practice Appointment Status Code" className='py-3'>
                <Table2 
                hiddenColumns={hiddenColumns}
                export searchable 
                data={allList} 
                columns={columns} 
                loading={isLoader} 
                /> 
            </div>
            <ModalBox open={showEdit} onClose={() => { setShowEdit(false) }} title="Edit">
                <EditPracticeAppointmentStatusCode initialData={selectedRow} onClose={() => { getAll(); return setShowEdit(false) }} />
            </ModalBox>
        </div>
    )
}

export default PracticeAppointmentStatusCodeSettings